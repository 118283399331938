





























































import { Component, Emit, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { MixinsHomeworkTask } from '@xt/client/mixins'
import ReplyComment from '@xt/www/components/business/homework/replyComment.vue'
import WxImgPop from '@xt/www/components/wxImgPop/index.vue'
import { EnumHomeworkBizType } from '@xt/client/enums'

type HomeworkDetailType = {
  bizType: number
  classhourId: number
  commentResultVOS: any[]
  content: string
  contentLength: number
  courseName: string
  courseSubTitle: string
  createTime: number
  homeworkPicList: any[]
  homeworkTaskId: number
  homeworkTaskTitle: string
  id: number
  learnEndTime: number
  likeCount: number
  likeRecordDOS: any[]
  liked: boolean
  memberId: string
  memberNickname: string
  reviewComment: any
  reviewCount: number
  reviewed: boolean
  reviewerId: string
  reviewerNickname: string
  singleCourseId: number
  suned: boolean
  visibleRange: boolean
}

@Observer
@Component({
  components: {
    ReplyComment,
    WxImgPop
  }
})
export default class extends Mixins(MixinsHomeworkTask) {
  @Prop({ default: false }) visible: boolean
  @Prop() homeworkId: number
  @Prop() bizType: number

  uuid = Date.now() + '' + Math.floor(Math.random() * 100000)
  bottomState: boolean = false
  dataSource: HomeworkDetailType | null = null
  visibleExhibitionPopup: boolean = false

  get Details() {
    return this.PageStore.HomeworkDetail
  }
  get Pagination() {
    return this.PageStore.HomeworkComment
  }
  get body() {
    return { id: this.homeworkId }
  }
  get editPlaceholder() {
    return `回复${this.dataSource.reviewerNickname}助教`
  }
  get teacherComment() {
    return this.Pagination.dataSource.filter(item => item.memberType === 2)
  }
  get toCommentId() {
    if (this.bizType === EnumHomeworkBizType.Course) return
    return this.teacherComment[this.teacherComment.length - 1].id
  }

  goCourse(e: MouseEvent) {
    switch (this.bizType) {
      case EnumHomeworkBizType.Course:
        this.$emit('cancel', e)
        // 把通知弹窗和现在的弹窗都关掉
        document.body.click()
        this.$router.push({
          name: 'course-id',
          params: {
            id: `${this.dataSource.singleCourseId}`
          }
        })
        return
      case EnumHomeworkBizType.Show:
        this.visibleExhibitionPopup = true
        return
    }
  }

  canReply(dataSource) {
    return dataSource && dataSource.reviewed && !dataSource._FullEndofClass
  }

  scroll() {
    let clientHeight = this.$refs['refresh']['clientHeight']
    let scrollHeight = this.$refs['refresh']['scrollHeight']
    let scrollTop = this.$refs['refresh']['scrollTop']
    if (clientHeight + scrollTop + 300 >= scrollHeight) {
      if (this.bottomState) return
      this.bottomState = true
      this.Pagination.onLoading(this.body)
    } else {
      this.bottomState = false
    }
  }

  @Watch('visible')
  async updateVisible(value: boolean) {
    if (!value) return
    await this.Details.onLoading({ id: this.homeworkId })
    this.dataSource = this.Details.dataSource
    await this.Pagination.onLoading(this.body)
  }

  async onSubmit(event) {
    this.onReply({ event, id: this.homeworkId, toCommentId: this.toCommentId })
  }

  @Emit('cancel')
  cancelPopupHandler() {
    this.dataSource = null
  }

  reset() {
    this.Pagination.onReset()
  }

  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
