




































import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator'

enum EnumAgreementType {
  /** 用户协议 */
  USER = 'user',
  /** 隐私协议 */
  PROVACY = 'privacy'
}

@Component({
  components: {}
})
export default class extends Vue {
  get EnumAgreementType() {
    return EnumAgreementType
  }
  protocol(e, type) {
    e.stopPropagation()
    let jumpStr = type === EnumAgreementType.USER ? '/protocol/service' : '/protocol/privacy'
    window.open(jumpStr)
  }
  icp() {
    window.open('https://beian.miit.gov.cn/')
  }
  publics() {
    window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502044839')
  }
  onSubmit() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
