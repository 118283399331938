/**
 *  关于 api 枚举
 */
export enum EnumApiProduct {
  /** 商品详情 */
  productDetail = '/product/api/product/{productType}/{bizId}',
  productDetailByProductId = '/product/api/product/{productId}',
  productHasBuy = '/order/api/orders/hasbuy/product',
  ProductList = '/product/api/product/products',
  ProductAgreement = '/product/api/product/{productId}/protoco',
  productLive = '/product/api/product/liveCourse',
  productCalendar = '/product/api/product/studycalendar',
  productExistLive = '/product/api/product/liveCourse'
}
