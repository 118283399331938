




















import { Observer } from 'mobx-vue'
import { Vue, Component } from 'vue-property-decorator'
import OrderBtnsScript from './script'
import Inovice from '../inovice.vue'

@Observer
@Component({
  name: 'OrderBtnsComp',
  components: {
    Inovice
  }
})
export default class OrderBtnsComp extends OrderBtnsScript {}
