


















































































































import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator'
import { MixinsCourseRreview } from '@xt/client/mixins'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
@Observer
@Component({
  components: {}
})
export default class PageView extends Mixins(MixinsCourseRreview) {
  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
