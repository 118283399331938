export const enum ArmsSumKeys {
  /**
   * path参数丢失
   */
  PATH_PARAMTER_LOSS = 'error.path-paramter-loss',
  /**
   * 用户打开了浏览器控制台
   */
  USER_OPEN_DEVTOOL = 'warn.user-open-devtool',
/**
   * 用户右键打开了contextmenu
   */
  USER_OPEN_CONTEXTMENU = 'warn.user-open-contextmenu',
  /**
   * 获取当前最新版本文件超时
   */
  FETCH_VERSION_FILE_TIMEOUT = 'warn.fetch-version-file-timeout',
  /**
   * 获取当前最新版本文件失败
   */
  FETCH_VERSION_FILE_FAILED = 'error.fetch-version-file-failed',
  /**
   * 客户端的版本不是最新的
   */
  CLIENT_VERSION_TOO_LOW = 'warn.client-will-reload.{{client_version}}',
  /**
   * 用户页面reload了 但是没有成功升级到最新版
   */
  RELOAD_UPGRADE_FAILED = 'error.client-reload-upgrade-failed.{{from_version}}.{{to_version}}'
}
