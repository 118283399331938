import $global from '../../store/global'

export const BackupKeys = {
  /**
   * 真正存储用户数据的key
   */
  userinfo: `${$global.localStorageStartsWith}User`,

  /**
   * h5原本的身份备份
   */
  user_h5: `${$global.localStorageStartsWith}User__Backup`,
  /**
   * uc小程序当前的身份备份
   */
  user_uc: `${$global.localStorageStartsWith}User__UcBackup`,
  /**
   * 展览小程序当前的身份备份
   */
  user_show: `${$global.localStorageStartsWith}User__ShowBackup`
}
