




















import { Component, Prop, Ref, Vue, Emit } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import HeaderImage from '@xt/www/components/headerImage/index.vue'
import { AtItem } from '@xt/client/entities/gensee/types'

@Observer
@Component({
  components: {
    HeaderImage
  }
})
export default class PageView extends Vue {
  @Prop({ default: false }) visible: boolean

  get PageStore() {
    return this.$store.$genseeLive
  }

  get UserStore() {
    return this.$store.$storeUser
  }

  chooseAtItemHandler(item: AtItem) {
    this.PageStore.toggleAtSelect(item)
    this.onClose()
  }

  @Emit('close')
  onClose() {}

  created() {}
  mounted() {}
  destroyed() {}
}
