




















































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import LazyImg from '@/components/lazyImg/index.vue'
import lodash from 'lodash'

@Component({
  components: {
    LazyImg
  }
})
export default class BMYX extends Vue {
  @Prop() dataSource
  data() {
    return {
      upBtnHover: false,
      nextBtnHover: false
    }
  }
  get swiper() {
    return (this.$refs.swiper as any)[0].$swiper
  }
  onSwiper(key) {
    lodash.result(this.swiper, key)
  }
  swiperOptions = {
    loop: true,
    autoplay: {
      disableOnInteraction: false
    },
    speed: 2500,
    delay: 3000,
    grabCursor: true, //贴心抓手
    roundLengths: true,
    //allowTouchMove: true,
    autoHeight: true
  }
}
