




















import { Component, Emit, Prop, Mixins } from 'vue-property-decorator'
import { OrderDetails } from '@xt/client/mixins'
import { Observer } from 'mobx-vue'

@Observer
@Component({
  components: {}
})
export default class OrderDescPopup extends Mixins(OrderDetails) {
  @Prop() title: string
  @Prop({}) visible: boolean

  @Emit('hide')
  hidePopup() {}

  async created() {
    document.body.style.height = '100%'
    document.body.style.overflow = 'hidden'
  }

  destroyed() {
    document.body.style.height = 'auto'
    document.body.style.overflow = 'auto'
  }
}
