import md5 from 'crypto-js/md5'
import $global from '@xt/client/store/global'

function cryptoKey(name: string): string {
  return $global.DEPLOY_ENV === 'pro' ? md5(name).toString() : name
}

/**
 * 一些自定义的storage key
 */
export const StorageKeys = {
  /**
   * pc端上一次生成的设备指纹
   */
  LastDevIdPC: cryptoKey('__old__pc__devid__'),
  /**
   * h5端上一次生成的设备指纹
   */
  LastDevIdH5: cryptoKey('__old__h5__devid__'),
  /**
   * pc端上一次生成的喧桐指纹
   */
  LastXTDevIdPC: cryptoKey('__old__pc__xtdevid__'),
  /**
   * h5端上一次生成的喧桐指纹
   */
  LastXTDevIdH5: cryptoKey('__old__h5__xtdevid__'),
  /**
   * pc端上一次登录用户的memberId
   */
  LastMemberIdPC: cryptoKey('__old__pc__user__'),
  /**
   * pc端上一次登录用户的memberId
   */
  LastMemberIdH5: cryptoKey('__old__h5__user__'),
  /**
   * 设备用户追踪
   */
  TraceId: cryptoKey('__trace__'),
  /**
   * 去上课页面用户点击了不再提示按钮
   */
  UserIgnoredToMiniprogramPop: 'toclass-ignored-to-miniprogram-pop',
  /**
   * 当前端最新的版本号
   */
  LastestVersion: cryptoKey('__lastest__version__'),
  /**
   * 页面刷新前的本地版本号
   */
  BeforeReloadClientVersion: cryptoKey('__before__reload__client__version__'),
  /**
   * 页面刷新完毕版本还没有升级上来
   */
  PageVersionRefreshFailed: cryptoKey('__page__version__refresh__failed'),
  /**
   * 账号警告弹窗
   */
  AccountAlertModal: 'account-alert-id'
}
