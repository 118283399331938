import CryptoJS from 'crypto-js'
import $global from '@xt/client/store/global'

export function loadScript(src: string): Promise<boolean> {
  const now = Date.now()
  return new Promise(resolve => {
    const script = document.createElement('script')
    script.src = src + `?t=${now}`

    script.onload = () => {
      resolve(true)
    }

    script.onerror = () => {
      resolve(false)
    }

    document.body.appendChild(script)
  })
}

export async function chainLoadScript(srcList: string[]) {
  for (const src of srcList) {
    const isSuccess = await loadScript(src)
    if (!isSuccess) return false
  }

  return true
}

export type GenseeLinkSearch = {
  courseId: string
  isGray: boolean
  k: string
  nickname: string
  sdk: string
  uid: string
  wxx?: boolean
  gensee: string
  live: boolean
}

const parse = (value: string) => {
  if (['true', 'false'].includes(value)) {
    return value === 'true'
  }

  return value
}

export function parseGenseeLink<T extends Record<string, string | boolean>>(p: string, type: 'live' | 'playback'): T | null {
  if (!p) return null

  try {
    const result: Record<string, string | boolean> = {}
    const url = new URL(p)

    url.searchParams.forEach((value, key) => {
      if (key === 'sdk') {
        const word = CryptoJS.enc.Base64.parse(value)
        result[key] = word.toString(CryptoJS.enc.Utf8)
      } else {
        result[key] = parse(value)
      }
    })

    const genseeLink = new URL(url.pathname, url.origin)
    genseeLink.searchParams.set('k', result['k'] as string)
    genseeLink.searchParams.set('uid', result['uid'] as string)
    genseeLink.searchParams.set('nickname', result['nickname'] as string)
    if (result['wxx']) {
      genseeLink.searchParams.set('wxx', `${result['wxx']}`)
    }
    result['gensee'] = genseeLink.toString()

    return result as T
  } catch (e) {
    return null
  }
}
