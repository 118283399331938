import { render, staticRenderFns } from "./classhour.vue?vue&type=template&id=bc37c814&scoped=true&"
import script from "./classhour.vue?vue&type=script&lang=ts&"
export * from "./classhour.vue?vue&type=script&lang=ts&"
import style0 from "./classhour.vue?vue&type=style&index=0&lang=less&"
import style1 from "./classhour.vue?vue&type=style&index=1&id=bc37c814&lang=less&scoped=true&"
import style2 from "./classhour.vue?vue&type=style&index=2&lang=less&dec=%E4%B8%89%E9%98%B6&"
import style3 from "./classhour.vue?vue&type=style&index=3&lang=less&dec=%E4%BA%94%E9%98%B6&"
import style4 from "./classhour.vue?vue&type=style&index=4&lang=less&dec=%E5%8D%81%E9%98%B6&"
import style5 from "./classhour.vue?vue&type=style&index=5&lang=less&dec=%E4%BA%8C%E5%8D%81%E9%98%B6&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc37c814",
  null
  
)

export default component.exports