
































import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import { dataSource_stationery } from '@xt/client'

@Observer
@Component({
  name: 'PageStationery',
  // 每次进入页面都会调用
  async fetch(ctx) {
    // ctx.store.$storeStationery.onGetTypelist();
  },
  components: {}
})
export default class PageView extends Vue {
  typelist = dataSource_stationery
  keyword = ''
  name = ''
  get refreshKey() {
    return this.activeKey + this.name
  }
  get PageStore() {
    return this.$store.$storeStationery
  }
  get Pagination() {
    return this.$store.$storeStationery.Pagination
  }
  get body() {
    return {
      name: this.name,
      type: lodash.get(this.typelist, `[${this.activeKey}].key`, 0).toString()
    }
  }
  defaultActiveKey = '0'
  activeKey = lodash.get(this.$route.query, 'active', this.defaultActiveKey)
  getItems(typelist) {
    return lodash.map(typelist, item => {
      return { title: item.value, key: item.key }
    })
  }

  async tabsChange(activeKey) {
    this.activeKey = activeKey
    // this.Pagination.onReset({ infinite: true });
  }
  onSearch() {
    this.name = this.keyword
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
