import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { AjaxBasics } from '@xt/client'
import Product from '@xt/client/entities/basics/product'
import { EnumGroupStatus, EnumShowStatus } from '@xt/client/enums'
import lodash from 'lodash'
import { toJS } from 'mobx'
import moment from 'moment'

// 下单方式，内部应用不对外暴漏
enum EnumsPayType {
  Empty = 0,
  Give = 1,
  Self = 2,
  Group = 3
}

@Observer
@Component
export default class CommodityShowMiXins extends Vue {
  @Prop({}) dataSource: any & { product: Product }
  // 待支付订单的选择弹窗
  checkPopupData: any = {}
  visibleCheckPopup: boolean = false

  // 倒计时
  downtimeInterval: NodeJS.Timer
  countdownText: string = String()

  // 0 不显示协议 1 赠课 2 自己购买 3 团购
  visibleAgreement: number = EnumsPayType.Empty
  visibleAgreementPopup: boolean = false

  // 如果用户从参团转团长，需要传递订单编号，这里在用户操作满足以上条件时赋值
  attendGroupOrderNo: string
  // 控制是否是待支付订单（具体字段不知道啥意思）
  payOrigin: boolean = false

  showStatus: string = ''

  loading: boolean = false // 防止用户重复点击赠课｜ 自己报 ｜ 团购按钮

  //浮条倒计时
  statusText: string[] = []
  GroupExpire: boolean = false
  downTimer: NodeJS.Timer
  courseStyle: string = ''
  isEmpty(data) {
    return lodash.isEmpty(toJS(data))
  }
  get fixedBtnClass() {
    if (this.isZeroPrice) return 'xt-present-sign xt-giving-not-exist'
    return 'xt-present-sign'
  }
  /** 按钮的禁用样式 */
  get courseBtnDisabled() {
    return ((this.showIsSignUpEnd || this.showIsEnd || this.showIsInExhibit) && '-disabled') || ''
  }
  get isMobile() {
    return this.$store.$global.platform === 'Mobile'
  }

  /** 利用倒计时进行状态过渡 */
  downtime(signupEnd: number) {
    let wholeDay = 24 * 60 * 60 * 1000
    let diffText: string = ''
    let diffTextGroup: string = ''
    this.downTimer = setInterval(() => {
      let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf() // 当前时间
      let groupSignupEnd = signupEnd - wholeDay // 拼团报名截止时间
      let diff = signupEnd - currentTime // 正常报时间差
      let diffGroup = groupSignupEnd - currentTime // 拼团报时间差
      // 如果是可以拼团的展览
      if (this.canGroup) {
        switch (true) {
          case diffGroup < 0:
            this.GroupExpire = false
            diffTextGroup = `${this.isMobile ? '拉手团' : ''}活动已结束`
            break
          case diffGroup < 24 * 60 * 60 * 1000: // 如果小于24小时显示倒计时
            this.GroupExpire = true
            let time =
              (this.isMobile && moment(diffGroup).utcOffset(0).format('{HH}:{mm}:{ss}')) ||
              moment(diffGroup).utcOffset(0).format('HH:mm:ss')
            diffTextGroup = `${this.isMobile ? '拉手团' : ''}活动剩余 ${time}`
            break
          default:
            this.GroupExpire = true
            diffTextGroup =
              (this.isMobile && `拉手团活动剩余 {${this.computeDay(currentTime, groupSignupEnd)}}天`) ||
              `活动剩余 ${this.computeDay(currentTime, groupSignupEnd)}天`
        }
      }
      switch (true) {
        case diff < 0: // 如果正常报名结束了，就停止定时器
          clearInterval(this.downTimer)
          this.downTimer = null
          diffText = ''
          diffTextGroup = ''
          this.btn()
          break
        case diff < 24 * 60 * 60 * 1000: // 如果小于24小时显示倒计时
          diffText =
            (this.isMobile &&
              `报名剩余：${moment(signupEnd - currentTime)
                .utcOffset(0)
                .format('{HH}:{mm}:{ss}')}`) ||
            `报名剩余：${moment(signupEnd - currentTime)
              .utcOffset(0)
              .format('HH:mm:ss')}`
          break
        default: // 大于24小时  H5展示天  PC展示报名截至xxxx.xx.xx
          if (!this.canGroup)
            diffText =
              (this.isMobile && `报名剩余 {${this.computeDay(currentTime, signupEnd)}}天`) ||
              `报名剩余 ${this.computeDay(currentTime, signupEnd)}天`
          else diffText = `报名截止至：${moment(signupEnd).format('YYYY.MM.DD')}`
      }
      this.statusText = [diffTextGroup, diffText]
    }, 200)
  }

  initDownTime() {
    const { signupStartTime, signupEndTime } = this.dataSource
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    switch (true) {
      // 未开启报名 | 即将报名
      case this.isWaitSignUp && this.ExhibitionCanSignUp:
        return (this.statusText = ['', `报名时间：${moment(signupStartTime).format('YYYY.MM.DD')}`])
      // 正在报名（不管是有没有团购  不管是有没有24个小时  都开启倒计时）
      case this.isSignUp:
        return this.downtime(signupEndTime)
      default:
        return (this.statusText = ['', ''])
    }
  }

  /**
   * 活动的二进制字符串
   */
  get activityType(): string {
    return this.dataSource.product.origin.activityType.toString(2)
  }

  /**
   * 活动的二进制字符串
   *
   * 最后一位是 "1" 则开启了团购活动
   */
  get canGroup(): boolean {
    return this.activityType ? !!parseInt(this.activityType[this.activityType.length - 1]) : false
  }

  get WechatBowser() {
    return this.$store.$global.WechatBowser
  }

  // 平台
  get platform() {
    return this.$store.$global.platform
  }

  get Mobile() {
    return this.platform === 'Mobile'
  }

  get isSignUp() {
    return this.showStatus === EnumShowStatus.SignUp
  }

  get isWaitSignUp() {
    return this.showStatus === EnumShowStatus.WaitSignUp
  }

  get ExhibitionCanSignUp() {
    return this.dataSource.product.origin.canSignUp
  }

  get exhibitionhasOwn() {
    return this.dataSource.product.origin.hasOwn
  }

  get isVotingProgress() {
    let currentTime = this.$ajax.serviceDate().valueOf()
    let { voteStartTime, voteEndTime } = this.dataSource
    return voteStartTime < currentTime && currentTime < voteEndTime
  }

  get showIsInExhibit() {
    return this.showStatus === EnumShowStatus.CarryShow
  }

  get showIsEnd() {
    return this.showStatus === EnumShowStatus.CarryEnd
  }

  get showIsVoting() {
    return this.showStatus === EnumShowStatus.Voteing
  }

  get showEnd() {
    return this.showStatus === EnumShowStatus.WatchShow
  }

  get showIsSignUpEnd() {
    return this.showStatus === EnumShowStatus.SignUpEnd
  }

  get isSignUpEnd() {
    return (
      this.showStatus === EnumShowStatus.SignUpEnd ||
      this.showStatus === EnumShowStatus.CarryShow ||
      this.showStatus === EnumShowStatus.CarryEnd ||
      this.showStatus === EnumShowStatus.Voteing ||
      this.showStatus === EnumShowStatus.WatchShow
    )
  }

  get canGive() {
    return this.isSignUp && !this.isZeroPrice
  }

  /**
   * 购买方式是赠课
   */
  get payTypeIsGive() {
    return this.visibleAgreement === EnumsPayType.Give
  }

  /**
   * 购买方式是团购
   */
  get payTypeIsGroup() {
    return this.visibleAgreement === EnumsPayType.Group
  }

  get agreementTitle() {
    switch (this.visibleAgreement) {
      case EnumsPayType.Empty:
        return ''
      case EnumsPayType.Give:
        return '赠课前请了解'
      default:
        return '报名前请了解'
    }
  }

  get userIsSignUp(): boolean {
    let currentTime = this.$ajax.serviceDate().valueOf()
    let { voteStartTime, voteEndTime } = this.dataSource
    // return this.dataSource.signUp && !((currentTime > voteStartTime) && (currentTime < voteEndTime))
    return this.dataSource.signUp && currentTime < voteStartTime
  }

  //是否是0元单
  get isZeroPrice() {
    return !this.dataSource.product.origin.originalPrice
  }

  //是否展示拼团按钮
  get showGroupBtn() {
    return this.isSignUp && this.dataSource.product.isOpenGroup && !this.isZeroPrice
  }

  //是否拉手状态
  get isWaitShake() {
    //根据用户信息是否够买了团购，和当前的课程做对比
    let CourseGroupHasbuy = this.$store.$storeUser.CourseGroupHasbuy
    //已购此商品，并且未成功
    let res = CourseGroupHasbuy.filter(
      item =>
        item.productType == this.dataSource.product.origin.productType &&
        item.bizId == this.dataSource.product.origin.bizId &&
        item.status == EnumGroupStatus.Wait
    )
    return res.length ? res[0].groupNo : false
  }

  btn() {
    let currentTime = this.$ajax.serviceDate().valueOf()
    switch (true) {
      // 如果报名未开启或者报名开始时间大于当前时间则状态为即将报名
      case !this.dataSource.signStatus || this.dataSource.signupStartTime > currentTime:
        this.courseStyle = '-light-red'
        this.showStatus = EnumShowStatus.WaitSignUp
        return
      // 报名开始时间小于等于当前时间并且报名结束时间大于当前时间则状态为正在报名
      case this.dataSource.signupStartTime <= currentTime && this.dataSource.signupEndTime >= currentTime:
        this.courseStyle = '-light-red-hover'
        this.showStatus = EnumShowStatus.SignUp
        return
      // 如果报名结束小于等于当前时间 & 备展开始时间大于当前时间  则状态为报名结束
      case this.dataSource.signupEndTime < currentTime && this.dataSource.readyStartTime > currentTime:
        this.courseStyle = '-light-red'
        this.showStatus = EnumShowStatus.SignUpEnd
        return
      // 如果当前时间在备展开始时间与备展结束时间中  则状态为备展中
      case this.dataSource.readyStartTime <= currentTime && this.dataSource.readyEndTime >= currentTime:
        this.courseStyle = '-green'
        this.showStatus = EnumShowStatus.CarryShow
        return
      case !this.dataSource.voteStatus || (this.dataSource.readyEndTime < currentTime && this.dataSource.voteStartTime > currentTime):
        this.courseStyle = '-grey'
        this.showStatus = EnumShowStatus.CarryEnd
        return
      case this.dataSource.voteStartTime <= currentTime && this.dataSource.voteEndTime >= currentTime:
        this.courseStyle = '-deep-red-hover'
        this.showStatus = EnumShowStatus.Voteing
        return
      case this.dataSource.voteEndTime < currentTime:
        this.courseStyle = '-brown-hover'
        this.showStatus = EnumShowStatus.WatchShow
        return
      default:
        this.courseStyle = '-light-red'
        this.showStatus = EnumShowStatus.WaitSignUp
        return
    }
  }

  // 已报名去备展
  goReadyShow() {
    if (this.Mobile) {
      if (!this.WechatBowser) {
        return this.$msg('请在微信内操作')
      }
    }
  }

  async onSignUp(give: boolean = false, group: boolean = false) {
    if (this.loading) return
    this.loading = true
    this.attendGroupOrderNo = String()
    try {
      // 检测用户是否登录
      this.$InspectUser()
      // 检验是否需要完善用户信息
      this.onCheckDevice()
      // 如果该用户不能报名，弹Toast提示
      if (!this.dataSource.canSignUp && !give) {
        this.$msg('未报名指定课程，无法参加展览', 'warning')
        this.loading = false
        return
      }
      // 如果是赠课那么到下面IF代码块结束
      if (give) {
        // 展示赠课协议
        this.visibleAgreement = EnumsPayType.Give
        this.visibleAgreementPopup = true
        this.loading = false
        return
      }
      let res: any = await this.$store.$storeOrder.checkBeforeOrder(this.dataSource.product.origin.id, group)
      if (res.oldOrder && res.oldOrderNo) {
        this.popOrderChoose(res, group)
        throw new Error('Waiting For Payment')
      }
      this.visibleAgreement = group ? EnumsPayType.Group : EnumsPayType.Self
      this.visibleAgreementPopup = true
    } catch (err) {
      console.log(err)
      this.visibleAgreement = EnumsPayType.Empty
      this.visibleAgreementPopup = false
    }
    this.loading = false
  }

  async singleBuyCancel(res: any, group: boolean) {
    this.payOrigin = false
    await this.$store.$storeOrder.onOrderCancel({ orderNo: res.oldOrderNo })
    this.visibleAgreement = group ? EnumsPayType.Group : EnumsPayType.Self
    this.visibleAgreementPopup = true
  }

  /**
   * 跳转团购页面
   */
  gotoGroupBuy() {
    this.$router.push({
      name: 'groupbuy-groupNo',
      params: {
        groupNo: this.isWaitShake
      }
    })
  }

  /**
   * 单独购买 继续支付
   * **/
  singleBuyContinue(res: any) {
    this.payOrigin = true
    let query = {
      group: String(false), //因为是单独购买
      orderNo: String(res.oldOrderNo),
      productId: this.dataSource.product.origin.id,
      give: res.buyWay == 1 ? String(false) : String(true),
      memberBeforeOption: String(this.payOrigin)
    }
    this.toPayment(query)
  }

  /**
   * 已发起批团待支付，继续支付
   * **/
  async hasGroupOrderSingleContinue(oldOrderNo: string) {
    this.payOrigin = true
    let query = {
      group: String(true),
      orderNo: String(oldOrderNo),
      productId: this.dataSource.product.origin.id,
      memberBeforeOption: String(this.payOrigin)
    }
    this.toPayment(query)
  }

  /**
   * 已发起批团待支付，取消原订单继续支付
   * **/
  async hasGroupOrderSingleCancel(oldOrderNo: string, group: boolean) {
    this.payOrigin = false
    await this.$store.$storeOrder.onOrderCancel({ orderNo: oldOrderNo })
    this.visibleAgreement = group ? EnumsPayType.Group : EnumsPayType.Self
    this.visibleAgreementPopup = true
  }

  popOrderChoose(res: any, group: boolean) {
    switch (true) {
      case !new Product({ activityType: res.activityType } as any).isOpenGroup: // 单独购买
        this.checkPopupData = {
          title: `你已点过【${(this.canGroup && '一人学') || '立即报名'}】啦，但还没有支付`,
          waitPrice: res.payAmount,
          count: res.virtualCurrency / 100 / 8,
          ratio: 8,
          mainText: group ? '取消订单，重新报名' : '继续支付', //右侧按钮
          viceText: group ? '继续支付' : '取消订单，重新报名',
          mainHeader: '',
          mainHandler: () => {
            this.visibleCheckPopup = false
            group ? this.singleBuyCancel(res, group) : this.singleBuyContinue(res)
          },
          viceHandler: async () => {
            this.visibleCheckPopup = false
            group ? this.singleBuyContinue(res) : this.singleBuyCancel(res, group)
          }
        }
        this.visibleCheckPopup = true
        break
      case !!res.groupNo: // 接受了好友的拼团
        this.checkPopupData = {
          mainHeader: res.groupMemberHeaderUrl,
          title: '你已接受好友的【拉手团】邀请，但还没有支付',
          waitPrice: res.payAmount,
          count: res.virtualCurrency / 100 / 8,
          ratio: 8,
          mainText: '继续支付',
          viceText: '取消订单，重新报名',
          mainHandler: () => {
            this.visibleCheckPopup = false
            this.payOrigin = true
            //跳转支付页面,支付
            let query = {
              group: String(true),
              orderNo: String(res.oldOrderNo),
              productId: this.dataSource.product.origin.id,
              memberBeforeOption: String(this.payOrigin)
            }
            this.toPayment(query)
          },
          viceHandler: async () => {
            this.payOrigin = false
            this.visibleCheckPopup = false
            //想单独购买，则取消订单
            if (!group) await this.singleBuyCancel(res, group)
            else {
              this.visibleAgreement = EnumsPayType.Group
              this.visibleAgreementPopup = true
              this.attendGroupOrderNo = res.oldOrderNo
            }
          }
        }
        this.visibleCheckPopup = true
        break
      default: // 自己创建了拼团待支付
        this.checkPopupData = {
          title: '你已发起【拉手团】啦，但还没有支付',
          waitPrice: res.payAmount,
          count: res.virtualCurrency / 100 / 8,
          ratio: 8,
          mainText: group ? '取消订单，重新报名' : '继续支付',
          viceText: group ? '继续支付' : '取消订单，重新报名',
          mainHeader: '',
          viceHandler: async () => {
            this.visibleCheckPopup = false
            group ? this.hasGroupOrderSingleContinue(res.oldOrderNo) : this.hasGroupOrderSingleCancel(res.oldOrderNo, group)
          },
          mainHandler: () => {
            this.visibleCheckPopup = false
            group ? this.hasGroupOrderSingleCancel(res.oldOrderNo, group) : this.hasGroupOrderSingleContinue(res.oldOrderNo)
          }
        }
        this.visibleCheckPopup = true
    }
  }

  onAgreementCancel() {
    this.visibleAgreement = EnumsPayType.Empty
    this.visibleAgreementPopup = false
  }

  /**
   * 跳转支付页面
   */
  toPayment(query) {
    if (this.$store.$global.isEmbeddedH5Page) {
      const querySplice = this.$router.resolve({ query }).href.split('?')[1]
      this.onAgreementCancel()
      wx.miniProgram.navigateTo({
        url: `/pages/payment/index?${querySplice}`
      })
      return
    }
    // 微信内没有wxopenid id
    if (this.$store.$global.WechatBowser && this.Mobile && !this.$store.$storeUser.wxOpenid) {
      // 获取 code 换取 openid
      window.location.replace(
        this.$store.$wechat.getAuthorizeUrl(window.location.origin + this.$router.resolve({ name: 'payment', query }).href, 'snsapi_base')
      )
      return false
    }
    this.$router.push({
      name: 'payment',
      query
    })
  }

  /**
   * 协议弹出框跳转支付页面
   */
  agreementToPayment() {
    let query = {
      give: String(this.payTypeIsGive),
      group: String(this.payTypeIsGroup),
      orderNo: String(this.attendGroupOrderNo) || undefined,
      productId: this.dataSource.product.origin.id,
      memberBeforeOption: this.payOrigin ? 'true' : 'false'
    }
    this.toPayment(query)
  }

  /**
   * 检测用户完善个人信息
   */
  onCheckDevice() {
    if (this.$store.$storeUser.needBindDevice) {
      if (this.platform === 'Mobile') this.$router.push({ name: 'other-bind' })
      else this.$store.$storeUser.onToggleModal(this.$EnumLocaleLinks.links_bind_device)
      throw new Error('Need Bind Device')
    }
    //   用户需要完善个人信息
    if (this.$store.$storeUser.onneedCompleteInfo()) {
      this.$store.$storeUser.onToggleModal(this.$EnumLocaleLinks.links_complete)
      throw new Error('Need Complete Info')
    }
  }

  computeDay(currentTime, endtime) {
    return Math.floor(this.moment(endtime).diff(currentTime, 'hour') / 24)
  }

  mounted() {
    this.btn()
    this.initDownTime()
  }
}
