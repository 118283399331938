









































import { Component, Prop, Vue, Ref, Inject, Watch, Emit } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { EnumVerifyState, VerfiyWXMetaType } from '@xt/client/entities'
import { AjaxBasics } from '@xt/client/helpers'
import QRCode from 'qrcode'
import { getDeviceInfo } from '@xt/client/utils/device'
import { getFp } from '@xt/client/utils/fingerprint/report'

enum EnumQRCodeState {
  /** 待扫描 */
  No_Scan = 0,
  /** 已失效 */
  Have_Expire = 2,
  /** 已完成 */
  Have_Finish = 4
}

@Observer
@Component
export default class PageView extends Vue {
  @Prop({ default: false }) visible: boolean
  @Prop({ default: () => {} }) meta: VerfiyWXMetaType

  loading: boolean = false
  canvasHeight: number = 174
  canvasWidth: number = 174
  qrcodeUrl: string = ''
  scanState: number = EnumQRCodeState.No_Scan
  errorQRcode: boolean = false
  verifyState: number = EnumVerifyState.NoVerify

  get PageStore() {
    return this.$store.$storeUser
  }
  get EnumQRCodeState() {
    return EnumQRCodeState
  }
  get EnumVerifyState() {
    return EnumVerifyState
  }
  get verifyTip() {
    return this.verifyState === EnumVerifyState.NoVerify
      ? this.meta.scanTip
      : this.verifyState === EnumVerifyState.PassVerify
        ? '验证通过啦，与扫码微信一致'
        : '验证失败啦，与扫码微信不一致'
  }
  oldTime: number = Date.now()
  boundTime: number = 5 * 60 * 1000
  deadBoundTime: number = (5 * 60 + 10) * 1000
  timer = null

  onSkip() {
    this.meta.skipHandler && this.meta.skipHandler()
  }

  onSignup() {
    this.meta.signupHandler && this.meta.signupHandler()
  }

  onChangeWechat() {
    this.PageStore.hideWeiXinVerify()
    this.$router.replace({
      name: 'my-security'
    })
  }

  loadImage(url: string) {
    return new Promise(resolve => {
      let img: any = new Image()
      img.setAttribute('crossOrigin', 'anonymous')
      img.src = url
      img.onload = () => resolve(img)
      img.onerror = () => resolve(undefined)
    })
  }

  async pollingVerifyState(uuid: string) {
    if (!uuid) return
    let currentTime = Date.now()
    let diff: number = currentTime - this.oldTime
    try {
      let res: any = await this.PageStore.onPollingQrcodeState(uuid)
      if (!res && diff > this.boundTime) {
        this.scanState = EnumQRCodeState.Have_Expire
        clearTimeout(this.timer)
        this.timer = null
      }
      switch (res?.qrCodeStatus) {
        case EnumQRCodeState.No_Scan:
          // 超出边界展示失效界面
          if (diff > this.boundTime) {
            this.scanState = EnumQRCodeState.Have_Expire
          }
          // 超出死边界  释放
          if (diff > this.deadBoundTime) {
            clearTimeout(this.timer)
            this.timer = null
            return
          }
          break
        case EnumQRCodeState.Have_Finish:
          clearTimeout(this.timer)
          this.timer = null
          if (typeof res.unionIdEquals === 'boolean') {
            this.verifyState = res.unionIdEquals ? EnumVerifyState.PassVerify : EnumVerifyState.FailVerify
            if (res.unionIdEquals) {
              const currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
              this.$store.$storeUser.setWXCheckTime(currentTime)
            }
          }
          return
        default:
          // 未知异常
          return
      }
      this.timer = setTimeout(() => {
        this.pollingVerifyState(uuid)
      }, 2000)
    } catch (error) {
      clearTimeout(this.timer)
      this.timer = null
      this.errorQRcode = true
    }
  }

  resetScan() {
    clearTimeout(this.timer)
    this.timer = null
    this.errorQRcode = false
    this.scanState = EnumQRCodeState.No_Scan
    this.oldTime = Date.now()
    this.init()
  }

  async init() {
    if (this.loading) return
    this.loading = true
    this.errorQRcode = false
    this.scanState = EnumQRCodeState.No_Scan
    const deviceInfo = await getDeviceInfo()
    const {
      extra: { canvas, webgl, audio },
      current
    } = await getFp(this.$store.$global)
    let uuid: any = await this.PageStore.onGetLoginQrcode({
      ...deviceInfo,
      canvas,
      webgl,
      audio,
      xtDevId: current
    })
    this.oldTime = AjaxBasics.serviceDate.valueOf()
    if (!uuid) this.errorQRcode = true
    this.$nextTick(async () => {
      let canvas: any = this.$refs['canvas']
      let ctx = canvas.getContext('2d')
      let dpr = window.devicePixelRatio || 1
      const time = 2
      canvas.width = this.canvasWidth * time * dpr
      canvas.height = this.canvasHeight * time * dpr
      QRCode.toCanvas(
        canvas,
        `${process.env.hubBaseUrl}/hub/uc/landing/index.html?redirectPath=${encodeURIComponent(
          `/pages/index/index?action=verifyWechat&qrcodeId=${encodeURIComponent(uuid)}`
        )}`,
        {
          width: canvas.width,
          height: canvas.height,
          color: {
            dark: '#000000',
            light: '#FAF9F7'
          }
        },
        function (error) {
          if (error) console.error(error)
        }
      )
      // @ts-ignore
      let logo: any = await this.loadImage(this.$images.xt_qrcode_logo)
      if (logo) ctx.drawImage(logo, canvas.width / 2 - 30 * dpr, canvas.height / 2 - 30 * dpr, 30 * 2 * dpr, 30 * 2 * dpr)
      let dataUrl = canvas.toDataURL('image/png')
      this.qrcodeUrl = dataUrl
      this.loading = false
      await this.pollingVerifyState(uuid)
    })
  }

  @Watch('visible')
  changeVisible(value: boolean) {
    if (value) {
      this.init()
    } else {
      this.verifyState = EnumVerifyState.NoVerify
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
    }
  }

  @Emit('close')
  onCancel() {
    this.meta.closeHandler && this.meta.closeHandler()
  }

  async created() {}
}
