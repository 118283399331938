









































import { Component, Prop, Vue, Provide, Mixins } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import { MixinsHomeworkTask } from '@xt/client/mixins'

@Observer
@Component({
  scrollToTop: true,
  components: {}
})
export default class PageView extends Mixins(MixinsHomeworkTask) {
  get Pagination() {
    return this.PageStore.MyHomeworkList
  }
  get body() {
    return {
      typeKey: this.activeKey
    }
  }
  tabPane = [
    { key: 1, title: '已评阅' },
    { key: 0, title: '已提交' }
  ]
  defaultActiveKey = 1
  activeKey = lodash.get(this.$route.query, 'active', this.defaultActiveKey)

  tabsChange(activeKey) {
    this.activeKey = activeKey
  }

  created() {}
  beforeDestroy() {
    this.Pagination.onReset()
  }
}
