






































































































































































































































import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
// import RenderItem from "./views/item.vue";
import { MixinsMyPoints } from '@xt/client/mixins'
import lodash from 'lodash'
import { FormModel } from 'ant-design-vue'
import BindUser from '../staticComponents/bindUser.vue'
import NoneAccount from '../staticComponents/fail.vue'
import Success from '../staticComponents/success.vue'
import moment from 'moment'
import LazyImg from '@/components/lazyImg/index.vue'
let exchange_var_this

@Observer
@Component({
  // scrollToTop: true,
  // components: { BindUser,NoneAccount,Success,MySwiper }
  components: { BindUser, NoneAccount, Success, LazyImg },
  validate({ query }) {
    return /^\d+$/.test(query.id as string)
  }
})
export default class PageView extends Vue {
  @Ref('ruleForm')
  ruleForm: FormModel
  nickName: string = ''
  price: any = ''
  mobile: any = ''
  photo: any = ''
  useBiShan: number = 0
  moneyLit: number = 0
  moneyBig: number = 0
  expireTime: any = ''
  mode = 'top'

  yzUserId: any = ''
  youzanDetail: any = null
  quanId = this.$route.query.id
  dataSource = {}
  bigImages = []
  scrollLeft = 0
  isSwiperButtonPrevEnd = true
  isSwiperButtonNextEnd = false
  swiperMainActive = 0
  introduceImages = []
  myBiShan = 0
  deadline = 60
  isVisible = false //是否可以显示
  exchangeResult = 0 //兑换的结果

  swiperOption = {
    // loop: false,
    loopedSlides: 4, // looped slides should be the same
    spaceBetween: 10,
    watchSlidesVisibility: true /*避免出现bug*/,
    slideToClickedSlide: true,
    navigation: {
      // nextEl: '.swiper-button-next',
      // prevEl: '.swiper-button-prev'
    },
    on: {
      slideChange: function () {
        // exchange_var_this.$refs['swiperThumbs'].$swiper.slideTo(this.activeIndex,0 ,true);
        exchange_var_this.swiperMainActive = this.activeIndex
      }
    }
  }

  swiperOptionThumbs = {
    loop: false,
    loopedSlides: 4, // looped slides should be the same
    spaceBetween: 5,
    // centeredSlides: true,
    // centeredSlidesBounds: true,
    slidesPerView: 'auto',
    touchRatio: 0.2,
    slideToClickedSlide: true,
    watchSlidesVisibility: true, //防止不可点击
    on: {
      click: function (e) {
        exchange_var_this.$refs['swiperMain'].$swiper.slideTo(this.clickedIndex)
      }
    }
    // spaceBetween: 5, //在slide之间设置距离（单位px）
    // slidesPerView: 4, //设置slider容器能够同时显示的slides数量
    // freeMode: true, //默认为false，普通模式：slide滑动时只滑动一格
    // loopedSlides: 4, //一般设置大于可视slide个数2个即可
    // watchSlidesVisibility: true, //开启watchSlidesVisibility选项前需要先开启watchSlidesProgress
    // watchSlidesProgress: true,
  }
  get disabled() {
    return !this.$Regulars.mobilePhone.test(this.model.phone) || this.model.confirmCode == '' || this.model.confirmCode.length != 4
  }
  //验证所需要的
  model = {
    phone: '',
    confirmCode: ''
  }
  formRules = {
    phone: [
      {
        validator: async (rule, value, callback) => {
          if (value == '') {
            return callback(new Error('请输入手机号'))
          }
          if (!this.$Regulars.mobilePhone.test(value)) {
            return callback(new Error('请输入正确的手机号'))
          }
          return callback()
        },
        trigger: 'blur'
      }
    ],
    confirmCode: [
      {
        required: true,
        message: '请输入验证码',
        trigger: 'blur'
      },
      { min: 4, max: 4, message: '请输入正确的验证码', trigger: 'blur' }
    ]
  }
  phone = ''
  codeDisabled = true
  isStartCountDown = false
  validDevice = true //发送验证码是否需要验证设备

  /**
   * 是否满足兑换条件
   */
  async isExchange() {
    try {
      await this.$store.$storeTicket.isExchange(this.$route.query.id, this.PageStore.UserInfo.phoneNum)
      return true
    } catch (err) {
      return false
    }
  }

  //
  cancelModel() {
    this.exchangeResult = 0
  }
  okModel() {}
  // bindMobile(){
  //   this.$router.push('/my/security');
  // }
  closeFail() {
    this.exchangeResult = 0
  }
  numberInput(target) {
    target.value = target.value.replace(/[^\d]/g, '')
  }
  tabsChange(e) {
    console.log(e)
  }
  goToPoints() {
    this.$router.push({
      name: 'my-points'
    })
  }
  //关闭成功弹窗
  closeSuccess() {
    this.exchangeResult = 0
  }
  /**
   * 是获取有赞信息
   */
  async getYouzanDetail() {
    //检查是否在有赞有账号
    let res = this.$store.$storeTicket.checkYouzan(this.model.phone, this.$route.query.id)
    //有账户
    res.then(res => {
      if (res == false) {
        this.$msg('验证码错误')
      }
      //如果没有账户
      if (res && (res['yzOpenId'] == '' || res['yzOpenId'] == null)) {
        setTimeout(() => {
          this.exchangeResult = 1
        }, 800)
        return
      }
      this.youzanDetail = res
      this.photo = res['avatar']
      this.nickName = res['userNickname']
      this.mobile = res['userPhone']
      this.yzUserId = res['yzOpenId']
      this.exchangeResult = 2
    })
    // this.isVisible = false;
    this.closeMadel()
  }
  //提交
  async submitForm() {
    let canExg = await this.isExchange()
    if (!canExg) {
      return
    }
    this.ruleForm.validate((vaild, errList) => {
      if (vaild) {
        let res = this.$store.$storeTicket.checkPhoneCode({ device: this.model.phone, verifyCode: this.model.confirmCode })
        res.then(res => this.getYouzanDetail()) //如果校验成功，则请求详细信息
        res.catch(res => {
          if (res === false) {
            this.$msg('验证码错误')
          }
        })
      } else {
        let keys = lodash.keys(errList)
        let error = lodash.get(errList, [lodash.head(keys), 0, 'message'], '校验失败')
        return this.$msg(error)
      }
    })
  }
  async getDatasource() {
    //请求数据
    let dataSource = await this.$store.$storeTicket.getTicketDetail(this.quanId)
    // this.dataSour.name = dataSour.productName;
    this.dataSource = dataSource

    this.expireTime = moment(dataSource['expireTime']).format('YYYY.MM.DD HH:mm')
    this.price = dataSource['productPrice']
    this.photo = dataSource['avatar']
    this.moneyBig = dataSource['condition']
    this.moneyLit = dataSource['denominations']

    let bigImages = []
    let introduceImages = []
    if (this.dataSource['pictureResultVO']) {
      this.dataSource['pictureResultVO'].forEach(element => {
        if (element.pictureType == 1) {
          bigImages.push(element)
        } else if (element.pictureType == 2) {
          introduceImages.push(element)
        }
      })
      this.bigImages = bigImages
      this.introduceImages = introduceImages
    }
  }
  //发送验证码
  async onSendSms(model, callback) {
    if (this.validDevice) {
      this.ruleForm.validateField('phone', async error => {
        if (error) return this.$msg(error)

        this.onGetCode()
      })
    } else {
      this.onGetCode()
    }
  }
  async onGetCode() {
    try {
      if (this.model.phone) {
        let res = await this.$store.$storeTicket.sendMsgByPhone<boolean | { errCode: number }>(this.model.phone)

        if (typeof res !== 'boolean' && 'errCode' in res) {
          if (res.errCode !== 1191100006 /** 验证码已发送，请查收 在Request层直接弹出来了，业务不弹 */) {
            this.$msg('获取验证码失败')
          }

          return
        }

        if (res) {
          this.toggleCountDown(true)
          this.$msg('获取验证码成功', 'success')
        }
      }
    } catch (error) {
      if (lodash.eq(error, false)) {
        this.$msg(this.$EnumMessage.check_code_error)
      }
    }
  }
  get PageStore() {
    // 获取全局状态
    return this.$store.$storeUser
  }
  //是否可以购买
  get canBuyState() {
    //库存不足
    if (lodash.toInteger(this.dataSource['stockQty']) <= 0) {
      return 1
    }
    //可以购买
    else if (lodash.toInteger(this.dataSource['productPrice']) <= lodash.toInteger(this.myBiShan)) {
      return 2
    }
    //笔山不足
    else if (lodash.toInteger(this.dataSource['productPrice']) > lodash.toInteger(this.myBiShan)) {
      return 3
    }
  }
  /**
   * 切换倒计时开始/结束
   */
  toggleCountDown(loading: boolean = !this.isStartCountDown) {
    if (loading) {
      this.deadline = Date.now() + 1000 * 60
    }
    this.isStartCountDown = loading
  }
  /**
   * 是否满足兑换条件
   */
  get canExchange() {
    this.updateBSAndDetail()
    if (lodash.toInteger(this.dataSource['productPrice']) > lodash.toInteger(this.myBiShan)) {
      this.$msg('笔山不足')
      return false
    }
    if (lodash.toInteger(this.dataSource['stockQty']) <= 0) {
      this.$msg('库存不足')
      return false
    }
    return true
  }
  //立即兑换
  onExchanging = false
  async onExchange() {
    if (this.onExchanging) {
      return
    }
    this.onExchanging = true
    let res = this.$store.$storeTicket.exchangeTicket(this.youzanDetail.userPhone, this.quanId, this.yzUserId, this.photo)
    res
      .then(res => {
        setTimeout(async () => {
          this.exchangeResult = 3
          this.onExchanging = false
          await this.$store.$storeUser.onGetUserInfo()
          this.myBiShan = this.PageStore.UserInfo.bishans
        }, 800)
      })
      .catch(err => {
        this.$msg(err.errMsg)
        this.onExchanging = false
        //  this.exchangeResult = 1;
      })
  }
  //关闭弹窗
  closeMadel() {
    this.model = {
      phone: '',
      confirmCode: ''
    }
    this.deadline = 60
    this.isVisible = false
    this.isStartCountDown = false
    this.ruleForm.resetFields()
  }
  async showModel() {
    let canExg = await this.isExchange()

    if (!canExg) {
      return
    }

    this.isVisible = true
  }
  handleOk() {}
  /**
   * 更新库存和笔山
   */
  updateBSAndDetail() {
    this.$store.$storeUser.onGetBishans()
    this.getDatasource()
  }

  /* 修改swiper */
  changeSwiper(i) {
    this.swiperMainActive = i
    // this.$refs.swiperMain.$swiper.slideTo(i);
    this.$refs.swiperMain['$swiper'].slideTo(i)
  }
  /* 点击向右 */
  changeSwiperThumbPrev() {
    if (this.bigImages.length > 4) {
      this.isSwiperButtonNextEnd = false
      if (this.scrollLeft < 0) {
        this.scrollLeft = this.scrollLeft + 100
        if (this.scrollLeft == 0) {
          this.isSwiperButtonPrevEnd = true
        }
      }
    }
  }

  /* 点击向左 */
  changeSwiperThumbNext() {
    if (this.bigImages.length > 4) {
      this.isSwiperButtonPrevEnd = false
      let itemLength = this.bigImages.length
      let maxMult = Math.floor(itemLength / 4) //最大滚动1倍
      let nowMult = this.scrollLeft / 100
      if (maxMult > Math.abs(nowMult)) {
        this.scrollLeft = this.scrollLeft - 100
        if (maxMult == Math.abs(this.scrollLeft / 100)) {
          this.isSwiperButtonNextEnd = true
        }
      }
    }
  }

  created() {
    this.getDatasource()
    exchange_var_this = this
    this.myBiShan = this.PageStore.UserInfo.bishans
  }
  mounted() {}
}
