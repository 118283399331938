import '@xt/client/prototype'
import { message } from 'ant-design-vue'
import Vue from 'vue'
import RootSrore, { ajax } from '~/store/create'
import images from './images'
import lodash from 'lodash'

// 状态 导出
export default () => {
  return RootSrore
}
Vue.prototype.$ajax = ajax
/**
 * 消息提示
 */
Vue.prototype.$msg = function (msg, type: any = 'warning') {
  console.warn('LENG: Vue.prototype.$msg -> msg', msg, type)
  if (msg && !lodash.isObject(msg)) {
    message.open({ key: msg, content: this.$tc(msg), type })
  }
}

/**
 * 加载
 */
Vue.prototype.$loading = function (msg: string) {
  let key = Date.now()
  message.loading({ content: msg, key, duration: 60000 })
  return function (msg: string) {
    message.success({ content: msg, key, duration: 2 })
  }
}
/**
 * 像素转rem
 */
Vue.prototype.$px2rem = function (px: number) {
  return px / 16 + 'rem'
}
// 扩展
Vue.prototype.$images = images
/**
 * 设置 面包屑
 */
Vue.prototype.$setBreadcrumb = RootSrore.$menu.setBreadcrumb
declare module 'vue/types/vue' {
  interface Vue {
    /** 设置面包屑 */
    readonly $setBreadcrumb: typeof RootSrore.$menu.setBreadcrumb
    /** APi 枚举 */
    readonly $images: typeof images
    readonly $px2rem: (px: number) => string
  }
}
