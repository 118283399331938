




























































import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
// import RenderItem from "./views/item.vue";
import { MixinsMyPoints } from '@xt/client/mixins'
import lodash from 'lodash'
import { Overlay } from 'vant'
import { Form } from 'vant'
import { Button } from 'vant'
import moment from 'moment'

@Observer
@Component({
  scrollToTop: true
  //   components: { RenderItem }
})
export default class PageView extends MixinsMyPoints {
  isVisible = true
  tickets = null
  phone = ''

  model = {
    phone: '',
    password: '',
    confirmCode: '',
    newPassword: ''
  }

  showAlert: true

  confirmLoading: true

  rules = getRules.call(this)

  get getVisible() {
    return this.isVisible
  }

  get label() {
    return this.phone ? '+86' : '邮箱'
  }
  get placeholder() {
    return this.phone ? '请输入手机号' : '请输入邮箱'
  }
  @Emit('submit')
  onSubmit() {
    return this.model
  }

  @Emit('failed')
  // 校验不通过
  onFailed(errorInfo) {
    console.log('Clin -> file: verifyForm.vue -> line 200 -> PageView -> onFailed -> errorInfo', errorInfo, errorInfo.errors)

    return lodash.get(errorInfo, ['errors', '0', 'message'], '')
  }

  numberInput(target) {
    target.value = lodash.replace(target.value, /[^\d]/g, '')
  }
  closeMadel() {
    this.isVisible = false
  }
  showModel() {
    this.isVisible = true
  }
  handleOk() {}

  tabsChange(e) {
    console.log(e)
  }
  goToPoints() {
    this.$router.push({
      name: 'my-points'
    })
  }
  /**
   * 是否登录
   */
  get loggedIn() {
    return this.$store.$storeUser.loggedIn
  }
  get Pagination() {
    let work = this.$store.$storeTicket.MyTicketWork
    work['_dataSource'].map(item => {
      item.resultStatus = 2
    })
    return work
    return this.$store.$storeTicket.MyTicketWork
  }
  async created() {
    if (this.loggedIn) {
      this.tickets = await this.$store.$storeTicket.MyTicketWork.onLoading()
    }
  }

  mounted() {}
}
function getRules() {
  const validatePhone = async (value, rule) => {
    if (value == '') {
      lodash.set(rule, 'message', '请输入手机号')
      return false
    }
    if (!this.$Regulars.mobilePhone.test(value)) {
      lodash.set(rule, 'message', '请输入正确的手机号')
      return false
    }
    return true
  }

  return {
    phone: [
      {
        validator: this.phone,
        trigger: 'onBlur'
      }
    ],
    confirmCode: [
      {
        required: true,
        message: '请输入验证码',
        trigger: 'onBlur'
      },
      { pattern: /^\d{4}$/, message: '请输入正确的验证码', trigger: 'onBlur' }
    ]
  }
}
