



































































































































































































































import GroupBuy from './script'
import Teleport from '../components/teleport.vue'
import Popup from '../components/groupbuy/groupPopup/groupPopup.vue'
import { Component } from 'vue-property-decorator'
import Poster from '../components/groupbuy/poster/poster.vue'
import SignedAlert from '../components/groupbuy/signedAlert/signedAlert.vue'
import UserGroup from '../components/groupbuy/userGroup/userGroup.vue'
import Wxbind from '../components/groupbuy/wxbind/wxbind.vue'
import Agreement from '../components/groupbuy/agreement/agreement.vue'
import CollagePopup from '../components/groupbuy/collagePopup/collagePopup.vue'
import SharePopup from '../components/groupbuy/sharePopup/sharePopup.vue'

@Component({
  name: 'GroupBuyComp',
  components: { Teleport, SharePopup, UserGroup, Popup, Poster, SignedAlert, Wxbind, Agreement, CollagePopup }
})
export default class GroupBuyComp extends GroupBuy {}
