import { render, staticRenderFns } from "./allCourse.vue?vue&type=template&id=286fd8f2&scoped=true&"
import script from "./allCourse.vue?vue&type=script&lang=js&"
export * from "./allCourse.vue?vue&type=script&lang=js&"
import style0 from "./allCourse.vue?vue&type=style&index=0&id=286fd8f2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "286fd8f2",
  null
  
)

export default component.exports