import { Observer } from 'mobx-vue'
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'

@Observer
@Component
export default class showPopupScript extends Vue {
  @Prop({}) visible: boolean
  @Prop({}) imgUrl: string

  @Emit('onCancel')
  onCancel() {}
}
