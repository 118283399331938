import { BindAll } from 'lodash-decorators'
import lodash from 'lodash'
import { EnumApiHomework } from '../../api'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { Pagination } from '../basics/pagination'
import { EntitiesBasics } from '../basics/entities'
import { EnumMessage } from '../../languages'
import { toJS } from 'mobx'

/**
 * 作业详情
 */
@BindAll()
export class HomeworkDetails extends EntitiesBasics<any> {
  constructor(protected $ajax: AjaxBasics) {
    super($ajax, {
      url: EnumApiHomework.HomeworkDetail
    })
  }

  DetailComment = new Pagination(this.$ajax, {
    url: EnumApiHomework.HomeworkComment
  })

  onPlusCommentCount() {
    this.onUpdate(old => {
      old.reviewCount += 1

      return old
    })
  }

  /**
   * 点赞
   */
  async onHomeworkLike(id, userInfo) {
    const resp = await this.$ajax.put(EnumApiHomework.HomeworkLike, { id })

    if (resp === true) {
      const updater = old => {
        old.liked = true
        old.likeCount += 1
        if (!old.likeRecordDOS) {
          old.likeRecordDOS = []
        }

        old.likeRecordDOS.push({
          memberNickname: userInfo.nickname,
          memberId: userInfo.id,
          id: null,
          createTime: null,
          homeworkId: old.id
        })

        return old
      }
      this.onUpdate(updater)
    }
  }

  async onToggleHomeworkVisible(homeworkId: number, visibleRange: number) {
    const resp = await this.$ajax.put(EnumApiHomework.HomeworkVisible, {
      id: homeworkId,
      visibleRange
    })

    if (resp) {
      const updater = old => {
        old.visibleRange = visibleRange

        return old
      }

      this.onUpdate(updater)
    }
  }

  async onDeleteComment(comment) {
    const resp = await this.$ajax.delete(EnumApiHomework.HomeworkCommentDelete, {
      id: comment.id
    })

    if (resp === true) {
      this.DetailComment.onRemove(comment)
      this.onUpdate(old => {
        old.reviewCount -= 1

        return old
      })
    }
  }

  async onComment(options: { homeworkId: string; toCommentId: string; replyContent: string }) {
    const resp = await this.$ajax.post<{ id: string }>(EnumApiHomework.HomeworkReply, {
      homeworkId: options.homeworkId,
      toCommentId: options.toCommentId,
      replyContent: options.replyContent,
      replyContentNum: options.replyContent.length
    })

    if (resp?.id) {
      this.DetailComment.onPush(resp)
    }
  }
}
export default HomeworkDetails
