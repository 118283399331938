





















import { Component, Prop, Vue } from 'vue-property-decorator'
import lodash from 'lodash'
import { Observer } from 'mobx-vue'
import { EnumLocaleLinks } from '@xt/client'
import tab_introduction from './tab_introduction.vue'
import tab_class from './tab_map/index.vue'
import tab_composition from './tab_composition.vue'
import tab_sunDrying from './tab_sunDrying.vue'
import tab_thoughts from './tab_thoughts.vue'
// @Observer
@Component({
  components: {
    [EnumLocaleLinks.links_courseIntroduction]: tab_introduction,
    [EnumLocaleLinks.links_courseComposition]: tab_composition,
    [EnumLocaleLinks.links_goToTheClass]: tab_class,
    [EnumLocaleLinks.links_sunDrying]: tab_sunDrying,
    [EnumLocaleLinks.links_thoughts]: tab_thoughts
  }
})
export default class PageView extends Vue {
  get PageStore() {
    return this.$store.$storeCourse.Details
  }
  get dataSource() {
    return this.PageStore.dataSource
  }
  get defaultActiveKey() {
    if (this.single) {
      // 已经购买课程默认展示去上课 未购买展示课程详情
      return this.dataSource.isHasOwn ? 'to' : 'ci'
    }
    return 'ci'
  }
  activeKey = lodash.get(this.$route.query, 'active', this.defaultActiveKey)
  keepAliveProps = {
    // include: [
    //   EnumLocaleLinks.links_courseIntroduction,
    //   EnumLocaleLinks.links_courseComposition,
    //   EnumLocaleLinks.links_goToTheClass,
    // ],
    exclude: [EnumLocaleLinks.links_thoughts]
  }
  get activeValue() {
    return lodash.get(lodash.find(this.tabPane, ['key', this.activeKey]), 'title', this.$EnumLocaleLinks.links_courseIntroduction)
  }
  /**
   * 单阶课程 true / 全阶 false
   */
  get single() {
    return this.dataSource.isSingle
    // return true;
  }
  get tabPane() {
    let tabs = [{ key: 'ci', title: this.$EnumLocaleLinks.links_courseIntroduction }]
    if (this.single) {
      tabs = lodash.concat(tabs, [
        { key: 'to', title: this.$EnumLocaleLinks.links_goToTheClass }, //去上课地图页
        { key: 'sd', title: this.$EnumLocaleLinks.links_sunDrying }, //晒作业
        { key: 'th', title: this.$EnumLocaleLinks.links_thoughts } //感想
      ])
    } else {
      tabs = lodash.concat(tabs, [
        { key: 'cc', title: this.$EnumLocaleLinks.links_courseComposition } //课程组成
      ])
    }
    return tabs
  }
  tabsChange(activeKey) {
    this.activeKey = activeKey
  }
  mounted() {}

  updated() {}
  destroyed() {}
}
