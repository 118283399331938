








import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import lodash from 'lodash'
import qrcode from 'qrcode'
@Component({
  components: {}
})
export default class extends Vue {
  @Prop({ default: 30 }) height
  @Prop({ default: '' }) background
  get shadowStyle() {
    return { height: `${this.height}px` }
  }
  get afterStyle() {
    return { background: this.background }
  }
  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
