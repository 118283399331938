





















































































import { Observer } from 'mobx-vue'
import { Component, Mixins, Vue } from 'vue-property-decorator'
import ViewLiveCourse from './views/liveCourse.vue'
import ViewCalendarDay from './views/calendarDay.vue'
import ViewRing from './views/ring.vue'
import { MixinsAllCalendar } from '@xt/client/mixins'

@Observer
@Component({
  name: 'calendar',
  components: {
    ViewLiveCourse,
    ViewCalendarDay,
    ViewRing
  },
  async asyncData(ctx) {
    // 读取远程JSON文件
    const res = await ctx.store.$ajax.get(
      `/mocks/studyCalendar.json`,
      {},
      { 'Cache-Control': 'no-store', 'If-Modified-Since': '0' },
      { target: ctx.store.$global.production ? 'https://static.xuantong.cn' : 'https://testing-static.xuantong.cn' }
    )
    //单元测试用本地JSON文件
    //const res = JSONDATA
    return {
      calendar: res
    }
  }
})
export default class Calendar extends Mixins(MixinsAllCalendar) {
  async created() {
    this.loading = true
    let res = await this.ClasshourPageStore.onCurrentExistLive()
    this.$store.$storeUser.updateLiveStatus(!!res)
    await this.onLoad()
  }
}
