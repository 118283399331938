




























import { Observer } from 'mobx-vue'
import { Component, Mixins } from 'vue-property-decorator'
import { MixinsMyAllCourse } from '@xt/client/mixins'
import ShowPopup from '../../../../../components/showPopup/showPopup.vue'
import LazyImg from '@/components/lazyImg/index.vue'

@Observer
@Component({
  components: {
    ShowPopup,
    LazyImg
  }
})
export default class Page extends Mixins(MixinsMyAllCourse) {
  showPopVisible: boolean = false

  get showUrl() {
    return 'https://file.xuantong.cn/images/origin/98558c34fce77c259460228f038a630f.jpeg'
  }

  jumpPage() {
    if (this.isCourse) {
      this.$router.push({
        name: 'course-id',
        params: { id: this.dataSource.bizId }
      })
    } else if (this.isDay) {
      this.$router.push({
        name: 'activitys-course-id',
        params: { id: this.dataSource.bizId }
      })
    } else {
      // this.showPopVisible = true
      this.$router.push({
        name: 'show-id',
        params: { id: this.dataSource.id }
      })
    }
  }
}
