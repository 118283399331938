

































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { MixinsRetrieve } from '@xt/client/mixins/index'
import lodash from 'lodash'
import ChangePassword from './changePassword.vue'
import ChangePhone from './changePhone.vue'
import ChangeEmail from './changeEmail.vue'

@Observer
@Component({
  components: { ChangePassword, ChangePhone, ChangeEmail }
})
export default class PageView extends Mixins(MixinsRetrieve) {
  @Prop({ default: '' }) verifyType // 判断跳转类型
  @Prop({ default: '请输入弹窗标题' }) skipWindowTitle // 弹窗标题
  isVerifySuccess: boolean = false
  confirmCode: string = ''
  model: any = {}
  get formOptions() {
    return {
      validDevice: this.validDevice,
      phone: this.phone,
      btnText: !this.deviceIsExist ? '绑定' : '下一步',
      label: this.label,
      defaultValue: this.defaultValue,
      rules: this.formRules,
      inputInnerText: '请输入四位验证码',
      layout: {
        labelCol: { span: 0 },
        wrapperCol: { span: 24 }
      },
      btnCol: { span: 24, offset: 0 }
    }
  }
  get getModel() {
    return this.model
  }
  async onNextStep(dataSource) {
    const { disabled, device, confirmCode } = dataSource
    if (!this.deviceIsExist) {
      try {
        if (this.verifyType === 'phone') {
          await this.PageStore.onBindPhone({
            phoneNum: device,
            verifyCode: confirmCode
          })
        } else {
          await this.PageStore.onBindEmail({
            email: device,
            verifyCode: confirmCode
          })
        }
        await this.PageStore.onGetUserInfo()
        this.$msg(this.$EnumMessage.bind_success, 'info')
        this.PageStore.onChangeBindVisible(false)
      } catch (error) {
        if (lodash.eq(error, false)) this.$msg(this.$EnumMessage.check_code_error)
      }
      return
    }
    if (this.verifyType === 'password') {
      try {
        await this.PageStore.onCheckCode(
          lodash.merge(
            { device, verifyCode: confirmCode },
            {
              verifyCodeType: this.PageStore.UserInfo.phoneNum ? 7 : 8
            }
          )
        )
        this.confirmCode = confirmCode
        !disabled && (this.isVerifySuccess = true)
      } catch (error) {
        if (lodash.eq(error, false)) this.$msg(this.$EnumMessage.check_code_error)
      }
    } else {
      try {
        await this.PageStore.onCheckCode(
          lodash.merge(
            { device, verifyCode: confirmCode },
            {
              verifyCodeType: this.judgmentSkipType ? 5 : 6
            }
          )
        )
        if (this.verifyType === 'phone') {
          this.model = {
            oldPhoneNum: this.PageStore.UserInfo.phoneNum,
            oldPhoneVerifyCode: confirmCode
          }
        } else {
          this.model = {
            oldEmail: this.PageStore.UserInfo.email,
            oldEmailVerifyCode: confirmCode
          }
        }
        !disabled && (this.isVerifySuccess = true)
      } catch (error) {
        if (lodash.eq(error, false)) this.$msg(this.$EnumMessage.check_code_error)
      }
    }
  }
  get outputTitle() {
    let inputTitle = ''
    let judgmentStatue = true
    if (this.verifyType === 'phone') {
      inputTitle = '手机号'
      judgmentStatue = this.PageStore.UserInfo.phoneNum
    }
    if (this.verifyType === 'email') {
      inputTitle = '邮箱'
      judgmentStatue = this.PageStore.UserInfo.email
    }
    return !this.isVerifySuccess ? (judgmentStatue ? '身份验证' : `绑定${inputTitle}`) : this.skipWindowTitle
  }
  get deviceIsExist() {
    if (this.verifyType === 'phone') {
      return this.PageStore.UserInfo.phoneNum
    } else if (this.verifyType === 'email') {
      return this.PageStore.UserInfo.email
    } else {
      return true
    }
  }
  get judgmentSkipType() {
    return this.phone
  }
  get onChoiceSendSms() {
    return this.verifyType === 'password' ? this.onSendSms : this.onMySendSms
  }
  isCurrentRights(type) {
    return type === this.verifyType
  }
  async onMySendSms(model, callback, closeLockCallback) {
    try {
      await this.PageStore.onGetCode({
        device: model.device,
        verifyCodeType: !this.deviceIsExist ? (this.judgmentSkipType ? 3 : 4) : this.judgmentSkipType ? 5 : 6
      })
      this.clickSendSms = true
      callback()
      closeLockCallback()
    } catch (error) {
      closeLockCallback()
      if (lodash.eq(error, false)) this.$msg(this.$EnumMessage.check_code_error)
    }
  }
  exchangeInitialData(type) {
    if (type === 'phoneNum') {
      this.showback = false
      this.phone = true
      this.validDevice = false
      this.defaultValue = this.PageStore.UserInfo.phoneNum
    } else {
      this.showback = false
      this.phone = false
      this.validDevice = false
      this.defaultValue = this.PageStore.UserInfo.email
    }
  }
  created() {
    let phone = lodash.get(this.PageStore.UserInfo, 'phoneNum')
    let email = lodash.get(this.PageStore.UserInfo, 'email')
    if (this.verifyType === 'phone') {
      this.exchangeInitialData('phoneNum')
      !this.PageStore.UserInfo.phoneNum && (this.validDevice = true)
    }
    if (this.verifyType === 'email') {
      this.exchangeInitialData('email')
      !this.PageStore.UserInfo.email && (this.validDevice = true)
    }
    if (this.verifyType === 'password') {
      if (email && !phone) {
        this.exchangeInitialData('email')
      } else {
        this.exchangeInitialData('phoneNum')
      }
    }
  }
}
