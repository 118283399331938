/*
 * @Author: mengxd
 * @CreateTime: 2021-7-21
 * @LastEditors: mengxd
 * @LastEditTime: 2021-7-21 14:11:09
 * @Description: 订单
 */
/**
 * 订单状态枚举
 * 订单状态（0未支付，1已支付，2退款中，3已退款，4已取消）
 */
export enum EnumBiShanType {}
