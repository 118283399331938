import { Observer } from 'mobx-vue'
import { Component } from 'vue-property-decorator'
import CommonOrderListScript from '@xt/client/mixins/order/list'

@Observer
@Component
export default class OrderListScript extends CommonOrderListScript {
  toTop() {
    document.documentElement.scrollTo({ top: 0 })
  }
  created() {
    this.toTop()
  }
  onRefresh() {
    this.toTop()
    this.Pagination.onReset({ infinite: true })
  }
}
