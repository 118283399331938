






















import { Observer } from 'mobx-vue'
import { Vue, Component } from 'vue-property-decorator'
import OrderItem from './views/item/item.vue'
import OrderListScript from './script'
import Teleport from '@xt/client/mixins/components/teleport.vue'

@Observer
@Component({
  components: {
    OrderItem, // 订单卡片
    Teleport
  }
})
export default class OrderListComp extends OrderListScript {}
