/**
 * @ Author: Mengcx
 * @ Create Time: 2022-11-22 14:31:51
 * @ Modified by: Mengcx
 * @ Modified time: 2022-11-23 14:43:37
 * @ Description:
 */

import { Observer } from 'mobx-vue'
import { Component, Mixins } from 'vue-property-decorator'
import ShowActiveMixins from '@xt/client/mixins/show/active'

@Observer
@Component
export default class ShowItemScript extends Mixins(ShowActiveMixins) {
  mounted() {
    this.init()
  }

  destroyed() {
    clearInterval(this.downTimer)
    this.downTimer = null
  }
}
