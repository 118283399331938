














import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
@Observer
@Component({
  components: {}
})
export default class extends Vue {
  get PageStore() {
    return this.$store.$menu
  }
  get UserStore() {
    return this.$store.$storeUser
  }
  get selectedkeys() {
    if (this.$route.name) {
      return [this.$route.name, ...this.PageStore.getSelectedkeys(this.$route)]
    }
  }
  get locale() {
    return this.$EnumLocaleLinks
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
