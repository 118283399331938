












import { Observer } from 'mobx-vue'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component
export default class PlaybackPopup extends Vue {
  @Prop({ default: false }) visible: boolean
  @Prop({ default: '' }) content: string
  @Prop({ default: false }) showCancelButton: boolean

  @Emit('confirm')
  confirmHandler() {}

  @Emit('cancel')
  cancelHandler() {}
}
