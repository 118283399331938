import { Bind, BindAll } from 'lodash-decorators'
import { action, observable, runInAction } from 'mobx'
import { OAuthError, OAuthErrorCode } from '@xt/client/entities/user/controller'
import $global from '@xt/client/store/global'
import { getDeviceInfo } from '@xt/client/utils/device'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { EnumApiUser } from '../../api'

function isObject(data: unknown): data is object {
  return typeof data === 'object' && data !== null
}

type ServerError = { errCode: number }

function isServerError(data: unknown): data is ServerError {
  return isObject(data) && 'errCode' in data && typeof (data as ServerError).errCode === 'number'
}

export type BindDeviceMeta = {
  /**
   * 错误码，对应不同的操作弹窗
   */
  errCode: OAuthErrorCode
  /**
   * 电话号码（电话号码和邮箱有其一）
   */
  phoneNum: string
  /**
   * 邮箱（电话号码和邮箱有其一）
   */
  email: string
  /**
   * memberId
   */
  memberId: string
  /**
   * 剩余的绑定次数
   */
  bindCount: number
  /**
   * tempToken
   */
  tempToken: string

  nickname: string

  /**
   * 脱敏后的帐后
   */
  account: string
}

export type BindedDeviceItem = {
  appId: string
  appName: string
  devBrowser: string
  devId: string
  devType: 1 | 2
  devWxAppletBrand: string
  devWxAppletModel: string
  id: number
  lastLoginTime: string
  memberId: number | string
}

type AuthForPCParams = {
  memberId: string
  refreshToken: string
  /**
   * uc小程序上的devId，谁扫的码就是谁。用来做小程序的关联
   */
  authDevId: string
}

type DeviceInfo = {
  title: string
  iconImage: string
  info: string
}

@BindAll()
export class ControllerDevice {
  constructor(protected $ajax: AjaxBasics) {
    this.initCurrentDeviceInfo()
  }

  @observable deviceInfo: DeviceInfo | null = null

  @observable showDeviceControlModal: boolean = false

  @observable bindDeviceMeta: BindDeviceMeta | null = null

  // pc扫码授权临时参数
  authForPCParams: null | AuthForPCParams = null
  // h5授权登录临时参数
  authForH5Params: null | AuthForPCParams = null

  @action initCurrentDeviceInfo() {
    getDeviceInfo().then(devInfo => {
      this.deviceInfo = {
        title: `${devInfo.devBrowser}浏览器`,
        iconImage:
          $global.platform === 'Mobile'
            ? `${$global.static}/images/my/device/manage_im_phone.png`
            : `${$global.static}/images/my/device/manage_im_computer.png`,
        info: `${devInfo.devSys}`.toLowerCase(),
      }
    })
  }

  @action setShowDeviceControlModal(isShow: boolean) {
    this.showDeviceControlModal = isShow

    if (!isShow) {
      this.bindDeviceMeta = null
    }
  }

  @action setBindDeviceMeta(meta: Omit<BindDeviceMeta, 'account'>) {
    let account = ''

    if (meta.phoneNum) {
      account = meta.phoneNum.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')
    } else if (meta.email) {
      account = meta.email.replace(/^(\w{2})(\w*)(@.*)$/, '$1***$3')
    }

    this.bindDeviceMeta = {
      ...meta,
      account
    }
  }

  @Bind
  async trustLogin() {
    const deviceInfo = await getDeviceInfo()
    const loginType = sessionStorage.getItem('last-login-type')
    const result = await this.$ajax
      .post<{ access_token: string } | ServerError>(EnumApiUser.DeviceBind, {
        trustedDeviceInfo: {
          ...deviceInfo,
          memberId: this.bindDeviceMeta.memberId,
          tempToken: this.bindDeviceMeta.tempToken,
          loginType
        }
      })
      .catch(e => {
        return e.response
      })

    if (isServerError(result) || !result.access_token) {
      return false
    }

    window.$nuxt.$store.$storeUser.setUserInfo(result)
    await window.$nuxt.$store.$storeUser.onGetUserInfo()
    return true
  }

  @Bind
  async tempLogin() {
    let tempLoginToken: { access_token: string } | ServerError = await this.$ajax
      .post<{ access_token: string } | ServerError>(
        `${EnumApiUser.Login}?scope={scope}&grant_type={grant_type}&member_id={member_id}&tempToken={tempToken}`,
        {
          scope: 'all',
          grant_type: 'device_temp_token',
          member_id: this.bindDeviceMeta.memberId,
          tempToken: this.bindDeviceMeta.tempToken
        },
        { 'x-authType': '4', Authorization: 'Basic dXNlci1jbGllbnQ6dXNlci1zZWNyZXQtODg4OA==' }
      )
      .catch(e => e.response)

    // 临时登录上报设备信息
    if (isServerError(tempLoginToken) && tempLoginToken.errCode === 201100013 /** TempBind */) {
      let deviceInfo = await getDeviceInfo()
      tempLoginToken = await this.$ajax
        .post<{ access_token: string }>(
          EnumApiUser.ShortcutDeviceBind,
          {
            deviceInfo: {
              ...deviceInfo,
              memberId: this.bindDeviceMeta.memberId
            },
            tempToken: this.bindDeviceMeta.tempToken
          },
          { 'x-authType': '4' }
        )
        .catch(e => e.response)
    }

    if (isServerError(tempLoginToken) || !tempLoginToken.access_token) {
      console.warn('失败了')
      return false
    }

    window.$nuxt.$store.$storeUser.setUserInfo(tempLoginToken)
    await window.$nuxt.$store.$storeUser.onGetUserInfo()
    return true
  }
}
