import { EnumLocaleLinks } from '../enumLocale'
export default {
  [EnumLocaleLinks.links_home]: '首页',
  [EnumLocaleLinks.links_course]: '课程',
  [EnumLocaleLinks.links_video]: '公开课',
  [EnumLocaleLinks.links_stationery]: '暄桐文房',
  [EnumLocaleLinks.links_about]: '关于暄桐',
  [EnumLocaleLinks.links_my]: '去上课',
  [EnumLocaleLinks.links_register]: '注册',
  [EnumLocaleLinks.links_signin]: '登录',
  [EnumLocaleLinks.links_wx_login]: '微信登录',
  [EnumLocaleLinks.links_phone_login]: '手机号登录',
  [EnumLocaleLinks.links_email_login]: '海外登录',
  [EnumLocaleLinks.links_bind_device]: '绑定设备',
  [EnumLocaleLinks.links_complete]: '完善个人信息',
  [EnumLocaleLinks.links_retrieve]: '找回密码',
  [EnumLocaleLinks.links_courseIntroduction]: '课程介绍',
  [EnumLocaleLinks.links_courseComposition]: '课程组成',
  [EnumLocaleLinks.links_goToTheClass]: '去上课',
  [EnumLocaleLinks.links_sunDrying]: '晒作业',
  [EnumLocaleLinks.links_thoughts]: '感想',
  [EnumLocaleLinks.links_payment]: '支付'
}
