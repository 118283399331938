import Bowser from 'bowser'
import lodash from 'lodash'
export default function () {
  const BrowserName = Bowser.getParser(window.navigator.userAgent).getBrowserName()
  const parseOS = Bowser.getParser(window.navigator.userAgent).parseOS()
  const collection = [Bowser.BROWSER_MAP.ie]
  // 只有 PC 校验
  // if (lodash.includes([Bowser.OS_MAP.Windows, Bowser.OS_MAP.MacOS], parseOS.name)) {
  //     collection.push(Bowser.BROWSER_MAP.wechat)
  // }
  return lodash.includes(collection, BrowserName)
}
