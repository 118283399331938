import $global from '@xt/client/store/global'
import { Context } from '@nuxt/types'
import { ArmsSumKeys, StorageKeys } from '@xt/client/enums'
import { satisfies } from 'compare-versions'
import { StorageWithExpiredTime } from './storage'

const cache = new StorageWithExpiredTime(sessionStorage)

const target = $global.production ? 'https://static.xuantong.cn' : 'https://testing-static.xuantong.cn'

function reportBehavior(key: string) {
  if (window.__bl) {
    console.warn(`reportBehavior ${key}`)
    window.__bl.sum(key)
  } else {
    document.addEventListener('armsloaded', () => {
      console.warn(`reportBehavior event ${key}`)
      window.__bl.sum(key)
    })
  }
}

type VersionConfig = {
  latest: string
  available_versions: Array<string>
  enable: boolean
  options: {
    version_file_request_expired: number
    page_can_reload_expired: number
    page_reload_but_version_not_match_expired: number
  }
}

/**
 * 当前客户端版本是否在可用版本范围中
 * @param clientVersion 当前客户端版本
 * @param rules semver规则集合
 * @see https://github.com/omichelsen/compare-versions?tab=readme-ov-file#version-ranges
 */
function isClientVersionMatch(clientVersion: string, rules: Array<string>) {
  return rules.some(rule => satisfies(clientVersion, rule))
}

// 用户版本过低，尝试原地刷新升级版本
export async function onUpgradeClientVersion(route: Context['route']) {
  const versionContent = cache.get<VersionConfig>(StorageKeys.LastestVersion)

  if (!versionContent) {
    const controller = new AbortController()
    const timer = setTimeout(() => {
      controller.abort()
    }, 600)
    controller.signal.onabort = () => {
      console.warn('500ms请求超时了')
      reportBehavior(ArmsSumKeys.FETCH_VERSION_FILE_TIMEOUT)
    }
    const result = await fetch(`${target}/mocks/versions.json?_t=${Date.now()}`, { method: 'get', signal: controller.signal })
    const version = (await result.json()) as { web: VersionConfig }
    clearTimeout(timer)

    if (!version?.web) {
      reportBehavior(ArmsSumKeys.FETCH_VERSION_FILE_FAILED)
      return
    }

    cache.set(StorageKeys.LastestVersion, version.web, version.web.options.version_file_request_expired * 1000)
  }

  const {
    enable,
    available_versions,
    options: { page_can_reload_expired, page_reload_but_version_not_match_expired }
  } = cache.get<VersionConfig>(StorageKeys.LastestVersion) || {}

  if (!enable) {
    console.warn('开关已关闭')
    return
  }

  const isPageRefreshFailed = cache.get(StorageKeys.PageVersionRefreshFailed)

  if (!isPageRefreshFailed) {
    const clientVersion = $global.version

    if (isClientVersionMatch(clientVersion, available_versions)) {
      // 太棒了 是可用版本
      return
    }

    const beforeRefreshedVersion = cache.get(StorageKeys.BeforeReloadClientVersion)

    if (beforeRefreshedVersion) {
      reportBehavior(
        ArmsSumKeys.RELOAD_UPGRADE_FAILED.replace('{from_version}', beforeRefreshedVersion).replace('{to_version}', $global.version)
      )

      cache.set(StorageKeys.PageVersionRefreshFailed, 1, page_reload_but_version_not_match_expired * 1000)
    } else {
      reportBehavior(ArmsSumKeys.CLIENT_VERSION_TOO_LOW.replace('{client_version}', clientVersion))

      cache.set(StorageKeys.BeforeReloadClientVersion, clientVersion, page_can_reload_expired * 1000)
      setTimeout(() => {
        const loc = new URL(route.fullPath, location.href).toString()
        window.location.replace(loc)
      }, 0)
    }
  }
}

export const giftCache = new StorageWithExpiredTime(localStorage)
export const giftExpiredTime = 24 * 60 * 60 * 1000
