
























































































import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'

import signin from './signin.vue'
import wxLogin from './wxLogin.vue'
import phoneLogin from './phoneLogin.vue'
import emailLogin from './emailLogin.vue'
import bindDevice from './bindDevice.vue'
import retrieve from './retrieve.vue'
import completeInfo from './completeInfo.vue'
import HeaderImage from '../../../components/headerImage/index.vue'

@Observer
@Component({
  components: {
    signin,
    wxLogin,
    phoneLogin,
    emailLogin,
    bindDevice,
    retrieve,
    completeInfo,
    HeaderImage
  }
})
export default class userModal extends Vue {
  title = ''
  get PageStore() {
    return this.$store.$storeUser
  }
  get locale() {
    return this.$EnumLocaleLinks
  }
  isSignin(title) {
    return lodash.includes([this.locale.links_signin, '', undefined], title)
  }
  isWxLogin(title) {
    return lodash.eq(this.locale.links_wx_login, title)
  }
  isPhoneLogin(title) {
    return lodash.eq(this.locale.links_phone_login, title)
  }
  isEmailLogin(title) {
    return lodash.eq(this.locale.links_email_login, title)
  }
  isBindDevice(title) {
    return lodash.eq(this.locale.links_bind_device, title)
  }
  isRetrieve(title) {
    return lodash.eq(this.locale.links_retrieve, title)
  }
  isComplete(title) {
    return lodash.eq(this.locale.links_complete, title)
  }
  getModalClass(isComplete) {
    return isComplete ? 'xt-complete-modal' : 'xt-login-modal'
  }

  menu = [
    {
      key: 'my-homework',
      name: '我的作业'
    },
    {
      key: 'my-order',
      name: '我的订单'
    },
    {
      key: 'my-showHomework',
      name: '我晒出的作业'
    }
  ]
  onVisible(visible, title?) {
    this.PageStore.onToggleVisible(visible)
    if (title) this.PageStore.onToggleModal(title)
  }
  async onOutLogin() {
    // let currentPath = lodash.get(this.$router, ["currentRoute", "fullPath"]);
    // if (!lodash.eq(currentPath, "/")) {
    //   this.$router.replace("/");
    // }
    await this.PageStore.onLogout()
    await this.PageStore.onOutLogin()
    window.location.replace(this.$router.resolve('/').href)
  }

  onOk() {
    this.onVisible(false)
  }
  onCancel() {
    this.onVisible(false)
  }

  onSubmit() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
