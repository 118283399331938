import { EnumInvoiceState, EnumOrderState, EnumGroupStatus } from '@xt/client/enums'
import { Observer } from 'mobx-vue'
import { Component, Emit } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'
import OrderItemScript from './order'

@Observer
@Component
export default class OrderBtnsScript extends OrderItemScript {
  /**
   * 是否待支付
   * **/
  get showTopayBtn() {
    return this.dataSource.isNotPay
  }

  get showRefundApply() {
    return (
      this.dataSource.orderStatus === EnumOrderState.HasPay &&
      !this.dataSource.isRefundEnd &&
      (!this.dataSource.isGive || !this.isMyGive) &&
      this.dataSource.origin.orderAmount !== 0 && //判断支付价格为0元
      this.dataSource.origin.refund
    )
  }

  // 6.30去掉ToBeConfirmed
  get showRefundCancel() {
    return (
      this.dataSource.orderStatus === EnumOrderState.Refund /** this.dataSource.orderStatus === EnumOrderState.ToBeConfirmed */ &&
      (!this.dataSource.isGive || !this.isMyGive)
    )
  }

  get showRefundSuccess() {
    return this.dataSource.orderStatus === EnumOrderState.HasRefund
  }

  /**
   * 是否待支付
   * **/
  get showInvoiceApply() {
    return (
      this.dataSource.orderStatus === EnumOrderState.HasPay &&
      (this.dataSource.invoiceState === EnumInvoiceState.NotApply || this.dataSource.invoiceState === EnumInvoiceState.Reject) &&
      (!this.dataSource.isGive || this.isMyGive) &&
      (!this.dataSource.productInfo.isOpenGroup || this.dataSource.origin.status === EnumGroupStatus.Success) &&
      !this.dataSource.zeroOrder
    )
  }

  get showInvoiceIng() {
    return (
      this.dataSource.orderStatus === EnumOrderState.HasPay &&
      this.dataSource.invoiceState === EnumInvoiceState.WaitInvoice &&
      (!this.dataSource.isGive || this.isMyGive) &&
      !this.dataSource.zeroOrder
    )
  }

  get ShowInvoiceSuccess() {
    return (
      this.dataSource.orderStatus === EnumOrderState.HasPay &&
      this.dataSource.invoiceState === EnumInvoiceState.HasInvoice &&
      (!this.dataSource.isGive || this.isMyGive) &&
      !this.dataSource.zeroOrder
    )
  }

  get isMobile() {
    return this.$store.$global.platform === 'Mobile'
  }

  async refund(platform: (res: any, callback: () => Promise<void>) => void) {
    // 检验积分积分补偿
    let checkRes: any = await this.$store.$storeOrder.checkRefund(this.orderNo)
    // 如果积分不足则返回校验信息
    platform(!checkRes.adequacy ? checkRes : true, async () => {
      try {
        await this.$store.$storeOrder.onOrderRefund(this.orderNo)
      } catch (_) {}
      await this.$store.$storeUser.onUpdatingUserInfo()
      this.onRefresh()
    })
  }

  async cancelRefund() {
    try {
      await this.$store.$storeOrder.onOrderCancelRefund({
        orderNo: this.orderNo,
        refundOrderSn: this.dataSource.origin.refundOrderSn
      })
    } catch (_) {}
    await this.$store.$storeUser.onUpdatingUserInfo()
    this.onRefresh()
  }

  applyInvoice() {
    this.$router.push({ name: 'my-invoice', query: { orderNo: this.orderNo } })
  }

  async cancelOrder() {
    try {
      await this.$store.$storeOrder.onOrderCancel({
        orderNo: this.orderNo
      })
      await this.$store.$storeUser.onUpdatingUserInfo()
      this.$msg(this.$EnumMessage.cancel_order_success, 'success')
      this.onRefresh()
    } catch (_) {}
  }

  toPayment() {
    let route: RawLocation = {
      name: 'payment',
      query: {
        orderNo: this.orderNo,
        productId: this.dataSource.origin.productId.toString(),
        give: this.dataSource.isGive.toString(),
        memberBeforeOption: true.toString(),
        group: this.dataSource.productInfo.isOpenGroup.toString()
      }
    }
    if (this.$store.$global.isEmbeddedH5Page) {
      wx.miniProgram.navigateTo({
        url: `/pages/payment/index?${Object.entries(route.query)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')}`
      })
      return
    }
    // 微信内 无 wxOpenid id
    if (this.$store.$global.WechatBowser && this.$store.$global.platform === 'Mobile' && !this.$store.$storeUser.wxOpenid) {
      // 获取 code 换取 openid
      window.location.replace(this.$store.$wechat.getAuthorizeUrl(window.location.origin + this.$router.resolve(route).href, 'snsapi_base'))
      return false
    }
    this.$router.push(route)
  }

  @Emit('refresh')
  onRefresh() {}
}
