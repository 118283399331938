/*
 * @Author: Erlin
 * @CreateTime: 2021-02-22 15:02:06
 * @LastEditors: Erlin
 * @LastEditTime: 2021-02-22 15:02:07
 * @Description: 找回密码
 * 打算抽成pc、h5共用，目前仅pc使用
 */
import { Component, Prop, Vue, Ref } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'

@Observer
@Component
export class MixinsRetrieve extends Vue {
  @Prop({ default: '' }) state
  @Ref('newPasswordRef') newPasswordRef
  @Ref('confirmNewPasswordRef') confirmNewPasswordRef
  validDevice = true
  title = '找回密码'
  defaultValue = ''
  phone = true
  showback = true
  model = {
    newPassword: '',
    confirmNewPassword: ''
  }
  formRules = getRules.call(this)
  clickSendSms: boolean = false
  get PageStore() {
    return this.$store.$storeUser
  }
  get locale() {
    return this.$EnumLocaleLinks
  }
  get formOptions() {
    return {
      validDevice: this.validDevice,
      phone: this.phone,
      btnText: '完成',
      label: this.label,
      defaultValue: this.defaultValue,
      rules: this.formRules
    }
  }
  get label() {
    return this.phone ? '手机号' : '邮箱'
  }
  created() {
    let state = this.state || this.PageStore.ModalState
    if (lodash.eq(state, 'phone')) {
      this.phone = true
      // this.title = "修改密码"
    }
    if (lodash.eq(state, 'email')) {
      this.phone = false
      // this.title = "修改密码"
    }
    // if (lodash.eq(state, "noBackPhone")) {
    //   this.showback = false
    //   this.phone = true
    //   this.title = "修改密码"
    //   this.validDevice = false
    //   this.defaultValue = this.PageStore.UserInfo.phoneNum
    // }
    // if (lodash.eq(state, "noBackEmail")) {
    //   this.showback = false
    //   this.phone = false
    //   this.title = "修改密码"
    //   this.validDevice = false
    //   this.defaultValue = this.PageStore.UserInfo.email
    // }
  }
  async onSendSms(model, callback, closeLockCallback) {
    try {
      if (this.phone) {
        await this.PageStore.onFindPwdByPhone(model.device)
      } else {
        await this.PageStore.onFindPwdByEmail(model.device)
      }
      this.clickSendSms = true
      callback()
      closeLockCallback()
    } catch (error) {
      closeLockCallback()
      if (lodash.eq(error, false)) this.$msg(this.$EnumMessage.check_code_error)
    }
  }
  async onSubmit(model) {
    try {
      if (this.phone) {
        await this.PageStore.onFindByPhone({
          phoneNum: model.device,
          verifyCode: model.verifyCode,
          newPassword: this.model.newPassword,
          confirmNewPassword: this.model.confirmNewPassword
        })
      } else {
        await this.PageStore.onFindByEmail({
          email: model.device,
          verifyCode: model.verifyCode,
          newPassword: this.model.newPassword,
          confirmNewPassword: this.model.confirmNewPassword
        })
      }
      // this.PageStore.onToggleVisible(false);
      if (this.showback) {
        // 找回密码切换到登录
        this.PageStore.onToggleModal(this.locale.links_signin)
      } else {
        // 修改密码去掉登录跳转到首页 打开登录框
        this.PageStore.onToggleVisible(false)
        await this.PageStore.onLogout()
        this.PageStore.onOutLogin()
        this.$router.replace('/', () => {
          this.PageStore.onToggleVisible(true)
        })
      }
      window.location.reload()
    } catch (error) {
      if (lodash.eq(error, false)) this.$msg(this.$EnumMessage.check_code_error)
    }
  }
}
function getRules() {
  let validatePassword = (rule, value, callback) => {
    let { field } = rule
    let fieldValue = lodash.get(this.model, field)
    if (fieldValue == '') {
      return callback(new Error('密码不能为空'))
    }
    if (/[\s]+/.test(fieldValue)) {
      return callback(new Error('密码不能包含空格'))
    }
    if (fieldValue.length > 16 || fieldValue.length < 8) {
      return callback(new Error('密码长度8-16位，须包含数字+字母'))
    }
    if (/^[\d!\(\)-\.?\[\]_`~;:!@#$%^&*+=]+$/i.test(fieldValue) || /^[a-zA-Z!\(\)-\.?\[\]_`~;:!@#$%^&*+=]+$/i.test(fieldValue)) {
      return callback(new Error('密码长度8-16位，须包含数字+字母'))
    }
    if (!this.$Regulars.password.test(fieldValue)) {
      return callback(new Error('请勿输入非法字符'))
    }
    if (field === 'confirmNewPassword') {
      if (fieldValue !== this.model.newPassword) {
        return callback(new Error('两次输入的密码不一致'))
      }
    }
    return callback()
  }
  return {
    newPassword: [{ validator: validatePassword, trigger: 'change' }],
    confirmNewPassword: [{ validator: validatePassword, trigger: 'change' }]
  }
}
