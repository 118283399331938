









import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'

@Observer
@Component({
  layout: 'clean',
  components: {},
  validate({ query }) {
    return /^\d+$/.test(query.id as string)
  }
})
export default class CourseAgreement extends Vue {
  agreement: any = {}

  get id() {
    return lodash.get(this.$route.query, 'id') as string
  }

  get PageStore() {
    return this.$store.$Product
  }

  async created() {
    this.agreement = await this.PageStore.getProductAgreementById(parseInt(this.id))
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
