




















































import { Component, Prop, Vue, Provide, Mixins } from 'vue-property-decorator'
import lodash from 'lodash'
import { MixinsCourseSignup } from '@xt/client/mixins'
import Agreement from './agreement.vue'
import { EnumCourseSignupType } from '@xt/client/enums'

@Component({
  components: { Agreement }
})
export default class PageView extends Mixins(MixinsCourseSignup) {
  get title() {
    return {
      [EnumCourseSignupType.Purchase]: '报名前请了解',
      [EnumCourseSignupType.Give]: '赠课前请了解',
      [EnumCourseSignupType.Gift]: '赠课前请了解'
    }[this.type]
  }
  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
