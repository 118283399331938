































import { Component } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import GroupBuyScript from './groupbuyScript'
import HeaderImage from '../../../../../../components/headerImage/index.vue'

@Observer
@Component({
  name: 'GroupBuyComp',
  components: { HeaderImage }
})
export default class GroupBuyComp extends GroupBuyScript {}
