/*
 * @Author: Erlin
 * @CreateTime: 2020-12-24 16:19:40
 * @LastEditors: Erlin
 * @LastEditTime: 2020-12-24 16:19:41
 * @Description: 修改密码
 */
import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator'
import { FormModel } from 'ant-design-vue'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'

@Observer
@Component
export class MixinsChangePassword extends Vue {
  @Ref('ruleForm') ruleForm: FormModel
  get PageStore() {
    return this.$store.$storeUser
  }
  get locale() {
    return this.$EnumLocaleLinks
  }
  model = {
    oldPassword: '', //旧密码
    newPassword: '', //新密码
    confirmNewPassword: '' //确认密码
  }
  rules = this.getRules()
  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 }
  }
  async onSubmit() {
    try {
      await this.ruleForm.validate()
      await this.PageStore.onUpdatePwd(this.model)
      this.$emit('reset')
      await this.PageStore.onLogout()
      await this.PageStore.onOutLogin()
      this.PageStore.onToggleVisible(true)
      this.$router.replace('/')
    } catch (error) {
      console.log('Clin -> file: changePassword.vue -> line 70 -> PageView -> onSubmit -> error', error)
    }
  }
  getRules() {
    let validatePassword = (rule, value, callback) => {
      let { field } = rule
      if (value == '') {
        return callback(new Error('密码不能为空'))
      }
      if (/[\s]+/.test(value)) {
        return callback(new Error('密码不能包含空格'))
      }
      if (value.length > 16 || value.length < 8) {
        return callback(new Error('密码长度8-16位，须包含数字+字母'))
      }
      if (!this.$Regulars.password.test(value)) {
        return callback(new Error('密码长度8-16位，须包含数字+字母'))
      }

      if (field === 'newPassword') {
        if (value === this.model.oldPassword) {
          return callback(new Error('新密码不能与原密码一致'))
        }
      }

      if (field === 'confirmNewPassword') {
        if (value !== this.model.newPassword) {
          return callback(new Error('两次输入的密码不一致'))
        }
      }
      return callback()
    }
    return {
      oldPassword: [{ validator: validatePassword, trigger: 'blur' }],
      newPassword: [{ validator: validatePassword, trigger: 'blur' }],
      confirmNewPassword: [{ validator: validatePassword, trigger: 'change' }]
    }
  }
}
