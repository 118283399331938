import md5 from 'crypto-js/md5'
import { StorageKeys } from '../../enums'
import { aesDecrypt, aesEncrypt } from './crypto'
import { reportEntryLog } from '@xt/client/utils/log'

export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    let r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function uuidWithTime() {
  return uuid() + '-' + Number(Date.now()).toString(32)
}

// 根据设备来维护xt_dev_id
export function getXTDevId(platform: 'PC' | 'Mobile') {
  const traceKey = platform === 'PC' ? StorageKeys.LastDevIdPC : StorageKeys.LastDevIdH5
  let _xtDevId = localStorage.getItem(traceKey)
  if (!_xtDevId) {
    const u = uuid()
    _xtDevId = aesEncrypt(
      md5(platform === 'PC' ? `${u}-p` : `${u}-m`)
        .toString()
        .toUpperCase()
    )
    localStorage.setItem(traceKey, _xtDevId)
  }

  try {
    const result = aesDecrypt(_xtDevId)
    if (!result) throw new Error()
    return result
  } catch (e) {
    // 可能被篡改
    localStorage.removeItem(traceKey)
    return getXTDevId(platform)
  }
}

export function setDevId(devId: string, platform: 'PC' | 'Mobile') {
  const traceKey = platform === 'PC' ? StorageKeys.LastDevIdPC : StorageKeys.LastDevIdH5

  // md5一下既能找回之前的设备，又可以满足当前的后端实现
  devId = md5(`${devId}${platform}`).toString().toUpperCase()

  const data = aesEncrypt(devId)

  console.log('即将更改devId', platform, localStorage.getItem(traceKey), devId)
  reportEntryLog('debug.devid.change', {
    platform,
    before: localStorage.getItem(traceKey),
    after: devId
  })

  localStorage.setItem(traceKey, data)
}

export function getTraceId() {
  let trace = localStorage.getItem(StorageKeys.TraceId)

  if (!trace) {
    trace = uuidWithTime()
    localStorage.setItem(StorageKeys.TraceId, trace)
  }

  return trace
}
