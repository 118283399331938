/**
 * @author mengxd
 * @email mengxd
 * @create date 2021-11-12 20:25:53
 * @desc 活动协议
 */
import { Component, Prop, Vue, Emit, Mixins } from 'vue-property-decorator'
import lodash from 'lodash'
import { MixinsCourseHelpers } from './helpers'
import { EnumCourseSignupType } from '../../enums'
import { MixinsBasicsHelpers } from '../basics/helpers'

@Component
export class MixinsActivityAgreement extends Mixins(MixinsBasicsHelpers) {
  /** 课程数据 */
  @Prop({ default: () => ({}) }) dataSource: any
  @Prop({ default: EnumCourseSignupType.Purchase }) type
  @Prop({ default: false }) group
  @Prop({ default: false }) payOrigin
  @Prop({ default: '' }) oldOrderNo: any //老的订单号
  @Prop({ default: '' }) groupNo: any //团购的单号
  deadline = this.moment().add(5, 'seconds').valueOf()
  countdown = true
  checked = false
  visible = false

  get disabled() {
    return !this.checked || this.countdown
  }

  get PageStore() {
    return this.$store.$storeCourse
  }
  get UserStore() {
    return this.$store.$storeUser
  }

  onFinish() {
    this.countdown = false
  }
  // 协议内容
  agreement = {
    id: 0,
    courseId: 0,
    title: '',
    promptSelf: '',
    promptGift: '',
    content: ''
  }
  get title() {
    return {
      [EnumCourseSignupType.Purchase]: '报名前请了解',
      [EnumCourseSignupType.Give]: '赠课前请了解',
      [EnumCourseSignupType.Gift]: '赠课前请了解',
      [EnumCourseSignupType.groupBuy]: '报名前请了解'
    }[this.type]
    // return this.dataSource.title;
    // return this.agreement.title;
  }
  get content() {
    return {
      [EnumCourseSignupType.Purchase]: this.agreement.promptSelf,
      [EnumCourseSignupType.groupBuy]: this.agreement.promptSelf,
      [EnumCourseSignupType.Give]: this.agreement.promptGift,
      [EnumCourseSignupType.Gift]: this.agreement.promptGift
    }[this.type]
    // return  this.dataSource.promptSelf;
    // return this.agreement.content;
  }
  get welcome() {
    // let giveWelcome = "在Ta拆开课程礼物之后，可以前往课程页面【开课前要准备什么】中查看以上信息哦～"
    const purchaseWelcome = '祝您在暄桐教室学习开心~'
    return {
      [EnumCourseSignupType.Purchase]: purchaseWelcome,
      [EnumCourseSignupType.groupBuy]: purchaseWelcome,
      [EnumCourseSignupType.Give]: purchaseWelcome,
      [EnumCourseSignupType.Gift]: purchaseWelcome
    }[this.type]
  }
  toPayment() {
    let query = Object.assign(
      { productId: lodash.get(this.dataSource, 'productInfo.id') },
      {
        give: lodash.includes([EnumCourseSignupType.Give, EnumCourseSignupType.Gift], this.type).toString()
      },
      { group: this.group ? this.group.toString() : 'false' },
      this.groupNo ? { groupNo: this.groupNo } : {},
      this.oldOrderNo ? { orderNo: this.oldOrderNo } : {},
      { memberBeforeOption: this.payOrigin == true ? 'true' : 'false' }
    )
    // {
    //   productId: lodash.get(this.dataSource, "productInfo.id"),
    //   give : lodash.includes([EnumCourseSignupType.Give, EnumCourseSignupType.Gift], this.type).toString(),
    //   group : this.group.toString(),
    //   groupNo : this.dataSource.productInfo.groupNo,
    //   orderNo : this.dataSource.orderNo,
    //   memberBeforeOption : this.payOrigin == true ? "true" : "false"
    // }
    this.$router.push({
      name: 'payment',
      query
    })
  }
  async onLoad() {
    const res = await this.PageStore.onGetCourseProtos(this.dataSource.productInfo.id)
    this.agreement = res
  }
  @Emit('cancel')
  onCancel() {
    return true
  }
}
