





















import Order from '@xt/client/entities/basics/order'
import { Observer } from 'mobx-vue'
import { Component, Vue, Emit, Prop, Mixins } from 'vue-property-decorator'
import ViewUserGroup from '../views/userGroup.vue'

@Observer
@Component({
  components: {
    ViewUserGroup
  }
})
export default class ItemPage extends Vue {
  @Prop({}) dataSource: any

  copyValue() {
    const disappear = 'position: fixed;z-index: -2;top: -100px'
    let input = document.createElement('input')
    input.value = this.dataSource.value
    input.setAttribute('style', disappear)
    document.body.appendChild(input)
    input.focus()
    input.select()
    if (document.execCommand('copy', false, null)) {
      //success info
      this.$msg('复制成功', 'success')
    } else {
      this.$msg('请手动复制订单编号')
    }
    document.body.removeChild(input)
  }

  created() {}
}
