export function gotoPractice({
  url: _url,
  showId,
  worksId,
  tokens
}: {
  url?: string
  showId?: number | string
  worksId?: number | string
  tokens?: any
}) {
  const url = new URL(_url || `${window.location.origin}/part/practice`)
  url.searchParams.append('source', 'xt_web_h5')
  url.searchParams.append('t', `${Date.now()}`)

  if (!_url) {
    if (!showId || !worksId) {
      throw new Error('arguments showId and worksId required')
    }

    ;[
      { k: 'showId', v: `${showId}`.trim() },
      { k: 'worksId', v: `${worksId}`.trim() }
    ].forEach(({ k, v }) => {
      url.searchParams.append(k, v)
    })
  }

  if (tokens?.access_token) {
    const { access_token, refresh_token, scope, token_type, jti, login_time, expires_in } = tokens
    localStorage.setItem(
      '__only_for_sync_token',
      JSON.stringify({
        access_token,
        refresh_token,
        scope,
        token_type,
        jti,
        expires_in,
        expirationTime: expires_in * 1000 + login_time
      })
    )

    url.searchParams.append('sync', '__only_for_sync_token')
  }

  location.href = url.toString()
}
