





























































































import { Component, Mixins } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import CommodityShowMiXins from '@xt/client/mixins/show/commodity'
import Agreement from '../agreement.vue'
import Popup from '@xt/client/mixins/components/groupbuy/groupPopup/groupPopupPc.vue'
import MiniprogramPopup from '../miniprogramPopup.vue'

@Observer
@Component({
  components: {
    Agreement,
    Popup,
    MiniprogramPopup
  }
})
export default class ExhibitionSignUp extends Mixins(CommodityShowMiXins) {
  get PageStore() {
    return this.$store.$storeShow
  }

  visibleMiniPopup: boolean = false
  qrcodeUrl: any = ''
  title: string = ''
  desc: string = ''
  loading = false

  async goReady() {
    this.visibleMiniPopup = true
    this.loading = true
    this.title = '去备展'
    this.desc = '扫码后，学习展览辅导课'
    const url = await this.PageStore.getQrcodeUrl({
      page: 'pages/levels/index',
      query: `id=${this.dataSource.id}`
    })
    this.qrcodeUrl = url
    this.loading = false
  }

  async goVoteOrWatch() {
    if (this.showIsVoting || this.showEnd) {
      this.visibleMiniPopup = true
      this.loading = true
      this.title = (this.showIsVoting && '去投票') || '去观展'
      this.desc = (this.showIsVoting && '扫码后，去给喜欢的作品投票') || '扫码后，去看同学的参展作品'
      const url = await this.PageStore.getQrcodeUrl({
        page: 'pages/lookingEx/index',
        query: `id=${this.dataSource.id}`
      })
      this.qrcodeUrl = url
      this.visibleMiniPopup = true
      this.loading = false
    }
  }
}
