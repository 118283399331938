























import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator'
import lodash from 'lodash'
import { Observer } from 'mobx-vue'
import tabs_about from './views/tabs_about.vue'
import tabs_course from './views/tabs_course.vue'
import tabs_stationery from './views/tabs_stationery.vue'
import tabs_video from './views/tabs_video.vue'
@Observer
@Component({
  components: {
    course: tabs_course,
    about: tabs_about,
    stationery: tabs_stationery,
    videos: tabs_video
  }
})
export default class PageView extends Vue {
  tabPane = [
    { key: 'course', title: '课程' },
    { key: 'about', title: '文章' },
    { key: 'stationery', title: '商品' },
    { key: 'videos', title: '视频' }
  ]
  defaultActiveKey = 'course'
  activeKey = lodash.get(this.$route.query, 'active', this.defaultActiveKey)
  tabsChange(activeKey) {
    this.activeKey = activeKey
    this.setBreadcrumb()
  }
  created() {
    this.setBreadcrumb()
  }
  /**
   * 设置 面包屑
   */
  setBreadcrumb() {
    const linksName = `搜索${lodash.find(this.tabPane, ['key', this.activeKey]).title}`
    this.$setBreadcrumb(
      {
        linksName
      },
      true
    )
  }
  mounted() {
    console.log('LENG: PageView -> mounted -> this', this)
  }
  updated() {}
  destroyed() {}
}
