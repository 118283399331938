/// <reference types="./course" />
import lodash from 'lodash'
import { BindAll } from 'lodash-decorators'
import { EnumApiCourse } from '../../api'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { Pagination } from '../basics/pagination'
import { CourseDetails } from './details'
// import { onComputeState } from "./helpers"
import { Course } from '../basics/course'

@BindAll()
export class ControllerCourse {
  constructor(protected $ajax: AjaxBasics) {}
  // onComputeState = onComputeState
  /**
   * 课程详情
   * @memberof ControllerCourse
   */
  Details = new CourseDetails(this.$ajax)
  /**
   * 分页列表数据
   * @memberof ControllerCourse
   */
  Pagination = new Pagination<Course>(this.$ajax, {
    url: EnumApiCourse.CourseList,
    key: 'id',
    lastKey: 'origin.orderNo',
    onMapValues: this.onMapValues
  })

  onMapValues(res) {
    let data = lodash.map(res, item => {
      // item = onComputeState(item, true)
      // item.singleList = lodash.map(item.singleList, x => onComputeState(x, true))
      return new Course(item, [], true)
    })
    return lodash.merge(res, { dataSource: data })
  }
  /** 获取报名前请了解/购买协议 */
  async onGetCourseProtos(id) {
    return this.$ajax.get<{
      content: string
      courseId: number
      id: number
      promptGift: string
      promptSelf: string
      title: string
    }>(EnumApiCourse.ProductProtos, { productId: id })
    // await this.setCourseArgument(res)
  }
  /**
   * 更新 修改课程信息 重新计算状态
   * @param key
   */
  onUpdate(key) {
    this.Details.onUpdate(old => {
      return new Course(old.original)
    })
    this.Pagination.onUpdate(key, old => {
      return new Course(old.original, [], true)
    })
  }
}
export default ControllerCourse
