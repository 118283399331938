































import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import LazyImg from '@/components/lazyImg/index.vue'
import buttonGroup from '../components/buttonGroup.vue'
@Component({
  components: {
    LazyImg,
    buttonGroup
  }
})
export default class BMYX extends Vue {
  @Prop({}) dataSource
  headerinterval
  topShow: boolean = false
  get show() {
    return this.isLogin() && this.topShow
  }
  isLogin() {
    return this.$store.$storeUser.loggedIn
  }
  created() {}
  mounted() {
    this.headerinterval = setInterval(() => {
      // 可以修改482来获取不同滑轮高度的显示
      if (document.documentElement.scrollTop > 482) this.topShow = true
      else this.topShow = false
    }, 100)
  }
  destroyed() {
    clearInterval(this.headerinterval)
  }
}
