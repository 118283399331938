import { render, staticRenderFns } from "./bindUser.vue?vue&type=template&id=72f7a604&scoped=true&"
import script from "./bindUser.vue?vue&type=script&lang=js&"
export * from "./bindUser.vue?vue&type=script&lang=js&"
import style0 from "./bindUser.vue?vue&type=style&index=0&id=72f7a604&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72f7a604",
  null
  
)

export default component.exports