/**
 * @author 冷 (https://github.com/LengYXin)
 * @email lengyingxin8966@gmail.com
 * @create date 2020-10-20 20:26:03
 * @modify date 2020-10-20 20:26:03
 * @desc 课程 感想
 */
import lodash from 'lodash'
import { Component, Mixins } from 'vue-property-decorator'
import { MixinsCourseHelpers } from './helpers'

@Component
export class MixinsCourseThoughts extends Mixins(MixinsCourseHelpers) {
  get CourseDetails() {
    return this.$store.$storeCourse.Details
  }
  get Pagination() {
    return this.$store.$storeCourse.Details.Thoughts
  }
  get id() {
    return this.$route.params.id
  }
  get body() {
    return {
      id: this.id
    }
  }
  hasComment = false
  async onGetMyCourseThought() {
    try {
      this.$InspectUser(false)
      let res = await this.Pagination.onGetMyCourseThought(this.id)
      this.hasComment = !lodash.isEmpty(res)
    } catch (error) {
      this.hasComment = false
    }
  }

  getComment(item) {
    return {
      content: item?.content,
      memberId: item.memberId,
      author: item?.memberNickname,
      time: item?.createTime
    }
  }
  loading = false
  /** 发表感想 */
  async onSubmit(event) {
    try {
      if (this.loading) return
      this.loading = true
      await this.Pagination.onInstall({
        id: this.id,
        courseId: this.id,
        content: this.encodeHTML(event.content)
      })
      event.onReset()
      this.Pagination.onReset()
      // this.Pagination.onGetMyCourseThought(this.id)
      await this.onGetMyCourseThought()
      this.loading = false
    } catch (error) {
      console.log('LENG: PageView -> onSubmit -> error', error)
    }
  }
  created() { }
  mounted() { }
  updated() { }
  destroyed() { }
}
