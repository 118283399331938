/**
 * 王石磊 2021、07、19
 */

export enum EnumBishan {
  // 我的积分过期提醒
  EXPIRE_TIP = '/bsp/api/bishanpoints/remind',
  // 已读提醒
  READ = '/bsp/api/bishanpoints/remind/read'
}
