






















import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator'
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
import lodash from 'lodash'
import { Observer } from 'mobx-vue'
import { Course } from '@xt/client/entities/basics/course'
@Observer
@Component({
  components: {}
})
export default class extends Vue {
  isTop = true
  get locale() {
    return this.$EnumLocaleLinks
  }
  get PageStore() {
    return this.$store.$storeUser
  }

  get window() {
    return window
  }
  async onOutLogin() {
    // let currentPath = lodash.get(this.$router, ["currentRoute", "fullPath"]);
    // if (!lodash.eq(currentPath, "/")) {
    //   this.$router.replace("/");
    // }
    await this.PageStore.onOutLogin()
    window.location.replace(this.$router.resolve('/').href)
  }
  tipPopupClose = false
  tipPopupShow = false
  tipTabShow = false
  mousewheel(e, b) {
    e.stopPropagation()
  }
  stopPropagation(e) {
    e.stopPropagation()
  }
  closeWFPopup() {
    this.tipPopupClose = true
    setTimeout(() => {
      this.tipPopupShow = false
      this.tipTabShow = true
    }, 1000)
  }
  openWFPopup() {
    this.tipTabShow = false
    this.tipPopupClose = false
    this.tipPopupShow = true
  }
  hasbuy() {
    let arr = [80, 81, 85]
    for (let i = 0; i < arr.length; i++) {
      if (lodash.includes(Course.UserHasbuy, arr[i])) return true
    }
    return false
  }
  mounted() {
    // 80_81_85
    let signed_tips_popup = localStorage.getItem(`xt_signed_tips_popup_v1_${this.$store.$storeUser?.UserInfo?.id}`)
    if (!this.$store.$global.isMobile) {
      if (!signed_tips_popup) {
        let hasbuy = this.hasbuy()
        if (hasbuy) {
          this.tipPopupShow = true
          localStorage.setItem(`xt_signed_tips_popup_v1_${this.$store.$storeUser?.UserInfo?.id}`, '80_81_85')
        } else {
          this.tipPopupShow = false
          this.tipTabShow = true
        }
      } else {
        this.tipTabShow = true
      }
    }
  }
  updated() {}
  destroyed() {}
  isComplete(title) {
    return lodash.eq(this.locale.links_complete, title)
  }
  get hideAll() {
    return this.$route.name === 'groupbuy-groupNo'
  }
}
