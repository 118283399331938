import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'

@Observer
@Component
export class MixinsMyOrder extends Vue {
  get PageStore() {
    return this.$store.$storeOrder
  }
  get Pagination() {
    return this.PageStore.Pagination
  }
  get body() {
    return {
      type: this.activeKey
    }
  }
  tabPane = [
    { key: '', title: '全部' },
    { key: 0, title: '待支付' },
    { key: 1, title: '已支付' },
    { key: 3, title: '已退款' }
  ]

  defaultActiveKey = ''
  activeKey = lodash.get(this.$route.query, 'active', this.defaultActiveKey)
  tabsChange(activeKey) {
    this.activeKey = activeKey
  }
  onRefresh() {
    this.Pagination.onReset({ infinite: true })
    this.Pagination.onLoading(this.body)
  }
  mounted() {}
  updated() {}
  destroyed() {
    this.Pagination.onReset()
  }
}
