// @ts-ignore
var originalOpen = window.open
window.open = function open(url?: string | URL, target?: string, features?: string) {
  if (!url) return originalOpen.call(this, url, target, features)

  if (typeof url === 'string') {
    url = new URL(url, location.origin)
  }

  // 只给喧桐自己增加_t时间戳参数
  if (url.hostname.includes('xuantong')) url.searchParams.set('force-use', "PC")

  return originalOpen.call(this, url, target, features)
}
