/*
 * @Author: Erlin
 * @CreateTime: 2020-11-13 21:46:24
 * @LastEditors: Erlin
 * @LastEditTime: 2020-11-13 21:46:24
 * @Description: 暄桐文房分类
 */
export const dataSource_stationery = [
  { key: 0, value: '全部' },
  { key: 1, value: '文房' },
  { key: 2, value: '书' },
  { key: 3, value: '茶' },
  { key: 4, value: '香' },
  { key: 5, value: '琴' },
  { key: 6, value: '家具' },
  { key: 7, value: '其他' }
]
