







































































import { Component, Prop, Vue, Mixins, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import { MixinsHomeworkTask } from '@xt/client/mixins'
import ReplyComment from './replyComment.vue'
import WxImgPop from '../../../components/wxImgPop/index.vue'

@Observer
@Component({
  components: { ReplyComment, WxImgPop }
})
export default class PageView extends Mixins(MixinsHomeworkTask) {
  @Prop({ default: {} }) dataSource

  uuid = Date.now() + '' + Math.floor(Math.random() * 100000)
  get homeworkId() {
    return this.dataSource.id
  }
  get Details() {
    return this.PageStore.HomeworkDetail
  }
  get Pagination() {
    return this.PageStore.HomeworkComment
  }
  get body() {
    return { id: this.homeworkId }
  }
  get editPlaceholder() {
    return `回复${this.dataSource.replyName}助教`
  }
  goCourse() {
    switch (this.dataSource.bizType) {
      case 1:
        return this.$router.push({
          name: 'course-id',
          params: {
            id: this.dataSource.courseId
          }
        })
      case 2:
        return (this.exhibitionIsVisibleGolearn = true)
      default:
        return this.$router.push({
          name: 'course-id',
          params: {
            id: this.dataSource.courseId
          }
        })
    }
  }
  exhibitionIsVisibleGolearn = false
  visible = false
  bottomState = false
  canReply(dataSource) {
    return dataSource.reviewed && !dataSource._FullEndofClass
  }
  async onloading() {
    try {
      await this.Details.onLoading({
        id: this.dataSource.id
      })
    } catch (error) {
      console.log('Clin -> file: reply.vue -> line 187 -> PageView -> onloading -> error', error)
    }
  }
  scroll() {
    let clientHeight = this.$refs['refresh']['clientHeight']
    let scrollHeight = this.$refs['refresh']['scrollHeight']
    let scrollTop = this.$refs['refresh']['scrollTop']
    if (clientHeight + scrollTop + 300 >= scrollHeight) {
      if (this.bottomState) return
      this.bottomState = true
      this.Pagination.onLoading(this.body)
    } else {
      this.bottomState = false
    }
  }
  get teacherComment() {
    return this.Pagination.dataSource.filter(item => item.memberType === 2)
  }
  get toCommentId() {
    if (this.dataSource.bizType === 1) return
    return this.teacherComment[this.teacherComment.length - 1].id
  }
  // pc点击去看看加载作业详情
  async onVisible(visible) {
    this.visible = visible
    this.Pagination.onLoading(this.body)
    this.onloading()
  }
  /**
   * 晒作业
   */
  async onSunWork(item) {
    this.$emit('onSunWork', item)
  }

  /**
   * 回复助教
   */
  async onReply(event) {
    this.$emit('onReply', { event, id: this.dataSource.id, toCommentId: this.toCommentId })
  }
  reset() {
    this.Pagination.onReset()
  }
  beforeDestroy() {
    this.Pagination.onReset()
  }

  mounted() {}
}
