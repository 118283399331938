


































import lodash from 'lodash'
import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import VeLike from './like.vue'
import LazyImg from '@/components/lazyImg/index.vue'

@Component({
  components: { VeLike, LazyImg }
})
export default class extends Vue {
  @Prop({ default: () => [], required: true }) dataSource
  getIntro(text) {
    return text
    return text && text.substring(0, 10) + '<span>…</span>'
  }
  bindCol = {
    lg: 8,
    sm: 12,
    xs: 24
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
