















































import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import HeaderImg from '../../components/headerImage/index.vue'
import lodash from 'lodash'
@Component({
  components: { HeaderImg }
})
export default class extends Vue {
  @Prop({ default: 120 }) imgSize // 图片大小
  @Prop({ default: {} }) comment: {
    // 内容
    content: string
    // 头像
    // avatar: string;
    // 作者
    author: string
    // 时间
    time: string
    // 笔山
    bishan: number
    // 回复
    toUserName: string
    // 图片
    imgs: any[]
    // 标记
    top: boolean
    // 用户id
    memberId: string
  }
  viewerOptions = {
    show: () => {
      this.$emit('viewerShow', this.comment)
    }
  }
  get datetime() {
    return this.comment.time
  }
  get content() {
    let content = this.comment.content
    if (this.comment.toUserName) {
      // const toUserNickname =Vue.compile(`回复 <span class="xt-text-yellow">${item.toUserNickname}</span> : `) ;
      const toUserName = `回复 <span class="xt-text-yellow">${this.comment.toUserName}</span> : `
      if (lodash.startsWith(content, '<p>')) {
        content = lodash.replace(content, '<p>', `<p>${toUserName}`)
      } else {
        content = toUserName + content
      }
    }
    return content
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
