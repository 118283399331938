/**
 * @author wangsl
 */
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import Platform from '../platform'

@Observer
@Component
export default class sharePopupScript extends Vue {
  @Prop({}) visible: boolean

  get web() {
    return !Platform.Mobile()
  }

  get isEmbeddedH5Page() {
    return this.$store.$global.isEmbeddedH5Page
  }

  @Emit('hide')
  hidePopup() { }
}
