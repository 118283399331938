











































import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component({
  components: {}
})
export default class SignAgreement extends Vue {
  // 标题
  @Prop({}) title: string
  // 是否团购
  @Prop({}) group: boolean
  // 是否赠课
  @Prop({}) give: boolean
  // 商品ID
  @Prop({}) id: number

  // 倒计时时间
  deadline: number = this.moment().add(5, 'seconds').valueOf()
  // 是否同意协议
  checked: boolean = false
  // 是否倒计时中
  countdown: boolean = true
  // 协议内容
  agreement: any = {}

  // 倒计时完成的回调
  countdownOnFinish() {
    this.countdown = false
  }

  goCourseAgreement() {
    window.open(`/agreement?id=${this.id}`)
  }

  // 同意按钮是否失效
  // 如果没
  get disabled() {
    return !this.checked || this.countdown
  }

  get content() {
    return !this.give ? this.agreement?.promptSelf : this.agreement?.promptGift
  }

  get welcome() {
    // return !this.give ? "祝您在暄桐教室学习开心~" : "在Ta拆开课程礼物之后，可以前往课程页面【开课前要准备什么】中查看以上信息哦～212"
    return '祝您在暄桐教室学习开心~'
  }

  @Emit('cancel')
  onCancel() {}

  @Emit('payment')
  toPayment() {}

  isShowChecked() {
    this.checked = !this.checked
  }
  async created() {
    this.agreement = await this.$store.$Product.getProductAgreementById(this.id)
  }

  mounted() {}
  updated() {}
  destroyed() {}
}
