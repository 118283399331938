













import { Component, Vue } from 'vue-property-decorator'
import Product from '@xt/client/entities/basics/product'
import { EnumContentType, EnumContentTypeText, EnumProductType, EnumProductTypeQuery } from '@xt/client/enums'
import Header from './views/header.vue'
import Content from './views/content.vue'
import TopHeader from './views/topHeader.vue'

@Component({
  async asyncData(ctx) {
    // 根据不同种情况来获取相对应课程数据
    let productId = parseInt(ctx.route.params.id)
    await ctx.store.$storeCourse.Details.onGetGiveawayInfo()
    await ctx.store.$storeCourse.Details.onGetStudentWelfareInfo()
    let product: Product = <Product>await ctx.store.$Product.getProductDetailById(productId)
    let type = ''
    switch (product.origin.productType) {
      case EnumProductType.single:
      case EnumProductType.fullstage:
        type = EnumProductTypeQuery.Course
        break
      case EnumProductType.dayCourse:
        type = EnumProductTypeQuery.DayCourse
        break
      case EnumProductType.selfStudy:
        type = EnumProductTypeQuery.SelfStudy
        break
      case EnumProductType.offlineExhibition:
      case EnumProductType.onlineExhibition:
        type = EnumProductTypeQuery.Exhibition
        break
    }
    product.origin['onlyEntryKey'] = type
    let detail: any = {}
    // 单阶直播课
    if (type === EnumProductTypeQuery.Course) {
      detail = await ctx.store.$storeCourse.Details.getCourseDetail(product.origin.bizId)
      // 日课
    } else if ([EnumProductTypeQuery.DayCourse, EnumProductTypeQuery.SelfStudy].includes(type as any)) {
      detail = await ctx.store.$storeActivitys.Course.getActivityDetail(product.origin.bizId)
      // 线上展览
    } else {
      detail = await ctx.store.$storeShow.getShowDetailByShowId(product.origin.bizId)
    }
    detail.product = product
    return { dataSource: { courseInfo: detail, productInfo: product.origin } }
  },
  validate({ params }) {
    return /^\d+$/.test(params.id)
  },
  components: {
    TopHeader,
    Header,
    Content
  }
})
export default class PageView extends Vue {
  dataSource: any = {}
  VisibleAgreement: boolean = false
  get contentType() {
    switch (this.dataSource.productInfo.contentType) {
      case EnumContentType.Painting:
        return EnumContentTypeText.Painting
      case EnumContentType.Calligraphy:
        return EnumContentTypeText.Calligraphy
      case EnumContentType.CalligraphyAndReading:
        return EnumContentTypeText.CalligraphyAndReading
      case EnumContentType.Health:
        return EnumContentTypeText.Health
      case EnumContentType.Physical:
        return EnumContentTypeText.Physical
      case EnumContentType.Reading:
        return EnumContentTypeText.Reading
      case EnumContentType.Writing:
        return EnumContentTypeText.Writing
      case EnumContentType.AestheticEducation:
        return EnumContentTypeText.AestheticEducation
      default:
        return EnumContentTypeText.Calligraphy
    }
  }
  created() {
    this.dataSource.productInfo = { ...this.dataSource.productInfo, contentType: this.contentType }
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
