/*
 * @Author: MantorHuang
 * @CreateTime: 2023-06-12 20:30:32
 * @LastEditors: MantorHuang
 * @LastEditTime: 2023-06-12 20:56:54
 * @Description: 格式化邮箱展示
 */

import Vue from 'vue'
import lodash from 'lodash'
Vue.directive('email', {
  inserted: onRender,
  componentUpdated: onRender,
  unbind(el: any) {}
})
/**
 * 邮箱格式化
 * @param el
 * @param binding
 * @param vnode
 * @param oldVnode
 */
function onRender(el: HTMLDivElement, binding, vnode, oldVnode) {
  let { value, oldValue } = binding
  if (lodash.eq(value, oldValue) || lodash.isEmpty(value) || value.length > 30) {
    return
  }
  let emailSlice = value.split('@')
  if (emailSlice[0].length === 1) {
    emailSlice = value.slice(0, 1) + '****@' + emailSlice[1]
  } else {
    emailSlice = value.slice(0, 2) + '****@' + emailSlice[1]
  }
  el.innerHTML = emailSlice
}
