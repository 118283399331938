



































import { Component, Prop, Vue, Ref, Inject, Watch, Emit } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import { BindWXMetaType } from '@xt/client/entities'

enum EnumQRCodeState {
  /** 待扫描 */
  No_Scan = 0,
  /** 已扫描 */
  Have_Scan = 1,
  /** 已失效 */
  Have_Expire = 2,
  /** 已绑定 */
  Have_Finish = 3
}

@Observer
@Component
export default class PageView extends Vue {
  @Prop({ default: false }) visible: boolean
  @Prop({ default: () => {} }) meta: BindWXMetaType

  loading: boolean = true
  qrcodeUrl: string
  scanState: number = EnumQRCodeState.No_Scan
  errorQRcode: boolean = false

  get PageStore() {
    return this.$store.$storeUser
  }
  get EnumQRCodeState() {
    return EnumQRCodeState
  }
  oldTime: number = Date.now()
  boundTime: number = 5 * 60 * 1000
  deadBoundTime: number = (5 * 60 + 10) * 1000
  timer = null
  async pollingGetCodeState(uuid: string) {
    if (!uuid) return
    let currentTime = Date.now()
    let diff: number = currentTime - this.oldTime
    try {
      let res: any = await this.PageStore.onPollingQrcodeWXBindState(uuid)
      if (!res && diff > this.boundTime) {
        this.scanState = EnumQRCodeState.Have_Expire
        clearTimeout(this.timer)
        this.timer = null
      }
      switch (res?.bindWXQRCodeStatus) {
        case EnumQRCodeState.No_Scan:
          // 超出边界展示失效界面
          if (diff > this.boundTime) {
            this.scanState = EnumQRCodeState.Have_Expire
          }
          // 超出死边界  释放
          if (diff > this.deadBoundTime) {
            clearTimeout(this.timer)
            this.timer = null
            return
          }
          break
        case EnumQRCodeState.Have_Scan:
          // 从未扫描进来  重新计算5分10秒
          if (this.scanState === EnumQRCodeState.No_Scan || this.scanState === EnumQRCodeState.Have_Expire) {
            this.oldTime = currentTime
            uuid = res.uuid
          }
          this.scanState = EnumQRCodeState.Have_Scan
          // 超出死边界  释放
          if (diff > this.deadBoundTime) {
            clearTimeout(this.timer)
            this.timer = null
            this.scanState = EnumQRCodeState.Have_Expire
            return
          }
          break
        case EnumQRCodeState.Have_Expire:
          // 单纯的实效  释放
          this.scanState = EnumQRCodeState.Have_Expire
          clearTimeout(this.timer)
          this.timer = null
          return
        case EnumQRCodeState.Have_Finish:
          this.$emit('close')
          this.$msg('绑定成功', 'success')
          clearTimeout(this.timer)
          this.timer = null
          this.scanState = EnumQRCodeState.Have_Finish
          setTimeout(() => {
            window.location.reload()
          }, 1000)
          return
        default:
          // 未知异常
          return
      }
      this.timer = setTimeout(() => {
        this.pollingGetCodeState(uuid)
      }, 2000)
    } catch (error) {
      clearTimeout(this.timer)
      this.timer = null
      this.errorQRcode = true
    }
  }
  resetScan() {
    clearTimeout(this.timer)
    this.timer = null
    this.init()
  }
  async init() {
    this.loading = true
    this.oldTime = Date.now()
    this.errorQRcode = false
    this.scanState = EnumQRCodeState.No_Scan
    try {
      const res: any = await this.PageStore.onGetBindWXQrcode('pages/login/scan/index')
      const currentTarget: XMLHttpRequest = lodash.get(res, 'originalEvent.currentTarget')
      const uuid = currentTarget.getResponseHeader('Uuid')
      this.qrcodeUrl = window.URL.createObjectURL(res.response)
      this.loading = false
      this.pollingGetCodeState(uuid)
    } catch (error) {
      console.log(error)
      this.errorQRcode = true
    } finally {
      this.loading = false
    }
  }
  @Watch('visible')
  changeVisible(value: boolean) {
    if (value) {
      this.init()
    } else {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
    }
  }

  async created() {}

  @Emit('close')
  onCancel() {}
}
