












import PopupScript from './popupScript'
import { Component } from 'vue-property-decorator'

@Component({
  name: 'PopupComp'
})
export default class PopupComp extends PopupScript {}
