/// <reference types="./video" />
import { BindAll } from 'lodash-decorators'
import { EnumApiVideo, EnumApiCurrency } from '../../api'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { EnumMessage } from '../../languages'
import { Pagination } from '../basics/pagination'
import { VideoCommentPagination } from './comment'
import { VideoDetails } from './details'

@BindAll()
export class ControllerVideo {
  constructor(protected $ajax: AjaxBasics) {}
  /**
   * 视频列表
   */
  Pagination = new Pagination(this.$ajax, {
    url: EnumApiVideo.VideoList,
    key: 'id'
  })
  /**
   * 视频详情
   */
  Details = new VideoDetails(this.$ajax)
  /**
   * 视频评论列表
   * @memberof ControllerVideo
   */
  CommentPagination = new VideoCommentPagination(this.$ajax)
  /**
   * 视频推荐列表
   */
  RecommendPagination = new Pagination(this.$ajax, {
    url: EnumApiVideo.VideoRecommend,
    key: 'id'
  })

  /**
   * 视频分享-发布评论
   */
  async onVideoCommentAdd(body: { content; contentLength?; videoShareId; toCommentId? }) {
    await this.$ajax.post(EnumApiVideo.VideoComment, body)
    const updater = old => {
      old.commentCount++
      return old
    }
    // 视频详情 评论数+1
    this.Details.onUpdate(updater)
  }

  /**
   * 视频点赞
   * @param data 点赞的数据
   * @param list 列表||详情
   */
  async onLikes(data) {
    try {
      if (data.liked) {
        throw EnumMessage.like_error
      }
      await this.$ajax.put(EnumApiVideo.VideoLike, {
        id: data.id,
        likeType: true
      })
      const updater = old => {
        old.likeCount++
        old.liked = true
        return old
      }
      // 视频推荐列表更新
      this.RecommendPagination.onUpdate(data, updater)
      // 视频列表更新
      this.Pagination.onUpdate(data, updater)
      // 视频详情更新
      this.Details.onUpdate(updater)
    } catch (error) {
      throw error
    }
  }
}

export default ControllerVideo
