







































import { Observer } from 'mobx-vue'
import { Vue, Component } from 'vue-property-decorator'
import Course from './views/course/index.vue'
import ActiveCourseScript from './script'
import Detail from '../views/detail.vue'
import LazyImg from '@/components/lazyImg/index.vue'

@Observer
@Component({
  name: 'ShowActivityComp',
  components: { Course, Detail, LazyImg }
})
export default class ShowActivityView extends ActiveCourseScript {}
