<template>
  <div @click="toDetail" @mouseenter="click" :class="`list-content-left-item ${current === index && 'list-content-left-item-current'}`">
    <div class="signuped" v-if="courseHasbuy()">已报名</div>
    <!-- <div class="lookDetail" v-if="index === current" >单阶报名</div> -->
    <div class="lookDetail" v-if="signupOpened">单阶报名</div>
    <LazyImg className="list-content-left-item-cover" :src="ossProcessImg(dataSource.cover, 232)" />
    <div class="list-content-left-item-title">{{ dataSource.title }}</div>
    <div class="list-content-left-item-vice">{{ dataSource.vice }}</div>
    <div class="list-content-left-item-price">
      <span>{{ dataSource.price / 100 }}</span>
      <span>元/共{{ dataSource.count }}课</span>
      <span :class="`list-content-left-item-status${btn().color}`" v-html="btn().text"></span>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import LazyImg from '@/components/lazyImg/index.vue'
import { AjaxBasics } from '@xt/client'
import moment from 'moment'
import lodash from 'lodash'
import { Course } from '@xt/client/entities/basics/course'
@Component({
  components: {
    LazyImg
  }
})
export default class BMYX extends Vue {
  @Prop({}) current
  @Prop({}) index
  @Prop({}) dataSource
  @Emit('onClick')
  click() {}
  /**
   * 跳转到详情页
   */
  toDetail() {
    window.open(`/commodity/${this.dataSource.productId}`)
  }

  data() {
    return {
      countdownText: ''
    }
  }

  interval = null

  get convertOpened() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    return currentTime > this.dataSource.signupEndTime && currentTime < this.dataSource.convertEndTime
  }

  get signupOpened() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    return currentTime >= this.dataSource.signupStartTime && currentTime < this.dataSource.signupEndTime
  }

  get courseOpened() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    return currentTime >= this.dataSource.courseStartTime && currentTime < this.dataSource.courseEndTime
  }

  courseHasbuy() {
    return lodash.includes(Course.UserHasbuy, this.dataSource.id)
  }
  countdown() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    // 若报名未结束且报名开始则进行报名倒计时
    if (currentTime > this.dataSource.signupStartTime && currentTime < this.dataSource.signupEndTime) {
      return 1
    }
    // 不需要倒计时
    return 0
  }

  btn() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    // 当前时间大于转报结束时间且课程结束则 已结课
    if (currentTime >= this.dataSource.courseEndTime) {
      return { text: '已结课', color: '-black' }
    }
    // 当前时间大于转报结束时间且大于报名结束时间且课程已开启则 开课中
    if (currentTime >= this.dataSource.signupEndTime && currentTime >= this.dataSource.courseStartTime) {
      return { text: '开课中', color: '-yellow' }
    }
    // 当前时间大于转报结束时间且课程未开启则 转报结束
    if (currentTime >= this.dataSource.signupEndTime && currentTime <= this.dataSource.courseStartTime) {
      return { text: '报名结束', color: '-red' }
    }
    // 当前时间大于报名开始时间小于报名结束时间
    if (currentTime < this.dataSource.signupEndTime && currentTime >= this.dataSource.signupStartTime) {
      return { text: this.countdownText, color: '-red' }
    }
    // 默认
    return { text: this.countdownText, color: '-red' }
  }

  get moment() {
    return moment
  }

  showCourseTime() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    return currentTime >= this.dataSource.convertEndTime && currentTime >= this.dataSource.signupEndTime
  }

  countdownInterval(endtime) {
    this.computed(endtime)
    this.interval = setInterval(() => {
      this.computed(endtime)
    }, 1000)
  }

  computeDay(currentTime, endtime) {
    return Math.floor(this.moment(endtime).diff(currentTime, 'hour') / 24)
  }

  computed(endtime) {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    let diff = endtime - currentTime
    if (diff <= 0) {
      clearInterval(this.interval)
      if (currentTime < this.dataSource.convertEndTime) {
        return this.countdownInterval(this.dataSource.convertEndTime)
      } else {
        return (this.countdownText = '')
      }
    }

    if (diff > 24 * 60 * 60 * 1000) {
      this.countdownText = (this.countdown() == 2 ? '转报' : '报名') + '剩余 ' + this.computeDay(currentTime, endtime) + '天'
    } else {
      return (this.countdownText =
        (this.countdown() == 2 ? '转报' : '报名') +
        '剩余 ' +
        `<div style="display: inline-block;width:${this.$px2rem(55)}">` +
        moment(diff).utcOffset(0).format('HH:mm:ss') +
        '</div>')
    }
  }

  mounted() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    if (this.countdown() == 1) this.countdownInterval(this.dataSource.signupEndTime)
    else if (currentTime < this.dataSource.signupStartTime)
      this.countdownText = '报名时间：<br>' + moment(this.dataSource.signupStartTime).format('YYYY.MM.DD')
  }
  destroyed() {
    clearInterval(this.interval)
  }
}
</script>
<style lang="less">
.lookDetail {
  width: 88px;
  height: 26px;
  background: #c37f7f;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #ffffff;
  line-height: 12px;
  position: absolute;
  top: 115px;
  left: 76px;
  z-index: 55;
}
// .mantle {
//   width: 232px;
//   height: 143px;
//   position: absolute;
//   background: fade(#000000, 50%);
//   top: 4px;
//   left: 4px;
//   z-index: 50;
// }
.signuped {
  width: 66px;
  height: 28px;
  background: fade(#000000, 40%);
  border-radius: 14px 0px 0px 14px;
  font-size: 12px;
  color: #ffffff;
  line-height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 19px;
  right: 4px;
}
.list-model {
  width: 100vw;
  min-width: 1200px;
  height: 1090px;
  background: #ede5dd;
  margin-top: -416px;
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 1;
}
.list-content {
  width: 1200px;
  margin-top: 341px;
  display: flex;
  position: relative;
  z-index: 11;
  &-left {
    width: 802px;
    &-title {
      font-size: 24px;
      font-weight: 500;
      color: #181c25;
      line-height: 24px;
    }
    &-vice {
      font-size: 14px;
      color: #181c25;
      line-height: 14px;
      margin-top: 23px;
      margin-bottom: 10px;
    }
    &-item {
      margin-top: 30px;
      width: 240px;
      height: 255px;
      background: linear-gradient(180deg, #d1bc9e 0%, rgba(243, 225, 197, 0.27) 79%, rgba(255, 239, 212, 0) 100%);
      border-radius: 4px;
      display: inline-block;
      margin-right: 20px;
      cursor: pointer;
      user-select: none;
      position: relative;
      &-current {
        background: white;
      }
      &-cover {
        width: 232px;
        height: 143px;
        margin: 4px;
      }
      &-title {
        width: 240px;
        margin-top: 10px;
        padding: 0 14px;
        font-size: 14px;
        color: #333;
        line-height: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        font-weight: 500;
      }
      &-vice {
        width: 240px;
        font-size: 12px;
        font-weight: 300;
        color: #999999;
        line-height: 12px;
        margin-top: 10px;
        padding: 0 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
      &-price {
        vertical-align: bottom;
        padding: 0 14px;
        margin-top: 23px;
        position: relative;
        height: 18px;
        width: 240px;
        display: inline-flex;
        align-items: flex-end;
        span {
          &:nth-child(1) {
            height: 18px;
            display: inline-flex;
            align-items: flex-end;
            font-size: 18px;
            font-weight: 500;
            color: #181c25;
            line-height: 14px;
          }
          &:nth-child(2) {
            height: 18px;
            display: inline-flex;
            align-items: flex-end;
            font-size: 12px;
            font-weight: 500;
            color: #181c25;
            line-height: 12px;
          }
          &:nth-child(3) {
            display: inline-flex;
            align-items: flex-end;
            justify-content: flex-end;
            font-size: 12px;
            line-height: 16px;
            flex: 1;
            text-align: right;
            transform: translateY(2px);
          }
        }
      }
      &-status {
        &-red {
          color: rgba(186, 90, 90, 1);
        }
        &-yellow {
          color: rgba(173, 129, 83, 1);
        }
        &-black {
          color: rgba(136, 136, 136, 1);
        }
      }
    }
  }
  &-right {
    width: 398px;
    position: relative;
    &-title {
      font-size: 24px;
      font-weight: 500;
      color: #181c25;
      line-height: 24px;
    }
    &-vice {
      font-size: 14px;
      color: #181c25;
      line-height: 14px;
      margin-top: 23px;
      margin-bottom: 10px;
      overflow: hidden;
    }
    &-swiper {
      width: 398px;
      height: 520px;
      background: fade(#ffffff, 80%);
      border-radius: 4px;
      margin-top: 40px;
      padding: 0 20px;
      padding-top: 17px;
      padding-bottom: 28px;
      position: relative;
      &-item {
        position: absolute;
        top: 17px;
        width: 358px;
        cursor: pointer;
        opacity: 0;
        transition: 0.25s;
        &-current {
          transition: 0.5s;
          opacity: 1;
        }
      }
    }
    &-up {
      font-size: 24px !important;
      color: #e0bf95;
      position: absolute;
      top: 37px;
      left: 340px;
      display: inline-block;
      transform: rotateY(180deg);
      cursor: pointer;
      &:hover {
        color: fade(#e0bf95, 80%);
      }
    }
    &-next {
      font-size: 24px !important;
      color: #e0bf95;
      position: absolute;
      top: 37px;
      left: 374px;
      display: inline-block;
      cursor: pointer;
      &:hover {
        color: fade(#e0bf95, 80%);
      }
    }
  }
}

.curse-model {
  &-live1 {
    width: 662px;
    height: 898px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    transform: translateX(592px);
  }
}
</style>
