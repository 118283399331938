
























import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator'
import lodash from 'lodash'
@Component({
  components: {}
})
export default class extends Vue {
  // 注册当前页面 name 为 key
  key = this.$route.name
  // 固定模式
  @Prop({ default: false }) affix
  // 固定距离
  @Prop({ default: 72 }) offsetTop
  // 对其方式
  @Prop({ default: 'center' }) align
  // 默认选择
  @Prop({ default: 0 }) defaultActiveKey
  // tabPane
  @Prop({ default: [], required: true }) tabPane
  // 主题
  @Prop({ default: '' }) theme
  // 联动路由
  @Prop({ default: true }) linkageRoute
  @Prop({ default: false }) replace
  @Prop({ default: 'top' }) tabPosition
  @Prop({ default: 0 }) tabBarGutter
  @Prop({ default: () => {} }) tabBarStyle

  // 选择
  activeKey: any = '0' //lodash.get(this.$route.query, "active", this.defaultActiveKey);
  // 当前页面显示
  get isConnected() {
    // return this.$el.isConnected && this.key === this.$route.name;
    return this.key === this.$route.name
  }
  get themeClass() {
    return `xt-tabs-${this.theme} xt-tabs-${this.align}`
  }
  // 更改
  tabsChange(activeKey) {
    if (this.linkageRoute) {
      const query = lodash.merge({}, this.$route.query, {
        active: activeKey
      })
      // 存在分页页码
      if (query.current) {
        query.current = '1'
      }
      if (this.replace) {
        this.$router.replace({
          query
        })
      } else {
        this.$router.push({
          query
        })
      }
    } else {
      this.activeKey = activeKey
      this.emitTabsChange()
    }
  }
  // 组件中 使用不了 生命周期 beforeRouteUpdate
  @Watch('$route.query.active')
  queryUpdate(to, from, next) {
    if (this.linkageRoute && this.isConnected) {
      const { active } = this.$route.query
      if (!lodash.eq(String(active), String(this.activeKey))) {
        if (lodash.isNil(active)) {
          this.activeKey = this.defaultActiveKey
        } else {
          this.activeKey = active
        }
        this.emitTabsChange()
      }
    }
  }
  @Emit('tabsChange')
  emitTabsChange() {
    return this.activeKey
  }
  created() {
    if (this.linkageRoute) {
      this.activeKey = lodash.get(this.$route.query, 'active', this.defaultActiveKey)
    } else {
      this.activeKey = this.defaultActiveKey
    }
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
