






























































import { Component, Mixins } from 'vue-property-decorator'
import { CommodityLiveCourseMixins } from '@xt/client/mixins'
import Agreement from '../agreement.vue'
import Popup from '@xt/client/mixins/components/groupbuy/groupPopup/groupPopupPc.vue'

@Component({
  components: { Agreement, Popup }
})
export default class PageView extends Mixins(CommodityLiveCourseMixins) {
  goCourse() {
    this.$router.push({
      name: 'course-id',
      params: { id: this.dataSource.id }
    })
  }
}
