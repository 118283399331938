/*
 * @Author: mengxd
 * @CreateTime: 2020-07-22
 * @LastEditors: mengxd
 * @LastEditTime: 2020-07-22
 * @Description: 我的积分
 */
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'

@Observer
@Component
export class MixinsMyPoints extends Vue {
  get PageStore() {
    return this.$store.$my
  }
  get Pagination() {
    return this.PageStore.MyBishan
  }
  get body() {
    return {
      type: this.activeKey
    }
  }
  tabPane = [
    { key: 0, title: '笔山明细' },
    { key: 1, title: '桐板儿明细' }
  ]

  defaultActiveKey = '0'
  activeKey = lodash.get(this.$route.query, 'active', this.defaultActiveKey)
  tabsChange(activeKey) {
    this.activeKey = activeKey
  }
  onRefresh() {
    this.Pagination.onReset({ infinite: true })
    this.Pagination.onLoading(this.body)
  }
  mounted() {}
  updated() {}
  destroyed() {
    this.Pagination.onReset()
  }
}
