










































import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class PageView extends Vue {
  @Prop({ default: false }) visible: boolean
  @Prop({ default: '报名后，你需要：' }) title: string
  @Prop() qrcodeUrl: string
  @Prop() appletUrl: string
  @Prop() studyStartTime: number

  onCancel() {
    this.$emit('close')
  }
  created() {}
}
