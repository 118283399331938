<template>
  <div class="ring-top">
    <div class="calendar-ring">
      <div class="ring"></div>
      <div class="ring"></div>
    </div>
    <div class="calendar-ring">
      <div class="ring"></div>
      <div class="ring"></div>
    </div>
  </div>
</template>

<script>
import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Ring extends Vue {}
</script>

<style lang="less" scoped>
.ring-top {
  width: 262px;
  display: flex;
  justify-content: space-between;
  .calendar-ring {
    display: flex;
    width: 26px;
    height: 20px;
    .ring {
      width: 10px;
      height: 20px;
      background: #ffffff;
      border-radius: 11px;
      border: 1px solid #e1d5d5;
      &:first-child {
        margin-right: 6px;
      }
    }
  }
}
</style>
