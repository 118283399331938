/*
 * @Author: Erlin
 * @CreateTime: 2020-09-01 18:28:10
 * @LastEditors: Erlin
 * @LastEditTime: 2020-09-10 17:32:32
 * @Description: 我的作业
 */
import { BindAll } from 'lodash-decorators'
import { EnumApiMy } from '../../api'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { EntitiesBasics } from '../basics/entities'
import { Pagination } from '../basics/pagination'
/**
 * 我的作业
 * @export
 * @class ControllerMyWork
 * @extends {Pagination<any>}
 */
@BindAll()
export class ControllerMyWork extends Pagination<any> {
  constructor($ajax: AjaxBasics) {
    super($ajax, {
      url: EnumApiMy.MyHomeworkList
    })
  }

  /**
   * 回复作业详情
   * @memberof ControllerMyWork
   */
  Details = new EntitiesBasics<any>(this.$ajax, {
    url: EnumApiMy.MyHomeworkDetail
  })

  /**
   * 晒作业
   */
  async onSunWork(id: number) {
    try {
      await this.$ajax.post(EnumApiMy.SunHomework, {
        courseType: 1,
        homeworkId: id
      })
      const updater = old => {
        old.suned = 1
        return old
      }
      this.onUpdate(id, updater)
      this.Details.onUpdate(updater)
    } catch (error) {
      throw error
    }
  }

  /**
   * 删除作业
   */
  async onDelWork(id: number) {
    await this.$ajax.post(EnumApiMy.DelHomework, {
      id
    })
    this.onRemove(id)
  }

  /**
   * 回复助教
   */
  async onReply(body: { courseId?; homeworkId?; replyContent?; replyContentNum? }) {
    return this.$ajax.post(EnumApiMy.MyHomeworkReply, body)
  }
}
export default ControllerMyWork
