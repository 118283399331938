

















import { Component, Prop, Vue, Ref, Emit, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { FormModel } from 'ant-design-vue'
import lodash from 'lodash'
import verifyForm from './index.vue'

@Observer
@Component({
  components: { verifyForm }
})
export default class PageView extends Vue {
  @Prop({ default: false }) validDevice // 发送验证码是否需要验证
  @Prop({ default: null }) validExist // 验证设备是否存在 true存在 false不存在 null不验证
  @Prop({ default: '' }) defaultValue // 验证已存在时的默认值
  @Prop({ default: true }) phone // 手机/邮箱 默认手机
  @Prop({ default: '' }) oldValue // 旧值 更换时验证使用
  @Prop({ default: 3 }) codeType // 发送验证码的类型
  // 验证码类型 3 绑定手机，4 绑定邮箱，5 更换手机，6 更换邮箱，7 手机找回密码，8 邮箱找回密码
  @Prop({ default: '手机号' }) label // 设备描述
  @Prop({ default: false }) isLabel // 展示为label 或者 placeholder
  @Prop({ default: '绑定' }) btnText // 按钮文字
  @Prop() layout
  @Prop() btnCol

  get PageStore() {
    return this.$store.$storeUser
  }

  get bind() {
    return lodash.merge(this.$props, { rules: getRules.call(this) })
  }
  async onSendSms(model, callback, closeLockCallback) {
    try {
      await this.PageStore.onGetCode({
        device: model.device,
        verifyCodeType: this.codeType
      })
      callback()
      closeLockCallback()
    } catch (error) {
      closeLockCallback()
      if (lodash.eq(error, false)) this.$msg(this.$EnumMessage.check_code_error)
    }
  }

  async onSubmit(model) {
    try {
      await this.PageStore.onCheckCode(
        lodash.merge(model, {
          verifyCodeType: this.codeType
        })
      )
      this.$emit('submit', model)
    } catch (error) {
      if (lodash.eq(error, false)) this.$msg(this.$EnumMessage.check_code_error)
    }
  }

  // 检测手机号是否已存在
  // async onCheckPhone(phoneNum) {
  //   try {
  //     await this.PageStore.onCheckPhone({
  //       phoneNum
  //     });
  //     if (lodash.eq(this.validExist, true)) {
  //       return undefined;
  //     } else {
  //       return new Error("手机号已存在");
  //     }
  //   } catch (error) {
  //     if (lodash.eq(error, false)) {
  //       if (lodash.eq(this.validExist, true)) {
  //         return new Error("手机号不存在");
  //       } else {
  //         return undefined;
  //       }
  //     } else return error;
  //   }
  // }

  // 检测邮箱是否已存在
  //   async onCheckEmail(email) {
  //     try {
  //       await this.PageStore.onCheckEmail({
  //         email
  //       });
  //       if (lodash.eq(this.validExist, true)) {
  //         return undefined;
  //       } else {
  //         return new Error("邮箱已存在");
  //       }
  //     } catch (error) {
  //       if (lodash.eq(error, false)) {
  //         if (lodash.eq(this.validExist, true)) {
  //           return new Error("邮箱不存在");
  //         } else {
  //           return undefined;
  //         }
  //       } else return error;
  //     }
  //   }
}
function getRules() {
  const validatePhone = async (rule, value, callback) => {
    if (value == '') {
      return callback(new Error('请输入手机号'))
    }
    if (!this.$Regulars.mobilePhone.test(value)) {
      return callback(new Error('请输入正确的手机号'))
    }
    if (value == this.oldValue) {
      return callback(new Error('新手机号不能与原手机号相同'))
    }
    // if (lodash.isBoolean(this.validExist)) {
    //   return callback(await this.onCheckPhone(value));
    // }
    return callback()
  }

  const validateEmail = async (rule, value, callback) => {
    if (value == '') {
      return callback(new Error('请输入邮箱'))
    }
    if (!this.$Regulars.email.test(value)) {
      return callback(new Error('请输入正确的邮箱'))
    }
    if (value == this.oldValue) {
      return callback(new Error('新邮箱不能与原邮箱相同'))
    }
    // if (lodash.isBoolean(this.validExist)) {
    //   return callback(await this.onCheckEmail(value));
    // }
    return callback()
  }
  return {
    device: [
      {
        validator: this.phone ? validatePhone : validateEmail,
        trigger: 'blur'
      }
    ],
    password: [
      {
        required: true,
        message: '请输入密码',
        trigger: 'blur'
      },
      {
        pattern: this.$Regulars.password,
        message: '密码输入错误',
        trigger: 'blur'
      }
    ],
    confirmCode: [
      {
        required: true,
        message: '请输入验证码',
        trigger: 'blur'
      },
      { min: 4, max: 4, message: '请输入正确的验证码', trigger: 'blur' }
    ]
  }
}
