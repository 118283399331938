










































import { Component, Prop, Vue } from 'vue-property-decorator'
import lodash from 'lodash'
import LazyImg from '@/components/lazyImg/index.vue'
import NewSwiper from './newSwiper.vue'

@Component({
  components: {
    LazyImg,
    NewSwiper
  }
})
export default class RelatedFullStage extends Vue {
  // 列表数据
  @Prop() dataSource

  get singleProductList(): any[] {
    return lodash.chunk(this.dataSource.singleProductList, 3)
  }

  toDetail(item, isFull = false) {
    if (isFull) return window.open(item.activityPageUrl)
    this.$router.push({
      path: `/commodity/${item.id}`
    })
  }

  // 计算课程状态
  courseStatus(item, isFull = false) {
    let status = ''
    let style = ''
    let hot: boolean = false
    let currentTime = this.$ajax.serviceDate().valueOf()
    let { canSignUp, learnStartTime, learnEndTime, signUpStartTime, signUpEndTime, turnSignUpEndTime } = item
    switch (true) {
      case !canSignUp || currentTime < signUpStartTime:
        status = '即将报名'
        style = 'xt-course-status-red'
        break
      case currentTime >= signUpStartTime && currentTime <= signUpEndTime:
        status = '正在报名'
        style = 'xt-course-status-red'
        hot = true
        break
      case isFull && currentTime > signUpEndTime && currentTime < turnSignUpEndTime:
        status = '转报全阶'
        style = 'xt-course-status-red'
        hot = true
        break
      case currentTime >= learnStartTime && currentTime <= learnEndTime:
        status = '开课中'
        style = 'xt-course-status-yellow'
        break
      case currentTime > learnEndTime:
        status = '已结课'
        style = 'xt-course-status-grey'
        break
      case currentTime > signUpEndTime:
        status = '报名结束'
        style = 'xt-course-status-red'
        break
    }
    return { status, style, hot }
  }
}
