
















import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component({
  components: {}
})
export default class extends Vue {
  @Prop({ default: false }) visible: boolean

  get PageStore() {
    return this.$store.$storeUser
  }

  cancelPopupHandler() {
    this.PageStore.toggleStudentWelfare(false)
  }

  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
