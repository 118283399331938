/** * wangsl */
<template>
  <div class="swiper">
    <div class="sites">
      <div class="trains" :style="trainStyle">
        <div
          :class="`carriage ${index != dataSource.length - 1 && 'carriage-right'}`"
          v-for="(item, index) in dataSource"
          :key="item.id"
          :ref="`course-${index}`"
          @click="clickItem(item)"
        >
          <div class="img-box">
            <LazyImg className="product-cover" :src="ossProcessImg(item.cover, 376 * 2)" />
          </div>
          <div class="product-topay">{{ item.qrcode ? '去购买': '查看详情'}}</div>
          <div class="product-title">{{ item.title }}</div>
          <!-- <div class="product-vice" v-html="item.vice"></div> -->
        </div>
      </div>
    </div>
    <div class="swiper-progress" v-if="count > 1">
      <i
        @click="upPage"
        :class="`
          iconfont 
          ${upBtnHover && this.current !== 0 ? 'iconbanner_switch_hover_ic' : 'iconbanner_switch_ic'} 
          swiper-progress-up 
          ${upBtnHover && 'swiper-progress-up-hover'} 
          ${!current && 'swiper-progress-up-hide'}
        `"
        @mouseover="upBtnHover = true"
        @mouseout="upBtnHover = false"
      ></i>
      <!-- <div 
        :class="`swiper-progress-point`">
        {{current + 1}}/{{count}}
      </div> -->
      <i
        @click="nextPage"
        :class="`
          iconfont 
          ${nextBtnHover && !(this.current === this.count - 1) ? 'iconbanner_switch_hover_ic' : 'iconbanner_switch_ic'} 
          swiper-progress-next
          ${nextBtnHover && 'swiper-progress-next-hover'} 
          ${this.current === this.count - 1 && 'swiper-progress-next-no'} 
        `"
        @mouseover="nextBtnHover = true"
        @mouseout="nextBtnHover = false"
      ></i>
    </div>
    <div v-if="more" class="swiper-more" @click="window.open(jump)">查看更多</div>
  </div>
</template>
<script>
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import LazyImg from '@/components/lazyImg/index.vue'

@Component({
  components: {
    LazyImg
  }
})
class Swiper extends Vue {
  // 列表数据
  @Prop() dataSource
  // 更多
  @Prop() more
  // 每页几条数据
  @Prop({ default: 3 }) pageSize
  // 过渡动画时长
  @Prop({ default: 500 }) duration

  data() {
    return {
      current: 0, // 当前页
      upBtnHover: false,
      nextBtnHover: false
    }
  }
  @Emit('clickItem')
  clickItem(item) {
    return item
  }

  get window() {
    return window
  }

  get exactDivision() {
    return this.dataSource.length % this.pageSize == 0
  }

  get count() {
    return Math.floor(this.dataSource.length / this.pageSize) + (this.exactDivision ? 0 : 1)
  }

  get currentExactPage() {
    return this.exactDivision || this.count - 1 != this.current
  }

  get remaining() {
    return this.dataSource.length - this.pageSize
  }

  get trainStyle() {
    let currentItem = 0
    if (this.current != 0) currentItem = this.current * this.pageSize
    if (!this.currentExactPage) currentItem -= this.pageSize - (this.dataSource.length % this.pageSize)
    return `
      transition-duration: ${this.duration / 1000}s;
      width: calc(100% * ${this.count}); 
      left: -${this.$refs['course-' + currentItem] ? this.$refs['course-' + currentItem][0].offsetLeft || 0 : 0}px;`
  }

  way(val) {
    if (!val) return
    const item = this.dataSource.wf.find(item => item.id === val)
    return item.jump_url
  }

  nextPage() {
    if (this.current === this.count - 1) return
    this.current += 1
  }

  upPage() {
    if (this.current === 0) return
    this.current -= 1
  }
}

export default Swiper
</script>

<style scoped lang="less">
.swiper {
  user-select: none;
  width: 1200px;
  min-width: 1200px;
  z-index: 14;
  margin-top: 40px;
  .sites {
    width: 1210px;
    height: 283px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    user-select: none;
    .trains {
      display: flex;
      height: 100%;
      transition-property: left;
      position: absolute;
      width: auto;
      .carriage {
        height: 100%;
        width: 376px;
        position: relative;
        border-radius: 2px;
        &-right {
          margin-right: 36px;
        }
        .img-box {
          width: 376px;
          height: 212px;
          overflow: hidden;

          .product-cover {
            width: 376px;
            height: 212px;
            transition: all 0.4s;

            &:hover {
              transform: scale(1.1);
            }
          }
        }
        .product {
          &-title {
            font-size: 20px;
            color: #181c25;
            line-height: 21px;
            margin-top: 20px;
            width: 376px;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
          }
          &-vice {
            font-size: 14px;
            color: #666666;
            line-height: 21px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            margin-top: 10px;
          }
          &-topay {
            width: 88px;
            height: 26px;
            background: fade(#ffffff, 90%);
            border-radius: 19px;
            font-size: 12px;
            font-weight: 400;
            color: #c37f7f;
            line-height: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 166px;
            left: 144px;
          }
        }
      }
    }
  }
  &-progress {
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &-up {
      font-size: 38px;
      border-radius: 50%;
      color: #8c96a8;
      transform: rotateY(180deg);
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.25s;
      &-hide {
        color: #d8d8d8;
        &-hover {
          color: #d8d8d8 !important;
        }
      }
    }
    &-next {
      font-size: 38px;
      border-radius: 50%;
      margin-left: 20px;
      color: #8c96a8;
      cursor: pointer;
      &-no {
        color: #d8d8d8;

        &-hover {
          color: #d8d8d8 !important;
        }
      }
    }
    &-point {
      font-size: 20px;
      color: #8c96a8;
      line-height: 43px;
      width: 30px;
    }
  }
  &-more {
    width: 120px;
    height: 36px;
    border-radius: 19px;
    border: 1px solid #bda893;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #bda893;
    transform: translateX(-50%);
    margin-left: 50%;
    margin-top: 21px;
    cursor: pointer;
    &:hover {
      border: 1px solid #bda893;
      background: #bda893;
      color: white;
    }
  }
}
</style>
