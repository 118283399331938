import { Observer } from 'mobx-vue'
import { Vue, Component } from 'vue-property-decorator'

/**************************** 类型 *********************************** */
export type KV = { key: string | number; value: string }

// 课程方向列表
export const ProductDirection: KV[] = [
  {
    key: 0,
    value: '全部方向'
  },
  {
    key: 1,
    value: '书法'
  },
  {
    key: 5,
    value: '国画'
  },
  {
    key: 7,
    value: '书画'
  },
  {
    key: 8,
    value: '美育'
  }
]
// 课程类型列表
export const ProductType: KV[] = [
  {
    key: 0,
    value: '全部分类'
  },
  {
    key: 1,
    value: '直播课'
  },
  {
    key: 2,
    value: '教练日课'
  },
  {
    key: 3,
    value: '展览辅导课'
  },
  {
    key: 4,
    value: '自习课'
  }
]

/******************************* 脚本  **************************************** */
@Observer
@Component
export default class ProductListScript extends Vue {
  directionCurrent: number = 0
  typeCurrent: number = 0
  productDirection: KV[] = ProductDirection
  productType: KV[] = ProductType

  /**
   * 分页器
   */
  get Pagination() {
    return this.$store.$Product.Pagination
  }

  /**
   * 分页器加载参数
   */
  get body() {
    return {
      productType: this.productType[this.typeCurrent].key,
      contentType: this.productDirection[this.directionCurrent].key
    }
  }

  /**
   * 切换课程方向
   */
  switchDirectionCurrent(current: number): void {
    if (current === this.directionCurrent) return
    this.directionCurrent = current
    this._toTop()
  }

  /**
   * 切换课程类型
   */
  switchTypeCurrent(current: number): void {
    if (current === this.typeCurrent) return
    this.typeCurrent = current
    this._toTop()
  }

  private _toTop() {
    this.$store.$global.platform === 'Mobile' && (document.documentElement.scrollTop = 0)
  }

  /**
   * 重置当前选择
   */
  resetSelectCurrent(): void {
    this.directionCurrent = 0
    this.typeCurrent = 0
    this._toTop()
  }

  created() {
    this.Pagination.onReset()
    let { directionCurrent, typeCurrent } = this.$route.query
    if (directionCurrent) this.directionCurrent = parseInt(<string>directionCurrent)
    if (typeCurrent) this.typeCurrent = parseInt(<string>typeCurrent)
  }
}
