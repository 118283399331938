

























import { Component, Prop, Vue, Provide, Emit } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
@Observer
@Component({
  components: {}
})
export default class extends Vue {
  get PageStore() {
    return this.$store.$storeUser
  }
  get locale() {
    return this.$EnumLocaleLinks
  }
  @Emit('toggle')
  onToggle(value) {
    return value //"links_register";
  }
  created() {}
  mounted() {
    this.$store.$wechat.onWxLogin(
      'wx_login_container',
      this.$store.$global.openAppid,
      'login',
      this.$store.$storeUser.usedRepage(window.location.origin + this.$store.$global.base)
    )
  }
  updated() {}
  destroyed() {}
}
