




















































import { Component, Prop, Vue } from 'vue-property-decorator'
import LazyImg from '@/components/lazyImg/index.vue'
import lodash from 'lodash'

const baseSwiperOpt = {
  loop: true,
  autoplay: {
    disableOnInteraction: false
  },
  speed: 2500,
  delay: 3000,
  grabCursor: true, //贴心抓手
  roundLengths: true,
  //allowTouchMove: true,
  autoHeight: true
}

@Component({
  components: {
    LazyImg
  }
})
export default class BMYX extends Vue {
  @Prop() dataSource
  upBtnHover: boolean
  nextBtnHover: boolean
  data() {
    return {
      upBtnHover: false,
      nextBtnHover: false
    }
  }
  get swiper() {
    return (this.$refs.swiper as any)[0].$swiper
  }
  onSwiper(key) {
    lodash.result(this.swiper, key)
  }
  swiperOptions = baseSwiperOpt
  disableAutoPlaySwiperOptions = { ...baseSwiperOpt, autoplay: false }
}
