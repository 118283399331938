










import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component
export default class LivePageView extends Vue {
  @Prop({ default: false }) visible: boolean

  get PageStore() {
    return this.$store.$genseeLive
  }

  @Emit('close')
  onClose() {}

  @Emit('choose')
  chooseHandler(emoji: string) {
    this.onClose()
    return emoji
  }

  @Watch('visible')
  changeVisible(value: boolean) {}

  created() {}
  mounted() {}
  destroyed() {}
}
