































































import { Component, Prop, Vue, Ref, Emit, Watch } from 'vue-property-decorator'
import { FormModel } from 'ant-design-vue'
import { Observer } from 'mobx-vue'
import QRCode from 'qrcode'
import { AjaxBasics } from '@xt/client'
import { action } from 'mobx'
import { getDeviceInfo } from '@xt/client/utils/device'
import { getFp } from '@xt/client/utils/fingerprint/report'

enum EnumQRCodeState {
  /** 待扫描 */
  No_Scan = 0,
  /** 已扫描 */
  Have_Scan = 1,
  /** 已失效 */
  Have_Expire = 2,
  /** 已登录 */
  Have_Login = 3
}

enum EnumAgreementType {
  /** 用户协议 */
  USER = 'user',
  /** 隐私协议 */
  PROVACY = 'privacy'
}

@Observer
@Component({
  components: {}
})
export default class extends Vue {
  @Ref('ruleForm')
  ruleForm: FormModel

  qrcodeUrl: any = ''
  scanState: number = 0
  loading: boolean = false
  canvasHeight: number = 174
  canvasWidth: number = 174
  errorQRcode: boolean = false
  clickQRcodeBox: boolean = false

  get PageStore() {
    return this.$store.$storeUser
  }
  get locale() {
    return this.$EnumLocaleLinks
  }
  get EnumQRCodeState() {
    return EnumQRCodeState
  }
  get EnumAgreementType() {
    return EnumAgreementType
  }

  @action
  changeCheckState() {
    if (this.scanState === EnumQRCodeState.Have_Scan) return
    this.PageStore.checkState = !this.PageStore.checkState
  }

  @Emit('valid')
  validAgreement(type) {
    return {
      checkState: this.PageStore.checkState,
      type
    }
  }

  @Emit('toggle')
  onToggle(value) {
    return value //"links_register";
  }

  changeClickStatus() {
    this.clickQRcodeBox = true
    setTimeout(() => {
      this.clickQRcodeBox = false
    }, 500)
  }

  goProtocolPage(e, type) {
    e.stopPropagation()
    let jumpStr = type === EnumAgreementType.USER ? '/protocol/service' : '/protocol/privacy'
    window.open(jumpStr)
  }
  oldTime: number = 0
  boundTime: number = 5 * 60 * 1000
  deadBoundTime: number = (5 * 60 + 10) * 1000
  pausePolling: boolean = false
  timer: NodeJS.Timer | null = null
  async pollingGetCodeState(uuid: string) {
    if (!uuid || this.pausePolling) return
    let currentTime = AjaxBasics.serviceDate.valueOf()
    let diff: number = currentTime - this.oldTime
    try {
      let res: any = await this.PageStore.onPollingQrcodeState(uuid)
      if (!res && diff > this.boundTime) {
        this.scanState = EnumQRCodeState.Have_Expire
      }
      switch (res?.qrCodeStatus) {
        case EnumQRCodeState.No_Scan:
          // 超出边界展示失效界面
          if (diff > this.boundTime) {
            this.scanState = EnumQRCodeState.Have_Expire
          }
          // 超出死边界  释放
          if (diff > this.deadBoundTime) {
            return
          }
          break
        case EnumQRCodeState.Have_Scan:
          // 从未扫描进来  重新计算5分10秒
          if (this.scanState === EnumQRCodeState.No_Scan || this.scanState === EnumQRCodeState.Have_Expire) {
            this.oldTime = currentTime
            uuid = res.qrCodeId
          }
          this.scanState = EnumQRCodeState.Have_Scan
          // 超出死边界  释放
          if (diff > this.deadBoundTime) {
            this.scanState = EnumQRCodeState.Have_Expire
            return
          }
          break
        case EnumQRCodeState.Have_Expire:
          // 单纯的实效  释放
          this.scanState = EnumQRCodeState.Have_Expire
          return
        case EnumQRCodeState.Have_Login:
          this.scanState = EnumQRCodeState.Have_Login
          this.PageStore.onSetRefreshToken(res.refreshToken)
          // 设置请求参数
          this.$store.$device.authForPCParams = {
            refreshToken: res.refreshToken,
            memberId: res.memberId,
            authDevId: res.ownerDevId
          }
          await this.PageStore.onExpirationRefresh(undefined, {
            'x-authType': '2'
          })
          this.PageStore.onToggleVisible(false)
          if (this.PageStore.isLogin()) {
            this.$msg('登录成功，欢迎你桐学 🎉')
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          }
          return
        default:
          // 未知异常
          return
      }
      this.timer = setTimeout(() => {
        this.pollingGetCodeState(uuid)
      }, 2000)
    } catch (error) {
      this.errorQRcode = true
    }
  }
  loadImage(url: string) {
    return new Promise(resolve => {
      let img: any = new Image()
      img.setAttribute('crossOrigin', 'anonymous')
      img.src = url
      img.onload = () => resolve(img)
      img.onerror = () => resolve(undefined)
    })
  }
  resetScan() {
    clearTimeout(this.timer)
    this.timer = null
    this.init()
  }
  async init() {
    if (this.loading) return
    this.loading = true
    this.errorQRcode = false
    this.scanState = EnumQRCodeState.No_Scan
    const deviceInfo = await getDeviceInfo()
    const {
      extra: { canvas, webgl, audio },
      current
    } = await getFp(this.$store.$global)
    let uuid: any = await this.PageStore.onGetLoginQrcode({
      ...deviceInfo,
      canvas,
      webgl,
      audio,
      xtDevId: current
    })
    // let uuid = '47563d6e093e43488e0028235aa949b8'
    this.oldTime = AjaxBasics.serviceDate.valueOf()
    if (!uuid) this.errorQRcode = true
    this.$nextTick(async () => {
      let canvas: any = this.$refs['canvas']
      let ctx = canvas.getContext('2d')
      let dpr = window.devicePixelRatio || 1
      const time = 2
      canvas.width = this.canvasWidth * time * dpr
      canvas.height = this.canvasHeight * time * dpr
      QRCode.toCanvas(
        canvas,
        `${process.env.hubBaseUrl}/hub/uc/index.html?${uuid}`,
        {
          width: canvas.width,
          height: canvas.height,
          color: {
            dark: '#000000',
            light: '#FAF9F7'
          }
        },
        function (error) {
          if (error) console.error(error)
        }
      )
      // @ts-ignore
      let logo: any = await this.loadImage(this.$images.xt_qrcode_logo)
      if (logo) ctx.drawImage(logo, canvas.width / 2 - 30 * dpr, canvas.height / 2 - 30 * dpr, 30 * 2 * dpr, 30 * 2 * dpr)
      let dataUrl = canvas.toDataURL('image/png')
      this.qrcodeUrl = dataUrl
      this.loading = false
      await this.pollingGetCodeState(uuid)
    })
  }
  async created() {
    await this.init()
  }
  mounted() { }
  updated() { }
  destroyed() {
    this.scanState === EnumQRCodeState.No_Scan
    this.pausePolling = true
  }
}
