/*
 * @Author: Erlin
 * @CreateTime: 2020-12-25 16:21:14
 * @LastEditors: Erlin
 * @LastEditTime: 2020-12-25 16:21:15
 * @Description: 订单详情
 */
import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import { MixinsOrderHelpers } from './helpers'
import { MixinsBasicsHelpers } from '../../basics/helpers'
import { aliases } from 'commander'

@Observer
@Component
export class MixinsOrderDetail extends Mixins(MixinsBasicsHelpers, MixinsOrderHelpers) {
  @Prop({ default: () => {} }) dataSource: IOrderDetails
  get PageStore() {
    return this.$store.$storeOrder
  }
  get orderNo() {
    return this.dataSource.orderNo
  }
  /** 订单退款 */
  async onOrderRefund(jumpCheckRefund: boolean = false) {
    if (this.OrderHelpers.Refund(this.dataSource)) {
      // 订单退款中 则取消退款
      await this.PageStore.onOrderCancelRefund({
        orderNo: this.orderNo,
        refundOrderSn: this.dataSource.refundOrderSn
      })
    } else {
      if (!jumpCheckRefund) {
        // 检验积分积分补偿
        let checkRes: any = await this.PageStore.checkRefund(this.orderNo)
        // 如果积分不足则返回校验信息
        if (!checkRes.adequacy) return checkRes
      }
      await this.PageStore.onOrderRefund(this.orderNo)
    }
    // 正常执行退款或取消逻辑
    return true
  }
  /** 取消订单 */
  async onOrderCancel() {
    let dataSource: any = this.dataSource
    await this.PageStore.onOrderCancel({
      orderNo: this.orderNo
    })
  }
  /**
   * 去支付
   */
  toPayment() {
    let dataSource: any = this.dataSource
    let query = {
      orderNo: this.orderNo,
      productId: dataSource.productId.toString(),
      give: lodash.eq(dataSource.buyWay, 2).toString(),
      memberBeforeOption: true.toString(),
      group: dataSource.activityType == 1 ? true.toString() : false.toString()
    }
    // 微信内 无 wxOpenid id
    if (this.$store.$global.WechatBowser && this.$store.$global.platform === 'Mobile' && !this.$store.$storeUser.wxOpenid) {
      // 获取 code 换取 openid
      window.location.replace(
        this.$store.$wechat.getAuthorizeUrl(window.location.origin + this.$router.resolve({ name: 'payment', query }).href, 'snsapi_base')
      )
      return false
    }
    this.BasicsHelpers.toPayment({
      orderNo: this.orderNo,
      productId: dataSource.productId.toString(),
      give: lodash.eq(dataSource.buyWay, 2),
      memberBeforeOption: true,
      group: dataSource.activityType == 1 ? true : false
    })

    // this.BasicsHelpers.toPayment(query)
    // if (dataSource.goalType == 201) {
    // let query = {
    //   course: String(dataSource.goalId),
    //   give: String(false),
    //   activitys: String(true),
    //   orderNo: this.dataSource.orderNo
    // }
    // if( this.$store.$global.platform==='PC'){
    //   let router = { name:'pay',query}
    //   this.$router.push(router);
    //   return false;
    // }
    // const router = { name: "payment-activitys", query }
    // const router = { name: "payment", query }
    // 微信内 无 wxOpenid id
    // if (this.$store.$global.WechatBowser && this.$store.$global.platform === 'Mobile' && !this.$store.$storeUser.wxOpenid) {
    //   // 获取 code 换取 openid
    //   window.location.replace(
    //     this.$store.$wechat.getAuthorizeUrl(
    //       window.location.origin + this.$router.resolve(router).href,
    //       "snsapi_base"
    //     )
    //   )
    //   return false
    // }
    // this.$router.push(router)
    // } else {
    //   this.BasicsHelpers.toPayment({
    //     orderNo: this.orderNo,
    //     group:false,
    //     groupNo:'',
    //     // courseId: this.dataSource.courseId,
    //     productId: this.dataSource['goalId'].toString(),
    //     give: lodash.eq(this.dataSource.buyWay, 2)
    //   })
    // }
  }
}
