/*
 * @Author: mengxd
 * @CreateTime: 2021-06-07 16:21:14
 * @Description: 去上课
 */
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import { EnumProductTypeQuery } from '@xt/client/enums'

type ActiveItemType = 'progressing' | 'course' | 'daycourse' | 'exhibition' | 'wenfang' | 'selfstudy'

@Observer
@Component
export class MixinsGoCourse extends Vue {
  myProductList = {
    courseResultVOS: [],
    activityResultVOS: [],
    showResultVOS: [],
    selfStudyResultVOS: [],
    progressingResultVOS: []
  }
  //进行中课程
  get progressingCourse() {
    return this.myProductList.progressingResultVOS
  }
  //直播课
  get liveCourse() {
    if (!this.myProductList.courseResultVOS) return []

    return this.myProductList.courseResultVOS.map(v => {
      lodash.set(v, 'type', EnumProductTypeQuery.Course)
      return v
    })
  }
  //日课
  get dayCourse() {
    if (!this.myProductList.activityResultVOS) return []

    return this.myProductList.activityResultVOS.map(v => {
      lodash.set(v, 'type', EnumProductTypeQuery.DayCourse)
      return v
    })
  }
  // 自习课
  get selfStudyCourse() {
    if (!this.myProductList.selfStudyResultVOS) return []

    return this.myProductList.selfStudyResultVOS.map(v => {
      lodash.set(v, 'type', EnumProductTypeQuery.SelfStudy)
      return v
    })
  }
  //展览辅导课
  get exhibitionCourse() {
    if (!this.myProductList.showResultVOS) return []

    return this.myProductList.showResultVOS.map(v => {
      lodash.set(v, 'type', EnumProductTypeQuery.Exhibition)
      return v
    })
  }

  courseList = []
  dataLoading = false
  tabCurIndex = 0
  tabListLength = 5
  changeTabHandler(item: { type: ActiveItemType; index: number }) {
    this.dataLoading = true
    this.tabCurIndex = item.index
    switch (item.type) {
      case 'course':
        this.courseList = this.liveCourse
        break
      case 'daycourse':
        this.courseList = this.dayCourse
        break
      case 'exhibition':
        this.courseList = this.exhibitionCourse
        break
      case 'selfstudy':
        this.courseList = this.selfStudyCourse
        break
      case 'progressing':
        this.courseList = this.myProductList.progressingResultVOS
        break
    }
    setTimeout(() => {
      this.dataLoading = false
    }, 500)
  }

  mounted() {}
  updated() {}
  destroyed() {
    //this.Pagination.onReset()
  }
}
