import lodash from 'lodash'
import { BindAll } from 'lodash-decorators'
import { EnumApiAbout } from '../../api'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { Pagination } from '../basics/pagination'
@BindAll()
export class ControllerAbout {
  constructor(protected $ajax: AjaxBasics) {}
  /**
   * 分页列表数据
   * @memberof ControllerAbout
   */
  Pagination = new Pagination(this.$ajax, {
    url: EnumApiAbout.AboutList,
    key: 'id'
  })
  /**
   * 增加文章浏览量
   */

  async onAboutViewAdd(id) {
    const res = await this.$ajax.put(EnumApiAbout.AboutViewAdd, { id })
    return res
  }
}
export default ControllerAbout
