/**
 * @author mengxd
 * @email lengyingxin8966@gmail.com
 * @create date 2021-11-11 20:25:53
 * @modify date 2021-11-11 20:25:53
 * @desc
 */
import lodash from 'lodash'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { EnumApiOrder } from '../../api'
import { EnumCourseSignupType } from '../../enums'
import { MixinsCourseHelpers } from './helpers'
@Component
export class MixinsActivitySignup extends Mixins(MixinsCourseHelpers) {
  /** 课程数据 */
  @Prop({ default: () => ({}) }) dataSource: any
  /** 课程剩余倒计时 */
  @Prop({ default: () => ({}) }) statusText: any
  /** 按钮类型 默认自己购买 */
  @Prop({ default: EnumCourseSignupType.Purchase }) type: EnumCourseSignupType
  /** 进详情 */
  @Prop({ default: false }) to
  @Prop() buttonText
  @Prop() buttonType
  // 平台
  get platform() {
    return this.$store.$global.platform
  }
  get PageStore() {
    return this.$store.$storeCourse
  }
  get UserStore() {
    return this.$store.$storeUser
  }
  /**
   * 是否参与报名
   */
  get hasbuy() {
    return this.dataSource.enrolled
  }
  get canSignUp() {
    let serviceDate = this.$ajax.serviceDate().valueOf()
    return this.dataSource.signStatus && serviceDate > this.dataSource.signUpStartTime && serviceDate < this.dataSource.signUpEndTime
  }
  /**
   * 自己买
   * @readonly
   * @memberof MixinsCourseSignup
   */
  get IsPurchase() {
    return lodash.eq(this.type, EnumCourseSignupType.Purchase)
  }
  /**
   * 送人
   * @readonly
   * @memberof MixinsCourseSignup
   */
  get IsGift() {
    return lodash.eq(EnumCourseSignupType.Gift, this.type)
  }
  /** 禁用 购买按钮 */
  get disabled() {
    if (this.IsPurchase) {
      //如果已经报名，则不可点击
      if (this.dataSource.enrolled) {
        return true
      }
      //报名已结束
      if (this.dataSource.signStatus && this.$ajax.serviceDate() > this.dataSource.signUpEndTime) {
        return true
      }
      //如果能报名，且在有效期内，则可点击
      //  if (this.dataSource.canSignUp) {
      //   return false
      //  }
      if (!this.canSignUp) {
        return true
      }
      return false
      //  if (this.dataSource.isSingleHasOwn) {
      //   return !this.dataSource.isSignupTurn
      //  }
      //  return !this.dataSource.isSignup
    }

    return !this.dataSource.isSignupGift
  }
  get SignupEnded() {
    return this.$ajax.serviceDate() > this.dataSource.signUpEndTime
  }
  get btnText() {
    // 自买文字
    if (this.IsPurchase) {
      //  let canSignUpByTime = (this.$ajax.serviceDate() > this.dataSource.original.signUpStartTime);
      //如果canSignUp 为true，还需要判断时间是否为可以
      if (!this.dataSource.signStatus || this.$ajax.serviceDate() < this.dataSource.signUpStartTime) {
        return '即将报名' //"报名未开始"
      }
      // 报名已结束
      if (this.$ajax.serviceDate() >= this.dataSource.signUpEndTime) {
        return '报名结束'
      }
      // 已报名
      if (this.dataSource.enrolled) {
        return '已报名'
      }
      if (this.dataSource.productInfo && this.dataSource.productInfo.activityType == 0) {
        return '我要报名'
      }
      return '自己报 ' + this.dataSource.productInfo.discountPrice / 100 + '元'
    }
  }
  get btnType() {
    if (this.buttonType) {
      return this.buttonType
    }
    return {
      [EnumCourseSignupType.Purchase]: 'yellow',
      [EnumCourseSignupType.Give]: { PC: 'primary' }[this.platform],
      [EnumCourseSignupType.Gift]: undefined
    }[this.type]
  }
  get block() {
    return {
      [EnumCourseSignupType.Purchase]: true,
      [EnumCourseSignupType.Give]: true,
      [EnumCourseSignupType.Gift]: undefined
    }[this.type]
  }
  get classStr() {
    return 'xt-signup-' + this.type
  }

  /**
   * 显示礼物图标
   * @readonly
   * @memberof MixinsCourseSignup
   */
  get showGiftIcon() {
    if (lodash.eq(this.type, EnumCourseSignupType.Gift)) {
      return this.dataSource.isHasOwn && this.dataSource.isSignupGift
    }
    return true
  }

  /**
   * 协议 显示
   * @memberof MixinsCourseSignup
   */
  VisibleAgreement = false

  /*
   * 绑定微信
   */
  VisibleBindWX = false

  /**
   * 是否有待支付订单
   * **/
  VisibleHasPay = false

  /**
   * 待支付订单弹窗消息
   * **/
  checkPopupData = {
    title: '',
    waitPrice: '',
    count: 0,
    ratio: 8,
    mainText: '',
    viceText: '',
    mainHeader: '',
    mainHandler: () => {},
    viceHandler: () => {}
  }
  /**
   * 单独购买 继续支付
   * **/
  singleBuyContinue(errorData, oldOrderNo) {
    this.payOrigin = true
    this.$router.push({
      name: 'payment',
      query: {
        group: String(false), //因为是单独购买
        orderNo: String(oldOrderNo),
        productId: this.productId,
        give: errorData.buyWay == 1 ? String(false) : String(true),
        memberBeforeOption: String(true)
      }
    })
  }
  async singleBuyCancel(errorData, oldOrderNo) {
    this.payOrigin = false
    await this.$store.$storeOrder.onOrderCancel({ orderNo: oldOrderNo })
    this.VisibleAgreement = true
    this.VisibleHasPay = false
    // this.$router.push({
    //   name:"payment",
    //   query:{
    //     group: String(false),//因为是单独购买
    //     productId: this.productId,
    //     give: errorData.buyWay ==1 ? String(false) : String(true)
    //   }
    // });
  }

  /**
   * 已发起批团待支付，取消原订单继续支付
   * **/
  async hasGroupOrderSingleCancel(oldOrderNo) {
    //取消原订单，重新创建团
    this.payOrigin = false
    await this.$store.$storeOrder.onOrderCancel({ orderNo: oldOrderNo })
    //如果是想参团
    if (this.isGroupBuy) {
      //如果取消成功，则弹出来
      this.oldOrderNo = ''
      this.groupNo = ''
    }
    this.VisibleHasPay = false
    this.VisibleAgreement = true
  }
  /**
   * 已发起批团待支付，继续支付
   * **/
  async hasGroupOrderSingleContinue(oldOrderNo) {
    this.payOrigin = true

    this.$router.push({
      name: 'payment',
      query: {
        group: String(true),
        orderNo: String(oldOrderNo),
        productId: this.productId,
        memberBeforeOption: String(true)
      }
    })
  }

  payOrigin = false
  oldOrderNo = '' //老的订单号
  popOrderChoose(errorData: any, group: boolean = true, create: boolean = false) {
    const { payAmount, virtualCurrency, oldOrderNo } = errorData
    this.productId = errorData.productId
    //所有的订单，待支付订单【1，继续支付，只需要传orderNo,2,重新下单的时候，只需要区分orderNo 和groupNo 即可】
    // return false;
    switch (true) {
      case errorData.activityType === 0: // 单独购买
        this.checkPopupData = {
          title: '你已点过【自己报】啦，但还没有支付',
          waitPrice: payAmount,
          count: virtualCurrency / 100 / 8,
          ratio: 8,
          mainText: this.isGroupBuy ? '取消订单，重新报名' : '继续支付', //右侧按钮
          viceText: this.isGroupBuy ? '继续支付' : '取消订单，重新报名',
          mainHeader: '',
          mainHandler: () => {
            this.isGroupBuy ? this.singleBuyCancel(errorData, oldOrderNo) : this.singleBuyContinue(errorData, oldOrderNo)
          },
          viceHandler: async () => {
            this.isGroupBuy ? this.singleBuyContinue(errorData, oldOrderNo) : this.singleBuyCancel(errorData, oldOrderNo)
          }
        }
        break
      case !!errorData.groupNo: // 接受了好友的拼团
        this.checkPopupData = {
          mainHeader: errorData.groupMemberHeaderUrl,
          title: '你已接受好友的【拉手团】邀请，但还没有支付',
          waitPrice: payAmount,
          count: virtualCurrency / 100 / 8,
          ratio: 8,
          mainText: '继续支付',
          viceText: '取消订单，重新报名',
          mainHandler: () => {
            //跳转支付页面,支付
            this.payOrigin = true
            this.$router.push({
              name: 'payment',
              query: {
                group: String(true),
                orderNo: String(oldOrderNo),
                productId: this.productId,
                memberBeforeOption: String(true)
              }
            })
          },
          viceHandler: async () => {
            this.payOrigin = false
            //想单独购买，则取消订单
            if (!this.isGroupBuy) {
              await this.$store.$storeOrder.onOrderCancel({ orderNo: oldOrderNo })
              this.VisibleHasPay = false
              this.VisibleAgreement = true
            }
            //如果是参团（待支付订单），想拼团，则给老的orderNo；groupNo 不需要；group给；
            if (this.isGroupBuy) {
              this.VisibleHasPay = false
              this.VisibleAgreement = true
              this.oldOrderNo = oldOrderNo
              this.groupNo = ''
            }
          }
        }
        break
      default: // 自己创建了拼团待支付
        this.checkPopupData = {
          title: '你已发起【拉手团】啦，但还没有支付',
          waitPrice: payAmount,
          count: virtualCurrency / 100 / 8,
          ratio: 8,
          mainText: this.isGroupBuy ? '取消订单，重新报名' : '继续支付',
          viceText: this.isGroupBuy ? '继续支付' : '取消订单，重新报名',
          mainHeader: '',
          viceHandler: async () => {
            this.isGroupBuy ? this.hasGroupOrderSingleContinue(oldOrderNo) : this.hasGroupOrderSingleCancel(oldOrderNo)
          },
          mainHandler: () => {
            //需要带过去orderNo
            this.isGroupBuy ? this.hasGroupOrderSingleCancel(oldOrderNo) : this.hasGroupOrderSingleContinue(oldOrderNo)
          }
        }
    }
  }
  groupNo: any = ''
  productId: any = ''
  /**
   * 是否是团购
   * **/
  isGroupBuy = false
  setGroupBuy(groupBuyWay: boolean) {
    this.isGroupBuy = groupBuyWay
  }
  /**
   * 待支付订单详细信息
   * **/
  hasPayInfo = {
    group: '',
    groupNo: '',
    orderNo: '',
    payAmount: '',
    score: 0,
    status: '',
    virtualCurrency: 0,
    activityType: 0
    //  memberBeforeOption:true
  }
  /**
   * 重新下单
   * **/
  async placeOrder() {
    //先取消原订单
    let orderNo = this.hasPayInfo.orderNo
    //  return false;
    //如果是参团或拼团
    if (this.hasPayInfo.activityType == 1) {
      this.VisibleHasPay = false
      this.VisibleAgreement = true
    }
    //如果是自己购买的,取消原订单
    else {
      let res = await this.$ajax.put(EnumApiOrder.OrderCancel, { orderNo })
      //如果取消成功,则弹出来协议
      if (res == true) {
        this.VisibleHasPay = false //隐藏掉重新下单
        this.VisibleAgreement = true
      }
    }
  }
  /**
   * 继续支付
   * **/
  rePayOrder() {
    let query = {}
    //如果是参团，需要把团购号带过去，订单号带过去
    if (this.hasPayInfo.groupNo != '') {
      query = {
        productId: lodash.get(this.dataSource, 'productInfo.id'),
        orderNo: this.hasPayInfo.orderNo,
        give: 'false',
        memberBeforeOption: 'true',
        groupNo: String(this.hasPayInfo.groupNo),
        group: String(true)
      }
    }
    //如果是拼团，只把group带过去，订单号带过去
    else if (this.hasPayInfo.groupNo == '') {
      query = {
        productId: lodash.get(this.dataSource, 'productInfo.id'),
        orderNo: this.hasPayInfo.orderNo,
        give: 'false',
        memberBeforeOption: 'true',
        group: String(true)
      }
    }
    //非团
    else {
      query = {
        productId: lodash.get(this.dataSource, 'productInfo.id'),
        orderNo: this.hasPayInfo.orderNo,
        give: 'false',
        memberBeforeOption: 'true'
      }
    }
    this.$router.push({
      name: 'payment',
      query
    })
  }

  /**
   * 报名购买
   */
  async onSignup(isGroup?) {
    try {
      if (!isGroup && this.dataSource.productInfo?.activityType === 0 && !this.dataSource.canSignUp)
        return this.$msg('未报名指定课程，无法参加日课')
      //如果有to属性，则跳转
      if (this.to) {
        return this.$router.push({
          name: 'activitys-course-id',
          params: { id: lodash.get(this.dataSource, 'id', this.to) }
        })
      }
      // 检测用户是否登录
      this.$InspectUser()

      //如果是赠课，直接过
      if (lodash.eq(this.type, EnumCourseSignupType.Give)) {
        this.onCheckDevice()
        this.VisibleAgreement = true
        return false
      }
      //检测用户是否绑定微信
      if (!this.UserStore.isWxBind) {
        this.VisibleBindWX = true
        this.bindWeixin()
        throw '绑定微信'
      }
      this.onCheckDevice()
      let query = {
        group: isGroup ? 'true' : 'false',
        groupNo: this.$route.query.groupNo ? this.$route.query?.groupNo : null,
        productId: this.dataSource.productInfo.id
      }

      let toBePaid: any = await this.$ajax.get(EnumApiOrder.checkProduct, query)

      //校验用户是否绑定微信
      if (!toBePaid.ownerWx) {
        this.VisibleBindWX = true
        this.bindWeixin()
        throw '绑定微信'
      }

      //区分是否有团长 团员 自己购买
      if (toBePaid.oldOrder && toBePaid.oldOrderNo != '') {
        this.VisibleHasPay = true
        this.hasPayInfo = toBePaid
        this.hasPayInfo.orderNo = toBePaid.oldOrderNo
        this.popOrderChoose(this.hasPayInfo, this.isGroupBuy, false)
        return false
      }

      this.VisibleAgreement = true
    } catch (error) {
      console.log('LENG ~ MixinsCourseSignup ~ onSignup ~ error', error)
      this.VisibleAgreement = false
    }
  }
  bindWeixin() {
    setTimeout(() => {
      try {
        this.$store.$wechat.onWxLogin(
          'wx_login_container',
          this.$store.$global.openAppid,
          'bind',
          window.location.origin + this.$router.resolve({ name: this.$route.name }).href,
          'data:text/css;base64,LyogLmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMXB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyMDBweDt9Ci5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30gKi8KLmltcG93ZXJCb3ggLnRpdGxlewogICAgZGlzcGxheTogbm9uZTsKfQouaW1wb3dlckJveCAuaW5mbyB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnFyY29kZSB7CiAgICB3aWR0aDogMTI0cHg7CiAgICBib3gtc2l6aW5nOiBib3JkZXItYm94OwogICAgbWFyZ2luLXRvcDogMDsKICAgIGJvcmRlcjogbm9uZTsKICAgIG1hcmdpbi10b3A6IC04cHg7CiAgICBtYXJnaW4tbGVmdDogLThweDsKfQouaW1wb3dlckJveCAud3JwX2NvZGV7CiAgICB3aWR0aDoxMTBweDsKfQ=='
        )
      } catch (error) {
        console.log('Clin -> file: bindWx.vue -> line 42 -> PageView -> mounted -> error', error)
      }
    }, 800)
  }
  /**
   * 检测用户完善个人信息
   */
  onCheckDevice() {
    if (this.UserStore.needBindDevice) {
      if (this.platform === 'Mobile') {
        this.$router.push({
          name: 'other-bind'
        })
        throw this.$EnumLocaleLinks.links_bind_device
      }
      this.UserStore.onToggleModal(this.$EnumLocaleLinks.links_bind_device)
      throw this.$EnumLocaleLinks.links_bind_device
    }
    //   用户需要完善个人信息
    if (this.UserStore.onneedCompleteInfo()) {
      // this.onCancel()
      this.UserStore.onToggleModal(this.$EnumLocaleLinks.links_complete)
      // this.UserStore.onSetToPaymentQuery(query)
      throw this.$EnumLocaleLinks.links_complete
    }
  }
  onCancel() {
    this.VisibleAgreement = false
  }
  onBindWXCancel() {
    this.VisibleBindWX = false
  }
  onBindHasPayCancel() {
    this.VisibleHasPay = false
  }
  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
