



































import { Component, Prop, Vue } from 'vue-property-decorator'
import lodash from 'lodash'
import NewSwiper from './newSwiper.vue'
import LazyImg from '@/components/lazyImg/index.vue'

@Component({
  components: {
    NewSwiper,
    LazyImg
  }
})
export default class PartCourse extends Vue {
  // 列表数据
  @Prop() dataSource

  relatedCourses(item): any[] {
    return lodash.chunk(item.singleProductList, 3)
  }

  toDetail(item) {
    if (item.courseType === 2) return window.open(item.activityPageUrl)
    this.$router.push({
      path: `/commodity/${item.productId}`
    })
  }
}
