import { EnumMessage } from '../enumMessage'
export default {
  [EnumMessage.success]: '{0}成功',
  [EnumMessage.error]: '{0}失败',
  [EnumMessage.logged_not]: '未登录',
  [EnumMessage.logged_success]: '登录成功，欢迎你桐学 🎉',
  [EnumMessage.register_success]: '注册成功',
  [EnumMessage.register_error]: '注册失败',
  [EnumMessage.sundrying_success]: '晒作业成功啦',
  [EnumMessage.like_success]: '点赞成功啦',
  [EnumMessage.like_error]: '您已经点过赞了',
  [EnumMessage.delete_success]: '删除成功',
  [EnumMessage.save_success]: '保存成功',
  [EnumMessage.bind_success]: '绑定成功',
  [EnumMessage.wx_not_operation]: '请您在微信客户端内操作',
  [EnumMessage.upload_img_type_error]: '只能上传jpg/png格式图片！',
  [EnumMessage.upload_img_size_error]: '图片必须小于2M！',
  [EnumMessage.upload_img_heif_error]: '上传图片不满足要求，请上传JPG、PNG格式的图片',
  [EnumMessage.course_not_registered]: '您还没有报名该课程！',
  [EnumMessage.course_end_of_class]: '课程已经结课！',
  [EnumMessage.classhour_ont_publish]: '课时还未发布！',
  [EnumMessage.send_code_error]: '发送验证码失败！',
  [EnumMessage.check_code_error]: '验证码错误！',
  [EnumMessage.verify_failed]: '验证失败，请重试',
  [EnumMessage.apply_invoice_success]: '申请发票成功',
  [EnumMessage.cancel_order_success]: '取消订单成功'
}
