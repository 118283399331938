export enum EnumLogoutType {
  /** 须知 */
  NOTICE = 'notice',
  /** 注销条件 */
  CONDITION = 'condition',
  /** 身份认证 */
  AUTH = 'auth',
  /** 检测账户 */
  ACCOUNT = 'account',
  /** 放弃资产 */
  ABANDON = 'abandon'
}

export enum EnumAbandonType {
  /** 放弃课程 */
  COURSE = 'course',
  /** 放弃虚拟资源 */
  RESOURCE = 'resource'
}
