




































































































import { Component, Prop, Vue, Ref, ProvideReactive } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import { EnumLocaleLinks } from '@xt/client'
import tab_introduction from './tab_introduction.vue'
// import tab_questionanswer from "./tab_questionanswer.vue"
import NewSwiper from './newSwiper.vue'
import LazyImg from '@/components/lazyImg/index.vue'
import PartCourse from './partCourse.vue'
import RelatedFullStage from './relatedFullStage.vue'
import Giveaway from './giveaway.vue'
import WxImgPop from '@xt/www/components/wxImgPop/index.vue'
import { CSSProperties } from '@vue/runtime-dom'

@Observer
@Component({
  components: {
    [EnumLocaleLinks.links_courseIntroduction]: tab_introduction,
    // [EnumLocaleLinks.links_questionAnswer]: tab_questionanswer,
    LazyImg,
    NewSwiper,
    PartCourse,
    RelatedFullStage,
    Giveaway,
    WxImgPop
  }
})
export default class PageView extends Vue {
  @Prop({}) dataSource
  @ProvideReactive('dataSource') courseInfo = {}
  @Ref('xtShowRight') xtShowRight: HTMLDivElement
  @Ref('xtShowLeft') xtShowLeft: HTMLDivElement
  @Ref('xtShowContent') xtShowContent: HTMLDivElement
  activeKey: string = '' // 切换课程介绍与报名问答
  stickClassName: string = '' // 贴底状态样式类名
  currentIndex = null
  visibleWenfangPopup = false

  get tableTag() {
    let tabs = [
      { key: this.$EnumLocaleLinks.links_courseIntroduction, title: '课程介绍' }
      // { key: this.$EnumLocaleLinks.links_questionAnswer, title:"报名问答" }
    ]
    return tabs
  }
  get fullCourse() {
    return this.dataSource.productInfo.fullProductDetail
  }
  get productTools(): any {
    return lodash.chunk(this.dataSource.productInfo.productTools, 4)
  }
  get toolsHeight() {
    return this.productTools[0].length > 2 ? (this.productTools[1] ? 298 : 268) : 124
  }
  get studentWelfareInfo() {
    return this.$store.$storeCourse.Details.studentWelfareInfo
  }
  get isWelfare() {
    return this.studentWelfareInfo?.productIds.includes(+this.$route.params.id)
  }
  changeActiveKey(key) {
    this.activeKey = key
  }
  ImagePreview() {
    this.$showViewer(this.dataSource.productInfo.curriculumUrl)
  }

  wfPopupContent: any = {}
  wfPopupShow = false
  showWFPopup(content) {
    this.wfPopupContent = content
    this.wfPopupShow = true
    document.body.style.height = '100%'
    document.body.style.overflow = 'hidden'
  }
  closeWFPopup() {
    this.wfPopupShow = false
    this.wfPopupContent = {}
    document.body.style.height = 'auto'
    document.body.style.overflow = 'auto'
  }
  stopPropagation(e) {
    e.stopPropagation()
  }

  rightStyle: CSSProperties = {
    position: 'absolute',
    top: '0',
    right: '0'
  }

  onScroll() {
    const scrollClientHeight = document.documentElement.scrollTop + document.documentElement.clientHeight
    const contentOffsetTop = this.xtShowContent.offsetTop
    const rightOffsetHeight = this.xtShowRight.offsetHeight
    const leftOffsetHeight = this.xtShowLeft.offsetHeight
    if (this.xtShowRight.offsetHeight > this.xtShowLeft.offsetHeight) {
      this.rightStyle = {}
      // window.removeEventListener('scroll', this.onScroll)
      return
    }
    // 滑动到右侧的顶部  若一屏幕展示完全
    if (document.documentElement.clientHeight - 100 > rightOffsetHeight) {
      if (document.documentElement.scrollTop + 100 >= contentOffsetTop) {
        if (scrollClientHeight >= contentOffsetTop + leftOffsetHeight) {
          this.rightStyle = {
            position: 'absolute',
            top: `${leftOffsetHeight - rightOffsetHeight}px`,
            right: '0'
          }
          return
        }
        this.rightStyle = {
          position: 'fixed',
          top: '100px',
          right: `${(document.documentElement.clientWidth - 8 - 1200) / 2}px`
        }
      } else {
        this.rightStyle = {
          position: 'absolute',
          top: '0',
          right: '0'
        }
      }
      return
    }
    // 滑动到右侧的顶部  若一屏幕无法展示完全  看到右侧底部了  可以变动top值了
    if (scrollClientHeight > contentOffsetTop + rightOffsetHeight) {
      if (scrollClientHeight >= contentOffsetTop + leftOffsetHeight) {
        this.rightStyle = {
          position: 'absolute',
          top: `${leftOffsetHeight - rightOffsetHeight}px`,
          right: '0'
        }
        return
      }
      this.rightStyle = {
        position: 'fixed',
        bottom: '0',
        // -8是因为/www/assets/style/scrollbar.less下设置的滚动条宽度为0.5rem
        right: `${(document.documentElement.clientWidth - 8 - 1200) / 2}px`
      }
    } else {
      this.rightStyle = {
        position: 'absolute',
        top: '0',
        right: '0'
      }
    }
  }

  created() {
    this.activeKey = this.tableTag[0].key
    this.courseInfo = this.dataSource

    this.$nextTick(() => {
      // if (this.xtShowRight.offsetHeight > this.xtShowLeft.offsetHeight) return (this.rightStyle = {})
      window.addEventListener('scroll', this.onScroll)
    })
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  }
}
