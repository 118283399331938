















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import IdentityVerify from './views/identityVerify.vue'
import UnBindWx from './views/unBindWx.vue'

@Observer
@Component({
  scrollToTop: true,
  components: { UnBindWx, IdentityVerify }
})
export default class PageView extends Vue {
  signoutShow: boolean = false
  auth: number = 0 // 0未验证 1验证通过 2 验证未通过
  logouted: boolean = false
  beforeres: string = ''
  bodyStyle: any = {
    backgroundColor: '#FAF9F7',
    width: '520px',
    height: '520px'
  }
  currentClickType: string = ''
  get PageStore() {
    return this.$store.$storeUser
  }
  get isSetCode() {
    return this.PageStore.UserInfo.phoneNum || this.PageStore.UserInfo.email
  }
  get displayBindEmail() {
    let email = this.PageStore.UserInfo.email
    let emailSlice = email.split('@')
    if (emailSlice[0].length === 1) {
      return email.slice(0, 1) + '****@' + emailSlice[1]
    } else {
      return email.slice(0, 2) + '****@' + emailSlice[1]
    }
  }
  changeVisibleStatus(status) {
    this.$store.$storeUser.onChangeBindVisible(status)
  }
  EditorSetting(type) {
    if (type === 'password') {
      if (!this.PageStore.UserInfo.phoneNum && !this.PageStore.UserInfo.email) {
        this.$msg('您还未绑定手机号或邮箱')
        return
      }
    }
    this.changModalStyle('other')
    this.currentClickType = type
    this.changeVisibleStatus(true)
  }
  EditorBind(type) {
    if (type === 'bindPhone') {
      this.EditorSetting('phone')
    } else {
      this.EditorSetting('email')
    }
  }
  onOk() {
    this.changeVisibleStatus(false)
  }
  onCancel() {
    this.changeVisibleStatus(false)
  }
  async onOutLogin() {
    await this.PageStore.onLogout()
    await this.PageStore.onOutLogin()
    window.location.replace(this.$router.resolve('/').href)
  }
  isCorrect(type) {
    return type === this.currentClickType
  }
  bindOrUnBindDesc(type) {
    if (type === 'phone') {
      return this.PageStore.UserInfo.phoneNum ? '更换手机号' : '绑定手机号'
    }
    if (type === 'email') {
      return this.PageStore.UserInfo.email ? '更换邮箱' : '绑定邮箱'
    }
  }
  changModalStyle(other?: any) {
    if (other) {
      this.bodyStyle.height = '520px'
    } else {
      this.bodyStyle.height = '224px'
    }
  }
  bindWXHandler() {
    this.PageStore.showWeiXinBind({
      title: '绑定微信',
      content: '使用微信扫描小程序码完成绑定'
    })
  }
  verifyWXHandler() {
    this.PageStore.showWeiXinVerify({
      title: '验证微信',
      content: '扫码验证绑定微信与使用微信是否一致'
    })
  }
  onUnbindWx() {
    if (this.PageStore.needBindDevice) {
      return this.$msg('您未绑定邮箱及手机号，不允许解绑微信')
    }
    this.currentClickType = 'unBindWx'
    this.changeVisibleStatus(true)
    this.changModalStyle()
  }
  // 显示注销弹窗
  showSignout() {
    this.signoutShow = true
  }
  // 隐藏注销弹窗
  signoutHide() {
    this.signoutShow = false
  }
  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
