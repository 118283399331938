/**
 * @author 冷 (https://github.com/LengYXin)
 * @email lengyingxin8966@gmail.com
 * @create date 2021-01-20 20:15:10
 * @modify date 2021-01-20 20:15:10
 * @desc [description]
 */
import lodash from 'lodash'
import { EnumCourseState, EnumCourseType, EnumCoursePublishStatus, EnumProductType } from '../../enums'
import { BindAll } from 'lodash-decorators'
import moment from 'moment'
import { AjaxBasics } from '../../helpers'
import { reserveArrayBuffer } from 'mobx/lib/internal'
class CourseBasics {
  /**
   * 原始数据
   * @memberof Course
   */
  original: ICourseDetails
  /**
   * 课程ID
   */
  get id() {
    return this.original.id
  }
  /**
   * 绑定全阶 id
   */
  get courseFullId() {
    return this.original.courseFullId
  }
  /**
   * 已购买课程
   */
  get hasOwn() {
    return this.original.hasOwn
  }
  /**
   * 已购买全阶课程
   */
  get fullHasOwn() {
    return this.original.fullHasOwn
  }
  /**
   * 已经发布
   */
  get isPublished() {
    return lodash.eq(this.original.publishStatus, EnumCoursePublishStatus.Published)
  }
  /**
   * 报名开关已打开 是否可报名使用 isSignup
   */
  get isCanSignUp() {
    return this.isPublished && this.original.canSignUp
  }
  /**
   * 课程名称
   */
  get courseName() {
    return this.original.courseName
  }
  /**
   * 课程简介
   */
  get courseSubName() {
    return this.original.courseSubName
  }

  /**
   * 课程封面图
   */
  get coverUrl() {
    return this.original.coverUrl
  }
  /**
   * 课程原价
   */
  get originalPrice() {
    return lodash.toInteger(this.original.originalPrice)
  }
  /**
   * 课程优惠价
   */
  get discountPrice() {
    return lodash.toInteger(this.original.discountPrice)
  }
  /**
   * 介绍图
   */
  get pictureList() {
    return this.original.pictureList
  }
  /**
   * 介绍图PC
   */
  get pictureListPC() {
    return this.original.pictureListPC
  }
  /**
   * 介绍图H5
   */
  get pictureListH5() {
    return this.original.pictureListH5
  }
  /**
   * 第一次进入
   */
  get viewPrepare() {
    return this.original.viewPrepare
  }
  /**
   * 开课前请了解
   */
  get coursePrepare() {
    return this.original.coursePrepare
  }
  /**
   * 模板类型
   */
  get templateType() {
    return this.original.templateType
  }

  /**
   * 报名截至
   */
  get signUpEndTime() {
    if (this.original.signUpEndTime) {
      return moment(this.original.signUpEndTime).startOf('seconds').valueOf()
    }
    return 0
  }
  /**
   * 转报截至
   */
  get turnSignUpEndTime() {
    if (this.isFull && this.original.turnSignUpEndTime) {
      return moment(this.original.turnSignUpEndTime).startOf('seconds').valueOf()
    }
    return 0
  }
  /**
   * 开课
   */
  get learnStartTime() {
    if (this.original.learnStartTime) {
      return moment(this.original.learnStartTime).startOf('seconds').valueOf()
    }
    return 0
  }
  /**
   * 结课
   */
  get learnEndTime() {
    if (this.original.learnEndTime) {
      return moment(this.original.learnEndTime).startOf('seconds').valueOf()
    }
    return 0
  }
  /**
   * 全阶结课
   */
  get fullLearnEndTime() {
    if (this.isSingle && this.original.fullLearnEndTime) {
      return moment(this.original.fullLearnEndTime).startOf('seconds').valueOf()
    }
    return 0
  }

  /**
   * 单阶课
   * @readonly
   * @memberof CourseBasics
   */
  get isSingle() {
    return lodash.eq(this.original.courseType, EnumCourseType.single)
  }
  /**
   * 全阶课
   * @readonly
   * @memberof CourseBasics
   */
  get isFull() {
    return lodash.eq(this.original.courseType, EnumCourseType.full) || lodash.eq(this.original.productType, EnumProductType.fullstage)
  }
  /**
   * 课程类型
   */
  get courseBizTypeText() {
    return lodash.get({ 1: '直播课', 2: '录播课' }, this.original.courseBizType)
  }
  /**
   *课程状态
   */
  state: EnumCourseState = EnumCourseState.NotStarted

  /**
   * 课程上架下架状态
   * **/
  get publishStatus() {
    return this.original.publishStatus //1未发布，2已发布，3已下架
  }

  /**
   * 已经购买课程
   */
  isHasOwn = false
  /**
   * 已经购买全阶段
   */
  isFullHasOwn = false
  /**
   * 已经购买全阶下面的单阶
   */
  isSingleHasOwn = false
  /**
   * 可报名
   */
  isSignup = false
  /**
   * 可赠课
   */
  isSignupGift = false
  /**
   * 可转报全阶
   */
  isSignupTurn = false
  /**
   * 倒计时
   */
  isDownTime = false
  /**
   * 倒计时结束时间
   */
  downEndTime = 0
  /**
   * 已开课
   */
  isInClass = false
  /**
   * 已经结课
   */
  isEndofClass = false
  /**
   * 剩余时间
   */
  restTime = 0
}
BindAll()
export class Course extends CourseBasics {
  /**
   *
   * @param dataSource 课程原始数据
   * @param UserHasbuy 用户已经买的课程ids 赠课使用
   * @param isList 列表页面
   * @param isGive 赠课
   */
  constructor(
    dataSource: ICourseDataSource,
    private UserHasbuy = [],
    private isList = false,
    private isGive = false
  ) {
    super()
    this.onInit(dataSource)
  }
  /**
   * 用户已经购买的课程 ids
   */
  static UserHasbuy = []
  /**
   * 用户已经购买的团购课程
   */
  static UserHasbuyGroup = []
  /**
   * 设置用户已经购买课程 ids
   */
  static onSetUserHasbuy(hasbuy: Array<number>, groupHasBuy: Array<any>) {
    Course.UserHasbuy = lodash.cloneDeep(lodash.orderBy(hasbuy))
    Course.UserHasbuyGroup = lodash.cloneDeep(lodash.orderBy(groupHasBuy))
  }
  /**
   * 服务器时间
   */
  serviceDate = AjaxBasics.serviceDate.startOf('seconds').add('milliseconds', 100).valueOf()
  /**
   * 测试日期 方便查看
   */
  get testTimes() {
    return {
      signUpEndTime: this.original.signUpEndTime && moment(this.original.signUpEndTime).format('YYYY-MM-DD HH:mm:ss'),
      turnSignUpEndTime: this.original.turnSignUpEndTime && moment(this.original.turnSignUpEndTime).format('YYYY-MM-DD HH:mm:ss'),
      learnStartTime: this.original.learnStartTime && moment(this.original.learnStartTime).format('YYYY-MM-DD HH:mm:ss'),
      learnEndTime: this.original.learnEndTime && moment(this.original.learnEndTime).format('YYYY-MM-DD HH:mm:ss'),
      fullLearnEndTime: this.original.fullLearnEndTime && moment(this.original.fullLearnEndTime).format('YYYY-MM-DD HH:mm:ss'),
      serviceDate: moment(this.serviceDate).format('YYYY-MM-DD HH:mm:ss')
    }
  }
  /**
   * 单节课列表
   */
  get singles() {
    if (this.isFull) {
      let res = lodash.map(
        //  lodash.get(this.original, 'singleList', lodash.get(this.original, "courseSingleList", [])), x => new Course(x, this.UserHasbuy, this.isList, this.isGive)
        lodash.get(this.original.productInfo, 'singleProductList', lodash.get(this.original, 'singleProductList', [])),
        x => {
          return new Course(x, this.UserHasbuy, this.isList, this.isGive)
        }
      )
      return res
    }
    return []
  }
  /**
   * 课程数量统计
   */
  get statistics() {
    if (this.isFull) {
      return lodash.sumBy(this.singles, 'statistics') || this.templateType
    }
    return this.templateType || 0
  }
  get combination() {
    // if (this.isFull) {
    //     return lodash.sumBy(this.singles, 'statistics') || lodash.sumBy(this.singles, 'original.bizProdCount')
    // }
    // if(this.templateType){
    //     return this.templateType
    // }
    return this.original?.productInfo?.bizProdCount || this.original?.bizProdCount || this.original?.classhourCount || 0
  }
  /**
   * 自买报名按钮文本
   */
  get signupText() {
    // 报名已结束
    if (this.isCanSignUp && !this.isSignup && !this.isSignupTurn) {
      return '报名已结束'
    }
    // 已报名
    if (this.isHasOwn) {
      return '已报名'
    }
    // 可转报
    if (this.isSignupTurn && (lodash.eq(this.downEndTime, this.turnSignUpEndTime) || this.isSingleHasOwn)) {
      return '转报全阶'
    }
    return '立即报名'
  }
  /**
   * 倒计时格式化
   */
  get downEndTimeFormat() {
    // 可转报
    if (this.isSignupTurn && lodash.eq(this.downEndTime, this.turnSignUpEndTime)) {
      return '转报截至 YYYY-MM-DD'
    }
    return '报名截至 YYYY-MM-DD'
  }
  /**
   * 已购买单阶的价格
   */
  get hasSinglePrice() {
    return lodash.sumBy(this.singles, item => {
      if (item.isHasOwn) {
        return item.discountPrice
      }
      return 0
    })
  }
  /**
   * 转报全阶价格
   */
  get turnSignUpPrice() {
    if (this.isFull) {
      return lodash.max([this.originalPrice - this.discountPrice, 0])
    }
    return 0
  }
  /**
   * 初始化
   * @param dataSource
   */
  onInit(dataSource) {
    const UserHasbuy = this.isGive ? this.UserHasbuy : Course.UserHasbuyGroup // lodash.concat(this.UserHasbuy, Course.UserHasbuy)
    if (this.isGive) {
      this.original = lodash.cloneDeep(dataSource)
      this.onInitSignup()
      // 初始课程状态
      this.onInitState()
      // 计算是否已经开课
      this.isInClass = lodash.includes([EnumCourseState.InClass, EnumCourseState.EndofClass], this.state)
      // 已购买课程
      this.isHasOwn = this.hasOwn || lodash.includes(UserHasbuy, this.id)
      // 结课
      this.isEndofClass = lodash.eq(EnumCourseState.EndofClass, this.state)
      if (this.isFull) {
        // 已购买其中单阶
        this.isSingleHasOwn = this.singles.some(x => lodash.includes(UserHasbuy, x.id))
      } else {
        // 已购买全阶
        this.isFullHasOwn = this.fullHasOwn || lodash.includes(UserHasbuy, this.courseFullId)
        // 单阶已结课，已购买全
        if (this.isFullHasOwn && lodash.eq(EnumCourseState.EndofClass, this.state)) {
          this.isEndofClass = lodash.min([this.serviceDate, this.fullLearnEndTime]) === this.fullLearnEndTime
        }
      }
      this.onInitDownTime()
      return
    }
    this.original = lodash.cloneDeep(dataSource)
    this.onInitSignup()
    // 初始课程状态
    this.onInitState()
    // 计算是否已经开课
    this.isInClass = lodash.includes([EnumCourseState.InClass, EnumCourseState.EndofClass], this.state)
    // 已购买课程
    this.isHasOwn =
      this.hasOwn ||
      UserHasbuy.some(item => {
        return item.bizId == this.original.bizId && item.productType == this.original.productType
      })
    // 结课
    this.isEndofClass = lodash.eq(EnumCourseState.EndofClass, this.state)
    if (this.isFull == true) {
      // 已购买其中单阶
      // this.isSingleHasOwn = this.singles.some(x => lodash.includes(UserHasbuy, x.id))
      this.isSingleHasOwn = this.singles.some(x => {
        let res = UserHasbuy.some(y => {
          return y.productId == x.original.id && y.productType == x.original.productType
        })
        return res
      })
    } else {
      // 已购买全阶
      // this.isFullHasOwn = this.fullHasOwn || lodash.includes(UserHasbuy, this.courseFullId);
      this.isFullHasOwn =
        this.fullHasOwn ||
        lodash.find(UserHasbuy, o => {
          return o.bizId == this.courseFullId
        }) != ''
      // 单阶已结课，已购买全
      if (this.isFullHasOwn && lodash.eq(EnumCourseState.EndofClass, this.state)) {
        this.isEndofClass = lodash.min([this.serviceDate, this.fullLearnEndTime]) === this.fullLearnEndTime
      }
    }
    this.onInitDownTime()
    // if (this.isSingle) {
    //     return
    // }
    // console.warn("LENG ~ Course ", this, UserHasbuy)
  }
  /**
   * 倒计时
   */
  onInitDownTime() {
    const times = [this.serviceDate, this.signUpEndTime]
    // 全阶 添加转报全阶
    if (this.isFull) {
      times.push(this.turnSignUpEndTime)
    }
    const min = lodash.min(lodash.compact(times))
    const downEndTime = lodash.get(
      {
        // 系统时间最小
        [this.serviceDate]: this.signUpEndTime,
        // 新报时间最小
        [this.signUpEndTime]: this.turnSignUpEndTime
      },
      min,
      this.signUpEndTime
    )
    const diff = moment(downEndTime).diff(this.serviceDate, 'seconds')
    this.isDownTime = diff > 0 && diff < 24 * 60 * 60
    this.downEndTime = downEndTime
  }
  /***
   * 报名是否结束
   */

  /**
   * 可报名
   * @readonly
   * @memberof Course
   */
  onInitSignup() {
    // 开启报名
    if (this.isCanSignUp) {
      this.isSignup = lodash.min([this.serviceDate, this.signUpEndTime]) === this.serviceDate
      if (this.isFull) {
        this.isSignupTurn = lodash.min([this.serviceDate, this.turnSignUpEndTime]) === this.serviceDate
      }
      this.isSignupGift = this.isSignup || this.isSignupTurn
    }
  }
  /**
   * 初始化课程状态
   */
  onInitState() {
    const isList = this.isList,
      serviceDate = this.serviceDate,
      learnEndTime = this.learnEndTime,
      signUpEndTime = this.signUpEndTime,
      learnStartTime = this.learnStartTime

    //如果课程是已下架状态
    if (this.publishStatus == 3) {
      ;[
        // 已经结课
        {
          condition: () => {
            // 结课<当前
            return lodash.min([serviceDate, learnEndTime]) === learnEndTime
          },
          state: EnumCourseState.EndofClass
        },
        // 未开课
        {
          condition: () => {
            return lodash.min([serviceDate, learnStartTime]) === serviceDate
            // 报名<当前<开课
            //  const line = [signUpEndTime, serviceDate, learnStartTime]
            //  return lodash.min(line) === signUpEndTime && lodash.max(line) === learnStartTime
          },
          state: EnumCourseState.NoClass
        },
        // 开课中
        {
          condition: () => {
            // 开课<当前<结课
            const line = [learnStartTime, serviceDate, learnEndTime]
            return lodash.min(line) === learnStartTime && lodash.max(line) === learnEndTime
          },
          state: EnumCourseState.InClass
        }
      ].reduce((prev, curr) => {
        // 条件以成立 直接跳过
        if (!prev) {
          const condition = curr.condition()
          if (condition) {
            this.state = curr.state
          }
          return condition
        }
        return true
      }, false)
      return false
    }
    ;[
      // 已经结课
      {
        condition: () => {
          // 结课<当前
          return lodash.min([serviceDate, learnEndTime]) === learnEndTime
        },
        state: EnumCourseState.EndofClass
      },
      // 报名未开始
      {
        condition: () => {
          // 未发布
          if (!this.isPublished) {
            return false
          }
          return !this.isCanSignUp
        },
        state: EnumCourseState.NotStarted
      },
      // 正在报名
      {
        condition: () => {
          // 未发布
          if (!this.isPublished) {
            return false
          }
          const line = [serviceDate, signUpEndTime, learnStartTime]
          // 开课时间在截止之前
          // if (isList && lodash.max(line) === signUpEndTime) {
          //     const min = lodash.min(line)
          //     return min === serviceDate || min === learnStartTime
          // }

          // 当前<报名
          return lodash.min(line) === serviceDate
        },
        state: EnumCourseState.InProgress
      },
      // 未开课
      {
        condition: () => {
          // 报名<当前<开课
          const line = [signUpEndTime, serviceDate, learnStartTime]
          return lodash.min(line) === signUpEndTime && lodash.max(line) === learnStartTime
        },
        state: EnumCourseState.NoClass
      },
      // 开课中
      {
        condition: () => {
          // 开课<当前<结课
          const line = [learnStartTime, serviceDate, learnEndTime]
          return lodash.min(line) === learnStartTime && lodash.max(line) === learnEndTime
        },
        state: EnumCourseState.InClass
      }
    ].reduce((prev, curr) => {
      // 条件以成立 直接跳过
      if (!prev) {
        const condition = curr.condition()
        if (condition) {
          this.state = curr.state
        }
        return condition
      }
      return true
    }, false)
  }
}

export interface ICourseDataSource {
  /** 开启报名 */
  canSignUp?: boolean
  courseBizType?: number
  courseContentType?: number
  courseFullId?: number
  courseName?: string
  coursePrepare?: string
  courseSubName?: string
  courseType?: number
  coverUrl?: string
  defaultPlaybackDuration?: number
  discountPrice?: string
  fullHasOwn?: boolean
  fullLearnEndTime?: number
  fullSignUpEndTime?: number
  fullTurnSignUpEndTime?: number
  hasOwn?: boolean
  id?: number
  bizId?: number
  learnStartTime?: number
  learnEndTime?: number
  publishStatus?: number
  originalPrice?: string
  pictureList?: Array<any>
  refundEndTime?: number
  signUpEndTime?: number
  templateType?: number
  turnSignUpEndTime?: any
  viewPrepare?: boolean
  contentType?: number
}
