
















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import lodash from 'lodash'
import { Observer } from 'mobx-vue'
import LazyImg from '@/components/lazyImg/index.vue'

@Observer
@Component({
  components: { LazyImg }
})
export default class PageView extends Vue {
  @Prop({ default: () => {} }) dataSource
  get currentId() {
    return lodash.get(this.dataSource, 'id', null)
  }
  get PageStore() {
    return this.$store.$storeVideo
  }
  get Pagination() {
    return this.PageStore.RecommendPagination
  }
  get id() {
    return this.$route.params.id
  }
  created() {
    this.Pagination.onLoading()
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
