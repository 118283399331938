function splitString(str: string, arr: string[]) {
  const reg = new RegExp(arr.join('|'), 'g')
  str = str.replaceAll(reg, token => {
    return `$$__${token}$$__`
  })
  const result = str.split('$$__')

  return result
}

export type ParsedResult = {
  type: 'Normal' | 'Keyword'
  text: string
}

export function parseContent(content: string, keywords = ['暄桐客服', '暄桐教室客服']): Array<ParsedResult> {
  if (!content) {
    return []
  }

  return splitString(content, keywords).map(item => {
    if (keywords.includes(item)) {
      return {
        type: 'Keyword',
        text: item
      }
    } else {
      return {
        type: 'Normal',
        text: item
      }
    }
  })
}
