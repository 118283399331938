





















































import { Observer } from 'mobx-vue'
import { Component } from 'vue-property-decorator'
import Product from '../../../components/product/product.vue'
import ProductListScript from '@xt/client/mixins/product/list'
import LazyImg from '@/components/lazyImg/index.vue'

@Observer
@Component({
  name: 'CourseListComp',
  components: { Product, LazyImg }
})
export default class CourseListComp extends ProductListScript {
  // 是否在微信内
  get isWechatBowser() {
    return this.$store.$global.WechatBowser
  }
  // head() {
  //     return this.$AppCreateShareData({ title: "全部课程" });
  // }
  mounted() {
    document.getElementsByTagName('main')[0].style.backgroundColor = '#F6F6F6'
  }

  beforeDestroy() {
    document.getElementsByTagName('main')[0].style.backgroundColor = '#ffffff'
  }
}
