import { EnumLocaleLinks } from '../enumLocale'
export default {
  [EnumLocaleLinks.links_home]: 'Home',
  [EnumLocaleLinks.links_course]: 'Course',
  [EnumLocaleLinks.links_video]: 'Video',
  [EnumLocaleLinks.links_stationery]: 'Stationery',
  [EnumLocaleLinks.links_about]: 'About',
  [EnumLocaleLinks.links_my]: 'Go',
  [EnumLocaleLinks.links_register]: 'Register',
  [EnumLocaleLinks.links_signin]: 'Sign In',
  [EnumLocaleLinks.links_wx_login]: 'WeChat Login',
  [EnumLocaleLinks.links_phone_login]: 'Phone Login',
  [EnumLocaleLinks.links_email_login]: 'Overseas Login',
  [EnumLocaleLinks.links_bind_device]: 'Bind Device',
  [EnumLocaleLinks.links_retrieve]: 'Retrieve',
  [EnumLocaleLinks.links_courseIntroduction]: 'Course Introduction',
  [EnumLocaleLinks.links_courseComposition]: 'Course Composition',
  [EnumLocaleLinks.links_goToTheClass]: 'Go to the class',
  [EnumLocaleLinks.links_sunDrying]: 'Sun Drying',
  [EnumLocaleLinks.links_thoughts]: 'Thoughts'
}
