export enum EnumMessage {
  success = 'success',
  error = 'error',
  /** 未登录 */
  logged_not = 'logged_not',
  /** 登录成功 */
  logged_success = 'logged_success',
  /** 注册成功 */
  register_success = 'register_success',
  /** 注册失败 */
  register_error = 'register_error',
  /** 晒作业成功 */
  sundrying_success = 'sundrying_success',
  /** 晒作业失败 */
  sundrying_error = 'sundrying_error',
  /** 点赞成功 */
  like_success = 'like_success',
  /** 点赞失败 */
  like_error = 'like_error',
  /** 删除成功 */
  delete_success = 'delete_success',
  /** 保存成功 */
  save_success = 'save_success',
  /** 绑定成功 */
  bind_success = 'bind_success',
  /** 微信 操作 */
  wx_not_operation = 'wx_not_operation',
  /** 上传图片格式错误 */
  upload_img_type_error = 'upload_img_type_error',
  /** 上传图片大小错误 */
  upload_img_size_error = 'upload_img_size_error',
  /** 上传heif图片错误 */
  upload_img_heif_error = 'upload_img_heif_error',
  /** 课程未报名 */
  course_not_registered = 'course_not_registered',
  /** 课程结束 */
  course_end_of_class = 'course_end_of_class',
  /** 课时未发布 */
  classhour_ont_publish = 'classhour_ont_publish',
  /** 发送验证码错误 */
  send_code_error = 'send_code_error',
  /** 校验验证码错误 */
  check_code_error = 'check_code_error',
  /** 申请发票成功 */
  apply_invoice_success = 'apply_invoice_success',
  /** 取消订单成功 */
  cancel_order_success = 'cancel_order_success',
  /** 验证失败 */
  verify_failed = 'verify_failed'
}
