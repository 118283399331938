


























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class PageView extends Vue {
  closeBox() {
    this.$emit('closeFail')
  }
}
