
















import { Observer } from 'mobx-vue'
import { Component } from 'vue-property-decorator'
import showPopupScript from './showPopupScript'
import LazyImg from '@/components/lazyImg/index.vue'

@Observer
@Component({
  name: 'ShowPopupComp',
  components: {
    LazyImg
  }
})
export default class ShowPopupComp extends showPopupScript {}
