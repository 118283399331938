/**
 * @author mengxd
 * @create date 2022-07-07 20:25:53
 * @desc 课程卡片
 */
import lodash from 'lodash'
import moment from 'moment'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Course } from '../../entities'
import {
  EnumProductTypeNameSimple,
  EnumProductStatusByShow,
  EnumProductUnit,
  EnumProductStatus,
  EnumProductType,
  EnumContentType,
  EnumContentTypeText,
  EnumProductStyle
} from '../../enums/product'

@Component
export class MixinsCourseCard extends Vue {
  /** 课程数据 */
  @Prop({ default: () => ({}) }) dataSource: Course
  /** 受赠人是否已经购买过全阶课程下面的单阶课 */
  @Prop() receiveHasbuy: any

  /** 是否是赠课订单 */
  get give() {
    return lodash.eq(this.$route.query.give, 'true')
  }

  /** 是否已经购买 */
  get hasOwn() {
    return this.dataSource.hasOwn
  }
  /** 是否是h5 **/
  get isH5() {
    return this.$store.$global.platform === 'Mobile'
  }

  /** 封面图 **/
  get cover() {
    let width = this.isH5 ? 160 : 720
    let height = this.isH5 ? 160 : 444
    let imgUrl = this.isH5 ? this.dataSource.original.coverUrlH5 : this.dataSource.original.coverUrl
    return `${imgUrl}?x-oss-process=image/resize,w_${width},h_${height},m_fill/quality,q_90`
  }

  /** 商品副标题 **/
  get productSubName() {
    return this.dataSource.original.productSubName
  }

  /** 商品标题 */
  get productName() {
    return this.dataSource.original.productName
  }

  /** 赠课订单下是否展示已报名印章 */
  get giveOrderShowSeal() {
    if (this.receiveHasbuy.length > 0) {
      return lodash.includes(this.receiveHasbuy, this.dataSource.id)
    }
    return false
  }

  /**
   * 直播课
   */
  get isLiveCourse() {
    return (
      this.dataSource.original.productType === EnumProductType.single || this.dataSource.original.productType === EnumProductType.fullstage
    )
  }

  /**
   * 展览进阶课
   */
  get isShowCourse() {
    return (
      this.dataSource.original.productType === EnumProductType.onlineExhibition ||
      this.dataSource.original.productType === EnumProductType.offlineExhibition
    )
  }

  /**
   * 教练日课
   */
  get isDayCourse() {
    return this.dataSource.original.productType === EnumProductType.dayCourse
  }
  /**
   * 获取商品数量单位
   */
  get countUnit() {
    switch (true) {
      case this.isDayCourse:
        return EnumProductUnit.DayCourseCountUnit
      case this.isShowCourse:
        return EnumProductUnit.ExhibitonCourseCountUnit
      case this.isLiveCourse:
        return EnumProductUnit.LiveCourseCountUnit
      default:
        return EnumProductUnit.PhysicalCountUnit
    }
  }

  /** 多少节 **/
  get count() {
    return this.isShowCourse ? this.dataSource.original.bizProdCount + 2 : this.dataSource.original.bizProdCount
  }
  get freeOrder() {
    return this.dataSource.originalPrice === 0
  }
  /** 商品价格 **/
  get shouldPayPrice() {
    if (this.freeOrder) return '免费'
    if (this.$route.query.group == 'true') {
      return this.dataSource.original.groupPrice / 100
    }
    return this.dataSource.discountPrice / 100
  }
  /**
   * 当前商品的类型
   */
  get contentType() {
    switch (this.dataSource.original.contentType) {
      case EnumContentType.Painting:
        return EnumContentTypeText.Painting
      case EnumContentType.Calligraphy:
        return EnumContentTypeText.Calligraphy
      case EnumContentType.CalligraphyAndReading:
        return EnumContentTypeText.CalligraphyAndReading
      case EnumContentType.Health:
        return EnumContentTypeText.Health
      case EnumContentType.Physical:
        return EnumContentTypeText.Physical
      case EnumContentType.Reading:
        return EnumContentTypeText.Reading
      case EnumContentType.Writing:
        return EnumContentTypeText.Writing
      case EnumContentType.AestheticEducation:
        return EnumContentTypeText.AestheticEducation
      default:
        return EnumContentTypeText.Calligraphy
    }
  }

  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
