<template>
  <a-modal
    :visible="true"
    destroyOnClose
    @cancel="onBindWXCancel"
    :footer="null"
    width="414px"
    centered
    :zIndex="100003"
    dialogClass="xt-bindwx-dialog"
  >
    <div class="bindweixin">
      <div class="bindweixin-top">
        <h4>去绑定</h4>
        <p class="bind-tips">
          {{ $store.$storeUser.weixinBindMessage || '教练日课在暄桐日课小程序中进行，购课前请绑定微信' }}
        </p>
      </div>
      <div class="bind-erweima" id="wx_login_container"></div>
      <p class="bind-erweima-tips">扫描二维码绑定微信</p>
    </div>
  </a-modal>
</template>
<script>
import WxbindScript from './wxbindScript'
import { Observer } from 'mobx-vue'
import { Component } from 'vue-property-decorator'

@Observer
@Component({
  name: 'WXbindComp'
})
export default class WXbindComp extends WxbindScript {}
</script>

<style lang="less">
@import url('./wxbind.less');
</style>
