

















































































































import { Component, Vue, Watch, Emit, Mixins } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import { MixinsCourseClasshour } from '@xt/client/mixins'

@Observer
@Component({
  components: {}
})
export default class PageView extends Mixins(MixinsCourseClasshour) {
  @Watch('$route.query.classhour')
  onWatch(newVal, oldVal) {
    if (newVal) {
      this.onLoading(newVal)
    }
  }
  created() {
    if (this.id) {
      this.onLoading(this.id)
    }
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
