


























































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

interface Exp {
  level: number
  background: string
  backgroundPC: string
  name: string
  levelImg: string
  nameImg: string
  cornerImg: string
  upgrade: number
  tip: string
  bg?: string
}

@Observer
@Component
export default class PageView extends Vue {
  @Prop() dataSource: Exp[]

  get PageStore() {
    return this.$store.$storeUser
  }

  maxLevel = 10
  level = this.PageStore.level
  count = this.PageStore.count
  currentIndex = this.level
  sumExperience = [0, 200, 450, 800, 1300, 2000, 3000, 4500, 7000, 12500, 22500]
  @Ref('swiperScroll') swiperScroll: HTMLDivElement

  allLoaded: boolean = false
  lazyedList: (Exp & { loaded: boolean })[] = []
  firstCall: boolean = true

  @Watch('currentIndex', { immediate: true })
  changeIndexHandler() {
    if (this.allLoaded) return
    if (this.firstCall) {
      this.firstCall = false
      this.lazyedList = [
        ...this.dataSource.map((item: Exp & { loaded: boolean }) => {
          item.loaded = false
          return item
        })
      ]
    }
    const copyed: (Exp & { loaded: boolean })[] = [...this.lazyedList]
    for (let i = Math.max(0, this.currentIndex - 2); i < Math.min(copyed.length, this.currentIndex + 3); i++) {
      copyed[i].loaded = true
    }
    this.allLoaded = copyed.every(item => item.loaded)
    this.lazyedList = [...copyed]
  }

  progressWidth(idx: number) {
    if (idx < this.level) {
      return 100
    } else if (idx === this.level) {
      if (idx + 1 === this.sumExperience.length) {
        return 100
      }
      const upExp = this.sumExperience[this.level + 1]
      const currExp = this.sumExperience[this.level]
      return ((this.count - currExp - 1) / (upExp - currExp)) * 100
    } else {
      return 0
    }
  }

  moveSwiper() {
    this.swiperScroll.style.transform = `translateX(-${this.$px2rem(290 * this.currentIndex)})`
  }

  changeLevel(operation: 'next' | 'prev') {
    switch (operation) {
      case 'next':
        if (this.currentIndex === this.dataSource.length - 1) return
        this.currentIndex++
        break
      case 'prev':
        if (this.currentIndex === 0) return
        this.currentIndex--
        break
    }
    this.moveSwiper()
  }

  created() {
    this.$nextTick(() => {
      this.moveSwiper()
    })
  }
  updated() {}
  destroyed() {}
}
