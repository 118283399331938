/**
 * @author wangsl
 */
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component
export default class Popup extends Vue {
  @Prop({}) visible: boolean
  @Prop({}) title: string

  @Watch('visible')
  visibleUpdate(value) {
    // if (value) this.hideBodyHeight(); else this.showBodyHeight()
  }
  // 显示弹窗口修改body防止滚动穿透
  hideBodyHeight() {
    document.body.style.height = '100%'
    document.body.style.overflow = 'hidden'
  }
  // 关闭弹窗时，恢复body
  showBodyHeight() {
    document.body.style.height = 'auto'
    document.body.style.overflow = 'auto'
  }

  stopEvent() {}

  @Emit('hide')
  hidePopup() {}

  created() {
    // if (this.visible) this.hideBodyHeight()
  }
}
