/*
 * @Author: Erlin
 * @CreateTime: 2020-12-25 14:11:09
 * @LastEditors: Erlin
 * @LastEditTime: 2020-12-25 14:11:09
 * @Description: 订单
 */
/**
 * 订单状态枚举
 * 订单状态（0未支付，1已支付，2退款中，3已退款，4已取消）
 */
export enum EnumOrderState {
  /** 待支付 */
  NotPay = '待支付',
  /** 已支付 */
  HasPay = '已支付',
  /** 退款中 */
  Refund = '退款中',
  /** 已退款 */
  HasRefund = '已退款',
  /** 已取消 */
  Cancelled = '已取消'
  /** 6.30去掉 待确认 */
  // ToBeConfirmed = '待确认'
}

/**
 * 订单状态枚举
 * 订单状态（0未支付，1已支付，2退款中，3已退款，4已取消）
 */
export enum EnumOrderStateTypeNumber {
  /** 待支付 */
  NotPay = 0,
  /** 已支付 */
  HasPay = 1,
  /** 退款中 */
  Refund = 2,
  /** 已退款 */
  HasRefund = 3,
  /** 已取消 */
  Cancelled = 4
  /** 6.30去掉 待确认 */
  // ToBeConfirmed = 5
}

export enum EnumOrderStatusStyle {
  /** 待支付 */
  NotPay = 'notpay',
  /** 已支付 */
  HasPay = 'haspay',
  /** 退款中 */
  Refund = 'refund',
  /** 已退款 */
  HasRefund = 'hasrefund',
  /** 已取消 */
  Cancelled = 'cancelled'
  /** 6.30去掉 待确认 */
  // ToBeConfirmed = 'tobeconfirmed'
}

/**
 * 发票状态枚举
 */
export enum EnumInvoiceState {
  /** 未申请 */
  NotApply = '未申请',
  /** 待开票 */
  WaitInvoice = '待开票',
  /** 已开票 */
  HasInvoice = '已开票',
  /** 驳回 */
  Reject = '驳回',
  /** 作废 */
  Cancellation = '作废'
}

/**
 * 发票按钮文字枚举
 */
export enum EnumInvoiceText {
  /** 申请发票 */
  Apply = '申请发票',
  /** 发票开具中 */
  InvoiceIssue = '发票开具中',
  /** 发票已开具 */
  HasInvoice = '发票已开具',
  /** 发票已作废 */
  Cancellation = '发票已作废'
}

export enum EnumBuyWay {
  Buy = 1,
  Give = 2
}

export enum EnumGiftStatus {
  Wait = 0,
  Success = 1,
  Fail = 2
}
export enum PaymentTypes {
  UnPay = '未支付',
  Free = '免费',
  Alipay = '支付宝',
  WeChat = '微信',
  CopperPlate = '桐板儿',
  UnderlineWechat = '线下微信',
  UnderlineAlipay = '线下支付宝',
  UnderlineBanks = '线下银行转账'
}
//0未支付，1免费，2微信，3支付宝，4桐板儿，5线下微信，6线下支付宝，7线下银行转账
export enum PaymentTypesNumber {
  UnPay = 0,
  Free = 1,
  WeChat = 2,
  Alipay = 3,
  CopperPlate = 4,
  UnderlineWechat = 5,
  UnderlineAlipay = 6,
  UnderlineBanks = 7
}

export enum BuyCourseType {
  buy = '自买',
  give = '赠课'
}
export enum BuyCourseTypeNumber {
  buy = 1,
  give = 2
}

export enum refundStatusNumber {
  //0待审批，1退款中，2退款成功，3退款关闭，4退款撤销
  ApprovalPending = 0,
  refunding = 1,
  refundSuccess = 2,
  refundClose = 3,
  refundCanceled = 4
}

export enum GiftReceiveStatus {
  /** 未领取 */
  NotReceive = 0,
  /** 已领取 */
  HaveReceive = 1,
  /** 已拒绝 */
  HasRefused = 2
}
