











































import { Component, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import GiveawayIntroduction from './giveaway_introduction.vue'

@Observer
@Component({
  components: { GiveawayIntroduction }
})
export default class PageView extends Vue {
  visibleIntroduction: boolean = false

  get giveawayInfo() {
    return this.$store.$storeCourse.Details.giveawayInfo
  }

  // 6.30
  get isGiveaway() {
    return this.giveawayInfo?.ids.some(item => `${item.courseProductId}` === this.$route.params.id)
  }

  selectIntroduction(e: Event) {
    e.stopPropagation()
    this.visibleIntroduction = true
  }

  // 6.30
  goGiveawayDetail() {
    const item = this.giveawayInfo?.ids.find(item => `${item.courseProductId}` === this.$route.params.id)
    if (!item?.giveawayProductId) return
    this.$router.push({
      path: `/commodity/${item.giveawayProductId}`
    })
  }

  onAgreementCancel() {
    this.visibleIntroduction = false
  }

  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
