/**
 * @author 冷 (https://github.com/LengYXin)
 * @email lengyingxin8966@gmail.com
 * @create date 2020-10-20 20:26:03
 * @modify date 2020-10-20 20:26:03
 * @desc 基础函数
 */
import { Component, Prop, Vue, Provide, Inject, Watch, Emit } from 'vue-property-decorator'
import { EnumMessage } from '../../languages'
import { EnumCourseSignupType, EnumCourseState } from '../../enums'
import lodash from 'lodash'
@Component
export class MixinsBasicsHelpers extends Vue {
  get UserStore() {
    return this.$store.$storeUser
  }
  BasicsHelpers = {
    /**
     * 支付页面 跳转函数
     * @param params.productId 商品id
     * @param params.orderNo  订单
     * @param params.give  是否是赠课
     */
    toPayment: (params: {
      productId: string | number
      orderNo?: string
      group?: boolean
      groupNo?: number | string
      give: boolean | string
      memberBeforeOption?: boolean | string
    }) => {
      // //   用户需要完善个人信息
      // if (this.UserStore.onneedCompleteInfo()) {
      //   // this.onCancel()
      //   this.UserStore.onToggleModal(this.$EnumLocaleLinks.links_complete)
      //   // this.UserStore.onSetToPaymentQuery(query)
      //   return false
      // }
      let query = {
        orderNo: String(params.orderNo) != 'undefined' ? String(params.orderNo) : '',
        productId: String(params.productId),
        give: String(params.give),
        group: String(params.group) != 'undefined' ? String(params.group) : String(false),
        groupNo: params.groupNo ? String(params.groupNo) : '',
        memberBeforeOption: String(params.memberBeforeOption)
      }
      // 存在订单编号
      if (!lodash.isNil(params.orderNo)) {
        lodash.set(query, 'orderNo', String(params.orderNo))
      }
      const router = { name: 'payment', query }
      // 微信内 无 wxOpenid id
      if (this.$store.$global.WechatBowser && this.$store.$global.platform === 'Mobile' && !this.$store.$storeUser.wxOpenid) {
        // 获取 code 换取 openid
        window.location.replace(
          this.$store.$wechat.getAuthorizeUrl(window.location.origin + this.$router.resolve(router).href, 'snsapi_base')
        )
        return false
      }

      this.$router.push(router)
    }
  }
}
