






























import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator'
import userModal from './login/userModal.vue'
import giftModal from './giftModal.vue'
@Component({
  components: { userModal, giftModal }
})
export default class extends Vue {
  get $locale() {
    return this.$store.$locale
  }
  get production() {
    return this.$store.$global.production
  }
  onLocaleChange(locale) {
    if (this.$locale.locale === locale) {
      return
    }
    this.$locale.onLocaleChanges(locale)
    window.location.reload()
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
