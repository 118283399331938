








import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({
  layout: 'clean'
})
export default class LogoutAgreement extends Vue {
  get htmlSrc() {
    return `${process.env.static}/protocol/logout_pc.html?v=${process.env.version}`
  }

  @Watch('$route.query')
  changeQuery(query) {
    if (query.action === 'toTop') {
      window.frames['iframe'].postMessage({ typeAction: 'toTop' }, '*')
      this.$router.replace({ query: {} })
    }
  }
}
