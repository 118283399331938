import { Component, Prop, Vue } from 'vue-property-decorator'
import moment from 'moment'
import { Bind, Debounce } from 'lodash-decorators'
import { GenseeLinkSearch, parseGenseeLink } from '@xt/client/entities/gensee/utils'

@Component
export class MixinsLiveCourse extends Vue {
  @Prop() dataSource: any
  timer: string | boolean = ''
  downtimeTimer: NodeJS.Timer
  liveTime: string = '' // 直播时间
  living: boolean = false // 正在直播

  get platform() {
    return this.$store.$global.platform
  }

  get UserStore() {
    return this.$store.$storeUser
  }

  get PageStore() {
    return this.$store.$storeCourse.Details.ClasshourLive
  }

  /** 今天存在直播内容 */
  currentDayExistLive(data) {
    let currentTime = this.$ajax.serviceDate().valueOf()
    const [currentY, currentM, currentD] = moment(currentTime).format('YYYY-MM-DD').split('-')
    const [startY, startM, startD] = moment(data.startTime).format('YYYY-MM-DD').split('-')
    return currentY === startY && currentM === startM && currentD === startD
  }

  /** 展示倒计时 & 开启倒计时 & 展示进直播按钮 & 点击图片跳转(课程详情 | 直播间)  （核心） */
  get showDownTime() {
    // 当天存在直播 & 已发布 && 没有结束
    if (this.currentDayExistLive(this.dataSource) && this.dataSource.publishStatus === 1 && !this.dataSource.liveEnd) {
      const fixHour = 24 * 60 * 60 * 1000
      let currentTime = this.$ajax.serviceDate().valueOf()
      if (this.dataSource.startTime - currentTime <= fixHour) {
        this.downtime()
        return true
      }
    }
    this.liveTime = moment(this.dataSource.startTime).format('YYYY.MM.DD HH:mm')
    return false
  }

  /** 倒计时走起 */
  downtime() {
    this.downtimeTimer = setInterval(() => {
      let currentTime = this.$ajax.serviceDate().valueOf()
      let diff = this.dataSource.startTime - currentTime
      this.timer = diff <= 0 ? false : moment(diff).utcOffset(0).format('HH:mm:ss')
      if (diff <= 0) {
        clearInterval(this.downtimeTimer)
        this.downtimeTimer = null
        this.living = true
      }
    }, 200)
  }
  // 调用这个函数的都是日历页面  都是进直播间的页面  所以直接写死live
  @Debounce(500)
  async golive(e: Event) {
    e.stopPropagation()
    try {
      // 用户需要完善个人信息
      if (this.UserStore.onneedCompleteInfo()) {
        this.UserStore.onToggleModal(this.$EnumLocaleLinks.links_complete)
        return
      }

      if (!this.dataSource.id) return

      if (this.$store.$global.isEmbeddedH5Page) {
        wx.miniProgram.navigateTo({
          url: `/pages/webview/index?p=${encodeURIComponent(`/live?classhour=${this.dataSource.id}`)}`
        })
      } else {
        window.open(`/live?classhour=${this.dataSource.id}`, '_blank')
      }
    } catch (error) {}
  }

  goCourse(e) {
    if (this.$store.$global.isEmbeddedH5Page) {
      const path = window.location.origin + this.$router.resolve({ path: `/course/${this.dataSource.courseId}` }).href
      wx.miniProgram.navigateTo({
        url: `/pages/webview/index?p=${encodeURIComponent(path)}`
      })
    } else {
      e.stopPropagation()
      this.$router.push({
        name: 'course-id',
        params: { id: this.dataSource.courseId }
      })
    }
  }

  goCourseOrLive(e: Event) {
    if (this.showDownTime) {
      this.golive(e)
    } else {
      this.goCourse(e)
    }
  }
}
