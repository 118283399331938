<template>
  <div class="header-box" v-if="show()">
    <div class="content">
      <div class="course-left-title">
        <div>{{ dataSource.type }}</div>
        <div>{{ dataSource.title }}</div>
      </div>
      <div class="plh"></div>
      <div class="price">
        <div :data-original="dataSource.originalPrice > dataSource.price ? `原学费:${dataSource.originalPrice / 100}元` : ''">
          <span v-money="dataSource.price"></span>
        </div>
        <div v-if="dataSource.originalPrice > dataSource.price">
          优惠
          <span v-money="dataSource.originalPrice - dataSource.price"></span>
        </div>
      </div>
      <div :class="`gifts gifts${gifts()}`" @click="clickGifts">赠课给Ta人</div>
      <div class="signup" @click="sign">{{ statusAndStyle().text }}</div>
      <div class="course-right-btn-box-countdown" v-show="false">
        <div :class="`course-right-btn-box-countdown-date${!day ? '-123' : ''}`">{{ countdown.date }}</div>
        <div class="course-right-btn-box-countdown-type">{{ countdown.label }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import LazyImg from '@/components/lazyImg/index.vue'
import { AjaxBasics } from '@xt/client'
import moment from 'moment'
import lodash from 'lodash'
import { Course } from '@xt/client/entities/basics/course'
@Component({
  components: {
    LazyImg
  }
})
export default class BMYX extends Vue {
  @Prop({}) dataSource
  @Prop() productId
  interval
  headerinterval
  data() {
    return {
      countdown: {},
      day: false,
      topShow: false
    }
  }
  show() {
    let sas = this.statusAndStyle()
    return (sas.text === '我要报名' || sas.text === '转报全阶') && this.isLogin() && this.topShow
  }
  get moment() {
    return moment
  }
  @Emit('onShow')
  onShow(type) {
    return {
      type
    }
  }
  @Emit('onHide')
  onHide(type) {
    return {
      type
    }
  }
  async sign() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    // 当前时间大于转报结束时间且课程结束则 已结课
    if (currentTime >= this.dataSource.convertEndTime && currentTime >= this.dataSource.courseEndTime) {
      return
    }
    if (this.allCourseHasbuy()) {
      return this.$router.push({
        name: 'my'
      })
    }
    if (currentTime >= this.dataSource.signupEndTime && currentTime < this.dataSource.convertEndTime) {
      if (!this.haveEndCourse() && this.hasPer()) return await this.csign()
    }

    if (currentTime >= this.dataSource.signupStartTime && currentTime < this.dataSource.signupEndTime) {
      await this.csign()
    }
  }

  haveEndCourse() {
    let b = false
    this.dataSource.course.map(v => {
      if (lodash.includes(Course.UserHasbuy, v.id)) return
      if (this.currentTime() >= v.signupEndTime) b = true
    })
    return b
  }

  async csign() {
    try {
      // 检测用户是否登录
      this.$InspectUser()
      this.onCheckDevice()
      let res = await this.$store.$storeOrder.checkBeforeOrder(this.productId, false)
      if (res.oldOrder && res.oldOrderNo) {
        this.$emit('choosePop', res)
        throw new Error('Waiting For Payment')
      }
      this.onShow('Purchase')
    } catch (error) {
      console.warn(error)
    }
  }
  clickGifts() {
    if (this.isC()) {
      // 检测用户是否登录
      this.$InspectUser()
      this.onCheckDevice()
      this.onShow('Give')
    }
  }
  get UserStore() {
    return this.$store.$storeUser
  }
  /**
   * 检测用户是否手机邮箱
   */
  onCheckDevice() {
    if (this.UserStore.needBindDevice) {
      if (this.platform === 'Mobile') {
        this.$router.push({
          name: 'other-bind'
        })
        throw this.$EnumLocaleLinks.links_bind_device
      }
      this.UserStore.onToggleModal(this.$EnumLocaleLinks.links_bind_device)
      throw this.$EnumLocaleLinks.links_bind_device
    }
    //   用户需要完善个人信息
    if (this.UserStore.onneedCompleteInfo()) {
      this.UserStore.onToggleModal(this.$EnumLocaleLinks.links_complete)
      throw this.$EnumLocaleLinks.links_complete
    }
  }
  isC() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    if (currentTime > this.dataSource.signupStartTime && currentTime < this.dataSource.convertEndTime) return true
    return false
  }

  gifts() {
    return this.isC() ? '-yes' : '-no'
  }

  statusAndStyle() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    // 当前时间大于转报结束时间且课程结束则 已结课
    if (currentTime >= this.dataSource.convertEndTime && currentTime >= this.dataSource.courseEndTime) {
      return { text: '已结课', color: '-black' }
    }
    if (this.allCourseHasbuy()) {
      return { text: '已报名,去上课', color: '-yellow' }
    }
    // 当前时间大于转报结束时间且大于报名结束时间且课程已开启则 开课中
    if (
      currentTime >= this.dataSource.convertEndTime &&
      currentTime >= this.dataSource.signupEndTime &&
      currentTime >= this.dataSource.courseStartTime
    ) {
      return { text: '开课中', color: '-yellow' }
    }
    if (
      currentTime >= this.dataSource.convertEndTime &&
      currentTime >= this.dataSource.signupEndTime &&
      currentTime < this.dataSource.courseStartTime
    ) {
      return { text: '报名结束', color: '-red-no' }
    }
    if (currentTime >= this.dataSource.signupEndTime && currentTime < this.dataSource.convertEndTime) {
      if (!this.interval) {
        this.countdown = {
          date: this.computed(this.dataSource.convertEndTime),
          label: '后转报结束'
        }
        this.interval = setInterval(() => {
          this.countdown = {
            date: this.computed(this.dataSource.convertEndTime),
            label: '后转报结束'
          }
        }, 500)
      }
      if ((!this.haveEndCourse() && this.hasPer()) || !this.isLogin()) return { text: '转报全阶', color: '-red' }
      return { text: '不满足转报全阶要求', color: '-red-14' }
    }

    if (currentTime >= this.dataSource.signupStartTime && currentTime < this.dataSource.signupEndTime) {
      if (!this.interval) {
        this.countdown = {
          date: this.computed(this.dataSource.signupEndTime),
          label: '后报名结束'
        }
        this.interval = setInterval(() => {
          this.countdown = {
            date: this.computed(this.dataSource.signupEndTime),
            label: '后报名结束'
          }
        }, 500)
      }
      if (!this.haveEndCourse() && this.hasPer()) return { text: '转报全阶', color: '-red' }
      return { text: '我要报名', color: '-red' }
    }
    this.countdown = {
      date: moment(this.dataSource.signupStartTime).format('YYYY.MM.DD'),
      label: '报名时间'
    }
    return { text: '即将报名', color: '-red-no' }
  }

  currentTime() {
    return AjaxBasics.serviceDate.utcOffset(+8).valueOf()
  }
  isLogin() {
    return this.$store.$storeUser.loggedIn
  }
  hasPer() {
    return !!this.dataSource.course.filter(v => lodash.includes(Course.UserHasbuy, v.id)).length
  }
  allCourseHasbuy() {
    return lodash.includes(Course.UserHasbuy, this.dataSource.id)
  }
  async created() {
    if (this.isLogin()) {
      this.$store.$storeUser.onGetCourseListHasbuy()
    }
  }
  computeDay(currentTime, endtime) {
    return Math.floor(this.moment(endtime).diff(currentTime, 'hour') / 24)
  }
  computed(endtime) {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    let diff = endtime - currentTime
    if (diff <= 0) {
      clearInterval(this.interval)
      this.interval = null
      if (currentTime < this.dataSource.courseConvertEndTime) {
        return this.computedCountDown()
      } else {
        return false
      }
    }
    if (diff > 24 * 60 * 60 * 1000) {
      this.day = true
      return this.computeDay(currentTime, endtime) + '天'
    } else {
      this.day = false
      return moment(diff).utcOffset(0).format('HH:mm:ss')
    }
  }
  getElementTop(elem) {
    var elemTop = elem.offsetTop //获得elem元素距相对定位的父元素的top
    elem = elem.offsetParent //将elem换成起相对定位的父元素
    while (elem != null) {
      //只要还有相对定位的父元素
      //获得父元素 距他父元素的top值,累加到结果中
      elemTop += elem.offsetTop
      //再次将elem换成他相对定位的父元素上;
      elem = elem.offsetParent
    }
    return elemTop
  }
  mounted() {
    this.headerinterval = setInterval(() => {
      let top = this.getElementTop(document.getElementById('all_course_mode_bmyx'))
      if (document.documentElement.scrollTop > top + 120) this.topShow = true
      else this.topShow = false
    }, 100)
  }
  destroyed() {
    clearInterval(this.interval)
    clearInterval(this.headerinterval)
  }
}
</script>
<style lang="less" scoped>
.header-box {
  position: fixed;
  width: 100vw;
  height: 80px;
  z-index: 10001;
  background: white;
  box-shadow: @xt-header-box-shadow;
  top: 0;
  display: flex;
  justify-content: center;
  .content {
    width: 1200px;
    height: 80px;
    display: flex;
    align-items: center;
  }
}
.course-left-title {
  display: flex;
  height: 30px;
  align-items: center;
  & > div {
    &:nth-child(1) {
      width: 32px;
      height: 20px;
      background: #ad9174;
      border-radius: 2px;
      text-align: center;
      line-height: 20px;
      font-size: 12px;
      color: #ffffff;
    }
    &:nth-child(2) {
      flex: 1;
      padding-left: 6px;
      font-size: 20px;
      font-weight: 500;
      color: #181c25;
      line-height: 20px;
    }
  }
}
.plh {
  flex: 1;
}
.price {
  margin-top: 25px;
  height: 34px;
  display: flex;
  align-items: center;
  & > div {
    &:nth-child(1) {
      font-size: 26px;
      font-weight: 500;
      color: #181c25;
      position: relative;
      line-height: 34px;
      &::after {
        content: attr(data-original);
        position: absolute;
        width: 100%;
        height: 14px;
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        color: #bbbbbb;
        line-height: 14px;
        top: -18px;
        left: 0;
        z-index: 9;
        text-decoration: line-through;
        white-space: nowrap;
      }
    }
    &:nth-child(2) {
      height: 28px;
      border-radius: 17px 17px 17px 0px;
      font-size: 12px;
      background: fade(#ba5a5a, 8%);
      color: #c37f7f;
      line-height: 28px;
      text-align: center;
      margin-left: 10px;
      padding: 0 10px;
    }
  }
}
.gifts {
  width: 132px;
  height: 46px;
  background: fade(#ad9174, 10%);
  border-radius: 2px;
  font-size: 18px;
  color: #ad9174;
  line-height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  margin-left: 80px;
  cursor: pointer;
  &-yes {
    cursor: pointer;
    background: rgba(173, 145, 116, 0.1);
    color: rgba(173, 145, 116, 1);
    &:hover {
      background: rgba(173, 145, 116, 0.2);
    }
  }
  &-no {
    cursor: auto;
    // background: #e8e8e8;
    background-color: rgba(#d2d2d2, 0.5);
    color: #fff;
  }
}
.signup {
  margin-left: 20px;
  width: 132px;
  height: 46px;
  background: #d9a8a8;
  border-radius: 2px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #d99292 !important;
  }
}
</style>
