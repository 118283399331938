/**
 * @author 冷 (https://github.com/LengYXin)
 * @email lengyingxin8966@gmail.com
 * @create date 2020-10-20 20:25:30
 * @modify date 2020-10-20 20:25:30
 * @desc 富文本 扩展
 */
import lodash from 'lodash'
import { Subject } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
@Component({
  components: {}
})
export class MixinsComponentEdirorToolbar extends Vue {
  // 富文本
  @Prop() quill
  // 按钮文案
  @Prop({ default: '发布' }) buttonText
  // 校验规则
  @Prop({}) rules
  // 最大文件数量
  @Prop({ default: 9 }) maxFile
  // 文件列表
  fileList = []
  // 显示 上传按钮
  get disabled() {
    return this.fileList.length >= this.maxFile
  }
  // 提交按钮
  get disabledSubmit() {
    return lodash.some(this.fileList, ['status', 'uploading'])
  }
  get max() {
    return lodash.get(this.rules, 'max', 2000)
  }
  /**
   * 订阅 富文本粘贴板通知
   */
  onClipboardSubject(subscribe: (obs) => void) {
    const clipboardSubject: Subject<any> = this.quill.clipboardSubject
    if (clipboardSubject) {
      clipboardSubject
        .pipe(
          filter(value => {
            return lodash.has(value, 'ops[0].insert.image')
          }),
          map(value => {
            const file: any = b64toBlob(lodash.get(value, 'ops[0].insert.image'))
            file.uid = this.$Encryption.uniqueId()
            return file
          })
        )
        .subscribe(obs => {
          subscribe(obs)
        })
    }
  }
  // 添加表情
  onAddFace(face) {
    const index = lodash.get(this.quill, '_custom.range.index', this.getLength())
    this.quill.insertText(index, face.value)
    this.quill.scrollIntoView()
    lodash.update(this.quill, '_custom.range.index', val => {
      return index + face.value.length
    })
  }
  // 文本长度
  getLength() {
    try {
      if (this.quill) {
        return this.quill.getLength() - 1
      }
    } catch (error) {
      console.log('LENG: extends -> getLength -> error', error)
    }
    return 0
  }
  onSubmitRules() {
    try {
      // 必填内容
      const required = lodash.get(this.rules, 'required', false)
      //  必填文件
      const requiredFile = lodash.get(this.rules, 'requiredFile', false)
      // 最大数
      const max = this.max
      // 自定义校验 函数
      const validator = lodash.get(this.rules, 'validator', () => true)
      const fileList = lodash.cloneDeep(this.fileList)
      const data = {
        fileList: fileList,
        fileResult: lodash.filter(
          lodash.map(fileList, 'response').map(v => {
            return { ...v, originWidth: v.originalWidth, originHeight: v.originalHeight }
          }),
          lodash.isObject
        ),
        text: this.quill.getText(),
        html: this.getLength() ? this.quill.getHTML() : '',
        length: this.getLength(),
        quill: this.quill,
        /** 重置内容 */
        onReset: () => {
          this.quill.root.innerHTML = ''
          this.fileList = []
        }
      }
      console.log('LENG: extends -> onSubmitRules -> data', data)
      if (required) {
        if (!/^[\s\S]*.*[^\s][\s\S]*$/.test(data.text)) {
          // if (data.fileList.length === 0) {
          throw '请输入内容'
          // }
        }
      }
      if (max != Number.MAX_SAFE_INTEGER) {
        if (data.length > max) {
          throw '超过最大长度'
        }
      }
      if (requiredFile) {
        if (data.fileList.length === 0) {
          throw '请上传图片'
        }
      }
      if (lodash.some(data.fileList, ['status', 'uploading'])) {
        throw '图片还在上传中'
      }
      if (lodash.some(data.fileList, ['status', 'failed']) || lodash.some(data.fileList, ['status', 'error'])) {
        throw '请删除上传失败的图片'
      }

      if (validator(data, this.rules)) {
        this.onSubmit(data)
      }
    } catch (error) {
      this.$msg(error, 'warning')
    }
  }
  @Emit('submit')
  onSubmit(event) {
    return event
  }
  onRemove(file) {
    const fileList = [...this.fileList]
    lodash.remove(fileList, ['uid', file.uid])
    this.fileList = fileList
  }
  onNineChange(fileList) {
    this.fileList = fileList
  }
  updated() {}
  destroyed() {}
}
function b64toBlob(base64, sliceSize?) {
  const arr = base64.split(',')
  const b64Data = arr[1]
  const contentType = arr[0].match(/:(.*?);/)[1]
  sliceSize = sliceSize || 512

  var byteCharacters = atob(b64Data)
  var byteArrays = []

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize)

    var byteNumbers = new Array(slice.length)
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    var byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  // var blob = new Blob(byteArrays, { type: contentType });
  return new File(byteArrays, 'temp', { type: contentType })
  // return blob;
}
