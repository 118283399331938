/**
 * @author wangsl
 */
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component
export default class collagePopupScript extends Vue {
  @Prop({}) visible: boolean

  @Emit('hide')
  hidePopup() {}
}
