/** * wangsl */
<template>
  <div class="video">
    <div class="video-label">笔墨点亮生活</div>
    <div class="video-title">
      <span class="video-title-big">暄</span>
      <span>|</span>
      <span class="video-title-big">桐</span>
      <span>|</span>
      <span class="video-title-big">教</span>
      <span>|</span>
      <span class="video-title-big">室</span>
    </div>
    <div class="video-box">
      <video autoplay muted ref="video" loop src="https://static.xuantong.cn/video/1.mp4" />
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
class Index extends Vue {
  data() {
    return {
      // play: false
    }
  }
  controls() {
    // if (!this.play) {
    //   this.play = true
    //   this.$refs.video.play()
    // } else {
    //   this.play = false
    //   this.$refs.video.pause()
    // }
  }
  mounted() {}
}

export default Index
</script>

<style scoped lang="less">
.video {
  width: 100vw;
  min-width: 1200px;
  height: 650px;
  background: #bda893;
  user-select: none;
  text-align: center;
  flex-direction: column;
  &-label {
    width: 100%;
    min-width: 1200px;
    font-size: 14px;
    font-weight: 500;
    color: #f1ded4;
    line-height: 20px;
    letter-spacing: 16px;
    text-align: center;
    padding-top: 100px;
  }
  &-title {
    width: 100%;
    min-width: 1200px;
    text-align: center;
    margin-top: 10px;
    span {
      color: #ffffff;
      margin: 0 5px;
      font-size: 72;
    }
    &-big {
      font-size: 48px;
      font-weight: 500;
      color: #ffffff;
      line-height: 48px;
      letter-spacing: 1px;
    }
  }
  &-box {
    width: 1200px;
    min-width: 1200px;
    height: 675px;
    margin-top: 60px;
    display: inline-block;
    video {
      width: 1200px;
      min-width: 1200px;
      height: 675px;
      margin-left: 50%;
      transform: translateX(-50%);
      border-radius: 2px;
      z-index: 7;
      position: relative;
    }
  }
}
</style>
