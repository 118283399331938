/**
 * wangsl
 */
enum CharacterEnum {
  space = ' ',
  comma = ','
}
/**
 * 图形Path的绘制类型的枚举
 */
enum PathTypeEnum {
  moveTo = 'M',
  bezierCurveTo = 'C'
}
/**
 * 图形Path的绘制类型 M = moveTo \ C = bezierCurveTo ............
 */
type PathType = PathTypeEnum.moveTo | PathTypeEnum.bezierCurveTo // 不完整
/**
 * 图形Paths的数据存储类型
 */
interface PathsInterface {
  type: PathType
  paths: number[]
}

export interface ShapeProps {
  svgPaths: string
  fillStyle: string
  fillStyleSelected: string
}

/** ----------------------------------------------------------------- */

/**
 * 图形的精灵类
 */
class Shape<ShapeProps> {
  private state: number = 0 // 0 未选中， 1 选中
  private path2DExample: any = new Path2D()
  private fillStyle: string
  private zoom: number
  private fillStyleSelected: string
  private paths: PathsInterface[] = []
  constructor(svgPaths: string, fillStyle: string, fillStyleSelected: string, zoom: number) {
    this.fillStyle = fillStyle
    this.fillStyleSelected = fillStyleSelected
    this.zoom = zoom
    this.paths = this.parsePathsBySvg(svgPaths)
    this.drawPath2D()
  }
  /**
   * 绘制当前形状
   * @param ctx
   */
  public draw(ctx: CanvasRenderingContext2D): void {
    ctx.fillStyle = this.state ? this.fillStyleSelected : this.fillStyle
    ctx.fill(this.path2DExample)
  }

  /**
   * 点是否在路径中
   * @param ctx
   * @param cx
   * @param cy
   */
  public isPointInPath(ctx: CanvasRenderingContext2D, cx: number, cy: number): number {
    this.state = ctx.isPointInPath(this.path2DExample, cx, cy) ? 1 : 0
    return this.state
  }

  /**
   * 取消选中状态
   * @returns
   */
  public cancelSelctedStatus(): void {
    this.state = 0
  }

  /**
   * 获取状态
   */
  public getState(): number {
    return this.state
  }

  private drawPath2D(): void {
    this.paths.map((v: PathsInterface) => {
      if (v.type === PathTypeEnum.moveTo) this.path2DExample.moveTo(...v.paths)
      if (v.type === PathTypeEnum.bezierCurveTo) this.path2DExample.bezierCurveTo(...v.paths)
    })
    this.path2DExample.closePath()
  }

  /**
   * 解析Path通过SVG中Path的字符串
   */
  private parsePathsBySvg(svgPaths: string): PathsInterface[] {
    let paths: PathsInterface[] = []
    let svgPathsArray: string[] = svgPaths.split(CharacterEnum.space)
    let path: PathsInterface = { type: PathTypeEnum.moveTo, paths: [] } // 存储每一组
    // 遍历路径数组，拆分不同操作项合并为组
    svgPathsArray.map((val: string, key: number) => {
      // 如果包含指定字母则认为是一个操作的开始
      if (val.indexOf(PathTypeEnum.moveTo) !== -1 || val.indexOf(PathTypeEnum.bezierCurveTo) !== -1) {
        // 每组操作开始时保存上一组数据并清空组
        if (path?.paths.length) {
          paths.push(path)
          path = { type: PathTypeEnum.moveTo, paths: [] }
        }
        // 取第一个字母作为操作类型, 由于substring()返回值类型为string 这里要做强制类型转换
        path.type = <PathType>val.substring(0, 1)
        // 去除字母保留像素点
        val = val.substring(1, val.length)
      }
      let pointsArr = val.split(CharacterEnum.comma) // 拆分坐标点为X和Y
      pointsArr.map((val: string) => path.paths.push(parseFloat(val) * this.zoom))
    })
    return paths
  }
}

export default Shape
