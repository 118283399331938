<template>
  <div class="detail">
    <Header :dataSource="dataSource" :productId="product.id" @onShow="onShow" @choosePop="popOrderChoose" />
    <div class="detail-cover">
      <LazyImg :src="dataSource.cover + '?x-oss-process=image/format,jpg/quality,q_70'" />
    </div>
    <Course :dataSource="dataSource" :productId="product.id" @onShow="onShow" @choosePop="popOrderChoose" />
    <List :dataSource="dataSource" />
    <Wf @clickItem="showWFPopup" v-if="dataSource.wfshow && dataSource.wf.length" :dataSource="dataSource" />
    <div class="wf-popup-mantle" v-if="wfPopupShow" @click="closeWFPopup">
      <div class="wf-popup-content" @click="stopPropagation">
        <img class="topim" :src="ossProcessImg($images.all_exhpc_pop_topim, 1000)" />
        <div class="title">{{ wfPopupContent.title }}</div>
        <div class="text-box">
          <div v-html="wfPopupContent.content[1] || wfPopupContent.content[0]"></div>
          <LazyImg v-if="wfPopupContent.qrcode" className="qrcode" :src="ossProcessImg(wfPopupContent.qrcode, 144, 144)" />
          <div v-if="wfPopupContent.shop" class="tips">扫描二维码去购买</div>
        </div>
        <i class="iconfont iconicon-sahnchu close" @click="closeWFPopup" />
      </div>
    </div>
    <a-modal
      :visible="VisibleAgreement"
      destroyOnClose
      @cancel="VisibleAgreement = false"
      :footer="null"
      :zIndex="1000"
      centered
      width="670px"
      dialogClass="xt-agreement-dialog"
    >
      <Agreement :type="type" :dataSource="agreementData()" @cancel="VisibleAgreement = false" />
    </a-modal>
    <Detail v-for="(item, index) in dataSource.detail" :key="index" :dataSource="item" />
    <Popup
      :visible="visibleCheckPopup"
      :title="checkPopupData.title"
      :ratio="checkPopupData.ratio"
      :count="checkPopupData.count"
      :waitPrice="checkPopupData.waitPrice"
      :mainHeader="checkPopupData.mainHeader"
      :mainText="checkPopupData.mainText"
      :viceText="checkPopupData.viceText"
      :isGroupBuy="checkPopupData.isGroupBuy"
      @hide="visibleCheckPopup = false"
      @mainHandler="checkPopupData.mainHandler() || function() {}"
      @viceHandler="checkPopupData.viceHandler() || function() {}"
    />
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator'
import Course from './views/allCourse'
import List from './views/list'
import Wf from './views/wf'
import Detail from '../views/detail'
// import JSONDATA from "./reading.json"
import Agreement from '../../../components/business/course/agreement.vue'
import Header from './views/header.vue'
import LazyImg from '@/components/lazyImg/index.vue'
import Popup from '@xt/client/mixins/components/groupbuy/groupPopup/groupPopupPc.vue'

import { EnumApiProduct } from '@xt/client/api'
import { EnumProductType } from '@xt/client/enums/product'

@Component({
  components: {
    Course,
    List,
    Wf,
    Agreement,
    Detail,
    Header,
    LazyImg,
    Popup
  },
  async asyncData(ctx) {
    if (ctx.store.$storeUser.loggedIn) await ctx.store.$storeUser.onGetCourseListHasbuy()
    // let res = JSONDATA

    const res = await ctx.store.$ajax.get(
      `/mocks/reading.json`,
      {},
      { 'Cache-Control': 'no-store', 'If-Modified-Since': '0' },
      {
        target: ctx.store.$global.production ? 'https://static.xuantong.cn' : 'https://testing-static.xuantong.cn'
      }
    )

    let bizId = res.id
    let productType = EnumProductType.fullstage
    const product = await ctx.store.$ajax.get(EnumApiProduct.productDetail, {
      bizId,
      productType
    })

    return {
      dataSource: res,
      product
    }
  }
})
export default class BMYX extends Vue {
  dataSource = {}
  product = {}
  checkPopupData = {}
  visibleCheckPopup = false
  data() {
    return {
      VisibleAgreement: false,
      type: 'Purchase',
      wfPopupContent: {},
      wfPopupShow: false
    }
  }
  mousewheel(e, b) {
    e.stopPropagation()
  }
  stopPropagation(e) {
    e.stopPropagation()
  }
  showWFPopup(content) {
    this.wfPopupContent = content
    this.wfPopupShow = true
    document.body.style.height = '100%'
    document.body.style.overflow = 'hidden'
  }
  toPay() {
    window.open(this.wfPopupContent.url)
    this.closeWFPopup()
  }
  closeWFPopup() {
    this.wfPopupShow = false
    this.wfPopupContent = {}
    document.body.style.height = 'auto'
    document.body.style.overflow = 'auto'
  }
  onShow(type) {
    this.VisibleAgreement = true
    this.type = type.type
  }
  agreementData() {
    return {
      original: {
        productInfo: {
          id: this.product.id
        }
      }
    }
  }
  /** 单独购买  取消订单 */
  async singleBuyCancel(res) {
    await this.$store.$storeOrder.onOrderCancel({ orderNo: res.oldOrderNo })
    this.onShow('Purchase')
  }
  /** 单独购买 继续支付 **/
  singleBuyContinue(res) {
    let query = {
      orderNo: String(res.oldOrderNo),
      productId: this.product.id,
      give: res.buyWay == 1 ? String(false) : String(true),
      memberBeforeOption: String(true)
    }
    this.$router.push({
      name: 'payment',
      query
    })
  }
  popOrderChoose(res) {
    this.checkPopupData = {
      title: '当前课程您有未完成支付的订单~',
      waitPrice: res.payAmount,
      count: res.virtualCurrency / 100 / 8,
      ratio: 8,
      mainText: '继续支付', //右侧按钮
      viceText: '取消订单，重新报名',
      mainHeader: '',
      mainHandler: () => {
        this.visibleCheckPopup = false
        this.singleBuyContinue(res)
      },
      viceHandler: async () => {
        this.visibleCheckPopup = false
        this.singleBuyCancel(res)
      }
    }
    this.visibleCheckPopup = true
  }
}
</script>
<style lang="less" scoped>
.detail {
  .wf-popup {
    &-mantle {
      position: fixed;
      height: 100vh;
      width: 100vw;
      margin: auto;
      background: rgba(0, 0, 0, 0.6);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10002;
    }
    &-content {
      width: 670px;
      height: 600px;
      position: fixed;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10002;
      background-color: #ffffff;
      .topim {
        width: 670px;
        height: 100px;
      }
      .title {
        position: absolute;
        top: 32px;
        z-index: 2;
        width: 100%;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        line-height: 18px;
      }
      .close {
        color: #999999;
        font-size: 12px;
        position: absolute;
        top: 21px;
        right: 21px;
        z-index: 9;
        font-weight: 500;
        cursor: pointer;
        &:hover {
          color: #666666;
        }
      }
      .text-box {
        width: 630px;
        height: 480px;
        padding: 20px 30px;
        font-size: 14px;
        color: #333333;
        line-height: 28px;
        margin-left: 20px;
        overflow-y: scroll;
      }
      .qrcode {
        width: 144px;
        height: 144px;
        margin-top: 60px;
        margin-left: 50%;
        transform: translateX(-50%);
      }
      .tips {
        margin-top: 15px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
        text-align: center;
      }
    }
  }
  &-cover {
    width: 100vw;
    min-width: 1920px;
    max-height: 380px;
    overflow: hidden;
    min-height: 380px;
    margin-left: 50%;
    transform: translateX(-50%);
    position: relative;
    img {
      width: 100vw;
      min-width: 1920px;
      min-height: 380px;
      height: calc(100vw * 19.7916%);
      user-select: none;
      -webkit-user-drag: none;
    }
  }
}

.xt-agreement-dialog {
  .ant-modal-body {
    padding: 0 60px 40px;
  }
  .ant-modal-close-x {
    // height: 80px;
    // line-height: 80px;
    color: @xt-yellow-6;
  }
}
</style>
