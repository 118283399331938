import { BindAll } from 'lodash-decorators'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { EnumApiHomework } from '../../api'
import SunDrying from './sunDrying'
import Details from './details'
import HomeworkDetail from './homework'
import HomeworkTask from './task'

import Entities from './entities'
import { EnumMessage } from '../../languages'

@BindAll()
export class ControllerHomework extends Entities {
  constructor(protected $ajax: AjaxBasics) {
    super()
  }
  /**
   * 晒作业
   * @memberof ControllerHomework
   */
  SunDrying = new SunDrying(this.$ajax)
  /**
   * 作业详情
   * @memberof ControllerHomework
   */
  Details = new Details(this.$ajax)

  /** 作业任务 */
  HomeworkTask = new HomeworkTask(this.$ajax)

  HomeworkDetail = new HomeworkDetail(this.$ajax)

  /**
   * 发布朋友圈评论
   * @param body
   */
  async onMomentComment(body: { content?; contentLength?; momentId?; toCommentId?; toMemberId? }) {
    await this.$ajax.post(EnumApiHomework.MomentComment, body)
    const updater = old => {
      old.commentCount++
      return old
    }
    // 更新朋友圈列表评论数
    this.SunDrying.onUpdate(body.momentId, updater)
    // 更新朋友圈详情点赞数
    this.Details.onUpdate(updater)
  }

  /**
   * 朋友圈点赞
   */
  async onLikes(data) {
    if (data.like) {
      throw EnumMessage.like_error
    }
    await this.$ajax.put(EnumApiHomework.MomentLike, {
      momentId: data.id,
      likeType: true
    })
    const updater = old => {
      old.likeCount++
      old.like = true
      return old
    }
    // 更新朋友圈列表点赞数
    this.SunDrying.onUpdate(data, updater)
    // 更新朋友圈详情点赞数
    this.Details.onUpdate(updater)
  }

  /**
   * 删除回复
   * @param momentCommentId
   */
  async onDeleteComment(item) {
    await this.$ajax.delete(EnumApiHomework.MomentDeleteComment, {
      id: item.id
    })
    const updater = old => {
      old.commentCount--
      return old
    }
    // 删除朋友圈评论
    this.Details.MomentComment.onRemove(item.id)
    // 更新朋友圈详情评论数
    this.Details.onUpdate(updater)
    // 更新朋友圈列表评论数
    this.SunDrying.onUpdate(item.momentId, updater)
  }
}
export default ControllerHomework
