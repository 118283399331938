/**
 * @author 冷 (https://github.com/LengYXin)
 * @email lengyingxin8966@gmail.com
 * @create date 2020-08-05 14:12:35
 * @modify date 2020-08-05 14:12:35
 * @desc [description]
 */
import Vue from 'vue'
import VueLazyload from 'vue-lazyload'
// import loading from '~/assets/svg/loading.gif';
// import images from '~/store/images';

Vue.use(VueLazyload, {
  // preLoad: 1.3,
  // error: images.logo,
  // loading: images.loading,
  // attempt: 1
  adapter: {
    loaded({ bindType, el, naturalHeight, naturalWidth, $parent, src, loading, error, Init }) {
      el.style.backgroundColor = 'rgba(0,0,0,0)'
    }
  }
})
