





















































import { Component, Vue, Emit, Prop, Mixins, Watch } from 'vue-property-decorator'
import CircleGroup from './views/circleGroup/index.vue'
import { OrderDetails } from '@xt/client/mixins'
import lodash from 'lodash'
import { Observer } from 'mobx-vue'
import ViewGroup from './views/group.vue'
import OrderProduct from '../../pages/my/children/order/views/product/product.vue'
import Product from '@xt/client/entities/basics/product'

@Observer
@Component({
  components: {
    CircleGroup,
    ViewGroup,
    OrderProduct
  }
})
export default class OrderDescPopup extends Mixins(OrderDetails) {
  @Prop() title: string
  @Prop({}) visible: boolean
  @Prop({}) orderNum: string
  @Prop() productInfo: Product
  @Prop() showRefundTime: boolean
  @Prop() refundStatus: boolean
  @Prop() refundEndDate: number
  @Prop() orderStatus: string
  @Prop() orderStatusStyle: string
  @Prop() virtualCurrency: number
  @Prop() getCoinPayPrice: number
  @Prop() payAmounts: number
  @Prop() showInvoiceIng: boolean
  @Prop() showInvoiceSuccess: boolean

  @Emit('hide')
  hidePopup() {}

  async created() {
    const store = this.$store.$storeOrder.orderDetail
    await store.onLoading({ orderNo: this.orderNum })
    this.dataSource = this.Detail.dataSource
    document.body.style.height = '100%'
    document.body.style.overflow = 'hidden'
  }

  destroyed() {
    document.body.style.height = 'auto'
    document.body.style.overflow = 'auto'
  }
}
