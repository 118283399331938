

















import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import { EnumProductTypeQuery } from '@xt/client/enums'
import LiveCourseBtn from '../components/liveCourseBtn/signup.vue'
import DayCourseBtn from '../components/dayCourseBtn/signup.vue'
import ExhibitionBtn from '../components/exhibitionBtn/signup.vue'

@Component({
  components: {
    LiveCourseBtn,
    DayCourseBtn,
    ExhibitionBtn
  }
})
export default class ButtonGroup extends Vue {
  @Prop({}) dataSource
  get EnumProductTypeQuery() {
    return EnumProductTypeQuery
  }
  onlyEntryKey(entryKey) {
    return this.dataSource.productInfo.onlyEntryKey === entryKey
  }
  created() {}
  mounted() {}
  destroyed() {}
}
