













import { Component, Vue, Ref, Inject } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component({
  scrollToTop: false
})
export default class PageView extends Vue {
  get PageStore() {
    return this.$store.$storeUser
  }

  onCancel() {
    this.PageStore.onChangeBindVisible(false)
  }

  async onOk() {
    try {
      await this.PageStore.onUnbindwechat()
      this.PageStore.onChangeBindVisible(false)
      await this.PageStore.onGetUserInfo()
    } catch (error) {
      console.log('Clin -> file: change_wx.vue -> line 45 -> PageView -> bindWx -> error', error)
    }
  }

  created() {}
}
