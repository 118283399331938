





















































import { Component, Prop, Vue } from 'vue-property-decorator'
import Course from './course.vue'
import Show from './show.vue'
import computed from 'mobx'
import DayClass from './dayclass/course.vue'
import SelfStudy from './selfStudy.vue'
import { EnumProductTypeSimple } from '@xt/client/enums/product'

@Component({
  components: {
    Show,
    Course,
    DayClass,
    SelfStudy
  }
})
class Swiper extends Vue {
  // 列表数据
  @Prop() dataSource
  // 更多
  @Prop() more
  // 每页几条数据
  @Prop({ default: 4 }) pageSize
  // 过渡动画时长
  @Prop({ default: 500 }) duration
  // 标题
  @Prop() title
  // 跳转地址
  @Prop() jump
  // 是否是展览列表
  @Prop() show

  @Prop() dayclass

  @Prop() type: EnumProductTypeSimple

  current: number
  upBtnHover: boolean
  nextBtnHover: boolean
  EnumProductTypeSimple = EnumProductTypeSimple

  data() {
    return {
      current: 0, // 当前页
      upBtnHover: false,
      nextBtnHover: false
    }
  }

  get window() {
    return window
  }

  get exactDivision() {
    return this.dataSource.length % this.pageSize == 0
  }

  get count() {
    return Math.floor(this.dataSource.length / this.pageSize) + (this.exactDivision ? 0 : 1)
  }

  get currentExactPage() {
    return this.exactDivision || this.count - 1 != this.current
  }

  get remaining() {
    return this.dataSource.length - this.pageSize
  }

  get trainStyle() {
    let currentItem = 0
    if (this.current != 0) currentItem = this.current * this.pageSize
    if (!this.currentExactPage) currentItem -= this.pageSize - (this.dataSource.length % this.pageSize)
    return `
      transition-duration: ${this.duration / 1000}s;
      width: calc(100% * ${this.count}); 
      left: -${this.$refs['course-' + currentItem] ? this.$refs['course-' + currentItem][0].offsetLeft || 0 : 0}px;`
  }

  nextPage() {
    if (this.current === this.count - 1) return
    this.current += 1
  }

  upPage() {
    if (this.current === 0) return
    this.current -= 1
  }
}

export default Swiper
