



















import LazyImg from '@/components/lazyImg/index.vue'
import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
@Component({
  components: {
    LazyImg
  }
})
export default class extends Vue {
  @Prop({ default: () => [], required: true }) dataSource
  bindCol = {
    lg: 6,
    md: 8,
    sm: 12,
    xs: 24
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
