import { BindAll } from 'lodash-decorators'
import { EnumApiMy } from '../../api'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { EntitiesBasics } from '../basics/entities'
import { Pagination } from '../basics/pagination'

@BindAll()
export class ControllerBiShan extends Pagination<any> {
  constructor($ajax: AjaxBasics) {
    super($ajax, {
      url: EnumApiMy.MyPointsBiShan,
      method: 'get'
    })
  }
}
export default ControllerBiShan
