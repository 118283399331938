







import { Component, Vue } from 'vue-property-decorator'
import lodash from 'lodash'
import tabsView from './views/tabs.vue'
import headerView from './views/header.vue'

@Component({
  async fetch(ctx) {
    const store = ctx.store.$storeCourse
    // 获取我购买的课程ids
    // 加载课程详情数据
    await store.Details.onLoading({
      id: ctx.params.id
    })
  },
  validate({ params }) {
    return /^\d+$/.test(params.id)
  },
  components: {
    tabsView,
    headerView
  }
})
export default class PageView extends Vue {
  VisibleAgreement: boolean = false
  type: string = 'Purchase'
  totalHeight: string = ''
  get PageStore() {
    return this.$store.$storeCourse.Details
  }
  onShow(type) {
    this.VisibleAgreement = true
    this.type = type.type
  }
  created() {}
  mounted() {
    const dataSource = lodash.cloneDeep(this.PageStore.dataSource)
    // if (dataSource.courseFullId && this.$store.$global.dev) {
    //   if (
    //     lodash.isEmpty(dataSource.fullLearnEndTime) ||
    //     dataSource.turnSignUpEndTime === 0
    //   ) {
    //     this.$notification.error({
    //       message: "课程数据有误",
    //       description: "没有正确返回全阶课时间数据",
    //     });
    //   }
    // }
  }

  updated() {}
  destroyed() {}
}
