/*
 * @Author: Erlin
 * @CreateTime: 2020-12-24 20:50:04
 * @LastEditors: Erlin
 * @LastEditTime: 2020-12-24 20:50:04
 * @Description: 修改手机
 */
import { Component, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'

@Observer
@Component
export class MixinsChangePhone extends Vue {
  model = {}
  codeType = 3 //3 绑定手机，4 绑定邮箱，5 更换手机，6 更换邮箱，7 手机找回密码，8 邮箱找回密码
  get PageStore() {
    return this.$store.$storeUser
  }
  isVaild = false
  defaultValue = ''
  oldValue = ''
  get bind() {
    return {
      isVaild: this.isVaild,
      type: 'phone',
      defaultValue: this.defaultValue,
      oldValue: this.oldValue,
      codeType: this.codeType,
      bindText: this.bindText,
      btnText: this.btnText
    }
  }
  get bindText() {
    return this.isVaild ? '已绑定手机号' : '新手机号'
  }
  get btnText() {
    return this.isVaild ? '下一步' : '绑定'
  }
  created() {
    let phone = lodash.get(this.PageStore, ['UserInfo', 'phoneNum'])
    if (phone) {
      this.isVaild = true
      this.defaultValue = phone
      this.oldValue = phone
      this.codeType = 5
    } else {
      this.isVaild = false
      this.codeType = 3
    }
  }
  // 验证原手机号
  async onSubmit(model) {
    if (this.isVaild) {
      lodash.merge(this.model, {
        oldPhoneNum: model.device,
        oldPhoneVerifyCode: model.verifyCode
      })
      this.isVaild = false
      this.defaultValue = ''
    } else {
      try {
        // 3 绑定手机
        if (lodash.eq(this.codeType, 3))
          await this.PageStore.onBindPhone({
            phoneNum: model.device,
            verifyCode: model.verifyCode
          })
        // 5 更换手机
        if (lodash.eq(this.codeType, 5))
          await this.PageStore.onChangePhone(
            lodash.merge(this.model, {
              newPhoneNum: model.device,
              newPhoneVerifyCode: model.verifyCode
            })
          )
        await this.PageStore.onGetUserInfo()
        this.$msg(this.$EnumMessage.bind_success, 'info')
        this.onBindComplete()
      } catch (error) {
        if (lodash.eq(error, false)) this.$msg(this.$EnumMessage.check_code_error)
      }
    }
  }
  // 绑定成功
  onBindComplete() {}
}
