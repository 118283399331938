





import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class extends Vue {
  @Prop({}) words
  @Prop({ default: 10 }) font
  @Prop({ default: 'primary' }) type
  created() {}
  mounted() {}

  updated() {}
  destroyed() {}
}
