/**
 * @author 冷 (https://github.com/LengYXin)
 * @email lengyingxin8966@gmail.com
 * @create date 2020-10-20 20:25:53
 * @modify date 2020-10-20 20:25:53
 * @desc 课程地图页
 */
import { Component, Prop, Vue, Provide, Mixins } from 'vue-property-decorator'
import lodash from 'lodash'
import { MixinsCourseHelpers } from './helpers'
@Component
export class MixinsCourseMap extends Mixins(MixinsCourseHelpers) {
  get id() {
    return this.$route.params.id
  }
  get Details() {
    return this.$store.$storeCourse.Details
  }
  get PageStore() {
    return this.$store.$storeCourse.Details.Map
  }
  get templateType() {
    return this.Details.dataSource.templateType
  }
  // 模板类
  get template() {
    return 'xt-cc-template-' + this.templateType
  }
  // 背景图
  get bgSrc() {
    return `/template/${this.templateType}.png`
  }
  get isReview() {
    return true
  }
  onLoading(id) {
    this.PageStore.onReset()
    return this.PageStore.onLoading({ id })
  }
  // 课程回顾
  onToReview() {
    try {
      this.CourseHelpers.onCheck(this.Details.dataSource)
      this.$router.push({
        name: 'course-review',
        query: { id: this.id }
      })
    } catch (error) {
      this.$msg(error)
    }
  }

  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
