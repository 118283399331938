import { uuid } from '../uuid'

class SocketClient {
  private tabId: string
  private openTime: number

  constructor() {
    this.openTime = Date.now()
    this.setTabId(uuid())
  }

  private setTabId(tabId: string) {
    this.tabId = tabId
    sessionStorage.setItem('tab-id', tabId)
  }

  public getTabId() {
    const id = sessionStorage.getItem('tab-id')

    if (!id || id !== this.tabId) {
      const tabId = uuid()
      this.setTabId(tabId)
      return tabId
    }

    return id
  }
}

const socketClient = new SocketClient()

export default socketClient
