import { AjaxBasics } from '@xt/client'
import { throttle } from 'lodash'

type MouseFrame = {
  type: 'mousemove' | 'mousepause'
  begin: number
  end: number
  duration: number
}

export class MouseTrack {
  private isRecording = false
  private frames: MouseFrame[] = []
  private isBindTouchMove = 'ontouchmove' in window
  private lastTrackTime: number
  private isFirstTrack = false
  private MousePauseThreshold: number
  private trackInterval: null | ReturnType<typeof setInterval> = null
  // 暂停后的inteval有时间间隙没有统计
  private intervalRecordTime: number | null = null
  // 相邻两次移动有时间间隙没有统计
  private lastMouseMoveFrame: null | MouseFrame = null

  constructor(mousePauseThreshold: number) {
    this.lastTrackTime = this.getServerTime()
    this.stop = this.stop.bind(this)
    this.MousePauseThreshold = mousePauseThreshold
    this.handleMouseMove = throttle(this.handleMouseMove.bind(this), 1000, { trailing: false })
  }

  private getServerTime() {
    return AjaxBasics.serviceDate.valueOf()
  }

  private appendFrame(eventType: MouseFrame['type'], begin: number, end: number) {
    const lastFrame = this.frames[this.frames.length - 1]

    if (this.lastMouseMoveFrame) {
      begin = this.lastMouseMoveFrame.end
    }

    if (!lastFrame || eventType !== lastFrame.type) {
      this.frames.push({ type: eventType, begin, end, duration: end - begin })
    } else {
      // 合并相同的事件
      lastFrame.end = end
      lastFrame.duration = lastFrame.end - lastFrame.begin
    }

    this.lastMouseMoveFrame = eventType === 'mousemove' ? { type: eventType, begin, end, duration: end - begin } : null
  }

  private handleMouseMove() {
    const now = this.getServerTime()

    if (!this.isFirstTrack) {
      this.isFirstTrack = true
      this.appendFrame('mousepause', this.lastTrackTime, now)
    }

    // 检测一下上一次定时器是否执行完，有时间间隙
    if (this.intervalRecordTime) {
      this.appendFrame('mousepause', this.intervalRecordTime, now)
      this.intervalRecordTime = null
    }

    this.appendFrame('mousemove', now, now)

    if (this.trackInterval) {
      clearInterval(this.trackInterval)
      this.trackInterval = null
    }

    this.trackInterval = setInterval(() => {
      const _now = this.getServerTime()
      if (_now - this.lastTrackTime > this.MousePauseThreshold) {
        this.appendFrame('mousepause', this.lastTrackTime, _now)
        this.intervalRecordTime = _now
      }
    }, 2000)

    this.lastTrackTime = now
  }

  record() {
    if (this.isRecording) return

    if (this.isBindTouchMove) {
      window.addEventListener('touchmove', this.handleMouseMove)
    } else {
      window.addEventListener('mousemove', this.handleMouseMove)
    }
  }

  stop() {
    this.isRecording = false
    this.frames = []

    if (this.isBindTouchMove) {
      window.removeEventListener('touchmove', this.handleMouseMove)
    } else {
      window.removeEventListener('mousemove', this.handleMouseMove)
    }
  }

  flush() {
    const now = this.getServerTime()
    const result: Record<string, any> = {}
    // 特殊处理一下，如果本次chunk收集到的数据为空，那么填充完整的暂停数据并上报
    if (this.frames.length === 0) {
      this.appendFrame('mousepause', this.lastTrackTime, now)
    }

    const actionFrames = this.frames.filter(item => item.type === 'mousemove')
    const pauseFrames = this.frames.filter(item => item.type === 'mousepause')
    const actionTotal = actionFrames.reduce((prev, current) => {
      return prev + current.duration
    }, 0)
    const pauseTotal = pauseFrames.reduce((prev, current) => {
      return prev + current.duration
    }, 0)
    const total = actionTotal + pauseTotal

    result.total = total
    result.frames = [...this.frames]
    result.actionTotal = actionTotal
    result.pauseTotal = pauseTotal

    this.frames = []
    this.lastTrackTime = this.intervalRecordTime ?? now
    return result
  }

  export() {
    return this.frames
  }
}
