export enum EnumApiNotice {
  /** 消息通知列表 */
  noticeList = '/notice/api/xt/notice/list',
  /** 系统消息通知预览 */
  noticePreview = '/notice/api/xt/notice/preview',
  /** 用户已读通知 */
  noticeRead = '/notice/api/xt/notice/read?id={id}',
  /** 用户全部已读 */
  noticeAllRead = '/notice/api/xt/notice/read/all?type={type}',
  /** 未读消息数量 */
  noticeUnreadQuantity = '/notice/api/xt/notice/unread/quantity'
}
