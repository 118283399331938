import $global from '@xt/client/store/global'
import getBrowser from './browser'
import { AjaxBasics } from '@xt/client/helpers'
// import { getFp } from '@xt/client/utils/fingerprint/report'

export const DeviceType = {
  /**
   * 小程序
   */
  MiniProgram: 1,
  /**
   * PC浏览器
   */
  PCBrowser: 2,
  /**
   * H5浏览器
   */
  H5Browser: 3
} as const

type DeviceInfoResult = {
  appId: string
  /**
   * 应用名称（暄桐PC官网，暄桐H5官网，暄桐管理后台，暄桐小程序，暄桐展览小程序）
   */
  appName: string

  /**
   * 浏览器类型(全小写chrome/edge/safari/xiaomi等)
   */
  devBrowser: string

  /**
   * 浏览器内核(全小写webkit,blink）
   */
  devBrowserKernel: string

  /**
   * 浏览器版本
   */
  devBrowserVersion: string

  /**
   * 设备指纹
   */
  devId: string

  /**
   * 设备系统类型（全大写IOS,AN,MAC,WIN）
   */
  devSys: string

  /**
   * 设备系统版本
   */
  devSysVersion: string

  /**
   * 设备类型（1 小程序，2浏览器）
   */
  devType: typeof DeviceType[keyof typeof DeviceType]

  /**
   * 微信app版本
   */
  devWxAppVersion: string

  /**
   * 设备品牌（iPhone，Xiaomi，Apple，Microsoft）
   */
  devWxAppletBrand: string

  /**
   * 设备型号
   */
  devWxAppletModel: string

  /**
   * 小程序sdk版本
   */
  devWxAppletsdkVersion: string
}

export const getDeviceInfo: () => Promise<DeviceInfoResult> = async () => {
  await AjaxBasics.initDevId()

  const { browser, engine, browserVersion, system, systemVersion } = await getBrowser.getInfo(navigator.userAgent)

  // const { current } = await getFp($global)

  const result: DeviceInfoResult = {
    appId: $global.xtAppid,
    appName: $global.platform === 'Mobile' ? '暄桐H5官网' : '暄桐PC官网',
    devBrowser: browser.toLowerCase(),
    devBrowserKernel: engine.toLowerCase(),
    devBrowserVersion: browserVersion,
    devId: $global.devId,
    devSys: system.toUpperCase(),
    devSysVersion: systemVersion,
    devType: $global.platform === 'Mobile' ? DeviceType.H5Browser : DeviceType.PCBrowser,
    devWxAppVersion: '',
    devWxAppletBrand: '',
    devWxAppletModel: '',
    devWxAppletsdkVersion: ''
  }

  return Promise.resolve(result)
}
