




















































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import Comment from '@xt/www/components/business/homework/comment.vue'

type ShineHomeworkDetailType = {
  bishanCount: number
  collect: boolean
  collectCount: number
  commentCount: number
  content: string
  createTime: number
  id: string
  like: boolean
  likeCount: number
  likeRecords: any[]
  memberId: string
  memberNickname: string
  pictures: any[]
  publishTime: number
  showTitle: string
  sourceDescription: string
  sourceType: number
  title: string
  videoUrl: string
  viewCount: number
}

@Observer
@Component({
  components: {
    Comment
  }
})
export default class extends Vue {
  @Prop({ default: false }) visible: boolean
  @Prop() momentId: string

  dataSource: ShineHomeworkDetailType | null = null

  get HomeworkStore() {
    return this.$store.$storeHomework
  }

  get HomeworkDetailStore() {
    return this.HomeworkStore.Details
  }

  get MomentComment() {
    return this.HomeworkDetailStore.MomentComment
  }

  get Pagination() {
    return this.$store.$my.MySunWork
  }

  getComment(item: ShineHomeworkDetailType) {
    return {
      // toUserName: item.toMemberNickname,
      content: item.content,
      memberId: item.memberId,
      author: item.memberNickname,
      time: item.createTime,
      bishan: item.bishanCount,
      imgs: item.pictures
    }
  }

  /** 作业点赞 */
  async onLikes(item: ShineHomeworkDetailType) {
    try {
      await this.Pagination.onLikes(item)
    } catch (error) {
      this.$msg(error)
    }
  }

  /** 删除晒出的作业 */
  async onDelete(item: ShineHomeworkDetailType) {
    try {
      await this.Pagination.onDelWork(item.id)
      this.$msg(this.$EnumMessage.delete_success, 'success')
    } catch (error) {
      this.$msg(error)
    }
  }

  /** 朋友圈发布评论&回复评论 */
  async onSubmit(event, data) {
    try {
      this.$InspectUser()
      let hasComment = this.dataSource.commentCount ? true : false
      await this.HomeworkStore.onMomentComment({
        content: this.encodeHTML(event.content),
        contentLength: event.length,
        momentId: this.momentId,
        toCommentId: lodash.get(data, 'id', 0),
        toMemberId: lodash.get(data, 'memberId', 0)
      })
      event.onReset()
      this.onLoading()
      if (hasComment) {
        this.MomentComment.onReset()
      }
    } catch (error) {
      console.log('LENG: PageView -> onSubmit -> error', error)
    }
  }

  async onLoading() {
    await this.HomeworkDetailStore.onLoading({ id: this.momentId })
    this.dataSource = this.HomeworkDetailStore.dataSource
  }

  @Watch('visible')
  async updateVisible(value: boolean) {
    if (!value) return
    await this.onLoading()
  }

  @Emit('cancel')
  cancelPopupHandler() {
    this.dataSource = null
  }

  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
