import canvasFP from './canvas'
import webglFp from './webgl'
import audioFP from './audio'
// import sha256 from 'crypto-js/sha256'
import md5 from 'crypto-js/md5'
import { murmurHash } from 'ohash'

export const getFinger = async () => {
  const canvas = canvasFP()
  const webgl = webglFp()
  const audio = await audioFP()
  // 移除webgl指标，变化率很高
  // const result = `XT_${canvas.rawData}_${webgl.rawData}_${audio.rawData}_${process.env.platform}_Signature`
  const result = `XT_${canvas.rawData}_${audio.rawData}_${process.env.platform}_Signature`
  const md5Signature = md5(result)
    .toString()
    .toUpperCase()


  const canvasHash = md5(canvas.rawData).toString().toUpperCase();
  const webglHash = md5(webgl.rawData).toString().toUpperCase();
  const audioHash = md5(audio.rawData).toString().toUpperCase();
  const debugInfo = {
    canvas: canvasHash,
    webgl: webglHash,
    audio: audioHash,
    result: murmurHash(`${canvasHash}-${audioHash}`)
  }

  return { result: md5Signature, debugInfo }
}
