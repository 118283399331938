





















import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import Order from '@xt/client/entities/basics/order'
import Teleport from '@xt/client/mixins/components/teleport.vue'
import OrderDescPopup from '@xt/www/components/orderDesc/index.vue'
import { EnumGroupStatus, EnumInvoiceState, EnumOrderState } from '@xt/client/enums'

@Observer
@Component({
  components: {
    Teleport,
    OrderDescPopup
  }
})
export default class extends Vue {
  @Prop({ default: false }) visible: boolean
  @Prop() orderNo: number

  dataSource: Order | null = null

  get OrderDetailStore() {
    return this.$store.$storeOrder.Details
  }

  get isMyGive() {
    return this.dataSource.origin.memberId === this.$store.$storeUser.UserInfo.id && this.dataSource.isGive
  }

  get showRefundMessage() {
    return (
      !this.dataSource.isNotPay &&
      !this.dataSource.isCancelled &&
      !this.dataSource.isHasRefund &&
      (!this.dataSource.isGive || !this.isMyGive) &&
      (!this.dataSource.productInfo.isOpenGroup || this.dataSource.origin.status !== EnumGroupStatus.Fail)
    )
  }

  get getCoinPayPrice() {
    // 桐板儿支付金额 > 订单原价  （纯桐板儿支付）
    if (this.dataSource.origin.virtualCurrency / 100 > this.dataSource.origin.orderAmount / 100) {
      return this.dataSource.origin.orderAmount / 100
    }
    return this.dataSource.origin.virtualCurrency / 100
  }

  get showInvoiceIng() {
    return (
      this.dataSource.orderStatus === EnumOrderState.HasPay &&
      this.dataSource.invoiceState === EnumInvoiceState.WaitInvoice &&
      (!this.dataSource.isGive || this.isMyGive) &&
      !this.dataSource.zeroOrder
    )
  }

  get showInvoiceSuccess() {
    return (
      this.dataSource.orderStatus === EnumOrderState.HasPay &&
      this.dataSource.invoiceState === EnumInvoiceState.HasInvoice &&
      (!this.dataSource.isGive || this.isMyGive) &&
      !this.dataSource.zeroOrder
    )
  }

  @Watch('visible')
  async updateVisible(value: boolean) {
    if (!value) return
    await this.OrderDetailStore.onLoading({ orderNo: this.orderNo })
    this.dataSource = new Order(this.OrderDetailStore.dataSource)
  }

  @Emit('cancel')
  cancelPopupHandler() {
    this.dataSource = null
  }

  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
