




































import { Component, Inject, Vue, Watch, Provide } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
@Observer
@Component({
  components: {}
})
export default class PageView extends Vue {
  get PageStore() {
    return this.$store.$storeVideo
  }
  get Pagination() {
    return this.PageStore.CommentPagination
  }
  get id() {
    return this.$route.params.id
  }
  get body() {
    return { videoShareId: this.id }
  }
  get placeholder() {
    return this.eqReply(this.reply) ? `回复 ${this.reply.memberNickName}` : '说说你的想法吧'
  }

  // 回复
  reply: any = {}
  getComment(item) {
    return {
      content: item.content,
      author: item.memberNickName,
      memberId: item.MemberId,
      time: item.createTime,
      toUserName: item.toMemberNickName
    }
  }

  /**
   * 是否回复
   */
  eqReply(data) {
    return lodash.eq(this.reply.id, data.id)
  }
  /**
   * 点赞
   */
  async onLikes(data) {
    try {
      await this.$InspectUser()
      await this.Pagination.onLikes(data)
      this.$msg(this.$EnumMessage.like_success, 'success')
    } catch (error) {
      this.$msg(error)
    }
  }
  /**
   * 回复
   */
  onReply(data) {
    if (this.eqReply(data)) {
      return (this.reply = {})
    }
    this.reply = data
  }
  async onSubmit(event, data) {
    try {
      this.$InspectUser()
      await this.PageStore.onVideoCommentAdd({
        videoShareId: this.id,
        content: this.encodeHTML(event.content),
        contentLength: event.length,
        toCommentId: lodash.get(data, 'id', 0)
      })
      event.onReset()
      this.reply = {}
      this.Pagination.onReset({ infinite: true })
      // this.Pagination.onLoading(this.body);
    } catch (error) {
      console.log('LENG: PageView -> onSubmit -> error', error)
    }
  }
  created() {
    this.Pagination.onReset()
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
