























import { Observer } from 'mobx-vue'
import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator'
import Product from './views/product.vue'

@Observer
@Component({
  components: { Product },
  name: 'allcourse'
})
export default class Page extends Vue {
  loading = true
  allCourseInfo: any = []
  haveNoneCourse = false

  get PageStore() {
    return this.$store.$my
  }

  async onLoading() {
    try {
      this.loading = true
      this.allCourseInfo = await this.PageStore.onGetMyAllProduct()
      if (this.allCourseInfo.length <= 0) {
        this.haveNoneCourse = true
      }
      this.loading = false
    } catch (error) {
      this.loading = false
    }
  }

  created() {
    this.onLoading()
  }
}
