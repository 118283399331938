



























































import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator'
import lodash from 'lodash'
import { EnumProductType } from '@xt/client'
import { Observer } from 'mobx-vue'
import Signup from './signup.vue'
import DownTime from './downTime.vue'
@Observer
@Component({
  components: { Signup, DownTime }
})
export default class PageView extends Vue {
  @Prop({ default: () => ({}) }) dataSource
  @Prop({ default: 'left' }) badge
  get PageStore() {
    return this.$store.$storeCourse
  }
  get badgeClass() {
    return 'xt-badge xt-badge-' + this.badge
  }
  get to() {
    if (this.dataSource.original.productType && this.dataSource.original.productType === EnumProductType.dayCourse) {
      return { name: 'activitys-course-id', params: { id: this.dataSource.original.bizId } }
    }
    return { name: 'course-id', params: { id: this.dataSource.original.bizId } }
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
