

































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { giftCache, giftExpiredTime } from '@xt/client/helpers'

@Observer
@Component
export default class GiveawayModal extends Vue {
  @Prop() dataSource: any

  qrcodeUrl: string = ''
  loading: boolean = true
  get PageStore() {
    return this.$store.$storeUser
  }

  async onCancel() {
    giftCache.set(`giveaway-${this.dataSource.orderNo}`, 'expired', giftExpiredTime)
    this.PageStore.removeUniformGift()
    await this.initAppletCode()
  }

  async initAppletCode() {
    this.loading = true
    try {
      this.qrcodeUrl = await this.$store.$storeShow.getQrcodeUrl({
        page: 'pages/giveaway/index',
        query: `${this.dataSource.orderNo}`,
        appletsType: 2,
        hyaline: true
      })
    } finally {
      this.loading = false
    }
  }

  async created() {
    await this.initAppletCode()
  }

  mounted() {
    // 显示弹窗口修改body防止滚动穿透
    document.body.style.height = '100%'
    document.body.style.overflow = 'hidden'
  }

  destroyed() {
    // 关闭弹窗时，恢复body
    document.body.style.height = 'auto'
    document.body.style.overflow = 'auto'
  }
}
