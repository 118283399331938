/// <reference types="./video" />
import { BindAll } from 'lodash-decorators'
import { EnumApiCurrency, EnumApiVideo } from '../../api'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { EntitiesBasics } from '../basics/entities'

/**
 * 视频详情
 */
@BindAll()
export class VideoDetails extends EntitiesBasics<IVideoDetails> {
  constructor(protected $ajax: AjaxBasics) {
    super($ajax, {
      // 课程详情地址
      url: EnumApiVideo.VideoDetail,
      method: 'get'
    })
  }
  /**
   * 添加视频播放量
   * @param videoShareId
   */
  async onPlayNumber(id) {
    return this.$ajax.put(EnumApiVideo.VideoPlayNumber, { id })
  }
}
export default VideoDetails
