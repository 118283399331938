









import { Component, Ref, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
// import Experience from './experience.json'
import Swiper from './views/swiper/index.vue'
import Detail from './views/detail/index.vue'

interface Root {
  tabs: Tab[]
  exp: Exp[]
  detail: any[]
  pcTopBackground: string
}

interface Tab {
  name: string
  key: string
  imageList: ImageList[]
}

interface ImageList {
  image: string
  width: number
  height: number
}

interface Exp {
  level: number
  background: string
  backgroundPC: string
  name: string
  levelImg: string
  nameImg: string
  cornerImg: string
  upgrade: number
  tip: string
  bg?: string
}

@Observer
@Component({
  async asyncData(ctx) {
    // 读取远程JSON文件
    const res = await ctx.store.$ajax.get(
      `/mocks/uc.exp.json`,
      {},
      { 'Cache-Control': 'no-store', 'If-Modified-Since': '0' },
      { target: ctx.store.$global.production ? 'https://static.xuantong.cn' : 'https://testing-static.xuantong.cn' }
    )
    //单元测试用本地JSON文件
    // const res = Experience
    return {
      dataSource: res
    }
  },
  components: { Swiper, Detail }
})
export default class PageView extends Vue {
  dataSource: Root

  created() {}
  updated() {}
  destroyed() {}
}
