import { OrderPayChooser, OrderPayChooserProps } from '@xt/client/mixins/orderpay/chooser'
import ChooserComp from './chooser.vue'

export default class Chooser extends OrderPayChooser {
  constructor(props: OrderPayChooserProps) {
    props = Object.assign({}, { vm: ChooserComp }, props)
    super(props)
  }
  protected platform(): 'Mobile' | 'PC' {
    return 'PC'
  }
}
