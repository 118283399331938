



import { Component, Prop, Vue, Watch, Inject } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'

@Observer
@Component({
  components: {}
})
export default class PageView extends Vue {
  @Prop({ default: {} }) item
  get PageStore() {
    return this.$store.$storeVideo
  }
  async onClick() {
    try {
      await this.$InspectUser()
      await this.PageStore.onLikes(this.item)
    } catch (error) {
      this.$msg(error)
    }
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
