





















































import { Component, Prop, Vue, Provide, Emit } from 'vue-property-decorator'
import lodash from 'lodash'
import { Observer } from 'mobx-vue'
import SliderValidComp from '../../../components/sliderValid/index.vue'
import { Bind, Debounce } from 'lodash-decorators'

@Observer
@Component({
  components: {
    SliderValidComp
  }
})
export default class extends Vue {
  get PageStore() {
    return this.$store.$storeUser
  }
  get locale() {
    return this.$EnumLocaleLinks
  }
  get formOptions() {
    return {
      vaild: false,
      phone: false,
      password: this.password,
      codeType: 3,
      btnText: '登录',
      label: '邮箱'
    }
  }
  visible = false
  password = false
  tabPane = [
    { key: 1, title: '邮箱验证码登录' },
    { key: 2, title: '邮箱密码登录' }
  ]
  activeKey = 1
  isClickBtn: boolean = false
  tabsChange(activeKey) {
    this.activeKey = activeKey
    if (lodash.eq(activeKey, '1')) {
      this.password = false
    } else {
      this.password = true
    }
  }
  removeBlankSymbol(val) {
    let newVal = val.replace(/[\r\n\f\t\v\s]/g, '')
    return newVal
  }
  tempModel: any
  tempCallback: any

  // 是否正在oauth
  isSubmitting = false

  closeHandler() {
    this.visible = false
  }

  confirmhandler() {
    this.closeHandler()
    this.onSubmit(this.tempModel)
  }

  @Bind
  @Debounce(500, { leading: true, trailing: false })
  async onValidLogin(model) {
    if (this.isSubmitting) return
    this.tempModel = model

    if (this.password) {
      this.isClickBtn = true
    } else {
      try {
        await this.PageStore.onValidAccountExist({
          device: model.device,
          verifyCode: model.verifyCode,
          verifyCodeType: 2
        })
      } catch (error) {
        if (error === false) {
          this.visible = true
        }
        return
      }

      this.onSubmit(this.tempModel)
    }
  }

  async onValidSend(model, callback, closeLockCallback) {
    this.tempModel = model
    this.tempCallback = callback
    closeLockCallback()
    this.isClickBtn = true
  }
  async onSuccessHandler() {
    this.PageStore.onChangeLoginModalLoading(true)
    this.isClickBtn = false
    try {
      if (this.password) {
        await this.onSubmit(this.tempModel)
      } else {
        await this.onSendSms(this.tempModel, this.tempCallback)
      }
    } finally {
      this.PageStore.onChangeLoginModalLoading(false)
    }
  }
  async onSendSms(model, callback) {
    let device = this.removeBlankSymbol(String(model.device))
    try {
      await this.PageStore.onLoginByEmail(device)
      callback()
    } catch (error) {
      if (lodash.eq(error, false)) this.$msg(this.$EnumMessage.check_code_error)
    }
  }
  async onSubmit(model) {
    this.isSubmitting = true
    let device = this.removeBlankSymbol(String(model.device))
    try {
      await this.$store.$storeUser.onLogin(
        {
          itcode: device,
          password: model.password,
          code: model.verifyCode
        },
        this.password ? 'email_pwd' : 'email_code'
      )
      this.PageStore.onToggleVisible(false)
      this.$msg('登录成功，欢迎你桐学 🎉')
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    } catch (error) {
    } finally {
      this.isSubmitting = false
    }
  }
  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
