










































import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator'
import lodash from 'lodash'
import { Observer } from 'mobx-vue'
import Signup from './signup.vue'
import DownTime from './downTime.vue'

@Component({
  components: { Signup, DownTime }
})
export default class PageView extends Vue {
  @Prop({ default: () => {} }) dataSource
  get hasData() {
    return !lodash.isEmpty(this.dataSource)
  }
  created() {}
  swiperOption = {
    type: 'bullets',
    slidesPerView: 2.6,
    spaceBetween: 30,
    grabCursor: true, //贴心抓手
    roundLengths: true, //将slide的宽和高取整(四舍五入)，以防止某些分辨率的屏幕上文字或边界(border)模糊。
    centerInsufficientSlides: true, //当slides的总数小于slidesPerView时，slides居中。
    direction: 'horizontal',
    navigation: {
      // nextEl: this.$refs.prev,
      // prevEl: this.$refs.prev,
    },
    on: {
      resize: () => {
        // this.$refs.swiper.$swiper.changeDirection(
        //   window.innerWidth <= 960 ? "vertical" : "horizontal"
        // );
      }
    }
  }
  formatTitle(title) {
    return lodash.replace(title, /\||｜/g, '<br/>')
  }
  get swiper() {
    return (this.$refs.swiper as any).$swiper
  }
  onShowPrev(length) {
    return length > this.swiperOption.slidesPerView
  }
  onSwiper(key) {
    lodash.result(this.swiper, key)
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
