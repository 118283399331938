


















import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class PageView extends Vue {
  @Prop({ default: false }) visible: boolean
  @Prop({ default: '' }) title: string
  @Prop({ default: '' }) subTitle: string
  @Prop({ default: '' }) qrcodeUrl: string
  @Prop({ default: '' }) scanTip: string

  onCancel() {
    this.$emit('closeWxImgPop')
  }
  created() {}
}
