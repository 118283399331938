import { EnumApiCourse, EnumApiProduct } from '@xt/client/api'
import { Course } from '../../entities/basics/course'
import { Observer } from 'mobx-vue'
import { Vue, Component } from 'vue-property-decorator'

@Observer
@Component
export default class OrderListScript extends Vue {
  get PageStore() {
    return this.$store.$storeOrder
  }
  get Pagination() {
    return this.PageStore.Pagination
  }

  /**
   * 获取我购买过的课程
   */
  async onOwnHasbuyCourse() {
    try {
      const res: Array<number> = await this.$ajax.get(EnumApiCourse.CourseListHasbuy)
      // 自己买过的课程
      const hasbuyCourse: Array<any> = await this.$ajax.get(EnumApiProduct.productHasBuy)
      Course.onSetUserHasbuy(res, hasbuyCourse)
    } catch (error) {
      console.log(error)
    }
  }

  onRefresh() {
    this.onOwnHasbuyCourse()
    this.Pagination.onReset({ infinite: true })
    this.Pagination.onLoading()
  }
}
