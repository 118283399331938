import { Observer } from 'mobx-vue'
import { Vue, Component } from 'vue-property-decorator'

@Observer
@Component
export default class WxBindScript extends Vue {
  onBindWXCancel() {
    this.$store.$storeUser.hideWeiXinBind()
  }

  /**
   * 重定向地址
   */
  private _redirectUri(): string {
    return (
      window.location.origin +
      window.$nuxt.$router.resolve({
        name: window.$nuxt.$route.name,
        params: window.$nuxt.$route.params,
        query: window.$nuxt.$route.query
      }).href
    )
  }
  mounted() {
    this.$nextTick(() => {
      this.$store.$wechat.onWxLogin(
        'wx_login_container',
        this.$store.$global.openAppid,
        'bind',
        this._redirectUri(),
        'data:text/css;base64,LyogLmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMXB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyMDBweDt9Ci5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30gKi8KLmltcG93ZXJCb3ggLnRpdGxlewogICAgZGlzcGxheTogbm9uZTsKfQouaW1wb3dlckJveCAuaW5mbyB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnFyY29kZSB7CiAgICB3aWR0aDogMTI0cHg7CiAgICBib3gtc2l6aW5nOiBib3JkZXItYm94OwogICAgbWFyZ2luLXRvcDogMDsKICAgIGJvcmRlcjogbm9uZTsKICAgIG1hcmdpbi10b3A6IC04cHg7CiAgICBtYXJnaW4tbGVmdDogLThweDsKfQouaW1wb3dlckJveCAud3JwX2NvZGV7CiAgICB3aWR0aDoxMTBweDsKfQ=='
      )
    })
  }
}
