/**
 * @author wangsl
 */
import { Observer } from 'mobx-vue'
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

@Observer
@Component
export default class agreementScript extends Vue {
  @Prop({}) visible: boolean
  @Prop({}) heandle: () => void
  @Prop({}) dataSource: any

  selected: boolean = false
  countdown: number = 5
  countdownTimer: NodeJS.Timer

  get platform() {
    return this.$store.$global.platform
  }

  get isWechatBowser() {
    return this.$store.$global.WechatBowser
  }

  get isSafariBowser() {
    return /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
  }

  get isAppleisSafariBowser() {
    // 如果是手机端 & 不是在微信内 & 是Safari浏览器
    return this.platform == 'Mobile' && !this.isWechatBowser && this.isSafariBowser
  }

  updateSelect() {
    this.selected = !this.selected
  }

  showSecAgr() {
    this.$router.push({
      name: 'agreement',
      query: { id: String(this.dataSource.productId) }
    })
  }

  @Emit('hide')
  hidePop() {}

  confir() {
    if (this.countdown <= 0 && this.selected) {
      this.heandle()
    }
  }

  @Watch('visible')
  watchVisible(nV: boolean) {
    this.countdown = 5
    this.selected = false
    if (nV) this.down()
  }

  beforeDestroy() {
    clearInterval(this.countdownTimer)
  }

  down() {
    clearInterval(this.countdownTimer)
    this.countdownTimer = setInterval(() => {
      this.countdown = this.countdown - 1
      if (this.countdown <= 0) clearInterval(this.countdownTimer)
    }, 1000)
  }

  created() {
    this.down()
  }
}
