














import collagePopupScript from './collagePopupScript'
import { Component } from 'vue-property-decorator'

@Component({
  name: 'collagePopupComp'
})
export default class collagePopupComp extends collagePopupScript {}
