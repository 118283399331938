import Confs from './htmlFilterConf.json'

// 要保留的属性，性能考虑不使用正则检验值
type AttrType = { aName: string; aValueRegExp: string | 'ALL'; perfectMatch?: boolean | undefined }

// 要保留的样式，性能考虑不使用正则检验值
type StyleType = { sName: string; sValueRegExp: string | 'ALL'; perfectMatch?: boolean | undefined }

/**
 * 要保留的节点
 */
type RetainElType = {
  elName: string
  attributes: AttrType[]
  styles: StyleType[]
  toSpan?: boolean
}

// 配置文件
const RetainEls: RetainElType[] = Confs.RetainEls

/**
 * HTML编码
 */
const __HTMLEncode = function (str: string): string {
  if (!str) return String().toString()
  return str
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/ /g, '&nbsp;')
    .replace(/\'/g, '&#39;')
    .replace(/\'/g, '&quot;')
}

/**
 * 将保留的属性Copy进空标签内
 */
const __CopyHtmlAttribute = function (emptyEl: HTMLElement, el: HTMLElement, attrs: AttrType[]): HTMLElement {
  attrs.map((attr: AttrType) => {
    let value: string = el.getAttribute(attr.aName) || String().toString()
    // 是否不限制值, 或者检验通过
    if (attr.aValueRegExp === 'ALL' || (attr.perfectMatch ? value === attr.aValueRegExp : value.indexOf(attr.aValueRegExp) !== -1))
      emptyEl.setAttribute(attr.aName, value)
  })
  return emptyEl
}

/**
 * 将保留的样式Copy进空标签内
 */
const __CopyHtmlStyle = function (emptyEl: HTMLElement, el: HTMLElement, styles: StyleType[]): HTMLElement {
  styles.map((style: StyleType) => {
    let value: string = el.style[style.sName] || String().toString()
    // 是否不限制值, 或者检验通过
    if (style.sValueRegExp === 'ALL' || (style.perfectMatch ? value === style.sValueRegExp : value.indexOf(style.sValueRegExp) !== -1))
      emptyEl.style[style.sName] = el.style[style.sName]
  })
  return emptyEl
}

/**
 * 删除指定节点
 */
const __RemoveEl = function (el: HTMLElement): void {
  el.parentElement.removeChild(el)
}

/**
 * Copy节点
 */
const __CreateAfterFilterHtml = function (el: HTMLElement): { html: string; retail: boolean } {
  for (let i: number = 0; i < RetainEls.length; i++) {
    let retailEl: RetainElType = RetainEls[i]
    // 该节点需要保留
    if (el.nodeName.toUpperCase() === retailEl.elName.toUpperCase()) {
      // 创建空白节点继承要保留的属性及样式
      let emptyEl: HTMLElement = document.createElement(el.nodeName)
      // 继承属性
      emptyEl = __CopyHtmlAttribute(emptyEl, el, retailEl.attributes)
      // 继承样式
      emptyEl = __CopyHtmlStyle(emptyEl, el, retailEl.styles)
      // 继承子节点
      emptyEl.innerHTML = htmlInjectionFilter(el)
      return { html: emptyEl.outerHTML, retail: true }
    }
  }
  let emptyEl: HTMLElement = document.createElement('span')
  emptyEl.innerHTML = htmlInjectionFilter(el)
  return { html: emptyEl.outerHTML, retail: false }
}

/**
 * 根据RetainEls规则渲染富文本
 * @returns
 */
export const htmlInjectionFilter = function (root: HTMLElement) {
  // 保存处理结果
  let html: string = String().toString()
  // 读取根节点中子节点
  let els: NodeListOf<ChildNode> = root.childNodes
  // 如果不包含子节点则返回空
  if (!els.length) return html
  els.forEach((el: HTMLElement) => {
    switch (el.nodeName) {
      case '#text': // 纯文本节点处理
        html += `<span>${__HTMLEncode(el.nodeValue)}</span>`
        break
      default:
        let res: { html: string; retail: boolean } = __CreateAfterFilterHtml(el)
        // 保留则拼接否则删除节点
        res.retail ? (html += res.html) : __RemoveEl(el)
    }
  })

  return html
}
