import lodash from 'lodash'
;(function (scope) {
  try {
    if (lodash.includes(scope.location.search, 'vconsole')) {
      const vcjs = document.createElement('script')
      vcjs.async = true
      vcjs.src = `${lodash.get(scope, '__xt__env.static', process.env.static)}/script/vconsole.min.js?t=${Date.now()}`
      vcjs.addEventListener('load', event => {
        console.log('加载成功')
        // @ts-ignore
        const vConsole = new VConsole()
        console.log('LENG ~ PageView ~ created ~ vConsole', vConsole)
      })
      document.body.appendChild(vcjs)
    }
  } catch (error) {
    console.log('LENG: error', error)
  }
})(
  ('undefined' !== typeof process && '[object process]' === {}.toString.call(process)) ||
    ('undefined' !== typeof navigator && navigator.product === 'ReactNative')
    ? global
    : self
)
