









import lodash from 'lodash'
import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class extends Vue {
  @Prop({ default: '' }) content
  mounted() {}
  updated() {}
  destroyed() {}
}
