import lodash from 'lodash'
import { BindAll } from 'lodash-decorators'
import { Observable } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { EnumApiCurrency } from '../../api'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { Toast } from 'vant'
declare const WxLogin: any
@BindAll()
export class ControllerWechat {
  constructor(
    protected $ajax: AjaxBasics,
    protected appId
  ) {
    // 提前加载WEIXINJSSDK
    try {
      if (wx) this.onInit()
    } catch (_) {}
  }
  get WeixinJSBridge() {
    if (wx) {
      return wx
    }
    return undefined
  }
  isReady = false
  loading = false
  jsApiList = [
    'updateAppMessageShareData',
    'updateTimelineShareData',
    // 'onMenuShareWeibo',
    // 'onMenuShareQZone',
    // 'startRecord',
    // 'stopRecord',
    // 'onVoiceRecordEnd',
    // 'playVoice',
    // 'pauseVoice',
    // 'stopVoice',
    // 'onVoicePlayEnd',
    // 'uploadVoice',
    // 'downloadVoice',
    'chooseImage',
    // 'previewImage',
    'uploadImage',
    // 'downloadImage',
    // 'translateVoice',
    // 'getNetworkType',
    // 'openLocation',
    // 'getLocation',
    // 'hideOptionMenu',
    // 'showOptionMenu',
    // 'hideMenuItems',
    'showMenuItems',
    // 'hideAllNonBaseMenuItem',
    // 'showAllNonBaseMenuItem',
    // 'closeWindow',
    // 'scanQRCode',
    'chooseWXPay',
    'openAddress'
    // 'openProductSpecificView',
    // 'addCard',
    // 'chooseCard',
    // 'openCard'
  ]
  openTagList = ['wx-open-launch-weapp']
  Promise: Promise<any>
  /**
   * 初始化
   * @param appId
   */
  async onInit(debug = false) {
    try {
      if (this.isReady) {
        return this.WeixinJSBridge
      }
      if (!this.loading) {
        this.loading = true
        const url = window['__xt__href'].split('#')[0]
        this.Promise = this.$ajax
          .request<string>({
            url: EnumApiCurrency.wxmp,
            method: 'get',
            body: { url },
            headers: { Authorization: 'Basic dXNlci1jbGllbnQ6dXNlci1zZWNyZXQtODg4OA==' }
          })
          .pipe(
            mergeMap(config => {
              this.loading = false
              return new Observable<boolean>(sub => {
                config = lodash.merge(
                  {
                    debug: debug,
                    appId: this.appId,
                    jsApiList: this.jsApiList,
                    openTagList: this.openTagList
                  },
                  config
                )
                this.WeixinJSBridge.config(config as any)
                this.WeixinJSBridge.ready(() => {
                  sub.next(true)
                  sub.complete()
                })
                wx.error(err => {
                  sub.error(false)
                  sub.complete()
                })
              })
            })
          )
          .toPromise()
      }
      await this.Promise
      this.isReady = true
    } catch (error) {
      console.log('LENG: ControllerWechat -> onInit -> error', error)
      this.isReady = false
    }
    return this.WeixinJSBridge
  }
  /**
   * 获取微信 open id
   * @param code
   */
  onGetOpenid(code) {
    return this.$ajax.request({ url: EnumApiCurrency.openid, body: { code, type: 2 }, responseType: 'text' }).toPromise()
  }
  // https://developers.weixin.qq.com/doc/oplatform/Website_App/WeChat_Login/Wechat_Login.html
  onWxLogin(id, appId, state = 'login', redirect_uri = window.location.origin, addCss?: string) {
    console.log('LENG ~ ControllerWechat ~ onWxLogin ~ redirect_uri', redirect_uri)
    new WxLogin({
      self_redirect: false,
      id: id,
      appid: appId,
      scope: 'snsapi_login',
      redirect_uri: encodeURIComponent(redirect_uri),
      state: state,
      style: '',
      response_type: 'auth',
      href: !addCss
        ? 'data:text/css;base64,LyogLmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMXB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyMDBweDt9Ci5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30gKi8KLmltcG93ZXJCb3ggLnRpdGxlewogICAgZGlzcGxheTogbm9uZTsKfQouaW1wb3dlckJveCAuaW5mbyB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnFyY29kZSB7CiAgICB3aWR0aDogMTY1cHg7CiAgICBib3gtc2l6aW5nOiBib3JkZXItYm94OwogICAgbWFyZ2luLXRvcDogMDsKICAgIHBvc2l0aW9uOiByZWxhdGl2ZTsKICAgIHRvcDogLTEwcHg7CiAgICBsZWZ0OiAtMTBweDsKfQouaW1wb3dlckJveCAud3JwX2NvZGV7CiAgICB3aWR0aDoxNDhweDsKfQ=='
        : addCss
    })
  }

  /**
   * 授权 url https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/Wechat_webpage_authorization.html#0
   * @param redirect_uri
   */
  getAuthorizeUrl(redirect_uri, scope: 'snsapi_base' | 'snsapi_userinfo' = 'snsapi_userinfo', state = 'login') {
    console.log('LENG ~ ControllerWechat ~ redirect_uri', redirect_uri)
    return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appId}&redirect_uri=${encodeURIComponent(
      redirect_uri
    )}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`
  }

  /*
   *判断用户是否可以注销
   * */
  async checkWechatCancelled() {
    try {
      let res = await this.$ajax.get(EnumApiCurrency.checkWechat)
      return res
    } catch (e) {
      if (e === false) {
        return e
      }
      console.log(e)
    }
  }
  /*
   *判断用户是否可以注销
   * */
  async checkWechat2(code, wxPlatformType = 2) {
    try {
      let res = await this.$ajax
        .request({
          url: EnumApiCurrency.checkWechat2,
          body: { code, wxPlatformType: wxPlatformType },
          responseType: 'text',
          method: 'post'
        })
        .toPromise()
      return res
    } catch (e) {
      if (e === false) {
        return e
      } else {
        let msg = e.errMsg ? e.errMsg : ''
        if (msg) {
          Toast(msg)
        }
      }
    }
  }
  /*
   *判断用户是否可以注销
   * */
  async cancelWechat(beforeres: string) {
    try {
      let res = await this.$ajax.put(EnumApiCurrency.cancelWechat, { beforeres: beforeres })
      return res
    } catch (e) {
      if (e === false) {
        return e
      }
      console.log(e)
    }
  }
}
export default ControllerWechat
