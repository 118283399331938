export const enum VideoLog {
  /**
   * 直播用户点击返回旧版
   * @platform PC、H5
   */
  live_user_click_back_to_gensee = 'live.back-to-gensee.click',
  /**
   * 回放用户点击返回旧版
   * @platform PC、H5
   */
  playback_user_click_back_to_gensee = 'playback.back-to-gensee.click',
  /**
   * 用户观看直播被展示互动踢出
   * @platform PC、H5
   * @property {string} reason
   */
  live_user_kickout_by_gensee = 'live.gensee-api.kickout',
  /**
   * 直播用户被暄桐踢出
   * @platform PC、H5
   * @property {number} type 1(被登出) | 0(保持登录)
   */
  live_user_kickout_by_xt = 'live.xt-api-logout.kickout',
  /**
   * 回放用户被暄桐踢出
   * @platform PC、H5
   * @property {number} type 1(被登出) | 0(保持登录)
   */
  playback_user_kickout_by_xt = 'playback.xt-api-logout.kickout',
  /**
   * 直播展示互动接口初始化完成 onDataReady
   * @platform PC、H5
   */
  live_gensee_data_ready = 'live.gensee-api.ready',
  /**
   * 回放展示互动接口初始化完成 onDataReady
   * @platform PC、H5
   */
  playback_gensee_data_ready = 'playback.gensee-api.ready',
  /**
   * 直播展示互动状态通知 onStatus
   * @platform PC、H5
   */
  live_gensee_status = 'live.gensee-api.status',
  /**
   * 回放展示互动状态通知 onStatus
   * @platform PC、H5
   */
  playback_gensee_status = 'playback.gensee-api.status',
  /**
   * 直播展示互动API错误通知 onAPIError
   * @platform PC、H5
   */
  live_gensee_error = 'live.gensee-api.error',
  /**
   * 回放展示互动API错误通知 onAPIError
   * @platform PC、H5
   */
  playback_gensee_error = 'playback.gensee-api.error',
  /**
   * 直播页面骨架屏loading开始
   * @platform PC、H5
   * @property {number} time
   */
  live_skeleton_start = 'live.skeleton.start',
  /**
   * 回放页面骨架屏loading开始
   * @platform PC、H5
   * @property {number} time
   */
  playback_skeleton_start = 'playback.skeleton.start',
  /**
   * 直播页面骨架屏loading结束
   * @platform PC、H5
   * @property {number} time
   * @property {number} duration
   */
  live_skeleton_done = 'live.skeleton.done',
  /**
   * 回放页面骨架屏loading结束
   * @platform PC、H5
   * @property {number} time
   * @property {number} duration
   */
  playback_skeleton_done = 'playback.skeleton.done',
  /**
   * 直播页面loading时间太长
   * @platform PC、H5
   */
  live_loading_too_long = 'live.page.loading-too-long',
  /**
   * 直播页面loading时间太长，用户选择继续等待
   * @platform PC、H5
   */
  live_loading_too_long_keep_wait = 'live.loading-too-long.wait',
  /**
   * 直播页面loading时间太长，用户选择刷新页面
   * @platform PC、H5
   */
  live_loading_too_long_reload = 'live.loading-too-long.reload',
  /**
   * 回放页面loading时间太长
   * @platform PC、H5
   */
  playback_loading_too_long = 'playback.page.loading-too-long',
  /**
   * 回放页面loading时间太长，用户选择继续等待
   * @platform PC、H5
   */
  playback_loading_too_long_keep_wait = 'playback.loading-too-long.wait',
  /**
   * 回放页面loading时间太长，用户选择刷新页面
   * @platform PC、H5
   */
  playback_loading_too_long_reload = 'playback.loading-too-long.reload'
}
