

































import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import VeComment from './views/comment.vue'
import VeRecommend from './views/recommend.vue'
@Observer
@Component({
  async fetch(ctx) {
    const store = ctx.store.$storeVideo.Details
    await store.onLoading({ id: ctx.params.id })
  },
  validate({ params }) {
    return /^\d+$/.test(params.id)
  },
  components: { VeComment, VeRecommend }
})
export default class PageView extends Vue {
  get id() {
    return this.$route.params.id
  }
  get VideoStore() {
    return this.$store.$storeVideo
  }
  get PageStore() {
    return this.$store.$storeVideo.Details
  }
  // options = {};
  get options() {
    return {
      video: {
        pic: this.ossProcessImg(this.PageStore.dataSource.videoCoverUrl, 1000, 0, undefined, true),
        url: this.PageStore.dataSource.videoUrl
      }
    }
  }
  onLike() {}
  onPlaying() {
    this.PageStore.onPlayNumber(this.id)
  }
  created() {
    this.$setBreadcrumb({ linksName: '公开课', links: 'video', linksKey: 'video' }, true).setBreadcrumb({
      linksName: this.PageStore.dataSource.title
    })
    // this.onloading();
  }
  // async onloading() {
  //   // let res = await this.PageStore.onLoading({ id: this.id });
  //   lodash.set(this.options, "video", {
  //     pic: res.videoCoverUrl,
  //     url: res.videoUrl,
  //   });
  // }
  mounted() {}

  updated() {}
  destroyed() {}
}
