import { ArmsSumKeys } from '@xt/client'
import browser from '@xt/client/utils/device/browser'

let isSuspend = () => false

let lock = false

function reportBehavior(key: string) {
  if (lock) return

  setTimeout(() => {
    lock = false
  }, 5000)

  lock = true
  if (window.__bl) {
    // alert(`你正试图打开控制台 ${!!window.__bl}`)
    window.__bl.sum(key)
  } else {
    document.addEventListener('armsloaded', () => {
      // alert(`你正试图打开控制台111 ${!!window.__bl}`)
      window.__bl.sum(key)
    })
  }
}

function disableTarget(target: Window) {
  browser.getInfo(window.navigator.userAgent).then(browser => {
    const isMacos = browser.system === 'macOS'
    // 'metaKey'; // mac 的 commond
    // 'altKey'; // mac 的 option
    const KEY = { J: 74, I: 73, U: 85, S: 83, F12: 123 }
    // 禁用 ctrl + shift + i/j
    const isOpenDevToolKey = isMacos
      ? (e: KeyboardEvent, code: number) => e.metaKey && e.altKey && (code === KEY.I || code === KEY.J)
      : (e: KeyboardEvent, code: number) => e.ctrlKey && e.shiftKey && (code === KEY.I || code === KEY.J)

    const isViewSourceCodeKey = isMacos
      ? (e: KeyboardEvent, code: number) => (e.metaKey && e.altKey && code === KEY.U) || (e.metaKey && code === KEY.S)
      : (e: KeyboardEvent, code: number) => e.ctrlKey && (code === KEY.S || code === KEY.U)

    target.addEventListener(
      'keydown',
      (e: KeyboardEvent) => {
        e = (e || target.event) as KeyboardEvent
        const keyCode = e.keyCode || e.which
        if (
          keyCode === KEY.F12 || // 禁用f12
          isOpenDevToolKey(e, keyCode) || // 禁用 ctrl + shift + i
          isViewSourceCodeKey(e, keyCode) // 禁用 ctrl + u 和 ctrl + s 查看和保存源码
        ) {
          // alert(`你正试图打开控制台 ${!!window.__bl}`)
          reportBehavior(ArmsSumKeys.USER_OPEN_DEVTOOL)
          // return preventEvent(target, e)
        }
      },
      true
    )

    disableMenu(target)
  })
}

function disableMenu(target: Window) {
  target.addEventListener('contextmenu', (e: Event & { pointerType: string }) => {
    if (e.pointerType === 'touch') return
    // return preventEvent(target, e)
    reportBehavior(ArmsSumKeys.USER_OPEN_CONTEXTMENU)
  })
}

function preventEvent(target: Window, e: Event) {
  if (isSuspend()) return
  e = e || target.event
  e.returnValue = false
  e.preventDefault()
  return false
}

disableTarget(window)
