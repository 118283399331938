<template>
  <div class="wf-model">
    <div class="wf-content">
      <div class="wf-content-title">文房装备</div>
      <div class="wf-content-vice">“暄桐文房”小程序或淘宝店</div>
      <div
        class="wf-content-zq"
        @click="
          clickItem({
            title: '买单品',
            content: ['<br><br><br>'],
            qrcode: dataSource.wfShopQrcode,
            shop: true
          })
        "
      >
        买单品
      </div>
      <Swiper @clickItem="clickItem" :duration="2000" :dataSource="dataSource.wf"></Swiper>
    </div>
  </div>
</template>
<script>
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import LazyImg from '@/components/lazyImg/index.vue'
import Swiper from './swiper.vue'
@Component({
  components: {
    LazyImg,
    Swiper
  }
})
export default class BMYX extends Vue {
  @Prop() dataSource
  current = 0
  toWfPage() {
    window.open('/stationery')
  }
  @Emit('clickItem')
  clickItem(item) {
    return item
  }
  up() {
    if (this.current < 1) return (this.current = 5)
    return (this.current = this.current - 1)
  }
  next() {
    if (this.current > 4) return (this.current = 0)
    return (this.current = this.current + 1)
  }
}
</script>
<style lang="less" scoped>
.wf-model {
  width: 100vw;
  min-width: 1200px;
  height: 561px;
  background: #ffffff;
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 1;
}
.wf-content {
  width: 1200px;
  margin-top: 104px;
  position: relative;
  z-index: 11;
  &-title {
    font-size: 24px;
    font-weight: 500;
    color: #181c25;
    line-height: 24px;
  }
  &-vice {
    font-size: 14px;
    color: #181c25;
    line-height: 14px;
    margin-top: 23px;
    margin-bottom: 10px;
  }
  &-zq {
    width: 120px;
    height: 36px;
    background: #e0bf95;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #ffffff;
    line-height: 36px;
    position: absolute;
    top: 16px;
    left: 1080px;
    cursor: pointer;
    &:hover {
      background-color: #e0b47e;
    }
  }
}
</style>
