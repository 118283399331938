import { observable, computed } from 'mobx'
import { create } from 'mobx-persist'
import { from, Subject, Subscription } from 'rxjs'
import { AjaxRequest } from 'rxjs/ajax'
import lodash from 'lodash'
import Entities from './entities'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { EnumApiHome } from '../../api'
import { EnumMessage } from '../../languages'
import { Pagination } from '../basics/pagination'
import Works from './works'

export class ControllerHome extends Entities {
  constructor(protected $ajax: AjaxBasics) {
    super()
  }
  /**
   * 同学作业
   */
  Works = new Works(this.$ajax)
  /**
   * 获取banner
   * @memberof ControllerHome
   */
  async onGetBanners() {
    const res = await this.$ajax.get(EnumApiHome.Banner)
    this.setBanners(res)
  }

  WorksRecommend = new Pagination(this.$ajax, {
    url: EnumApiHome.WorksRecommend,
    key: 'id'
  })
  /**
   * 点赞
   * @param data
   */
  async onLikes(data) {
    try {
      if (data.like) {
        throw EnumMessage.like_error
      }
      await this.$ajax.put(EnumApiHome.WorksLike, {
        id: data.id,
        likeType: true
      })
      this.WorksRecommend.onUpdate(data, old => {
        old.likeCount++
        old.like = true
        return old
      })
    } catch (error) {
      throw error
    }
  }
  // 获取首页推荐商品
  async onGetProductsRecommend() {
    const res = await this.$ajax.get(EnumApiHome.ProductsRecommend)
    this.setProductsRecommend(res)
  }

  // 获取首页推荐文章
  async onGetArticleRecommend() {
    const res: Array<any> = await this.$ajax.get(EnumApiHome.ArticleRecommend)
    let list = lodash.sortBy(res, item => {
      return -Number(item.recommendSort)
    })
    this.setArticleFirst(lodash.find(list, ['articleType', 1]))
    this.setArticleRecommend(list)
  }
}
export default ControllerHome
