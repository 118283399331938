/*
 * wangsl
 */
import { BindAll } from 'lodash-decorators'
import { EnumApiShow } from '../../api'
import { AjaxBasics } from '../../helpers/ajaxBasics'

type QrcodeParams = {
  page: string
  query?: string
  /** 1: 展览小程序  2: UC小程序 */
  appletsType?: 1 | 2
  envVersion?: 'release' | 'trial' | 'develop'
  hyaline?: boolean
}

@BindAll()
export class ControllerShow {
  constructor(protected $ajax: AjaxBasics) { }

  async getShowDetailByShowId(showId: number): Promise<any> {
    return await this.$ajax.get(EnumApiShow.showDetail, { showId })
  }

  // 获取二维码
  async onGetQrcode(params: QrcodeParams) {
    return await this.$ajax
      .request({
        url: EnumApiShow.appletsQrcode,
        method: 'get',
        body: params,
        responseType: 'blob',
        headers: {
          'Content-Type': 'image/jpeg'
        }
      } as any)
      .toPromise()
  }

  async getQrcodeUrl(params: QrcodeParams) {
    const res: any = await this.onGetQrcode(params)
    return window.URL.createObjectURL(res.response)
  }
}

export default ControllerShow
