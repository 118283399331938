export default ({ app }) => {
  app.router.beforeEach((to, _from, next) => {
    const userStore = app.store.$storeUser
    /** 注销状态常量 */
    const logoffStatus = 3
    /** 是否注销中 */
    const isLogOffStatus = userStore.UserInfo.status === logoffStatus
    /** 冷静期页面名称 */
    const calmnessName = 'calmness'
    /** 冷静期页面路径 */
    const calmnessPath = '/calmness'
    /** 是否即将前往冷静期页面 */
    const isToCalmnessPage = to.name === calmnessName

    const ignoreRedirectCalmnessPage = ['protocol-service', 'protocol-privacy', 'other-service', 'other-privacy']

    /** 限制正常账户访问冷静期页面 - 返回首页 */
    if (isToCalmnessPage && !isLogOffStatus) return next('/')
    /** 账户状态处于注销冷静中 & 不是前往冷静期页面，则强制跳转 */
    if (isLogOffStatus && !isToCalmnessPage && !ignoreRedirectCalmnessPage.includes(to.name)) return next(calmnessPath)
    /** 首页刷新并携带action或login参数时  清空参数 */
    const carryArgsKey = ['login']
    if (!_from.name && carryArgsKey.map(v => JSON.stringify(to.query).includes(v)).includes(true)) return next('/')

    /** 未触发任何路由规则，正常跳转 */
    next()
  })
}
