












































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import { MixinsCourseHelpers } from '@xt/client/mixins'
import lodash from 'lodash'
import inspect from './inspect.vue'
@Component({
  components: { inspect }
})
export default class extends Mixins(MixinsCourseHelpers) {
  /**
   * 检查用户 不通过 唤起 登录窗口
   */
  @Prop({ default: false }) inspect: any
  updated() {}
  destroyed() {}
}
