import { AjaxBasics } from '@xt/client/helpers'

export class StorageWithExpiredTime {
  storage: Storage

  constructor(storage: Storage) {
    this.storage = storage
  }

  /**
   *
   * @param key
   * @param value
   * @param expired 过期时间ms
   */
  set(key: string, value: any, expired: number) {
    if (expired < 0) throw new Error(`expired time must gt 0, got ${expired}`)

    const serverTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    const expiredTime = serverTime + expired
    this.storage.setItem(
      key,
      JSON.stringify({
        value,
        expired: expiredTime
      })
    )
  }

  get<T = any>(key: string): null | T {
    const now = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    const valueRaw = this.storage.getItem(key)

    if (!valueRaw) {
      return null
    }

    const { expired: _expired, value } = JSON.parse(valueRaw)

    if (now >= _expired) {
      this.delete(key)
      return null
    }

    return value
  }

  delete(key: string) {
    this.storage.removeItem(key)
  }
}
