import { Observer } from 'mobx-vue'
import { Component } from 'vue-property-decorator'
import CommonOrderBtnsScript from '@xt/client/mixins/order/btns'
import OrderPayChooser from '../../../../../../components/orderpay/chooser/chooserScript'
import { EnumInvoiceState } from '@xt/client/enums'

type GiveawayCheckResp = {
  orderNo: string
  receiveId: string
  receiveNickname: string
  receiveTime: number
  /** 领取状态(0.未领取，1.已领取) */
  status: 0 | 1
}

@Observer
@Component
export default class OrderBtnsScript extends CommonOrderBtnsScript {
  btnState: boolean = false
  // @ts-ignore
  chooseContentStyle: string = `width: ${this.$px2rem(460)};color:#333333;font-size: ${this.$px2rem(17)};font-weight: 500;line-height: ${this.$px2rem(
    26
  )};text-align:center;`

  showInovice: boolean = false

  async refundInvoiceAuth() {
    if (this.btnState) return
    this.btnState = true
    if (this.dataSource.invoiceState === EnumInvoiceState.WaitInvoice) {
      new OrderPayChooser({
        title: ' ',
        content: `<div style='${this.chooseContentStyle}'>申请退课将取消发票申请，是否退课？</div>`,
        viceText: '取消',
        mainText: '确定'
      })
        .main(async () => await this.refundAuth(true))
        .vice(() => (this.btnState = false))
        .close(() => (this.btnState = false))
    } else if (this.dataSource.invoiceState === EnumInvoiceState.HasInvoice) {
      new OrderPayChooser({
        title: ' ',
        content: `<div style='${this.chooseContentStyle}'>退款将作废已开具发票，<br>是否退课？</div>`,
        viceText: '取消',
        mainText: '确定'
      })
        .main(async () => await this.refundAuth(true))
        .vice(() => (this.btnState = false))
        .close(() => (this.btnState = false))
    } else {
      await this.refundAuth(false)
    }
  }

  async refundAuth(jumpRefundTip: boolean) {
    try {
      // 6.30去掉
      // const checkGiveaway: GiveawayCheckResp = await this.$store.$storeOrder.checkRefundGiveaway(this.orderNo) as GiveawayCheckResp
      await this.refund(async (res: any, callback: () => Promise<void>) => {
        // 6.30去掉
        // const giveawayToOtherRefund = async () => {
        //   new OrderPayChooser({
        //     title: ' ',
        //     content: `<div style='${this.chooseContentStyle}'>该课程附赠的课程已经转赠给(<span style='color: #c37f7f;'>${checkGiveaway.receiveNickname}</span>)，发起退课后，需Ta在72小时内确认返还赠课，确认后课程将退款。</div>`,
        //     viceText: '再想想',
        //     mainText: '确认退课'
        //   })
        //     .main(async () => await callback())
        //     .vice(() => (this.btnState = false))
        //     .close(() => (this.btnState = false))
        // }
        // const chooseRefundHandler = async () => {
        //   if (checkGiveaway && checkGiveaway.status === 1 && checkGiveaway.receiveId !== this.$store.$storeUser.UserInfo.id) {
        //     await giveawayToOtherRefund()
        //   } else {
        //     callback()
        //   }
        // }
        if (typeof res === 'boolean') {
          if (!jumpRefundTip)
            // 6.30去掉
            // if (checkGiveaway && checkGiveaway.status === 1 && checkGiveaway.receiveId !== this.$store.$storeUser.UserInfo.id) {
            //   await giveawayToOtherRefund()
            // } else {
            //   new OrderPayChooser({
            //     title: ' ',
            //     content: `<div style='${this.chooseContentStyle}'>您确认申请退课吗？</div>`,
            //     viceText: '取消',
            //     mainText: '确定'
            //   })
            //     .main(async () => await callback())
            //     .vice(() => (this.btnState = false))
            //     .close(() => (this.btnState = false))
            // }
            new OrderPayChooser({
              title: ' ',
              content: `<div style='${this.chooseContentStyle}'>您确认申请退课吗？</div>`,
              viceText: '取消',
              mainText: '确定'
            })
              .main(async () => await callback())
              .vice(() => (this.btnState = false))
              .close(() => (this.btnState = false))
          // 6.30去掉
          // else await chooseRefundHandler()
          else await callback()
        } else {
          new OrderPayChooser({
            title: '桐板儿余额不足',
            // @ts-ignore
            content: `<div style='text-align: center'><span style='color:#C37F7F;font-size: ${this.$px2rem(
              17
            )};font-weight: 500'>当前订单需扣回${res.consumerCoins
              }个桐板儿～</span><br><span  style='color:#666666;font-size:${this.$px2rem(12)}'>（不足扣回的${res.refundCompensateCoins
              }个桐板儿是否要用${res.refundCompensateCoins * 8}元抵扣？）</span></div>`,
            viceText: '再想想',
            mainText: '确认抵扣并退课'
          })
            // 6.30去掉
            // .main(async () => await chooseRefundHandler())
            .main(async () => await callback())
            .vice(() => (this.btnState = false))
            .close(() => (this.btnState = false))
        }
      })
    } catch (_) {
    } finally {
      this.btnState = false
    }
  }

  async cancelOrderAuth() {
    if (this.btnState) return
    this.btnState = true
    new OrderPayChooser({
      title: ' ',
      content: `<div style='${this.chooseContentStyle}'>您确认取消订单吗？</div>`,
      viceText: '取消',
      mainText: '确定'
    })
      .main(async () => await this.cancelOrder())
      .vice(() => (this.btnState = false))
      .close(() => (this.btnState = false))
  }

  async cancelRefundAuth() {
    if (this.btnState) return
    this.btnState = true
    new OrderPayChooser({
      title: ' ',
      content: `<div style='${this.chooseContentStyle}'>您确认取消退课吗？</div>`,
      viceText: '取消',
      mainText: '确定'
    })
      .main(async () => await this.cancelRefund())
      .vice(() => (this.btnState = false))
      .close(() => (this.btnState = false))
  }

  async applyInvoice() {
    this.showInovice = true
  }
}
