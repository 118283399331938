//微信跳转
export enum EnumWxLink {
  //暄桐展览
  XTExhibitionLink = 'weixin://dl/business/?t=exxCWgU6Gkl', // update: 22022/7/9
  //日课
  XTDayLink = 'weixin://dl/business/?t=s44tXZljzwq' // update: 22022/7/9
}

/** 用户中心AppID  这个暂时没用  记录一下 */
export enum EnumUCAppId {
  /** 体验版 */
  TRIAL = 'wx22db893614a494ea',
  /** 正式版 */
  RELEASE = 'wx44a132db3c5f5042'
}

/** 暄桐展览AppID */
export enum EnumShowAppId {
  /** 体验版 */
  TRIAL = 'wx31f0d148017bb1e1',
  /** 正式版 */
  RELEASE = 'wxa7a480615c0324ac'
}

/** 暄桐日课AppID */
export enum EnumDayCourseAppId {
  /** 体验版 */
  TRIAL = '',
  /** 正式版 */
  RELEASE = 'wx93b2a004dd8c3990'
}

/** 暄桐文房AppID */
export enum EnumWenFangAppId {
  /** 体验版 */
  TRIAL = '',
  /** 正式版 */
  RELEASE = 'wx64d682e31d37f90f'
}
