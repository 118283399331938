















import { Component, Prop, Vue, Watch, Provide } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
@Observer
@Component({
  name: 'PageVideo',
  // 每次进入页面都会调用
  fetch(ctx) {},
  components: {}
})
export default class PageView extends Vue {
  get PageStore() {
    return this.$store.$storeVideo
  }
  get Pagination() {
    return this.$store.$storeVideo.Pagination
  }
  get body() {
    return {}
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
