







































import { Observer } from 'mobx-vue'
import { Component } from 'vue-property-decorator'
import ProductScript from '@xt/client/mixins/product/product'
import LazyImg from '@/components/lazyImg/index.vue'
import ShowPopup from '../showPopup/showPopup.vue'
import Teleport from '@xt/client/mixins/components/teleport.vue'
import { EnumProductType } from '@xt/client/enums'

@Observer
@Component({
  components: { LazyImg, ShowPopup, Teleport }
})
export default class ProductListComp extends ProductScript {
  toExhibitionDetail() {
    window.open(`/commodity/${this.dataSource.origin.id.toString()}`)
  }

  toLiveCourseDetail() {
    if (this.dataSource.origin.activityPageUrl) return window.open(this.dataSource.origin.activityPageUrl)
    window.open(`/commodity/${this.dataSource.origin.id.toString()}`)
  }

  toDayCourseDetail() {
    window.open(`/commodity/${this.dataSource.origin.id.toString()}`)
  }

  created() {}
}
