


















import { Component, Vue, Prop } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component({
  components: {}
})
export default class extends Vue {
  @Prop() id: string
  @Prop() nickname: string
  @Prop() size: number
  @Prop({ default: false }) def: boolean

  onclick() {
    this.$emit('click')
  }
}
