export function reportEntryLog(entryKey: string, entryValue: any) {
  console.log(`即将上报日志${entryKey}`, JSON.stringify(entryValue))
  if (window.__bl) {
    window.__bl.api(entryKey, true, 0, 200, JSON.stringify(entryValue))
  }
}

export function reportSumLog(key: string, count: number = 1) {
  // console.log(`即将上报sum日志${key}`, count)
  if (window.__bl) {
    window.__bl.sum(key, count)
  }
}
