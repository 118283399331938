
















import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator'
import lodash from 'lodash'
import { Observer } from 'mobx-vue'
@Observer
@Component({
  components: {}
})
export default class PageView extends Vue {
  get Pagination() {
    return this.$store.$storeVideo.Pagination
  }
  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
