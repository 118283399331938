






























































































































import { Component, Vue, Ref, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import { Alert } from 'ant-design-vue'
import 'ant-design-vue/lib/alert/style/index'
import moment from 'moment'
import ExperiencePopup from '../../components/experience/index.vue'
import Teleport from '@xt/client/mixins/components/teleport.vue'
import HeaderImage from '../../components/headerImage/index.vue'
import LazyImg from '@/components/lazyImg/index.vue'
import Logout from './logout/index.vue'
import { EnumLogoutType } from './logout/enum/index'

export interface ICheckUserInfoResp {
  bishanPointCount?: number
  coin?: number
  purchaseProductCount?: number
  uncompleteProductCount?: number
}

@Observer
@Component({
  components: { AAlert: Alert, ExperiencePopup, Teleport, HeaderImage, LazyImg, Logout }
})
export default class PageView extends Vue {
  //展示积分过期提醒
  tips: any = false
  bishanMessage = ''
  allcourse: boolean = false
  tipState = 0 // 0 green 1 red
  random = 0 //随机数
  // svg的画布宽度 100的进度
  maxWidth = 340
  // 最小进度的绘制宽度，如果进度是零简易不渲染组件
  minWidth = 15
  // 当前总进度
  progress = 0
  interval
  myexperience: any
  sum = 0
  lv = 0
  title = '精进值是什么'
  //总精进值
  sumexperience = [0, 200, 450, 800, 1300, 2000, 3000, 4500, 7000, 12500, 22500]
  //升级所需精进值
  upgrade = [0, 200, 250, 350, 500, 700, 1000, 1500, 2500, 5500, 10000]
  //等级名称
  lvname = ['朗空', '精进', '闲趣', '得乐', '明诚', '和靖', '雅安', '广博', '翰墨', '妙微', '通会']
  //下一等级
  defferenceLv = this.lv + 1

  overTimeMessage: string = '积分过期提醒' //积分过期消息提示

  showOverTimeMessage: boolean = true //是否显示过期提醒

  offsetTop: number = 0 // 右边底部贴近左边底部时，之后全部向上偏移的距离

  @Ref('container') container
  get PageStore() {
    return this.$store.$storeUser
  }
  // defaultActiveKey = "course";
  get defaultActiveKey() {
    let nowRouterName = this.$route.name.replace(/-(copy|index)/g, '')
    if (['my-points-detail', 'my-points-exchange', 'my-points-myCoupon'].includes(nowRouterName)) {
      nowRouterName = 'my-points'
    }
    return lodash.get(
      lodash.find(this.tabPane, item => item.path === nowRouterName),
      'key',
      'course'
    )
  }
  get activeKey() {
    return this.$route.name.replace(/-(copy|index)/g, '')
  }
  get getOutDate() {
    if (this.tips['expireTime']) {
      let outTime = moment(this.tips['expireTime']).format('YYYY-MM-DD HH:mm:ss')
    } else {
      return ''
    }
  }
  get activeItem(): any {
    // 设置右侧title+icon
    // let result = lodash.find(this.tabPane, item => item.path === this.activeKey) || {};
    // if(JSON.stringify(result)=='{}'){
    //   result = this.tabPane.filter(item=>{
    //     return lodash.find(item.children, sitem => sitem === this.activeKey);
    //   });
    // }
    // return lodash.isArray(result) ? result[0] : result;

    return lodash.find(this.tabPane, item => item.path === this.activeKey) || {}
  }
  get experienceBackground() {
    switch (this.PageStore.level) {
      case 0:
        return '#E7E7DD'
      case 1:
        return '#EBE0D0'
      case 2:
        return '#EBD9BE'
      case 3:
        return '#E7D4BD'
      case 4:
        return '#E7D2C4'
      case 5:
        return '#E7CEC0'
      case 6:
        return '#E7CCBD'
      case 7:
        return '#E6C8C5'
      case 8:
        return '#E7C7C4'
      case 9:
        return '#E7C4C3'
      case 10:
        return ''
    }
  }
  tabPane = [
    { key: 'course', path: 'my', icon: '', img: 'https://static.xuantong.cn/images/userCenter/pc/pc_mine_attend_class_im.png' },
    { key: 'homework', path: 'my-homework', icon: 'iconpc_mine_task_ic' },
    { key: 'showHomework', path: 'my-showHomework', icon: 'iconpc_mine_publish_job_ic' },
    { key: 'order', path: 'my-order', icon: '', img: 'https://static.xuantong.cn/images/userCenter/pc/pc_mine_order_im.png' },
    { key: 'points', path: 'my-points', icon: 'iconpc_mine_integral_ic' },
    { key: 'information', path: 'my-information', icon: 'iconpc_mine_personal_ic' },
    { key: 'security', path: 'my-security', icon: 'iconpc_mine_securityl_ic' },
    { key: 'allCourse', path: 'my-allcourse', parent: 'course', hide: true },
    { key: 'calendar', path: 'my-calendar', parent: 'course', hide: true },
    { key: 'pointsDetail', path: 'my-points-detail', parent: 'points', hide: true },
    { key: 'pointsExchange', path: 'my-points-exchange', parent: 'points', hide: true },
    { key: 'pointsCoupon', path: 'my-points-myCoupon', parent: 'points', hide: true }
  ]

  experienceBtn() {
    this.$router.push({ name: 'experience' })
  }
  //隐藏tips显示
  hideTips() {
    this.tips.isShow = false
    this.$store.$my.BishanWork.read()
  }
  tabsChange(activeKey) {
    let path = lodash.get(
      lodash.find(this.tabPane, item => item.key == activeKey),
      'path',
      'my'
    )
    this.$router.push({ name: path })
    document.documentElement.scrollTo({ top: 0 })
  }
  closeAlert() {
    this.hideTips()
  }
  copyUserId() {
    const disappear = 'position: fixed;z-index: -2;top: -100px'
    let input = document.createElement('input')
    input.value = this.PageStore.UserInfo.userName
    input.setAttribute('style', disappear)
    document.body.appendChild(input)
    input.focus()
    input.select()
    if (document.execCommand('copy', false, null)) {
      //success info
      this.$msg('复制成功', 'success')
    } else {
      this.$msg('请手动复制ID号')
    }
    document.body.removeChild(input)
  }
  checkUserInfo: ICheckUserInfoResp = {}
  loading: boolean = false
  // 显示注销弹窗
  async showLogout() {
    try {
      this.loading = true
      this.checkUserInfo = (await this.PageStore.getUserCancelAccountCheckInfo()) as ICheckUserInfoResp
      setTimeout(() => {
        this.loading = false
        this.PageStore.onToggleLogoutVisible(true)
        this.PageStore.onToggleLogoutModal((this.checkUserInfo.purchaseProductCount && EnumLogoutType.ACCOUNT) || EnumLogoutType.NOTICE)
      }, 500)
    } catch (error) {
      setTimeout(() => {
        this.loading = false
      }, 500)
      console.log(error)
    }
  }
  // 隐藏注销弹窗
  hideLogout() {
    this.PageStore.onToggleLogoutVisible(false)
  }
  async created() {
    this.allcourse = this.$route.name === 'my-allcourse'
    let tips = await this.$store.$my.BishanWork.onRemind()
    this.tips = tips
    let outeDateTime = moment(this.tips['expireTime']).format('YYYY年MM月DD日')
    this.bishanMessage = `您有${this.tips.bishanNum ? this.tips.bishanNum : 0}个笔山即将在${outeDateTime}过期失效，记得尽快兑换哦～`
  }

  async mounted() {
    ;(document.querySelector('.xt-layout-content') as any).style.backgroundColor = '#F6F6F6'
    let content: any = this.$refs['content']
    let nav: any = this.$refs['nav']
    window.addEventListener('scroll', e => {
      let ch: number = content.offsetHeight
      let nh: number = nav.offsetHeight
      let top: number = document.documentElement.scrollTop
      if (nh + top >= ch) {
        this.offsetTop = nh + top - ch
        // @ts-ignore
        nav.style.top = this.$px2rem(100 - this.offsetTop)
      } else {
        // @ts-ignore
        nav.style.top = this.$px2rem(100)
        this.offsetTop = 0
      }
    })
    //随机数
    this.random = Math.ceil(Math.random() * 100)

    //精进值
    this.sum = this.PageStore.UserInfo.count
    this.lv = this.PageStore.UserInfo.level
    // this.sum = 460

    if (this.lv === 10) return
    this.progress = ((this.sum - this.sumexperience[this.lv]) / this.upgrade[this.lv + 1]) * 100
  }

  @Watch('$route')
  RouteUpdate(to, from, next) {
    this.allcourse = this.$route.name === 'my-allcourse'
  }

  toPageByName(key) {
    for (const item of this.tabPane) {
      if (item.key === key) return this.$router.push({ name: item.path })
    }
  }
  updated() {}
  beforeDestroy() {
    ;(document.querySelector('.xt-layout-content') as any).style.backgroundColor = '#ffffff'
  }
  destroyed() {}
}
