


























import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  name: 'SliderValidComp'
})
export default class SliderValidComp extends Vue {
  @Prop({ default: 305 }) width: number
  @Prop({ default: 208 }) height: number
  @Prop({ default: 55 }) sw: number
  @Prop({ default: 4 }) safeDis: number

  canvas: HTMLCanvasElement
  canvasBlock: HTMLCanvasElement

  arcFormula = Math.PI / 180
  blockWidth = 40 * devicePixelRatio
  circleR = 6 * devicePixelRatio
  needMinorArcDegrees = 90
  offsetY = this.circleR * Math.cos((this.arcFormula * this.needMinorArcDegrees) / 2)
  wholeWidth = this.blockWidth + this.circleR + this.offsetY
  randomX = 0

  blockLeft: number = 0
  sliderLeft: number = 0

  get canvasStyle() {
    return {
      width: this.width + 'px',
      height: this.height + 'px'
    }
  }

  get canvasBlockStyle() {
    return {
      width: this.width + 'px',
      height: this.height + 'px',
      left: this.blockLeft + 'px'
    }
  }

  get sliderStyle() {
    return {
      width: this.sw + 'px',
      left: this.sliderLeft + 'px'
    }
  }

  get trackStyle() {
    return {
      width: this.sw + 4 + this.sliderLeft + 'px'
    }
  }

  async getCanvas() {
    this.loadError = false
    this.loading = true
    this.canvas = document.querySelector('#canvas-bg')
    this.canvasBlock = document.querySelector('#canvas-block')
    this.canvas.width = this.width * devicePixelRatio
    this.canvas.height = this.height * devicePixelRatio
    const ctx = this.canvas.getContext('2d')
    this.canvasBlock.width = this.width * devicePixelRatio
    this.canvasBlock.height = this.height * devicePixelRatio
    const ctxBlock = this.canvasBlock.getContext('2d')

    const x = this.randomNum(this.wholeWidth, this.canvas.width - this.wholeWidth)
    const y = this.randomNum(this.offsetY + this.circleR, this.canvas.height - this.wholeWidth)
    this.randomX = x

    const imageResource = await this.initImage()
    setTimeout(() => {
      ctx.drawImage(imageResource as CanvasImageSource, 0, 0, this.canvas.width, this.canvas.height)
      draw.call(this, ctx, x, y, 'fill')
      draw.call(this, ctxBlock, x, y, 'clip')
      ctxBlock.drawImage(imageResource as CanvasImageSource, 0, 0, this.canvasBlock.width, this.canvasBlock.height)
      const tempY = y - this.circleR - this.offsetY
      const imageData = ctxBlock.getImageData(x, tempY, this.wholeWidth, this.wholeWidth)
      this.canvasBlock.width = this.width * devicePixelRatio
      ctxBlock.putImageData(imageData, 0, tempY)

      this.loading = false
    }, 500)

    function draw(context: CanvasRenderingContext2D, _x: number, _y: number, operation: 'fill' | 'clip') {
      context.beginPath()
      context.moveTo(_x, _y)
      context.arc(
        _x + this.blockWidth / 2,
        _y - this.offsetY,
        this.circleR,
        this.arcFormula * (90 + this.needMinorArcDegrees / 2),
        this.arcFormula * (90 - this.needMinorArcDegrees / 2)
      )
      context.lineTo(_x + this.blockWidth, _y)
      context.arc(
        _x + this.blockWidth + this.offsetY,
        _y + this.blockWidth / 2,
        this.circleR,
        this.arcFormula * (180 + this.needMinorArcDegrees - this.needMinorArcDegrees / 2),
        this.arcFormula * (180 - this.needMinorArcDegrees / 2)
      )
      context.lineTo(_x + this.blockWidth, _y + this.blockWidth)
      context.lineTo(_x, _y + this.blockWidth)
      context.arc(
        _x + this.offsetY,
        _y + this.blockWidth / 2,
        this.circleR,
        this.arcFormula * (180 - this.needMinorArcDegrees / 2),
        this.arcFormula * (180 + this.needMinorArcDegrees - this.needMinorArcDegrees / 2),
        true
      )
      context.lineTo(_x, _y)
      context.lineWidth = 2
      context.fillStyle = 'rgba(255, 255, 255, 0.7)'
      context.strokeStyle = 'rgba(255, 255, 255, 0.7)'
      context.stroke()
      context[operation]()
    }
  }

  initImage() {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.crossOrigin = 'anonymous'
      img.onload = () => {
        resolve(img)
      }
      img.onerror = () => {
        reject('load resource error')
        this.loadError = true
        this.loading = false
      }
      img.src = this.randomImage()
    })
  }

  randomImage() {
    const arr = [
      `${process.env.static}/images/my/verify_im_one.jpg`,
      `${process.env.static}/images/my/verify_im_two.jpg`,
      `${process.env.static}/images/my/verify_im_three.jpg`,
      `${process.env.static}/images/my/verify_im_four.jpg`
    ].map(v => this.ossProcessImg(v, 750))
    return arr[this.randomNum(0, arr.length - 1)]
  }

  randomNum(min, max) {
    return Math.floor(Math.random() * (max + 1 - min) + min)
  }

  loading: boolean = false
  loadError: boolean = false
  originX: number = 0
  isMove: boolean = false
  timestamp: number = 0
  isExistResult: boolean = false

  isTouch: boolean = false

  mouseDownHandler(e: MouseEvent) {
    if (this.loading || this.loadError) return
    this.originX = e.clientX
    this.isMove = true
    this.timestamp = Date.now()
  }

  touchStartHandler(e: TouchEvent) {
    if (this.loading || this.loadError) return
    const touch = e.touches[0]
    this.originX = touch.clientX
    this.isTouch = true
    this.timestamp = Date.now()
  }

  mouseMoveHandler(e: MouseEvent) {
    if (!this.isMove || this.isExistResult || this.loading || this.loadError) return
    const moveX = e.clientX - this.originX
    if (moveX < 0 || moveX + this.sw > this.width - 4) return
    this.sliderLeft = moveX
    this.blockLeft = ((this.width - this.wholeWidth / devicePixelRatio) * moveX) / (this.width - this.sw - 4)
  }

  touchMoveHandler(e: TouchEvent) {
    e.preventDefault()
    if (!this.isTouch || this.isExistResult || this.loading || this.loadError) return
    const touch = e.touches[0]
    const moveX = touch.clientX - this.originX
    if (moveX < 0 || moveX + this.sw > this.width - 4) return
    this.sliderLeft = moveX
    this.blockLeft = ((this.width - this.wholeWidth / devicePixelRatio) * moveX) / (this.width - this.sw - 4)
  }

  mouseUpHandler() {
    if (!this.isMove || this.isExistResult || this.loading || this.loadError) return
    this.isExistResult = true
    const diffTime = Math.floor((Date.now() - this.timestamp) / 1000)
    console.log(Math.abs(this.blockLeft - this.randomX / devicePixelRatio), this.safeDis)
    if (Math.abs(this.blockLeft - this.randomX / devicePixelRatio) < this.safeDis) {
      this.$emit('success')
      console.log('验证成功', diffTime)
    } else {
      console.log('验证失败')
      setTimeout(() => {
        this.reset()
        console.log('重置')
      }, 1000)
    }
  }

  touchEndHandler() {
    if (!this.isTouch || this.isExistResult || this.loading || this.loadError) return
    this.isExistResult = true
    const diffTime = Math.floor((Date.now() - this.timestamp) / 1000)
    console.log(Math.abs(this.blockLeft - this.randomX / devicePixelRatio), this.safeDis)
    if (Math.abs(this.blockLeft - this.randomX / devicePixelRatio) < this.safeDis) {
      this.$emit('success')
      console.log('验证成功', diffTime)
    } else {
      console.log('验证失败')
      setTimeout(() => {
        this.reset()
        console.log('重置')
      }, 1000)
    }
  }

  reset() {
    if (this.loading) return
    this.originX = 0
    this.isMove = false
    this.isExistResult = false
    this.sliderLeft = 0
    this.blockLeft = 0
    this.isTouch = false
    this.getCanvas()
  }

  created() {
    this.$nextTick(async () => {
      await this.getCanvas()
    })
    document.addEventListener('mousemove', this.mouseMoveHandler)
    document.addEventListener('mouseup', this.mouseUpHandler)
    document.addEventListener('touchmove', this.touchMoveHandler)
    document.addEventListener('touchend', this.touchEndHandler)
  }

  beforeDestroy() {
    document.removeEventListener('mousemove', this.mouseMoveHandler)
    document.removeEventListener('mouseup', this.mouseUpHandler)
    document.removeEventListener('touchmove', this.touchMoveHandler)
    document.removeEventListener('touchend', this.touchEndHandler)
  }
}
