import lodash, { values } from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'
import moment from 'moment'
import { MixinsAllCalendar } from './allCalendar'

@Component
export class MixinsMyCalendar extends MixinsAllCalendar {
  @Prop() calendarData: any

  get sayingArr() {
    return this.calendarData.saying
  }

  get calendarImg() {
    return this.calendarData.calendarImg
  }

  /** 当前周 */
  get currentWeekDays() {
    let currentTime = this.$ajax.serviceDate().utcOffset(+8).valueOf() || undefined
    let week = moment(currentTime).day() === 0 ? 7 : moment(currentTime).day()
    let dayTime = 24 * 1000 * 60 * 60
    let time = moment(currentTime).unix() * 1000 - week * dayTime
    let currWeek = []
    for (let i = 0; i < 7; i++) {
      time += dayTime
      let item = moment(time)
      const [y, m, d] = item
        .format('YYYY-MM-DD')
        .split('-')
        .map(v => parseInt(v))
      currWeek.push({ w: item.day(), y, m, d })
    }
    return currWeek
  }

  /** 上一周 */
  get prevWeekDays() {
    let dayTime = 24 * 1000 * 60 * 60
    let weekDays = this.currentWeekDays[0]
    let time =
      moment(`${this.singlePadding(weekDays.y)}-${this.singlePadding(weekDays.m)}-${this.singlePadding(weekDays.d)}`).valueOf() -
      8 * dayTime
    let prevWeek = []
    for (let i = 0; i < 7; i++) {
      time += dayTime
      let item = moment(time)
      const [y, m, d] = item
        .format('YYYY-MM-DD')
        .split('-')
        .map(v => parseInt(v))
      prevWeek.push({ w: item.day(), y, m, d })
    }
    return prevWeek
  }

  /** 下一周 */
  get nextWeekDays() {
    let dayTime = 24 * 1000 * 60 * 60
    let weekDays = this.currentWeekDays[this.currentWeekDays.length - 1]
    let time = moment(`${this.singlePadding(weekDays.y)}-${this.singlePadding(weekDays.m)}-${this.singlePadding(weekDays.d)}`).valueOf()
    let nextWeek = []
    for (let i = 0; i < 7; i++) {
      time += dayTime
      let item = moment(time)
      const [y, m, d] = item
        .format('YYYY-MM-DD')
        .split('-')
        .map(v => parseInt(v))
      nextWeek.push({ w: item.day(), y, m, d })
    }
    return nextWeek
  }

  singlePadding(num) {
    return num <= 9 ? '0' + num : num
  }

  changeDayGetLive() {
    if (!this.activeDay || !this.activeMonth || !this.activeYear) {
      let currentTime = this.$ajax.serviceDate().valueOf()
      // 默认展示今天的内容
      const [year, month, day] = moment(currentTime).format('YYYY-MM-DD').split('-')
      this.live = lodash.filter(this.dataSource, item => {
        const [y, m, d] = moment(item.startTime).format('YYYY-MM-DD').split('-')
        if (item.sort === 0 || item.sort === 9999) {
          return year === y && month === m && day === d && item.publishStatus === 1
        }
        return year === y && month === m && day === d
      })
    } else {
      this.live = [] // 先置空
      this.live = lodash.filter(this.dataSource, item => {
        const [y, m, d] = moment(item.startTime)
          .format('YYYY-MM-DD')
          .split('-')
          .map(v => parseInt(v))
        if (item.sort === 0 || item.sort === 9999) {
          return this.activeYear === y && this.activeMonth === m && this.activeDay === d && item.publishStatus === 1
        }
        return this.activeYear === y && this.activeMonth === m && this.activeDay === d
      })
    }
    if (!this.live?.length) {
      let words = JSON.parse(localStorage.getItem('saying'))
      // 如果本地存在语录 & 数据是今天的，语录不会改变
      if (words && words.y === this.currentYear && words.m === this.currentMonth && words.d === this.currentDay) this.saying = words.saying
      else {
        this.saying = this.sayingArr[Math.floor(Math.random() * this.sayingArr.length)]
        let says = {}
        says['y'] = this.currentYear
        says['m'] = this.currentMonth
        says['d'] = this.currentDay
        says['saying'] = this.saying
        localStorage.setItem('saying', JSON.stringify(says))
      }
    }
  }

  async onLoad() {
    let date = this.prevWeekDays[0]
    this.dataSource = await this.$store.$storeCourse.Details.ClasshourLive.onCalendarInfo(
      moment(`${this.singlePadding(date.y)}-${this.singlePadding(date.m)}-${this.singlePadding(date.d)}`).valueOf(),
      21
    )
    this.changeDayGetLive()
  }
}
