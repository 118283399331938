/**
 *  User api 枚举
 */
export enum EnumApiUser {
  /** 手机密码登录 */
  Login = '/uc/oauth/token',
  /**
   * 用户笔山数量
   */
  Bishans = '/bsp/api/bishanpoints/count',
  /** 查询自己的信息 */
  Userinfo = '/uc/api/members/myself',
  /** 查询账号封禁弹窗内容 */
  AccounBanInfo = '/uc/api/member/ban',
  /** 修改用户信息 */
  UpdateUserInfo = '/uc/api/members/{id}',
  /** 查询用户基本信息 */
  baseinfo = '/uc/api/members/baseinfo',
  /** 绑定邮箱 */
  BindEmail = '/uc/api/members/bindEmail',
  /** 绑定手机 */
  BindPhone = '/uc/api/members/bindPhone',
  /** 绑定微信 */
  Bindwechat = '/uc/api/members/bindwechat',
  /** 修改邮箱 */
  ChangeEmail = '/uc/api/members/changeEmail',
  /** 修改手机 */
  ChangePhone = '/uc/api/members/changePhone',
  /** 手机号找回密码 */
  FindByPhone = '/uc/api/members/findByPhone',
  /** 邮箱找回密码 */
  FindByEmail = '/uc/api/members/findByEmail',
  /** 修改密码 */
  UpdatePwd = '/uc/api/members/updatePwd',
  /** 解绑微信 */
  Unbindwechat = '/uc/api/members/unbindwechat',
  /** 获取验证码 */
  Verifycodes = '/uc/api/verifycodes',
  /** 获取验证码 不需要登录 */
  VerifycodesWhitelist = '/uc/api/verifycodes/whitelist',
  /** 校验验证码 */
  VerifycodesCheck = '/uc/api/verifycodes/check',
  /** 找回密码-邮箱验证码 */
  FindPwdByEmail = '/uc/api/verifycodes/findPwdByEmail',
  /** 找回密码-手机验证码 */
  FindPwdByPhone = '/uc/api/verifycodes/findPwdByPhone',
  /** 验证码登录-邮箱验证码 */
  LoginByEmail = '/uc/api/verifycodes/loginByEmail',
  /** 验证码登录-手机验证码 */
  LoginByPhone = '/uc/api/verifycodes/loginByPhone',
  /** 获取登录二维码 */
  LoginQrcode = '/uc/api/wxapplets/qrcode',
  /** 轮训查询二维码状态 */
  QrcodeStatePolling = '/uc/api/wxapplets/qrcode',
  /** 注销账号/取消注销 */
  // cancelAccount = "/uc/api/members/cancel/member?cancel={cancel}",
  /** 获取用户注销前需要校验的一些信息 */
  // userCancelCheck = "/uc/api/members/checkBeforeMemberCancel",
  /** 检查手机是否存在 */
  // CheckPhone = "/uc/api/members/checkphone/{phoneNum}",
  /** 检查邮箱是否存在 */
  // CheckEmail = "/uc/api/members/checkemail/{email}",
  Code4Judge = '/uc/api/wxapplets/code4Judge',
  /**校验验证码-微信登录时绑定另一个手机号或邮箱前置校验使用 */
  Check4Bind = '/uc/api/verifycodes/check4Bind',
  /** 获取绑定微信小程序二维码 */
  AppletQrcode = '/uc/api/wxapplets/applets/qrcode',
  /** 轮训查询绑定微信状态 */
  BindwxStatus = '/uc/api/wxapplets/bindwx/status',
  /** 统计数据 */
  UserStatistics = '/uc/api/members/{id}/statistics',
  /** 收货地址 */
  UserAddress = '/uc/api/address',
  /** 静默绑定设备 */
  SilenceBind = '/uc/api/trusted/device/silence/bind',
  /** 绑定设备 */
  DeviceBind = '/uc/api/trusted/device/bind',
  /** 登录成功后记录用户设备信息 */
  SaveDeviceInfo = '/uc/api/device/change',
  /** 更换设备 */
  DeviceChange = '/uc/api/trusted/device/change',
  /** 设备列表 */
  DeviceList = '/uc/api/trusted/device/list?appId={appId}&memberId={memberId}',
  /** 获取验证码 */
  WhiteList = '/uc/api/verifycodes/whitelist',
  /** 用户登出 */
  Logout = '/uc/api/members/logout',
  /** 快捷设备绑定 */
  ShortcutDeviceBind = '/uc/api/shortcut/device/bind',
  /** 添加设备日志 */
  AddDeviceLog = '/uc/api/device/log/add',
  /** 授权绑定了设备信息 */
  AppletBind = '/uc/api/applet/device/bind',
  /** 校验用户是否存在 */
  AccountExist = '/uc/api/members/exist',
  /** 校验当前微信和用户微信是否一致 */
  BindCheck = '/uc/api/wxapplets/unionId/check',
  // 轮询调用 连续出错重定向到首页
  VideoHeart = '/uc/api/live/heart?tabId={tabId}&courseId={courseId}',
  // 初始化调用 不关心返回值
  VideoInit = '/uc/api/live/heart/init?tabId={tabId}&courseId={courseId}'
}
