






































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { EnumLogoutType } from '../../enum/index'

@Observer
@Component
export default class NoticeView extends Vue {
  agrSelected: boolean = false

  get PageStore() {
    return this.$store.$storeUser
  }

  agrChanged() {
    this.agrSelected = !this.agrSelected
  }

  next() {
    if (!this.agrSelected) return
    this.PageStore.onToggleLogoutModal(EnumLogoutType.AUTH)
  }

  goOffAgreement(e: Event) {
    e.stopPropagation()
    window.open('/protocol/logout?type=logout')
  }
}
