



















































import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { BindDeviceMeta } from '../../../client/entities'
import HeaderImage from '../headerImage/index.vue'
import { Bind, Debounce } from 'lodash-decorators'

@Observer
@Component({
  components: {
    HeaderImage
  }
})
export default class DeviceModalComp extends Vue {
  @Prop() meta: BindDeviceMeta

  get PageStore() {
    return this.$store.$device
  }

  get UserStore() {
    return this.$store.$storeUser
  }

  get deviceInfo() {
    return this.PageStore.deviceInfo
  }

  get bindDeviceMeta() {
    return this.PageStore.bindDeviceMeta
  }

  handleClose() {
    this.$store.$device.setShowDeviceControlModal(false)
  }

  @Debounce(5000, { leading: true, trailing: false })
  @Bind
  async tempLoginHandler() {
    const isSuccess = await this.PageStore.tempLogin()
    await this.UserStore.onSaveDeviceInfo()
    this.handleClose()
    this.UserStore.onToggleVisible(false)

    if (isSuccess) {
      this.$msg('登录成功，欢迎你桐学 🎉')
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }
  }

  @Debounce(5000, { leading: true, trailing: false })
  @Bind
  async trustLoginHandler() {
    const isSuccess = await this.PageStore.trustLogin()
    this.handleClose()
    this.UserStore.onToggleVisible(false)

    if (isSuccess) {
      await this.UserStore.onSaveDeviceInfo()

      this.$msg('登录成功，欢迎你桐学 🎉')
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    } else {
      this.$msg('登录失效，请重新登录', 'error')
    }
  }

  created() {}
  mounted() {}
  beforeDestroy() {}
}
