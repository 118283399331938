

















import { Component, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import HeaderImage from '../../../../../components/headerImage/index.vue'
import { EnumLogoutType } from '../../enum/index'
import { ICheckUserInfoResp } from '../../../index.vue'

@Observer
@Component({
  components: {
    HeaderImage
  }
})
export default class PageView extends Vue {
  @Prop() checkUserInfo: ICheckUserInfoResp

  get PageStore() {
    return this.$store.$storeUser
  }

  confirmLogout() {
    this.PageStore.onToggleLogoutModal(EnumLogoutType.NOTICE)
  }
  thinkAgain() {
    this.PageStore.onToggleLogoutVisible(false)
  }
}
