/**
 * @author 冷 (https://github.com/LengYXin)
 * @email lengyingxin8966@gmail.com
 * @create date 2020-10-20 20:25:53
 * @modify date 2020-10-20 20:25:53
 * @desc 课程支付
 */
import { Component, Prop, Vue, Emit, Mixins } from 'vue-property-decorator'
import lodash from 'lodash'
import { MixinsCourseHelpers } from './helpers'

@Component
export class MixinsCourseRreview extends Mixins(MixinsCourseHelpers) {
  @Prop({ default: () => ({}) }) dataSource
  visible = false
  /** 课程id pc params h5 更改为 query  */
  get id(): any {
    return this.$route.params.id
  }
  /** 地址栏 临时 token */
  get token(): string {
    return this.$route.query.token as any
  }
  /** 显示 课程回顾 */
  get show() {
    return this.dataSource.showReview
  }
  get Details() {
    return this.$store.$storeCourse.Details
  }
  get PageStore() {
    return this.$store.$storeCourse.Details.Map
  }
  get User() {
    return this.$store.$storeUser
  }
  /** 分享二维码 */
  setQrcode(token) {
    return `${window.location.origin}/activitys/reviewshare?id=${this.id}&token=${token}`
  }
  isCreateTime(data) {
    return data && data.createTime
  }
  getPopupContainer() {
    return this.$refs.popover
  }
  /**
   * 获取课程回顾
   */
  onGetCourseReview() {
    if (this.token) {
      return this.PageStore.onGetCourseReview(this.id, this.token)
    }
    return this.PageStore.onGetCourseReview(this.id)
  }
  async visibleQrcodeChange(visible) {
    if (visible) {
      this.PageStore.onGetReviewToken(this.id)
    }
  }
  onVisible(visible) {
    try {
      this.CourseHelpers.onCheck(this.Details.dataSource)
      this.visible = visible
      if (this.visible) {
        this.onGetCourseReview()
      }
    } catch (error) {
      this.$msg(error)
    }
  }
}
