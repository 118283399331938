/** * wangsl */
<template>
  <div class="public-course" @click="toDetail">
    <div class="public-course-cover">
      <LazyImg :src="ossProcessImg(dataSource.videoCoverUrl, 658, 371)" />
    </div>
    <div class="public-course-basic">
      <div class="course-basic-title">{{ dataSource.title }}</div>
      <!-- <div class="course-basic-vice">{{dataSource.vice}}</div> -->
      <div class="course-basic-dec">{{ dec }}</div>
      <div class="course-basic-lastline">
        <div class="course-basic-date">{{ moment(dataSource.publishTime).format('YYYY-MM-DD') }}</div>
        <div class="course-basic-look">
          <div class="course-basic-look-icon">
            <i class="iconfont iconamount_of_play_ic1"></i>
          </div>
          <div class="course-basic-look-num course-basic-num">
            {{ dataSource.viewCount }}
          </div>
        </div>
        <div class="course-basic-like">
          <div class="course-basic-like-icon">
            <i class="iconfont icongive_a_like_ic"></i>
          </div>
          <div class="course-basic-like-num course-basic-num">
            {{ dataSource.likeCount }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator'
import LazyImg from '@/components/lazyImg/index.vue'
import moment from 'moment'

@Component({
  components: {
    LazyImg
  }
})
class Index extends Vue {
  // 数据
  @Prop() dataSource

  get dec() {
    if (this.dataSource.summary.length > 98) return this.dataSource.summary.substring(0, 99) + '......'
    return this.dataSource.summary
  }
  get moment() {
    return moment
  }
  toDetail() {
    window.open('/video/' + this.dataSource.id)
  }
}

export default Index
</script>

<style scoped lang="less">
.public-course {
  width: 1200px;
  height: 371px;
  background: #ffffff;
  border-radius: 30px;
  margin-top: 69px;
  display: flex;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    background: #fffdfb;
    box-shadow: 0px 0px 20px 0px rgba(173, 145, 116, 0.2);
    .public-course-cover {
      img {
        transform: scale(1.1);
      }
    }
  }
  &-cover {
    width: 600px;
    height: 371px;
    user-select: none;
    -webkit-user-drag: none;
    overflow: hidden;
    position: relative;
    img {
      transition: transform 0.5s;
      width: 658px;
      height: 371px;
      position: absolute;
      margin: auto;
      top: 0;
      left: -29px;
      bottom: 0;
    }
  }
  &-basic {
    padding-left: 50px;
    width: 600px;
    height: 371px;
    display: inline-flex;
    flex-direction: column;
    padding-right: 50px;
  }
  .course-basic {
    display: flex;
    flex-direction: column;
    &-num {
      width: 30px;
      color: #696f8c !important;
    }
    &-title {
      font-size: 30px;
      font-weight: 500;
      color: #181c25;
      line-height: 40px;
      margin-top: 60px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-vice {
      margin-top: 20px;
      height: 68px;
      font-size: 18px;
      font-weight: 500;
      color: #44536a;
      line-height: 24px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    &-dec {
      font-size: 14px;
      color: #696f8c;
      line-height: 26px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      margin-top: 30px;
    }
    &-lastline {
      margin-top: 55px;
      display: flex;
      align-items: center;
      flex: 1;
      align-items: flex-end;
      padding-bottom: 60px;
    }
    &-date {
      font-size: 14px;
      color: #8c96a8;
      line-height: 14px;
      flex: 1;
    }
    &-look {
      display: inline-flex;
      align-items: center;
      i {
        color: #dadde3;
        font-size: 18px;
        margin-right: 5px;
      }
      &-text {
        font-size: 14px;
        font-weight: 400;
        color: #696f8c;
      }
    }
    &-like {
      display: inline-flex;
      align-items: center;
      margin-left: 50px;
      i {
        color: #dadde3;
        font-size: 18px;
        margin-right: 5px;
      }
      &-text {
        font-size: 14px;
        font-weight: 400;
        color: #696f8c;
      }
    }
  }
}
</style>
