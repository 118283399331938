








import sharePopupScript from './sharePopupScript'
import { Component } from 'vue-property-decorator'

@Component({
  name: 'sharePopupComp'
})
export default class sharePopupComp extends sharePopupScript {}
