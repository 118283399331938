<template>
  <div class="model" id="all_course_mode_bmyx">
    <div class="course">
      <div class="course-left">
        <div class="course-left-title">
          <div>{{ dataSource.type }}</div>
          <div>{{ dataSource.title }}</div>
        </div>
        <div class="course-left-vice">{{ dataSource.vice }}</div>
        <div class="course-left-labels">
          <div
            :class="`course-left-labels-item ${item.title && 'course-left-labels-item-title'}`"
            :data-title="item.title"
            v-for="item in dataSource.labels"
            :key="item.label"
          >
            {{ item.label }}
            <i v-if="item.title" class="iconfont icondetails_ic" />
          </div>
        </div>
        <div class="course-left-stage-name">学习时间：</div>
        <div class="course-left-stage-content">
          <div class="course-left-stage-content-start">
            <div class="course-left-stage-content-label">开始上课</div>
            <div class="course-left-stage-content-date">{{ moment(dataSource.courseStartTime).format('YYYY-MM-DD') }}</div>
          </div>
          <div class="course-left-stage-content-margin"></div>
          <div class="course-left-stage-content-end">
            <div class="course-left-stage-content-label">课程结束</div>
            <div class="course-left-stage-content-date">{{ moment(dataSource.courseEndTime).format('YYYY-MM-DD') }}</div>
          </div>
        </div>
        <div class="course-left-tips">{{ dataSource.allCourseBottomTips }}</div>
      </div>
      <div class="course-right">
        <div class="course-right-current-price">
          <div :data-original="dataSource.originalPrice > dataSource.price ? `原学费:${dataSource.originalPrice / 100}元` : ''">
            <span v-money="dataSource.price"></span>
          </div>
          <div v-if="dataSource.originalPrice > dataSource.price">
            优惠
            <span v-money="dataSource.originalPrice - dataSource.price"></span>
          </div>
        </div>
        <div class="course-right-btn-box">
          <div :class="`course-right-btn-box-gifts course-right-btn-box-gifts${gifts()}`" @click="clickGifts">赠课给Ta人</div>
          <div
            :data-convert="dataSource.convertSignContent"
            :class="`course-right-btn-box-signup course-right-btn-box-signup${statusAndStyle().color} ${
              statusAndStyle().text === '不满足转报全阶要求' && 'course-right-btn-box-signup-nc'
            }`"
            @click="sign"
          >
            {{ statusAndStyle().text }}
          </div>
          <div class="course-right-btn-box-countdown" v-if="countdown.date && !this.allCourseHasbuy()">
            <div :class="`course-right-btn-box-countdown-date`" v-html="countdown.date"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import LazyImg from '@/components/lazyImg/index.vue'
import { AjaxBasics } from '@xt/client'
import moment from 'moment'
import lodash from 'lodash'
import Header from './header.vue'
import { Course } from '@xt/client/entities/basics/course'

@Component({
  asyncData(ctx) {},
  components: {
    LazyImg,
    Header
  }
})
export default class BMYX extends Vue {
  @Prop({}) dataSource
  @Prop() productId
  interval
  data() {
    return {
      countdown: {},
      day: false
    }
  }
  get moment() {
    return moment
  }
  @Emit('onShow')
  onShow(type) {
    return {
      type
    }
  }
  @Emit('onHide')
  onHide(type) {
    return {
      type
    }
  }
  async sign() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    // 当前时间大于转报结束时间且课程结束则 已结课
    if (currentTime >= this.dataSource.convertEndTime && currentTime >= this.dataSource.courseEndTime) {
      return
    }
    if (this.allCourseHasbuy()) {
      return this.$router.push({
        name: 'my'
      })
    }
    if (currentTime >= this.dataSource.signupEndTime && currentTime < this.dataSource.convertEndTime) {
      if ((!this.haveEndCourse() && this.hasPer()) || !this.isLogin()) return await this.csign()
    }

    if (currentTime >= this.dataSource.signupStartTime && currentTime < this.dataSource.signupEndTime) {
      await this.csign()
    }
  }
  async csign() {
    try {
      // 检测用户是否登录
      this.$InspectUser()
      this.onCheckDevice()
      let res = await this.$store.$storeOrder.checkBeforeOrder(this.productId, false)
      if (res.oldOrder && res.oldOrderNo) {
        this.$emit('choosePop', res)
        throw new Error('Waiting For Payment')
      }
      this.onShow('Purchase')
    } catch (error) {
      console.warn(error)
    }
  }
  clickGifts() {
    if (this.isC()) {
      // 检测用户是否登录
      this.$InspectUser()
      this.onCheckDevice()
      this.onShow('Give')
    }
  }
  get UserStore() {
    return this.$store.$storeUser
  }
  /**
   * 检测用户是否手机邮箱
   */
  onCheckDevice() {
    if (this.UserStore.needBindDevice) {
      if (this.platform === 'Mobile') {
        this.$router.push({
          name: 'other-bind'
        })
        throw this.$EnumLocaleLinks.links_bind_device
      }
      this.UserStore.onToggleModal(this.$EnumLocaleLinks.links_bind_device)
      throw this.$EnumLocaleLinks.links_bind_device
    }
    //   用户需要完善个人信息
    if (this.UserStore.onneedCompleteInfo()) {
      this.UserStore.onToggleModal(this.$EnumLocaleLinks.links_complete)
      throw this.$EnumLocaleLinks.links_complete
    }
  }
  isC() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    if (currentTime > this.dataSource.signupStartTime && currentTime < this.dataSource.convertEndTime) return true
    return false
  }
  haveEndCourse() {
    let b = false
    this.dataSource.course.map(v => {
      if (lodash.includes(Course.UserHasbuy, v.id)) return
      if (this.currentTime() >= v.signupEndTime) b = true
    })
    return b
  }
  gifts() {
    return this.isC() ? '-yes' : '-no'
  }

  currentTime() {
    return AjaxBasics.serviceDate.utcOffset(+8).valueOf()
  }
  statusAndStyle() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    // 当前时间大于转报结束时间且课程结束则 已结课
    if (currentTime >= this.dataSource.convertEndTime && currentTime >= this.dataSource.courseEndTime) {
      return { text: '已结课', color: '-black' }
    }
    if (this.allCourseHasbuy()) {
      if (currentTime >= this.dataSource.signupEndTime && currentTime < this.dataSource.convertEndTime) {
        if (!this.interval) {
          this.countdown = {
            date: this.computed(this.dataSource.convertEndTime),
            label: '后转报结束'
          }
          this.interval = setInterval(() => {
            this.countdown = {
              date: this.computed(this.dataSource.convertEndTime),
              label: '后转报结束'
            }
          }, 500)
        }
      }
      return { text: '已报名,去上课', color: '-yellow' }
    }
    // 当前时间大于转报结束时间且大于报名结束时间且课程已开启则 开课中
    if (
      currentTime >= this.dataSource.convertEndTime &&
      currentTime >= this.dataSource.signupEndTime &&
      currentTime >= this.dataSource.courseStartTime
    ) {
      return { text: '开课中', color: '-yellow' }
    }
    if (
      currentTime >= this.dataSource.convertEndTime &&
      currentTime >= this.dataSource.signupEndTime &&
      currentTime < this.dataSource.courseStartTime
    ) {
      return { text: '报名结束', color: '-red-no' }
    }
    if (currentTime >= this.dataSource.signupEndTime && currentTime < this.dataSource.convertEndTime) {
      if (!this.interval) {
        this.countdown = {
          date: this.computed(this.dataSource.convertEndTime),
          label: '后转报结束'
        }
        this.interval = setInterval(() => {
          this.countdown = {
            date: this.computed(this.dataSource.convertEndTime),
            label: '后转报结束'
          }
        }, 500)
      }
      if ((!this.haveEndCourse() && this.hasPer()) || !this.isLogin()) return { text: '转报全阶', color: '-red' }
      return { text: '不满足转报全阶要求', color: '-red-14' }
    }

    if (currentTime >= this.dataSource.signupStartTime && currentTime < this.dataSource.signupEndTime) {
      if (!this.interval) {
        this.countdown = {
          date: this.computed(this.dataSource.signupEndTime)
        }
        this.interval = setInterval(() => {
          this.countdown = {
            date: this.computed(this.dataSource.signupEndTime)
          }
        }, 500)
      }
      if (!this.haveEndCourse() && this.hasPer()) return { text: '转报全阶', color: '-red' }
      return { text: '我要报名', color: '-red' }
    }
    let detaStr = moment(this.dataSource.signupStartTime).format('YYYY-MM-DD').split('-')
    this.countdown = {
      date:
        '时间' +
        `
      <div style='${this.dateStyle}'>${detaStr[0]}</div>年<div style='${this.dateStyle}'>${detaStr[1]}</div>月<div style='${this.dateStyle}'>${detaStr[2]}</div>日
      `
    }
    return { text: '即将报名', color: '-red-no' }
  }

  isLogin() {
    return this.$store.$storeUser.loggedIn
  }
  hasPer() {
    return !!this.dataSource.course.filter(v => lodash.includes(Course.UserHasbuy, v.id)).length
  }
  allCourseHasbuy() {
    return lodash.includes(Course.UserHasbuy, this.dataSource.id)
  }
  async created() {
    if (this.isLogin()) {
      this.$store.$storeUser.onGetCourseListHasbuy()
    }
  }
  computeDay(currentTime, endtime) {
    return Math.floor(this.moment(endtime).diff(currentTime, 'hour') / 24)
  }
  computed(endtime) {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    let diff = endtime - currentTime
    if (diff <= 0) {
      clearInterval(this.interval)
      this.interval = null
      if (currentTime < this.dataSource.courseConvertEndTime) {
        return this.computedCountDown()
      } else {
        return false
      }
    }
    if (diff > 24 * 60 * 60 * 1000) {
      this.day = true
      return `剩余<div  style='${this.dateStyle}'>` + this.computeDay(currentTime, endtime) + '</div>天'
    } else {
      this.day = false
      let timeStr = moment(diff).utcOffset(0).format(`HH-mm-ss`).split('-')
      return `剩余
      <div style='${this.timeStyle}'>${timeStr[0]}</div>时<div style='${this.timeStyle}'>${timeStr[1]}</div>分<div style='${this.timeStyle}'>${timeStr[2]}</div>秒
      `
    }
  }
  mounted() {}
  destroyed() {
    clearInterval(this.interval)
  }

  get dateStyle() {
    return `
    display: inline-block;
    padding: 0 ${this.$px2rem(6)};
    margin: 0 ${this.$px2rem(6)};
    font-size: ${this.$px2rem(20)};
    font-weight: 500;
    color: #C37F7F;
    background: rgba(195,127,127,.1);
    height: ${this.$px2rem(28)};
    line-height: ${this.$px2rem(28)};`
  }

  get timeStyle() {
    return this.dateStyle + 'width: ' + this.$px2rem(36)
  }
}
</script>
<style lang="less" scoped>
.model {
  width: 100vw;
  min-width: 1200px;
  display: flex;
  justify-content: center;
  transform: translateY(-208px);
  position: relative;
  z-index: 9;
  .course {
    width: 1200px;
    height: 416px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    padding: 50px 60px;
    display: inline-flex;
    &-left {
      width: 619px;
      height: 100%;
      position: relative;
      &::before {
        content: '';
        width: 1px;
        height: 114px;
        background: #e8e8e8;
        position: absolute;
        right: 0;
        bottom: 0;
      }
      &-title {
        display: flex;
        height: 30px;
        align-items: center;
        & > div {
          &:nth-child(1) {
            width: 56px;
            height: 30px;
            background: #ad9174;
            border-radius: 2px;
            text-align: center;
            line-height: 30px;
            font-size: 20px;
            font-weight: 400;
            color: #ffffff;
          }
          &:nth-child(2) {
            flex: 1;
            padding-left: 10px;
            line-height: 30px;
            font-size: 30px;
            font-weight: 500;
            color: #181c25;
          }
        }
      }
      &-vice {
        font-size: 18px;
        color: #181c25;
        line-height: 18px;
        margin-top: 30px;
        margin-bottom: 20px;
      }
      &-labels {
        min-height: 70px;

        &-item {
          box-sizing: border-box;
          padding: 8px 20px;
          background: fade(#ad9174, 10%);
          font-size: 12px;
          font-weight: 400;
          color: #ad9174;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin-right: 20px;
          margin-bottom: 18px;
          float: left;
          i {
            font-size: 12px;
            margin-left: 5px;
          }
          &-title {
            &:hover {
              position: relative;
              &::after {
                display: flex;
                content: attr(data-title);
                padding: 20px 20px;
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                background: white;
                box-shadow: 0 0 8px 0 rgb(173 145 116 / 15%);
                top: -14px;
                transform: translateY(-100%);
                white-space: pre-line;
                z-index: 9;
                border: 1px solid #ededed;
                border-radius: 5px;
                min-width: 300px;
                color: #181c25;
                font-size: 12px;
                line-height: 24px;
                box-shadow: 0px 0px 8px 0px rgba(173, 145, 116, 0.15);
              }
              &::before {
                display: block;
                width: 8px;
                height: 8px;
                content: '';
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                top: -18px;
                transform: rotate(45deg);
                background: white;
                box-shadow: 0 0 0.5rem 0 rgb(173 145 116 / 15%);
                z-index: 10;
                border-bottom: 1px solid #ededed;
                border-right: 1px solid #ededed;
              }
            }
          }
        }
      }
      &-stage {
        &-name {
          font-size: 14px;
          color: #888888;
          line-height: 14px;
        }
        &-content {
          margin-top: 20px;
          width: 560px;
          display: flex;
          &-margin {
            width: 23px;
          }
          &-start {
            flex: 1;
            width: 268px;
            height: 82px;
            background: #f2ede5;
            overflow: hidden;
            position: relative;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            &::after {
              content: '';
              width: 82px;
              height: 82px;
              border-radius: 50%;
              background: white;
              position: absolute;
              margin: auto;
              top: 0;
              bottom: 0;
              right: -70px;
            }
            .course-left-stage-content-label {
              width: 68px;
              height: 18px;
              background: #e0bf95;
              border-radius: 8px 0px 8px 0px;
              position: absolute;
              top: 0;
              left: 0;
              font-size: 12px;
              font-weight: 500;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #ffffff;
              line-height: 12px;
            }
          }
          &-end {
            border-radius: 8px;
            flex: 1;
            width: 268px;
            height: 82px;
            background: #f2ede5;
            overflow: hidden;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            &::after {
              content: '';
              width: 82px;
              height: 82px;
              border-radius: 50%;
              background: white;
              position: absolute;
              margin: auto;
              top: 0;
              bottom: 0;
              left: -70px;
            }

            .course-left-stage-content-label {
              width: 68px;
              height: 18px;
              background: #bbbbbb;
              border-radius: 0px 8px 0px 8px;
              position: absolute;
              top: 0;
              right: 0;
              font-size: 12px;
              font-weight: 500;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #ffffff;
              line-height: 12px;
            }
          }
          &-date {
            font-size: 24px;
            font-weight: 500;
            color: #000000;
            text-align: center;
          }
        }
      }
      &-tips {
        font-size: 12px;
        color: #181c25;
        line-height: 12px;
        margin-top: 20px;
      }
    }
    &-right {
      flex: 1;
      width: 461px;
      height: 100%;
      position: relative;
      &-current-price {
        margin-top: 33px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        & > div {
          &:nth-child(1) {
            font-size: 36px;
            font-weight: 500;
            color: #181c25;
            line-height: 36px;
            position: relative;
            &::after {
              content: attr(data-original);
              position: absolute;
              width: 100%;
              height: 14px;
              display: inline-block;
              font-size: 18px;
              font-weight: 500;
              color: #bbbbbb;
              line-height: 14px;
              top: -10px;
              left: 0;
              z-index: 9;
              transform: translateY(-100%);
              text-decoration: line-through;
              white-space: nowrap;
            }
          }
          &:nth-child(2) {
            height: 28px;
            background: fade(#ba5a5a, 8%);
            color: #c37f7f;
            border-radius: 14px 14px 14px 0px;
            font-size: 12px;
            line-height: 28px;
            text-align: center;
            margin-left: 10px;
            padding: 0 10px;
          }
        }
      }
      &-btn-box {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 114px;
        width: 100%;
        &-gifts {
          width: 150px;
          height: 46px;
          background: fade(#ad9174, 10%);
          border-radius: 2px;
          font-size: 20px;
          font-weight: 400;
          color: #ad9174;
          line-height: 46px;
          text-align: center;
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
          user-select: none;
          &-yes {
            cursor: pointer;
            background: rgba(173, 145, 116, 0.1);
            color: rgba(173, 145, 116, 1);
            &:hover {
              background: rgba(173, 145, 116, 0.2);
            }
          }
          &-no {
            cursor: auto;
            // background: #e8e8e8;
            background: rgba(#d2d2d2, 0.5);
            color: #fff;
          }
        }
        &-signup {
          width: 150px;
          height: 46px;
          background: #d9a8a8;
          border-radius: 2px;
          font-size: 20px;
          font-weight: 500;
          color: #ffffff;
          line-height: 46px;
          text-align: center;
          position: absolute;
          bottom: 0;
          right: 0;
          cursor: pointer;
          user-select: none;
          &-red-no {
            background-color: rgba(217, 168, 168, 1) !important;
          }

          &-red-14 {
            font-size: 14px !important;
            background-color: rgba(217, 168, 168, 1) !important;
          }
          &-red {
            background-color: rgba(217, 168, 168, 1) !important;
            &:hover {
              background-color: #d99292 !important;
            }
          }
          &-yellow {
            background-color: rgba(224, 191, 149, 1) !important;
            &:hover {
              background-color: #e0b47e !important;
            }
          }
          &-black {
            // background-color: #e8e8e8 !important;
            background-color: rgba(#d2d2d2, 0.5) !important;
          }
          &-nc {
            &:hover::after {
              content: '';
              position: absolute;
              margin: auto;
              left: 0;
              right: 0;
              background: #ffffff;
              box-shadow: 0px 0px 8px 0px rgba(173, 145, 116, 0.15);
              width: 8px;
              height: 8px;
              transform: rotate(45deg);
              z-index: 8;
              bottom: -12px;
            }
            &:hover::before {
              content: attr(data-convert);
              position: absolute;
              margin: auto;
              left: 0;
              right: 0;
              width: 302px;
              height: 122px;
              background: #ffffff;
              box-shadow: 0px 0px 8px 0px rgba(173, 145, 116, 0.15);
              padding: 31px 20px;
              padding-bottom: 24px;
              font-size: 12px;
              line-height: 18px;
              color: #666666 !important;
              transform: translateX(-76px);
              bottom: -130px;
              font-weight: normal;
              z-index: 9;
            }
          }
        }
        &-countdown {
          width: auto;
          height: 40px;
          margin-top: 34px;
          margin-left: 47px;
          display: flex;
          &-date {
            height: 40px;
            border-radius: 2px 0px 0px 2px;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 16px;
            line-height: 40px;
            text-align: right;
            padding: 0 15px;
            position: absolute;
            right: 166px;
          }
          &-type {
            height: 40px;
            background: fade(#d9a8a8, 10%);
            border-radius: 0px 2px 2px 0px;
            font-size: 16px;
            color: #c37f7f;
            line-height: 40px;
            text-align: center;
            padding: 0 15px;
          }
        }
      }
    }
  }
}
</style>

<style lang="less"></style>
