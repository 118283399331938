export const dataSource_My = {
  sexOptions: [
    { label: '男', value: 1 },
    { label: '女', value: 2 }
  ],
  industryOptions: [
    { value: 20, label: '教育' },
    { value: 21, label: '文化艺术' },
    { value: 82, label: '互联网' },
    { value: 84, label: '传媒' },
    { value: 85, label: '金融' },
    { value: 86, label: '政府机关' },
    { value: 87, label: '其他' }
  ],
  careerOptions: [
    { value: 22, label: '教师' },
    { value: 23, label: '医生' },
    { value: 90, label: '工程师' },
    { value: 91, label: '程序员' },
    { value: 92, label: '公务员' },
    { value: 93, label: '创业者' },
    { value: 94, label: '管理者' },
    { value: 95, label: '职员' },
    { value: 96, label: '退休' },
    { value: 97, label: '自由职业' },
    { value: 98, label: '家庭主妇' },
    { value: 99, label: '学生' },
    { value: 100, label: '其他' }
  ],

  educationOptions: [
    { value: 24, label: '高中及以下' },
    { value: 25, label: '专科' },
    { value: 26, label: '本科' },
    { value: 88, label: '硕士' },
    { value: 89, label: '博士及以上' }
  ]
}
