



















































































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { MixinsRetrieve } from '@xt/client/mixins'

@Observer
@Component({})
export default class PageView extends Mixins(MixinsRetrieve) {
  passwordEye: boolean = false
  passwordConfirmEye: boolean = false
  goBack() {
    let jump = this.PageStore.ModalState === 'phone' ? this.locale.links_phone_login : this.locale.links_email_login
    this.PageStore.onToggleModal(jump)
  }

  passswordInput(target) {
    target.value = target.value.replaceAll(' ', '')
  }
}
