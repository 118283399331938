import '@xt/client/mixins/components/directives/autoHeight'
import '@xt/client/mixins/components/directives/dateFormat'
import '@xt/client/mixins/components/directives/ellipsis'
import '@xt/client/mixins/components/directives/money'
import '@xt/client/mixins/components/directives/phoneFormat'
import '@xt/client/mixins/components/directives/viewer'
import '@xt/client/mixins/components/directives/emailFormat'
import Vue from 'vue'
import aboutList from './business/about/list.vue'
import courseAgreement from './business/course/agreement.vue'
import classhourStatus from './business/course/classhourStatus.vue'
import classhourTag from './business/course/classhourTag.vue'
import courseComposition from './business/course/composition.vue'
import course from './business/course/course.vue'
import courseDownTime from './business/course/downTime.vue'
import courseSignup from './business/course/signup.vue'
import courseSingleSwiper from './business/course/singleSwiper.vue'

import homeworkList from './business/homework/list.vue'
import homeworkDetail from './business/homework/detail.vue'
import stationeryList from './business/stationery/list.vue'
import inspect from './business/user/inspect.vue'
import signed from './business/user/signed.vue'
import videoLike from './business/video/like.vue'
import videoList from './business/video/list.vue'
import ckeditor from './ckeditor/index.vue'
import action from './comment/action.vue'
import comment from './comment/index.vue'
import face from './face/index.vue'
import hover from './hover/index.vue'
import imgCode from './imgCode/index.vue'
import infiniteloading from './infiniteloading/index.vue'
import nine from './nine/index.vue'
import pagination from './pagination/index.vue'
import qrcode from './qrcode/index.vue'
import refresh from './refresh/index.vue'
import shadow from './shadow/index.vue'
import tabs from './tabs/index.vue'
import verifyForm from './verifyForm/index.vue'
import verifyCode from './verifyForm/verifyCode.vue'
Vue.config.ignoredElements = ['gs:video-vod', 'gs:doc', 'gs:video-live']
// 渲染 ckeditor 富文本
Vue.component('xt-ckeditor', ckeditor)

Vue.component('xt-video-list', videoList)
Vue.component('xt-video-like', videoLike)
Vue.component('xt-stationery-list', stationeryList)
Vue.component('xt-about-list', aboutList)

Vue.component('xt-course-agreement', courseAgreement)
Vue.component('xt-course', course)
Vue.component('xt-course-downTime', courseDownTime)
Vue.component('xt-course-signup', courseSignup)
Vue.component('xt-course-single-swiper', courseSingleSwiper)
Vue.component('xt-course-composition', courseComposition)
Vue.component('xt-homework-list', homeworkList)
Vue.component('xt-homework-detail', homeworkDetail)

// Vue.component("xt-course-agreement", courseAgreement);
// Vue.component("xt-course", course);
// Vue.component("xt-course-signup", courseSignup);
// Vue.component("xt-course-single-swiper", courseSingleSwiper);
Vue.component('xt-classhour-status', classhourStatus)
Vue.component('xt-classhour-tag', classhourTag)

// 视频
Vue.component('xt-dplayer', () => import('./dplayer/index.vue'))
// 富文本
Vue.component('xt-editor', () => import('./editor/index.vue'))
// 去除富文本
Vue.component('xt-new-editor', () => import('./newEditor/index.vue'))
// 图片裁剪
Vue.component('xt-img-cropper', () => import('./imgCropper/index.vue'))

// 验证表单
Vue.component('xt-verify-form', verifyForm)
// 验证码绑定
Vue.component('xt-verify-code', verifyCode)
// 检查用户登录
Vue.component('xt-inspect', inspect)
// 检查用户是否已购买课程
Vue.component('xt-signed', signed)
// 图片验证
Vue.component('xt-img-code', imgCode)
// 二维码
Vue.component('xt-qrcode', qrcode)
// Vue.component('xt-cat', () => import('./cat/index.vue'));
// 分页
Vue.component('xt-pagination', pagination)
// 滚动
Vue.component('xt-infinite-loading', infiniteloading)
// 标签页
Vue.component('xt-tabs', tabs)
// 悬浮
Vue.component('xt-hover', hover)
// 表情
Vue.component('xt-face', face)
// 评论
Vue.component('xt-comment', comment)
// 评论操作
Vue.component('xt-action', action)
// 阴影
Vue.component('xt-shadow', shadow)
// 九宫格
Vue.component('xt-nine', nine)
// 滚动加载
Vue.component('xt-refresh-list', refresh)
// 骨架屏
Vue.component('xt-skeleton', () => import('@xt/client/mixins/components/skeleton/index.vue'))
// 骨架屏item
Vue.component('xt-skeleton-item', () => import('@xt/client/mixins/components/skeleton/item.vue'))
