


























































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import { MixinsCalendarCard } from '@xt/client/mixins'

@Component
export default class CalendarDay extends Mixins(MixinsCalendarCard) {
  @Prop() currentIndex: number
  @Prop() week: number

  changeActive(y, m, d) {
    this.$emit('changeActive', { y, m, d })
    if (this.currentIndex === -1) {
      if (this.week === 1 && this.index === 0) {
        this.$emit('moveWeekCard', 'next')
      }
    } else if (this.currentIndex === 0) {
      if (this.week === 0 && this.index === 6) {
        this.$emit('moveWeekCard', 'prev')
      }
      if (this.week === 2 && this.index === 0) {
        this.$emit('moveWeekCard', 'next')
      }
    } else if (this.currentIndex === 1) {
      if (this.week === 1 && this.index === 6) {
        this.$emit('moveWeekCard', 'prev')
      }
    }
  }
}
