/*
 * @Author: Erlin
 * @CreateTime: 2020-11-04 20:18:48
 * @LastEditors: Erlin
 * @LastEditTime: 2020-11-04 20:20:50
 * @Description: 作业任务
 */

import { EnumHomeworkBizType } from '@xt/client/enums'
import lodash from 'lodash'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'

@Observer
@Component
export class MixinsHomeworkTask extends Vue {
  isSun = false
  replyBtnClickState = true
  placeholder = '单张图片较大时，上传时间会比较长，建议控制在5M以内哦~'
  get PageStore() {
    return this.$store.$storeHomework.HomeworkTask
  }
  get Pagination(): any {
    return this.PageStore
  }
  get HomeworkTaskDetail() {
    return this.PageStore.HomeworkTaskDetail
  }
  /** 作业任务 id */
  get id() {
    return this.$route.query.id
  }
  /** 作业类型 1文字必填 2图片必填 3图片文字必填 */
  get homeworkType() {
    return String(lodash.get(this.HomeworkTaskDetail, ['dataSource', 'homeworkType']))
  }
  /** 输入校验 作业类型为1时图片必填 不为1时文字必填 */
  get rules() {
    return {
      required: lodash.eq(this.homeworkType, '2') || lodash.eq(this.homeworkType, '3'),
      requiredFile: lodash.eq(this.homeworkType, '1') || lodash.eq(this.homeworkType, '3'),
      max: 2000
    }
  }
  getHomeworkList(dataSource) {
    return lodash.map(dataSource, this.getHomework)
  }
  getHomework(item) {
    return {
      id: item.id,
      // 课程类型 1直播课 2展览 3日课
      bizType: item.bizType,
      // 标题
      title: item.title || item.homeworkTaskTitle,
      // 课程id
      courseId: item.courseId,
      // 课程名字
      courseName: item.courseName,
      // 内容
      content: item.content,
      // 时间
      time: item.createTime,
      // 是否晒出
      isSun: item.suned,
      // 是否被助教回复
      isReply: item.reviewed,
      // 回复助教名字
      replyName: item.reviewerNickname,
      // 图片
      imgs: lodash.head(
        lodash.compact([lodash.get(item, 'picList'), lodash.get(item, 'pictureList'), lodash.get(item, 'homeworkPicList'), []])
      ),
      // 是否展示删除作业
      showDeleteHome: item.bizType === 1
    }
  }

  /** 同时分享至晒作业 */
  onChangeSun(e) {
    if (lodash.isBoolean(e)) {
      this.isSun = e
    } else {
      this.isSun = e.target.checked
    }
  }
  submitSpinning = false
  /** 交作业 */
  async onSubmit(event) {
    if (this.submitSpinning) return
    lodash.forEach(event.fileResult, item => {
      lodash.set(item, 'originalUrl', lodash.get(item, 'originUrl'))
    })
    try {
      this.submitSpinning = true
      await this.PageStore.onHomeworkAdd({
        homeworkTaskId: this.id,
        content: this.encodeHTML(event.content),
        contentNum: event.length,
        picList: event.fileResult,
        // sun: this.isSun,
        sun: false,
        bizType: EnumHomeworkBizType.Course
      })
      this.isSun = false
      event.onReset()
      // this.PageStore.onReset()
      this.submitComplate()
    } catch (error) {
      console.log('Clin: PageView -> onSubmit -> error', error)
    }
    this.submitSpinning = false
  }

  /** 回复助教 */
  async onReply({ event, id, toCommentId }) {
    if (this.replyBtnClickState) {
      this.replyBtnClickState = false
      try {
        let res = await this.PageStore.onReply({
          homeworkId: id,
          replyContent: this.encodeHTML(event.content),
          replyContentNum: event.length,
          toCommentId
        })
        event.onReset()
        this.onReplyComplete()
        this.PageStore.HomeworkComment.onPush(res)
        this.replyBtnClickState = true
      } catch (error) {
        this.$msg(error)
        this.replyBtnClickState = true
      }
    }
  }
  /**
   * 晒作业
   */
  async onSunWork(id) {
    try {
      await this.PageStore.onSunWork(id)
      this.$msg(this.$EnumMessage.sundrying_success, 'success')
    } catch (error) {
      this.$msg(error)
    }
  }
  /**
   * 删除作业
   */
  async onDelWork(id) {
    try {
      await this.PageStore.onDelWork(id)
      this.$msg(this.$EnumMessage.delete_success, 'success')
      this.onDelComplete()
    } catch (error) {
      this.$msg(error)
    }
  }
  /** 删除成功后的操作 */
  onDelComplete() { }
  /** 回复成功后的操作 */
  onReplyComplete() { }
  /** 提交作业成功后操作 */
  submitComplate() { }
  created() { }
  updated() { }
}
