import lodash from 'lodash'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
@Component({
  components: {}
})
export class MixinsComponentNewToolbar extends Vue {
  // 按钮文案
  @Prop({ default: '发布' }) buttonText
  @Prop({ default: 0 }) selectionStart: number
  @Prop({ default: '' }) content: string
  // 校验规则
  @Prop({}) rules
  // 最大文件数量
  @Prop({ default: 9 }) maxFile
  // 文件列表
  fileList = []
  // 显示 上传按钮
  get disabled() {
    return this.fileList.length >= this.maxFile
  }
  // 提交按钮
  get disabledSubmit() {
    return lodash.some(this.fileList, ['status', 'uploading'])
  }
  get max() {
    return lodash.get(this.rules, 'max', 2000)
  }
  // 添加表情
  @Emit('change')
  onAddFace(face) {
    const temp = this.content.slice(this.selectionStart)
    return {
      selectionStart: this.selectionStart + face.value.length,
      content: this.content.slice(0, this.selectionStart).concat(face.value).concat(temp)
    }
  }
  onSubmitRules() {
    try {
      // 必填内容
      const required = lodash.get(this.rules, 'required', false)
      //  必填文件
      const requiredFile = lodash.get(this.rules, 'requiredFile', false)
      // 最大数
      const max = this.max
      // 自定义校验 函数
      const validator = lodash.get(this.rules, 'validator', () => true)
      const fileList = lodash.cloneDeep(this.fileList)
      const data = {
        fileList: fileList,
        fileResult: lodash.filter(
          lodash.map(fileList, 'response').map(v => {
            return { ...v, originWidth: v.originalWidth, originHeight: v.originalHeight }
          }),
          lodash.isObject
        ),
        content: this.content,
        length: this.content.length,
        /** 重置内容 */
        onReset: () => {
          this.$emit('change', { selectionStart: 0, content: '' })
          this.fileList = []
        }
      }
      console.log('LENG: extends -> onSubmitRules -> data', data)
      if (required) {
        if (!/^[\s\S]*.*[^\s][\s\S]*$/.test(data.content)) {
          throw '请输入内容'
        }
      }
      if (max != Number.MAX_SAFE_INTEGER) {
        if (data.length > max) {
          throw '超过最大长度'
        }
      }
      if (requiredFile) {
        if (data.fileList.length === 0) {
          throw '请上传图片'
        }
      }
      if (lodash.some(data.fileList, ['status', 'uploading'])) {
        throw '图片还在上传中'
      }
      if (lodash.some(data.fileList, ['status', 'failed']) || lodash.some(data.fileList, ['status', 'error'])) {
        throw '请删除上传失败的图片'
      }

      if (validator(data, this.rules)) {
        this.onSubmit(data)
      }
    } catch (error) {
      this.$msg(error, 'warning')
    }
  }
  @Emit('submit')
  onSubmit(data) {
    return data
  }
  onRemove(file) {
    const fileList = [...this.fileList]
    lodash.remove(fileList, ['uid', file.uid])
    this.fileList = fileList
  }
  onNineChange(fileList) {
    this.fileList = fileList
  }
  updated() { }
  destroyed() { }
}
function b64toBlob(base64, sliceSize?) {
  const arr = base64.split(',')
  const b64Data = arr[1]
  const contentType = arr[0].match(/:(.*?);/)[1]
  sliceSize = sliceSize || 512

  var byteCharacters = atob(b64Data)
  var byteArrays = []

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize)

    var byteNumbers = new Array(slice.length)
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    var byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  // var blob = new Blob(byteArrays, { type: contentType });
  return new File(byteArrays, 'temp', { type: contentType })
  // return blob;
}
