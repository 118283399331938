/*
 * @Author: Erlin
 * @CreateTime: 2020-10-12 19:24:17
 * @LastEditors: Erlin
 * @LastEditTime: 2020-10-14 14:38:31
 * @Description:首页 api 枚举
 */

export enum EnumApiHome {
  /** 首页轮播图 */
  Banner = '/core/api/banners',
  // 首页同学作品推荐
  WorksRecommend = '/core/api/worksshares/recommend',
  // 首页文房推荐
  ProductsRecommend = '/core/api/products/recommend',
  // 首页视频分享推荐
  VideoRecommend = '/core/api/videoshares/recommend',
  // 文章推荐列表
  ArticleRecommend = '/core/api/articles/recommend',
  // 获取同学作业列表
  WorksList = '/core/api/worksshares',
  // 同学作业点赞
  WorksLike = '/core/api/worksshares/{id}/like'
}
