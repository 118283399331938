



















import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class PageView extends Vue {
  get PageStore() {
    return this.$store.$storeCourse.Details
  }
  visible = false
  onVisible(visible) {
    this.visible = visible
  }
  get locale() {
    return {
      link: 'link'
    }
  }
  created() {
    // 是否第一次进入
    this.visible = this.PageStore.dataSource.viewPrepare
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
