/**
 * 进入全屏
 */
export function enterFullScreen(element: HTMLElement) {
  if (element.requestFullscreen) {
    element.requestFullscreen()
    //@ts-ignore
  } else if (element.mozRequestFullScreen) {
    //@ts-ignore
    element.mozRequestFullScreen() /* Firefox */
    //@ts-ignore
  } else if (element.webkitRequestFullscreen) {
    //@ts-ignore
    element.webkitRequestFullscreen() /* Chrome, Safari & Opera */
    //@ts-ignore
  } else if (element.msRequestFullscreen) {
    //@ts-ignore
    element.msRequestFullscreen() /* IE/Edge */
  }
}

/**
 * 退出全屏
 */
export function exitFullScreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen()
    //@ts-ignore
  } else if (document.mozCancelFullScreen) {
    //@ts-ignore
    document.mozCancelFullScreen() /* Firefox */
    //@ts-ignore
  } else if (document.webkitExitFullscreen) {
    //@ts-ignore
    document.webkitExitFullscreen() /* Chrome, Safari and Opera */
    //@ts-ignore
  } else if (document.msExitFullscreen) {
    //@ts-ignore
    document.msExitFullscreen() /* IE/Edge */
  }
}
