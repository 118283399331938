



















import { Component, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { EnumLogoutType, EnumAbandonType } from '../../enum/index'
import { ICheckUserInfoResp } from '../../../index.vue'

@Observer
@Component
export default class PageView extends Vue {
  @Prop() checkUserInfo: ICheckUserInfoResp

  get PageStore() {
    return this.$store.$storeUser
  }

  get EnumAbandonType() {
    return EnumAbandonType
  }

  get abandonCourse() {
    return this.PageStore.abandonChoose === EnumAbandonType.COURSE
  }

  confirmHandler() {
    this.PageStore.onAgreeAbandonChoose(this.PageStore.abandonChoose)
    this.PageStore.onToggleLogoutModal(EnumLogoutType.CONDITION)
  }
}
