























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
// import RenderItem from "./views/item.vue";
import lodash from 'lodash'
import moment from 'moment'

@Observer
@Component({
  scrollToTop: true
  //   components: { RenderItem }
})
export default class PageView extends Vue {
  body = null
  get PageStore() {
    return this.$store.$my
  }
  get Pagination() {
    return this.PageStore.MyBishan
  }
  biShanType(itemType) {
    let types = ['上课', '活动', '消耗', '系统']
    return types[itemType - 1]
  }

  created() {}
  mounted() {}
  beforeDestroy() {
    //防止出现一些数据不显示问题
    this.Pagination.onReset()
  }
}
