











































import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import lodash from 'lodash'
import { Observer } from 'mobx-vue'

@Component({
  components: {}
})
export default class PageView extends Vue {
  @Prop({ default: true }) loading
  @Prop({ default: [] }) dataSource

  // 格式化评论数据
  getComment(item) {
    return {
      content: item.content,
      memberId: item.memberId,
      author: item.memberNickname,
      time: item.createTime,
      bishan: item.bishanCount,
      imgs: item.pictures || []
    }
  }
  @Emit('reply')
  onReply(item) {
    return item
  }
  @Emit('like')
  onLikes(item) {
    return item
  }

  @Emit('delete')
  onDelete(item) {
    return item
  }

  mounted() {}
  updated() {}
  destroyed() {}
}
