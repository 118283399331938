/**
 * @author mengxiande
 * @create date 2022-06-22 16:02:31
 * @modify date 2022-06-22 16:02:31
 * @desc 参照课程详情
 */
import lodash from 'lodash'
import { BindAll } from 'lodash-decorators'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { BasicsOptions, EntitiesBasics } from '../basics/entities'

import Order from '../basics/order'
import { AjaxRequest } from 'rxjs/ajax'
import { EnumApiOrder } from '../../api/order'
/**
 * 订单详情
 */
@BindAll()
export class OrderDetails extends EntitiesBasics<Order> {
  constructor(protected $ajax: AjaxBasics) {
    super($ajax, {})
    this.onReset({
      // 课程详情地址
      url: EnumApiOrder.OrderDetails
    })
  }
  async onLoading(body?: any, AjaxRequest?: AjaxRequest): Promise<any> {
    let res: any = await this.$ajax.get(EnumApiOrder.OrderDetails, body)
    let dataSource = new Order(res)

    return this.onSetDataSource(dataSource)
  }
}
export default OrderDetails
