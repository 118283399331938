/**
 * @author 冷 (https://github.com/LengYXin)
 * @email lengyingxin8966@gmail.com
 * @ Modified by: Mengcx
 * @ Modified time: 2024-11-04 18:28:43
 * @desc 课程支付
 */
import lodash from 'lodash'
import moment from 'moment'
import { Component, Vue } from 'vue-property-decorator'
import { AjaxBasics } from '../../../client/helpers/ajaxBasics'
import { EnumProductType } from '../../enums'

@Component
export class MixinsCoursePayment extends Vue {
  /** 是否享老桐学优惠 */
  isHasDiscount = false

  /**
   * 赠课
   * @readonly
   * @memberof MixinsCoursePayment
   */
  get give() {
    return lodash.eq(this.$route.query.give, 'true')
  }
  /**
   * 拼团
   */
  get group() {
    return lodash.eq(this.$route.query.group, 'true')
  }
  /**
   * 待支付 重新 支付  有问题
   * @readonly
   * @memberof MixinsCoursePayment
   */
  get orderNo() {
    return this.$route.query.orderNo || this.$route.query.no
  }
  /**
   * 课程
   * @readonly
   * @memberof MixinsCoursePayment
   */
  get PageStore() {
    return this.$store.$storeCourse.Details
  }
  /**
   * 支付
   * @readonly
   * @memberof MixinsCoursePayment
   */
  get UserStore() {
    return this.$store.$storeUser
  }
  /**
   * 支付
   * @readonly
   * @memberof MixinsCoursePayment
   */
  get PaymentStore() {
    return this.$store.$storeOrder
  }
  get isFull() {
    return this.PageStore.Orderinfo.original?.productType == EnumProductType.fullstage
    // return this.PageStore.Orderinfo.isFull
  }

  /** 自己拥有桐板儿 */
  get ownCopper() {
    return this.UserStore.copper
  }
  /** 全阶购买优惠 */
  get fullPaySale() {
    return lodash.subtract(this.originalPrice, this.discountPrice)
  }
  /** 老桐学优惠8折 */
  get oldStudentDiscount() {
    if (!this.isHasDiscount) return 0
    // (折扣价（只有全阶课的原价不等于折扣价） - 已支付学费扣减（全阶） - 拉手团优惠) * (1 - 0.8)
    return lodash.multiply(lodash.subtract(this.discountPrice - this.HasSinglePrice, this.groupPreferential), 0.2)
  }
  /** 是否展示老桐学优惠 */
  get showOldStudentDiscount() {
    return this.isHasDiscount && (!this.orderAgain.discountAmount || this.orderAgain.discountAmount === this.oldStudentDiscount)
  }
  /** 展示系统修改价格（因为老桐学需求  优惠和改价都用了discountAmount字段） */
  get showSystemUpdatePrice() {
    return this.orderAgain.discountAmount && this.orderAgain.discountAmount !== this.oldStudentDiscount
  }
  /** 拉手团优惠 */
  get groupPreferential() {
    if (this.$route.query.group == 'true') {
      return lodash.subtract(this.originalPrice, this.groupPrice)
    }
    return 0
  }
  /** 拼团价格 */
  get groupPrice() {
    return this.PageStore.Orderinfo.original.groupPrice
  }
  /** 优化价格 */
  get discountPrice() {
    return lodash.toInteger(this.PageStore.Orderinfo.original.discountPrice)
  }
  get freeOrder() {
    // return true
    return this.originalPrice === 0
  }
  /** 跳转到直播课 */
  toLiveCourseDetail(): void {
    if (this.paymentDataSource.activityPageUrl) this.$router.push({ path: this.paymentDataSource.activityPageUrl })
    else {
      this.$router.push({
        path: `/commodity/${this.paymentDataSource.productId.toString()}`
      })
    }
  }
  /** 跳转到展览详情页 */
  toExhibitionDetail(): void {
    this.$router.push({
      path: `/commodity/${this.paymentDataSource.productId.toString()}`
    })
  }
  /** 跳转到日课 */
  toDayCourseDetail(): void {
    this.$router.push({
      path: `/commodity/${this.paymentDataSource.productId.toString()}`
    })
  }
  /** 跳转 */
  toProductDetail(): void {
    switch (this.paymentDataSource.productType) {
      case EnumProductType.dayCourse:
        return this.toDayCourseDetail()
      case EnumProductType.fullstage:
        return this.toLiveCourseDetail()
      case EnumProductType.single:
        return this.toLiveCourseDetail()
      case EnumProductType.offlineExhibition:
        return this.toExhibitionDetail()
      case EnumProductType.onlineExhibition:
        return this.toExhibitionDetail()
      default:
      // 异常
    }
  }

  /**
   * 待支付 重新支付   有问题
   * @readonly
   * @memberof MixinsCoursePayment
   */
  get isAgain() {
    // return !lodash.isEmpty(this.orderNo) && this.$route.query.memberBeforeOption == 'true'
    return !lodash.isEmpty(this.orderNo)
  }
  /** 禁用 购买按钮 */
  get disabled() {
    if (this.give) {
      if (this.PageStore.Orderinfo.isSignupGift) {
        const isEmail = this.$Regulars.email.test(this.giftUser)
        const isPhone = this.$Regulars.mobilePhone.test(this.giftUser)
        return !(isEmail || isPhone)
      }
      return false
    }
    // 已经购买
    if (this.PageStore.Orderinfo.isHasOwn) {
      return true
    }
    // 课程状态是否可购买
    return !this.PageStore.Orderinfo.isSignupGift
  }
  /** 微信内 */
  get WechatBowser() {
    return this.$store.$global.WechatBowser
  }
  /** 页面已经销毁 */
  get isBeingDestroyed() {
    return lodash.get(this, '_isBeingDestroyed', false)
  }
  /** 待支付订单 */
  orderAgain: any = {
    discountAmount: 0,
    orderAmount: 0,
    payAmount: 0,
    virtualCurrency: 0,
    orderNo: ''
  }
  /** 支付信息 */
  OrderPay = {
    coinsPay: null,
    jsapiResultMap: null,
    orderNo: null,
    payresult: null,
    payAmount: null
  }
  loading = false
  visible = false
  aliVisible = false
  // 轮询校验支付状态 销毁退出
  isPayment = true
  // 礼物用户
  giftUser = ''
  // 礼物用户信息
  giftUserInfo: any = { id: '', nickname: '', headerUrl: '' }
  // 已购买课程价格
  HasSinglePrice = 0
  // 1代表支付宝前置校验  2代表支付宝网页支付
  orderPayType: number = 1
  qrCodeAli: string = ''

  /** 倒计时相关 */
  isShowPayLeaveTime: boolean = false // 是否展示支付剩余时间
  downtimeTimer: NodeJS.Timer // 支付倒计时
  m: string = '29' // 分
  s: string = '59' // 秒
  visibleOvertime: boolean = false // 是否展示超时弹出框
  timer: string = '' // H5展示时间
  /**
   * 课程原价
   */
  get originalPrice() {
    return lodash.toInteger(this.PageStore.Orderinfo.originalPrice)
  }
  get computedHasSinglePrice() {
    this.getHasSinglePrice(this.PageStore.Orderinfo)
    return this.HasSinglePrice
  }
  /**
   * 课程价格
   */
  get price() {
    if (this.$route.query.group == 'true') {
      return lodash.toInteger(this.PageStore.Orderinfo.original.groupPrice)
    }
    return lodash.toInteger(this.PageStore.Orderinfo.discountPrice)
  }
  /**
   * 优惠
   */
  get discount() {
    return lodash.subtract(this.originalPrice, this.price)
  }
  /** 差价  需要多少钱才可购买当前课程 */
  get diffPrice() {
    return (this.price - this.HasSinglePrice - this.oldStudentDiscount) / 100
  }
  /**
   * 最大可使用桐板儿数
   */
  get useCopperMax() {
    let max = this.PageStore.Orderinfo.original.maxCoin
    if (this.$route.query.group === 'true') {
      let res = Math.ceil(this.groupPrice / 100 / 8)
      max = lodash.min([this.PageStore.Orderinfo.original.maxCoin, res])
    }
    return max * 8 > this.diffPrice ? Math.ceil(this.diffPrice / 8) : max
  }
  /**
   * 最大可抵扣多少桐板儿对应的金额
   */
  get useCopperMaxPrice() {
    return Math.min(this.useCopperMax * 8, this.diffPrice)
  }
  /**
   * 最大可使用桐板儿数
   */
  get maxCopper() {
    // 待支付
    if (this.orderAgain.virtualCurrency) {
      return this.copper
    }
    const max = lodash.min([
      lodash.ceil(lodash.divide(this.price - this.HasSinglePrice - this.oldStudentDiscount, this.deduction)), // 课程
      this.UserStore.copper, // 自己桐板儿
      this.PageStore.Orderinfo.original?.maxCoin // 最大可使用
    ])
    // 如果不是待支付订单
    if (!this.isAgain) this.copper = max
    return max < 0 ? 0 : max
  }
  /**
   * 最大可抵扣多少桐板儿对应的金额
   */
  get maxCopperPrice() {
    return this.maxCopper * 8 * 100 > this.price ? this.price / 100 : this.maxCopper * 8
  }
  /**
   * 桐板儿
   */
  copper = 0
  // 可抵扣金额 分 1=0.01
  get deduction() {
    return 8 * 100
  }
  // 抵扣金额 单位 分
  get copperDeduction() {
    return this.copper * 8 > this.diffPrice ? this.diffPrice * 100 : this.deduction * this.copper
  }
  /**
   * 已购买的单节课价格统计
   */
  getHasSinglePrice(Orderinfo: any) {
    let price: any = 0
    if (Orderinfo.isGive) {
      if (Orderinfo.UserHasbuy.length > 0 && Orderinfo.original && Orderinfo.original.singleProductList) {
        Orderinfo.original.singleProductList.forEach(item => {
          Orderinfo.UserHasbuy.forEach(list => {
            if (list == item.id) {
              price += item.discountPrice
            }
          })
        })
      }
    } else {
      if (Orderinfo.original && Orderinfo.original.singleProductList) {
        Orderinfo.original.singleProductList.forEach(item => {
          if (item.hasOwn) {
            price += item.originalPrice
          }
        })
      }
    }
    this.HasSinglePrice = price
    return price
    // return this.HasSinglePrice
    // this.HasSinglePrice = Orderinfo.hasSinglePrice
    // return this.HasSinglePrice
  }

  /**
   * 支付价格
   * @readonly
   * @memberof MixinsCoursePayment
   */
  getPayPrice(Orderinfo: any) {
    if (this.isAgain) {
      return this.orderAgain.payAmount
    }
    // 已购买课程
    const HasSinglePrice = this.getHasSinglePrice(Orderinfo)
    return lodash.max([
      0,
      lodash.subtract(
        this.price - (this.showSystemUpdatePrice ? this.orderAgain.discountAmount : 0),
        lodash.sum([this.copperDeduction, HasSinglePrice, this.showOldStudentDiscount ? this.oldStudentDiscount : 0])
      )
    ])
  }
  get getOrderAgain() {
    return this.orderAgain
  }
  //支付价格，针对延时问题
  get computedPayPrice() {
    if (this.isAgain) {
      return this.getOrderAgain.payAmount
    }
    return lodash.max([
      0,
      lodash.subtract(this.price - this.orderAgain.discountAmount, lodash.sum([this.copperDeduction, this.HasSinglePrice]))
    ])
  }
  // 支付类型（0未支付，1免费，2微信，3支付宝，4线下微信，5线下支付宝，6线下银行转账）
  // paymentType: 2 | 3 = this.WechatBowser ? 2 : 3;
  paymentType: 2 | 3 = 2
  changePayType(type, orderPayType?) {
    if (this.paymentType != type) this.paymentType = type
  }
  /**
   * 平台（0EDU，1PC，2WAP，3BG，4APPLET）
   */
  get platform(): 0 | 1 | 2 | 3 | 4 {
    return lodash.get({ PC: 1, Mobile: 2 }, this.$store.$global.platform) as any
  }
  /**
   * 支付子类型（10 WxPayNative，11 WxPayJSAPI，12 WxPayMWEB，20 AliPayPC，21 AliPayWAP）
   */
  get payType(): 10 | 11 | 12 | 20 | 21 {
    if (this.WechatBowser && this.$store.$global.platform === 'Mobile') {
      return 11
    }
    return lodash.get({ PC_3: 20, Mobile_3: 21, PC_2: 10, Mobile_2: 12 }, `${this.$store.$global.platform}_${this.paymentType}`) as any
  }

  get paymentDataSource() {
    return this.PaymentStore.Details.dataSource
  }
  countdownTime(startTime, endTime) {
    let diff = endTime - startTime
    const [m, s] = diff <= 0 ? ['00', '00'] : moment(diff).utcOffset(0).format('mm:ss').split(':')
    this.timer = diff <= 0 ? '00:00' : moment(diff).utcOffset(0).format('mm:ss')
    this.m = m
    this.s = s
    if (diff <= 0) {
      clearInterval(this.downtimeTimer)
      this.downtimeTimer = null
      this.visible = false
      this.aliVisible = false
      this.visibleOvertime = true
    }
  }
  downTime() {
    if (this.downtimeTimer) {
      clearInterval(this.downtimeTimer)
      this.downtimeTimer = null
    }
    let endTime: number = this.PaymentStore.Details.dataSource.createTime + 30 * 60 * 1000
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    this.countdownTime(currentTime, endTime)
    this.downtimeTimer = setInterval(() => {
      let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
      this.countdownTime(currentTime, endTime)
    }, 1000)
  }

  async onInit() {
    this.isPayment = true
    this.onHasOwnOrderinfo()
    if (this.isAgain) {
      let order = (this.orderAgain = this.paymentDataSource)
      this.giftUser = lodash.head(lodash.compact([order.receiverPhoneNum, order.receiverEmail]))
      if (order.virtualCurrency) {
        this.copper = lodash.divide(order.virtualCurrency, this.deduction)
      }
      if (this.give) {
        await this.onBaseinfo()
      }
    } else this.copper = this.maxCopper
  }
  receiveHasbuy: any = []
  /**
   * 获取用户信息
   */
  async onBaseinfo() {
    try {
      const isEmail = this.$Regulars.email.test(this.giftUser)
      const isPhone = this.$Regulars.mobilePhone.test(this.giftUser)
      if (isEmail || isPhone) {
        this.giftUserInfo = await this.UserStore.onBaseinfo(this.giftUser)
        if (this.giftUserInfo) {
          if (this.$eqUser(this.giftUserInfo.id)) {
            this.giftUser = ''
            this.giftUserInfo = {}
            return this.$msg('赠送人不可以是自己哦~')
          }
          this.receiveHasbuy = await this.PaymentStore.onGetHasbuy(this.PageStore.Orderinfo.id, this.giftUserInfo.id)
          // console.log("LENG ~ MixinsCoursePayment ~ onBaseinfo ~ hasbuy", hasbuy)
          // this.PageStore.onHasOwnOrderinfo(hasbuy,true);
          this.PageStore.onHasOwnOrderinfo(this.receiveHasbuy, true)
        } else {
          this.giftUserInfo = {
            nickname: '赠课后，Ta通过此账号进入教室领课就可以啦～',
            headerUrl: 'http://static.xuantong.cn/images/payment/pc/gift_surprised_im.png'
          }
        }
        // 检测受赠人已经使用过老桐学优惠
        this.isHasDiscount = await this.PaymentStore.onCheckHasDiscount(+this.$route.query.productId, true, this.giftUserInfo.id)
      } else {
        if ([1, 2].includes(this.platform) && this.giftUser !== '') {
          this.$msg('请输入正确的手机号或邮箱')
        }
        throw ''
      }
    } catch (error) {
      console.log('LENG ~ MixinsCoursePayment ~ onBaseinfo ~ error', error)
      this.giftUserInfo = {}
      this.PageStore.onHasOwnOrderinfo([], true)
    }
  }
  /**
   * 计算课程是否购买
   */
  onHasOwnOrderinfo() {
    if (this.give) {
      return this.PageStore.onHasOwnOrderinfo([], true)
    }
    // this.PageStore.onHasOwnOrderinfo(this.UserStore.CourseListHasbuy);
    this.PageStore.onHasOwnOrderinfo(this.UserStore.CourseGroupHasbuy)
  }
  /**
   * 判断是否是转全阶
   * **/
  get isTurnPkg() {
    // //如果用户是赠课
    // if(this.give){
    //     return false;
    // }
    //如果课程是全阶课，并且用户已经购买了单阶课，则转报全阶
    if (this.isFull && this.PageStore.Orderinfo.isSignupTurn && this.PageStore.Orderinfo.isSingleHasOwn) {
      return true
    }
    return false
  }
  /**
   * 切换到支付宝网页版
   */
  toggleAliPage() {
    this.orderPayType = 2
    this.onSubmit()
  }
  orderList = []
  /**
   * 提交订单
   */
  async onSubmit() {
    let paypage
    if (this.paymentType === 3 && this.orderPayType === 2 && this.platform === 1) {
      paypage = window.open('', '_black')
    }
    try {
      // 微信 已有二维码
      // if (this.payType === 10 && this.OrderPay.payresult) {
      //     return this.onVisible(true)
      // }
      let giftEmail = ''
      let giftPhoneNum = ''
      if (this.give) {
        const isEmail = this.$Regulars.email.test(this.giftUser)
        const isPhone = this.$Regulars.mobilePhone.test(this.giftUser)
        if (isEmail) {
          giftEmail = this.giftUser
        } else if (isPhone) {
          giftPhoneNum = this.giftUser
        } else {
          return this.$msg('请输入正确的邮箱或者手机号')
        }
      }
      this.loading = true
      const callbackUrl =
        window.location.origin +
        this.$router.resolve({
          name: this.payType === 12 ? 'payment' : 'payment-success',
          query:
            this.payType === 12 ? lodash.pick(this.$route.query, ['course', 'give', 'group', 'productId']) : { t: Date.now().toString() }
          // query: this.payType === 12 ? lodash.pick(this.$route.query, ['course', 'give']) : { t: "13245467978" }
        }).href

      let orderBody: IOrderPayParam = {
        orderNo: this.orderNo,
        // 订单编号 为 重新支付 未支付订单 以下数据 会忽略
        buyWay: this.give ? 2 : 1,
        giftEmail,
        giftPhoneNum,
        platform: this.platform,
        // 如果是0元商品 paymentType传值为1（免费） 否则走正常的微信 ｜ 支付宝
        paymentType: this.freeOrder ? 1 : this.paymentType,
        payType: this.payType,
        wxOpenId: this.UserStore.wxOpenid,
        score: this.copper,
        callbackUrl,
        //综合订单添加
        productId: Number(this.$route.query.productId),
        group: String(this.$route.query.group) == 'true' ? true : false,
        groupNo: this.$route.query.groupNo ? this.$route.query.groupNo.toString() : '',
        memberBeforeOption: String(this.$route.query.memberBeforeOption) == 'true' ? true : false,
        turnPkg: this.isTurnPkg //是否转全阶
      }
      //如果是支付宝
      if (this.paymentType === 3 && this.orderPayType) {
        orderBody.orderPayType = this.orderPayType
      }
      const res: any = (this.OrderPay = await this.PaymentStore.onOrderPay(orderBody))
      const query = lodash.clone(this.$route.query)
      if (!(query.orderNo === res.orderNo)) {
        query.orderNo = res.orderNo
        query.memberBeforeOption = 'true'
        await this.$router.replace({ query })
      }
      if (!(res.payAmount === 0)) {
        this.orderAgain.payAmount = res.payAmount
      }
      await this.$store.$storeUser.onUpdatingUserInfo()
      switch (true) {
        case res.payAmount === 0:
          await this.onPollingOrder()
          break
        // 支付宝非前置 跳转 连接
        case this.paymentType === 3 && this.orderPayType === 2:
          this.platform === 1 ? (paypage.location.href = res.payresult) : (window.location.href = res.payresult)
          break
        // 微信 跳转连接
        case this.payType === 12:
          this.$router.replace({
            query: lodash.assign({}, this.$route.query, { type: 'wx' })
          })
          window.location.href = res.payresult
          break
        //支付宝二维码
        case this.paymentType === 3 && this.orderPayType === 1:
          this.onAliVisible(true, res)
          await this.onPollingOrder()
          this.onDownTimerVisible(true)
          break
        // 微信 二维码
        case this.payType === 10:
          this.onVisible(true)
          await this.onPollingOrder()
          this.onDownTimerVisible(true)
          break
        // 微信内支付
        case this.payType === 11:
          this.chooseWXPay(res)
          await this.PaymentStore.Details.onLoading({ orderNo: res.orderNo })
          this.onDownTimerVisible(true)
          break
      }
      // 如果是pc端，跳转完成后，将支付宝的支付方式重置为二维码支付
      if (this.platform === 1) {
        this.orderPayType = 1
      }
    } catch (error) {
      console.log('LENG ~ ', error)
    }
    if (this.payType === 12 || (this.paymentType === 3 && this.platform === 2)) {
      setTimeout(() => {
        this.loading = false
      }, 1000)
    } else {
      this.loading = false
    }
  }
  /**
   * 轮询订单 key
   */
  PollingKey = lodash.uniqueId()
  PollingCount = 0
  PollingMax = 90
  get PollingEnd() {
    return lodash.eq(this.PollingCount, this.PollingMax)
  }
  /**
   * 控制倒计时显示和隐藏
   */
  onDownTimerVisible(visible) {
    this.isShowPayLeaveTime = visible
    if (visible) {
      this.downTime()
    } else {
      clearInterval(this.downtimeTimer)
      this.downtimeTimer = null
    }
  }
  /**
   * 轮询订单 成功
   */
  async onPollingOrder(PollingKey = undefined) {
    if (!PollingKey) {
      PollingKey = lodash.uniqueId()
      this.PollingKey = PollingKey
      this.PollingCount = 1
    }
    const orderNo = this.OrderPay.orderNo
    await this.PaymentStore.Details.onLoading({ orderNo })
    // 支付成功
    if (this.PaymentStore.Details.dataSource.paid) {
      this.onSuccess(orderNo)
    } else {
      lodash.delay(() => {
        // 已重新轮询
        if (!lodash.eq(this.PollingKey, PollingKey) || this.PollingEnd) {
          return
        }
        this.PollingCount += 1
        this.onPollingOrder(this.PollingKey)
      }, 2000)
    }
  }
  /**
   * 微信支付
   */
  async chooseWXPay(res) {
    console.log('LENG ~ 微信支付', res)
  }
  /**
   * 支付成功
   * @param orderNo
   */
  onSuccess(orderNo) {
    console.warn('执行了ouSuccess', window.history)
    this.$router.replace({
      name: 'payment-success',
      query: { no: orderNo }
    })
  }
  /**
   * 微信扫码显示
   * @param visible
   */
  onVisible(visible) {
    this.visible = visible
    if (!visible) {
      this.PollingKey = lodash.uniqueId()
    }
  }
  /**
   * 支付宝扫码显示
   * @param visible
   */
  onAliVisible(visible, res?) {
    //显示
    this.aliVisible = visible
    if (visible && res) {
      this.qrCodeAli = res.payresult
    }
    if (!visible) {
      this.PollingKey = lodash.uniqueId()
    }
  }
}
