/**
 * @author 冷 (https://github.com/LengYXin)
 * @email lengyingxin8966@gmail.com
 * @create date 2020-08-05 14:13:00
 * @modify date 2020-08-05 14:13:00
 * @desc [description]
 */
import { toJS } from 'mobx'
import moment from 'moment'
import lodash from 'lodash'
import Vue from 'vue'
import { EnumApiCurrency } from './api'
import { Regulars, Encryption, AjaxBasics } from './helpers'
import { EnumLocaleDescriptions, EnumLocaleLinks, EnumMessage } from './languages'
import { htmlInjectionFilter } from './htmlFilter'

// 扩展
Vue.prototype.$EnumApiCurrency = EnumApiCurrency
Vue.prototype.$EnumLocaleLinks = EnumLocaleLinks
Vue.prototype.$EnumMessage = EnumMessage
Vue.prototype.$EnumLocaleDescriptions = EnumLocaleDescriptions
Vue.prototype.$Regulars = Regulars
Vue.prototype.$Encryption = Encryption
Vue.prototype.moment = moment
Vue.prototype.toJS = toJS
/**
 * 检查用户 状态 已登录返回用户信息
 * @visible 吊起 登录框
 */
Vue.prototype.$InspectUser = function (visible = true) {
  const store: any = this.$store
  if (store.$storeUser.isLogin()) {
    return toJS(store.$storeUser.UserInfo)
  }

  // 如果是内嵌的h5  需要跳转到小程序登录
  if (store.$global.isEmbeddedH5Page) {
    wx.miniProgram.navigateTo({
      url: `/pages/login/index?redirect=${encodeURIComponent(window.location.href)}`
    })
    throw undefined
  }

  store.$storeUser.onToggleVisible(visible)
  throw undefined
}
/**
 * 检查code 是否 是当前用户
 */
Vue.prototype.$eqUser = function (code) {
  const store: any = this.$store
  if (store.$storeUser.loggedIn) {
    const UserInfo = toJS(store.$storeUser.UserInfo)
    return lodash.eq(String(UserInfo.id), String(code))
  }
  return false
}
/**
 * 消息提示
 */
Vue.prototype.$msg = function (msg, type) {
  console.warn('LENG: Vue.prototype.$msg -> msg', msg, type)
}

/**
 * 处理小程序内嵌h5登录的场景
 */
Vue.prototype.$assertLogin = function (callback = () => { }) {
  const store: any = this.$store

  if (store.$storeUser.isLogin()) {
    callback()
    return
  }

  if (store.$global.isEmbeddedH5Page) {
    const embeddedProgrem = store.$global.embeddedProgrem

    if (embeddedProgrem === null) {
      throw new Error('unhandled')
    }

    if (embeddedProgrem === 'UserCenter') {
      wx.miniProgram.navigateTo({
        url: `/pages/login/index?redirect=${encodeURIComponent(window.location.href)}`
      })
    } else if (embeddedProgrem === 'Show') {
      wx.miniProgram.reLaunch({
        url: `/pages/index/index?autoAuthLogin=${encodeURIComponent(location.href)}`
      })
    }
    return
  }

  store.$storeUser.onToggleVisible(true)
}

/**
 * oss img 压缩 https://help.aliyun.com/document_detail/44688.html?spm=a2c4g.11186623.6.741.480c6c6bhsmI9U
 */
Vue.prototype.ossProcessImg = function (img, w = 750, h?, middle?, notInterlace?) {
  if (!img) return ''
  const store: any = this.$store
  const isMobile = lodash.eq(store.$global.platform, 'Mobile')
  // 手机端 默认 750

  let scw = isMobile ? Math.floor((w / 750) * window.innerWidth * window.devicePixelRatio) : w * window.devicePixelRatio

  w = scw
  h = isMobile ? (scw / w) * (h || 0) : (h || 0) * window.devicePixelRatio

  img = lodash.replace(img, '@Static', store.$global.static)
  if (lodash.includes(img, '.svg')) {
    return img
  }
  if (lodash.includes(img, 'xuantong-upload-free.oss')) {
    img = lodash.head(lodash.split(img, '?'))
  } else if (!lodash.some(['free.xuantong.cn', 'file.xuantong', 'static.xuantong.cn'], x => lodash.includes(img, x))) {
    return img
  }

  const isJpeg = img.indexOf('.jpg') !== -1 || img.indexOf('.jpeg') !== -1 || img.indexOf('.JPG') !== -1 || img.indexOf('.JPEG') !== -1
  const inc = lodash.includes(img, '?')
  return `${img}${inc ? '&' : '?'}x-oss-process=image/resize,w_${lodash.toInteger(w)}${h ? `,h_${lodash.toInteger(h)}` : ''}${middle ? ',m_fill,limit_0' : ',m_lfit'
    }/quality,q_90${isJpeg && !notInterlace ? '/interlace,1' : ''}`
}
Vue.prototype.ossProcessImgRules = function (img, rules) {
  const inc = lodash.includes(img, '?')
  let imgSrc = img
  if (inc) {
    imgSrc = img.split('?')[0]
  }
  return imgSrc + '?x-oss-process=' + rules
}

Vue.prototype.ossProcessImgJPG = function (img, w = 750, h?) {
  return this.ossProcessImg(img, w, h) + '/format,jpg/quality,q_90'
}
// Vue.prototype.ossProcessImgRatio = function (img, w, h) {
//   const inc = lodash.includes(img, '?')
//   return `${img}${inc ? '&' : '?'}?x-oss-process=image/resize,m_lfit,w_${w},h_${h}/crop,g_center,w_${w},h_${h}/quality,q_95/format,jpg`
// }
/** 格式化输出 */
Vue.prototype.formatterDate = function (value) {
  if (value) {
    return moment(value).format('YYYY-MM-DD HH:mm:ss')
  }
}

Vue.prototype.formatMomentDate = function (_date) {
  if (!_date) return ''
  const now = AjaxBasics.serviceDate.utcOffset(+8)
  const date = moment(_date)
  const day = now.diff(date, 'day')
  const hour = now.diff(date, 'hour')
  const minute = now.diff(date, 'minute')
  const second = now.diff(date, 'second')

  if (second <= 60) {
    return '刚刚'
  } else if (hour < 1) {
    return `${minute}分钟前`
  } else if (hour >= 1 && hour < 24) {
    return `${hour}小时前`
  } else if (hour >= 24 && day < 10) {
    return `${day}天前`
  } else if (now.isSame(date, 'year')) {
    return date.format('MM-DD')
  } else {
    return date.format('YYYY-MM-DD')
  }
}

Vue.prototype.formatMomentCount = function (count, defaultText) {
  if (!count) return defaultText

  if (count <= 9999) {
    return count
  }

  if (count > 9999) {
    return `${parseInt('' + (count / 10000) * 10, 10) / 10}万`
  }
}

/** 替换 换行 字符 */
Vue.prototype.replaceHtml = function (value) {
  if (lodash.isString(value)) {
    return lodash.replace(value, /[\n]/g, '<br/>')
  }
}
/** 替换 href 字符 */
Vue.prototype.toHref = function (value) {
  if (Regulars.url.test(value)) {
    return value
  }
  return 'javascript:void(0)'
}

Vue.prototype.htmlEncode = function (str: string) {
  let startTime = Date.now()
  let box = document.createElement('div')
  box.innerHTML = str
  str = htmlInjectionFilter(box)
  return str
}

/** 新窗口打开链接地址 */
Vue.prototype.onOpenBlock = function (url, download?: string) {
  // 下载文件
  if (download) {
    // Safari 浏览器
    // if (this.$store.$global.platform === 'Mobile' || this.$store.$global.SafariBowser) {
    //     window.location.href = url
    //     return
    // }
    // let link = document.createElement("a")
    // link.target = "_block"
    // link.href = url;
    // link.download = download
    // // link.dispatchEvent(new MouseEvent("click"));
    // link.click()
    // return lodash.defer(() => {
    //     link = undefined;
    // })
    // if (this.$store.$global.platform === 'PC' && this.$store.$global.WechatBowser) {
    //     this.$msg('请使用浏览器下载')
    // }
    return (window.location.href = url)

    // fetch(url, { method: 'GET' })
    //   .then(response => response.blob())
    //   .then(blob => {
    //     const a = document.createElement('a');
    //     a.href = URL.createObjectURL(blob);
    //     a.download = decodeURIComponent(download); // 保存的文件名
    //     a.style.display = 'none';
    //     document.body.appendChild(a);
    //     a.click();
    //     document.body.removeChild(a);
    //     URL.revokeObjectURL(a.href);
    //   })
    //   .catch(error => console.error('Error:', error));
    //   return
  }
  const newWindow = window.open('_blank') // 先打开页面
  if (newWindow) {
    newWindow.location.href = url // 后更改页面地址
  } else {
    window.location.href = url
  }
}
// 文件大小换算
Vue.prototype.bytesToSize = function (bytes) {
  if (bytes === 0) return '0 B'
  var k = 1024
  var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  var i = Math.floor(Math.log(bytes) / Math.log(k))
  var num = bytes / Math.pow(k, i)
  return lodash.ceil(num, 1) + ' ' + sizes[i]
  //return (bytes / Math.pow(k, i)) + ' ' + sizes[i];
  //toPrecision(3) 后面保留一位小数，如1.0GB //return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
}
Vue.prototype.formatterMoney = function (value) {
  return `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
Vue.prototype.parserMoney = function (value) {
  return value.replace(/\￥\s?|(,*)/g, '')
}
/**
 * 转换 金额 分
 */
Vue.prototype.toMoneyCent = function (value) {
  if (!value) {
    return 0
  }
  return lodash.multiply(lodash.toFinite(value), 100)
}
/**
 * 转换 金额 元
 */
Vue.prototype.toMoneyDollar = function (value) {
  if (!value) {
    return 0
  }
  return lodash.divide(lodash.toFinite(value), 100)
}
/**
 * 是否有上一页
 */
Vue.prototype.isEmptyRouter = function () {
  return window.history.state.key === window.localStorage.getItem('history-state-key')
}
/**
 * 将用户id转换为16进制以便获取用户头像
 */
Vue.prototype.stringToHex = function (memberId: string) {
  let res = new String()
  for (let i = 0; i < memberId?.length; i++) res += memberId.charCodeAt(i).toString(16)
  return res
}

Vue.prototype.encodeHTML = function (html: string) {
  return html.replace(/</g, '&lt;').replace(/>/g, '&gt;')
}
declare module 'vue/types/vue' {
  interface Vue {
    readonly isEmptyRouter: () => boolean
    readonly moment: typeof moment
    /** APi 枚举 */
    readonly $EnumApiCurrency: typeof EnumApiCurrency
    /** 链接枚举 */
    readonly $EnumLocaleLinks: typeof EnumLocaleLinks
    /** 消息枚举 */
    readonly $EnumMessage: typeof EnumMessage
    /** 说明枚举 */
    readonly $EnumLocaleDescriptions: typeof EnumLocaleDescriptions
    /** 正则表达式 */
    readonly $Regulars: typeof Regulars
    /** 加密算法 */
    readonly $Encryption: typeof Encryption
    /** Mobx  toJS*/
    readonly toJS: typeof toJS
    /**
     * 检查用户是否登录
     * 没有登录会抛异常。try 包裹使用
     * @visible 吊起 登录框
     */
    readonly $InspectUser: (visible?: Boolean) => any
    readonly $assertLogin: (callback?: () => void) => void
    /** 显示 图片 */
    readonly $showViewer: (src: string | string[], index?) => any
    /** 检查code 是否 是当前用户 */
    readonly $eqUser: (code: any) => Boolean
    /** 消息 提示 */
    readonly $msg: (msg: string, type?: 'success' | 'info' | 'warning' | 'error' | 'loading') => any
    /** oss 压缩图片 */
    readonly ossProcessImg: (img: string, w?: number, h?: number, middle?: boolean, notInterlace?) => string
    readonly ossProcessImgRules: (img: string, rules) => string
    readonly ossProcessImgJPG: (img: string, w: number, h?: string) => string
    /** 时间 格式化 */
    readonly formatterDate: (value: any) => string
    readonly formatMomentDate: (value: any) => string
    readonly formatMomentCount: (value: number, defaultText: string) => string
    /** 替换换行符 */
    readonly replaceHtml: (value: string) => string
    /** 文件大小换算 */
    readonly bytesToSize: (bytes: number) => string
    /** 价格 格式化 */
    readonly formatterMoney: (value: number) => string

    /** 反价格 格式化 */
    readonly parserMoney: any
    readonly htmlEncode: any
    /**
     * 转换 金额 分
     */
    readonly toMoneyCent: (value: any) => number
    /**
     * 转换 金额 元
     */
    readonly toMoneyDollar: (value: any) => number
    /** Ajax */
    readonly $ajax: AjaxBasics
    /**  替换 href 字符 */
    readonly toHref: (value: string) => string
    /** 新窗口打开链接 */
    readonly onOpenBlock: (value: string, download?: string) => void
    // 表情转换
    readonly formatFace: (html: string) => string
    // 转换16进制
    readonly stringToHex: (memberId: string) => string
    // html编译
    readonly encodeHTML: (html: string) => string
  }
}
