import { BindAll } from 'lodash-decorators'
import { Pagination } from '../basics/pagination'
import { EnumApiTicket } from '../../api'
import { AjaxBasics } from '../../helpers'

@BindAll()
export class MyTicketWork extends Pagination<any> {
  constructor($ajax: AjaxBasics) {
    super($ajax, {
      url: EnumApiTicket.MY_TICKET_LIST,
      method: 'get',
      defaultPageSize: 10
    })
  }
}
