/**
 * @author 冷 (https://github.com/LengYXin)
 * @email lengyingxin8966@gmail.com
 * @create date 2020-08-05 14:12:38
 * @modify date 2020-08-05 14:12:38
 * @desc [description]
 */
import Vue from 'vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
// import style
// import 'swiper/css/swiper.css'
// If you use Swiper 6.0.0 or higher
// import 'swiper/swiper-bundle.css'
// https://github.com/surmon-china/vue-awesome-swiper
Vue.use(VueAwesomeSwiper /* { default options with global component } */)
