















import { Component, Prop, Vue, Ref } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'

@Observer
@Component({})
export default class PageView extends Vue {
  @Prop({ default: {} }) model // 老邮箱以及验证码
  get PageStore() {
    return this.$store.$storeUser
  }
  codeType = 6 //3 绑定手机，4 绑定邮箱，5 更换手机，6 更换邮箱，7 手机找回密码，8 邮箱找回密码
  validDevice = false
  defaultValue = ''
  oldValue = ''
  get bind() {
    return {
      phone: false,
      validDevice: this.validDevice,
      validExist: false,
      label: '邮箱',
      btnText: '完成',
      layout: {
        labelCol: { span: 0 },
        wrapperCol: { span: 24 }
      },
      btnCol: { span: 24, offset: 0 }
    }
  }
  created() {
    let email = lodash.get(this.PageStore, ['UserInfo', 'email'])
    if (email || !email) {
      this.validDevice = true
    }
  }
  async onSubmit(model) {
    try {
      // 6 更换邮箱
      if (this.model.oldPhoneNum === model.device) {
        this.$msg('请更换一个新的邮箱', 'warning')
        return
      }
      if (lodash.eq(this.codeType, 6))
        await this.PageStore.onChangeEmail(
          lodash.merge(this.model, {
            newEmail: model.device,
            newEmailVerifyCode: model.verifyCode
          })
        )
      await this.PageStore.onGetUserInfo()
      this.$msg(this.$EnumMessage.bind_success, 'info')
      this.PageStore.onChangeBindVisible(false)
    } catch (error) {
      if (lodash.eq(error, false)) this.$msg(this.$EnumMessage.check_code_error)
    }
  }
  async onSendSms(model, callback, closeLockCallback) {
    try {
      await this.PageStore.onGetCode({
        device: model.device,
        verifyCodeType: this.codeType
      })
      callback()
      closeLockCallback()
    } catch (error) {
      closeLockCallback()
      if (lodash.eq(error, false)) this.$msg(this.$EnumMessage.check_code_error)
    }
  }
}
