/**
 * 课程状态枚举
 */
export enum EnumActivityState {
  /** 报名未开始 */
  NotStarted = '报名未开始',
  /** 正在报名 */
  InProgress = '正在报名',
  /** 未开课 */
  NoClass = '未开课',
  /** 开课中 */
  InClass = '开课中',
  /** 已结课 */
  EndofClass = '已结课'
}

export enum EnumNewActivityState {
  /** 报名未开始 */
  NotStarted = '即将报名',
  /** 正在报名 */
  InProgress = '立即报名',
  /** 报名结束 */
  SignupEnd = '报名结束',
  /** 开课中 */
  InClass = '开课中',
  /** 已结课 */
  EndofClass = '已结课'
}

/**
 * 订单目标类型（101:单阶课，102:全阶课，201:活动，301:展览）
 * **/
export enum EnumOrderType {
  single = 101,

  full = 102,

  activity = 201,

  exhibition = 301
}
