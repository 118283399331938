







import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import lodash from 'lodash'
import qrcode from 'qrcode'
@Component({
  components: {}
})
export default class extends Vue {
  // https://www.npmjs.com/package/qrcode#qr-code-options
  @Prop({ required: true }) options
  src = null
  onCreate() {
    if (this.options) {
      qrcode.toDataURL(this.options, (err, src) => {
        this.src = src
      })
    }
  }
  created() {}
  @Watch('options')
  onWatch() {
    this.onCreate()
  }
  mounted() {
    this.onCreate()
  }
  updated() {}
  destroyed() {}
}
