













import { Component, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component({
  components: {}
})
export default class GiveawayIntroduction extends Vue {
  // 标题
  @Prop({}) title: string

  get content() {
    return `<div>
    <p style="color: #827C7C;font-size: 14px;">成功报名“清风雅致·林曦老师的中国画美育直播课”，即可获赠国画线描系列一《线的初识》21天教练日课；赠送的教练日课，可选择自用或当作一份礼物转赠Ta人（二选一）。</p>
    <p style="color: #827C7C;font-size: 14px;">1. 如该直播课为赠课，则《线的初识》21天教练日课将会一并送至被赠人的账号中，被赠人可选择自用或转赠Ta人（二选一）。</p>
    <p style="color: #827C7C;font-size: 14px;">2. 该教练日课需要在<span style="color: #af9172;">2024年6月30日23:59:59前</span>选择自用或转赠，并完成领取。教练日课一旦被领取（不论自用领取或转赠后领取），则无法更换领取人，不能进行二次转赠，且每个账号下该教练日课只能领取一次，不能重复领取。如超时未领取（不论自用未领取或转赠后未领取），系统将默认选择为“自用”。但如果转赠出的教练日课未被领取，且本账号内已领取他人转赠的教练日课，则系统默认同学自动放弃本账号下赠送的教练日课。</p>
    <p style="color: #827C7C;font-size: 14px;">3. 如选择转赠该教练日课，转赠后，领取链接的时效为<span style="color: #af9172;">24小时</span>。超时未领取，领取链接失效，可在规定期限内<span style="color: #af9172;">（即2024年6月30日23:59:59前）</span>，再次选择自用或转赠。领取时间不足24小时，则以领取截止时间<span style="color: #af9172;">（2024年6月30日23:59:59）</span>为准。在未领取的情况下，有效的领取链接将以最后一次转赠为准。</p>
    <p style="color: #827C7C;font-size: 14px;">4. 该教练日课，<span style="color: #af9172;">开课时间为2024年7月18日</span>，领取成功的同学会在<span style="color: #af9172;">2024年7月5日</span>获得该课程权限，并陆续进入微信学习群。</p>
    <p style="color: #827C7C;font-size: 14px;">5. 关于退课：</p>
    <p style="color: #827C7C;font-size: 14px;">1) 直播课退课需在，<span style="color: #af9172;">退课截止日期2024年6月2日9:45前</span>申请，退课成功后，赠送的该教练日课课程权限将同步收回。</p>
    <p style="color: #827C7C;font-size: 14px;">2) 赠送的教练日课，在已转赠并被领取的情况下，申请直播课退课，转赠日课的领取人需在<span style="color: #af9172;">72小时内</span>确认退还该教练日课，退还后方可继续退款；退还失败或超时，将自动终止退课。如退课时间不足<span style="color: #af9172;">72小时</span>，则以退课剩余时间为准。</p>
  </div>`
  }

  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
