































































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import moment from 'moment'
import { Observer } from 'mobx-vue'
import { MixinsCourseSunDrying } from '@xt/client/mixins'

@Observer
@Component({
  components: {}
})
export default class PageView extends Mixins(MixinsCourseSunDrying) {
  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
