import { EnumBuyWay, EnumInvoiceState, EnumOrderState, EnumOrderStatusStyle } from '@xt/client/enums'
import Product from './product'
import moment from 'moment'
import { AjaxBasics } from '@xt/client/helpers'

// 原始数据类型
export type OriginApiOriginDataType = {
  turnPkgEndTime?: number
  pkgId?: number
  canSignUp?: boolean
  publishStatus?: number
  bizId?: number //业务商品id
  activityId: number // 活动id
  activityType: number // 活动类型（0:无活动，1:拼团）二进制存储
  bizProdCount: number // 子商品数：课时/关卡数量/商品件数
  buyWay: number // 购买方式（1自买，2赠课）
  coverUrl: string // 商品封面图
  createTime: number // 创建时间
  firstGroupMemberHeaderUrl: string // 团长头像
  groupCreateTime: number // 拼团创建时间
  groupNo: string // 拼团编号
  invoiceStatus: number // 发票状态（null-未申请,0待开票，1已开票，2驳回，3作废）
  learnEndTime: number // 学习结束时间
  learnStartTime: number // 学习开始时间
  memberId: number | string // 下单用户
  memberNickname: string // 下单用户昵称
  orderAmount: number // 订单原价
  orderNo: string // 订单号
  orderStatus: number // 订单状态（0未支付，1已支付，2退款中，3已退款，4已取消）
  otherGroupMemberHeaderUr: string // 团员头像
  otherGroupMemberNickname?: string // 团员昵称
  firstGroupMemberNickname?: string
  payAmount: number // 实际支付金额
  productId: number // 商品id
  productName: string // 商品名称
  productType: number // 商品类型
  receiverId: number // 买受用户
  receiverNickname: string // 买受用户昵称
  receiverPhoneNum: string // 买受用户手机号
  receiverStatus: number // 赠课单领取状态（0未领取，1已领取，2已拒绝）
  receiveStatus: number // 赠课单领取状态（0未领取，1已领取，2已拒绝）
  refund: boolean
  refundEndTime: number // 退课截止时间
  refundOrderId: number // 退款订单id
  refundOrderSn: string // 退款订单编号
  signUpEndTime: number // 报名截止时间
  status: number | string // 拼团状态
  refundAmount: number //实退金额
  scoreCompensationAmount: number // 积分补偿金额
  otherGroupMemberHeaderUrl: string
  firstGroupMemberId: string
  otherGroupMemberId: string // 团员id
  paymentType?: number //支付类型（0未支付，1免费，2微信，3支付宝，4桐板，5线下微信，6线下支付宝，7线下银行转账）
  virtualCurrency?: number //桐板儿优惠金额
  refundStatus?: number //退款状态
  finishTime?: string // 退款完成时间
  successTime?: string // 支付成功时间
  refundCreateTime?: string // 退款创建时间
  receiveTime?: string //领取时间
  groupHeader?: boolean //是否是团长
  cancelTime?: string //订单取消时间
  discountPrice?: number //商品售价
  receiverEmail: string // 买受用户邮箱
  shouldRefundAmount: number // 应退金额
  activityPageUrl?: string //跳转课程详情页
}

export default class Order {
  constructor(
    public origin: OriginApiOriginDataType,
    public productInfo: Product = new Product(
      Object.assign(
        { ...origin },
        {
          originalPrice: origin.orderAmount // 处理商品价格
        }
      ) as any
    )
  ) { }

  // zeroOrder:any = '';
  /**
   * 是否已取消
   */
  get isCancelled() {
    return this.orderStatus === EnumOrderState.Cancelled
  }

  /**
   * 是否待支付
   */
  get isNotPay() {
    return this.orderStatus === EnumOrderState.NotPay
  }

  /**
   * 是否是已支付
   */
  get isHasPay() {
    return this.orderStatus === EnumOrderState.HasPay
  }

  /**
   * 是否申请发票（发票开具中）
   */
  get isWaitInvoice() {
    return this.invoiceState === EnumInvoiceState.WaitInvoice
  }

  /**
   * 是否发票已开具
   */
  get isHasInvoice() {
    return this.invoiceState === EnumInvoiceState.HasInvoice
  }

  /**
   * 是否发起退款
   */
  get isRefund() {
    return this.orderStatus === EnumOrderState.Refund
  }

  /**
   * 是否已退款
   */
  get isHasRefund() {
    return this.orderStatus === EnumOrderState.HasRefund
  }

  /** 6.30 是否待确认 */
  // get isToBeConfirmed() {
  //   return this.orderStatus === EnumOrderState.ToBeConfirmed
  // }

  get zeroOrder() {
    return this.origin.payAmount <= 0
  }

  /**
   * 退款是否截止
   */
  get isRefundEnd() {
    return AjaxBasics.serviceDate.valueOf() > this.origin.refundEndTime
  }

  /**
   * 是否赠课订单
   */
  get isGive() {
    return this.buyWay === EnumBuyWay.Give
  }

  get buyWay() {
    switch (this.origin.buyWay) {
      case EnumBuyWay.Buy:
        return EnumBuyWay.Buy
      case EnumBuyWay.Give:
        return EnumBuyWay.Give
      default:
        return EnumBuyWay.Buy
    }
  }

  /**
   * 订单状态（0未支付，1已支付，2退款中，3已退款，4已取消）
   */
  get orderStatus() {
    switch (this.origin.orderStatus) {
      case 0:
        return EnumOrderState.NotPay
      case 1:
        return EnumOrderState.HasPay
      case 2:
        return EnumOrderState.Refund
      case 3:
        return EnumOrderState.HasRefund
      case 4:
        return EnumOrderState.Cancelled
      // 6.30
      // case 5:
      //   return EnumOrderState.ToBeConfirmed
      default:
        return EnumOrderState.Cancelled
    }
  }

  get orderStatusStyle() {
    switch (this.origin.orderStatus) {
      case 0:
        return EnumOrderStatusStyle.NotPay
      case 1:
        return EnumOrderStatusStyle.HasPay
      case 2:
        return EnumOrderStatusStyle.Refund
      case 3:
        return EnumOrderStatusStyle.HasRefund
      case 4:
        return EnumOrderStatusStyle.Cancelled
      // 6.30
      // case 5:
      //   return EnumOrderStatusStyle.ToBeConfirmed
      default:
        return EnumOrderStatusStyle.Cancelled
    }
  }

  /**
   * 订单付款时间
   */
  get payOrderTimeText() {
    return moment(this.origin.successTime).format('YYYY.MM.DD HH:mm:ss')
  }

  /**
   * 订单创建时间
   */
  get createOrderTimeText() {
    if (!this.origin.createTime) return ''
    return moment(this.origin.createTime).format('YYYY.MM.DD HH:mm:ss')
  }

  /**
   * 订单取消时间
   */
  get cancelOrderTimeText() {
    return moment(this.origin.cancelTime).format('YYYY.MM.DD HH:mm:ss')
  }

  /**
   * 订单退款时间
   */
  get refundFinishTime() {
    return moment(this.origin.finishTime).format('YYYY.MM.DD HH:mm:ss')
  }

  /**
   * 订单退款创建时间
   */
  get refundCreateTime() {
    return moment(this.origin.refundCreateTime).format('YYYY.MM.DD HH:mm:ss')
  }

  /**
   * 退款截至时间
   */
  get refundEndTime() {
    if (!this.origin.refundEndTime) return ''
    return moment(this.origin.refundEndTime).format('YYYY.MM.DD HH:mm:ss')
  }

  /**
   * 领取时间
   */
  get receiveTime() {
    if (this.origin.receiveTime) {
      return moment(this.origin.receiveTime).format('YYYY.MM.DD HH:mm:ss')
    }
    return ''
  }

  get invoiceState() {
    switch (this.origin.invoiceStatus) {
      case null:
        return EnumInvoiceState.NotApply
      case 0:
        return EnumInvoiceState.WaitInvoice
      case 1:
        return EnumInvoiceState.HasInvoice
      case 2:
        return EnumInvoiceState.Reject
      case 3:
        return EnumInvoiceState.Cancellation
      default:
        return EnumInvoiceState.NotApply
    }
  }

  resetGet(origin: OriginApiOriginDataType) {
    this.origin = { ...this.origin }
  }
}
