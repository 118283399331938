































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import Footer from './footer.vue'
import { enterFullScreen } from '@xt/client/helpers/system'

@Observer
@Component({
  components: { Footer }
})
export default class PlaybackPageView extends Vue {
  @Prop({ default: false }) toggle: boolean
  @Prop({ default: false }) fullscreen: boolean
  ob: MutationObserver | null = null

  controlVisible = true
  controlDuration = 4000
  controlTimer: NodeJS.Timer | null = null

  get PageStore() {
    return this.$store.$genseePlayback
  }

  @Ref('video-widget-box')
  videoWidgetBox: HTMLDivElement

  videoBoxMoveHandler() {
    if (!this.PageStore.videoState.isFullScreen) return
    this.recoverControlTimer()
  }

  resetControlTimer() {
    this.controlVisible = false
    if (this.controlTimer) {
      clearTimeout(this.controlTimer)
      this.controlTimer = null
    }
  }

  recoverControlTimer() {
    this.controlVisible = true
    if (this.controlTimer) {
      clearTimeout(this.controlTimer)
      this.controlTimer = null
    }
    this.controlTimer = setTimeout(this.resetControlTimer, this.controlDuration)
  }

  fullscreenHandler() {
    const targetNode = document.querySelector('#video-widget-box') as HTMLDivElement
    enterFullScreen(targetNode)
  }

  @Watch('fullscreen')
  changeFullscreen(value: boolean) {
    if (value) {
      this.recoverControlTimer()
    }
  }

  created() {
    this.$nextTick(() => {
      this.ob = new MutationObserver(list => {
        for (const item of list) {
          if (item.addedNodes.length > 0) {
            if ((item.addedNodes[0] as HTMLElement).tagName === 'IFRAME') {
              this.$msg('视频解析失败')
              setTimeout(() => {
                location.replace('/')
              }, 1000)
              return
            }
          }
        }
      })

      this.ob.observe(this.videoWidgetBox, { childList: true, subtree: true, attributes: false })
    })
  }
  mounted() {}
  destroyed() {
    if (this.ob) {
      this.ob.disconnect()
    }
  }
}
