/**
 * @author 冷 (https://github.com/LengYXin)
 * @email lengyingxin8966@gmail.com
 * @create date 2020-10-20 20:25:53
 * @modify date 2020-10-20 20:25:53
 * @desc 课程地图页
 */
import lodash from 'lodash'
import moment from 'moment'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { EnumCourseType } from '../../enums'
import { Course, Classhour } from '../../entities'
import { AjaxBasics } from '../../helpers'

@Component
export class MixinsCourseDownTime extends Vue {
  /** 课程数据 */
  @Prop({ default: () => ({}) }) dataSource: Course
  get PageStore() {
    return this.$store.$storeCourse
  }
  get UserStore() {
    return this.$store.$storeUser
  }
  /**
   * 结束时间，转报 或者 报名
   * @readonly
   * @memberof MixinsCourseDownTime
   */
  get endTime() {
    return this.dataSource.downEndTime
  }
  // 倒计时格式化
  get format() {
    if (this.$store.$global.platform === 'Mobile') {
      return '剩余 HH:mm:ss '
    }
    let star = this.dataSource.signupText === '转报全阶' ? '转报' : '报名'
    return `${star}剩余 HH:mm:ss `
  }
  get formatStr() {
    return this.dataSource.downEndTimeFormat
  }
  get isDown() {
    if (this.dataSource.id) {
      return this.dataSource.isDownTime
    }
  }
  get isCanSignUp() {
    return this.dataSource.isCanSignUp
  }
  get deadline() {
    return moment().add(moment(this.endTime).diff(this.$ajax.serviceDate(), 'seconds'), 'seconds').valueOf()
  }
  get surplus() {
    if (this.isSignupEnd()) return "报名结束"
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    return `剩余${this.computeDay(currentTime, this.endTime)}天`
  }

  computeDay(currentTime, endtime) {
    return Math.floor(this.moment(endtime).diff(currentTime, 'hour') / 24)
  }

  isSignupEnd() {
    const diff = moment(this.endTime).diff(this.$ajax.serviceDate(), 'seconds')
    return diff <= 0
  }

  onFinish() {
    lodash.delay(() => {
      this.PageStore.onUpdate(this.dataSource)
    }, 200)
  }
  created() { }
  mounted() { }
  updated() { }
  destroyed() { }
}

//活动 倒计时
@Component
export class MixinsActivityDownTime extends Vue {
  /** 课程数据 */
  @Prop({ default: () => ({}) }) dataSource: any
  get PageStore() {
    return this.$store.$storeActivitys
  }
  get UserStore() {
    return this.$store.$storeUser
  }
  get isSignupEndVal() {
    return this.endTime < this.$ajax.serviceDate()
  }
  isSignupEnd() {
    const diff = moment(this.endTime).diff(this.$ajax.serviceDate(), 'seconds')
    return diff <= 0
  }
  /**
   * 结束时间，转报 或者 报名
   * @readonly
   * @memberof MixinsCourseDownTime
   */
  get endTime() {
    return this.dataSource.signUpEndTime
  }
  get showFixedDate() {
    let sdate = this.dataSource.signUpStartTime
    if (this.isCanSignUp) {
      sdate = this.dataSource.signUpEndTime
    }

    return sdate
  }
  // 倒计时格式化
  get format() {
    let star = '拉手团活动已结束\xa0\xa0\xa0\xa0\xa0\xa0报名'
    return `${star}剩余 HH:mm:ss`
  }
  get formatStr() {
    //是否可以报名
    if (this.isCanSignUp) {
      return `拉手团活动将在${this.surplus}后结束\xa0\xa0\xa0\xa0\xa0\xa0报名截至 YYYY.MM.DD`
    }
    return `${this.format}`
  }
  get isDown() {
    if (this.dataSource.id) {
      let serviceDate = this.$ajax.serviceDate().startOf('seconds').add('milliseconds', 100).valueOf()
      let downEndTime = this.dataSource.signUpEndTime
      const diff = moment(downEndTime).diff(serviceDate, 'seconds')
      return diff > 0 && diff < 24 * 60 * 60
    }
  }
  get isCanSignUp() {
    let serviceDate = this.$ajax.serviceDate().startOf('seconds').add('milliseconds', 100).valueOf()
    let siginUpStartTime = this.dataSource.signUpStartTime
    return this.dataSource.canSignUp && siginUpStartTime < serviceDate
  }
  get deadline() {
    let res = moment().add(moment(this.endTime).diff(this.$ajax.serviceDate(), 'seconds'), 'seconds').valueOf()
    return res
  }
  // 剩余 天数
  get surplus() {
    return `${moment(this.endTime - 86400000).diff(this.$ajax.serviceDate(), 'days')}天`
  }
  onFinish() {
    lodash.delay(() => {
      this.PageStore.onUpdate(this.dataSource)
    }, 200)
  }
  created() { }
  mounted() { }
  updated() { }
  destroyed() { }
}

/**
 * 课时 倒计时
 */
@Component
export class MixinsCourseClasshourDownTime extends Vue {
  /** 课程数据 */
  @Prop({ default: () => ({}) }) dataSource: Classhour
  @Prop({ default: 0 }) index: number
  get PageStore() {
    return this.$store.$storeCourse.Details.Map
  }
  /**
   * 头 尾 未发布 不展示
   * @readonly
   * @memberof MixinsCourseClasshourDownTime
   */
  get isShow() {
    if (this.dataSource.isTemporary) {
      return this.dataSource.isPublished
    }
    return true
  }
  get startText() {
    return this.dataSource.statusText
  }
  get format() {
    // if (this.playback) {
    //     return " HH : mm : ss"
    // }
    return ' HH:mm:ss'
  }
  /**
   * 显示倒计时
   */
  get isDown() {
    return this.dataSource.isDownTime
  }
  /**
   * 倒计时时间
   */
  get deadline() {
    return moment().add(moment(this.dataSource.downEndTime).diff(this.$ajax.serviceDate(), 'seconds'), 'seconds').valueOf()
  }
  /**
   * 状态描述
   */
  get status() {
    if (!this.dataSource.startTime) {
      return '暂无直播时间'
    }
    if (this.dataSource.isLive) {
      return '正在直播'
    }
    if (this.dataSource.isPlayback) {
      if (this.isDown) {
        return ''
      }
      if (this.dataSource.surplusDay > 0) {
        return `${this.dataSource.surplusDay}天`
      } else {
        return this.moment(this.dataSource.startTime).format('YYYY-MM-DD')
      }
    }
    if (this.dataSource.liveEnd) {
      return `直播结束`
    }
    return this.moment(this.dataSource.startTime).format('YYYY-MM-DD')
  }
  get classStr(): any {
    if (!this.dataSource.isPublished) {
      return 'xt-classhour-not'
    }
    if (this.dataSource.isPlayback) {
      if (this.dataSource.surplusDay > 0 || this.isDown) {
        return 'xt-classhour-playback'
      }
    }
    if (this.dataSource.isLive || this.isDown) {
      return 'xt-classhour-live'
    }
    if (this.dataSource.liveEnd) {
      if (!this.dataSource.isPlayback) {
        return 'xt-classhour-end xt-classhour-not-playback'
      }
      return 'xt-classhour-end'
    }
    return ''
  }
  onFinish() {
    lodash.delay(() => {
      this.PageStore.onUpdateClasshourState(this.dataSource)
    }, 999)
  }
  created() { }
  mounted() { }
  updated() { }
  destroyed() { }
}
/**
 * 小于 1 天
 * @param start
 * @param end
 */
function diffDay(start: moment.Moment, end: moment.Moment) {
  const diff = end.diff(start, 'seconds')
  return diff > 0 && diff < 24 * 60 * 60
}
