import { render, staticRenderFns } from "./partCourse.vue?vue&type=template&id=50a9c5c0&scoped=true&"
import script from "./partCourse.vue?vue&type=script&lang=ts&"
export * from "./partCourse.vue?vue&type=script&lang=ts&"
import style0 from "./partCourse.vue?vue&type=style&index=0&id=50a9c5c0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50a9c5c0",
  null
  
)

export default component.exports