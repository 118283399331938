/**
 * 本地连接枚举
 */
export enum EnumLocaleLinks {
  /** 首页 */
  links_home = 'links_home',
  /** 课程 */
  links_course = 'links_course',
  /** 视频分享 */
  links_video = 'links_video',
  /** 暄桐文房 */
  links_stationery = 'links_stationery',
  /** 关于暄桐 */
  links_about = 'links_about',
  /** 去上课 我的 */
  links_my = 'links_my',
  /** 注册 */
  links_register = 'links_register',
  /** 登录 */
  links_signin = 'links_signin',
  /** 微信登录 */
  links_wx_login = 'links_wx_login',
  /** 手机号登录 */
  links_phone_login = 'links_phone_login',
  /** 邮箱登录 */
  links_email_login = 'links_email_login',
  /** 绑定设备 */
  links_bind_device = 'links_bind_device',
  /** 找回密码 */
  links_retrieve = 'links_retrieve',
  /** 完善个人信息 */
  links_complete = 'links_complete',
  /** 课程介绍 */
  links_courseIntroduction = 'links_courseIntroduction',
  /** 报名问答 */
  links_questionAnswer = 'links_questionAnswer',
  /** 相关推荐 **/
  links_relativeRecommend = 'links_relativeRecommend',
  /** 课程组成 */
  links_courseComposition = 'links_courseComposition',
  /** 去上课 */
  links_goToTheClass = 'links_goToTheClass',
  /** 晒作业 */
  links_sunDrying = 'links_sunDrying',
  /** 感想 */
  links_thoughts = 'links_thoughts',
  /** 作业 */
  links_homework = 'links_homework',
  /** 订单 */
  links_order = 'links_order',
  /** 支付 */
  links_payment = 'links_payment'
}
/**
 * 描述类枚举
 */
export enum EnumLocaleDescriptions {
  /** 微信公众号 */
  des_officialAccount = 'des_officialAccount',
  /** 新浪微博 */
  des_sinaWeibo = 'des_sinaWeibo',
  /** 暄桐 */
  des_xt = 'des_xt',
  /** 联系我们  */
  des_contactUs = 'des_contactUs',
  /** 版权声明  */
  des_copyright = 'des_copyright',
  /** 口号 */
  des_slogan = 'des_slogan',
  /** 地址 */
  des_address = 'des_address',
  /** email */
  des_email = 'des_email',
  /**版权声明内容 */
  des_copyright_content = 'des_slogan_content'
}
