/**
 * @author wangsl
 */
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component
export default class wxbindScript extends Vue {
  @Prop({}) visible: boolean
  @Prop({}) platform: string

  @Emit('hide')
  hidePopup() {}
  mounted() {
    // 绘制二维码 ....
    this.platform === 'web' &&
      this.$store.$wechat.onWxLogin(
        'qrcode-box',
        this.$store.$global.openAppid,
        'bind',
        window.location.href,
        'data:text/css;base64,LyogLmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMXB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyMDBweDt9Ci5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30gKi8KLmltcG93ZXJCb3ggLnRpdGxlewogICAgZGlzcGxheTogbm9uZTsKfQouaW1wb3dlckJveCAuaW5mbyB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnFyY29kZSB7CiAgICB3aWR0aDogMTQ4cHg7CiAgICBib3gtc2l6aW5nOiBib3JkZXItYm94OwogICAgbWFyZ2luLXRvcDogMDsKICAgIGJvcmRlcjogbm9uZTsKfQouaW1wb3dlckJveCAud3JwX2NvZGV7CiAgICB3aWR0aDoxNDhweDsKfQ=='
      )
  }

  created() {}
}
