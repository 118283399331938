/**
 * @author wangsl
 */
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import Platform from '../platform'

import QRCode from 'qrcode'

@Observer
@Component
export default class Poster extends Vue {
  @Prop({}) platform: string // 'web' || 'wap'
  @Prop({}) groupPrice: number
  @Prop({}) discountPrice: number
  @Prop({}) visible: boolean
  @Prop({}) contentType: string
  @Prop({}) productName: string
  @Prop({}) coverUrl: string

  quality: number = 2

  posterUrl: string = ''

  drawed: boolean = false // 绘制状态，为false时点击保存图片会报错
  canvas: HTMLCanvasElement

  draw() {
    let hide = Vue.prototype.$loading('加载中')
    let canvas: HTMLCanvasElement = <HTMLCanvasElement>document.getElementById('poster')
    canvas.width = canvas.offsetWidth * window.devicePixelRatio * this.quality
    canvas.height = canvas.offsetHeight * window.devicePixelRatio * this.quality

    this.canvas = canvas
    let ctx = canvas.getContext('2d')
    let cover = new Image()
    cover.crossOrigin = 'Anonymous'
    cover.src = this.coverUrl + '?x-oss-process=image/resize,w_650,h_402,m_fill/quality,q_90/format,jpg/quality,q_90'
    cover.onload = () => {
      ctx.fillStyle = '#FFFFFF'
      ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)
      ctx.drawImage(cover, 0, 0, this.computedSize(325), this.computedSize(201))
      ctx.fillStyle = '#AD9174'

      // ctx.fillRect(this.computedSize(20), this.computedSize(182), this.computedSize(26), this.computedSize(15))
      ctx.beginPath()
      ctx.moveTo(this.computedSize(20 + 3), this.computedSize(219))
      ctx.arcTo(
        this.computedSize(20 + 26),
        this.computedSize(219),
        this.computedSize(20 + 26),
        this.computedSize(219 + 15),
        this.computedSize(3)
      )
      ctx.arcTo(
        this.computedSize(20 + 26),
        this.computedSize(219 + 15),
        this.computedSize(20),
        this.computedSize(219 + 15),
        this.computedSize(3)
      )
      ctx.arcTo(this.computedSize(20), this.computedSize(219 + 15), this.computedSize(20), this.computedSize(219), this.computedSize(3))
      ctx.arcTo(this.computedSize(20), this.computedSize(219), this.computedSize(20 + 26), this.computedSize(219), this.computedSize(3))
      ctx.closePath()
      ctx.fill()
      ctx.font = `${this.computedSize(10)}px a`
      ctx.fillStyle = '#FFFFFF'
      ctx.fillText(this.contentType, this.computedSize(22.5), this.computedSize(229.5))

      ctx.font = `${this.computedSize(14)}px a`
      ctx.fillStyle = '#333333'
      ctx.fillText(this.productName, this.computedSize(51), this.computedSize(231))

      ctx.font = `${this.computedSize(12)}px a`
      ctx.fillStyle = '#999999'
      const discountPrice = `原学费:${this.money(this.discountPrice)}元`
      ctx.fillText(discountPrice, this.computedSize(20), this.computedSize(263))

      let tm: TextMetrics = ctx.measureText(discountPrice)
      ctx.fillRect(this.computedSize(20), this.computedSize(259), tm.width, 2)

      const groupPrice = this.money(this.groupPrice).toString()
      ctx.fillStyle = '#333333'
      ctx.font = `${this.computedSize(12)}px a`
      let yuanWidth = ctx.measureText('元  ').width
      let tipWidth = ctx.measureText('拉手团').width
      ctx.font = `${this.computedSize(17)}px a`
      let priceWidth = ctx.measureText(groupPrice).width
      ctx.fillText(groupPrice, this.canvas.width - priceWidth - this.computedSize(20) - yuanWidth - tipWidth, this.computedSize(265))

      ctx.font = `${this.computedSize(12)}px a`
      ctx.fillText('元  ', this.canvas.width - this.computedSize(20) - yuanWidth - tipWidth, this.computedSize(263))
      ctx.fillText('拉手团', this.canvas.width - this.computedSize(20) - tipWidth, this.computedSize(263))

      ctx.fillStyle = 'rgba(173, 145, 116, .2)'
      ctx.fillRect(this.computedSize(20), this.computedSize(285), this.canvas.width - this.computedSize(20) * 2, this.computedSize(1))

      // 第一版本为绘制文字，当前版本改为图片，保留初版代码，勿删
      // ctx.font = `${this.computedSize(15)}px a`
      // ctx.fillStyle = '#333333'
      // let tipBlackFirWidth = ctx.measureText("我正在参加该课程的报名活动").width
      // ctx.fillText("我正在参加该课程的报名活动", (this.canvas.width - tipBlackFirWidth) / 2, this.computedSize(285))

      // let tipBlackSecWidth = ctx.measureText("快来和我一起学习吧").width
      // ctx.fillText("快来和我一起学习吧", (this.canvas.width - tipBlackSecWidth) / 2, this.computedSize(310))
      let slogan = new Image()
      slogan.crossOrigin = 'Anonymous'
      slogan.src = `${this.$store.$storeGroup.posterSlogan}?t=${Date.now()}`
      slogan.onload = () => {
        let sloganWidth = this.computedSize(234)
        let sloganHeight = this.computedSize(43)
        ctx.drawImage(slogan, (canvas.width - sloganWidth) / 2, this.computedSize(307), sloganWidth, sloganHeight)
        QRCode.toDataURL(window.location.href, (err, string) => {
          if (err) throw err
          let qrcode = new Image()
          qrcode.src = string
          qrcode.onload = () => {
            let size = this.computedSize(80)
            ctx.drawImage(qrcode, (this.canvas.width - size) / 2, this.computedSize(381), size, size)

            ctx.font = `${this.computedSize(12)}px a`
            ctx.fillStyle = '#666666'
            let tipThrText = '长按识别二维码'
            let tipThrWidth = ctx.measureText(tipThrText).width
            ctx.fillText(tipThrText, (this.canvas.width - tipThrWidth) / 2, this.computedSize(473))
            this.posterUrl = this.canvas.toDataURL('image/png', 2.0)
            this.drawed = true // 修改状态为绘制完成
            hide('加载完成')
          }
        })
      }
    }
  }

  money(price: number) {
    let yuan = (price || 0) / 100
    return yuan % 1 != 0 ? yuan.toFixed(2) : yuan
  }

  download(href: string) {
    try {
      // 创建一个 a 标签，并设置 href 和 download 属性
      const el = document.createElement('a')
      // 设置 href 为图片经过 base64 编码后的字符串，默认为 png 格式
      el.href = href
      el.download = `share-${Date.now()}`
      // 创建一个点击事件并对 a 标签进行触发
      const event = new MouseEvent('click')
      el.dispatchEvent(event)
    } catch (_) {
      this.$msg('图片保存失败，换个浏览器试试')
    }
  }

  get btnText() {
    return `${this.isWechatBowser ? '长按' : ''}保存图片，去分享`
  }

  get isWechatBowser() {
    return this.platform !== 'web' && this.$store.$global.WechatBowser && Platform.Mobile
  }

  save() {
    if (!this.posterUrl) return
    if (!this.isWechatBowser) this.download(this.posterUrl)
  }

  @Emit('hide')
  hidePopup() {}

  computedSize(size: number) {
    let zoom = this.canvas.offsetWidth / 325
    return size * zoom * window.devicePixelRatio * this.quality
  }

  created() {}

  stopEvent() {}

  @Watch('visible')
  visibleUpdate(value) {
    if (!value) return (this.drawed = false)
    this.$nextTick(() => {
      value && this.draw()
    })
  }
  mounted() {
    this.visible && this.draw()
  }
}
