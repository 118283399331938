












import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import lodash from 'lodash'
@Component({
  components: {}
})
export default class extends Vue {
  // 活动状态
  @Prop({ default: false }) action
  // 统计数量
  @Prop({ default: 0 }) statistics
  // 默认标题
  @Prop({ default: '喜欢' }) title
  // 图标
  @Prop({ default: 'icon-like' }) icon
  // 图标大小
  @Prop({ default: 14 }) iconSize
  // 图标颜色
  @Prop({ default: 'yellow' }) iconColor
  // 文字颜色
  @Prop({ default: 'grey' }) textColor

  get text() {
    return this.statistics || this.title
  }
  get iconStyle() {
    return { fontSize: this.iconSize + 'px' }
  }
  @Emit('click')
  onLike(event) {
    return event
  }
  updated() {}
  destroyed() {}
}
