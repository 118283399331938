















import { Component, Prop, Vue, Watch, Inject, Emit } from 'vue-property-decorator'
import lodash from 'lodash'
@Component({
  components: {}
})
export default class extends Vue {
  @Prop({ default: 'slide' }) animation
  updated() {}
  destroyed() {}
}
