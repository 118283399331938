import { Observer } from 'mobx-vue'
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { ExtendedVue } from 'vue/types/vue'

/***************************  枚举  *************************** */
export type OrderPayChooserProps = {
  vm?: ExtendedVue<any, any, any, any, any> // 要实例化的对应组件
  title?: string // 标题
  content?: string // 内容 v-html
  mainText?: string // 主文本
  viceText?: string // 副文本
  imgSrc?: string // 一条图
}

/***************************  公共组件脚本  *************************** */
@Observer
@Component
export default class OrderPayChooseScript extends Vue {
  visible: boolean = true
  @Prop({}) title: string
  @Prop({}) mainText: string
  @Prop({}) viceText: string
  @Prop({}) content: string
  @Prop({}) imgSrc: string

  @Emit('clickMain')
  main(): void {}

  @Emit('clickVice')
  vice(): void {}

  @Emit('close')
  close(): void {
    this.visible = false
  }
}

/***************************  选择器  *************************** */
/**
 * new OrderPayChooser().then().vice().close()
 */
export abstract class OrderPayChooser {
  el: Vue = null
  compInstance: Vue = null
  closeFn = null //记录close调用方法
  protected abstract platform(): 'Mobile' | 'PC'
  constructor(private props: OrderPayChooserProps) {
    const Comp = Vue.extend(props.vm)
    this.compInstance = new Comp({
      propsData: {
        title: props.title || '标题',
        content: props.content || '内容',
        mainText: props.mainText || '确认',
        viceText: props.viceText || '取消',
        imgSrc:
          props.imgSrc ||
          (this.platform() === 'PC'
            ? 'https://static.xuantong.cn/images/groupbuy/pc/jinyu_im.png'
            : 'https://static.xuantong.cn/images/groupbuy/collage_popu_top_im.png')
      }
    })
    this.el = this.compInstance.$mount()
    this.compInstance.$on('close', () => this._destroy())
    document.body.appendChild(this.el.$el)
  }

  main(main: () => void): OrderPayChooser {
    this.compInstance.$on('clickMain', () => {
      try {
        main && main()
      } catch (_) {}
      this._destroy()
    })
    return this
  }

  vice(vice: () => void): OrderPayChooser {
    this.compInstance.$on('clickVice', () => {
      try {
        vice && vice()
      } catch (_) {}
      this._destroy()
    })
    return this
  }

  close(close?: () => void): OrderPayChooser {
    this.closeFn = close //记录close调用方法
    return this
  }

  private _destroy(): void {
    try {
      this.closeFn && this.closeFn() //执行closeFn,重置按钮状态
    } catch (_) {
    } finally {
      try {
        this.compInstance.$destroy()
        document.body.removeChild(this.el.$el)
      } catch (_) {}
    }
  }
}
