import lodash from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'
import moment from 'moment'

@Component
export class MixinsCalendarCard extends Vue {
  @Prop() dataSource: any
  @Prop() index: number
  @Prop() date
  @Prop() activeDate
  @Prop() currentDate
  @Prop() live: Array<any>

  weekArr = ['周一', '周二', '周三', '周四', '周五', '周六', '周日'] // 星期
  monthArr = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'] // 月份

  /** 是否是点击的那一天 */
  get isActiveDay() {
    return (
      this.activeDate.activeYear == this.dataSource.y &&
      this.activeDate.activeMonth == this.dataSource.m &&
      this.activeDate.activeDay == this.dataSource.d
    )
  }

  /** 是否是今天 */
  get isCurrentDay() {
    return (
      this.dataSource.y === this.currentDate.currentYear &&
      this.dataSource.m === this.currentDate.currentMonth &&
      this.dataSource.d === this.currentDate.currentDay
    )
  }

  /** 是否当天存在直播 */
  get isCurrentDayExistLive() {
    // 如果是当天
    if (
      this.dataSource.y === this.currentDate.currentYear &&
      this.dataSource.m === this.currentDate.currentMonth &&
      this.dataSource.d === this.currentDate.currentDay
    ) {
      let res = this.live?.filter(item => {
        let [y, m, d] = moment(item.startTime).format('YYYY-MM-DD').split('-')
        return (
          parseInt(y) === this.dataSource.y &&
          parseInt(m) === this.dataSource.m &&
          parseInt(d) === this.dataSource.d &&
          item.publishStatus === 1
        )
      })
      // return res?.length && !res[res?.length - 1].liveEnd
      let currentTime = this.$ajax.serviceDate().valueOf()
      // 是否能找到一个开始时间大于等于当前时间 | 开始时间小于当前时间但是直播没有结束
      return (
        res?.length &&
        lodash.find(res, item => {
          return item.startTime >= currentTime || (item.startTime < currentTime && !item.liveEnd)
        })
      )
    }
    return false
  }

  /** 是否存在直播 */
  get isExistLive() {
    let res = this.live?.find(item => {
      let [y, m, d] = moment(item.startTime).format('YYYY-MM-DD').split('-')
      if (item.sort === 0 || item.sort === 9999) {
        return (
          parseInt(y) === this.dataSource.y &&
          parseInt(m) === this.dataSource.m &&
          parseInt(d) === this.dataSource.d &&
          item.publishStatus === 1
        )
      }
      return parseInt(y) === this.dataSource.y && parseInt(m) === this.dataSource.m && parseInt(d) === this.dataSource.d
    })
    return !!res
  }

  /** 是否是当前月份（此当前非你想的当前） */
  get isCurrentMonth() {
    return this.dataSource.y === this.date.y && this.dataSource.m === this.date.m
  }

  // 是否比当前天要大
  get isCurrAndNextDays() {
    return (
      this.dataSource.y > this.currentDate.currentYear ||
      (this.dataSource.y === this.currentDate.currentYear && this.dataSource.m > this.currentDate.currentMonth) ||
      (this.dataSource.y === this.currentDate.currentYear &&
        this.dataSource.m === this.currentDate.currentMonth &&
        this.dataSource.d > this.currentDate.currentDay)
    )
  }

  /** 是否是当前月的第一天 */
  get isCurrentMonthFirstDay() {
    return this.dataSource.y === this.date.y && this.dataSource.m === this.date.m && this.dataSource.d === 1
  }

  changeActive(y, m, d) {
    this.$emit('changeActive', { y, m, d })
  }
}
