/**
 * @author wangsl
 */
import { Observer } from 'mobx-vue'
// import moment from "moment";
import { Vue, Component } from 'vue-property-decorator'
// import JSONDATA from "./activity.json"

@Observer
@Component({
  async asyncData(ctx) {
    // 读取远程JSON文件
    const res = await ctx.store.$ajax.get(
      `/mocks/activity.json`,
      {},
      { 'Cache-Control': 'no-store', 'If-Modified-Since': '0' },
      {
        target: ctx.store.$global.production ? 'https://static.xuantong.cn' : 'https://testing-static.xuantong.cn'
      }
    )
    //单元测试用本地JSON文件
    // const res = JSONDATA
    return {
      dataSource: res
    }
  }
})
export default class DayCourseScript extends Vue {
  dataSource: any = {}
  goCourseList() {
    this.$router.push({
      path: '/product/list?typeCurrent=2&directionCurrent=0'
    })
  }
  mounted() {}
}
