



















import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import LazyImg from '@/components/lazyImg/index.vue'
@Component({
  components: { LazyImg }
})
export default class PageView extends Vue {
  loading = true
  loadErr = false
  get PageStore() {
    return this.$store.$storeCourse.Details
  }
  isEmpty(data) {
    return lodash.isEmpty(data)
  }
  // imgs = [];
  // async created() {
  //   const res = await this.PageStore.onGetCourseIntroduce({
  //     courseId: this.$route.params.id,
  //     courseType: 1,
  //   });
  //   this.imgs = res;
  // }
  onLoadImg() {
    this.loading = false
  }
  onLoadError() {
    this.loading = false
    this.loadErr = true
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
