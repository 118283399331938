






















































































import { Component, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import Video from './components/video.vue'
import Doc from './components/doc.vue'
import Footer from './components/footer.vue'
import Chat from './components/chat.vue'
import Input from './components/input.vue'
import track from '@xt/client/utils/tracker'
import Popup from '../playback/components/popup.vue'
import { UserInfo } from '@xt/client/entities/user/types'
import { reportEntryLog } from '@xt/client/utils/log'
import { VideoLog } from '@xt/client/entities/gensee/log'
import { GenseeLinkSearch, parseGenseeLink } from '@xt/client/entities/gensee/utils'

@Observer
@Component({
  components: {
    Video,
    Doc,
    Footer,
    Chat,
    Input,
    Popup
  },
  head() {
    return {
      htmlAttrs: {
        'xmlns:gs': 'http://www.gensee.com/ec'
      }
    }
  },
  async asyncData(ctx) {
    const { classhour } = ctx.query as { classhour: string }
    const tabId = sessionStorage.getItem('tab-id')

    if (!classhour) {
      reportEntryLog('live.page-params.loss', { tabId })
      return ctx.redirect('/')
    }

    // 确保用户登录
    await ctx.store.$storeUser.onUpdatingUserInfo()
    const userInfo: UserInfo = ctx.store.$storeUser.UserInfo

    if (!ctx.store.$storeUser.isLogin || !userInfo) {
      reportEntryLog('live.check-user.fail', { isLoigin: ctx.store.$storeUser.isLogin, tabId })
      return ctx.redirect('/')
    }

    // 获取直播链接
    const liveLink = await ctx.store.$storeCourse.Details.Map.Details.onGolive(classhour)

    if (!liveLink) {
      reportEntryLog('live.get-link.fail', { tabId })
      return ctx.redirect('/')
    }

    // 解析直播链接参数
    const { isGray, gensee, sdk, k, courseId } = parseGenseeLink<GenseeLinkSearch>(liveLink, 'live')

    reportEntryLog('live.gensee.debug', { gensee, isGray, tabId })

    if (!isGray) {
      location.replace(gensee)
      return
    }

    // 课时详情
    await ctx.store.$storeCourse.Details.Map.Details.onLoading({ id: classhour })

    await ctx.store.$genseeLive.pageInit(courseId)

    ctx.store.$genseeLive.setWidgetParams({
      uid: userInfo.id,
      uname: userInfo.nickname,
      ownerid: sdk,
      k: k
    })

    return { gensee }
  },
  layout: 'clean'
})
export default class LivePageView extends Vue {
  // 是否让视频和文档大小屏幕切换  初始不切换
  toggle: boolean = false
  videoNode: HTMLMediaElement | null = null
  openSoundTimer: NodeJS.Timer | null = null
  // 原直播间地址
  gensee: string = ''

  loading = true

  get PageStore() {
    return this.$store.$genseeLive
  }

  get UserStore() {
    return this.$store.$storeUser
  }

  get classhourInfo() {
    return this.$store.$storeCourse.Details.Map.Details.dataSource.classhourInfo
  }

  // 切换屏幕
  onToggleScreen(toggle: boolean) {
    this.toggle = toggle
  }

  openSoundHandler() {
    this.PageStore.recoverVolumePlay()
    this.PageStore.resetSoundTimer()
  }

  ignoreSoundHandler() {
    this.PageStore.resetSoundTimer()
  }

  backOldVersionHandler() {
    reportEntryLog(VideoLog.live_user_click_back_to_gensee, { tabId: this.PageStore.currentTabId })
    location.replace(this.gensee)
  }

  logoutToHomeHandler() {
    this.UserStore.clearUserInfo()
    this.PageStore.clearBrowserWatchData()
    this.$nextTick(() => {
      location.replace('/')
    })
  }

  toHomeHandler() {
    this.PageStore.clearBrowserWatchData()
    location.replace('/')
  }

  handleReloadPage() {
    reportEntryLog(VideoLog.live_loading_too_long_reload, { tabId: this.PageStore.currentTabId })
    this.PageStore.setPageLoadingTooLong(false)

    window.location.reload()
  }

  handleKeepWaiting() {
    reportEntryLog(VideoLog.live_loading_too_long_keep_wait, { tabId: this.PageStore.currentTabId })
    this.PageStore.setPageLoadingTooLong(false)
  }

  created() {}
  mounted() {
    track.record(this.$ajax)
    this.PageStore.init('live-group')
  }
  destroyed() {}
}
