



















import { Component, Vue, Prop } from 'vue-property-decorator'
import HeaderImage from '../../../components/headerImage/index.vue'

@Component({
  components: { HeaderImage }
})
export default class UserGroupComp extends Vue {
  @Prop({}) dataSource

  get collageHand() {
    return this.dataSource.mainPhoto && this.dataSource.impactPhoto
      ? this.$store.$storeGroup.collageHandSuccessUrl
      : this.$store.$storeGroup.collageHandUrl
  }
  test() {}

  toGroupDetail() {
    if (this.dataSource.computedDataSource.isNotPay) return this.$msg('请先完成支付')
    if (this.dataSource.computedDataSource.isCancelled) return this.$msg('订单已取消')

    this.$router.push({
      name: 'groupbuy-groupNo',
      params: { groupNo: this.dataSource.groupNo }
    })
  }

  created() {}
}
