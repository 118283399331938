export enum EnumPublicStatus {
  Expectation = '敬请期待',
  Wait = '即将开播',
  Carry = '直播中',
  End = '直播结束',
  Playback = '观看回放'
}

export enum LastLoginType {
  /**
   * 密码登录
   */
  Password = 1,
  /**
   * 验证码登录
   */
  SMSCode = 2,
  /**
   * 微信登录
   */
  Wechat = 3
}