import { tSParenthesizedType } from '@babel/types'
import { EnumApiOrder } from '@xt/client/api'
import lodash from 'lodash'
import { BindAll } from 'lodash-decorators'
import { EnumApiProduct } from '../../api'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { Pagination } from '../basics/pagination'
import Product from '../basics/product'

@BindAll()
export class ControllerProduct {
  constructor(protected $ajax: AjaxBasics) {}

  Pagination = new Pagination<Product>(this.$ajax, {
    url: EnumApiProduct.ProductList,
    key: 'id',
    lastParamName: 'lastProductId',
    lastKey: 'origin.id',
    onMapValues: this.onMapValues
  })

  onMapValues(res) {
    let data = lodash.map(res, item => {
      return new Product(item)
    })
    return lodash.merge(res, { dataSource: data })
  }

  /**
   * 通过商品ID获取商品信息
   */
  async getProductDetailById(productId: number): Promise<Product | false> {
    let origin: any = await this.$ajax.get(EnumApiProduct.productDetailByProductId, { productId })
    if (origin.id) return new Product(origin)
    return false
  }

  /**
   * 通过商品类型及业务ID获取商品信息
   */
  async getProductDetailByTypeAndBizId(productType: number, bizId: number): Promise<Product | false> {
    let origin: any = await this.$ajax.get(EnumApiProduct.productDetail, { productType, bizId })
    if (origin.id) return new Product(origin)
    return false
  }

  /**
   * 获取商品协调信息
   */
  async getProductAgreementById(productId: number) {
    return await this.$ajax.get(EnumApiProduct.ProductAgreement, { productId })
  }
}
export default ControllerProduct
