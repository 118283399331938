

































































































import { Component, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import LazyImg from '@/components/lazyImg/index.vue'

@Observer
@Component({
  // name: "PageStationery",
  // 每次进入页面都会调用
  async fetch(ctx) {
    // ctx.store.$storeStationery.onGetTypelist();
    // 更新用户信息 获取桐板儿
    ctx.store.$storeUser.onUpdatingUserInfo()
  },
  async asyncData(ctx) {
    await ctx.store.$storeOrder.Details.onLoading({ orderNo: ctx.query.no })
  },
  components: { LazyImg }
})
export default class PageView extends Vue {
  /**
   * 支付
   * @readonly
   * @memberof MixinsCoursePayment
   */
  get PageStore() {
    return this.$store.$storeOrder.Details
  }
  get orderNo() {
    return this.$route.query.no
  }
  get isSexStage() {
    return this.PageStore.dataSource.productId === 534
  }
  groupImg: any = []
  deadline = this.moment().add(5, 'seconds').valueOf()
  onFinish() {
    if (this.$store.$global.dev) {
      return this.$msg('本地不跳转')
    }
    this.$router.replace({
      name: 'course-id',
      params: { id: this.PageStore.dataSource.courseId }
    })
  }
  toMyOrder() {
    this.$router.push({
      name: 'my-order'
    })

    // 6.30
    // this.$store.$storeUser.onGetUniformGift()
  }
  toMyClass() {
    this.$router.push({
      name: 'my'
    })

    // 6.30
    // this.$store.$storeUser.onGetUniformGift()
  }
  groupStatus = 0
  toMyGroup() {
    let groupNo = this.PageStore.dataSource.groupNo
    let productId = this.PageStore.dataSource.productId
    window.location.href = `/groupbuy/${groupNo}`
  }

  async mounted() {
    this.$store.$storeUser.onUpdatingUserInfo()
    try {
      if (!this.PageStore.dataSource.showAssistantUrlFlag) return
      // 获取团购加群二维码
      let res: any = await this.$store.$storeOrder.addGroupImg({ productId: this.PageStore.dataSource.productId })
      this.groupImg = res
    } catch (error) {}
  }
  updated() {}
  destroyed() {}
}
