



























import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator'
import lodash from 'lodash'

import { EnumCourseState } from '@xt/client/enums/course'
import { AjaxBasics } from '@xt/client'

@Component({
  components: {}
})
export default class PageView extends Vue {
  @Prop() dataSource
  // 需要过滤 至灰
  @Prop({ default: true }) filter

  // @Prop({ default: false }) give;
  get singleList() {
    return this.dataSource.singles
  }
  // 赠课 无视
  getClassStr(item) {
    // console.log('getClassStr item ~',item);
    if (this.filter) {
      return { 'xt-cid-composition-img-filter': item.isHasOwn }
    }
    return ''
  }
  getState(item) {
    // if (item.isHasOwn) {
    if (item.isHasOwn) {
      return '已报名'
    }
    // return item.state;
    return this.onInitState(item)
  }
  getName(item) {
    return item.original.productName
  }
  onInitState(item) {
    let signUpEndTime = item.signUpEndTime
    let learnEndTime = item.learnEndTime
    let learnStartTime = item.learnStartTime
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    if (item.publishStatus === 3) {
      // 当前时间大于转报结束时间且课程结束则 已结课
      if (currentTime >= learnEndTime) {
        return '已结课'
      }
      // 当前时间大于转报结束时间且课程未开启则 转报结束
      if (currentTime < learnStartTime) {
        return '未开课'
      }
      // 当前时间大于转报结束时间且大于报名结束时间且课程已开启则 开课中
      if (currentTime >= learnStartTime && currentTime <= learnEndTime) {
        return '开课中'
      }
      return
    }
    if (!item.isCanSignUp) {
      return '报名未开始'
    } else {
      // 当前时间大于转报结束时间且课程结束则 已结课
      if (currentTime >= learnEndTime) {
        return '已结课'
      }
      // 当前时间大于报名开始时间且小于报名结束时间则  我要报名
      if (currentTime <= signUpEndTime && currentTime < learnStartTime) {
        return '正在报名'
      }
      // 当前时间大于转报结束时间且课程未开启则 转报结束
      if (currentTime >= signUpEndTime && currentTime < learnStartTime) {
        return '未开课'
      }
      // 当前时间大于转报结束时间且大于报名结束时间且课程已开启则 开课中
      if (currentTime >= learnStartTime && currentTime <= learnEndTime) {
        return '开课中'
      }
    }
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
