<template>
  <div
    @click="toDetail"
    @mouseenter="click"
    :class="`list-content-item ${current === index && 'list-content-item-current'} ${index === 0 ? 'first' : ''}`"
  >
    <div class="signuped" v-if="courseHasbuy()">已报名</div>
    <!-- <div class="lookDetail" v-if="index === current" >单阶报名</div> -->
    <div class="lookDetail" v-if="signupOpened">单阶报名</div>
    <LazyImg className="list-content-item-cover" :src="ossProcessImg(dataSource.cover, 232)" />
    <div class="content">
      <div class="list-content-item-title">
        {{ dataSource.title }}
      </div>
      <div class="list-content-item-vice">{{ dataSource.vice }}</div>
      <div class="list-content-item-price">
        <span class="price">{{ dataSource.price / 100 }}</span>
        <span class="count">元/共{{ dataSource.count }}课</span>
        <span :class="['status', `status${btn().color}`]" v-html="btn().text"></span>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import LazyImg from '@/components/lazyImg/index.vue'
import { AjaxBasics } from '@xt/client'
import moment from 'moment'
import lodash from 'lodash'
import { Course } from '@xt/client/entities/basics/course'
@Component({
  components: {
    LazyImg
  }
})
export default class BMYX extends Vue {
  @Prop({}) current
  @Prop({}) index
  @Prop({}) dataSource
  @Emit('onClick')
  click() {}
  /**
   * 跳转到详情页
   */
  toDetail() {
    window.open(`/commodity/${this.dataSource.productId}`)
  }

  data() {
    return {
      countdownText: ''
    }
  }

  interval = null
  // 课程下的倒计时是否已经结束，用来控制lookDetail的显示
  isTimerOver = false

  get convertOpened() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    return currentTime > this.dataSource.signupEndTime && currentTime < this.dataSource.convertEndTime
  }

  get signupOpened() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    return (
      currentTime >= this.dataSource.signupStartTime &&
      currentTime < this.dataSource.signupEndTime &&
      // 倒计时还在走
      !this.isTimerOver
    )
  }

  get courseOpened() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    return currentTime >= this.dataSource.courseStartTime && currentTime < this.dataSource.courseEndTime
  }

  courseHasbuy() {
    return lodash.includes(Course.UserHasbuy, this.dataSource.id)
  }
  countdown() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    // 若报名未结束且报名开始则进行报名倒计时
    if (currentTime > this.dataSource.signupStartTime && currentTime < this.dataSource.signupEndTime) {
      return 1
    }
    // 不需要倒计时
    return 0
  }

  btn() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    // 当前时间大于转报结束时间且课程结束则 已结课
    if (currentTime >= this.dataSource.courseEndTime) {
      return { text: '已结课', color: '-black' }
    }
    // 当前时间大于转报结束时间且大于报名结束时间且课程已开启则 开课中
    if (currentTime >= this.dataSource.signupEndTime && currentTime >= this.dataSource.courseStartTime) {
      return { text: '开课中', color: '-yellow' }
    }
    // 当前时间大于转报结束时间且课程未开启则 转报结束
    if (currentTime >= this.dataSource.signupEndTime && currentTime <= this.dataSource.courseStartTime) {
      return { text: '报名结束', color: '-red' }
    }
    // 当前时间大于报名开始时间小于报名结束时间
    if (currentTime < this.dataSource.signupEndTime && currentTime >= this.dataSource.signupStartTime) {
      return { text: this.countdownText, color: '-red' }
    }
    // 默认
    return { text: this.countdownText, color: '-red' }
  }

  get moment() {
    return moment
  }

  showCourseTime() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    return currentTime >= this.dataSource.convertEndTime && currentTime >= this.dataSource.signupEndTime
  }

  countdownInterval(endtime) {
    this.computed(endtime)
    this.interval = setInterval(() => {
      this.computed(endtime)
    }, 1000)
  }

  computeDay(currentTime, endtime) {
    return Math.floor(this.moment(endtime).diff(currentTime, 'hour') / 24)
  }

  computed(endtime) {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    let diff = endtime - currentTime
    if (diff <= 0) {
      clearInterval(this.interval)
      this.isTimerOver = true
      if (currentTime < this.dataSource.convertEndTime) {
        return this.countdownInterval(this.dataSource.convertEndTime)
      } else {
        return (this.countdownText = '')
      }
    }

    if (diff > 24 * 60 * 60 * 1000) {
      this.countdownText = (this.countdown() == 2 ? '转报' : '报名') + '剩余 ' + this.computeDay(currentTime, endtime) + '天'
    } else {
      return (this.countdownText =
        (this.countdown() == 2 ? '转报' : '报名') +
        '剩余 ' +
        `<div style="display: inline-block;width:${this.$px2rem(55)}">` +
        moment(diff).utcOffset(0).format('HH:mm:ss') +
        '</div>')
    }
  }

  mounted() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    if (this.countdown() == 1) this.countdownInterval(this.dataSource.signupEndTime)
    else if (currentTime < this.dataSource.signupStartTime)
      this.countdownText = '报名时间：' + moment(this.dataSource.signupStartTime).format('YYYY.MM.DD')
  }
  destroyed() {
    clearInterval(this.interval)
  }
}
</script>
<style lang="less" scoped>
.list-content-item {
  position: relative;
  width: 750px;
  height: 160px;
  border-radius: 8px;
  background-color: #f2ede5;
  box-sizing: border-box;
  margin-bottom: 30px;

  &-current {
    background-color: #fff;
  }

  &.first {
    margin-top: 40px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .signuped {
    position: absolute;
    left: 4px;
    top: 20px;
    width: 66px;
    height: 28px;
    border-radius: 0 14px 14px 0;
    background-color: rgba(0, 0, 0, 0.4);
    font-size: 12px;
    z-index: 1;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .lookDetail {
    position: absolute;
    top: 35px;
    right: 40px;
    // /www/pages/activitys/bmyx/views/course.vue 样式干扰
    left: auto;
    width: 88px;
    height: 26px;
    border-radius: 13px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c37f7f;
  }

  .list-content-item-cover {
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 4px;
    width: 246px;
    height: 152px;
  }

  .content {
    position: absolute;
    left: 276px;
    top: 30px;

    .list-content-item-title {
      font-size: 14px;
      color: #000;
      line-height: 16px;
      margin-bottom: 8px;
    }

    .list-content-item-vice {
      font-size: 12px;
      line-height: 14px;
      color: #999999;
      margin-bottom: 58px;
    }

    .list-content-item-price {
      display: flex;
      align-items: center;
      width: 424px;
      line-height: 18px;
      .price {
        font-size: 18px;
        color: #181c25;
        font-weight: 500;
      }

      .count {
        font-size: 12px;
        color: #181c25;
        font-weight: 500;
      }

      .status {
        margin-right: 0;
        margin-left: auto;
        font-size: 12px;

        &-red {
          color: #ba5a5a;
        }
        &-yellow {
          color: #ad8153;
        }
        &-black {
          color: #888888;
        }
      }
    }
  }
}
</style>
