import { observable, computed, action } from 'mobx'
import { create, persist } from 'mobx-persist'
import { Subject, Subscription } from 'rxjs'
import { AjaxRequest } from 'rxjs/ajax'
import lodash from 'lodash'

export default class EntitiesHome {
  constructor() {}
  /**
   * 轮播图
   * @type {Array<{}>}
   * @memberof EntitiesHome
   */
  @observable
  private _Banners: Array<IBanners> = []
  @computed
  get Banners(): Array<IBanners> {
    return [...this._Banners]
  }
  set Banners(value: Array<IBanners>) {
    this._Banners = value
  }
  /**
   * 首页推荐文章
   */
  @observable
  ArticleRecommend: Array<any> = []
  @observable
  ArticleFirst: Object = {}

  /**
   * 首页推荐商品
   */
  @observable
  ProductsRecommend: Array<any> = []

  @action.bound
  protected setBanners(Banners) {
    this.Banners = Banners
  }

  @action.bound
  protected setProductsRecommend(list) {
    this.ProductsRecommend = list
  }

  @action.bound
  protected setArticleRecommend(list) {
    this.ArticleRecommend = list
  }
  @action.bound
  protected setArticleFirst(data) {
    this.ArticleFirst = data
  }
}
export interface IBanners {
  id: string
  jumpUrl: string
  picUrl: string
}

export interface IWorks {
  author: string
  id: number
  intro: string
  length: number
  like: any
  likeCount: number
  thumbUrl: string
  width: any
}
