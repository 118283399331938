import lodash from 'lodash'
import BrowerLogger from 'alife-logger'
import RootSrore from '~/store/create'

const { $global } = RootSrore
if (!$global.dev && $global.logger) {
  const __bl = BrowerLogger.singleton({
    pid: $global.logger,
    appType: 'web',
    imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
    sendResource: false,
    enableLinkTrace: true,
    behavior: true,
    enableSPA: true,
    useFmp: true,
    enableConsole: true,
    // 添加版本
    release: $global.version,
    disableHook: true,
    // environment
    // 环境字段，取值为：prod、gray、pre、daily和local，其中：
    // prod表示线上环境。
    // gray表示灰度环境。
    // pre表示预发环境。
    // daily表示日常环境。
    // local表示本地环境。
    environment: 'prod'
  })
  __bl.setConfig({
    setUsername: () => {
      return lodash.get(RootSrore.$storeUser.UserInfo, 'id', null)
    }
  })

  try {
    // @ts-ignore
    const uap = new UAParser()
    __bl.api(
      'debug.worker.support',
      true,
      0,
      200,
      JSON.stringify({
        worker: 'Worker' in window,
        sharedWorker: 'SharedWorker' in window,
        serviceWorker: 'serviceWorker' in navigator,
        pathname: location.pathname,
        parser: uap.getResult(),
        broadcast: 'BroadcastChannel' in window,
        from: 'pc'
      })
    )
  } catch (e) {
    __bl.api(
      'debug.worker.support.fail',
      true,
      0,
      200,
      JSON.stringify({
        worker: 'Worker' in window,
        sharedWorker: 'SharedWorker' in window,
        serviceWorker: 'serviceWorker' in navigator,
        pathname: location.pathname,
        broadcast: 'BroadcastChannel' in window,
        e: (e as Error).message,
        from: 'pc'
      })
    )
  }

  console.log('LENG: BrowerLogger', __bl)
  let event = new Event('armsloaded', { bubbles: false })
  document.dispatchEvent(event)
}
