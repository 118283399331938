















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import WxImgPop from '../../../components/wxImgPop/index.vue'
import Reply from './reply.vue'

@Component({
  components: { Reply, WxImgPop }
})
export default class PageView extends Vue {
  @Prop({ default: 'top' }) timePosition
  @Prop({ default: true }) loading
  @Prop({ default: 'key' }) rowKey
  @Prop({ default: [] }) dataSource: Array<{
    id: number
    bizType: number
    // 标题
    title: string
    // 课程id
    courseId?: number
    // 课程名字
    courseName?: string
    // 内容
    content: string
    // 时间
    time: string
    // 是否晒出
    isSun: Boolean
    // 是否被助教回复
    isReply: Boolean
    // 回复助教名字
    replyName: string
    // 图片
    imgs: any[]
    // 是否展示删除作业
    showDeleteHome: Boolean
  }>
  dayIsVisibleGolearn: boolean = false
  exhibitionIsVisibleGolearn: boolean = false

  goCourse(item) {
    switch (item.bizType) {
      case 1:
        return this.$router.push({
          name: 'course-id',
          params: {
            id: item.courseId
          }
        })
      case 2:
        return (this.exhibitionIsVisibleGolearn = true)
      case 3:
        return (this.dayIsVisibleGolearn = true)
      default:
    }
  }

  /**
   * 晒作业
   */
  async onSunWork(item) {
    this.$emit('onSunWork', item.id)
  }

  /**
   * 删除作业
   */
  async onDelWork(item) {
    this.$emit('onDelWork', item.id)
  }
  /**
   * 回复助教
   */
  async onReply({ event, id, toCommentId }) {
    this.$emit('onReply', { event, id, toCommentId })
  }

  mounted() {}
  updated() {}
  destroyed() {}
}
