




















import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import List from './views/list.vue'
import { MomentSourceType } from '@xt/client/enums'

@Observer
@Component({
  scrollToTop: true,
  components: { List }
})
export default class PageView extends Vue {
  // 回复
  reply = {}
  get Homework() {
    return this.$store.$storeHomework
  }

  get PageStore() {
    return this.$store.$my
  }
  get Pagination() {
    return this.PageStore.MySunWork
  }
  get body() {
    return {
      sourceTypes: [MomentSourceType.SunDrying].toString()
    }
  }

  /**
   * 回复
   */
  onReply(data) {
    if (data) {
      try {
        this.$InspectUser()
        this.reply = data
      } catch (error) {}
    } else {
      this.reply = {}
    }
  }

  /**
   * 作业点赞
   */

  async onLikes(item) {
    try {
      await this.Pagination.onLikes(item)
    } catch (error) {
      this.$msg(error)
    }
  }

  /**
   * 删除晒出的作业
   */
  async onDelete(item) {
    try {
      await this.Pagination.onDelWork(item.id)
      this.onReply({})
      this.$msg(this.$EnumMessage.delete_success, 'success')
    } catch (error) {
      this.$msg(error)
    }
  }

  mounted() {}
  updated() {}
  destroyed() {
    this.Pagination.onReset()
  }
}
