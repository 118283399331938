




























import { Component, Prop, Vue, Provide, Emit } from 'vue-property-decorator'
import lodash from 'lodash'
import { Observer } from 'mobx-vue'
@Observer
@Component({
  components: {}
})
export default class extends Vue {
  get PageStore() {
    return this.$store.$storeUser
  }
  get locale() {
    return this.$EnumLocaleLinks
  }
  get formOptions() {
    return {
      validDevice: true,
      validExist: false,
      phone: this.phone,
      codeType: this.codeType,
      label: this.label,
      btnText: '绑定'
    }
  }
  phone = true
  codeType = 3
  label = '手机'
  tabPane = [
    { key: 1, title: '手机绑定' },
    { key: 2, title: '邮箱绑定' }
  ]
  activeKey = 1
  tabsChange(activeKey) {
    this.activeKey = activeKey
    if (lodash.eq(activeKey, '1')) {
      this.phone = true
      this.label = '手机'
      this.codeType = 3
    } else {
      this.phone = false
      this.label = '邮箱'
      this.codeType = 4
    }
  }
  async onSubmit(model) {
    if (this.phone) {
      await this.PageStore.onBindPhone({
        phoneNum: model.device,
        verifyCode: model.verifyCode
      })
    } else {
      await this.PageStore.onBindEmail({
        email: model.device,
        verifyCode: model.verifyCode
      })
    }
    this.$msg(this.$EnumMessage.bind_success, 'info')
    this.PageStore.onToggleVisible(false)
    window.location.reload()
  }
  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
