/**
 * @author wangsl
 */
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component
export default class UserGroup extends Vue {
  @Prop({}) mainHeader: string
  @Prop({}) viceHeader: string
  @Prop({}) showNickName: boolean
  @Prop({}) mainNickName: string
  @Prop({}) viceNickName: string

  get collageHand() {
    return this.mainHeader && this.viceHeader ? this.$store.$storeGroup.collageHandSuccessUrl : this.$store.$storeGroup.collageHandUrl
  }

  created() {}
}
