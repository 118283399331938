/*
 * @Author: Erlin
 * @CreateTime: 2020-09-01 18:28:10
 * @LastEditors: Erlin
 * @LastEditTime: 2020-10-14 14:41:42
 * @Description: 同学作业
 */
import { BindAll } from 'lodash-decorators'
import { EnumApiHome } from '../../api'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { Pagination } from '../basics/pagination'
import { EnumMessage } from '../../languages'

/**
 * 同学作业
 * @export
 * @class ControllerWorks
 * @extends {Pagination<any>}
 */
@BindAll()
export class ControllerWorks extends Pagination<any> {
  constructor($ajax: AjaxBasics) {
    super($ajax, {
      url: EnumApiHome.WorksList,
      key: 'id',
      defaultPageSize: 20,
      onMapValues: item => {
        return { dataSource: item }
      }
    })
  }
  /**
   * 点赞
   * @param data
   */
  async onLikes(data) {
    try {
      if (data.like) {
        throw EnumMessage.like_error
      }
      await this.$ajax.put(EnumApiHome.WorksLike, {
        id: data.id,
        likeType: true
      })
      this.onUpdate(data, old => {
        old.likeCount++
        old.like = true
        return old
      })
    } catch (error) {
      throw error
    }
  }
}
export default ControllerWorks
