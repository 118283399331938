/*
 * @Author: Erlin
 * @CreateTime: 2020-10-14 19:41:44
 * @LastEditors: Erlin
 * @LastEditTime: 2020-10-17 15:41:42
 * @Description: 图片
 */
import RootSrore from './create'
const { $global } = RootSrore
export default {
  /** 首页文章固定图片 */
  about1: `${$global.static}/about/about-1.png`,
  about2: `${$global.static}/about/about-2.png`,
  about3: `${$global.static}/about/about-3.png`,
  about4: `${$global.static}/about/about-4.jpg`,
  about5: `${$global.static}/about/about-5.jpg`,

  alert: `${$global.static}/images/all_alert.png`,

  /** 画画课手指 */
  finger: `${$global.static}/icon/finger-icon.png`,
  beian: `${$global.static}/icon/beian.png`,
  /** 全阶课手指 */
  fullFinger: `${$global.static}/icon/finger.png`,
  fullFingerActive: `${$global.static}/icon/finger-active.png`,
  /** footer 公众号新浪微博 */
  wx: `${$global.static}/images/wx_code.jpeg`,
  xl: `${$global.static}/images/xl_code.jpeg`,
  title: `${$global.static}/images/title.png`,
  pc_login_logo: `${$global.static}/logo/pc_login_logo_sel_slogn_im.png`,
  logo: `${$global.static}/images/logo.svg`,
  null: `${$global.static}/images/null.png`,
  uploadError: `${$global.static}/images/upload-error.png`,

  // seals
  order_handle_no_suc_im: `${$global.static}/images/order/order_handle_no_suc_im.png`,
  order_handle_suc_im: `${$global.static}/images/order/order_handle_suc_im.png`,
  order_not_claimed_im: `${$global.static}/images/order/order_not_claimed_im.png`,
  order_received_im: `${$global.static}/images/order/order_received_im.png`,

  logo_ten_year: `${$global.static}/images/logo-ten-year.png`,
  photo_ten_year: `${$global.static}/images/photo-ten-year.png`,
  photo_ten_year_svg: `${$global.static}/images/photo-ten-year.svg`,

  /** 个人信息 */
  pc_mine_bg_ele: `${$global.static}/images/userCenter/pc/pc_mine_bg_ele.png`,
  pc_mine_bs_bgele: `${$global.static}/images/userCenter/pc/pc_mine_bs_bgele.png`,
  pc_mine_tb_bgele: `${$global.static}/images/userCenter/pc/pc_mine_tb_bgele.png`,
  pc_mine_attend_class_im: `${$global.static}/images/userCenter/pc/pc_mine_attend_class_im.png`,
  pc_mine_order_im: `${$global.static}/images/userCenter/pc/pc_mine_order_im.png`,

  /** 小程序入口 */
  weapp_entrance: `${$global.static}/images/weapp_2021_entrance.png`,
  giftIcon: `${$global.static}/icon/gift-icon.png`,
  questionIcon: `${$global.static}/icon/question-icon.png.png`,
  // 回顾
  review: `${$global.static}/course/review.png`,
  /** 视频播放背景图片 */
  videoBg: `${$global.static}/background/video-bg.png`,

  /** payment支付页面 */
  wxPay: `${$global.static}/images/wx-pay.png`,
  wxPayActive: `${$global.static}/images/wx-pay-active.png`,
  alPay: `${$global.static}/images/al-pay.png`,
  alPayActive: `${$global.static}/images/al-pay-active.png`,

  /** 侧边栏微信客服返回顶部 */
  wxLogo: `${$global.static}/icon/wx-logo.png`,
  service: `${$global.static}/icon/service.png`,
  backTop: `${$global.static}/icon/back-top.png`,
  /** 错误图片 */
  error: `${$global.static}/images/error.png`,
  /** 加载动画 */
  loading: `${$global.static}/images/loading.gif`,
  /** 首页文房图片 */
  stationery: `${$global.static}/images/stationery.png`,
  /** 助教 */
  assistant: `${$global.static}/images/assistant.jpg`,
  /** 协议icon */
  protocol: `${$global.static}/images/protocol-icon.png`,
  /** 加群qrcode */
  groupQrcode: `${$global.static}/images/group-qrcode.png`,
  groupQrcodeFive: `${$global.static}/images/group-qrcode-five.jpg`,
  rumenJichuQrcode: `${$global.static}/images/qrcode/2022_rumen_jichu.png`,
  /**  日课 **/
  dayClassTong: `${$global.static}/images/tong_hd.jpg`, //投票成功，童的二维码
  wxChart: `${$global.static}/images/clock_in_im.png`,
  /** 日课弹窗 **/
  jinyu_im: `${$global.static}/images/jinyu_im.png`,

  day_class_guide_im: `${$global.static}/images/day_class_guide_im.png`,

  exhibition_to_prepare_show_im: `${$global.static}/images/exhibition_to_prepare_show_im.png`,

  /* 积分 */
  bishan_ic: `${$global.static}/points/bishan_ic.png`,
  bishan_ic_gray: `${$global.static}/points/bishan_ic_gray.png`,
  copy_ic: `${$global.static}/points/copy_ic.png`,
  tongban_ic: `${$global.static}/points/tongban_ic.png`,
  integral_icpc: `${$global.static}/points/integral_icpc.png`,
  details_ic: `${$global.static}/points/details_ic.png`,
  erweima: `${$global.static}/points/erweima.png`,
  ticket_ic: `${$global.static}/points/ticket_ic.png`,
  _integral_binding_im: `${$global.static}/points/_integral_binding_im.png`,
  wenfang_ticket: `${$global.static}/points/wenfang_ticket.png`,
  xt_wenfang: `${$global.static}/points/xt_wenfang.png`,
  integral_binding_pcim: `${$global.static}/points/integral_binding_pcim.png`,

  about_top_ele: `${$global.static}/images/about_top_ele.png`,
  about_cen_ele: `${$global.static}/images/about_cen_ele.png`,
  about_bot_ele_all: `${$global.static}/images/about_bot_ele_all.png`,
  wf_jean_bg_ele: `${$global.static}/images/wf_jean_bg_ele.png`,
  about_xt_im01: `${$global.static}/images/about_xt_im01.png`,

  about_bushan_im: `${$global.static}/images/about_bushan_im.png`,
  about_brush_im: `${$global.static}/images/about_brush_im.png`,
  wx_code: `${$global.static}/images/wx_code.jpeg`,
  about_xt_im02: `${$global.static}/images/about_xt_im02.png`,
  lx: `${$global.static}/images/lx.png`,
  about_linxi_im: `${$global.static}/images/about_linxi_im.png`,

  xl_code: `${$global.static}/images/xl_code.jpeg`,
  wf_logo_im: `${$global.static}/images/wf_logo_im.png`,
  wf_monkey_bg_ele: `${$global.static}/images/wf_monkey_bg_ele.png`,
  wf_monkey_hand_ele: `${$global.static}/images/home_wf_hand_ele.png`,
  wf_monkey_ele: `${$global.static}/images/wf_monkey_ele.png?1`,
  wf_mp: `${$global.static}/images/wf_mp.png`,
  xt_wf_pic: `${$global.static}/images/wf_banner_im.png`,
  wecom_temp_5146396cdf04325322b84fbd80eab542: `${$global.static}/images/wecom-temp-5146396cdf04325322b84fbd80eab542.jpg`,
  classmate_top_ele: `${$global.static}/images/classmate_top_ele.png`,
  classmate_cen_ele: `${$global.static}/images/classmate_cen_ele.png`,
  share_left_ele: `${$global.static}/images/share_left_ele.png`,
  share_right_ele: `${$global.static}/images/share_right_ele.png`,
  home_banner_im: `${$global.static}/images/home_banner_im.png`,
  hotIcon: `${$global.static}/images/home/fire_stroke_im.png`,
  home_collage_im: `${$global.static}/images/home/home_collage_im.png`,
  home_list_collage_pcim: `${$global.static}/images/home/home_list_collage_pcim.png`,
  fire_stroke_im: `${$global.static}/images/home/fire_stroke_im.png`,

  public_class_bot_ele: `${$global.static}/images/public_class_bot_ele.png`,
  public_class_top_ele: `${$global.static}/images/public_class_top_ele.png`,
  about_lx_im01: `${$global.static}/images/about_lx_im01.png`,
  about_lx_im02: `${$global.static}/images/about_lx_im02.png`,
  about_lx_im03: `${$global.static}/images/about_lx_im03.png`,
  xt_logo: `${$global.static}/images/xt-logo.png`,
  xt_logo_green: `${$global.static}/images/xt_logo_green.png`,
  logo_ten_year_ten: `${$global.static}/images/logo_imsel_10th .png`,
  th_logo_imnor10: `${$global.static}/images/logo_imnor_10th.png`,
  nav_day_class_im: `${$global.static}/images/svg/nav_day_class_im.svg`,

  nav_exhibition_im: `${$global.static}/images/svg/nav_exhibition_im.svg`,
  wx_rk_code: `${$global.static}/images/qrcode/wx_rk_code.jpg`,
  lx_qrcode: `${$global.static}/images/qrcode/lx_qrcode.jpg?1`,
  // logo_no_slogn_im: `${$global.static}/images/logo_no_slogn_im.png`,
  // logo_sel_slogn_im: `${$global.static}/images/logo_sel_slogn_im.png`,
  pc_logo_no_slogn_im: `${$global.static}/logo/pc_logo_no_slogn_im.png`,
  pc_logo_sel_slogn_im: `${$global.static}/logo/logo_sel_im.png`,
  live2_bg_ele: `${$global.static}/images/live_%20bg_ele02.png`,
  live1_bg_ele: `${$global.static}/images/live_%20bg_ele01.png`,
  home_exh_applet_im_bg: `${$global.static}/images/home_exh_applet_im_bg.jpg`,
  live_all_exh_course_ele01: `${$global.static}/course/detail/live_all_exh_course_ele01.png`,
  live_bg_01: `${$global.static}/images/activitys/paint_upgrade_2024/pc/live1-bg.png`,
  all_exhpc_pop_topim: `${$global.static}/course/detail/all_exhpc_pop_topim.png`,
  home_all_course_ele: `${$global.static}/course/detail/home_all_course_ele.png`,
  home_all_course_ele_v2: `${$global.static}/images/activitys/paint_basic_2024/pc/home_all_course_ele_v2.png`,
  popu_apply_all_im: `${$global.static}/images/popu_apply_all_im.jpg`,
  popu_apply_entrance_pc: `${$global.static}/images/popu_apply_entrance_pc_v2.gif`,
  cancellation_pc_bg: `${$global.static}/images/logout/cancellation_pc_bg.png`,
  cancel_pcele_line: `${$global.static}/images/logout/cancel_pcele_line.png`,
  cancellation_pc_bg01: `${$global.static}/images/logout/cancellation_pc_bg01.png`,

  map_write_text_1: `${$global.static}/images/home/map/map_write_text_1%402x.png`,
  map_write_text_0: `${$global.static}/images/home/map/map_write_text_0%402x.png`,
  map_write_location_ic: `${$global.static}/images/home/map/map_write_location_ic%402x.png`,
  map_write_content: `${$global.static}/images/home/map/map_write_content%402x.png`,
  map_exhibition_content: `${$global.static}/images/home/map/map_exhibition_content%402x.png`,
  map_draw_content: `${$global.static}/images/home/map/map_draw_content%402x.png`,
  map_day_class_content: `${$global.static}/images/home/map/map_day_class_content_v2.png`,

  map_dragonfly_ele: `${$global.static}/images/home/map/map_dragonfly_ele%402x.png`,
  map_bg_ele03: `${$global.static}/images/home/map/map_bg_ele03%402x.png`,
  map_bg_ele01: `${$global.static}/images/home/map/map_bg_ele01%402x.png`,

  /** 去上课 */
  all_exhpc_pop_topim_go: `${$global.static}/images/goCourse/all_exhpc_pop_topim.png`,
  calendar_live_ic: `${$global.static}/images/goCourse/calendar/calendar_live_ic.png`,
  calendar_have_a_class_im: `${$global.static}/images/goCourse/calendar/calendar_have_a_class_im.png`,
  calendar_past_have_a_class_im: `${$global.static}/images/goCourse/calendar/calendar_past_have_a_class_im.png`,
  login_agree_nor: `${$global.static}/images/goCourse/pc/login_agree_nor.png`,
  login_agree_sel: `${$global.static}/images/goCourse/pc/login_agree_sel.png`,
  assistant_one_im: `${$global.static}/images/goCourse/pc/assistant_one_im.png`,
  assistant_two_im: `${$global.static}/images/goCourse/pc/assistant_two_im.png`,
  assistant_bottom_im: `${$global.static}/images/goCourse/pc/assistant_bottom_im.png`,
  intergral_exchange02_bgpc: `${$global.static}/points/intergral_exchange02_bgpc.png`,
  attend_class_empty_none_pcim: `${$global.static}/images/goCourse/pc/attend_class_empty_none_pcim.png`,

  /** 学习日历 */
  live_today_course_ic: `${$global.static}/images/goCourse/calendar/live_today_course_ic.png`,

  /**赠课 */
  give_class_no: `${$global.static}/images/give_class_no.png`,
  give_class_no_new: `${$global.static}/images/give_class_no_new.png`,

  /** 二维码 **/
  exhibitionQrCode: `https://static.xuantong.cn/images/qrcode/xt_show_weapp_code.png`, //展览辅导课
  goCourse: `${$global.static}/images/goCourse/lQLPDhsy1XXr9qvNAZDNAZCwyihLIq80JJ8CI_WXUsBxAA.png`, //教练日课

  /** 支付成功 */
  payment_succeeded_tex_im_pc: `${$global.static}/images/payment/pc/payment_succeeded_tex_im_pc.png`,
  payment_succeeded_tex_im_pc_sex: `${$global.static}/images/payment/pc/payment_succeeded_tex_im_sex.png`,
  payment_succeeded: `${$global.static}/images/payment/payment_succeeded.gif`,
  payment_succeeded_bg_ele: `${$global.static}/images/payment/pc/payment_succeeded_bg_ele.png`,
  payment_im_succeeded_pc_dj: `${$global.static}/images/payment/pc/payment_im_succeeded_pc_dj.png`,
  payment_im_succeeded_pc_qj: `${$global.static}/images/payment/pc/payment_im_succeeded_pc_qj.png`,

  /** 支付页面 */
  alipay_nor_im: `${$global.static}/images/payment/pc/alipay_nor_im.png`,
  alipay_sel_im: `${$global.static}/images/payment/pc/alipay_sel_im.png`,
  gift_coloured_ribbon_pcim: `${$global.static}/images/payment/pc/gift_coloured_ribbon_pcim.png`,
  gift_ropen_sel_pcim: `${$global.static}/images/payment/pc/gift_ropen_sel_pcim.png`,
  gift_rope_sel_pcim: `${$global.static}/images/payment/pc/gift_rope_sel_pcim.png`,
  order_received_pcim: `${$global.static}/images/payment/pc/order_received_pcim.png`,
  wechat_nor_im: `${$global.static}/images/payment/pc/wechat_nor_im.png`,
  wechat_sel_im: `${$global.static}/images/payment/pc/wechat_sel_im.png`,
  payment_choice_nor_ic: `${$global.static}/images/payment/pc/payment_choice_nor_ic.png`,
  payment_choice_sel_ic: `${$global.static}/images/payment/pc/payment_choice_sel_ic.png`,
  gift_icon_im: `${$global.static}/images/payment/pc/gift_icon_im.png`,
  day_class_clock_bg: `${$global.static}/images/payment/pc/day_class_clock_bg.png`,

  payment_choice_nor_ic_new: `${$global.static}/images/payment/pc/payment_choice_nor_ic_new.png`,
  payment_choice_sel_ic_new: `${$global.static}/images/payment/pc/payment_choice_sel_ic_new.png`,
  alipay_nor_im_new: `${$global.static}/images/payment/pc/alipay_nor_im_new.png`,
  alipay_sel_im_new: `${$global.static}/images/payment/pc/alipay_sel_im_new.png`,
  wechat_nor_im_new: `${$global.static}/images/payment/pc/wechat_nor_im_new.png`,
  wechat_sel_im_new: `${$global.static}/images/payment/pc/wechat_sel_im_new.png`,

  gift_surprised_im: `${$global.static}/images/payment/pc/gift_surprised_im.png`,
  payment_all_course_right_bg_ele: `${$global.static}/images/payment/pc/payment_all_course_right_bg_ele.png`,
  payment_course_right_bg_ele: `${$global.static}/images/payment/pc/payment_course_right_bg_ele.png`,
  payment_lotus_left_bg_ele: `${$global.static}/images/payment/pc/payment_lotus_left_bg_ele.png`,
  payment_lotus_right_bg_ele: `${$global.static}/images/payment/pc/payment_lotus_right_bg_ele.png`,
  payment_lotus_left_bottom_bg_ele: `${$global.static}/images/payment/pc/payment_lotus_left_bottom_bg_ele.png`,
  payment_lotus_right_bottom_bg_ele: `${$global.static}/images/payment/pc/payment_lotus_right_bottom_bg_ele.png`,

  loading_gif: `${$global.static}/images/payment/loading.gif`,

  /** 礼物 */
  receive_gift_light_im: `${$global.static}/images/gift/receive_gift_light_im_v2.png`, //背景光照
  receive_gift_box_cent_bgim: `${$global.static}/images/gift/receive_gift_box_cent_bgim.png`,
  receive_gift_box_bot_bgim: `${$global.static}/images/gift/receive_gift_box_bot_bgim.png`,
  receive_gift_ribbon_im: `${$global.static}/images/gift/receive_gift_ribbon_im.png`,
  receive_gift_bowknot_im: `${$global.static}/images/gift/receive_gift_bowknot_im_v2.png`,
  gift_popover_close_ic: `${$global.static}/images/gift/popover_close_ic.png`,

  /** qrcode */
  xt_classroom_gzh: `${$global.static}/qrcode/wx_code.png`,
  xt_daily_mini: `${$global.static}/qrcode/dliay_XCX.png`,
  xt_exhibition_mini: `${$global.static}/qrcode/exhibition_XCX.png`,
  xt_wenfang_gzh: `${$global.static}/qrcode/study_GZH.png`,
  xt_wenfang_mini: `${$global.static}/qrcode/study_XCX.png`,
  xt_wenfang_coupon_mini: `${$global.static}/qrcode/xt_wenfang_coupon.png`,
  wechat_applet_ic: `${$global.static}/qrcode/wechat_applet_ic.png`,

  xt_qrcode_logo: `${$global.static}/qrcode/xt_qrcode_logo.png`,
  xt_show_qrcode_logo: `${$global.static}/qrcode/xt_show_qrcode_logo.jpg`,

  /** 注销账户冷静期 */
  mine_im_cancel_hand: `${$global.static}/images/mine_im_cancel_hand.png`,

  login_popup_agreement: `${$global.static}/agreements/login_popup_agreement.png`,

  manage_im_computer: `${$global.static}/images/my/device/manage_im_computer.png`,
  changeCheck: `${$global.static}/images/my/device/check.png`,

  giveaway_detail_person: `${$global.static}/images/gift/giveaway_detail_person.png`,
  giveaway_detail_qrcode: `${$global.static}/images/gift/giveaway_detail_qrcode.png`,

  device_full_modal_head: `${$global.static}/images/my/device/point.png`,
  all_ic_off_black: `${$global.static}/images/my/all_ic_off_black.png`,
  all_ic_off_white: `${$global.static}/images/my/all_ic_off_white.png`,
  all_ic_off_white_outlined: `${$global.static}/images/my/all_ic_off_white-outlined.png`,

  mine_im_link: `${$global.static}/images/home/header/mine_im_link.png`,
  mine_im_notice: `${$global.static}/images/home/header/mine_im_notice.png`,
  mine_im_link_light: `${$global.static}/images/home/header/mine_im_link_light.png`,
  mine_im_notice_light: `${$global.static}/images/home/header/mine_im_notice_light.png`,
  mine_im_volume: `${$global.static}/images/home/header/mine_im_volume.png`,
  mine_ic_wechat_applet_white: `${$global.static}/images/home/header/mine_ic_wechat_applet_white.png`,
  mine_im_xuantong: `${$global.static}/images/home/header/mine_im_xuantong.png`,
  mine_im_daily: `${$global.static}/images/home/header/mine_im_daily.png`,
  mine_im_exhibition: `${$global.static}/images/home/header/mine_im_exhibition.png`,
  mine_im_study: `${$global.static}/images/home/header/mine_im_study.png`,
  mine_im_xuantong_text: `${$global.static}/images/home/header/mine_im_xuantong_text.png`,
  mine_im_daily_text: `${$global.static}/images/home/header/mine_im_daily_text.png`,
  mine_im_exhibition_text: `${$global.static}/images/home/header/mine_im_exhibition_text.png`,
  mine_im_study_text: `${$global.static}/images/home/header/mine_im_study_text.png`,
  mine_im_xuantong_qrcode: `${$global.static}/images/home/header/mine_im_xuantong_qrcode.png`,
  mine_im_daily_qrcode: `${$global.static}/images/home/header/mine_im_daily_qrcode.png`,
  mine_im_exhibition_qrcode: `${$global.static}/images/home/header/mine_im_exhibition_qrcode.png`,
  mine_im_study_qrcode: `${$global.static}/images/home/header/mine_im_study_qrcode.png`,
  mine_im_empty: `${$global.static}/images/home/header/mine_im_empty.png`,

  /** 微信验证 */
  verify_success: `${$global.static}/images/my/verify_success.png`,
  verify_failure: `${$global.static}/images/my/verify_failure.png`,

  /** 展示互动播放页 */
  gensee_video_play: `${$global.static}/images/gensee/ico_play_btn-v2.png`,
  gensee_direction_change: `${$global.static}/images/gensee/ico_direction_change.png`,
  gensee_expand_circle: `${$global.static}/images/gensee/ico_expand.png`,
  gensee_expand_exit_circle: `${$global.static}/images/gensee/ico_expand_exit.png`,
  gensee_expand: `${$global.static}/images/gensee/ico_expand_v2.png`,
  gensee_question: `${$global.static}/images/gensee/ico_question.png`,
  gensee_remind: `${$global.static}/images/gensee/ico_remind.png`,
  gensee_doc_expand: `${$global.static}/images/gensee/doc-expand-ico.png`,
  gensee_doc_deexpand: `${$global.static}/images/gensee/doc-deexpand-ico.png`,

  paint_basic_2024_header_side: `${$global.static}/images/activitys/paint_basic_2024/pc/header-side-01.png`,

  // 老桐学优惠
  student_welfare_desc_pc: `${$global.static}/images/welfare/student_welfare_desc_pc.png`,
  student_welfare_rule: `${$global.static}/images/welfare/student_welfare_rule.png`,
  student_welfare_tag: `${$global.static}/images/welfare/student_welfare_tag.png`,
  student_welfare_tag_hover: `${$global.static}/images/welfare/student_welfare_tag_hover.png`
}
