/** * wangsl */
<template>
  <div class="course" @click="goShowDetail">
    <div class="course-cover">
      <LazyImg :src="ossProcessImg(dataSource.showCover, 405, 250)" />
    </div>
    <!-- 团购标识 -->
    <div class="group-icon" v-if="dataSource.isGroup == 'true' && btn().hot">
      <LazyImg :src="$images.home_list_collage_pcim" :noBackground="true" />
    </div>

    <div class="course-types">
      <div class="course-types-label">{{ dataSource.showContentType }}</div>
      <div class="course-name">{{ dataSource.showTitle }}</div>
      <!-- <div class="course-types-icon"></div>
      <div class="course-types-text">书画展览</div> -->
    </div>
    <!-- <div class="course-title">{{dataSource.showTitle}}</div> -->
    <div class="course-vice">{{ dataSource.showVice }}</div>
    <div class="course-line"></div>
    <div class="course-wait" v-if="forward"><span>敬请期待～</span></div>
    <div :class="`course-lastline course-lastline${btn().color}`" v-else>
      <div :class="`price-label price-label${btn().color}`">
        <span v-if="dataSource.showPrice" class="price-label-num" v-money="dataSource.showPrice"></span>
        <span v-else class="price-label-num">免费</span>
        <span class="price-label-num">/</span>
        <span class="price-label-count">共{{ dataSource.showCount }}关</span>
      </div>
      <div class="course-lastline-state">
        <LazyImg v-if="btn().hot" :src="ossProcessImg($images.fire_stroke_im, 28, 32)" :noBackground="true" />{{ btn().text }}
      </div>
    </div>
    <!-- 注：这一版存在展览详情页，所以全部课程中的展览需要跳转到展览详情页 -->
    <!-- <div class="show-qrcode">
      <LazyImg :src="ossProcessImg($images.home_exh_applet_im_bg, 360, 424)" :className="`show-qrcode-bg`"/>
      <LazyImg :src="ossProcessImg(selectCode(), 160, 160)" :className="`show-qrcode-im`"/>
      <div class="show-qrcode-text">{{selectText()}}</div>
    </div> -->
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator'
import LazyImg from '@/components/lazyImg/index.vue'
import { AjaxBasics } from '@xt/client'
import moment from 'moment'

@Component({
  components: {
    LazyImg
  }
})
class Index extends Vue {
  // 数据
  @Prop() dataSource

  data() {
    return {
      countdownText: ''
    }
  }

  // 注：这一版存在展览详情页，所以全部课程中的展览需要跳转到展览详情页
  goShowDetail() {
    if (this.forward) return this.$msg('敬请期待~')
    this.$router.push({
      path: `/commodity/${this.dataSource.productId}`
    })
  }

  interval = null

  get forward() {
    return this.dataSource.isLookforward
  }

  countdown() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    if (currentTime < this.dataSource.showSignupEndTime && currentTime >= this.dataSource.showSignupStartTime) {
      return 1
    }
    // 不需要倒计时
    return 0
  }

  selectCode() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    if (currentTime >= this.dataSource.showVoteStartTime) return this.dataSource.miniLookCodeUrl
    return this.dataSource.miniSignupCodeUrl
  }

  selectText() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    if (currentTime >= this.dataSource.showVoteStartTime) return this.dataSource.miniCodeLookText
    return this.dataSource.miniCodeSignupText
  }

  btn() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    if (!this.dataSource.showSignupStartTime) {
      return { text: '即将报名', color: '-red' }
    }
    if (currentTime < this.dataSource.showSignupStartTime) {
      return { text: '报名时间：' + moment(this.dataSource.showSignupStartTime).format('YYYY.MM.DD'), color: '-red' }
    }
    if (currentTime >= this.dataSource.showSignupStartTime && currentTime < this.dataSource.showSignupEndTime) {
      return { text: this.countdownText, color: '-red', hot: true }
    }
    if (currentTime >= this.dataSource.showSignupEndTime && currentTime < this.dataSource.showReadyStartTime) {
      return { text: '报名结束', color: '-red' }
    }
    if (currentTime >= this.dataSource.showReadyStartTime && currentTime < this.dataSource.showReadyEndTime) {
      return { text: '备展中', color: '-green' }
    }
    if (currentTime >= this.dataSource.showReadyEndTime && currentTime < this.dataSource.showVoteStartTime) {
      return { text: '备展结束', color: '-black' }
    }
    if (currentTime >= this.dataSource.showVoteStartTime && currentTime < this.dataSource.showVoteEndTime) {
      return { text: '投票中', color: '-pink' }
    }
    return { text: '观展', color: '-yellow' }
  }

  get moment() {
    return moment
  }

  showCourseTime() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    return currentTime >= this.dataSource.courseConvertEndTime && currentTime >= this.dataSource.courseSignUpEndTime
  }

  countdownInterval(endtime) {
    this.computed(endtime)
    this.interval = setInterval(() => {
      this.computed(endtime)
    }, 1000)
  }

  computeDay(currentTime, endtime) {
    return Math.floor(this.moment(endtime).diff(currentTime, 'hour') / 24)
  }

  computed(endtime) {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    let diff = endtime - currentTime
    if (diff <= 0) {
      clearInterval(this.interval)
      return (this.countdownText = '')
    }
    if (diff > 24 * 60 * 60 * 1000) {
      this.countdownText = '正在报名'
    } else {
      return (this.countdownText = '正在报名')
    }
  }

  mounted() {
    if (this.countdown()) this.countdownInterval(this.dataSource.showSignupEndTime)
  }
}

export default Index
</script>

<style scoped lang="less">
// 注：这一版存在展览详情页，所以全部课程中的展览需要跳转到展览详情页
// .show-qrcode {
//   position: absolute;
//   width: 100%;
//   height: 424px;
//   top: 0;
//   left: 0;
//   z-index: 3;
//   opacity: 0;
//   transition: opacity .65s;
//   &-bg {
//     width: 100%;
//     height: 100%;
//   }
//   &-im {
//     width: 160px;
//     height: 160px;
//     position: absolute;
//     margin: auto;
//     top: 103px; left: 0; right: 0;
//     z-index: 2;
//   }
//   &-text {
//     position: absolute;
//     margin: auto;
//     top: 303px; left: 0; right: 0;
//     z-index: 2;
//     font-size: 18px;
//     color: #1F7074;
//     line-height: 22px;
//     text-align: center;
//   }
// }
.course {
  //border: 1px solid #E8E8E8;
  box-sizing: border-box;
  background-color: white;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  .group-icon {
    position: absolute;
    right: 30px;
    top: 202px;
    width: 40px;
    height: 40px;
    img {
      width: 40px;
      height: 40px;
    }
  }
  &:hover {
    .course-cover {
      img {
        transform: scale(1.1);
      }
    }
    // 注：这一版存在展览详情页，所以全部课程中的展览需要跳转到展览详情页
    // .show-qrcode {
    //   opacity: 1 !important;
    // }
  }
  &-cover {
    width: 100%;
    height: 222px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      user-select: none;
      -webkit-user-drag: none;
      transition: transform 0.5s;
    }
  }
  &-types {
    width: 100%;
    display: flex;
    align-items: center;
    height: 18px;
    margin-top: 20px;
    padding: 0 30px;
    &-label {
      padding: 0 4px;
      height: 18px;
      background: #ad9174;
      border-radius: 2px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      color: #ffffff;
      line-height: 18px;
    }
    &-icon {
      width: 4px;
      height: 4px;
      background: #ad9174;
      border-radius: 50%;
      margin: 0 6px;
    }
    &-text {
      font-size: 12px;
      color: #ad9174;
      line-height: 18px;
    }
    .course-name {
      font-size: 18px;
      line-height: 18px;
      line-height: 18px;
      height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 258px;
      font-weight: 500;
      color: #181c25;
      margin-left: 10px;
    }
  }
  &-title {
    font-size: 18px;
    font-weight: 500;
    color: #181c25;
    line-height: 18px;
    margin-top: 20px;
    padding: 0 30px;
  }
  &-vice {
    margin-top: 10px;
    font-size: 12px;
    color: #888888;
    line-height: 12px;
    padding: 0 30px;
  }
  &-line {
    margin: 0 30px;
    width: 300px;
    height: 1px;
    background: #e8e8e8;
    margin-top: 20px;
  }
  .course-wait {
    margin-top: 20px;
    width: 100%;
    height: 38px;
    line-height: 38px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #888888;
  }
  &-lastline {
    display: flex;
    height: 38px;
    align-items: center;
    padding: 0 30px;
    margin-top: 20px;
    .price-label {
      height: 38px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 0 12px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        background: white;
        border-radius: 50%;
        margin: auto;
        left: -4px;
        top: 0;
        bottom: 0;
        z-index: 5;
      }
      &::before {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        background: white;
        border-radius: 50%;
        margin: auto;
        right: -4px;
        top: 0;
        bottom: 0;
        z-index: 5;
      }
      &-num {
        font-size: 20px;
        font-weight: 500;
      }
      &-count {
        font-size: 12px;
        font-weight: 500;
        transform: translateY(3px);
      }
      &-red {
        background: fade(#ba5a5a, 10%);
      }
      &-yellow {
        background: fade(#ad8153, 10%) !important;
      }
      &-black {
        background: fade(#888888, 10%) !important;
      }
      &-green {
        background: fade(#939b71, 10%) !important;
      }
      &-pink {
        background: fade(#c37f7f, 10%) !important;
      }
    }
    &-state {
      flex: 1;
      text-align: right;
      font-size: 14px;
      font-weight: 500;
      img {
        margin-right: 4px;
        width: 14px;
        height: 16px;
        vertical-align: text-top;
      }
    }
    &-red {
      color: #ba5a5a;
    }
    &-yellow {
      color: #ad8153 !important;
    }
    &-black {
      color: #888888 !important;
    }
    &-green {
      color: #939b71 !important;
    }
    &-pink {
      color: #c37f7f !important;
    }
  }
}
</style>
