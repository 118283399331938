export const enum MomentSourceType {
  /**
   *  备展日记(1,"备展日记")
   */
  ExhibitionDiary = 1,
  /**
   * 备展花絮(2,"备展花絮")
   */
  ExhibitionTidbits = 2,
  /**
   * 晒作业(3,"晒作业")
   */
  SunDrying = 3
}
