
































import { Component, Vue, Emit, Prop, Mixins, Watch } from 'vue-property-decorator'
@Component({
  name: 'PayPopup'
})
export default class PayPopup extends Vue {
  @Prop() title: string
  @Prop() visible: boolean
  @Prop() shouldPrice: number
  // 2为微信  3为支付宝  默认不传值得情况下是微信
  @Prop({ default: 2 }) paymentType: number

  /** 是否用微信支付 */
  get isWeChat() {
    return this.paymentType === 2
  }

  @Emit('hide')
  hidePopup() {}
}
