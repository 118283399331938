











































































































import { Component, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import moment from 'moment'
import WxImgPop from '@xt/www/components/wxImgPop/index.vue'
import UseStep from '@xt/www/pages/my/children/points/staticComponents/useStep.vue'
import * as introduce_bishan_tongban from '@xt/client/dataSource/introduce'
import LazyImg from '@/components/lazyImg/index.vue'

@Observer
@Component({
  scrollToTop: true,
  components: { WxImgPop, UseStep, LazyImg }
})
// export default class PageView extends MixinsMyPoints {
export default class Page extends Vue {
  tickets: any = false //文房券
  showMiniAppCodeAlert: boolean = false //是否显示二维码弹窗
  showStepAlert: boolean = false //是否显示适用步骤弹窗
  get PageStore() {
    // 获取全局状态
    return this.$store.$storeUser
  }
  get tongbanIntroduce() {
    let detail = introduce_bishan_tongban.introduce_bishan_tongban
    let introduceArr = detail.tipsDetails[1].content.split('\n')
    return introduceArr
  }
  get bishanIntroduce() {
    let detail = introduce_bishan_tongban.introduce_bishan_tongban
    let introduceArr = detail.tipsDetails[0].content.split('\n')
    return introduceArr
  }
  get Pagination() {
    return this.$store.$storeTicket.ticketPagination
  }
  //显示弹窗
  showMiniAppCode() {
    this.showMiniAppCodeAlert = true
  }
  //关闭弹窗
  closeMiniAppCode() {
    this.showMiniAppCodeAlert = false
  }
  closeStep() {
    this.showStepAlert = false
  }
  showStep() {
    this.showStepAlert = true
  }
  //获取时间
  getDate(expireTime) {
    return moment(expireTime).format('YYYY.MM.DD HH:mm')
  }
  //跳转详情页
  goPointsDetail() {
    this.$router.replace({
      name: 'my-points-detail'
    })
  }
  //去文房券
  goMyWenFangQUN() {
    this.$router.push({
      name: 'my-points-myCoupon'
    })
  }
  //去兑换
  goExchange(item) {
    this.$router.push({
      path: '/my/points/exchange',
      query: {
        id: item.id
      }
    })
  }
  async created() {
    if (this.$store.$storeUser.loggedIn) {
      await this.$store.$storeTicket.getTickets()
    }
  }
  //文房券专区
  showWenFangTicket() {
    this.showMiniAppCode()
  }
  //暄桐文房券使用步骤
  useTicketStep() {}
}
