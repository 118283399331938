/*
 * @Author: Erlin
 * @CreateTime: 2020-12-25 16:11:05
 * @LastEditors: Erlin
 * @LastEditTime: 2020-12-25 16:11:14
 * @Description: 订单详情
 */

import { Component, Prop, Vue, Provide, Inject, Watch, Emit } from 'vue-property-decorator'
import { EnumOrderState, EnumInvoiceState, EnumInvoiceText } from '../../../enums'
import lodash from 'lodash'
@Component
export class MixinsOrderHelpers extends Vue {
  @Prop({ default: () => {} }) dataSource: IOrderDetails
  OrderHelpers = {
    /** 自买 */
    isMyBuy(dataSource: IOrderDetails) {
      return lodash.eq(dataSource.buyWay, 1)
    },
    /** 赠送给别人 */
    isGiveTo: (dataSource: IOrderDetails) => {
      return lodash.eq(dataSource.buyWay, 2) && !this.$eqUser(dataSource.receiverId)
    },
    /** 被别人赠送(接受礼物)  接收礼物id等于我的id*/
    isReceive: (dataSource: IOrderDetails) => {
      return lodash.eq(dataSource.buyWay, 2) && this.$eqUser(dataSource.receiverId)
    },
    /** 礼物状态 */
    receiverState: function (dataSource: IOrderDetails) {
      const list = ['未领取', '已领取', '已退课']
      // 订单已退款展示礼物状态为已退课
      if (this.HasRefund(dataSource)) return list[2]
      return lodash.get(list, dataSource.receiverStatus, '')
    },
    /** 未接受的礼物订单 */
    notReceiver: (dataSource: IOrderDetails) => {
      return this.OrderHelpers.isReceive(dataSource) && lodash.eq(dataSource.receiverStatus.toString(), '0')
    },

    /** 未支付 */
    notPay(dataSource: IOrderDetails) {
      return lodash.eq(dataSource._state, EnumOrderState.NotPay)
    },
    /** 已支付 */
    hasPay(dataSource: IOrderDetails) {
      return lodash.eq(dataSource._state, EnumOrderState.HasPay)
    },
    /** 退款中 */
    Refund(dataSource: IOrderDetails) {
      return lodash.eq(dataSource._state, EnumOrderState.Refund)
    },
    /** 已退款 */
    HasRefund(dataSource: IOrderDetails) {
      return lodash.eq(dataSource._state, EnumOrderState.HasRefund)
    },

    /** 是否可退课 */
    canRefund: (dataSource: IOrderDetails) => {
      // 退课未截止 并且不是被赠课
      return (
        // 退课未截止
        !dataSource._isRefundEnd &&
        // 订单是支付状态/退课中
        (this.OrderHelpers.hasPay(dataSource) || this.OrderHelpers.Refund(dataSource)) &&
        // 赠送给别人 自己不能退课
        !this.OrderHelpers.isGiveTo(dataSource)
      )
    },
    /** 退课按钮文字 */
    refundBtnText: (dataSource: IOrderDetails) => {
      if (lodash.eq(dataSource._state, EnumOrderState.Refund)) {
        return '取消退课申请'
      } else {
        return '申请退课'
      }
    },
    /** 开票按钮文字 */
    invoiceText: (dataSource: IOrderDetails) => {
      if (lodash.eq(dataSource._invoiceState, EnumInvoiceState.Cancellation)) {
        return EnumInvoiceText.Cancellation
      } else if (lodash.eq(dataSource._invoiceState, EnumInvoiceState.HasInvoice)) {
        return EnumInvoiceText.HasInvoice
      } else if (lodash.eq(dataSource._invoiceState, EnumInvoiceState.WaitInvoice)) {
        return EnumInvoiceText.InvoiceIssue
      } else {
        return EnumInvoiceText.Apply
      }
    },
    /** 展示开票按钮 */
    invoiceShow: (dataSource: IOrderDetails) => {
      // 被别人赠送的礼物 / 实付0元  不可开发票
      return !this.OrderHelpers.isReceive(dataSource) && !lodash.eq(dataSource.payAmount.toString(), '0')
    },
    /** 开票按钮状态 */
    invoiceDisbled: (dataSource: IOrderDetails) => {
      // 已支付
      if (lodash.eq(dataSource._state, EnumOrderState.HasPay)) {
        return !(
          lodash.eq(dataSource._invoiceState, EnumInvoiceState.NotApply) || lodash.eq(dataSource._invoiceState, EnumInvoiceState.Reject)
        )
      }
      // else {
      //   return !lodash.eq(dataSource._invoiceState, EnumInvoiceState.NotApply)
      // }
      return true
    }
  }
}
