import global from '@xt/client/store/global'
import { GroupBuyEnum } from '../../api'
import { AjaxBasics } from '../../helpers'

export class ControllerGroupBuy {
  constructor(protected $ajax: AjaxBasics) {}
  // 页面中贴图的路由前缀
  imgPrefix: string = `${global.static}/images/groupbuy/`
  // 非我创建的拼团失败显示的图片
  failUrl: string = `${this.imgPrefix}collage_people_ele_im.png`
  // slogan
  sloganUrl: string = `${this.imgPrefix}collage_two_people_im.png`
  // 文字下边的线
  bottomLineUrl: string = `${this.imgPrefix}collage_line_i.png`
  // 海报上的slogan
  posterSlogan: string = `${this.imgPrefix}share_text_im.png`
  // 拉手成功
  collageSuccessUrl: string = `${this.imgPrefix}collage_success_im.png`
  // 校验弹窗
  popupTopUrl: string = `${this.imgPrefix}collage_popu_top_im.png`
  // 拉手
  collageHandUrl: string = `${this.imgPrefix}collage_hand_im.png`
  // 拉手成功
  collageHandSuccessUrl: string = `${this.imgPrefix}collage_hand_success_im.png`
  // 分享箭头
  shareArrowUrl: string = `${this.imgPrefix}arrow_img.png`
  // 文房
  wfcode: string = `${this.imgPrefix}wfcode.png`
  // 金鱼
  jinyu: string = `${this.imgPrefix}pc/jinyu_im.png`
  // 拼团成功其他人
  collageSuccessOtherIm: string = `${this.imgPrefix}collage_success_other_im.png`

  collageRules: string[] = [`${this.imgPrefix}collage_rule_im01.png`, `${this.imgPrefix}collage_rule_im02.png`]

  async getGroupDetail(groupNo: string) {
    return await this.$ajax.get(GroupBuyEnum.GroupDetail, { groupNo })
  }

  /**
   * 预加载图片，增强显示效果
   */
  async loadImg(src: string) {
    return await new Promise((resolve, reject) => {
      let img = new Image()
      img.onload = () => resolve(img)
      img.onerror = () => reject(src)
      img.src = src
    })
  }

  /**
   * 加载团购详情页所需图片
   */
  async loaImgAll() {
    await this.loadImg(this.failUrl)
    await this.loadImg(this.sloganUrl)
    await this.loadImg(this.bottomLineUrl)
    // await this.loadImg(this.posterSlogan)
    await this.loadImg(this.collageSuccessUrl)
    await this.loadImg(this.popupTopUrl)
    await this.loadImg(this.collageHandUrl)
    await this.loadImg(this.collageHandSuccessUrl)
    await this.loadImg(this.shareArrowUrl)
    Promise.all(this.collageRules.map(async v => await this.loadImg(v)))
  }
}
export default ControllerGroupBuy
