<template>
  <div class="list-model">
    <img class="live_all_exh_course_ele01" :src="ossProcessImg($images.live_all_exh_course_ele01, 465, 476)" />
    <img class="curse-model-live1" :src="ossProcessImg($images.live_bg_01, 826, 990)" />
    <div class="list-content">
      <div class="list-content-left">
        <div class="list-content-left-title">
          <span>共30节课，历时一年半的时间</span>
          <img :src="ossProcessImg($images.home_all_course_ele)" />
        </div>
        <div class="list-content-left-vice">{{ dataSource.courseTips }}</div>
        <img class="list-content-left-content-img" :src="dataSource.courseContentImg" />
      </div>
      <div class="list-content-right">
        <div class="list-content-right-title">
          <span>课程表</span>
          <img :src="ossProcessImg($images.home_all_course_ele)" />
        </div>
        <div class="list-content-right-vice">
          {{ dataSource.course[current].title }}
        </div>
        <div class="list-content-right-swiper">
          <div class="list-content-right-swiper-tips">*点击可查看大图哦~</div>
          <swiper :options="swiperOptions" :style="`width: ${$px2rem(400)}; height: ${$px2rem(492)}`" ref="mySwiper"
            @slideChange="handleSlideChange" @click="handleClick">
            <swiper-slide v-for="(item, index) in dataSource.course" :key="index"
              :style="`width: ${$px2rem(400)}; height: ${$px2rem(492)}`">
              <div class="img-box">
                <img :src="ossProcessImg(dataSource.course[index].message, 400)" class="swiper-img" />
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import LazyImg from '@/components/lazyImg/index.vue'
@Component({
  components: {
    LazyImg,
  }
})
export default class BMYX extends Vue {
  @Prop({}) dataSource
  current = 0
  urls = []

  @Watch('dataSource', { immediate: true })
  handleDataChange(data) {
    if (data && Array.isArray(data.course)) {
      this.urls = data.course.map(v => v.message)
    }
  }

  swiperOptions = {
    loop: true,
    autoplay: {
      disableOnInteraction: false
    },
    speed: 2500,
    delay: 3000,
    grabCursor: true, //贴心抓手
    roundLengths: true,
    //allowTouchMove: true,
    autoHeight: true
  }

  handleSlideChange() {
    const el = this.$refs.mySwiper
    if (el && el.$swiper) {
      const index = el.$swiper.realIndex;
      if (typeof index === 'number')
        this.current = index
    }
  }

  handleClick() {
    this.$showViewer(this.urls, this.current)
  }
}
</script>
<style lang="less" scoped>
.live_all_exh_course_ele01 {
  width: 465px;
  height: 476px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateX(-671px);
}

.list-model {
  width: 100vw;
  min-width: 1200px;
  height: 1090px;
  background: #ede5dd;
  margin-top: -416px;
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 1;

  .curse-model-live1 {
    width: 826px;
    height: 990px;
    position: absolute;
    top: -95px;
    transform: translateX(710px);
  }
}

.list-content {
  width: 1200px;
  margin-top: 341px;
  display: flex;
  position: relative;
  z-index: 11;

  &-left {
    width: 802px;

    &-title {
      font-size: 24px;
      font-weight: 500;
      color: #181c25;
      line-height: 24px;
      position: relative;
      height: 24px;

      span {
        z-index: 9;
        display: inline-block;
        position: absolute;
      }

      img {
        position: absolute;
        width: 318px;
        height: 7px;
        bottom: 0;
        left: 0;
      }
    }

    &-vice {
      font-size: 14px;
      color: #181c25;
      line-height: 14px;
      margin-top: 23px;
      margin-bottom: 10px;
    }

    &-content-img {
      width: 750px;
      height: 535px;
      margin-top: 39px;
    }
  }

  &-right {
    width: 398px;
    position: relative;

    &-title {
      font-size: 24px;
      font-weight: 500;
      color: #181c25;
      line-height: 24px;
      position: relative;
      height: 24px;

      span {
        z-index: 9;
        display: inline-block;
        position: absolute;
      }

      img {
        position: absolute;
        width: 72px;
        height: 5px;
        bottom: 0;
        left: 0;
      }
    }

    &-vice {
      font-size: 14px;
      color: #181c25;
      line-height: 14px;
      margin-top: 23px;
      margin-bottom: 10px;
      overflow: hidden;
    }

    &-swiper {
      width: 400px;
      height: 540px;
      background: #ffffff;
      border-radius: 6px;
      margin-top: 40px;
      padding: 0;
      padding-bottom: 48px;
      position: relative;
      overflow: hidden;
      position: relative;
      z-index: 99;

      &-item {
        position: absolute;
        top: 0;
        left: 0;
        width: 400px;
        cursor: pointer;
        opacity: 0;
        transition: 0.25s;
        z-index: 1;

        &:hover {
          transform: scale(1.1);
        }

        &-current {
          transition: 0.4s;
          opacity: 1;
          z-index: 9;
        }
      }

      .img-box {
        width: 100%;
        height: 100%;

        .swiper-img {
          width: 400px;
          height: 492px;
          transition: all 0.25s;
          user-select: none;

          &:hover {
            transform: scale(1.1);
          }
        }
      }

      &-tips {
        width: 400px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #c37f7f;
        line-height: 48px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        background: white;
      }
    }
  }
}

.curse-model {
  &-live1 {
    width: 662px;
    height: 898px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    transform: translateX(592px);
  }
}
</style>
