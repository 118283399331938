/**
 *  订单 api 枚举
 */
export enum EnumApiOrder {
  /** 下单支付 */
  orderPay = '/order/api/orders/orderPay',
  /** 订单列表 */
  OrderList = '/order/api/orders',
  /** 订单详情 */
  OrderDetails = '/order/api/orders/{orderNo}',
  AssistantUrl = '/product/api/product/{productId}/assistantUrl',

  /** 取消订单 */
  OrderCancel = '/order/api/orders/{orderNo}/cancel',
  /** 订单退款 */
  OrderRefund = '/order/api/orders/{orderNo}/refund',
  /** 退款前校验 */
  CheckRefund = '/order/api/orders/{orderNo}/check/refund',
  /** 活动商品退单前校验信息 */
  CheckRefundGiveaway = '/order/api/activity/gifts/refund/verify',
  /** 取消退款 */
  OrderCancelRefund = '/order/api/orders/{refundOrderSn}/cancelrefund',
  /** 申请开发票 */
  OrderInvices = '/order/api/order/{orderNo}/invoices',
  /** 查询未认领赠品 */
  OrderUnclaimed = '/order/api/activity/gifts/unclaimed',
  /** 查询已认领赠品 */
  OrderClaimed = '/order/api/activity/gifts/claimed',
  /** 活动商品退款待确认通知 */
  OrderGiveawayRefundNotice = '/order/api/activity/gifts/v2/refund/notify',
  /** 退款拒绝通知 */
  OrderGiveawayRejectRefundNotice = '/order/api/gifts/order/reject/notify',
  /** 活动商品同意退款 */
  OrderGiveawayRefundConfirm = '/order/api/gifts/order/refund/confirm?giftsRecordId={giftsRecordId}',
  /** 活动商品拒绝退款 */
  OrderGiveawayRefundReject = '/order/api/gifts/order/refund/reject?giftsRecordId={giftsRecordId}',
  /** 查询我的礼物领取状态 */
  GiftReceiveStatus = '/order/api/gifts/receive/status',

  /** 查询我的礼物列表 */
  OrderGifts = '/order/api/gifts',
  /** 接收礼物 */
  ReceiveGifts = '/order/api/gifts/{id}',
  /** 查询已购买课程--用于赠课时查询 */
  CouseHasbuy = '/order/api/orders/hasbuy',

  /** 综合订单 **/
  checkProduct = '/order/api/orders/check/{productId}',
  /** 校验是否首次购买日课 */
  checkFirstActivity = '/order/api/orders/check/activity',
  /** 校验是否有折扣 */
  checkHasDiscount = '/order/api/orders/have/discount'
}
