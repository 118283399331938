/*
 * @Author: Erlin
 * @CreateTime: 2020-10-12 19:24:17
 * @LastEditors: Erlin
 * @LastEditTime: 2020-10-15 14:20:44
 * @Description:
 */
import { EnumLocaleDescriptions } from '../enumLocale'
export default {
  [EnumLocaleDescriptions.des_officialAccount]: '微信公众号',
  [EnumLocaleDescriptions.des_sinaWeibo]: '新浪微博',
  [EnumLocaleDescriptions.des_xt]: '暄桐',
  [EnumLocaleDescriptions.des_contactUs]: '联系我们',
  [EnumLocaleDescriptions.des_copyright]: '版权声明',
  [EnumLocaleDescriptions.des_slogan]: '写字是一种生活',
  [EnumLocaleDescriptions.des_address]: '北京市朝阳区光华路9号天阶大厦',
  [EnumLocaleDescriptions.des_email]: 'lstudio@xuantong.cn',
  [EnumLocaleDescriptions.des_copyright_content]: '暄桐教室所有视频著作权归暄桐文化（北京）有限公司所有，未经允许不得传播'
}
