/**
 * @author wangsl
 */
export default class Platform {
  static Android() {
    //
    return navigator.userAgent.match(/Android/i) ? true : false
  }
  static BlackBerry() {
    //黑莓
    return navigator.userAgent.match(/BlackBerry/i) ? true : false
  }
  static iOS() {
    //ios
    return navigator.userAgent.match(/iPhone|iPad|iPod/i) ? true : false
  }
  static Windows() {
    //
    return navigator.userAgent.match(/IEMobile/i) ? true : false
  }
  static Mobile() {
    return Platform.Android() || Platform.BlackBerry() || Platform.iOS() || Platform.Windows()
  }
}
