


















































import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
@Component({
  components: {}
})
export default class extends Vue {
  @Prop({}) momentId
  get Homework() {
    return this.$store.$storeHomework
  }
  get PageStore() {
    return this.Homework.Details
  }
  get Pagination() {
    return this.PageStore.MomentComment
  }
  get body() {
    return { momentId: this.momentId }
  }

  getComment(item) {
    return {
      toUserName: item.toMemberNickname,
      content: item.content,
      memberId: item.memberId,
      author: item.memberNickname,
      time: item.createTime,
      bishan: item.bishanCount
    }
  }
  /**
   * 回复
   */
  reply: any = {}
  /**
   * 回复 当前状态
   */
  eqReply(data) {
    return lodash.eq(this.reply.id, data.id)
  }
  /**
   * 回复
   */
  onReply(data) {
    if (this.eqReply(data)) {
      return (this.reply = {})
    }
    this.reply = data
  }
  /**
   * 朋友圈评论回复
   */
  async onSubmit(event, data) {
    try {
      this.$InspectUser()
      this.$emit('submit', event, data)
      event.onReset()
      this.onReply({})
    } catch (error) {
      console.log('LENG: PageView -> onSubmit -> error', error)
    }
  }
  async onLikes(item) {
    try {
      await this.PageStore.onLikesComment(item)
    } catch (error) {
      this.$msg(error)
    }
  }
  onConfirm(item) {
    try {
      this.Homework.onDeleteComment(item)
    } catch (error) {}
  }
  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
