/*
 * @Author: Erlin
 * @CreateTime: 2020-08-28 17:53:56
 * @LastEditors: Erlin
 * @LastEditTime: 2020-09-10 17:20:56
 * @Description: 我的 api 枚举
 */

export enum EnumApiMy {
  /** 去上课/我的课程列表 */
  // MyCourseList = "/course/api/courses/myself",

  /** 去上课新版 **/
  MyCourseList = '/product/api/product/myproducts',
  /** 我的全部课程 **/
  MyAllCourse = '/product/api/product/myproducts/all',

  /** 去上课 邀请入群不再提醒 **/
  changeGroupStatus = '/product/api/product/{productId}/changeGroupStatus',
  /**
   * 我的作业列表
   */
  MyHomeworkList = '/homework/api/homeworks/myself',
  /**
   * 我的作业详情
   */
  MyHomeworkDetail = 'homework/api/homeworks/{id}',
  /**
   * 晒出我的作业
   */
  SunHomework = '/userhomework/sunbyhomeworkId',
  /**
   * 删除我的作业
   */
  DelHomework = '/userhomework/delete',
  /**
   * 我的作业回复
   */
  MyHomeworkReply = '/userhomework/reply',
  /**
   * 我晒出的作业列表
   */
  MySunWorkList = '/moment/usermomentlist',
  /**
   * 我晒出的作业详情
   */
  MySunWorkDetail = '/moment/momentdetail',
  /**
   * 删除我晒出的作业
   */
  DelSunWork = '/moment/deletemoment',

  /*
    笔山和桐板儿
  */
  MyPointsBiShan = '/bsp/api/bishanpoints/myself', //笔山明细
  MyPointsTongBan = '/uc/api/coin/record' //桐板明细
}
