
















import Poster from './posterScript'
import { Component } from 'vue-property-decorator'

@Component({
  name: 'PosterComp'
})
export default class PosterComp extends Poster {}
