import { BindAll } from 'lodash-decorators'
import { action, observable } from 'mobx'
import $global from '@xt/client/store/global'
import { getDeviceInfo } from '@xt/client/utils/device'
import { getFp } from '@xt/client/utils/fingerprint/report'
import { AjaxBasics } from '@xt/client/helpers/ajaxBasics'
import { Toast } from 'vant'
import { setDevId } from '@xt/client/utils/uuid'
import { satisfies } from 'compare-versions'

export enum EnumQRCodeState {
  /** 待扫描 */
  No_Scan = 0,
  /** 已扫描 */
  Have_Scan = 1,
  /** 已失效 */
  Have_Expire = 2,
  /** 已登录 */
  Have_Login = 3
}

@BindAll()
class AppletAuthLogin {
  constructor(protected $ajax: AjaxBasics) {}

  @observable visibleAuthPopup: boolean = false
  @observable scanState: number = EnumQRCodeState.No_Scan
  @observable canAuthLogin: boolean = false
  @observable showQuestionEntry: boolean = false
  @observable clickQuestionEntry: boolean = false

  uuid: string = ''
  timer: NodeJS.Timer | null = null
  authTimer: NodeJS.Timer | null = null
  questionTimer: NodeJS.Timer | null = null
  oldTime: number = 0
  boundTime: number = 5 * 60 * 1000
  deadBoundTime: number = (5 * 60 + 10) * 1000

  get pageStore() {
    return window.$nuxt.$store.$storeUser
  }

  get deviceStore() {
    return window.$nuxt.$store.$device
  }

  get schema() {
    const appid = $global.DEPLOY_ENV === 'pro' ? 'wx44a132db3c5f5042' : 'wx22db893614a494ea'
    const env_version = $global.DEPLOY_ENV === 'pro' ? 'release' : 'develop'
    const path = `pages/login/scan/index`
    const query = encodeURIComponent(`q=${this.uuid}`)

    return `weixin://dl/business/?appid=${appid}&path=${path}&query=${query}&env_version=${env_version}`
  }

  /**
   * 不能授权的规则
   * 微信内 & 非IOS & 微信版本号<8.0.48的机型不可以走小程序授权登录
   * @returns
   */
  async initAuthLogin() {
    // 如果初始化已经确认可以授权了  那么下次就不需要调用获取设备信息取微信版本号了
    if (this.canAuthLogin) {
      this.updateCanAuthLogin(true)
      return true
    }
    if ($global.WechatBowser) {
      if ($global.isiOS) {
        this.updateCanAuthLogin(true)
        return true
      }
      const deviceInfo = await getDeviceInfo()
      this.updateCanAuthLogin(satisfies(deviceInfo.devBrowserVersion, '>=8.0.48'))
      return this.canAuthLogin
    }
    return false
  }

  async authLoginHandler() {
    await this.initUUID()
    if (!this.uuid) {
      Toast('数据获取异常，请重试')
      return
    }
    window.location.href = this.schema
    clearTimeout(this.timer)
    this.timer = null
    this.oldTime = AjaxBasics.serviceDate.valueOf()

    this.authTimer = setTimeout(() => {
      this.updateAuthPopup(true)
    }, 1000)

    this.questionTimer = setTimeout(() => {
      this.updateQuestionEntry(true)
    }, 5000)

    this.onPollingQrcodeState(this.uuid)
  }

  @action updateCanAuthLogin(state: boolean) {
    this.canAuthLogin = state
  }

  @action
  updateQrcodeState(state: 0 | 1 | 2 | 3) {
    this.scanState = state
  }

  @action
  updateAuthPopup(state: boolean) {
    this.visibleAuthPopup = state
  }

  @action
  updateQuestionEntry(state: boolean) {
    this.showQuestionEntry = state
  }

  @action
  updateClickQuestionEntry(state: boolean) {
    this.clickQuestionEntry = state
  }

  reset() {
    this.updateQrcodeState(EnumQRCodeState.No_Scan)
    this.updateQuestionEntry(false)
    clearTimeout(this.authTimer)
    clearTimeout(this.questionTimer)
    this.authTimer = null
    this.questionTimer = null
  }

  async onPollingQrcodeState(uuid: string) {
    if (!uuid) return
    let currentTime = AjaxBasics.serviceDate.valueOf()
    let diff: number = currentTime - this.oldTime
    try {
      let res: any = await this.pageStore.onPollingQrcodeState(uuid)
      switch (res?.qrCodeStatus) {
        case EnumQRCodeState.No_Scan:
          // 超出边界展示失效界面
          if (diff > this.boundTime) {
            this.updateQrcodeState(EnumQRCodeState.Have_Expire)
          }
          // 超出死边界  释放
          if (diff > this.deadBoundTime) {
            this.cancelPolling()
            return
          }
          break
        case EnumQRCodeState.Have_Scan:
          // 从未扫描进来  重新计算5分10秒
          if (this.scanState === EnumQRCodeState.No_Scan || this.scanState === EnumQRCodeState.Have_Expire) {
            this.oldTime = currentTime
            uuid = res.qrCodeId
          }
          this.updateQrcodeState(EnumQRCodeState.Have_Scan)
          // 超出死边界  释放
          if (diff > this.deadBoundTime) {
            this.updateQrcodeState(EnumQRCodeState.Have_Expire)
            this.cancelPolling()
            return
          }
          break
        case EnumQRCodeState.Have_Expire:
          // 单纯的实效  释放
          this.updateQrcodeState(EnumQRCodeState.Have_Expire)
          this.cancelPolling()
          return
        case EnumQRCodeState.Have_Login:
          this.pageStore.onSetRefreshToken(res.refreshToken)
          // 设置请求参数
          this.deviceStore.authForH5Params = {
            refreshToken: res.refreshToken,
            memberId: res.memberId,
            authDevId: res.ownerDevId
          }
          // 这里需要重置一下devId
          /**
           * H5轮询走微信小程序授权登录
           * 当完成授权时，将devId变更为小程序的
           */
          if (typeof res.ownerDevId === 'string') {
            setDevId(res.ownerDevId, 'Mobile')
            // 重新执行初始化
            AjaxBasics.initDevId(true)
          }

          await this.pageStore.onExpirationRefresh(undefined, {
            'x-authType': '3',
            'x-appletAuth': '1'
          })

          if (this.pageStore.isLogin()) {
            this.updateQrcodeState(EnumQRCodeState.Have_Login)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
            return
          }
        default:
          // 未知异常
          return
      }
      this.timer = setTimeout(() => {
        this.onPollingQrcodeState(uuid)
      }, 2000)
    } catch (error) {
      Toast('数据获取异常，请重试')
      this.updateAuthPopup(false)
    }
  }

  cancelPolling(preventToast?: boolean) {
    clearTimeout(this.timer)
    this.timer = null
    !preventToast && Toast('登录失败')
    this.updateAuthPopup(false)
    this.reset()
  }

  async initUUID() {
    const deviceInfo = await getDeviceInfo()
    const {
      extra: { canvas, webgl, audio },
      current
    } = await getFp($global)
    const uuid = (await window.$nuxt.$store.$storeUser.onGetLoginQrcode({
      ...deviceInfo,
      canvas,
      webgl,
      audio,
      xtDevId: current
    })) as string
    this.uuid = uuid
  }
}

export default AppletAuthLogin
