// 课程内容类型枚举
export enum EnumContentType {
  /** 书法课 */
  Calligraphy = 1,
  /** 读书课 */
  Reading = 2,
  /** 写作课 */
  Writing = 3,
  /** 养生课 */
  Health = 4,
  /** 画画课 */
  Painting = 5,
  /** 实物商品 */
  Physical = 6,
  /** 书画课 */
  CalligraphyAndReading = 7,
  /** 美育 */
  AestheticEducation = 8
}

export enum EnumContentTypeText {
  /** 书法课 */
  Calligraphy = '书法',
  /** 读书课 */
  Reading = '读书',
  /** 写作课 */
  Writing = '写作',
  /** 养生课 */
  Health = '养生',
  /** 画画课 */
  Painting = '国画',
  /** 实物商品 */
  Physical = '实物',
  /** 书画课 */
  CalligraphyAndReading = '书画',
  /** 美育 */
  AestheticEducation = '美育'
}

// 商品类型枚举
export enum EnumProductType {
  /** 单节课 */
  single = 201001,
  /** 全阶课 */
  fullstage = 201002,
  /** 教练日课 */
  dayCourse = 202001,
  /** 自习课 */
  selfStudy = 202002,
  /** 线上展览 */
  onlineExhibition = 203001,
  /** 线下展览 */
  offlineExhibition = 203002
}

// 商品状态枚举
export enum EnumProductStatus {
  WaitEnroll = '即将报名',
  Enrolling = '正在报名',
  EnorllEnd = '报名结束',
  StartLearn = '开课中',
  EndLearn = '已结课'
}

export enum EnumProductStatusByShow {
  WaitEnroll = '即将报名',
  Enrolling = '正在报名',
  EnorllEnd = '报名结束',
  StartLearn = '备展中',
  EndLearn = '备展结束'
}

// 商品数量单位
export enum EnumProductUnit {
  DayCourseCountUnit = '天',
  LiveCourseCountUnit = '课',
  ExhibitonCourseCountUnit = '关',
  PhysicalCountUnit = '天'
}

export enum EnumProductStyle {
  WaitEnroll = 'wait',
  Enrolling = 'enorll',
  EnorllEnd = 'enorlled',
  StartLearn = 'learn',
  EndLearn = 'learned'
}

// 简版不细分线上线下及单阶全阶
export enum EnumProductTypeNameSimple {
  Live = '直播课',
  DayCourse = '教练日课',
  Exhibition = '展览辅导课',
  SelfStudy = '自习课'
}

// 简版不细分线上线下及单阶全阶
export enum EnumProductTypeSimple {
  // 直播课
  Live,
  // 教练日课
  DayCourse,
  // 展览辅导课
  Exhibition,
  // 自习课
  SelfStudy
}

export enum EnumProductTypeQuery {
  /** 单节课 */
  Course = 'course',
  /** 教练日课 */
  DayCourse = 'daycourse',
  /** 线上展览 */
  Exhibition = 'exhibition',
  /** 文房 */
  WenFang = 'wenfang',
  /** 自习课 */
  SelfStudy = 'selfstudy'
}
