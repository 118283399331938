/**
 * @author 冷 (https://github.com/LengYXin)
 * @email lengyingxin8966@gmail.com
 * @create date 2020-09-25 16:03:01
 * @modify date 2020-09-25 16:03:01
 * @desc 晒作业详情
 */
import { BindAll } from 'lodash-decorators'
import lodash from 'lodash'
import { EnumApiHomework } from '../../api'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { Pagination } from '../basics/pagination'
import { EntitiesBasics } from '../basics/entities'
import { EnumMessage } from '../../languages'
import SunDrying from './sunDrying'
/**
 * 作业详情
 */
@BindAll()
export class HomeworkDetails extends EntitiesBasics<any> {
  constructor(protected $ajax: AjaxBasics) {
    super($ajax, {
      url: EnumApiHomework.MomentDetail
    })
  }

  /**
   * 朋友圈评论
   */
  MomentComment = new Pagination(this.$ajax, {
    url: EnumApiHomework.MomentCommentList
  })

  /**
   * 发布朋友圈评论
   * @param body
   */
  // async onInstall(body: {
  //   content?
  //   contentLength?
  //   momentId?
  //   toCommentId?
  //   toMemberId?
  // }) {
  //   await this.$ajax.post(EnumApiHomework.MomentComment, body)
  //   // 朋友圈评论数量+1
  //   this.onUpdate((old) => {
  //     old.commentCount++
  //     return old
  //   })
  // }

  /**
   * 删除回复
   * @param momentCommentId
   */
  // async onDeleteComment(id?) {
  //   await this.$ajax.delete(EnumApiHomework.MomentDeleteComment, { id })
  //   this.MomentComment.onRemove(id)
  //   this.onUpdate((old) => {
  //     old.commentCount--
  //     return old
  //   })
  // }

  /**
   * 点赞
   * @param data
   */
  // async onLikes(data) {
  //   if (data.like) {
  //     throw EnumMessage.like_error
  //   }
  //   await this.$ajax.put(EnumApiHomework.MomentLike, {
  //     momentId: data.id,
  //     likeType: true,
  //   })
  //   this.onUpdate((old) => {
  //     old.likeCount++
  //     old.like = true
  //     return old
  //   })
  // }
  /**
   * 点赞 评论
   * @param data
   */
  async onLikesComment(data) {
    if (data.like) {
      throw EnumMessage.like_error
    }
    await this.$ajax.put(EnumApiHomework.MomentLikeComment, {
      commentId: data.id,
      likeType: true
    })
    this.MomentComment.onUpdate(data, old => {
      old.likeCount++
      old.like = true
      return old
    })
    // this.onUpdate((old) => {
    //   const dataSource = old.momentComments
    //   const index = lodash.findIndex(dataSource, ["id", data.id])
    //   lodash.updateWith(dataSource, `[${index}]`, (com) => {
    //     com.likeCount++
    //     com.like = true
    //     return com
    //   })
    //   old.momentComments = dataSource
    //   return old
    // })
  }

  async onGetHomeworkBizType(homeworkId: string) {
    try {
      const resp = await this.$ajax.get<number>(EnumApiHomework.HomeworkBizType, { homeworkId })
      return resp
    } catch (error) {}
  }
}
export default HomeworkDetails
