






















import { Component, Mixins, Prop } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { MixinsRetrieve } from '@xt/client/mixins/index'
import lodash from 'lodash'
import { EnumLogoutType } from '../../enum/index'

@Observer
@Component
export default class PageView extends Mixins(MixinsRetrieve) {
  defaultValue: string = ''
  phone: boolean = true
  model: any = {}
  validDevice = false
  selected = false
  get formOptions() {
    return {
      validDevice: this.validDevice,
      phone: this.phone,
      btnText: '下一步',
      label: this.label,
      defaultValue: this.defaultValue,
      rules: this.formRules,
      inputInnerText: '请输入四位验证码',
      layout: {
        labelCol: { span: 0 },
        wrapperCol: { span: 24 }
      },
      btnCol: { span: 24, offset: 0 }
    }
  }
  get judgmentSkipType() {
    return this.phone
  }
  async getSendSms(model, callback, closeLockCallback) {
    try {
      await this.PageStore.onGetCode({
        device: model.device,
        verifyCodeType: (this.judgmentSkipType && 12) || 13 // 12 手机号验证码校验 | 13 邮箱验证码校验
      })
      this.selected = true
      callback()
      closeLockCallback()
    } catch (error) {
      closeLockCallback()
      if (lodash.eq(error, false)) this.$msg(this.$EnumMessage.check_code_error)
    }
  }
  async onNextStep(dataSource) {
    const { disabled, device, confirmCode } = dataSource
    const verifyCodeType = (this.judgmentSkipType && 12) || 13 // 12 手机号验证码校验 | 13 邮箱验证码校验
    try {
      await this.PageStore.onCheckCode(
        lodash.merge(
          { device, verifyCode: confirmCode },
          {
            verifyCodeType
          }
        )
      )
      // 获取临时token后再调用/api/members/cancelWechat接口
      await this.PageStore.onGetTempTokenForCancelAccount({
        device,
        verifyCode: confirmCode,
        verifyCodeType: `${verifyCodeType}`
      })

      this.PageStore.onToggleLogoutModal(EnumLogoutType.CONDITION)
    } catch (error) {
      if (lodash.eq(error, false)) this.$msg(this.$EnumMessage.check_code_error)
      else if (lodash.eq(error, this.$EnumMessage.verify_failed)) {
        this.$msg(this.$EnumMessage.verify_failed)
      }
    }
  }
  created() {
    let phone = lodash.get(this.PageStore.UserInfo, 'phoneNum')
    let email = lodash.get(this.PageStore.UserInfo, 'email')
    this.defaultValue = phone || email
    this.phone = (phone && true) || false
  }
}
