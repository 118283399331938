















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import Viewer from 'viewerjs'

@Observer
@Component({
  async fetch(ctx) {},
  components: {}
})
export default class PageView extends Vue {
  col = 4
  loadFinish = true
  InfiniteEvent = null
  get PageStore() {
    return this.$store.$storeHome
  }
  get UserStore() {
    return this.$store.$storeUser
  }
  get Pagination() {
    return this.PageStore.Works
  }
  // get viewer(): Viewer {
  //   return this.$refs.viewer["$viewer"];
  // }

  onViewer(index) {
    this.$showViewer(lodash.map(this.Pagination.dataSource, 'originalUrl'), index)
    // this.viewer.view(index);
  }
  // 加载更多数据
  loadmore(event) {
    if (this.loadFinish) {
      this.loadFinish = false
      this.Pagination.onLoading({}, {}, event)
    } else {
      this.InfiniteEvent = event
      // event.loaded();
    }
  }
  // 图片加载完成
  finish() {
    this.loadFinish = true
    this.InfiniteEvent && this.InfiniteEvent.loaded()
  }

  // onLoading(event?) {
  //   this.Pagination.onLoading({}, {}, event);
  // }
  async created() {
    this.$setBreadcrumb({ linksName: '作业分享' }, true)
  }
  isLink(item) {
    return item.like ? 'icon-like-full' : 'icon-like'
  }
  // 点赞
  async onLikes(item) {
    try {
      await this.$InspectUser()
      await this.Pagination.onLikes(item)
    } catch (error) {
      this.$msg(error)
    }
  }
  updated() {}
  destroyed() {
    this.Pagination.onReset()
  }
}
