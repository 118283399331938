/** * @author wangsl */
<template>
  <Popup :visible="visible" @hide="hidePopup" title="绑定微信">
    <div id="qrcode-box"></div>
  </Popup>
</template>

<script>
import { Component } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import Popup from '../../popup/popup.vue'
import wxbindScript from './wxbindScript'

@Observer
@Component({
  name: 'WxBindComp',
  components: { Popup }
})
export default class WxBindComp extends wxbindScript {}
</script>

<style lang="less" scoped>
@import './wxbind.less';
</style>
