import { Component, Prop, Vue, Emit, Mixins } from 'vue-property-decorator'

@Component
export class MixinsComponentNewEditor extends Vue {
  @Prop({ default: false, type: Boolean }) spinning
  @Prop({ default: undefined, type: String }) tip
  @Prop({ default: '', type: String }) buttonText
  @Prop({ default: '', type: String }) placeholder
  @Prop({ default: () => ({}), type: Object }) rules
  @Prop({ default: () => ({ minRows: 1, maxRows: 2 }) }) autosize
  @Prop({ default: 1, type: Number }) rows

  showFace: boolean = false
  content: string = ''
  selectionStart: number = 0

  onShowFace(showFace) {
    this.showFace = showFace
  }

  blurHandler(e: FocusEvent) {
    this.selectionStart = (e.target as HTMLInputElement).selectionStart
  }

  changeHandler({ content, selectionStart }) {
    this.content = content
    this.selectionStart = selectionStart
  }

  onSubmit(data) {
    this.$emit('submit', data)
  }
}