/**
 * @author wangsl
 */
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component
export default class GroupPopup extends Vue {
  @Prop({}) visible: boolean
  @Prop({}) title: string
  @Prop({}) mainHeader: string
  @Prop({}) viceHeader: string
  @Prop({}) waitPrice: number
  @Prop({}) count: number
  @Prop({}) ratio: number
  @Prop({}) mainText: string
  @Prop({}) viceText: string

  @Emit('mainHandler')
  mainClick() {}

  @Emit('viceHandler')
  viceClick() {}

  @Emit('hide')
  hidePopup() {}
}
