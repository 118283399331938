/**
 * @author 冷 (https://github.com/LengYXin)
 * @email lengyingxin8966@gmail.com
 * @create date 2021-01-20 20:15:10
 * @modify date 2021-01-20 20:15:10
 * @desc [description]
 */
import lodash from 'lodash'
import { EnumCourseState, EnumCourseType, EnumCoursePublishStatus } from '../../enums'
import { BindAll } from 'lodash-decorators'
import moment from 'moment'
import { AjaxBasics } from '../../helpers'
class ClasshourBasics {
  /**
   * 原始数据
   * @memberof Course
   */
  original: IClasshourDataSource
  /**
   * 课程ID
   */
  get id() {
    return this.original?.id
  }
  get classhourType() {
    return this.original?.classhourType
  }
  get content() {
    return this.original?.content
  }

  get defaultPlaybackDuration() {
    return this.original?.defaultPlaybackDuration
  }
  get liveEnd() {
    return this.original?.liveEnd
  }
  get name() {
    return this.original?.name
  }
  get playbackGeneratedTime() {
    if (this.original.playbackGeneratedTime) {
      return moment(this.original.playbackGeneratedTime).startOf('seconds').valueOf()
    }
    return 0
  }
  get playbackStatus() {
    return this.original?.playbackStatus
  }
  get publishStatus() {
    return this.original?.publishStatus
  }
  get publishTime() {
    return this.original?.publishTime
  }
  get startTime() {
    if (this.original.startTime) {
      return moment(this.original.startTime).startOf('seconds').valueOf()
    }
    return 0
  }
  get subName() {
    return this.original.subName
  }
  get videoPlay() {
    return this.original.videoPlay
  }
  get bishanList() {
    return this.original.bishanList
  }
  get courseLearnEndTime() {
    return this.original.courseLearnEndTime
  }
  /** 回放结束时间 */
  get playbackEndTime() {
    // if (this.isPlayback && this.original.playbackGeneratedTime && this.defaultPlaybackDuration) {
    //     return moment(this.original.playbackGeneratedTime).add(this.defaultPlaybackDuration, 'days').endOf("days").valueOf()
    // }
    // 3-12 LENG update 回放结束时间使用 服务器返回
    if (this.isPlayback) {
      return this.original.playbackEndTime
    }
    return 0
  }
  /**
   * 回放
   */
  get isPlayback() {
    return lodash.eq(this.playbackStatus, 1)
  }
  /**
   * 已经发布
   */
  get isPublished() {
    return lodash.eq(this.publishStatus, 1)
  }
  /** 临时课 */
  get isTemporary() {
    return lodash.includes([2, 3], this.classhourType)
  }
  /**
   * 直播中
   */
  isLive = false
  /**
   * 倒计时
   */
  isDownTime = false
  /**
   * 活动的课时，当前上课最新操作的状态
   * @readonly
   * @memberof Classhour
   */
  isActivity = false
  /**
   * 倒计时结束时间
   */
  downEndTime = 0
}
BindAll()
export class Classhour extends ClasshourBasics {
  constructor(dataSource: IClasshourDataSource) {
    super()
    this.onInit(dataSource)
  }
  /**
   * 服务器时间
   */
  serviceDate = AjaxBasics.serviceDate.startOf('seconds').add('milliseconds', 100).valueOf()
  /**
   * 测试日期 方便查看
   */
  get testTimes() {
    return {
      startTime: this.original.startTime && moment(this.original.startTime).format('YYYY-MM-DD HH:mm:ss'),
      playbackGeneratedTime:
        this.original.playbackGeneratedTime && moment(this.original.playbackGeneratedTime).format('YYYY-MM-DD HH:mm:ss'),
      playbackEndTime: this.playbackEndTime && moment(this.playbackEndTime).format('YYYY-MM-DD HH:mm:ss'),
      serviceDate: moment(this.serviceDate).format('YYYY-MM-DD HH:mm:ss')
    }
  }
  /**
   * 回放剩余
   */
  get surplusDay() {
    if (this.isPlayback) {
      return moment(this.playbackEndTime).diff(this.serviceDate, 'days')
    }
    return 0
  }
  /**
   * 状态文本
   */
  get statusText() {
    if (this.isPlayback) {
      if (this.surplusDay > 0 || this.isDownTime) {
        return '回放剩余'
      }
      return '回放已结束'
    }
    if (this.isDownTime) {
      return '直播倒计时'
    }
    return
  }

  get coverUrl() {
    if (this.isActivity) {
      return '@Static/course/class-live.png'
    }
    return this.original.coverUrl
  }
  /**
   * 初始化
   * @param dataSource
   */
  onInit(dataSource) {
    this.original = lodash.cloneDeep(dataSource)
    if (this.isPublished) {
      // 直播中
      if (!this.liveEnd) {
        this.isLive = lodash.eq(lodash.min([this.serviceDate, this.startTime]), this.startTime)
      }
      this.onInitDownTime()
      this.onInitActivity()
    }
  }
  /**
   * 倒计时
   */
  onInitDownTime() {
    const times = [this.serviceDate]
    // 直播结束可回放
    if (this.liveEnd && this.isPlayback) {
      times.push(this.playbackEndTime)
    }
    // 直播未结束
    if (!this.liveEnd) {
      times.push(this.startTime)
    }
    const downEndTime = lodash.max(lodash.compact(times))
    if (!lodash.eq(downEndTime, this.serviceDate)) {
      const diff = moment(downEndTime).diff(this.serviceDate, 'seconds')
      // hhh
      this.isDownTime = diff > 0 && diff < 24 * 60 * 60
      this.downEndTime = downEndTime
    }
  }
  /**
   * 活动的课时，当前上课最新操作的状态
   * @readonly
   * @memberof Classhour
   */
  onInitActivity() {
    // 直播中
    if (this.isLive) {
      return (this.isActivity = true)
    }
    // 直播倒计时1小时内
    if (!this.liveEnd && this.isDownTime) {
      const diff = moment(this.downEndTime).diff(this.serviceDate, 'seconds')
      if (diff > 0 && diff < 1 * 60 * 60) {
        return (this.isActivity = true)
      }
    }
  }
}

export interface IClasshourDataSource {
  /** 课时类型 1非临时课，2头部临时课，3尾部临时课 */
  classhourType: 1 | 2 | 3
  /** 课时内容 */
  content: string
  coverUrl: string
  /**默认回放时长（单位：天） */
  defaultPlaybackDuration: number
  id: number
  /** 直播结束 */
  liveEnd: boolean
  /** 课时名称 */
  name: string
  /** 回放结束时间 */
  playbackEndTime: number
  /** 生成回放的时间 */
  playbackGeneratedTime: number
  /** 回放状态 0 回放未生成 1 可以回放 2 取消回放 */
  playbackStatus: 0 | 1 | 2
  /**发布状态 1发布，2未发布’ */
  publishStatus: 1 | 2
  /** 发布时间 */
  publishTime: number
  courseLearnEndTime: number
  /** 直播开始时间 */
  startTime: number
  subName: string
  /**视频笔山是否完成 */
  videoPlay: boolean
  /** 笔山 */
  bishanList: Array<{
    hasCompleted: boolean
    homeworkTaskId: number
    name: string
  }>
}
