























import { Component, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { EnumLogoutType, EnumAbandonType } from '../../enum/index'
import { ICheckUserInfoResp } from '../../../index.vue'

@Observer
@Component
export default class ConditionView extends Vue {
  @Prop() checkUserInfo: ICheckUserInfoResp

  logoutAccountLock = false

  get PageStore() {
    return this.$store.$storeUser
  }
  get EnumAbandonType() {
    return EnumAbandonType
  }

  get dataSource() {
    return [
      {
        id: 1,
        type: EnumAbandonType.COURSE,
        isPass: this.checkUserInfo?.uncompleteProductCount === 0 || this.PageStore.clickAbandonCourse
      },
      {
        id: 2,
        type: EnumAbandonType.RESOURCE,
        isPass: this.checkUserInfo?.bishanPointCount + this.checkUserInfo?.coin === 0 || this.PageStore.clickAbandonResource
      }
    ]
  }

  get logoutable() {
    return this.dataSource.filter(item => item.isPass).length === this.dataSource.length
  }

  goAbandonResource(item) {
    if (item.isPass) return
    this.PageStore.onToggleLogoutModal(EnumLogoutType.ABANDON)
    this.PageStore.onChangeAbandonChoose(item.type)
  }

  async logoutAccount() {
    try {
      if (!this.logoutable) return

      if (this.logoutAccountLock) return

      this.logoutAccountLock = true
      await this.PageStore.cancelAccount(true)
      await this.PageStore.onGetUserInfo()
      this.PageStore.onToggleLogoutVisible(false)
      window.location.replace(this.$router.resolve('/calmness').href)
    } catch (error) {
      console.log(error)
    } finally {
      this.logoutAccountLock = false
    }
  }
}
