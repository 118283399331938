export default class FileUtil {
  static async isHeif(file: File) {
    const fileReaderBuffer: FileReader = new FileReader()
    fileReaderBuffer.readAsArrayBuffer(file)
    const res: ArrayBuffer | boolean | string = await new Promise((resolve, reject) => {
      fileReaderBuffer.onload = res => resolve(res.target.result)
      fileReaderBuffer.onerror = () => reject(false)
    })
    if (!res) return false
    const bufferInt = new Uint8Array(res as any)
    const headerArr = bufferInt.slice(8, 12)
    // Uint8Array 重写了map函数慎用
    let header = ''
    headerArr.forEach((v: any) => {
      header += v.toString(16)
    })
    /* 
    heif 68656966
    mif1 6d696631
    heic 68656963
    heix 68656978
    hevc 68657663
    avcs 61766373
    avci 61766369
    jpeg 6a706567
    iso8 69736f38
    msf1 6d736631
    mp41 6d703431
    */
    let heif = [
      '68656963',
      '69736f38',
      '68656966',
      '6d736631',
      '6d696631',
      '68656978',
      '68657663',
      '61766373',
      '61766369',
      '6a706567',
      '6d703431'
    ]
    return heif.indexOf(header) !== -1
  }
}
