import lodash from 'lodash'
import { ApiSnapshot } from '@xt/client/entities/user/controller'

const replaceTag = '__hide__'

type MaybeNullable<T> = T | null | undefined

export interface IAjaxBasicsOptions {
  /**
   * 路径
   * @type {string}
   * @memberof IAjaxBasicsOptions
   */
  target?: string
  /**
   * 超时 时间
   * @type {number}
   * @memberof IAjaxBasicsOptions
   */
  timeout?: number
  /**
   * 重写 XMLHttpRequest
   * @memberof IAjaxBasicsOptions
   */
  createXHR?: () => XMLHttpRequest
}

export function formatApi(api: string, dataDesensitization?: string[]) {
  if (!Array.isArray(dataDesensitization)) return api

  try {
    const url = new URL(api);
    const searches = url.searchParams
    dataDesensitization.forEach(field => {
      if (searches.has(field)) {
        searches.set(field, replaceTag)
      }
    })

    return url.toString()
  } catch {
    return api
  }
}

function formatData(data: MaybeNullable<Record<string, any>>, dataDesensitization?: string[]) {
  if (!data || !Array.isArray(dataDesensitization)) return data

  const res = lodash.forOwn(data, (_value, key, object) => {
    if (dataDesensitization.includes(key)) {
      object[key] = replaceTag
    }
  })

  // console.log(res)
  return data
}

function formatHeaders(headers: MaybeNullable<Record<string, any>>, pickList?: string[]) {
  if (!headers || !Array.isArray(pickList)) return headers

  return lodash.pick(headers, pickList)
}

export function formatApiSnapshot(options: {
  ajax: XMLHttpRequest
  method: string
  data: MaybeNullable<Record<string, any>>
  headers: any
  /**
   * 自定义哪些头部数据上报
   */
  pickResponseHeaders?: string[]
  /**
   * 自定义哪些头部数据上报
   */
  pickRequestHeaders?: string[]
  /**
   * 敏感数据字段，会在上报时将字段值替换为"__hide__"
   */
  sensitiveDataFields?: string[]
}): ApiSnapshot {
  const { ajax, method, data, headers, sensitiveDataFields, pickRequestHeaders, pickResponseHeaders } = options
  const getResponseHeaders = () => {
    try {
      return ajax
        .getAllResponseHeaders()
        .split('\r\n')
        .reduce(function (e, t) {
          var n = t.split(': ')
          return (e[n[0]] = n[1]), e
        }, {})
    } catch {
      return {}
    }
  }

  return {
    method,
    originApi: formatApi(ajax.responseURL, sensitiveDataFields),
    params: formatData(data, sensitiveDataFields),
    response: '',
    reqHeaders: formatHeaders(headers ?? {}, pickRequestHeaders),
    resHeaders: formatHeaders(getResponseHeaders(), pickResponseHeaders)
  }
}
