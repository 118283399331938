
























































































import lodash from 'lodash'
import moment from 'moment'
import { Component, Prop, Vue, Ref } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import HeaderImage from '../../../../../components/headerImage/index.vue'
import FileUtil from '@xt/client/utils/file/file'

function getBase64(blob) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = e => {
      resolve(e.target.result)
    }
    // readAsDataURL
    fileReader.readAsDataURL(blob)
    fileReader.onerror = () => {
      reject(new Error('文件流异常，转换图片失败'))
    }
  })

  // const reader = new FileReader();
  // reader.addEventListener("load", () => callback(reader.result));
  // reader.readAsDataURL(img);
}

@Observer
@Component({
  components: { HeaderImage }
})
export default class PageView extends Vue {
  get PageStore() {
    return this.$store.$storeUser
  }
  get oss() {
    return this.$store.$OSS
  }
  @Ref('uploadRef') uploadRef
  @Ref('cropperRef') cropperRef
  visible = false
  loading = false
  uploadFile = null
  cropper = {
    img: null,
    size: 1,
    full: false,
    outputType: 'png',
    canMove: false,
    fixed: true,
    canMoveBox: true,
    autoCrop: true,
    centerBox: false,
    enlarge: 1,
    mode: 'contain',
    maxImgSize: 3000,
    limitMinSize: [100, 100]
  }
  previews = {}
  realTime(data) {
    this.previews = data
  }

  showModal() {
    if (this.$store.$storeUser.UserInfo.memberType === 2) return this.$msg('管理端用户无法更换头像')
    this.visible = true
  }

  customRequest() {}

  handleCancel() {
    this.visible = false
  }

  // 转换base64图片
  async onUploadChange(info) {
    try {
      this.loading = true
      let imageUrl = await getBase64(info.file.originFileObj)
      this.uploadFile = info.file
      this.cropper.img = imageUrl
      this.loading = false
    } catch (error) {
      console.log('Clin -> file: updateAvatar.vue -> line 198 -> PageView -> onUploadChange -> error', error)
    }
  }
  // 重新选择
  reSelect() {
    try {
      let input = this.uploadRef.$el.querySelector('input')
      input.dispatchEvent(new MouseEvent('click'))
    } catch (error) {
      console.log('Clin -> file: updateAvatar.vue ->  reSelect', error)
    }
  }

  async beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      this.$msg(this.$EnumMessage.upload_img_type_error)
      return new Promise((res, rej) => {
        rej()
      })
    }
    const isLt20M = file.size / 1024 / 1024 < 2
    if (!isLt20M) {
      this.$msg(this.$EnumMessage.upload_img_size_error)
      return new Promise((res, rej) => {
        rej()
      })
    }

    if (await FileUtil.isHeif(file)) {
      this.$msg(this.$EnumMessage.upload_img_heif_error)
      return new Promise((res, rej) => {
        rej()
      })
    }
    return false
  }

  onUpdateAvatar() {
    this.cropperRef.getCropBlob(async data => {
      try {
        this.loading = true
        // 拿到裁剪前的文件类型
        const file = this.oss.blobToFile(data, {
          name: this.uploadFile.name,
          type: this.uploadFile.type
        })

        // 问题
        const Policy = this.oss.onPolicy(0, 1)
        await Policy
        await this.oss.beforeFileMD5(file)
        // await this.oss.beforeFileMD5(file);
        //上传阿里云服务器

        const result: any = await this.$ajax.post(
          this.oss.options.host,
          lodash.merge(this.oss.beforeData(file), { file: file }),
          { 'Content-Type': undefined }
          // { "Content-Type": "Access-Control-Allow-Origin" }
        )

        this.onUpdate({
          headerThumbUrl: result.originThumbUrl,
          headerUrl: result.originUrl
        })
      } catch (error) {
        this.$msg('图片上传失败，请重试~')

        console.log('Clin -> file: updateAvatar.vue -> line 208 -> PageView -> onUpdateAvatar -> error', error)
      }
    })
  }

  // 更新头像信息
  async onUpdate(data) {
    try {
      lodash.merge(data, { id: this.PageStore.UserInfo.id })
      await this.PageStore.onUpdateUserInfo(data)
      this.$store.$global.setUpdateTime(Date.now())

      this.loading = false
      this.$msg(this.$EnumMessage.save_success, 'success')
      // this.$router.back();
      this.handleCancel()
    } catch (error) {
      this.loading = false
      this.$msg(error)
    }
  }
}
