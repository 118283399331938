

















































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import lodash from 'lodash'
import { MixinsCourseAgreement } from '@xt/client/mixins'
@Component({
  components: {}
})
export default class PageView extends Mixins(MixinsCourseAgreement) {
  goCourseProtocol() {
    window.open(`/agreement?id=${this.dataSource.original.productInfo.id}`)
  }
  created() {
    this.onLoad()
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
