









































































import { Component, Prop, Ref, Vue, Mixins, Emit } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { FormModel } from 'ant-design-vue'
import lodash from 'lodash'
import { MixinsOrderDetail } from '@xt/client/mixins'
import Teleport from '@xt/client/mixins/components/teleport.vue'

@Observer
@Component({
  components: { Teleport }
})
export default class PageView extends Mixins(MixinsOrderDetail) {
  @Prop({ default: () => {} }) dataSource
  @Ref('ruleForm') ruleForm: FormModel
  isInvoice = false
  model = {
    titleType: '2', // 发票抬头类型 1个人，2公司
    title: '', // 发票抬头
    taxpayerCode: '', // 纳税人识别号
    targetEmail: '', // 邮箱
    orderNo: '', // 订单编号
    mediumType: '1', //发票介质 1电子发票，2纸质发票—(目前只有电子发票，前台可以不传)
    invoiceType: '2', // 发票类型 1普通发票，2增值税发票—（目前只有增值税发票，前台可以不传）
    content: '培训费' // 发票内容–(目前写死培训费，前台可以不传)
  }
  formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 }
  }
  rules = {
    title: { required: true, message: '请填写发票抬头', trigger: 'blur' },
    taxpayerCode: {
      required: true,
      message: '请填写纳税人识别号',
      trigger: 'blur'
    },
    targetEmail: [
      { required: true, message: '请填写邮箱', trigger: 'blur' },
      {
        validator: (rule, value, callback) => {
          if (!this.$Regulars.email.test(value)) {
            return callback(new Error('请输入正确的邮箱'))
          }
          return callback()
        },
        trigger: 'blur'
      }
    ]
  }
  get PageStore() {
    return this.$store.$storeOrder
  }

  get isCompany() {
    return lodash.eq(this.model.titleType, '2')
  }
  @Emit('cancel')
  cancel(visible) {
    return visible
  }
  @Emit('refresh')
  onRefresh() {}
  async onSubmit() {
    if (this.isInvoice) return
    this.isInvoice = true
    try {
      let isValidate = await this.ruleForm.validate()
      let model = { ...this.model }
      model.targetEmail = model.targetEmail.trim()
      await this.PageStore.onOrderInvices(lodash.merge(model, { orderNo: this.dataSource.origin.orderNo }))
      this.$msg(this.$EnumMessage.apply_invoice_success, 'success')
      this.cancel(false)
      this.onRefresh()
      this.isInvoice = false
    } catch (_) {
      this.isInvoice = false
    }
  }
  created() {
    document.body.style.height = '100%'
    document.body.style.overflow = 'hidden'
  }
  mounted() {}
  updated() {}
  destroyed() {
    document.body.style.height = 'auto'
    document.body.style.overflow = 'auto'
  }
}
