/** * wangsl and mengxd */
<template>
  <div class="box" :style="styles">
    <!-- <div class="hot-course-item" @click="toDetail"> -->
    <div :class="['hot-course-item', getThemeClassName(dataSource)]" @click="detailUrl">
      <div class="tips">
        <img
          :style="`
            width: ${$px2rem(dataSource.courseTipsWidth)};
            height: ${$px2rem(dataSource.courseTipsHeight)};
          `"
          :src="ossProcessImg(dataSource.courseTipsUrl, dataSource.courseTipsWidth * 2, dataSource.courseTipsHeight * 2)"
        />
      </div>
      <div class="course-box">
        <div class="course-box-cover-box">
          <LazyImg className="course-box-cover" :src="ossProcessImg(dataSource.courseCoverImg, 458, 283)" />
        </div>
        <div class="course-box-basic">
          <div class="course-basic-firstline">
            <div class="course-basic-type">
              <span>{{ dataSource.courseContentType }}</span>
            </div>
            <div class="course-basic-title">
              {{ dataSource.courseTitle }}
            </div>
          </div>
          <div class="course-basic-dec">
            <!--{{dataSource.courseViceTitle}}-->
            <span>{{ dataSource.courseViceTitle }}</span>
          </div>
          <div class="course-basic-labels">
            <div
              :class="`course-basic-labels-item ${(item.title && 'course-basic-labels-item-title') || ''} ${
                (index === dataSource.courseLabels.length - 1 && 'course-basic-labels-item-last') || ''
              }`"
              :data-title="item.title"
              v-for="(item, index) in dataSource.courseLabels"
              :key="item.label"
            >
              {{ item.label }}
              <i v-if="item.title" class="iconfont icondetails_ic" />
            </div>
          </div>
          <div class="course-basic-giveaway-box" @click="goGiveawayDetail" v-if="dataSource.giveaway">
            <div class="giveaway-icon-box">
              <img class="giveaway-icon" :src="$images.give_class_no_new" />
            </div>
            <div class="giveaway-course-box">
              <img class="giveaway-course-img" :src="ossProcessImg(dataSource.giveaway.coverUrl, 136, 80)" />
              <div class="giveaway-course-desc">
                <div class="tip">{{ dataSource.giveaway.tip }}</div>
                <div class="desc">{{ dataSource.giveaway.desc }}</div>
              </div>
            </div>
          </div>
          <div class="course-basic-center-box" v-else-if="dataSource.pcSinglesImg || dataSource.studentWelfare">
            <!-- 单节课图 -->
            <img
              :src="dataSource.pcSingles.url"
              class="single-img"
              :style="{ width: $px2rem(dataSource.pcSingles.width) }"
              v-if="dataSource.pcSinglesImg"
            />
            <div class="welfare-tag-box" v-if="dataSource.studentWelfare" @click="welfareTagHandler">
              <img :src="$images.student_welfare_tag" class="student-welfare" />
              <img :src="$images.student_welfare_tag_hover" class="student-welfare student-welfare-hover" />
            </div>
          </div>
          <div class="course-basic-other-box">
            <div v-if="dataSource.courseType === 6" class="course-basic-signup course-basic-signup-left">
              直播时间
              <template v-if="showCourseTime()">：{{ moment(dataSource.courseStartTime).format('YYYY.MM.DD') }}</template>
              <template v-else>
                <div :style="timeStyle">
                  {{ moment(dataSource.courseRealStartTime || dataSource.courseStartTime).format('YYYY') }}
                </div>
                年
                <div :style="timeStyle">
                  {{ moment(dataSource.courseRealStartTime || dataSource.courseStartTime).format('MM') }}
                </div>
                月
                <div :style="timeStyle">
                  {{ moment(dataSource.courseRealStartTime || dataSource.courseStartTime).format('DD') }}
                </div>
                日
                <div :style="timeStyle">
                  {{ moment(dataSource.courseRealStartTime || dataSource.courseStartTime).format('HH:mm') }}
                </div>
              </template>
            </div>
            <div v-else-if="!showCourseTime()" class="course-basic-signup course-basic-signup-right">
              <div v-html="countdownText"></div>
            </div>
            <div v-else-if="showCourseTime() && dataSource.courseType === 4" class="course-basic-signup">
              <span>{{ countdownText }}</span>
            </div>
            <div v-else-if="showCourseTime() && !(dataSource.courseType === 4)" class="course-basic-signup">
              <span>{{
                '学习时间：' +
                moment(dataSource.courseStartTime).format('YYYY.MM.DD') +
                '-' +
                moment(dataSource.courseEndTime).format('YYYY.MM.DD')
              }}</span>
            </div>

            <div :class="`course-basic-button course-basic-button${courseStyle}`">
              {{ courseState }}
            </div>
            <div v-if="dataSource.btnBottomText && countdown() !== 0" class="course-basic-button-btmText">
              {{ dataSource.btnBottomText }}<LazyImg :src="ossProcessImg($images.home_collage_im, 32, 32)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator'
import { AjaxBasics } from '@xt/client'
import LazyImg from '@/components/lazyImg/index.vue'
import moment from 'moment'
import { EnumAllCourseState, EnumHotCourseStyle, EnumShowStatus, EnumPublicStatus } from '@xt/client/enums'

@Component({
  components: {
    LazyImg
  }
})
class Index extends Vue {
  // 数据
  @Prop() dataSource
  // 样式
  @Prop() styles

  data() {
    return {
      countdownText: ''
    }
  }

  courseState = ''
  courseStyle = ''
  interval = null
  get Math() {
    return Math
  }
  get convertOpened() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    return currentTime > this.dataSource.courseSignUpEndTime && currentTime < this.dataSource.courseConvertEndTime
  }

  get signupOpened() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    return currentTime >= this.dataSource.courseSignUpStartTime && currentTime < this.dataSource.courseSignUpEndTime
  }

  get courseOpened() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    return currentTime >= this.dataSource.courseStartTime && currentTime < this.dataSource.courseEndTime
  }

  welfareTagHandler(e) {
    e.stopPropagation()
    this.$store.$storeUser.toggleStudentWelfare(true)
  }

  /**
   *
   * @param item {{ courseContentType: string } & {[k: string]: any}}
   */
  getThemeClassName(item) {
    // 如果标签是 国画 则使用粉色主题，style-v2
    if (item && item.courseContentType === '国画') {
      return 'v2'
    }

    return 'default-version'
  }

  countdown() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    // 若报名结束且转报未结束则进行转报倒计时
    if (currentTime < this.dataSource.courseConvertEndTime && currentTime >= this.dataSource.courseSignUpEndTime) {
      return 2
    }
    // 若报名未结束且报名开始则进行报名倒计时
    if (currentTime > this.dataSource.courseSignUpStartTime && currentTime < this.dataSource.courseSignUpEndTime) {
      return 1
    }
    // 不需要倒计时
    return 0
  }

  getCourseState() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    //判断是否为展览
    if (this.dataSource.courseType === 4) {
      // 当前时间小于报名开始时间  报名未开始
      if (currentTime < this.dataSource.courseSignUpStartTime)
        return (this.courseState = EnumShowStatus.WaitSignUp), (this.courseStyle = EnumHotCourseStyle.NotStarted)
      // 当前时间大于报名开始时间 & 小于报名结束时间 正在报名
      if (currentTime >= this.dataSource.courseSignUpStartTime && currentTime <= this.dataSource.courseSignUpEndTime)
        return (this.courseState = EnumAllCourseState.InProgress), (this.courseStyle = EnumHotCourseStyle.InProgress)
      // 当前时间大于报名结束时间 报名结束
      if (currentTime >= this.dataSource.courseSignUpEndTime && currentTime < this.dataSource.showReadyStartTime)
        return (this.courseState = EnumShowStatus.SignUpEnd), (this.courseStyle = EnumHotCourseStyle.Signuped)
      if (currentTime >= this.dataSource.showReadyStartTime && currentTime < this.dataSource.showReadyEndTime)
        return (this.courseState = EnumShowStatus.CarryShow), (this.courseStyle = '-green')
      if (currentTime >= this.dataSource.showReadyEndTime && currentTime < this.dataSource.showVoteStartTime)
        return (this.courseState = EnumShowStatus.CarryEnd), (this.courseStyle = '-grey')
      if (currentTime >= this.dataSource.showVoteStartTime && currentTime < this.dataSource.showVoteEndTime)
        return (this.courseState = EnumShowStatus.Voteing), (this.courseStyle = '-red')
      if (currentTime >= this.dataSource.showVoteStartTime)
        return (this.courseState = EnumShowStatus.WatchShow), (this.courseStyle = '-yellow')
    } else if (this.dataSource.courseType === 6) {
      const startTimeDiff = this.dataSource.courseStartTime - currentTime
      if (startTimeDiff > 24 * 60 * 60 * 1000)
        return (this.courseState = EnumPublicStatus.Expectation), (this.courseStyle = EnumHotCourseStyle.NotStarted)
      if (startTimeDiff > 0) return (this.courseState = EnumPublicStatus.Wait), (this.courseStyle = EnumHotCourseStyle.NotStarted)
      if (currentTime >= this.dataSource.courseStartTime && currentTime < this.dataSource.courseEndTime) {
        return (this.courseState = EnumPublicStatus.Carry), (this.courseStyle = '-yellow')
      }
      if (this.dataSource.playback) return (this.courseState = EnumPublicStatus.Playback), (this.courseStyle = '-yellow')
      return (this.courseState = EnumPublicStatus.End), (this.courseStyle = EnumHotCourseStyle.EndofClass)
    } else {
      // 当前时间大于转报结束时间且课程结束则 已结课 及按钮样式
      if (currentTime >= this.dataSource.courseConvertEndTime && currentTime >= this.dataSource.courseEndTime)
        return (this.courseState = EnumAllCourseState.EndofClass), (this.courseStyle = EnumHotCourseStyle.EndofClass)
      // 当前时间大于转报结束时间且大于报名结束时间且课程已开启则 开课中 及按钮样式
      if (
        currentTime >= this.dataSource.courseConvertEndTime &&
        currentTime >= this.dataSource.courseSignUpEndTime &&
        currentTime >= this.dataSource.courseStartTime
      )
        return (this.courseState = EnumAllCourseState.InClass), (this.courseStyle = EnumHotCourseStyle.InClass)
      // 当前时间大于转报结束时间且课程未开启则 转报结束 及按钮样式
      if (
        currentTime >= this.dataSource.courseConvertEndTime &&
        currentTime >= this.dataSource.courseSignUpEndTime &&
        currentTime <= this.dataSource.courseStartTime
      )
        return (this.courseState = EnumAllCourseState.Signuped), (this.courseStyle = EnumHotCourseStyle.Signuped)
      // 当前时间大于报名结束时间且转报开启则 转报全阶 及按钮样式
      if (currentTime >= this.dataSource.courseSignUpEndTime && currentTime < this.dataSource.courseConvertEndTime)
        return (this.courseState = EnumAllCourseState.toAll), (this.courseStyle = EnumHotCourseStyle.toAll)
      // 当前时间大于报名开始时间且小于报名结束时间则  我要报名 及按钮样式
      if (this.signupOpened) return (this.courseState = EnumAllCourseState.InProgress), (this.courseStyle = EnumHotCourseStyle.InProgress)
      return (this.courseState = EnumAllCourseState.NotStarted), (this.courseStyle = EnumHotCourseStyle.NotStarted)
    }
  }

  initState() {
    // this.getCourseStyle()
    this.getCourseState()
    if (this.courseState === EnumAllCourseState.InProgress || this.courseState === EnumAllCourseState.toAll)
      this.$emit('changeHot', this.dataSource.courseId, 'add')
    else this.$emit('changeHot', this.dataSource.courseId, 'remove')
  }

  get moment() {
    return moment
  }

  showCourseTime() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    if (this.dataSource.courseType === 6) return this.dataSource.playback
    return currentTime >= this.dataSource.courseConvertEndTime && currentTime >= this.dataSource.courseSignUpEndTime
  }
  /**
   * 跳转到详情页
   */
  toDetail() {
    if (this.dataSource.url) return window.open(this.dataSource.url)
    if (this.dataSource.courseType == 1 || this.dataSource.courseType == 2)
      // window.open("/course/" + this.dataSource.courseId)
      console.log('object')
    else if (this.dataSource.courseType == 4) window.open('/course/' + this.dataSource.courseId)
    else window.open('/activitys/course/' + this.dataSource.courseId)
  }
  detailUrl() {
    const currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    const isPublicLive = this.dataSource.courseType === 6
    // 公开课 直播结束后没有回放地址 点击无反应
    if (isPublicLive && currentTime > this.dataSource.courseEndTime && !this.dataSource.playback) return
    if (this.dataSource.playback) return window.open(this.dataSource.playback)
    if (this.dataSource.url) return window.open(this.dataSource.url)
    if (!this.dataSource.productId) {
      this.$msg(`即将开始${isPublicLive ? '直播' : '报名'}，再等等吧 😉`)
      return
    }
    if (this.dataSource.courseType == 1 || this.dataSource.courseType == 2) return window.open(`/commodity/${this.dataSource.productId}`)
    else if (this.dataSource.courseType == 4) return window.open(`/commodity/${this.dataSource.productId}`)
    else return window.open(`/commodity/${this.dataSource.productId}`)
  }
  goGiveawayDetail(e) {
    e.stopPropagation()
    const id = this.dataSource.giveaway.productId
    if (!id) {
      return
    }
    window.open(`/commodity/${id}`)
  }
  countdownInterval(endtime) {
    this.computed(endtime)
    this.interval = setInterval(() => {
      this.computed(endtime)
    }, 1000)
  }

  computeDay(currentTime, endtime) {
    return Math.floor(this.moment(endtime).diff(currentTime, 'hour') / 24)
  }

  computed(endtime) {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    let diff = endtime - currentTime
    if (diff < 0) {
      clearInterval(this.interval)
      this.initState()
      if (this.dataSource.courseType === 4) {
        if (currentTime > this.dataSource.courseSignUpEndTime && currentTime < this.dataSource.showReadyEndTime) {
          this.countdownText = `备展时间：${moment(this.dataSource.courseSignUpStartTime).format('YYYY.MM.DD')} - ${moment(
            this.dataSource.showReadyEndTime
          ).format('YYYY.MM.DD')}`
        }
        if (currentTime > this.dataSource.showReadyEndTime && currentTime < this.dataSource.showVoteEndTime) {
          this.countdownText =
            this.dataSource.voteTip ||
            `投票时间：${moment(this.dataSource.showVoteStartTime).format('YYYY.MM.DD')} - ${moment(this.dataSource.showVoteEndTime).format(
              'YYYY.MM.DD'
            )}`
        }
        if (currentTime > this.dataSource.showVoteEndTime) {
          this.countdownText = `展览时间：${moment(this.dataSource.courseSignUpStartTime).format('YYYY.MM.DD')} - ${moment(
            this.dataSource.showVoteEndTime
          ).format('YYYY.MM.DD')}`
        }
      } else {
        this.countdownText =
          currentTime < this.dataSource.courseConvertEndTime ? this.countdownInterval(this.dataSource.courseConvertEndTime) : ''
      }
      return
    }
    if (diff > 24 * 60 * 60 * 1000)
      this.countdownText = `剩余<div  style='${this.dateStyle}'>` + this.computeDay(currentTime, endtime) + '</div>天'
    else {
      let timeStr = moment(diff).utcOffset(0).format(`HH-mm-ss`).split('-')
      return (this.countdownText = `剩余
      <div style='${this.timeStyle}'>${timeStr[0]}</div>时<div style='${this.timeStyle}'>${timeStr[1]}</div>分<div style='${this.timeStyle}'>${timeStr[2]}</div>秒
      `)
    }
  }

  created() {
    this.initState()
  }

  mounted() {
    if (this.dataSource.courseType === 6) return
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    if (this.countdown() == 2) this.countdownInterval(this.dataSource.courseConvertEndTime)
    else if (this.countdown() == 1) this.countdownInterval(this.dataSource.courseSignUpEndTime)
    else if (currentTime < this.dataSource.courseSignUpStartTime) {
      let detaStr = moment(this.dataSource.courseSignUpStartTime).format('YYYY-MM-DD').split('-')
      this.countdownText =
        '时间' +
        `
      <div style='${this.dateStyle}'>${detaStr[0]}</div>年<div style='${this.dateStyle}'>${detaStr[1]}</div>月<div style='${this.dateStyle}'>${detaStr[2]}</div>日
      `
    } else if (this.dataSource.courseType === 4) {
      if (currentTime > this.dataSource.courseSignUpEndTime && currentTime < this.dataSource.showReadyEndTime) {
        this.countdownText = `备展时间：${moment(this.dataSource.courseSignUpStartTime).format('YYYY.MM.DD')} - ${moment(
          this.dataSource.showReadyEndTime
        ).format('YYYY.MM.DD')}`
      } else if (currentTime > this.dataSource.showReadyEndTime && currentTime < this.dataSource.showVoteEndTime) {
        this.countdownText =
          this.dataSource.voteTip ||
          `投票时间：${moment(this.dataSource.showVoteStartTime).format('YYYY.MM.DD')} - ${moment(this.dataSource.showVoteEndTime).format(
            'YYYY.MM.DD'
          )}`
      } else if (currentTime > this.dataSource.showVoteEndTime) {
        this.countdownText = `展览时间：${moment(this.dataSource.courseSignUpStartTime).format('YYYY.MM.DD')} - ${moment(
          this.dataSource.showVoteEndTime
        ).format('YYYY.MM.DD')}`
      }
    }
  }
  destroyed() {
    clearInterval(this.interval)
  }

  get dateStyle() {
    return `
    display: inline-block;
    padding: 0 ${this.$px2rem(6)};
    margin: 0 ${this.$px2rem(6)};
    font-size:  ${this.$px2rem(20)};
    font-weight: 500;
    color: #C37F7F;
    background: rgba(195,127,127,.1);
    height:  ${this.$px2rem(28)};
    line-height:  ${this.$px2rem(28)};`
  }

  get timeStyle() {
    return this.dateStyle + 'width: ' + this.$px2rem(36) + 'rem'
  }
}

export default Index
</script>

<style scoped lang="less">
.box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: #fcfcfc;
  border-radius: 30px;
  cursor: pointer;
  user-select: none;
  &:hover {
    .course-box-cover {
      transform: scale(1.1);
    }
  }
  .hot-course-item {
    width: 100%;
    padding-top: 40px;
    padding-left: 60px;
    padding-right: 60px;
    display: block;

    // 国画课粉色tag样式
    &.v2 {
      .course-box-basic {
        .course-basic-labels-item {
          background: fade(#f1e0df, 50%);
          color: #c37f7f;
        }
      }
    }

    .tips {
      font-size: 24px;
      font-weight: 400;
      color: #181c25;
      line-height: 24px;
      display: flex;
      align-items: flex-end;
      &-icon {
        display: inline-flex;
        i {
          font-size: 35px;
        }
      }
      &-text {
        display: inline-flex;
      }
    }
    .course-box {
      width: 100%;
      margin-top: 34px;
      height: 283px;
      display: flex;
      &-cover {
        width: 458px;
        height: 283px;
        background: #cacaca;
        border-radius: 12px;
        user-select: none;
        -webkit-user-drag: none;
        transition: transform 0.4s;
        &-box {
          width: 458px;
          border-radius: 12px;
          height: 283px;
          margin-right: 40px;
          overflow: hidden;
        }
      }
      &-basic {
        flex: 1;
        height: 100%;
        display: inline-flex;
        flex-direction: column;
        vertical-align: bottom;
        .course-basic {
          &-firstline {
            display: flex;
            height: 30px;
            align-items: flex-end;
          }
          &-center-box {
            display: flex;
            align-items: center;
            width: 100%;
            height: 56px;

            .single-img {
              height: 56px;
              margin-right: 58px;
            }

            .welfare-tag-box {
              &:hover {
                .student-welfare {
                  display: none;

                  &-hover {
                    display: block;
                  }
                }
              }

              .student-welfare {
                width: 180px;
                height: 50px;

                &-hover {
                  display: none;
                }
              }
            }
          }
          &-giveaway-box {
            display: flex;
            width: 386px;
            height: 56px;
            background: #f4f1ee;
            border-radius: 8px;
            padding: 8px 0;
            .giveaway-icon-box {
              width: 86px;
              border-right: 1px dashed #ad8f7b;
              padding: 0 18px;
              .giveaway-icon {
                width: 50px;
                height: 38px;
                object-fit: cover;
              }
            }
            .giveaway-course-box {
              display: flex;
              margin-left: 10px;
              .giveaway-course-img {
                width: 68px;
                height: 40px;
                object-fit: cover;
                border-radius: 6px;
              }
              .giveaway-course-desc {
                width: 210px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 12px;
                .tip {
                  font-size: 12px;
                  color: #343434;
                  line-height: 17px;
                  font-weight: 500;
                }
                .desc {
                  font-size: 20px;
                  color: #343434;
                  line-height: 14px;
                  transform: scale(0.5);
                  transform-origin: left center;
                  margin-top: 2px;
                  white-space: nowrap;
                }
              }
            }
          }
          &-type {
            height: 30px;
            padding: 0 8px;
            background: #ad9174;
            border-radius: 2px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 400;
            color: #ffffff;
            margin-right: 10px;
            line-height: 30px;
            position: relative;
            bottom: 0;
          }
          &-title {
            display: inline-flex;
            font-size: 30px;
            color: #181c25;
            line-height: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 500;
          }
          &-dec {
            margin-top: 30px;
            font-size: 18px;
            font-weight: 400;
            color: #181c25;
            line-height: 24px;
            height: 48px;
          }
          &-labels {
            height: 49px;
            &-item {
              padding: 7px 20px;
              background: fade(#ad9174, 10%);
              font-size: 12px;
              font-weight: 400;
              color: #ad9174;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              margin-right: 20px;
              margin-bottom: 18px;
              float: left;
              i {
                font-size: 12px;
                margin-left: 5px;
              }
              &-title {
                &:hover {
                  position: relative;
                  &::after {
                    display: flex;
                    content: attr(data-title);
                    padding: 20px 20px;
                    position: absolute;
                    margin: auto;
                    left: 0;
                    right: 0;
                    background: white;
                    box-shadow: 0 0 8px 0 rgb(173 145 116 / 15%);
                    top: -14px;
                    transform: translateY(-100%);
                    white-space: pre-line;
                    z-index: 9;
                    border: 1px solid #ededed;
                    border-radius: 5px;
                    min-width: 300px;
                    color: #181c25;
                    font-size: 12px;
                    line-height: 24px;
                    box-shadow: 0px 0px 8px 0px rgba(173, 145, 116, 0.15);
                    text-align: justify;
                  }
                  &::before {
                    display: block;
                    width: 8px;
                    height: 8px;
                    content: '';
                    position: absolute;
                    margin: auto;
                    left: 0;
                    right: 0;
                    top: -18px;
                    transform: rotate(45deg);
                    background: white;
                    box-shadow: 0 0 0.5rem 0 rgb(173 145 116 / 15%);
                    z-index: 10;
                    border-bottom: 1px solid #ededed;
                    border-right: 1px solid #ededed;
                  }
                }
              }
              &-last {
                margin-right: 0;
              }
            }
          }
          &-other-box {
            flex: 1;
            vertical-align: bottom;
            position: relative;
            height: 46px;
          }

          &-signup {
            font-size: 16px;
            color: #181c25;
            line-height: 46px;
            position: absolute;
            bottom: 0;
            &-right {
              right: 200px;
            }
            &-left {
              left: 0;
            }
          }
          &-course {
            position: absolute;
            bottom: 0;
            span {
              &:nth-child(1) {
                font-size: 20px;
                font-weight: 500;
                color: #181c25;
                line-height: 20px;
                margin-bottom: 10px;
                display: block;
              }

              &:nth-child(2) {
                font-size: 16px;
                color: #181c25;
                line-height: 16px;
              }
            }
          }
          &-button {
            height: 46px;
            width: 150px;
            font-size: 20px;
            font-weight: 500;
            color: #ffffff;
            display: inline-block;
            text-align: center;
            line-height: 46px;
            border-radius: 2px;
            position: absolute;
            bottom: 0;
            right: 0;
            user-select: none;
            &-red-no {
              background-color: #d9a8a8;
            }
            &-red {
              background-color: #d9a8a8;
              &:hover {
                background-color: #d99292 !important;
              }
            }
            &-yellow {
              background-color: #e0bf95;
              &:hover {
                background-color: #e0b47e !important;
              }
            }
            &-black {
              background-color: rgba(#d2d2d2, 0.7) !important;
            }
            &-grey {
              background: rgba(#d2d2d2, 0.7);
            }
            &-green {
              background: #939b71;
            }
          }
          &-button-btmText {
            bottom: -25px;
            right: 0;
            position: absolute;
            font-size: 14px;
            color: #c37f7f;
            line-height: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 150px;
            img {
              vertical-align: middle;
              width: 16px;
              margin-left: 10px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
