/**
 * @ Author: Mengcx
 * @ Create Time: 2022-06-06 15:06:13
 * @ Modified by: Mengcx
 * @ Modified time: 2024-09-24 11:18:23
 * @ Description:
 */

/**
 * H5 PC  统一做处理
 */
import { AjaxBasics } from '@xt/client'
import { EnumContentType, EnumContentTypeText, EnumProductType } from '@xt/client/enums'
import lodash from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export class MixinsMyAllCourse extends Vue {
  // 获取全部课程数据
  @Prop({ default: () => {} }) dataSource
  // tip: string = ''
  interval = null
  color: boolean = true

  get isColor() {
    return this.color
  }

  // 1书法课，2读书课，3写作课，4养生课，5国画课，6实物商品，7书画课，8美育
  get contentType() {
    switch (this.dataSource.contentType) {
      case EnumContentType.Painting:
        return EnumContentTypeText.Painting
      case EnumContentType.Calligraphy:
        return EnumContentTypeText.Calligraphy
      case EnumContentType.CalligraphyAndReading:
        return EnumContentTypeText.CalligraphyAndReading
      case EnumContentType.Health:
        return EnumContentTypeText.Health
      case EnumContentType.Physical:
        return EnumContentTypeText.Physical
      case EnumContentType.Reading:
        return EnumContentTypeText.Reading
      case EnumContentType.Writing:
        return EnumContentTypeText.Writing
      case EnumContentType.AestheticEducation:
        return EnumContentTypeText.AestheticEducation
      default:
        return EnumContentTypeText.Calligraphy
    }
  }

  // 直播课
  get isCourse() {
    return this.dataSource.productType === EnumProductType.single || this.dataSource.productType === EnumProductType.fullstage
  }

  // 日课
  get isDay() {
    return this.dataSource.productType === EnumProductType.dayCourse || this.dataSource.productType === EnumProductType.selfStudy
  }

  // 展览
  get isExhibition() {
    return (
      this.dataSource.productType === EnumProductType.onlineExhibition || this.dataSource.productType === EnumProductType.offlineExhibition
    )
  }

  // 显示的单位  直播课：课  日课：天  展览：关
  get unit() {
    switch (true) {
      case this.isCourse:
        return '课'
      case this.isDay:
        return '天'
      case this.isExhibition:
        return '关'
      default:
        return '课'
    }
  }

  // 课程进度
  get courseProgress() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    let learnStartTime = this.dataSource.learnStartTime // 学习开始时间
    let learnEndTime = this.dataSource.learnEndTime // 学习结束时间
    let voteStartTime = this.dataSource.voteStartTime // 投票开始时间
    let voteEndTime = this.dataSource.voteEndTime // 投票结束时间
    let formalEndTime = this.dataSource.formalEndTime // 正式提交结束时间
    if (this.isDay || this.isCourse) {
      if (lodash.max([currentTime, learnStartTime]) === learnStartTime) {
        this.color = false
        return '即将开课'
      } else if (lodash.max([currentTime, learnEndTime]) === learnEndTime) {
        this.color = true
        return `进行至第${this.dataSource.taskSchedule}${this.unit}`
      } else {
        this.color = false
        return '已结课'
      }
    } else {
      if (currentTime > voteEndTime) return '投票结束'
      if (currentTime > voteStartTime) return '正在投票'
      if (currentTime > formalEndTime) return '备展结束'
      if (this.dataSource.mainType === 1 || this.dataSource.mainType === 2)
        return this.dataSource.taskSchedule !== 0 ? `进行至第${this.dataSource.taskSchedule}关` : '选择临摹习作'
      if (this.dataSource.mainType === 3) return '练习习作提交'
      return '正式习作提交'
    }
  }
}
