import { BindAll } from 'lodash-decorators'
import { AjaxBasics } from '@xt/client/helpers/ajaxBasics'
import { Pagination } from '../basics/pagination'
import { EnumApiNotice } from '../../api'
import { action, observable, runInAction } from 'mobx'
import { EnumNoticeReadStatus, EnumNoticeType } from '../../enums'

type SystemNoticePrviewType = {
  content: string
  unreadQuantity: number
}

export type NoticeContentType = {
  /** 小程序跳转url */
  appletUrl: string
  bizData: {
    content: string
    pictures: string[]
    valid: boolean
  }
  bizId: string
  bizType: number
  content: string
  id: number
  /** 小程序跳转url */
  jumpUrl: string
  msgStatus: number
  noticeMainType: number
  noticeSubType: number
  /** 子类型描述 */
  noticeSubTypeDesc: string
  sendTime: number
  senderId: string
  senderNickname: string
  title: string
}

@BindAll()
class MessageNotice {
  constructor(protected $ajax: AjaxBasics) {}

  @observable systemNotice: SystemNoticePrviewType | null = null
  @observable unreadQuantity: number = 0

  /** 消息通知列表 */
  noticeListPagination = new Pagination<NoticeContentType>(this.$ajax, {
    url: EnumApiNotice.noticeList,
    key: 'id'
  })

  systemListPagination = new Pagination<NoticeContentType>(this.$ajax, {
    url: EnumApiNotice.noticeList,
    key: 'id'
  })

  /** 系统消息通知预览 */
  async onSystemNoticePreview() {
    const resp = await this.$ajax.get<SystemNoticePrviewType>(EnumApiNotice.noticePreview)
    runInAction(() => {
      this.systemNotice = resp
    })
  }

  /**
   * 用户已读通知
   * @param id 通知id
   */
  async onReadNotice(id: number, type: 1 | 2 | 3 | 4 = 4) {
    try {
      const resp = await this.$ajax.post<boolean>(EnumApiNotice.noticeRead, { id })
      // 已读成功
      if (resp === true) {
        switch (type) {
          // 用户通知 || 系统通知
          case EnumNoticeType.Account:
          case EnumNoticeType.System:
            const tempPagination = type === EnumNoticeType.Account ? this.noticeListPagination : this.systemListPagination
            tempPagination.onUpdate(`${id}`, old => {
              old.msgStatus = EnumNoticeReadStatus.Read
              return old
            })
            break
          case EnumNoticeType.Classroom:
          case EnumNoticeType.Manual:
            break
          default:
            break
        }
        this.updateUnreadQuantity(this.unreadQuantity - 1)
        if (type === EnumNoticeType.System) {
          this.updateSystemNoticeQuantity(this.systemNotice.unreadQuantity - 1)
        }
      }
    } catch (error) {}
  }

  /**
   * 未读消息数量
   * @param type 通知类型
   */
  async onUnreadCount(type?: 1 | 2 | 3 | 4) {
    try {
      const resp = await this.$ajax.get<number>(EnumApiNotice.noticeUnreadQuantity, { type })
      switch (type) {
        case EnumNoticeType.Account:
        case EnumNoticeType.System:
        case EnumNoticeType.Classroom:
        case EnumNoticeType.Manual:
          break
        default:
          // 用户通知 + 系统通知
          this.updateUnreadQuantity(resp)
          break
      }
    } catch (error) {}
  }

  /**
   * 用户全部已读
   * @param type
   */
  async onAllReadNotice(type?: 1 | 2 | 3 | 4) {
    try {
      const resp = await this.$ajax.post<number>(EnumApiNotice.noticeAllRead, { type })
      switch (type) {
        // 用户通知 || 系统通知
        case EnumNoticeType.Account:
        case EnumNoticeType.System:
          const tempPagination = type === EnumNoticeType.Account ? this.noticeListPagination : this.systemListPagination
          tempPagination.onUpdateAll(old => {
            old.msgStatus = EnumNoticeReadStatus.Read
            return old
          })
          this.updateUnreadQuantity(this.unreadQuantity - resp)
          if (type === EnumNoticeType.System) {
            this.updateSystemNoticeQuantity(0)
          }
          break
        case EnumNoticeType.Classroom:
        case EnumNoticeType.Manual:
          break
        default:
          // 用户通知 + 系统通知
          this.noticeListPagination.onUpdateAll(old => {
            old.msgStatus = EnumNoticeReadStatus.Read
            return old
          })
          this.systemListPagination.onUpdateAll(old => {
            old.msgStatus = EnumNoticeReadStatus.Read
            return old
          })
          this.updateUnreadQuantity(0)
          this.updateSystemNoticeQuantity(0)
          break
      }
      return resp
    } catch (error) {}
  }

  @action
  updateUnreadQuantity(count: number) {
    this.unreadQuantity = count
  }

  @action
  updateSystemNoticeQuantity(count: number) {
    this.systemNotice.unreadQuantity = count
  }
}

export default MessageNotice
