
































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import agreementScript from './agreementScript'

@Observer
@Component({
  name: 'agreementComponents'
})
export default class agreementComponents extends agreementScript {}
