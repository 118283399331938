/**
 * @author 冷 (https://github.com/LengYXin)
 * @email lengyingxin8966@gmail.com
 * @create date 2020-09-25 16:02:09
 * @modify date 2020-09-25 16:02:09
 * @desc 感想
 */
import { BindAll } from 'lodash-decorators'
import { observable, runInAction, computed } from 'mobx'
import { EnumApiCourse } from '../../api'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { Pagination } from '../basics/pagination'
import lodash from 'lodash'

@BindAll()
export class CourseThoughts extends Pagination<any> {
  constructor($ajax: AjaxBasics) {
    super($ajax, {
      url: EnumApiCourse.CourseThought
    })
  }
  @observable
  MyCourseThout = {}

  @computed
  get CanWrite() {
    return lodash.isEmpty(this.MyCourseThout)
  }

  /**
   * 是否可以提交感想
   * @param id 课程id
   */
  async onGetMyCourseThought(id) {
    let MyCourseThout = await this.$ajax.get(EnumApiCourse.MyCourseThought, {
      id
    })
    runInAction(() => {
      this.MyCourseThout = MyCourseThout
    })
    return MyCourseThout
  }

  /**
   * 发布一条感想
   * @param body 内容 课程id
   */
  async onInstall(body: { id?; content?; courseId? }) {
    return this.$ajax.post(EnumApiCourse.CourseThought, body)
  }
}
export default CourseThoughts
