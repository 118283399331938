import { Observer } from 'mobx-vue'
import { Component } from 'vue-property-decorator'
import CommonOrderItemScript from '@xt/client/mixins/order/order'
import lodash from 'lodash'
import { EnumOrderState, EnumInvoiceState, EnumProductType } from '@xt/client/enums'

@Observer
@Component
export default class OrderItemScript extends CommonOrderItemScript {
  visibleOrderDescPopup: boolean = false // 是否展示订单详情弹出框

  showOrderDescPopup() {
    this.visibleOrderDescPopup = true
  }

  get cover() {
    return `${this.dataSource.origin.coverUrl}?x-oss-process=image/resize,w_${284},h_${174},m_fill/quality,q_90`
  }

  get title() {
    switch (this.dataSource.origin.productType) {
      case EnumProductType.dayCourse:
        return '教练日课'
      case EnumProductType.fullstage || EnumProductType.single:
        return '直播课'
      case EnumProductType.offlineExhibition || EnumProductType.onlineExhibition:
        return '展览'
      default:
      // 异常
    }
  }

  copyOrderId() {
    const disappear = 'position: fixed;z-index: -2;top: -100px'
    let input = document.createElement('input')
    input.value = this.dataSource.origin.orderNo
    input.setAttribute('style', disappear)
    document.body.appendChild(input)
    input.focus()
    input.select()
    if (document.execCommand('copy', false, null)) {
      //success info
      this.$msg('复制成功', 'success')
    } else {
      this.$msg('请手动复制订单编号')
    }
    document.body.removeChild(input)
  }

  get showInvoiceIng() {
    return (
      this.dataSource.orderStatus === EnumOrderState.HasPay &&
      this.dataSource.invoiceState === EnumInvoiceState.WaitInvoice &&
      (!this.dataSource.isGive || this.isMyGive) &&
      !this.dataSource.zeroOrder
    )
  }

  get ShowInvoiceSuccess() {
    return (
      this.dataSource.orderStatus === EnumOrderState.HasPay &&
      this.dataSource.invoiceState === EnumInvoiceState.HasInvoice &&
      (!this.dataSource.isGive || this.isMyGive) &&
      !this.dataSource.zeroOrder
    )
  }
}
