import { observable } from 'mobx'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import Course from './course'
export class ControllerActivitys {
  constructor(protected $ajax: AjaxBasics) {}
  Course = new Course(this.$ajax)

  /**
   * 更新 修改课程信息 重新计算状态
   * @param key
   */
  onUpdate(key) {
    this.Course.onUpdate(old => {
      return new Course(old.original)
    })
    // this.Pagination.onUpdate(key, (old) => {
    //   return new Course(old.original, [], true)
    // })
  }
}
export default ControllerActivitys
