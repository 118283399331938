
















import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
@Observer
@Component({
  components: {}
})
export default class PageView extends Vue {
  bowsers = [
    {
      title: 'Chrome',
      url: 'https://www.google.cn/chrome',
      logo: 'https://www.google.cn/chrome/static/images/chrome-logo.svg'
    },
    {
      title: 'Firefox',
      url: 'http://www.firefox.com.cn/',
      logo: 'https://www.firefox.com.cn/media/protocol/img/logos/firefox/browser/logo-lg.3d9087ac44e8.png'
    },
    {
      title: '360',
      url: 'https://browser.360.cn/ee/',
      logo: 'https://p0.ssl.qhmsg.com/t0183d516d5cc2b569c.png'
    },
    {
      title: 'Edge',
      url: 'https://www.microsoft.com/zh-cn/edge',
      logo: 'https://dss2.bdstatic.com/6Ot1bjeh1BF3odCf/it/u=3915001226,2908983139&fm=74&app=80&f=PNG&size=f121,121?sec=1880279984&t=869e19cff9c0e4492bf39b26d02427bd'
    }
  ]
  get title() {
    return this.$store.$global.WechatBowser ? '请用以下任一浏览器进入教室官网（xuantong.cn）哦~' : '浏览器版本过低'
  }
  get subTitle() {
    return this.$store.$global.WechatBowser ? '' : '请使用以下浏览器'
  }
  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
