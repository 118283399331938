











import lodash from 'lodash'
import { dataSource_face } from '@xt/client'
import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
// Vue.filter("face", function (value) {
//   if (!value) return "";
//   return value;
// });
/**
 * 转换 表情
 */
Vue.prototype.formatFace = function (html: string) {
  if (html) {
    const fas = lodash.uniq(html.match(/\[([\s\S]+?)\]/g))
    fas.map(value => {
      const face = lodash.find(dataSource_face, ['value', value])
      if (face) {
        const reg = new RegExp(`(\\${value})`, 'g')
        html = lodash.replace(
          html,
          reg,
          `<span class="ant-avatar ant-avatar-sm ant-avatar-circle ant-avatar-image xt-face"><img src="${face.icon}"></span>`
        )
      }
    })
  }
  return this.replaceHtml(html)
}
@Component({
  components: {}
})
export default class extends Vue {
  faces = dataSource_face
  @Emit('select')
  onFace(face) {
    return face
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
