import { render, staticRenderFns } from "./ring.vue?vue&type=template&id=c560564a&scoped=true&"
import script from "./ring.vue?vue&type=script&lang=js&"
export * from "./ring.vue?vue&type=script&lang=js&"
import style0 from "./ring.vue?vue&type=style&index=0&id=c560564a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c560564a",
  null
  
)

export default component.exports