












import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import QRCode from 'qrcode'
import { EnumUCAppId, EnumShowAppId } from '@xt/client/enums'

@Observer
@Component({
  components: {}
})
export default class extends Vue {
  @Prop({ default: false }) visible: boolean
  @Prop({ default: '' }) path: string
  @Prop({ default: false }) verifyLogin: boolean

  canvasHeight: number = 174
  canvasWidth: number = 174
  qrcodeUrl: string = ''
  loading: boolean = false

  get miniProgramInfo(): {
    platform: 'uc' | 'weapp'
    platformName: string
  } {
    const appid = new URLSearchParams(this.path).get('appid')
    if (!appid) return
    switch (appid) {
      case EnumUCAppId.TRIAL:
      case EnumUCAppId.RELEASE:
        return {
          platform: 'uc',
          platformName: '暄桐小程序'
        }
      case EnumShowAppId.TRIAL:
      case EnumShowAppId.RELEASE:
        return {
          platform: 'weapp',
          platformName: '暄桐展览小程序'
        }
      default:
        return {
          platform: 'uc',
          platformName: '暄桐小程序'
        }
    }
  }

  loadImage(url: string) {
    return new Promise(resolve => {
      let img: any = new Image()
      img.setAttribute('crossOrigin', 'anonymous')
      img.src = url
      img.onload = () => resolve(img)
      img.onerror = () => resolve(undefined)
    })
  }

  @Watch('visible')
  updateVisible(value: boolean) {
    if (!value) return
    this.$nextTick(async () => {
      this.loading = true
      let canvas: any = this.$refs['canvas']
      let ctx = canvas.getContext('2d')
      let dpr = window.devicePixelRatio || 1
      const time = 2
      canvas.width = this.canvasWidth * time * dpr
      canvas.height = this.canvasHeight * time * dpr
      QRCode.toCanvas(
        canvas,
        `${process.env.hubBaseUrl}/hub/${this.miniProgramInfo.platform}/landing/index.html?redirectPath=${encodeURIComponent(this.path)}${
          this.verifyLogin ? '&verifyLogin=true' : ''
        }`,
        // 备展详情
        // `${process.env.hubBaseUrl}/hub/weapp/landing/index.html?redirectPath=${encodeURIComponent(
        //   '/pages/levels/index?showId=118'
        // )}&verifyLogin=true`,
        // 练习习作详情
        // `${process.env.hubBaseUrl}/hub/weapp/landing/index.html?redirectPath=${encodeURIComponent(
        //   '/pages/levels/im/index?id=8492&worksType=1'
        // )}&verifyLogin=true`,
        // 正式习作详情
        // `${process.env.hubBaseUrl}/hub/weapp/landing/index.html?redirectPath=${encodeURIComponent(
        //   '/pages/levels/im/index?id=2029&worksType=2'
        // )}&verifyLogin=true`,
        // 作品详情
        // `${process.env.hubBaseUrl}/hub/weapp/landing/index.html?redirectPath=${encodeURIComponent(
        //   '/pages/webview/index?p=%2Fpart%2Fpractice%3FworksId%3D460'
        // )}`,
        // 作业详情
        // `${process.env.hubBaseUrl}/hub/uc/landing/index.html?redirectPath=${encodeURIComponent(
        //   '/pages/webview/index?p=%2Fmobile%2Fhomework%2Fdetail%2F524926'
        // )}`,
        {
          width: canvas.width,
          height: canvas.height,
          color: {
            dark: '#000000',
            light: '#FAF9F7'
          }
        },
        function (error) {
          if (error) console.error(error)
        }
      )
      // @ts-ignore
      let logo: any = await this.loadImage(
        this.miniProgramInfo.platform === 'uc' ? this.$images.xt_qrcode_logo : this.$images.xt_show_qrcode_logo
      )
      if (logo) ctx.drawImage(logo, canvas.width / 2 - 30 * dpr, canvas.height / 2 - 30 * dpr, 30 * 2 * dpr, 30 * 2 * dpr)
      let dataUrl = canvas.toDataURL('image/png')
      this.qrcodeUrl = dataUrl
      this.loading = false
    })
  }

  @Emit('cancel')
  cancelPopupHandler() {}

  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
