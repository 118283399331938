import { EnumBishan } from '../../api'
import { AjaxBasics } from '../../helpers'

export default class BishanWork {
  constructor(protected $ajax: AjaxBasics) {}
  async onRemind() {
    return await this.$ajax.get(EnumBishan.EXPIRE_TIP)
  }
  async read() {
    return await this.$ajax.get(EnumBishan.READ)
  }
}
