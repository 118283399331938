
















































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { MixinsRetrieve } from '@xt/client/mixins/index'
import lodash from 'lodash'

@Observer
@Component({
  components: {}
})
export default class PageView extends Mixins(MixinsRetrieve) {
  @Prop({ default: '' }) confirmCode // 获取前一个界面验证码
  passwordEye: boolean = false
  surePasswordEye: boolean = false
  get formOptions() {
    return {
      validDevice: this.validDevice,
      phone: this.phone,
      confirmCode: this.confirmCode,
      btnText: '保存修改',
      label: this.label,
      isLabel: false,
      isNeed: false,
      isInputChange: this.model.newPassword && this.model.confirmNewPassword,
      isInput: true,
      defaultValue: this.defaultValue,
      rules: this.formRules,
      layout: {
        labelCol: { span: 0 },
        wrapperCol: { span: 24 }
      },
      btnCol: { span: 24, offset: 0 }
    }
  }
  passswordInput(target) {
    target.value = target.value.replaceAll(' ', '')
  }
  created() {
    let phone = lodash.get(this.PageStore.UserInfo, 'phoneNum')
    let email = lodash.get(this.PageStore.UserInfo, 'email')
    if (email && !phone) {
      this.showback = false
      this.phone = false
      this.title = '修改密码'
      this.validDevice = false
      this.defaultValue = this.PageStore.UserInfo.email
    } else {
      this.showback = false
      this.phone = true
      this.title = '修改密码'
      this.validDevice = false
      this.defaultValue = this.PageStore.UserInfo.phoneNum
    }
  }
}
