


















import UserGroupPc from './userGroupScriptPc'
import { Component } from 'vue-property-decorator'

@Component({
  name: 'UserGroupCompPc'
})
export default class UserGroupComp extends UserGroupPc {}
