<template>
  <div>
    <a-modal
      :visible="true"
      destroyOnClose
      @cancel="onCancel"
      :footer="null"
      :width="`${$px2rem(414)}`"
      :height="`${$px2rem(474)}`"
      centered
      class="fixed-box"
    >
      <!-- <div class="fixed-bg"></div>     -->
      <!-- <div class="fixed-box"> -->
      <h3 class="fixed-title">确认兑换信息</h3>
      <div class="fixed-user-question-1">①这是你在“暄桐文房微信店”的账号吗？</div>
      <div class="fixed-user-info">
        <img
          :src="
            photo
              ? photo
              : 'https://file.xuantong.cn/images/origin/ca54b86becfb1a9320d5a5717b0f9abb.jpg?x-oss-process=image/resize,w_828,m_lfit'
          "
        />
        <div class="fixed-user-info-name-mobile">
          <p>昵称：{{ nickName }}</p>
          <p>手机号：{{ mobile }}</p>
        </div>
      </div>
      <div class="fixed-box-btm-box">
        <div class="fixed-quan-tips">
          ②是否使用 <span style="color: rgba(173, 145, 116, 1); font-size: 18px">{{ price }}</span>
          <img :src="$images.bishan_ic" style="opacity: 0.3" class="bishan-img" /> 兑换？
          <span class="color-666 fn12">（兑换后剩余</span>
          <span class="color-yellow fn12">{{ myBiShan - price }}</span>
          <img :src="$images.bishan_ic" style="opacity: 0.3" class="bishan-img" />
          <span class="color-666 fn12">）</span>
        </div>
        <div class="fixed-quan-box">
          <div class="fixed-quan-box-left">
            <div class="fixed-quan-box-money">
              ￥<b>{{ moneyLit / 100 }}</b>
            </div>
            <div class="fixed-quan-list-total fn12">满{{ moneyBig / 100 }}使用</div>
          </div>
          <div class="fixed-quan-box-right">
            <div class="color-yellow btm-line" style="display: inline-block">
              消耗：<span class="fn12">{{ price }}</span> <img class="bishan-img" :src="$images.bishan_ic" />
            </div>
            <!--期限：{{expireTime}}前可用-->
            <div class="fixed-quan-list-main-date fn12"><span>兑换后可在暄桐文房微信店使用</span></div>
          </div>
        </div>
        <div class="btn-box" @click="onCancel">
          <button class="cancel-btn" @click="onCancel">取消</button>
          <button class="confirm-btn" @click="onOk">是的</button>
        </div>
        <div class="close-btn" @click="onCancel"></div>
      </div>
      <!-- </div> -->
    </a-modal>
  </div>
</template>

<script>
import { Prop, Vue, Component } from 'vue-property-decorator'

@Component({
  scrollToTop: true
  //   components: { RenderItem }
})
export default class PageView extends Vue {
  @Prop({ default: () => '' }) nickName
  @Prop({ default: () => '' }) mobile
  @Prop({ default: () => '' }) moneyLit
  @Prop({ default: () => '' }) moneyBig
  @Prop({ default: () => '' }) myBiShan
  @Prop({ default: () => '' }) expireTime
  @Prop({ default: () => '' }) price
  @Prop({ default: () => '' }) photo
  closable = true
  visible = true

  onCancel() {
    this.$emit('cancelModel')
  }
  onOk() {
    this.$emit('onExchange')
  }
}
</script>

<style lang="less" scoped>
.fixed-box {
  box-sizing: border-box;
  /deep/ .ant-modal-body {
    padding: 0px 0px 30px;
    background: #fff;
    padding: 0px 30px 40px;
    box-sizing: border-box;
    background-image: url(https://static.xuantong.cn/points/integral_exchange01_bgpc.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-color: #f8f7f5;
  }
}
.close-btn {
  position: fixed;
  top: 21px;
  right: 21px;
  background: url(https://static.xuantong.cn/points/integral_shut_ic.png);
  background-size: 100% auto;
  width: 11px;
  height: 11px;
  z-index: 99999999111111111;
  cursor: pointer;
}

.fixed-title {
  margin-top: 38px;
  font-size: 18px;
  color: #333;
  text-align: center;
}
.fixed-user-question-1 {
  font-size: 14px;
  color: #333;
  margin-top: 24px;
  line-height: 28px;
}

.bishan-img {
  width: 14px;
  vertical-align: middle;
}
.fixed-quan-box {
  display: flex;
  margin: 18px auto 19px;
  width: 282px;
  height: 88px;
  background: url(https://static.xuantong.cn/points/integral_pop_red_bgpc_alert.png) no-repeat;
  background-size: 100% auto;
}
.fixed-quan-box-left {
  padding-top: 20px;
  width: 88px;
  text-align: center;
  font-size: 12px;
  color: #fff;
}
.fixed-quan-box-left b {
  font-size: 24px;
  color: #fff;
  font-weight: normal;
}
.fixed-quan-list-main-date {
  margin-top: 5px;
  overflow: hidden;
}
.fixed-quan-list-main-date span {
  transform: scale(0.83);
  transform-origin: left center;
  font-size: 12px;
  display: block;
  width: 120%;
  color: #999;
}
.fixed-quan-list-total {
  position: relative;
  top: -6px;
  opacity: 0.8;
}
.btm-line {
  border-bottom: 1px dotted #ad9174;
  padding-bottom: 7px;
}

.fixed-user-info {
  display: flex;
  margin-top: 15px;
}
.fixed-user-info-name-mobile p {
  margin-bottom: 0;
  height: 17px;
  line-height: 17px;
  margin-top: 5px;
}
.fixed-user-info > img {
  width: 60px;
  height: 60px;
  margin-right: 20px;
  border-radius: 50px;
}
.fixed-user-info > div {
  line-height: 22px;
  font-size: 12px;
  margin-top: 7px;
}
.fixed-box-btm-box {
  width: 100%;
  background: #f8f7f5;
  margin-top: 44px;
  overflow: hidden;
}
.fixed-quan-box-right {
  padding-left: 20px;
  padding-top: 20px;
  font-size: 12px;
}
.fixed-tip-mobile {
  text-align: center;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  line-height: 24px;
  margin-top: 60px;
  padding-bottom: 30px;
}
.fixed-tip-img {
  width: 100%;
}
.fixed-tip-erweima {
  text-align: center;
  margin-top: 30px;
}
.fixed-tip-erweima img {
  width: 88px;
  height: 88px;
  vertical-align: middle;
}
.fixed-tip-erweima p {
  color: rgba(187, 187, 187, 1);
  font-size: 12px;
  margin-top: 6px;
}

.fn12 {
  font-size: 12px;
}
.fixed-quan-tips {
  text-align: left;
  line-height: 2em;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  margin-top: 10px;
}
.btn-box {
  display: flex;
  width: 100%;
  margin-top: 49px;
}
.confirm-btn {
  width: 160px;
  height: 40px;
  background: #ad9174;
  border-radius: 4px;
  font-size: 18px;
  font-weight: normal;
  color: #ffffff;
  line-height: 36px;
  border: none;
  outline: none;
  cursor: pointer;
}
.cancel-btn {
  cursor: pointer;
  background: #eee;
  margin-right: 34px;
  border: none;
  width: 160px;
  height: 40px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: normal;
  color: #999999;
  line-height: 36px;
}
</style>
