import { uuid } from '@xt/client/utils/uuid'
import { MouseTrack } from './mouse-track'
import { KeyboardTrack } from './keyboard-track'
import { VisibleTrack } from './visible-track'
import { AjaxBasics } from '@xt/client'
import { reportEntryLog } from '@xt/client/utils/log'

function appendStorage(key: string, value: object) {
  try {
    const item = localStorage.getItem(key)
    if (!item) {
      localStorage.setItem(key, JSON.stringify([value]))
    } else {
      const itemValue = JSON.parse(item)
      if (Array.isArray(itemValue)) {
        itemValue.push(value)
        localStorage.setItem(key, JSON.stringify([itemValue]))
      }
    }
  } catch {}
}

const FLUSH_INTERVAL = 5 * 60 * 1000
const PAUSE_THRESHOLD = 30 * 1000

class Track {
  private uuid: string
  private isRunning = false
  private mouseTrack: MouseTrack
  private keyboardTrack: KeyboardTrack
  private visibleTrack: VisibleTrack
  private flushInterval: null | ReturnType<typeof setInterval> = null
  private lastSendTime: number
  private windowInfo = {
    width: window.outerWidth,
    height: window.outerHeight
  }
  private chunk = 0
  private $ajax: AjaxBasics

  constructor() {
    this.sendLastChunkData()

    this.uuid = uuid()
    this.lastSendTime = this.getServerTime()
    this.mouseTrack = new MouseTrack(PAUSE_THRESHOLD)
    this.keyboardTrack = new KeyboardTrack(PAUSE_THRESHOLD)
    this.visibleTrack = new VisibleTrack()

    this.stop = this.stop.bind(this)
    this.sendLog = this.sendLog.bind(this)
    this.flush = this.flush.bind(this)
    this.sendLastChunkData = this.sendLastChunkData.bind(this)
  }

  private getServerTime() {
    return AjaxBasics.serviceDate.valueOf()
  }

  private async sendLog(data: { mouse: Record<string, any>; keyboard: Record<string, any>; visible: Record<string, any> }) {
    try {
      const now = this.getServerTime()
      if (this.$ajax) {
        this.$ajax.post(
          '/moment/api/action/log',
          {
            ...data,
            uid: this.uuid,
            path: location.pathname,
            window: this.windowInfo,
            chunk: this.chunk,
            begin: this.lastSendTime,
            end: now,
            duration: now - this.lastSendTime
          },
          {
            Authorization: 'Basic dXNlci1jbGllbnQ6dXNlci1zZWNyZXQtODg4OA=='
          }
        )
      }
    } catch (e) {
      console.log(e)
      reportEntryLog('tracker.report.error', { e, uuid: this.uuid, path: location.pathname })
    } finally {
      this.chunk = this.chunk + 1
      this.lastSendTime = this.getServerTime()
    }
  }

  private sendLastChunkData() {
    try {
      const item = localStorage.getItem('unsend-track-data')
      if (item) {
        const itemValue = JSON.parse(item)

        if (Array.isArray(itemValue)) {
          itemValue.forEach(item => {
            if (this.$ajax) {
              this.$ajax.post('/moment/api/action/log', item, {
                Authorization: 'Basic dXNlci1jbGllbnQ6dXNlci1zZWNyZXQtODg4OA=='
              })
            }
          })
        }
      }
    } catch (e) {
      reportEntryLog('tracker.report-last-chunk-data.error', { e })
    } finally {
      localStorage.removeItem('unsend-track-data')
    }
  }

  private saveLastChunkData() {
    const now = this.getServerTime()
    const mouseFrames = this.mouseTrack.flush()
    const keyboardFrame = this.keyboardTrack.flush()
    const visibleFrame = this.visibleTrack.flush()

    appendStorage('unsend-track-data', {
      mouse: mouseFrames,
      keyboard: keyboardFrame,
      visible: visibleFrame,
      uid: this.uuid,
      path: location.pathname,
      window: this.windowInfo,
      chunk: this.chunk,
      begin: this.lastSendTime,
      end: now,
      duration: now - this.lastSendTime
    })
  }

  private flush() {
    // 定时五分钟清理一波数据
    this.flushInterval = setInterval(() => {
      const mouseFrames = this.mouseTrack.flush()
      const keyboardFrame = this.keyboardTrack.flush()
      const visibleFrame = this.visibleTrack.flush()

      this.sendLog({ mouse: mouseFrames, keyboard: keyboardFrame, visible: visibleFrame })
    }, FLUSH_INTERVAL)
  }

  record($ajax: AjaxBasics) {
    this.$ajax = $ajax
    if (this.isRunning) return

    this.isRunning = true

    this.mouseTrack.record()
    this.keyboardTrack.record()
    this.visibleTrack.record()

    this.flush()
    window.addEventListener('beforeunload', this.stop)
  }

  stop() {
    // 上报或缓存最后一批数据
    this.saveLastChunkData()

    this.mouseTrack.stop()
    this.keyboardTrack.stop()
    this.visibleTrack.stop()

    if (this.flushInterval) {
      clearInterval(this.flushInterval)
      this.flushInterval = null
    }
  }
}

const track = new Track()
;(window as any).track = track
export default track
