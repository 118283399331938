import { Component, Prop, Mixins } from 'vue-property-decorator'
import lodash from 'lodash'
import { toJS } from 'mobx'
import moment from 'moment'
import { Observer } from 'mobx-vue'
import { AjaxBasics } from '@xt/client/helpers'
import { MixinsCourseHelpers } from './helpers'
import { EnumNewCourseState } from '../../enums'

// 下单方式，内部应用不对外暴漏
enum EnumsPayType {
  Empty = 0,
  Give = 1,
  Self = 2
}

@Observer
@Component
export class CommodityLiveCourseMixins extends Mixins(MixinsCourseHelpers) {
  /** 课程数据 */
  @Prop({ default: () => ({}) }) dataSource: any
  /** 进详情 */
  @Prop({ default: false }) to

  // 待支付订单的选择弹窗
  checkPopupData: any = {}
  visibleCheckPopup: boolean = false

  // 倒计时
  downtimeInterval: NodeJS.Timer
  countdownText: string = String()

  // 0 不显示协议 1 赠课 2 自己购买 3 团购
  visibleAgreement: number = EnumsPayType.Empty
  visibleAgreementPopup: boolean = false

  // 控制是否是待支付订单（具体字段不知道啥意思）
  payOrigin: boolean = false
  courseStatus: string = '' // 课程状态
  courseStyle: string = '' // 课程样式

  loading: boolean = false // 防止用户重复点击赠课｜ 自己报 ｜ 团购按钮

  //浮条倒计时
  statusText: string = ''
  downTimer: NodeJS.Timer

  /** 按钮的禁用样式 */
  get courseBtnDisabled() {
    return ((this.courseIsSignUpEnd || this.courseIsStart || this.courseIsEnd) && '-disabled') || ''
  }

  get WechatBowser() {
    return this.$store.$global.WechatBowser
  }

  // 平台
  get platform() {
    return this.$store.$global.platform
  }

  get Mobile() {
    return this.platform === 'Mobile'
  }

  get courseCanSignUp() {
    return this.dataSource.product.origin.canSignUp
  }

  get isSignUp() {
    return this.courseStatus === EnumNewCourseState.InProgress
  }

  get isWaitSignUp() {
    return this.courseStatus === EnumNewCourseState.NotStarted
  }

  get courseIsSignUpEnd() {
    return this.courseStatus === EnumNewCourseState.SignupEnd
  }

  get courseIsStart() {
    return this.courseStatus === EnumNewCourseState.InClass
  }

  get courseIsEnd() {
    return this.courseStatus === EnumNewCourseState.EndofClass
  }

  get isSignUpEnd() {
    return (
      this.courseStatus === EnumNewCourseState.SignupEnd ||
      this.courseStatus === EnumNewCourseState.InClass ||
      this.courseStatus === EnumNewCourseState.EndofClass
    )
  }

  get canGive() {
    return this.isSignUp && !this.isZeroPrice
  }

  /** 购买方式是赠课 */
  get payTypeIsGive() {
    return this.visibleAgreement === EnumsPayType.Give
  }

  get agreementTitle() {
    switch (this.visibleAgreement) {
      case EnumsPayType.Empty:
        return ''
      case EnumsPayType.Give:
        return '赠课前请了解'
      default:
        return '报名前请了解'
    }
  }

  get userIsSignUp(): boolean {
    let currentTime = this.$ajax.serviceDate().valueOf()
    let { learnEndTime } = this.dataSource
    return this.dataSource.hasOwn && currentTime < learnEndTime
  }

  //是否是0元单
  get isZeroPrice() {
    return !this.dataSource.product.origin.originalPrice
  }

  isEmpty(data) {
    return lodash.isEmpty(toJS(data))
  }

  //客服
  openZhichiCustom() {
    document.getElementById('zhiChiCustomModel').click()
  }

  btn() {
    let currentTime = this.$ajax.serviceDate().valueOf()
    switch (true) {
      // 如果报名未开启或者报名开始时间大于当前时间则状态为即将报名
      case !this.dataSource.product.origin.canSignUp || currentTime < this.dataSource.signUpStartTime:
        this.courseStyle = '-light-red'
        this.courseStatus = EnumNewCourseState.NotStarted
        return
      // 报名开始时间小于等于当前时间并且报名结束时间大于当前时间则状态为正在报名
      case currentTime >= this.dataSource.signUpStartTime && currentTime <= this.dataSource.signUpEndTime:
        this.courseStyle = '-light-red-hover'
        this.courseStatus = EnumNewCourseState.InProgress
        return
      // 如果当前时间在学习开始时间与学习结束时间中  则状态为开课中
      case currentTime >= this.dataSource.learnStartTime && currentTime <= this.dataSource.learnEndTime:
        this.courseStyle = '-yellow'
        this.courseStatus = EnumNewCourseState.InClass
        return
      case currentTime > this.dataSource.learnEndTime:
        this.courseStyle = '-grey'
        this.courseStatus = EnumNewCourseState.EndofClass
        return
      // 如果报名结束小于等于当前时间 & 备展开始时间大于当前时间  则状态为报名结束
      case currentTime > this.dataSource.signUpEndTime:
        this.courseStyle = '-light-red'
        this.courseStatus = EnumNewCourseState.SignupEnd
        return
      default:
        this.courseStyle = '-light-red'
        this.courseStatus = EnumNewCourseState.NotStarted
        return
    }
  }

  /** 利用倒计时进行状态过渡 */
  downtime(endTime: number) {
    let diffText: string = ''
    this.downTimer = setInterval(() => {
      let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf() // 当前时间
      let diff = endTime - currentTime // 正常报时间差
      switch (true) {
        case diff < 0: // 如果正常报名结束了，就停止定时器
          clearInterval(this.downTimer)
          this.downTimer = null
          diffText = ''
          this.btn()
          break
        case diff < 24 * 60 * 60 * 1000: // 如果小于24小时显示倒计时
          diffText =
            (this.Mobile &&
              `报名剩余：${moment(endTime - currentTime)
                .utcOffset(0)
                .format('{HH}:{mm}:{ss}')}`) ||
            `报名剩余：${moment(endTime - currentTime)
              .utcOffset(0)
              .format('HH:mm:ss')}`
          break
        default: // 大于24小时  H5展示天  PC展示报名截至xxxx.xx.xx
          diffText =
            (this.Mobile && `报名剩余 {${this.computeDay(currentTime, endTime)}}天`) ||
            `报名剩余 ${this.computeDay(currentTime, endTime)}天`
      }
      this.statusText = diffText
    }, 200)
  }

  initDownTime() {
    const { signUpStartTime, signUpEndTime } = this.dataSource
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    switch (true) {
      // 处于即将报名状态 & 报名开启了
      case this.isWaitSignUp && this.courseCanSignUp:
        return (this.statusText = `报名时间：${moment(signUpStartTime).format('YYYY.MM.DD')}`)
      // 正在报名（不管是有没有团购  不管是有没有24个小时  都开启倒计时）
      case this.isSignUp:
        return this.downtime(signUpEndTime)
      default:
        return (this.statusText = '')
    }
  }

  async onSignUp(give: boolean = false) {
    if (this.loading) return
    this.loading = true
    try {
      if (this.to) {
        //TODO:不知道在干啥  之前的逻辑
        this.$router.push({
          name: 'course-id',
          params: { id: lodash.get(this.dataSource, 'id', this.to) }
        })
        this.loading = false
        return
      }
      // 检测用户是否登录
      this.$InspectUser()
      // 检验是否需要完善用户信息
      this.onCheckDevice()
      // 如果该用户不能报名，弹Toast提示
      if (!this.dataSource.canSignUp && !give) {
        this.$msg('未报名指定课程，无法参加日课', 'warning')
        this.loading = false
        return
      }
      // 如果是赠课那么到下面IF代码块结束
      if (give) {
        // 展示赠课协议
        this.visibleAgreement = EnumsPayType.Give
        this.visibleAgreementPopup = true
        this.loading = false
        return
      }
      let res: any = await this.$store.$storeOrder.checkBeforeOrder(this.dataSource.product.origin.id, false)
      if (res.oldOrder && res.oldOrderNo) {
        this.popOrderChoose(res)
        this.loading = false
        throw new Error('Waiting For Payment')
      }
      this.visibleAgreement = EnumsPayType.Self
      this.visibleAgreementPopup = true
    } catch (err) {
      console.log(err)
      this.visibleAgreement = EnumsPayType.Empty
      this.visibleAgreementPopup = false
    }
    this.loading = false
  }

  /** 单独购买  取消订单 */
  async singleBuyCancel(res: any) {
    this.payOrigin = false
    await this.$store.$storeOrder.onOrderCancel({ orderNo: res.oldOrderNo })
    this.visibleAgreement = EnumsPayType.Self
    this.visibleAgreementPopup = true
  }

  /** 单独购买 继续支付 **/
  singleBuyContinue(res: any) {
    this.payOrigin = true
    let query = {
      orderNo: String(res.oldOrderNo),
      productId: this.dataSource.product.origin.id,
      give: res.buyWay == 1 ? String(false) : String(true),
      memberBeforeOption: String(this.payOrigin)
    }
    this.toPayment(query)
  }

  popOrderChoose(res: any) {
    this.checkPopupData = {
      title: '你已点过【立即报名】啦，但还没有支付',
      waitPrice: res.payAmount,
      count: res.virtualCurrency / 100 / 8,
      ratio: 8,
      mainText: '继续支付', //右侧按钮
      viceText: '取消订单，重新报名',
      mainHeader: '',
      mainHandler: () => {
        this.visibleCheckPopup = false
        this.singleBuyContinue(res)
      },
      viceHandler: async () => {
        this.visibleCheckPopup = false
        this.singleBuyCancel(res)
      }
    }
    this.visibleCheckPopup = true
  }

  onAgreementCancel() {
    this.visibleAgreement = EnumsPayType.Empty
    this.visibleAgreementPopup = false
  }

  /** 跳转支付页面 */
  toPayment(query) {
    if (this.$store.$global.isEmbeddedH5Page) {
      const querySplice = this.$router.resolve({ query }).href.split('?')[1]
      this.onAgreementCancel()
      wx.miniProgram.navigateTo({
        url: `/pages/payment/index?${querySplice}`
      })
      return
    }
    // 微信内没有wxopenid id
    if (this.$store.$global.WechatBowser && this.Mobile && !this.$store.$storeUser.wxOpenid) {
      // 获取 code 换取 openid
      window.location.replace(
        this.$store.$wechat.getAuthorizeUrl(window.location.origin + this.$router.resolve({ name: 'payment', query }).href, 'snsapi_base')
      )
      return false
    }
    this.$router.push({
      name: 'payment',
      query
    })
  }

  /** 协议弹出框跳转支付页面 */
  agreementToPayment() {
    let query = {
      give: String(this.payTypeIsGive),
      orderNo: undefined,
      productId: this.dataSource.product.origin.id,
      memberBeforeOption: this.payOrigin ? 'true' : 'false'
    }
    this.toPayment(query)
  }

  /** 检测用户完善个人信息 */
  onCheckDevice() {
    if (this.$store.$storeUser.needBindDevice) {
      if (this.platform === 'Mobile') this.$router.push({ name: 'other-bind' })
      else this.$store.$storeUser.onToggleModal(this.$EnumLocaleLinks.links_bind_device)
      throw new Error('Need Bind Device')
    }
    //   用户需要完善个人信息
    if (this.$store.$storeUser.onneedCompleteInfo()) {
      this.$store.$storeUser.onToggleModal(this.$EnumLocaleLinks.links_complete)
      throw new Error('Need Complete Info')
    }
  }

  computeDay(currentTime, endtime) {
    return Math.floor(this.moment(endtime).diff(currentTime, 'hour') / 24)
  }

  created() {}
  mounted() {
    this.btn()
    this.initDownTime()
  }
  updated() {}
  destroyed() {}
}
