


















import { Observer } from 'mobx-vue'
import { Component } from 'vue-property-decorator'
import OrderPayChooserScript from '@xt/client/mixins/orderpay/chooser'

@Observer
@Component
export default class ChooserComp extends OrderPayChooserScript {}
