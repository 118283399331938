



import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class extends Vue {
  mounted() {}
  updated() {}
  destroyed() {}
}
