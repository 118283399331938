














import { Component, Vue, Emit, Prop } from 'vue-property-decorator'

@Component
export default class CircleGroup extends Vue {}
