



























import { Component, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
@Observer
@Component({
  components: {}
})
export default class extends Vue {
  @Prop({ default: false }) showLink: boolean

  hover: boolean = false
  hoverIndex: number = 0
  linkData = Object.freeze([
    {
      icon_box_bg: '#DCD2C4',
      qrcode_icon: this.$images.mine_im_xuantong,
      qrcode_text: this.ossProcessImg(this.$images.mine_im_xuantong_text, 56, 24),
      qrcode_text_class: 'qrcode-text-xuantong',
      qrcode_url: this.$images.mine_im_xuantong_qrcode,
      qrcode_tip: '笔墨点亮生活'
    },
    {
      icon_box_bg: '#CBCCC1',
      qrcode_icon: this.$images.mine_im_daily,
      qrcode_text: this.ossProcessImg(this.$images.mine_im_daily_text, 112, 28),
      qrcode_text_class: 'qrcode-text-daily',
      qrcode_url: this.$images.mine_im_daily_qrcode,
      qrcode_tip: '安心跟随，日日精进'
    },
    {
      icon_box_bg: '#EFE6E3',
      qrcode_icon: this.$images.mine_im_exhibition,
      qrcode_text: this.ossProcessImg(this.$images.mine_im_exhibition_text, 108, 28),
      qrcode_text_class: 'qrcode-text-exhibition',
      qrcode_url: this.$images.mine_im_exhibition_qrcode,
      qrcode_tip: '开启你的书画创作之旅'
    },
    {
      icon_box_bg: '#E1D6C9',
      qrcode_icon: this.$images.mine_im_study,
      qrcode_text: this.ossProcessImg(this.$images.mine_im_study_text, 108, 24),
      qrcode_text_class: 'qrcode-text-study',
      qrcode_url: this.$images.mine_im_study_qrcode,
      qrcode_tip: '入文房，养心境'
    }
  ])

  stopHandler(e: MouseEvent) {
    e.stopPropagation()
  }

  mouseEnterHandler(idx: number) {
    this.hoverIndex = idx
    this.hover = true
  }

  mouseLeaveHandler() {
    this.hover = false
  }

  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
