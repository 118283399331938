











































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import MapClasshour from './classhour.vue'
import MapDetails from './details.vue'
import MapReview from './review.vue'
import { MixinsCourseMap } from '@xt/client/mixins'
@Observer
@Component({
  components: { MapClasshour, MapDetails, MapReview }
})
export default class PageView extends Mixins(MixinsCourseMap) {
  // popover 容器
  getPopupContainer(triggerNode) {
    return this.$refs.content
  }
  async created() {
    try {
      await this.onLoading(this.id)
    } catch (error) {
      console.error('课时数据', error)
    }
  }
  mounted() {
    // this.onVisible({ classhourId: 339, createTime: Date.now() });
  }
  updated() {}
  destroyed() {}
}
