export function getRandom(min: number, max: number) {
  const floatRandom = Math.random()

  const difference = max - min

  // 介于 0 和差值之间的随机数
  const random = Math.round(difference * floatRandom)

  const randomWithinRange = random + min

  return randomWithinRange
}
