/** * wangsl */
<template>
  <div class="share">
    <LazyImg className="share-left-ele" :src="ossProcessImg($images.share_left_ele, 187, 140)" :noBackground="true" />
    <LazyImg className="share-right-ele" :src="ossProcessImg($images.share_right_ele, 271, 281)" :noBackground="true" />
    <div class="share-content">
      <div class="share-title">
        <span>文章分享</span>
        <span>文人的生活与快乐</span>
      </div>
      <div
        @click="e => jumpHandler(e, item)"
        class="share-item"
        v-for="(item, index) in list"
        :key="index"
        :style="`
          z-index: ${index === current ? 10 : 5};
          opacity: ${index === current ? 1 : 0};
          transition: opacity ${index === current ? 0.85 : 0.25}s;
        `"
      >
        <div class="share-item-text" :style="`background-color: ${color}`">
          <div class="share-item-text-title">{{ item.title }}</div>
          <div class="share-item-text-dec">{{ item.intro }}</div>
          <div class="share-item-text-button">
            <button @click="e => jumpHandler(e, item)" :style="`color: ${color}`">查看全文</button>
            <i class="iconfont iconshare_arrow_ic"></i>
          </div>
        </div>
        <div class="share-item-text-shadow" :style="`background-color: ${color}`"></div>
        <div class="share-item-cover">
          <LazyImg :src="ossProcessImg(item.originalUrl, 680, 420, true)" />
        </div>
      </div>
      <div class="swiper-progress" v-if="this.list.length > 1">
        <i
          @click="upPage"
          :class="`
            iconfont 
            ${!upBtnHover ? 'iconbanner_switch_ic' : 'iconbanner_switch_hover_ic'} 
            swiper-progress-up 
            ${upBtnHover && 'swiper-progress-up-hover'} 
            ${!current && 'swiper-progress-up-hide'}
          `"
          @mouseover="upBtnHover = true"
          @mouseout="upBtnHover = false"
        ></i>
        <div
          :key="item.id"
          v-for="(item, index) in list"
          :class="`swiper-progress-point ${index === current && 'swiper-progress-point-selected'}`"
        ></div>
        <i
          @click="nextPage"
          :class="`
            iconfont 
            ${nextBtnHover && !(this.current === this.list.length - 1) ? 'iconbanner_switch_hover_ic' : 'iconbanner_switch_ic'} 
            swiper-progress-next
            ${nextBtnHover && 'swiper-progress-next-hover'} 
            ${this.current === this.list.length - 1 && 'swiper-progress-next-no'} 
          `"
          @mouseover="nextBtnHover = true"
          @mouseout="nextBtnHover = false"
        ></i>
      </div>
    </div>
    <div class="more" @click="window.open('/about?active=1')">查看更多</div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator'
import { toJS } from 'mobx'
import LazyImg from '@/components/lazyImg/index.vue'
const randomColors = ['#C6C0BC', '#BCBFC6', '#C0BCC6', '#C6BCBC', '#C6C6BC', '#BCC5C6']

@Component({
  components: {
    LazyImg
  }
})
class Index extends Vue {
  data() {
    return {
      list: [],
      current: 0,
      upBtnHover: false,
      nextBtnHover: false,
      color: randomColors[Math.floor(Math.random() * 6)]
    }
  }
  jumpHandler(e, item) {
    e.stopPropagation()
    this.window.open(item.jumpUrl)
    this.$store.$storeAbout.onAboutViewAdd(item.id)
  }
  async created() {
    await this.$store.$storeHome.onGetArticleRecommend()
    this.list = toJS(this.$store.$storeHome.ArticleRecommend)
  }
  nextPage() {
    if (this.current === this.list.length - 1) return
    this.current += 1
    this.color = this.getRandomColor()
  }
  get window() {
    return window
  }
  upPage() {
    if (this.current === 0) return
    this.current -= 1
    this.color = this.getRandomColor()
  }
  getRandomColor() {
    let color = randomColors[Math.floor(Math.random() * 6)]
    return color == this.color ? this.getRandomColor() : color
  }
}

export default Index
</script>

<style scoped lang="less">
.share {
  width: 100vw;
  min-width: 1200px;
  height: 944px;
  background: #f6f6f6;
  position: relative;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-left-ele {
    position: absolute;
    width: 187px;
    height: 140px;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 160px;
    transform: translateX(-470px);
    user-select: none;
    -webkit-user-drag: none;
  }

  &-right-ele {
    position: absolute;
    width: 271px;
    height: 281px;
    margin: auto;
    left: 0;
    right: 0;
    top: 130px;
    transform: translateX(550px);
    user-select: none;
    -webkit-user-drag: none;
  }

  &-content {
    width: 1200px;
    min-width: 1200px;
    height: 100%;
    z-index: 7;
    position: relative;
    display: inline-block;
  }
  &-title {
    vertical-align: bottom;
    margin-top: 100px;
    span {
      &:first-child {
        font-size: 34px;
        font-weight: 500;
        color: #000000;
        line-height: 34px;
      }
      &:last-child {
        font-size: 18px;
        color: #000000;
        margin-left: 17px;
      }
    }
  }
  &-item {
    width: 1200px;
    height: 490px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 194px;
    &:hover {
      .share-item-cover img {
        transform: scale(1.1);
      }
      .share-item-text {
        filter: saturate(1.5);
      }
    }
    &-text {
      width: 620px;
      height: 383px;
      background: #c6bcbc;
      border-radius: 2px 40px 2px 2px;
      z-index: 7;
      position: absolute;
      left: 0;
      top: 0;
      padding-top: 60px;
      padding-left: 60px;
      &-title {
        font-size: 24px;
        font-weight: 500;
        color: #ffffff;
        line-height: 36px;
        width: 430px;
        display: -webkit-box; /**对象作为伸缩盒子模型展示**/
        -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
        -webkit-line-clamp: 2; /**显示的行数**/
        overflow: hidden; /**隐藏超出的内容**/
      }
      &-dec {
        margin-top: 25px;
        font-size: 14px;
        color: #ffffff;
        line-height: 28px;
        width: 430px;
        display: -webkit-box; /**对象作为伸缩盒子模型展示**/
        -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
        -webkit-line-clamp: 4; /**显示的行数**/
        overflow: hidden; /**隐藏超出的内容**/
      }
      &-button {
        position: absolute;
        bottom: 55px;
        display: flex;
        align-items: center;
        button {
          width: 96px;
          height: 34px;
          background: #ffffff;
          border-radius: 2px;
          outline: none;
          border: none;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: #ad9174;
          cursor: pointer;
        }
        i {
          font-size: 35px;
          color: white;
          margin-left: 10px;
        }
      }
      &-shadow {
        width: 620px;
        height: 383px;
        opacity: 0.15;
        border-radius: 2px 40px 2px 2px;
        z-index: 5;
        left: 20px;
        top: 20px;
        position: absolute;
      }
    }
    &-cover {
      width: 680px;
      height: 420px;
      background: #d8d8d8;
      border-radius: 50px 2px 2px 50px;
      position: absolute;
      top: 70px;
      right: 0;
      z-index: 10;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        user-select: none;
        -webkit-user-drag: none;
        transition: transform 0.5s;
      }
    }
  }
}
.more {
  width: 120px;
  height: 36px;
  border-radius: 19px;
  border: 1px solid #8c96a8;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #8c96a8;
  margin-top: 21px;
  cursor: pointer;
  position: absolute;
  margin: auto;
  bottom: 130px;
  left: 0;
  right: 0;
  z-index: 40;

  &:hover {
    border: 1px solid #8c96a8;
    background: #8c96a8;
    color: white;
  }
}
.swiper {
  &-progress {
    height: 43px;
    margin-top: 30px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    z-index: 99;
    right: 0;
    bottom: 180px;
    &-up {
      font-size: 43px;
      border-radius: 50%;
      margin-right: 16px;
      color: #8c96a8;
      transform: rotateY(180deg);
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.25s;
      &-hide {
        opacity: 0;
        cursor: url(https://static.xuantong.cn/images/cur.png?22), auto;
      }
      &-hover {
        color: #8c96a8;
      }
    }
    &-next {
      font-size: 43px;
      border-radius: 50%;
      margin-left: 16px;
      color: #8c96a8;
      cursor: pointer;
      &-hover {
        color: #8c96a8;
      }
      &-no {
        color: #d8d8d8;
      }
    }
    &-point {
      width: 8px;
      height: 8px;
      background: #d8d8d8;
      margin: 0 5px;
      border-radius: 50%;
      &-selected {
        background: #8c96a8;
      }
    }
  }
}
</style>
