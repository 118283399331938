import { render, staticRenderFns } from "./msAlert.vue?vue&type=template&id=da0d887c&scoped=true&"
import script from "./msAlert.vue?vue&type=script&lang=ts&"
export * from "./msAlert.vue?vue&type=script&lang=ts&"
import style0 from "./msAlert.vue?vue&type=style&index=0&lang=less&"
import style1 from "./msAlert.vue?vue&type=style&index=1&id=da0d887c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da0d887c",
  null
  
)

export default component.exports