import { AjaxBasics } from '@xt/client'
import { onCreateRootStore } from '@xt/client/store'
import { message } from 'ant-design-vue'
import lodash from 'lodash'
// import { getXTDevId } from '@xt/client/utils/uuid'

const root = onCreateRootStore()

// 设置公共请求头，以x-开头的最终发送请求时会被合并
AjaxBasics.headers = {
  ...AjaxBasics.headers,
  'x-platform': 'pc',
  'x-version': root.RootSrore.$global.version,
  'x-appid': root.RootSrore.$global.xtAppid,
  // 'x-xtDevId': getXTDevId('PC')
}

root.onResetAjaxBasics({
  msg: error => {
    console.log('LENG ~ error', error, message)
    message.warn({
      content: lodash.get(error, 'message'),
      key: 'message'
    })
  }
})
root.onCreatePersist()
export const ajax = root.ajax
export default root.RootSrore
