/**
 * 课程状态枚举
 */
export enum EnumCourseState {
  /** 报名未开始 */
  NotStarted = '报名未开始',
  /** 正在报名 */
  InProgress = '正在报名',
  /** 未开课 */
  NoClass = '未开课',
  /** 开课中 */
  InClass = '开课中',
  /** 已结课 */
  EndofClass = '已结课'
}

export enum EnumNewCourseState {
  /** 报名未开始 */
  NotStarted = '即将报名',
  /** 正在报名 */
  InProgress = '立即报名',
  /** 报名结束 */
  SignupEnd = '报名结束',
  /** 开课中 */
  InClass = '开课中',
  /** 已结课 */
  EndofClass = '已结课'
}

export enum EnumAllCourseState {
  /** 报名未开始 */
  NotStarted = '即将报名',
  /** 正在报名 */
  InProgress = '我要报名',
  /** 未开课 */
  Signuped = '报名结束',
  /** 转报全阶 */
  toAll = '转报全阶',
  /** 开课中 */
  InClass = '开课中',
  /** 已结课 */
  EndofClass = '已结课'
}

export enum EnumHotCourseStyle {
  /** 报名未开始 */
  NotStarted = '-red-no',
  /** 正在报名 */
  InProgress = '-red',
  /** 未开课 */
  Signuped = '-red-no',
  /** 转报全阶 */
  toAll = '-red',
  /** 开课中 */
  InClass = '-yellow',
  /** 已结课 */
  EndofClass = '-black'
}

/**
 * 日课报名
 * **/
export enum EnumDayCourseState {
  /** 报名未开始 */
  NotStarted = '报名未开始',
  /** 正在报名 */
  InProgress = '正在报名',
  endSignup = '报名结束'
}
export enum EnumCourseSignupType {
  /** 自己购买 */
  Purchase = 'Purchase',
  /** 赠送 */
  Give = 'Give',
  /** 礼物 */
  Gift = 'Gift',
  /** 活动进度 */
  Progress = 'Progress',
  /** 团购购买 **/
  groupBuy = 'groupBuy',
  /** 去上课 */
  GoCourse = 'GoCourse'
}
/** 课程状态（1未发布，2已发布，3已下架） */
export enum EnumCoursePublishStatus {
  /** 未发布 */
  Unpublished = 1,
  /** 已发布 */
  Published = 2,
  /** 已下架 */
  Offtheshelf = 3
}
/** 课程类型 */
export enum EnumCourseType {
  /** 单 */
  single = 1,
  /** 全 */
  full = 2
}

/**
 * 课时状态枚举
 */
export enum EnumClasshour {}
