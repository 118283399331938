/**
 * @author 冷 (https://github.com/LengYXin)
 * @email lengyingxin8966@gmail.com
 * @create date 2020-10-20 20:25:53
 * @modify date 2020-10-20 20:25:53
 * @desc 课程协议
 */
import { Component, Prop, Vue, Emit, Mixins } from 'vue-property-decorator'
import lodash from 'lodash'
import { MixinsCourseHelpers } from './helpers'
import { EnumCourseSignupType } from '../../enums'
import { MixinsBasicsHelpers } from '../basics/helpers'

@Component
export class MixinsCourseAgreement extends Mixins(MixinsBasicsHelpers) {
  /** 课程数据 */
  @Prop({ default: () => ({}) }) dataSource: ICourseDetails
  @Prop({ default: EnumCourseSignupType.Purchase }) type
  deadline = this.moment().add(5, 'seconds').valueOf()
  countdown = true
  checked = false
  visible = false

  get disabled() {
    return !this.checked || this.countdown
  }

  get PageStore() {
    return this.$store.$storeCourse
  }
  get UserStore() {
    return this.$store.$storeUser
  }

  onFinish() {
    this.countdown = false
  }
  // 协议内容
  agreement = {
    id: 0,
    courseId: 0,
    title: '',
    promptSelf: '',
    promptGift: '',
    content: ''
  }
  get title() {
    return {
      [EnumCourseSignupType.Purchase]: '报名前请了解',
      [EnumCourseSignupType.Give]: '赠课前请了解',
      [EnumCourseSignupType.Gift]: '赠课前请了解'
    }[this.type]
  }
  get content() {
    return {
      [EnumCourseSignupType.Purchase]: this.agreement.promptSelf,
      [EnumCourseSignupType.Give]: this.agreement.promptGift,
      [EnumCourseSignupType.Gift]: this.agreement.promptGift
    }[this.type]
  }
  get welcome() {
    let giveWelcome = '在Ta拆开课程礼物之后，可以前往课程页面【开课前要准备什么】中查看以上信息哦～'
    return {
      [EnumCourseSignupType.Purchase]: '祝您在暄桐教室学习开心~',
      [EnumCourseSignupType.Give]: giveWelcome,
      [EnumCourseSignupType.Gift]: giveWelcome
    }[this.type]
  }
  toPayment() {
    const params = {
      productId: lodash.get(this.dataSource, 'original.productInfo.id'),
      // group : this.dataSource.original.productInfo.activityType==1 ? true : false,
      // groupNo : this.dataSource.original.productInfo.groupNo,
      memberBeforeOption: this.$route.query?.memberBeforeOption == 'true' ? 'true' : 'false',
      give: `${lodash.includes([EnumCourseSignupType.Give, EnumCourseSignupType.Gift], this.type)}`
    }

    if (this.$store.$global.isEmbeddedH5Page) {
      const querySplice = this.$router.resolve({ query: params }).href.split('?')[1]
      // 关闭弹窗
      this.$emit('cancel')
      wx.miniProgram.navigateTo({
        url: `/pages/payment/index?${querySplice}`
      })
      return
    }

    this.BasicsHelpers.toPayment(params)
  }
  async onLoad() {
    const res = await this.PageStore.onGetCourseProtos(this.dataSource.original.productInfo.id)
    this.agreement = res
  }
  @Emit('cancel')
  onCancel() {
    return true
  }
}
