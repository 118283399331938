/*
 * @Author: Erlin
 * @CreateTime: 2021-01-07 17:40:25
 * @LastEditors: Erlin
 * @LastEditTime: 2021-01-07 17:40:37
 * @Description: 作业详情
 */

import lodash from 'lodash'
import moment from 'moment'
import { AjaxBasics } from '../../helpers'
/**
 * 获取课程状态
 * @param dataSource 课程数据
 * @param isList 列表
 */
export function onComputeState(entities): any {
  const serviceDate = AjaxBasics.serviceDate.startOf('seconds').valueOf() + 100
  const learnEndTime = moment(entities.learnEndTime).startOf('seconds').valueOf()
  if (lodash.min([serviceDate, learnEndTime]) === learnEndTime) {
    entities._FullEndofClass = true
  } else {
    entities._FullEndofClass = false
  }
  return entities
}
