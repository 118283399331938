import { BindAll } from 'lodash-decorators'
import { EnumApiVideo } from '../../api'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { Pagination } from '../basics/pagination'
import { EnumMessage } from '../../languages'

@BindAll()
export class VideoCommentPagination extends Pagination<any> {
  constructor(protected $ajax: AjaxBasics) {
    super($ajax, {
      url: EnumApiVideo.VideoCommentList
    })
  }

  /**
   * 发布一条作业
   */
  // async onInstall(body: {
  //   content
  //   contentLength?
  //   videoShareId
  //   toCommentId?
  // }) {
  //   return this.$ajax.post(EnumApiVideo.VideoComment, body)
  // }

  /**
   * 视频分享 评论点赞
   */
  async onLikes(data) {
    try {
      if (data.liked) {
        throw EnumMessage.like_error
      }
      await this.$ajax.put(EnumApiVideo.VideoCommentLike, {
        id: data.id,
        likeType: true
      })
      this.onUpdate(data, old => {
        old.likeCount++
        old.liked = true
        return old
      })
    } catch (error) {
      throw error
    }
  }
}
export default VideoCommentPagination
