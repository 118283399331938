












































import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import understand from './understand.vue'
@Observer
@Component({
  components: { understand }
})
export default class PageView extends Vue {
  get PageStore() {
    return this.$store.$storeCourse.Details
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
