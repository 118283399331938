










































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import { MixinsCalendarCard } from '@xt/client/mixins'

@Component
export default class CalendarDay extends Mixins(MixinsCalendarCard) {}
