// 展览状态
export enum EnumShowStatus {
  /** 即将报名 */
  WaitSignUp = '即将报名',
  /** 正在报名 */
  SignUp = '正在报名',
  /** 报名结束 */
  SignUpEnd = '报名结束',
  /** 备展中 */
  CarryShow = '备展中',
  /** 备展结束 */
  CarryEnd = '备展结束',
  /** 投票中 */
  Voteing = '投票中',
  /** 观展 */
  WatchShow = '观展'
}
