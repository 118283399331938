







































































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { enterFullScreen, exitFullScreen } from '@xt/client/helpers/system'
import { ChapterItem } from '@xt/client/entities/gensee/types'

@Observer
@Component
export default class PlaybackPageView extends Vue {
  @Prop({ default: true }) show: boolean

  toggle: boolean = false
  videoNode: HTMLMediaElement | null = null
  visibleChapter = false
  visibleSpeed = false
  visibleVolume = false
  dragVolumeSlider = false

  get PageStore() {
    return this.$store.$genseePlayback
  }

  formatTime(time: number) {
    const ms = Math.ceil(time)

    const hours = this.moment(ms).utcOffset(0).get('hours')

    return this.moment(ms)
      .utcOffset(0)
      .format(hours >= 1 ? 'HH:mm:ss' : 'mm:ss')
  }

  chooseChapterHandler(item: ChapterItem) {
    if (!this.PageStore.videoState.isPlaying) {
      this.PageStore.toggleVideoPlay()
    }
    this.PageStore.seekVideoByTimestamp(item.starttimestamp)
    this.visibleChapter = false
  }

  progrssChangeHandler(value: number) {
    this.PageStore.updateProgressByDrag(value)
  }

  progrssAfterChangeHandler(value: number) {
    this.PageStore.onProgressDragFinish(value)
    // 把滑块失焦  否则会触发组件的键盘事件
    ;(this.$refs['slider'] as any).blur()
  }

  volumeChangeHandler(value: number) {
    this.dragVolumeSlider = true
    this.PageStore.updateVolumeByDrag(value)
  }

  volumeAfterChangeHandler() {
    // 滑动滑块鼠标移出区域后  音量控件会被关掉
    setTimeout(() => {
      this.dragVolumeSlider = false
    }, 20)
  }

  toggleVideoPlayHandler() {
    this.PageStore.toggleVideoPlay()
  }

  @Emit('toggleScreen')
  toggleScreenHandler() {
    this.toggle = !this.toggle
    return this.toggle
  }

  fullScreenHandler() {
    const targetNode = document.querySelector(!this.toggle ? '#video-widget-box' : '#doc-widget-box') as HTMLDivElement
    if (this.PageStore.videoState.isFullScreen) {
      exitFullScreen()
    } else {
      enterFullScreen(targetNode)
    }
  }

  onVisible(visible: boolean, type: 'chapter' | 'speed' | 'volume') {
    this.resetClickHandler()
    if (visible) {
      switch (type) {
        case 'chapter':
          this.visibleChapter = true
          break
        case 'speed':
          this.visibleSpeed = true
          break
        case 'volume':
          this.visibleVolume = true
          if (this.PageStore.videoState.volume === 0) {
            this.PageStore.recoverVolumePlay()
          } else {
            this.PageStore.resetVolumePlay()
          }
          break
      }
      this.recoverClickHandler()
    }
  }

  clickHandler(e: MouseEvent) {
    const node = e.target as HTMLElement
    const id = node.offsetParent?.id
    if ((id && id.startsWith('control-trigger')) || this.dragVolumeSlider) return
    this.resetClickHandler()
  }

  resetClickHandler() {
    this.visibleChapter = false
    this.visibleSpeed = false
    this.visibleVolume = false
    document.removeEventListener('click', this.clickHandler)
  }

  recoverClickHandler() {
    document.addEventListener('click', this.clickHandler)
  }

  fullscreenChangeHandler() {
    const ids = ['video-widget-box', 'doc-widget-box']
    this.PageStore.updateFullScreen(!!ids.includes(document.fullscreenElement?.id))
  }

  created() {
    document.addEventListener('fullscreenchange', this.fullscreenChangeHandler)
  }
  mounted() {}
  destroyed() {
    document.removeEventListener('fullscreenchange', this.fullscreenChangeHandler)
  }
}
