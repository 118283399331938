

































































































import { Component, Prop, Vue, Provide, Inject, Mixins, Ref } from 'vue-property-decorator'
import { MixinsMyCalendar } from '@xt/client/mixins'
import ViewCalendarDay from './calendar/calendarDay.vue'
import ViewLiveCourse from '../calendar/views/liveCourse.vue'
import ViewRing from '../calendar/views/ring.vue'
import moment from 'moment'
import LazyImg from '@/components/lazyImg/index.vue'

@Component({
  components: {
    ViewCalendarDay,
    ViewLiveCourse,
    ViewRing,
    LazyImg
  }
})
export default class StudyCalendar extends Mixins(MixinsMyCalendar) {
  currentIndex: number = 0

  /** 左侧按钮消失 */
  get prevButtonDisappear() {
    return this.currentIndex === -1
  }

  /** 右侧按钮消失 */
  get nextButtonDisappear() {
    return this.currentIndex === 1
  }

  @Ref('calendar')
  calendarNode: HTMLDivElement

  prevWeek() {
    this.currentIndex--
    let box: any = this.$refs.box
    // @ts-ignore
    box.style.transform = `translateX(${this.$px2rem(-402 * this.currentIndex)})`
  }

  nextWeek() {
    this.currentIndex++
    let box: any = this.$refs.box
    // @ts-ignore
    box.style.transform = `translateX(${this.$px2rem(-402 * this.currentIndex)})`
  }

  moveWeekCard(forward) {
    if (forward === 'next') {
      this.nextWeek()
    } else {
      this.prevWeek()
    }
  }

  goJumpAllCalendar() {
    this.$router.push({
      name: 'my-calendar'
    })
  }

  changeActiveHandler(data: any) {
    this.changeActive(data)
    this.$nextTick(() => {
      this.$emit('loaded', this.calendarNode.offsetHeight)
    })
  }

  async created() {
    let res = await this.ClasshourPageStore.onCurrentExistLive()
    this.$store.$storeUser.updateLiveStatus(!!res)
    let currentTime = this.$ajax.serviceDate().valueOf()
    const [y, m, d] = moment(currentTime).format('YYYY-MM-DD').split('-')
    this.y = parseInt(y)
    this.m = parseInt(m)
    this.d = 1 // 每个月一定会有1号，但是不一定会有29、30、31号（2月）
    this.currentYear = parseInt(y)
    this.currentMonth = parseInt(m)
    this.currentDay = parseInt(d)
    await this.onLoad()
    this.$nextTick(() => {
      this.$emit('loaded', this.calendarNode.offsetHeight)
    })
  }
}
