










import { Component, Prop, Vue, Provide, Mixins } from 'vue-property-decorator'
import lodash from 'lodash'
import { MixinsCourseClasshourDownTime } from '@xt/client/mixins'
@Component({
  components: {}
})
export default class PageView extends Mixins(MixinsCourseClasshourDownTime) {
  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
