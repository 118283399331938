/** * @author wangsl */
<script>
import { Prop, Vue, Component } from 'vue-property-decorator'

@Component
export default class Teleport extends Vue {
  @Prop({}) to

  mounted() {
    document.querySelector(this.to).appendChild(this.$el)
  }

  destroyed() {
    try {
      document.querySelector(this.to).removeChild(this.$el)
    } catch (_) {}
  }

  render() {
    return this.$scopedSlots.default()
  }
}
</script>
