/**
 * @ Author: Mengcx
 * @ Create Time: 2022-11-22 14:31:51
 * @ Modified by: Your name
 * @ Modified time: 2023-07-26 14:16:21
 * @ Description:
 */

import { Observer } from 'mobx-vue'
import moment from 'moment'
import { Vue, Component } from 'vue-property-decorator'
import JSONDATA from './showActivity.json'

@Observer
@Component({
  async asyncData(ctx) {
    // 读取远程JSON文件
    const res = await ctx.store.$ajax.get(
      `/mocks/showActivity.json`,
      {},
      { 'Cache-Control': 'no-store', 'If-Modified-Since': '0' },
      {
        target: ctx.store.$global.production ? 'https://static.xuantong.cn' : 'https://testing-static.xuantong.cn'
      }
    )
    //单元测试用本地JSON文件
    //  const res = JSONDATA
    return {
      dataSource: res
    }
  }
})
export default class ActiveCourseScript extends Vue {
  dataSource: any = {}
  goCourseList() {
    this.$router.push({
      path: '/product/list?typeCurrent=3&directionCurrent=0'
    })
  }
  mounted() {}
}
