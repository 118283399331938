import country from './country.json'
import china from './data.json'
import lodash from 'lodash'

/**
 * 中国城市
 */
export const dataSource_provinc_cities = lodash.map(china, pro => {
  const children = lodash.map(pro.children, cit => {
    return { value: cit.name, label: cit.name }
  })
  return { value: pro.name, label: pro.name, children }
})
/**
 * 国家
 */
export const dataSource_country = lodash.map(lodash.orderBy(country, 'code'), cty => {
  return { value: cty.nameZH, label: cty.nameZH }
})
/**
 * 国家 > 城市
 */
export const dataSource_country_provinc = [
  {
    value: '国内',
    label: '国内',
    children: dataSource_provinc_cities
  },
  {
    value: '国外',
    label: '国外',
    children: dataSource_country
  }
]
/**
 * 格式化 地址
 * @param values
 * @param back
 */
export function getLocationCascader(values, back = false) {
  if (back) {
    let location = lodash.head(values.locationCascader)
    if (location === '国内') {
      values.location = 1
      values.country = '中国'
      values.province = lodash.nth(values.locationCascader, 1)
      values.city = lodash.nth(values.locationCascader, 2)
    } else if (location === '国外') {
      values.location = 2
      values.country = lodash.nth(values.locationCascader, 1)
      values.province = ''
      values.city = ''
    }
  } else {
    let locationCascader = []
    // 国内
    if (lodash.eq(values.location, 1)) {
      locationCascader = ['国内', values.province, values.city]
    } else if (lodash.eq(values.location, 2)) {
      locationCascader = ['国外', values.country]
    }
    values.locationCascader = locationCascader
  }
  return values
}
/**
 * 搜索
 * @param inputValue
 * @param path
 */
export function countryCascaderFilter(inputValue, path) {
  return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
}
