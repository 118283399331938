











































import { Component, Prop, Vue, Mixins, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import { MixinsHomeworkTask } from '@xt/client/mixins'
import HeaderImage from '../../../components/headerImage/index.vue'

@Observer
@Component({
  components: { HeaderImage }
})
export default class PageView extends Mixins(MixinsHomeworkTask) {
  @Prop() body
  get Pagination() {
    return this.PageStore.HomeworkComment
  }
  get Details() {
    return this.PageStore.HomeworkDetail
  }
  beforeDestroy() {
    this.Details.onReset()
  }
  created() {
    // this.Pagination.onLoading(this.body)
  }
}
