import { StorageWithExpiredTime } from '@xt/client/helpers/storage';
import { BanType, StorageKeys } from '@xt/client/enums';
export function parseAccountBanInfo(banInfo) {
    if (!banInfo || !banInfo.id)
        return null;
    var key = StorageKeys.AccountAlertModal;
    var storage = new StorageWithExpiredTime(localStorage);
    var value = storage.get(key);
    if (value === banInfo.id && banInfo.banType === BanType.Warn) {
        // 已经弹过并且还没有到下一次弹出的时候
        return null;
    }
    return banInfo;
}
