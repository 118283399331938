







































































import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { MixinsHomeworkTask } from '@xt/client/mixins/index'
import lodash from 'lodash'
// import List from "../my/children/homework/views/list.vue";
// import List from "./views/list.vue";

@Observer
@Component({
  // 每次进入页面都会调用
  async fetch(ctx) {
    let id = lodash.get(ctx.query, 'id', 0)
    let store = ctx.store.$storeHomework.HomeworkTask
    await store.HomeworkTaskDetail.onLoading({ id })
  },
  validate({ query }) {
    return /^\d+$/.test(query.id as string)
  },
  components: {}
})
export default class PageView extends Mixins(MixinsHomeworkTask) {
  get body() {
    return { id: this.id }
  }
  notData(data) {
    return lodash.isEmpty(data)
  }
  onloading() {
    this.Pagination.onLoading(this.body)
  }
  created() {
    this.onloading()
  }
  submitComplate() {
    this.Pagination.onReset()
    this.onloading()
  }
  beforeDestroy() {
    this.Pagination.onReset()
  }
}
