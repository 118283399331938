import { AjaxBasics } from '@xt/client'

type KeyboardInput = {
  type: 'keyboardinput'
  begin: number
  end: number
  duration: number
  keys: string
}

type KeyboardPause = {
  type: 'keyboardpause'
  begin: number
  end: number
  duration: number
}

type KeyboardFrame = KeyboardInput | KeyboardPause

export class KeyboardTrack {
  private isRecording = false
  private frames: KeyboardFrame[] = []
  private lastTrackTime: number
  private isFirstTrack: boolean = false
  private KeyboardPauseThreshold: number
  private intervalRecordTime: number | null = null
  private trackInterval: null | ReturnType<typeof setInterval> = null
  private lastKeyboardInputFrame: null | KeyboardInput = null

  constructor(keyboardPauseThreshold: number) {
    this.lastTrackTime = this.getServerTime()

    this.KeyboardPauseThreshold = keyboardPauseThreshold
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  private getServerTime() {
    return AjaxBasics.serviceDate.valueOf()
  }

  // 当前页面存在聚焦的input 或者 textarea才认为是有效的输入
  private isValidKeyDown() {
    const activeElement = document.activeElement

    return activeElement instanceof HTMLInputElement || activeElement instanceof HTMLTextAreaElement
  }

  private appendFrame(eventType: KeyboardInput['type'], begin: number, end: number, key: string)
  private appendFrame(eventType: KeyboardPause['type'], begin: number, end: number)
  private appendFrame(eventType: KeyboardInput['type'] | KeyboardPause['type'], begin: number, end: number, key?: string) {
    const lastFrame = this.frames[this.frames.length - 1]

    if (this.lastKeyboardInputFrame) {
      begin = this.lastKeyboardInputFrame.end
    }

    if (!lastFrame || lastFrame.type !== eventType) {
      const frame: KeyboardInput | KeyboardPause =
        eventType === 'keyboardinput'
          ? {
              type: eventType,
              begin,
              end,
              duration: end - begin,
              keys: key
            }
          : {
              type: eventType,
              begin,
              end,
              duration: end - begin
            }

      this.frames.push(frame)
    } else {
      if (lastFrame.type === 'keyboardinput') {
        lastFrame.keys = lastFrame.keys + key
      }

      lastFrame.end = end
      lastFrame.duration = lastFrame.end - lastFrame.begin
    }

    this.lastKeyboardInputFrame =
      eventType === 'keyboardinput'
        ? {
            type: eventType,
            begin,
            end,
            duration: end - begin,
            keys: ''
          }
        : null
  }

  private handleKeyDown(event: KeyboardEvent) {
    if (!this.isValidKeyDown()) return

    const now = this.getServerTime()

    if (!this.isFirstTrack) {
      this.isFirstTrack = true
      this.appendFrame('keyboardpause', this.lastTrackTime, now)
    }

    if (this.intervalRecordTime) {
      this.appendFrame('keyboardpause', this.intervalRecordTime, now)
      this.intervalRecordTime = null
    }

    this.appendFrame('keyboardinput', now, now, event.key)

    if (this.trackInterval) {
      clearInterval(this.trackInterval)
      this.trackInterval = null
    }

    this.trackInterval = setInterval(() => {
      const _now = this.getServerTime()
      if (_now - this.lastTrackTime > this.KeyboardPauseThreshold) {
        this.appendFrame('keyboardpause', this.lastTrackTime, _now)
        this.intervalRecordTime = _now
      }
    }, 2000)

    this.lastTrackTime = now
  }

  record() {
    if (this.isRecording) return

    window.addEventListener('keydown', this.handleKeyDown)
  }

  stop() {
    this.isRecording = false
    this.frames = []

    window.removeEventListener('keydown', this.handleKeyDown)
  }

  flush() {
    const now = this.getServerTime()
    // 特殊处理一下，如果本次chunk收集到的数据为空，那么填充完整的暂停数据并上报
    if (this.frames.length === 0) {
      this.appendFrame('keyboardpause', this.lastTrackTime, now)
    }

    const result: Record<string, any> = {}
    const actionFrames = this.frames.filter(item => item.type === 'keyboardinput')
    const pauseFrames = this.frames.filter(item => item.type === 'keyboardpause')
    const actionTotal = actionFrames.reduce((prev, current) => {
      return prev + current.duration
    }, 0)
    const pauseTotal = pauseFrames.reduce((prev, current) => {
      return prev + current.duration
    }, 0)
    const total = actionTotal + pauseTotal

    result.total = total
    result.frames = [...this.frames]
    result.actionTotal = actionTotal
    result.pauseTotal = pauseTotal

    this.frames = []
    this.lastTrackTime = this.intervalRecordTime ?? now
    return result
  }

  export() {
    return this.frames
  }
}
