












































import { Component, Prop, Vue, Provide, Mixins } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { MixinsCourseThoughts } from '@xt/client/mixins'
import lodash from 'lodash'

@Observer
@Component
export default class PageView extends Mixins(MixinsCourseThoughts) {
  async created() {
    this.onGetMyCourseThought()
  }
  mounted() {}
  updated() {}
  destroyed() {
    this.Pagination.onReset()
  }
}
