<template>
  <div class="groupbuy-wrapper">
    <GroupBuy platform="web" :dataSource="dataSource" />
    <div class="qrcode-wrapper" v-if="qrcodeUrl">
      <img class="qrcode" :src="qrcodeUrl" />
      <div class="text">微信扫码去分享</div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import GroupBuy from '@xt/client/mixins/groupbuy'
import QRCode from 'qrcode'

@Observer
@Component({
  async asyncData(ctx) {
    const { groupNo } = ctx.route.params
    let dataSource = await ctx.store.$storeGroup.getGroupDetail(groupNo)
    return {
      dataSource: dataSource
    }
  },
  components: { GroupBuy }
})
export default class PageView extends Vue {
  dataSource
  qrcodeUrl
  created() {
    QRCode.toDataURL(window.location.href, (err, string) => {
      if (err) throw err
      this.qrcodeUrl = string
    })
  }
}
</script>

<style lang="less" scoped>
.groupbuy-wrapper {
  background-color: #f7f8fa;
  width: 100vw;
  min-width: 325px;
  display: flex;
  justify-content: center;
  user-select: none;
  position: relative;
}
.qrcode-wrapper {
  width: 80px;
  height: 98px;
  background: #ffffff;
  border-radius: 2px;
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 40px;
  transform: translateX(250px);
  .qrcode {
    width: 80px;
    height: 80px;
    position: absolute;
    z-index: 9;
  }
  .text {
    font-size: 8px;
    color: #333333;
    line-height: 12px;
    text-align: center;
    position: absolute;
    top: 80px;
    width: 80px;
  }
}
.qrcode-wrapper::before {
  content: '';
  width: 14px;
  height: 17px;
  background: #ffffff;
  transform: rotate(45deg);
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: -7px;
  z-index: 1;
}
</style>
