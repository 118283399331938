








import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator'
import lodash from 'lodash'

declare var zc: any
@Component({
  components: {}
})
export default class Page extends Vue {
  isLoad = false
  get PageStore() {
    return this.$store.$storeUser
  }
  created() {
    let info = lodash.cloneDeep(this.PageStore.UserInfo)
    let config = {
      color: '009487', //自定义颜色
      async: true, //页面进入时自动加载JS组件 默认0 1开启 0关闭
      show_face: 1, //show_face 1：显示客服头像
      custom: true, //设置自定义咨询按钮
      refresh: true, //每次打开都刷新
      manual: true //设置成手动初始化
    }
    if (!lodash.isEmpty(info)) {
      config = lodash.merge(config, {
        refresh: false,
        partnerid: info.id,
        uname: info.nickname, //客户名称
        realname: info.nickname, //客户真实姓名
        tel: info.phoneNum, //客户电话,
        email: info.email, //客户邮箱
        face: info.headerUrl //客户头像
      })
    }
    ;((w: any, d, e, x?: HTMLScriptElement) => {
      if (w[e]) {
        return
      }
      w[e] = function () {
        w.cbk = w.cbk || []
        w.cbk.push(arguments)
      }
      x = d.createElement('script')
      x.async = true
      x.id = 'zhichiScript'
      x.className = 'zhiCustomBtn' //该class绑定到自定义按钮上 第一步
      // ***为您的自定义域名
      x.src = 'https://www.sobot.com/chat/frame/v2/entrance.js?sysnum=f2c2e254039745dfb3413702ef22c324'
      d.body.appendChild(x)
      x.addEventListener('load', event => {
        zc('config', config)
        zc('dynamic_ready')
      })
    })(window, document, 'zc')
  }
  openZhichiCustom() {
    if (!zc) return
    if (this.isLoad) {
      let el: any = document.querySelector('#zc__sdk__container')
      el && (el.style.height = '540px')
      // zc("frame_status", function(data) {
      // });
      return false
    }
    zc('frame_manual', res => {
      this.isLoad = true
    })
  }
}
