

















import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator'
import lodash from 'lodash'
import { reportEntryLog } from '@xt/client/utils/log'
@Component({
  components: {}
})
export default class extends Vue {
  @Prop() error
  get status() {
    if (this.error.statusCode === 200) {
      return '500'
    }
    return String(this.error.statusCode)
  }
  get homePage() {
    return ['index'].includes(this.$route.name?.replace(/-(index)/g, ''))
  }
  goHome() {
    if (this.homePage) {
      window.location.reload()
    } else {
      this.$router.replace({
        path: '/'
      })
    }
  }
  get production() {
    return this.$store.$global.production
  }
  get path() {
    return this.$route.path
  }
  get query() {
    return this.$route.query
  }
  get message() {
    if (this.production && this.status === '500') {
      return '数据异常，请联系客服'
    }
    if (this.status === '404') {
      return '找不到页面'
    }
    return this.error.message
  }
  redirect = false
  created() {
    console.log('LENG: extends -> created -> this', this)
    // 上报错误日志
    reportEntryLog('app.layout.error', {
      path: this.path,
      query: this.query,
      message: this.message,
      status: this.status,
      error: this.error
    })
    if (this.status === '404') {
      if (lodash.has(this.query, 'redirect')) {
        return
      }
      if (lodash.includes(this.path, '/mobile/')) {
        this.toPage('/mobile/')
      }
    }
  }
  toPage(name) {
    this.redirect = true
    let redirect = this.$route.fullPath
    // 本地
    if (this.$store.$global.dev) {
      this.$msg('本地不跳转')
    }
    // 手机平台
    if (this.$store.$global.isMobilePage) {
      const router = this.$router.resolve({
        path: `${name}redirect`,
        query: {
          redirect
        }
      })


      return window.location.replace(router.href)
      // 桌面
    }
    redirect = lodash.replace(redirect, '/mobile', '')
    this.$router.replace({ path: redirect })
  }
  mounted() { }
  updated() { }
  destroyed() { }
}
