































































import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import comment from './comment.vue'
@Observer
@Component({
  components: { comment }
})
export default class extends Vue {
  @Prop({}) momentId
  @Prop({ default: '查看评论' }) title
  // 显示
  get visible() {
    return !!this.momentId
  }
  get HomeWork() {
    return this.$store.$storeHomework
  }
  get PageStore() {
    return this.HomeWork.Details
  }
  get MomentComment() {
    return this.PageStore.MomentComment
  }
  // 加载数据
  async onLoading(id) {
    if (id) {
      await this.PageStore.onLoading({ id })
    }
  }
  //   关闭
  @Emit('cancel')
  onCancel() {
    this.PageStore.onReset()
  }
  //   id 变化
  @Watch('momentId')
  updateClasshour(newVal, oldVal) {
    this.onLoading(newVal)
  }

  getComment(item) {
    return {
      toUserName: item.toMemberNickname,
      content: item.content,
      memberId: item.memberId,
      author: item.memberNickname,
      time: item.createTime,
      bishan: item.bishanCount,
      imgs: item.pictures
    }
  }
  /**
   * 朋友圈发布评论&回复评论
   */
  async onSubmit(event, data) {
    try {
      this.$InspectUser()
      let hasComment = this.PageStore.dataSource.commentCount ? true : false
      await this.HomeWork.onMomentComment({
        content: this.encodeHTML(event.content),
        contentLength: event.length,
        momentId: this.momentId,
        toCommentId: lodash.get(data, 'id', 0),
        toMemberId: lodash.get(data, 'memberId', 0)
      })
      event.onReset()
      this.onLoading(this.momentId)
      if (hasComment) {
        this.MomentComment.onReset()
      }
    } catch (error) {
      console.log('LENG: PageView -> onSubmit -> error', error)
    }
  }

  // onLikes(item) {
  //   this.$emit("like", item, false);
  // }

  async onLikes(item) {
    try {
      // await this.HomeWork.onLikes(item);
      await this.$emit('like', item)
    } catch (error) {
      this.$msg(error)
    }
  }

  /**
   * 确认删除
   */
  @Emit('delete')
  onConfirm() {
    return this.momentId
  }
  created() {
    this.$nextTick(() => {
      const containerNode = this.$refs['container'] as HTMLElement
      containerNode.addEventListener('scroll', () => {})
      containerNode.addEventListener('click', () => {})
    })
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
