import { Context } from '@nuxt/types'
import 'core-js/es'
import lodash from 'lodash'
import { onUpgradeClientVersion } from '@xt/client/helpers'
import Bowser from './utils/bowser'
import './utils/es6-proxy-polyfill.js'
import './utils/registerHooks'
import './utils/vconsole'
import './utils/disable-f12'
import "./utils/window-open"
// Bowser()
console.group('Start')
export default async function (context: Context) {
  try {
    // 之前commodity的多参数页面重定向到单参数页面
    const commodityReg = /\/commodity\/(\d{1,})\/(course|daycourse|exhibition)/
    if (commodityReg.test(context.route.path)) {
      context.redirect({
        path: `/commodity/${context.route.path.replace(commodityReg, '$1')}`
      })
      return
    }

    // 之前拼团的多参数页面重定向到单参数页面
    const groupBuyReg = /\/groupbuy\/(\w+)\/\d{1,}/
    if (groupBuyReg.test(context.route.path)) {
      context.redirect({
        path: `/groupbuy/${context.route.path.replace(groupBuyReg, '$1')}`
      })
      return
    }

    await onUpgradeClientVersion(context.route).catch(() => {})
    isBowser(context)
    // 面包屑
    context.store.$menu.initBreadcrumb(context.route)
    context.store.$storeUser.onToggleVisible(lodash.hasIn(context.route.query, 'login'))
    onLog(context)
    loggedIn(context)
    await onGetCourseHasbuy(context)
    return context.store.$storeUser.GetUserAsync.toPromise()
  } catch (error) {
    console.error('LENG: error', error)
  }
}
/**
 * 获取用户课程
 * @param context
 */
function onGetCourseHasbuy(context: Context) {
  if (lodash.includes(['course', 'payment-success', 'my'], context.route.name)) {
    return context.store.$storeUser.onGetCourseListHasbuy()
  }
}
/**
 * 日志
 * @param context
 */
function onLog(context: Context) {
  if (context.from) {
    if (context.from.name !== context.route.name) {
      console.clear()
      console.group('Route', context.route)
      console.info('LENG: middleware', context.store.$global)
      console.log('LENG: context', context)
      console.groupEnd()
    }
  }
}
/**
 * 登录状态
 * @param context
 */
function loggedIn(context: Context) {
  if (lodash.startsWith(context.route.name, 'my')) {
    if (!context.store.$storeUser.loggedIn) {
      // login 表示弹出登录框
      return context.redirect('/', { login: '' })
    }
  }
}

/**
 * 浏览器环境
 * @param context
 */
function isBowser(context: Context) {
  // 不支持 浏览器
  if (Bowser() && !lodash.startsWith(context.route.name, 'bowser')) {
    context.redirect('/bowser')
    throw '浏览器不支持'
  }
}
