/**
 * @author 冷 (https://github.com/LengYXin)
 * @email lengyingxin8966@gmail.com
 * @create date 2020-10-20 20:26:03
 * @modify date 2020-10-20 20:26:03
 * @desc 课程 晒作业
 */
import { Observer } from 'mobx-vue'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { MixinsCourseHelpers } from './helpers'
import { MomentSourceType } from '../../enums'

@Observer
@Component
export class MixinsCourseSunDrying extends Mixins(MixinsCourseHelpers) {
  @Prop({ default: () => { } }) dataSource
  get CourseDetails() {
    return this.$store.$storeCourse.Details
  }
  get PageStore() {
    return this.$store.$storeHomework
  }
  get Pagination() {
    return this.PageStore.SunDrying
  }
  get id() {
    return this.$route.params.id
  }
  get body() {
    return { sourceId: this.id, sourceType: MomentSourceType.SunDrying }
  }
  // 回复
  reply = {}
  /**
   * 回复
   */
  onReply(data) {
    if (data && data.id) {
      try {
        this.$InspectUser()
        this.onAddbrowsenum(data)
        this.reply = data
      } catch (error) { }
    } else {
      this.reply = {}
    }
  }

  isLink(item) {
    return item.like ? 'icon-like-full' : 'icon-like'
  }
  getComment(item) {
    return {
      content: item.content,
      memberId: item.memberId,
      author: item.memberNickname,
      time: item.createTime,
      bishan: item.bishanCount,
      imgs: item.pictures,
      top: item.top
    }
  }
  loading = false
  async onSubmit(event) {
    try {
      if (!event.fileResult.length && !event.content.replace(this.$Regulars.invalidText, '').length) {
        event.next && event.next()
        return this.$msg('请填写朋友圈内容')
      }
      if (this.loading) return
      this.loading = true
      await this.Pagination.onInstall({
        content: this.encodeHTML(event.content),
        contentLength: event.length,
        sourceId: this.id,
        pictures: event.fileResult
      })
      event.onReset()
      this.Pagination.onReset()
      this.loading = false
      // this.$msg(this.$EnumMessage.sundrying_success);
    } catch (error) {
      console.log('LENG: PageView -> onSubmit -> error', error)
    }
  }
  onAddbrowsenum(item) {
    this.Pagination.onAddbrowsenum(item.id)
  }
  /**
   * 删除
   */
  async onDelete(item) {
    try {
      await this.Pagination.onDeleteMoment(item.id)
      this.onReply({})
    } catch (error) { }
  }
  async onLikes(item) {
    try {
      // this.CourseHelpers.onCheck(this.CourseDetails.dataSource)
      await this.PageStore.onLikes(item)
    } catch (error) {
      this.$msg(error)
    }
  }
  created() { }
  mounted() { }
  updated() { }
  destroyed() { }
}
