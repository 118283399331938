


















import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import { AjaxBasics } from '@xt/client/helpers'
import lodash from 'lodash'
import moment from 'moment'
import { MixinsLiveCourse } from '@xt/client/mixins'

@Component
export default class LiveCourse extends Mixins(MixinsLiveCourse) {
  destroyed() {
    clearInterval(this.downtimeTimer)
    this.downtimeTimer = null
  }
}
