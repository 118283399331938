import { Component, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import moment from 'moment'
import { AjaxBasics } from '@xt/client'

@Observer
@Component
export default class ShowActiveMixins extends Vue {
  @Prop() dataSource: any

  statusText: string = ''
  statusStyle: string = ''
  downTimer: NodeJS.Timer
  countdownText: any = {}
  signupInprogress: boolean = false // 是否展示正在报名
  groupIcon: boolean = false // 是否展示团购图标

  // 是否0元单
  get isZeroOrder() {
    return this.dataSource.showPrice === 0
  }

  // 拼团
  get isGroup() {
    return this.dataSource.isGroup
  }

  // 敬请期待
  get forward() {
    return this.dataSource.isLookforward
  }

  // 即将报名
  get willSignup() {
    return !this.dataSource.showSignupStartTime
  }

  // 等待报名阶段
  get waitSignup() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    return currentTime < this.dataSource.showSignupStartTime
  }

  // 报名阶段
  get signingUp() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    return currentTime >= this.dataSource.showSignupStartTime && currentTime <= this.dataSource.showSignupEndTime
  }

  toDetail() {
    if (this.forward) {
      return this.$msg('敬请期待~')
    }
    this.$router.push({
      path: `/commodity/${this.dataSource.productId}`
    })
  }

  /**
   * 倒计时及状态切换的实现
   */
  downtime() {
    this.downTimer = setInterval(() => {
      let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
      let diff: number = this.dataSource.showSignupEndTime - currentTime
      switch (true) {
        case currentTime < this.dataSource.showSignupStartTime:
          this.statusStyle = '-red'
          this.signupInprogress = false
          return (this.statusText = `报名时间：${moment(this.dataSource.showSignupStartTime).format('YYYY.MM.DD')}`)
        case diff <= 0: // 如果结束了那么切换结束状态停止计时器
          clearInterval(this.downTimer)
          this.downTimer = null
          this.signupInprogress = false
          this.groupIcon = false
          this.btn()
          return
        case diff > 24 * 60 * 60 * 1000: // 如果倒计时大于24小时显示天
          this.statusStyle = '-red'
          this.signupInprogress = true
          this.groupIcon = this.isGroup
          return (this.statusText = `报名剩余：${this.computeDay(currentTime, this.dataSource.showSignupEndTime)}天`)
        default:
          let hms = moment(this.dataSource.showSignupEndTime - currentTime)
            .utcOffset(0)
            .format('HH:mm:ss')
          this.statusStyle = '-red'
          this.signupInprogress = true
          this.groupIcon = this.isGroup
          //@ts-ignore
          // return this.statusText = `报名剩余：<div style='width: ${this.$px2rem(50)}'>${hms}</div>`
          return (this.statusText = `报名剩余：${hms}`)
      }
    }, 200)
  }

  btn() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    if (this.forward) {
      this.statusText = '敬请期待～'
      this.statusStyle = '-grey'
      return
    }
    if (this.willSignup) {
      this.statusText = '即将报名'
      this.statusStyle = '-red'
      return
    }
    if (currentTime < this.dataSource.showReadyStartTime) {
      this.statusText = '报名结束'
      this.statusStyle = '-red'
      return
    }
    if (currentTime >= this.dataSource.showReadyStartTime && currentTime <= this.dataSource.showReadyEndTime) {
      this.statusText = '备展中'
      this.statusStyle = '-green'
      return
    }
    if (currentTime > this.dataSource.showReadyEndTime && currentTime <= this.dataSource.showVoteStartTime) {
      this.statusText = '备展结束'
      this.statusStyle = '-grey'
      return
    }
    if (currentTime >= this.dataSource.showVoteStartTime && currentTime <= this.dataSource.showVoteEndTime) {
      this.statusText = '投票中'
      this.statusStyle = '-light-red'
      return
    }
    this.statusText = '观展'
    this.statusStyle = '-yellow'
  }

  /**
   * 计算差几天
   */
  computeDay(currentTime: number, endtime: number): number {
    return Math.floor(this.moment(endtime).diff(currentTime, 'hour') / 24)
  }

  init() {
    if (!this.forward && !this.willSignup && (this.signingUp || this.waitSignup)) this.downtime()
    else this.btn()
  }
}
