/// <reference types="./order" />
import lodash from 'lodash'
import { BindAll, Debounce } from 'lodash-decorators'
import { EnumApiOrder, EnumApiCourse } from '../../api'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { Pagination } from '../basics/pagination'
import { EntitiesBasics } from '../basics/entities'
import { EnumApiProduct } from '@xt/client/api'
import Entities from './entities'
import Order from '../basics/order'
import OrderDetails from './details'
import { Course } from '@xt/client/entities/basics/course'

@BindAll()
export class ControllerOrder extends Entities {
  constructor(protected $ajax: AjaxBasics) {
    super()
  }
  /**
   * 分页列表数据
   * @memberof ControllerOrder
   */
  Pagination = new Pagination(this.$ajax, {
    url: EnumApiOrder.OrderList,
    key: 'origin.orderNo',
    infinite: true,
    onMapValues: this.onMapValues
  })

  onMapValues(res) {
    let data = lodash.map(res, item => new Order(item))
    return lodash.merge(res, { dataSource: data })
  }
  /**
   * 订单详情
   * @memberof ControllerOrder
   */
  Details = new EntitiesBasics(this.$ajax, { url: EnumApiOrder.OrderDetails })
  /**
   * 活动订单详情
   * @memberof ControllerOrder
   */
  ActicityDetails = new EntitiesBasics(this.$ajax, { url: EnumApiOrder.OrderDetails })

  /*
   * 订单详情
   */
  orderDetail = new OrderDetails(this.$ajax)

  /**
   * 获取成功页面图片
   * **/
  addGroupImg(productId: { productId }) {
    return this.$ajax.get(EnumApiOrder.AssistantUrl, productId)
  }

  /**
   * 下单前校验,是否有团购的待支付订单
   */
  checkBeforeOrder(productId: any, group?: any) {
    return this.$ajax.get(EnumApiOrder.checkProduct, { group: group.toString(), productId })
  }

  /**
   * 校验是否首次购买日课
   * true  买过
   * false 首次购买
   */
  async onCheckBuyActivityCourse() {
    try {
      const resp = await this.$ajax.get<boolean>(EnumApiOrder.checkFirstActivity)
      return resp
    } catch (error) {
      if (error === false) {
        return error
      }
    }
  }

  // @Debounce(500)
  onOrderPay(body: IOrderPayParam) {
    return this.$ajax.post<{
      coinsPay: boolean
      jsapiResultMap: any
      orderNo: string
      payresult: string
      payAmount: number
    }>(EnumApiOrder.orderPay, lodash.merge({ buyWay: 1, score: 0 }, body))
  }

  // onActivitysOrderPay(body: any) {
  //   return this.$ajax.post<{
  //     coinsPay: boolean
  //     jsapiResultMap: any
  //     orderNo: string
  //     payresult: string
  //     payAmount: number
  //   }>(EnumApiOrder.activitysOrderPay, lodash.merge({ buyWay: 1, score: 0 }, body))
  // }

  /**
   * 查询已购买课程--用于赠课时查询
   * @param courseId 课程
   * @param receiverId 买受用户
   */
  onGetHasbuy(courseId, receiverId) {
    return this.$ajax.get(EnumApiOrder.CouseHasbuy, { productId: courseId, receiverId })
  }
  // 取消订单
  async onOrderCancel(body: { orderNo? }) {
    return await this.$ajax.put(EnumApiOrder.OrderCancel, body)
    // this.Pagination.onReset()
  }
  // 退款前校验
  async checkRefund(orderNo) {
    return await this.$ajax.get(EnumApiOrder.CheckRefund, { orderNo })
  }
  // 活动商品退单前校验
  async checkRefundGiveaway(orderNo) {
    return await this.$ajax.get(EnumApiOrder.CheckRefundGiveaway, { orderNo })
  }
  // 订单退款
  async onOrderRefund(orderNo) {
    return await this.$ajax.put(EnumApiOrder.OrderRefund, { orderNo })
  }
  //  取消订单退款
  async onOrderCancelRefund(body: { refundOrderSn?: string; orderNo?: string }) {
    return await this.$ajax.put(EnumApiOrder.OrderCancelRefund, body)
  }
  /* 申请发票 */
  async onOrderInvices(body: {
    content?: string
    invoiceType?: string
    mediumType?: string
    orderNo?: string
    targetEmail?: string
    taxpayerCode?: string
    title?: string
    titleType?: string
  }) {
    await this.$ajax.post(EnumApiOrder.OrderInvices, body)
  }

  /**
   * 校验是否有老桐学优惠
   */
  async onCheckHasDiscount(productId: number, isGive: boolean, receiverId?: string) {
    try {
      const resp = await this.$ajax.get(EnumApiOrder.checkHasDiscount, { productId, isGive: `${isGive}`, receiverId })
      return resp
    } catch (error) {
      if (error === false) return error
      return false
    }
  }
}
export default ControllerOrder
