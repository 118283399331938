
































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import LazyImg from '@/components/lazyImg/index.vue'

@Component({
  components: { LazyImg }
})
export default class PageView extends Vue {
  @Prop({ default: () => [], required: true }) dataSource
  @Prop({ default: 'key' }) rowKey
  get AboutStore() {
    return this.$store.$storeAbout
  }
  onAboutViewAdd(item) {
    this.AboutStore.onAboutViewAdd(item.id)
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
