
























import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import { dataSource_about } from '@xt/client'
@Observer
@Component({
  name: 'PageAbout',
  // 每次进入页面都会调用
  // async fetch(ctx) {
  //   ctx.store.$storeAbout.onGetTypelist();
  // },
  components: {}
})
export default class PageView extends Vue {
  get PageStore() {
    return this.$store.$storeAbout
  }
  get Pagination() {
    return this.$store.$storeAbout.Pagination
  }
  get body() {
    return { type: this.activeKey }
  }
  get tabPane() {
    return dataSource_about
  }
  defaultActiveKey = '1'
  activeKey = lodash.get(this.$route.query, 'active', this.defaultActiveKey)
  tabsChange(activeKey) {
    this.activeKey = activeKey
  }
  mounted() {}
  updated() {}
  destroyed() {
    // 无限滚动列表 销毁时 清理
    this.Pagination.onReset()
  }
}
