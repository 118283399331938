






import Order from '@xt/client/entities/basics/order'
import { Observer } from 'mobx-vue'
import { Component, Vue, Emit, Prop, Mixins } from 'vue-property-decorator'
import ViewItem from '../views/item.vue'

@Observer
@Component({
  components: {
    ViewItem
  }
})
export default class GroupPage extends Vue {
  @Prop({}) dataSource: Order
}
