import lodash from 'lodash'
import { BindAll } from 'lodash-decorators'
import { EnumApiStationery } from '../../api'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { Pagination } from '../basics/pagination'

@BindAll()
export class ControllerStationery {
  constructor(protected $ajax: AjaxBasics) {}
  /**
   * 分页列表数据
   * @memberof ControllerStationery
   */
  Pagination = new Pagination(this.$ajax, {
    url: EnumApiStationery.Products,
    key: 'id'
  })
}
export default ControllerStationery
