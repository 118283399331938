


















import GroupPopup from './groupPopupScript'
import { Component } from 'vue-property-decorator'
import Popup from '../../popup/popup.vue'
import UserGroup from '../userGroup/userGroup.vue'
import { Observer } from 'mobx-vue'

@Observer
@Component({
  name: 'GroupPopopComp',
  components: { Popup, UserGroup }
})
export default class GroupPopopComp extends GroupPopup {}
