








































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class NewSwiper extends Vue {
  // 列表数据
  @Prop() dataSource
  // 每页几条数据
  @Prop({ default: 4 }) pageSize
  // 过渡动画时长
  @Prop({ default: 500 }) duration
  @Prop({}) width: number
  @Prop({}) height: number

  current: number = 0
  upBtnHover: boolean = false
  nextBtnHover: boolean = false

  get exactDivision() {
    return this.dataSource.length % this.pageSize === 0
  }

  get count() {
    return Math.floor(this.dataSource.length / this.pageSize) + (this.exactDivision ? 0 : 1)
  }

  get currentExactPage() {
    return this.exactDivision || this.count - 1 != this.current
  }

  get trainStyle() {
    let currentItem = 0
    if (this.current != 0) currentItem = this.current * this.pageSize
    if (!this.currentExactPage) currentItem -= this.pageSize - (this.dataSource.length % this.pageSize)
    return `
      transition: transform ${this.duration / 1000}s
      width: calc(${this.$px2rem(this.width * this.count)});`
  }

  transformTrains(current: number) {
    const trains: HTMLDivElement = this.$refs['trains'] as HTMLDivElement
    trains.style.transform = `translateX(-${this.$px2rem(this.width * current)})`
  }

  nextPage() {
    if (this.current === this.count - 1) return
    this.current += 1
    this.transformTrains(this.current)
  }

  upPage() {
    if (this.current === 0) return
    this.current -= 1
    this.transformTrains(this.current)
  }
}
