
















































import { Component, Prop, Vue, Mixins, Emit } from 'vue-property-decorator'
import MapBishan from './bishan.vue'
import { MixinsCourseClasshour } from '@xt/client/mixins'
import lodash from 'lodash'
@Component({
  components: { MapBishan }
})
export default class PageView extends Mixins(MixinsCourseClasshour) {
  get item() {
    return this.dataSource
  }
  @Emit('click')
  onClick() {
    return this.dataSource
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
