import { runInAction, observable, action } from 'mobx'
import { EnumApiActivity } from '../../api/activitys'
import { EnumApiProduct } from '../../api'
import { AjaxBasics } from '../../helpers'
import { EnumProductType } from '../../enums'
import lodash from 'lodash'

class Course {
  constructor(protected $ajax: AjaxBasics) {}
  @observable detail: any = {}

  async loadDetail(id) {
    const data: any = await this.$ajax.get(EnumApiActivity.ActivityCourse, { id })
    let productType = EnumProductType.dayCourse
    const product: any = await this.$ajax.get(EnumApiProduct.productDetail, {
      bizId: data.id,
      productType
    })
    let detail = data
    detail.productInfo = product
    runInAction(async () => {
      this.detail = { ...detail }
    })
    return lodash.cloneDeep(detail)
  }

  /**
   * 获取日课详情
   * @param id
   * @returns
   */
  async getActivityDetail(id: number): Promise<any> {
    return await this.$ajax.get(EnumApiActivity.ActivityCourse, { id })
  }

  async onSetDataSource(res) {
    this.detail = { ...res }
  }
  /**
   * 修改更新
   * @param value
   */
  @action
  onUpdate(updater: (oldValue: any) => any) {
    const dataSource = this.detail
    this.onSetDataSource(updater(dataSource))
  }

  /**
   * 订单信息 课程
   * @memberof CourseDetails
   */
  @observable Orderinfo: Course = null
  async onOrderinfo(id) {
    try {
      const res: any = await this.$ajax.get(EnumApiProduct.productDetailByProductId, { id })
      const orderinfo = new Course(res)
      // orderinfo.courseSingleList = lodash.map(orderinfo.singleList, onComputeState)
      // lodash.unset(orderinfo, 'singleList')
      runInAction(() => {
        this.Orderinfo = orderinfo
      })
    } catch (error) {}
  }

  reset() {
    runInAction(async () => {
      this.detail = {}
    })
  }
}

export default Course
