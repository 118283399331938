















import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator'
import fab from './views/fab.vue'
@Component({
  components: { fab }
})
export default class extends Vue {}
