
















import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component
export default class GiveawayRejectRefundModal extends Vue {
  @Prop() dataSource: any

  get PageStore() {
    return this.$store.$storeUser
  }

  close() {
    this.PageStore.removeUniformGift()
    this.PageStore.removeUniformGiftData()
  }
}
