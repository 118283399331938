

































import DayCourseScript from './script'
import { Observer } from 'mobx-vue'
import LazyImg from '@/components/lazyImg/index.vue'
import { Vue, Component } from 'vue-property-decorator'
import Detail from '../views/detail.vue'
// 活动课组件，日课活动页与其他页面样式及状态略有不同这里单独定义
import Course from './views/course/course.vue'
@Observer
@Component({
  name: 'DayCourseComp',
  components: { Course, LazyImg, Detail }
})
export default class DayCourseComp extends DayCourseScript {}
