



































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { AjaxBasics, giftCache, giftExpiredTime } from '@xt/client/helpers'
import moment from 'moment'

@Observer
@Component
export default class giftModal extends Vue {
  @Prop() dataSource: any

  giftBtnState: boolean = false
  get PageStore() {
    return this.$store.$storeUser
  }

  stopHandler(e: MouseEvent) {
    e.stopPropagation()
  }

  onCancel() {
    giftCache.set(`gift-${this.dataSource.orderNo}`, 'expired', giftExpiredTime)
    this.PageStore.removeOrderGifts(this.dataSource.id)
  }

  async onReceiveGift(id) {
    let res = await this.PageStore.onReceiveGift(id)
    return res
  }
  /**
   * 校验是否绑定微信
   */
  get isWxBind() {
    return this.$store.$storeUser.isWxBind
  }

  /**
   * 去往普通课程的详情页
   */
  toCourseDetail(activityUrl: string) {
    this.$router.push({ path: `${activityUrl}?skipType=hasBuy` })
  }

  /**
   * 统一跳转到标准详情页面
   */
  uniformGoDetail(productId) {
    if (this.$route.path === `/commodity/${productId}`) {
      return (window.location.href = window.location.href)
    }
    this.$router.push({
      path: `/commodity/${productId}`
    })
  }

  /**
   * 检验不同商品类型去往对应的详情页
   */
  toProductDetail(item) {
    const { productType, bizId: id, activityPageUrl: activityUrl, productId } = item
    switch (productType) {
      case 201001: // 单阶课
        this.uniformGoDetail(productId)
        break
      case 201002: // 全阶课
        this.toCourseDetail(activityUrl)
        break
      case 202001: // 教练日课
        this.uniformGoDetail(productId)
        break
      case 203001: // 线上展览 暂时还没有这个商品的详情页 抛出异常
        this.uniformGoDetail(productId)
        break
      case 203002: // 线下展览 暂时还没有这个商品的详情页 抛出异常
        throw new Error('未处理的商品类型')
        break
      default: // 以后可能出现的其他商品类型
        throw new Error('未定义的商品类型')
    }
  }
  async toCourse(item) {
    if (this.giftBtnState) return
    this.giftBtnState = true

    if (!this.isWxBind && item.productType === 202001) {
      this.giftBtnState = false
      this.$store.$storeUser.showWeiXinBind({
        title: '去绑定',
        content: '当前课程需使用微信登录暄桐日课小程序中学习，购课前请绑定微信。 购买课程后，将不再支持微信换绑和解绑。',
        scanTip: '扫描小程序码绑定微信'
      })
      return
    }

    if (this.PageStore.verifyWechat && item.productType === 202001) {
      this.giftBtnState = false
      const buyActivityCourse = await this.$store.$storeOrder.onCheckBuyActivityCourse()
      const currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
      if (!buyActivityCourse && currentTime > moment(this.PageStore.wxCheckTime).add(7, 'days').valueOf()) {
        this.PageStore.showWeiXinVerify({
          title: '微信验证',
          content: '当前课程需使用账号绑定的微信登录暄桐日课小程序中学习。 购买课程后，将不再支持微信换绑和解绑。',
          scanTip: '扫码验证绑定微信与使用微信是否一致',
          operation: true,
          gift: true,
          skipHandler: () => {
            this.PageStore.updateVerifyWechat(false)
            this.PageStore.hideWeiXinVerify()
            this.PageStore.onResetGiftPopup()
          },
          signupHandler: () => {
            this.PageStore.hideWeiXinVerify()
            this.PageStore.onResetGiftPopup()
          },
          closeHandler: () => {
            // 会触发关闭弹窗  所以这里就不用写了
            this.PageStore.onResetGiftPopup()
          }
        })
        this.PageStore.removeAllOrderGifts()
        return
      }
    }

    try {
      let res: any = await this.onReceiveGift(item.id)
      if (res === true) {
        this.PageStore.removeOrderGifts(item.id)
        this.PageStore.removeOrderGiftsData(item.id)
        this.giftBtnState = false
        this.toProductDetail(item)
      }
    } catch (_) {
      this.giftBtnState = false
    }
  }

  mounted() {
    // 显示弹窗口修改body防止滚动穿透
    document.body.style.height = '100%'
    document.body.style.overflow = 'hidden'
  }

  destroyed() {
    // 关闭弹窗时，恢复body
    document.body.style.height = 'auto'
    document.body.style.overflow = 'auto'
  }
}
