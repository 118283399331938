import { getFinger } from './index'
import { StorageKeys } from '../../enums'
import type { XTGlobal } from '@xt/client/store/global'
import type { ControllerUser } from '@xt/client/entities/user/controller'

type Result = {
  changed: boolean
  current: string
  before: null | string
  extra: {
    canvas: string
    webgl: string
    audio: string
    result: number
  }
}

export async function getFp($global: XTGlobal): Promise<Result> {
  const lastFp1Key = $global.platform === 'Mobile' ? StorageKeys.LastXTDevIdH5 : StorageKeys.LastXTDevIdPC
  const { result: fp1, debugInfo } = await getFinger()
  const fp1InStorage = localStorage.getItem(lastFp1Key)

  if (!fp1InStorage) {
    localStorage.setItem(lastFp1Key, fp1)
    return {
      changed: true,
      current: fp1,
      before: null,
      extra: debugInfo
    }
  }

  localStorage.setItem(lastFp1Key, fp1)
  return {
    changed: fp1InStorage !== fp1,
    current: fp1,
    before: fp1InStorage,
    extra: debugInfo
  }
}

export function getUserId($storeUser: ControllerUser, $global: XTGlobal): Omit<Result, 'extra'> {
  const lastUserKey = $global.platform === 'Mobile' ? StorageKeys.LastMemberIdH5 : StorageKeys.LastMemberIdPC
  const id = $storeUser.UserInfo.id ?? null
  const lastID = localStorage.getItem(lastUserKey)

  if (lastID === '') {
    localStorage.setItem(lastUserKey, id)
    return {
      changed: true,
      current: id,
      before: null
    }
  }

  localStorage.setItem(lastUserKey, id)
  return {
    changed: `${id}` !== lastID,
    current: id,
    before: lastID
  }
}
