/** 通知类型 */
export enum EnumNoticeType {
  /** 系统消息 */
  System = 1,
  /** 教室通知 */
  Classroom = 2,
  /** 手动推送 */
  Manual = 3,
  /** 用户消息 */
  Account = 4
}

/** 通知的业务类型 */
export enum EnumNoticeBizType {
  /** 订单 */
  Order = 1,
  /** 直播课 */
  Course = 2,
  /** 作业 */
  Homework = 3,
  /** 日课 */
  DayCourse = 4,
  /** 练习习作 */
  PracticeWork = 5,
  /** 正式作品 */
  FormalWork = 6,
  /** 日记 */
  Diary = 7
}

/** 通知的读取状态 */
export enum EnumNoticeReadStatus {
  /** 未收到 */
  NotReceive = 0,
  /** 未读 */
  NotRead = 1,
  /** 已读 */
  Read = 2,
  /** 未读撤回 */
  NotReadRecall = 3,
  /** 已读撤回 */
  ReadRecall = 4
}

/** 消息通知子类型 */
export enum EnumNoticeSubType {
  /** 作业消息提醒 */
  a = 40100,
  /** 作业评论提醒 */
  b = 40101,
  /** 作业评论回复提醒 */
  c = 40102,
  /** 作业点赞提醒 */
  d = 40103,
  /** 作业批阅提醒 */
  e = 40104,
  /** 作业批阅回复提醒 */
  f = 40105,
  /** 日记内容消息提醒 */
  g = 40200,
  /** 内容评论提醒 */
  h = 40201,
  /** 内容评论回复提醒 */
  i = 40202,
  /** 内容点赞提醒 */
  j = 40203
}
