





















import GroupPopup from './groupPopupScript'
import { Component } from 'vue-property-decorator'
import Popup from '../../popup/popup.vue'
import UserGroupPc from '../userGroup/userGroupPc.vue'

@Component({
  name: 'GroupPopopComp',
  components: { Popup, UserGroupPc }
})
export default class GroupPopopComp extends GroupPopup {}
