<template>
  <div class="list-model">
    <img class="live_all_exh_course_ele01" :src="ossProcessImg($images.live_all_exh_course_ele01, 465, 476)" />
    <img class="curse-model-live1" :src="ossProcessImg($images.live1_bg_ele, 736, 1182)" />
    <div class="list-content">
      <div class="list-content-left">
        <div class="list-content-left-title">
          <span>包含课程</span>
          <img :src="ossProcessImg($images.home_all_course_ele)" />
        </div>
        <div class="list-content-left-vice">{{ dataSource.courseTips }}</div>
        <Course
          v-for="(item, index) in dataSource.course"
          :key="index"
          :dataSource="item"
          :current="current"
          :index="index"
          @onClick="current = index"
        />
      </div>
      <div class="list-content-right">
        <div class="list-content-right-title">
          <span>课程表</span>
          <img :src="ossProcessImg($images.home_all_course_ele)" />
        </div>
        <div class="list-content-right-vice">
          {{ dataSource.course[current].title }}
        </div>
        <div class="list-content-right-swiper">
          <div class="list-content-right-swiper-tips">*点击可查看大图哦~</div>
          <LazyImg
            @click="ImagePreview(index)"
            v-for="(item, index) in dataSource.course"
            :key="index"
            :className="`list-content-right-swiper-item ${current === index && 'list-content-right-swiper-item-current'}`"
            :src="ossProcessImg(dataSource.course[index].message, 400)"
          />
        </div>
        <i
          @click="up"
          :class="`iconfont 
                  iconleft_circle_hover_ic
                  list-content-right-up
                `"
        ></i>
        <i
          @click="next"
          :class="`iconfont 
                  iconleft_circle_hover_ic
                  list-content-right-next
                `"
        ></i>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator'
import LazyImg from '@/components/lazyImg/index.vue'
import Course from './course.vue'
@Component({
  components: {
    LazyImg,
    Course
  }
})
export default class BMYX extends Vue {
  @Prop({}) dataSource
  current = 0

  ImagePreview(index) {
    let urls = []
    this.dataSource.course.map(v => urls.push(v.message))
    this.$showViewer(urls, this.current)
  }
  up() {
    if (this.current < 1) return (this.current = this.dataSource.course.length - 1)
    return (this.current = this.current - 1)
  }
  next() {
    if (this.current >= this.dataSource.course.length - 1) return (this.current = 0)
    return (this.current = this.current + 1)
  }
}
</script>
<style lang="less">
.live_all_exh_course_ele01 {
  width: 465px;
  height: 476px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateX(-671px);
}
.list-model {
  width: 100vw;
  min-width: 1200px;
  height: 1090px;
  background: #ede5dd;
  margin-top: -416px;
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 1;
}
.list-content {
  width: 1200px;
  margin-top: 341px;
  display: flex;
  position: relative;
  z-index: 11;
  &-left {
    width: 802px;
    &-title {
      font-size: 24px;
      font-weight: 500;
      color: #181c25;
      line-height: 24px;
      position: relative;
      height: 24px;
      span {
        z-index: 9;
        display: inline-block;
        position: absolute;
      }
      img {
        position: absolute;
        width: 96px;
        height: 7px;
        bottom: 0;
        left: 0;
      }
    }
    &-vice {
      font-size: 14px;
      color: #181c25;
      line-height: 14px;
      margin-top: 23px;
      margin-bottom: 10px;
    }
  }
  &-right {
    width: 398px;
    position: relative;
    &-title {
      font-size: 24px;
      font-weight: 500;
      color: #181c25;
      line-height: 24px;
      position: relative;
      height: 24px;
      span {
        z-index: 9;
        display: inline-block;
        position: absolute;
      }
      img {
        position: absolute;
        width: 72px;
        height: 5px;
        bottom: 0;
        left: 0;
      }
    }
    &-vice {
      font-size: 14px;
      color: #181c25;
      line-height: 14px;
      margin-top: 23px;
      margin-bottom: 10px;
      overflow: hidden;
    }
    &-swiper {
      width: 400px;
      height: 540px;
      background: #ffffff;
      border-radius: 6px;
      margin-top: 40px;
      padding: 0 20px;
      padding-bottom: 48px;
      position: relative;
      overflow: hidden;
      &-item {
        position: absolute;
        top: 0;
        left: 0;
        width: 400px;
        cursor: pointer;
        opacity: 0;
        transition: 0.25s;
        z-index: 1;
        &:hover {
          transform: scale(1.1);
        }

        &-current {
          transition: 0.4s;
          opacity: 1;
          z-index: 9;
        }
      }
      &-tips {
        width: 400px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #c37f7f;
        line-height: 48px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        background: white;
      }
    }
    &-up {
      font-size: 24px !important;
      color: #ffffff;
      position: absolute;
      top: 37px;
      left: 340px;
      display: inline-block;
      transform: rotateY(180deg);
      cursor: pointer;
      &:hover {
        color: #e0bf95;
      }
    }
    &-next {
      font-size: 24px !important;
      color: #ffffff;
      position: absolute;
      top: 37px;
      left: 374px;
      display: inline-block;
      cursor: pointer;
      &:hover {
        color: #e0bf95;
      }
    }
  }
}

.curse-model {
  &-live1 {
    width: 662px;
    height: 898px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    transform: translateX(592px);
  }
}
</style>
