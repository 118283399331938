







// https://github.com/yeild/jigsaw
import { Component, Vue, Emit } from 'vue-property-decorator'
import lodash from 'lodash'
import code from './code'
@Component({
  components: {}
})
export default class extends Vue {
  mounted() {
    try {
      new code({
        el: this.$refs.code,
        onSuccess: () => {},
        onFail: () => {},
        onRefresh: () => {}
      }).init()
    } catch (error) {
      console.error(error)
    }
  }
  updated() {}
  destroyed() {}
}
