





















































































import { Component, Prop, Vue, Provide, Mixins } from 'vue-property-decorator'
import { CommodityDayCourseMixins } from '@xt/client/mixins'
import Agreement from '../agreement.vue'
import MiniprogramPopup from '../miniprogramPopup.vue'
import Popup from '@xt/client/mixins/components/groupbuy/groupPopup/groupPopupPc.vue'

@Component({
  components: {
    Agreement,
    Popup,
    MiniprogramPopup
  }
})
export default class PageView extends Mixins(CommodityDayCourseMixins) {
  @Prop() isSelfCourse: boolean
  visibleMiniPopup: boolean = false

  goClockIn() {
    this.visibleMiniPopup = true
  }
}
