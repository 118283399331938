








import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({
  layout: 'clean'
})
export default class ServiceAgreement extends Vue {
  get htmlSrc() {
    return `${process.env.static}/protocol/user_pc.html?v=${process.env.version}`
  }

  jumpPage(e) {
    if (e.data.typeAction === 'jump') {
      this.$router.push({
        name: 'protocol-privacy'
      })
    }
  }

  @Watch('$route.query')
  changeQuery(query) {
    if (query.action === 'toTop') {
      window.frames['iframe'].postMessage({ typeAction: 'toTop' }, '*')
      this.$router.replace({ query: {} })
    }
  }

  created() {
    window.addEventListener('message', this.jumpPage)
  }

  beforeDestroy() {
    window.removeEventListener('message', this.jumpPage)
  }
}
