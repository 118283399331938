


























import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import { MixinsCourseClasshour } from '@xt/client/mixins'
import lodash from 'lodash'
@Component({
  components: {}
})
export default class PageView extends Mixins(MixinsCourseClasshour) {
  @Prop({ default: () => [] }) dataSource
  @Prop() getPopupContainer
  @Prop() index
  get id() {
    return this.dataSource.id
  }
  get Details() {
    return this.$store.$storeCourse.Details
  }
  get templateType() {
    return this.Details.dataSource.templateType
  }
  /**
   * 课程已生成回放
   */
  get playback() {
    return this.dataSource.playbackStatus === 1
  }
  // 笔山 悬浮方向
  getPlacement(index) {
    const collection = lodash.get(
      {
        10: [1, 2, 3, 6, 7, 8, 9],
        20: [1, 2, 3, 9, 10, 11, 12, 13]
      },
      this.templateType
    )
    if (lodash.includes(collection, index)) {
      return 'left'
    }
    return 'right'
  }
  // 笔山列表
  get bishanList() {
    return lodash.concat(
      [
        {
          hasCompleted: this.dataSource.videoPlay,
          homeworkTaskId: -1,
          name: this.playback ? '观看回放' : '观看直播'
        }
      ],
      this.dataSource.bishanList
    )
  }
  onIcon(hasCompleted) {
    return hasCompleted ? 'check-circle' : 'close-circle'
  }
  onTo(item) {
    try {
      this.CourseHelpers.onCheck(this.Details.dataSource)
      this.CourseHelpers.onCheckClasshour(this.dataSource)
      if (item.homeworkTaskId === -1) {
        this.onGolive(this.playback, this.id)
      } else {
        this.onToHomework({ id: item.homeworkTaskId })
      }
    } catch (error) {
      this.$msg(error)
    }
  }
  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
