/**
 * @author 冷 (https://github.com/LengYXin)
 * @email lengyingxin8966@gmail.com
 * @create date 2020-07-03 16:30:08
 * @modify date 2020-07-03 16:30:08
 * @desc [description]
 * 重置 antd icon
 */

export { default as QuestionCircleOutline } from '@ant-design/icons/lib/outline/QuestionCircleOutline'

export { default as QuestionOutline } from '@ant-design/icons/lib/outline/QuestionOutline'
export { default as GiftOutline } from '@ant-design/icons/lib/outline/GiftOutline'

export { default as LoadingOutline } from '@ant-design/icons/lib/outline/LoadingOutline'
export { default as RedoOutline } from '@ant-design/icons/lib/outline/RedoOutline'

export { default as CloseOutline } from '@ant-design/icons/lib/outline/CloseOutline'
export { default as CloseCircleOutline } from '@ant-design/icons/lib/outline/CloseCircleOutline'
export { default as CloseCircleFill } from '@ant-design/icons/lib/fill/CloseCircleFill'
export { default as CloseSquareFill } from '@ant-design/icons/lib/fill/CloseSquareFill'
export { default as InfoCircleOutline } from '@ant-design/icons/lib/outline/InfoCircleOutline'
export { default as InfoCircleFill } from '@ant-design/icons/lib/fill/InfoCircleFill'
export { default as InfoOutline } from '@ant-design/icons/lib/outline/InfoOutline'

export { default as ExclamationCircleOutline } from '@ant-design/icons/lib/outline/ExclamationCircleOutline'
export { default as ExclamationCircleFill } from '@ant-design/icons/lib/fill/ExclamationCircleFill'
export { default as ShareAltOutline } from '@ant-design/icons/lib/outline/ShareAltOutline'

export { default as ExclamationOutline } from '@ant-design/icons/lib/outline/ExclamationOutline'
// 勾
export { default as CheckCircleOutline } from '@ant-design/icons/lib/outline/CheckCircleOutline'

export { default as CheckOutline } from '@ant-design/icons/lib/outline/CheckOutline'
export { default as CheckCircleFill } from '@ant-design/icons/lib/fill/CheckCircleFill'

// 上
export { default as UpOutline } from '@ant-design/icons/lib/outline/UpOutline'
// 下
export { default as DownOutline } from '@ant-design/icons/lib/outline/DownOutline'
// 左
export { default as LeftCircleOutline } from '@ant-design/icons/lib/outline/LeftCircleOutline'

export { default as LeftOutline } from '@ant-design/icons/lib/outline/LeftOutline'
// 右
export { default as RightCircleOutline } from '@ant-design/icons/lib/outline/RightCircleOutline'

// 眼睛
export { default as EyeOutline } from '@ant-design/icons/lib/outline/EyeOutline'
export { default as EyeInvisibleOutline } from '@ant-design/icons/lib/outline/EyeInvisibleOutline'

export { default as RightOutline } from '@ant-design/icons/lib/outline/RightOutline'
// 搜索
export { default as SearchOutline } from '@ant-design/icons/lib/outline/SearchOutline'
// 通知
export { default as BellOutline } from '@ant-design/icons/lib/outline/BellOutline'
// 省略号
export { default as EllipsisOutline } from '@ant-design/icons/lib/outline/EllipsisOutline'
// 点赞
export { default as LikeOutline } from '@ant-design/icons/lib/outline/LikeOutline'
export { default as LikeFill } from '@ant-design/icons/lib/fill/LikeFill'
// 笑脸
export { default as SmileOutline } from '@ant-design/icons/lib/outline/SmileOutline'
// 消息
export { default as MessageOutline } from '@ant-design/icons/lib/outline/MessageOutline'
// 图片
export { default as PictureOutline } from '@ant-design/icons/lib/outline/PictureOutline'
// 爱心
export { default as HeartOutline } from '@ant-design/icons/lib/outline/HeartOutline'
export { default as HeartFill } from '@ant-design/icons/lib/fill/HeartFill'
// 人
export { default as TeamOutline } from '@ant-design/icons/lib/outline/TeamOutline'
// 播放
export { default as PlayCircleOutline } from '@ant-design/icons/lib/outline/PlayCircleOutline'
// 删除
export { default as DeleteOutline } from '@ant-design/icons/lib/outline/DeleteOutline'
export { default as PlusOutline } from '@ant-design/icons/lib/outline/PlusOutline'
// 下箭头
export { default as CaretDownOutline } from '@ant-design/icons/lib/outline/CaretDownOutline'
// 警告
export { default as WarningOutline } from '@ant-design/icons/lib/outline/WarningOutline'
export { default as WarningFill } from '@ant-design/icons/lib/fill/WarningFill'
// 购物车
export { default as ShoppingCartOutline } from '@ant-design/icons/lib/outline/ShoppingCartOutline'
// 右标识 caret-right
export { default as CaretRightOutline } from '@ant-design/icons/lib/outline/CaretRightOutline'
// <a-icon type="laptop" />
export { default as LaptopOutline } from '@ant-design/icons/lib/outline/LaptopOutline'
// <a-icon type="edit" />
export { default as EditOutline } from '@ant-design/icons/lib/outline/EditOutline'
// <a-icon type="container" />
export { default as ContainerOutline } from '@ant-design/icons/lib/outline/ContainerOutline'
// <a-icon type="mail" />
export { default as MailOutline } from '@ant-design/icons/lib/outline/MailOutline'
// <a-icon type="user" />
export { default as UserOutline } from '@ant-design/icons/lib/outline/UserOutline'
// <a-icon type="lock" />
export { default as LockOutline } from '@ant-design/icons/lib/outline/LockOutline'
// <a-icon type="man" />
export { default as ManOutline } from '@ant-design/icons/lib/outline/ManOutline'
// WomanOutline
export { default as WomanOutline } from '@ant-design/icons/lib/outline/WomanOutline'
// <a-icon type="calendar" />
export { default as CalendarOutline } from '@ant-design/icons/lib/outline/CalendarOutline'
// <a-icon type="double-right" />
export { default as DoubleRightOutline } from '@ant-design/icons/lib/outline/DoubleRightOutline'
// <a-icon type="sync" />
export { default as SyncOutline } from '@ant-design/icons/lib/outline/SyncOutline'
// <a-icon type="wechat" />
export { default as WechatOutline } from '@ant-design/icons/lib/outline/WechatOutline'
// <a-icon type="mobile" />
export { default as MobileOutline } from '@ant-design/icons/lib/outline/MobileOutline'
export { default as FireOutline } from '@ant-design/icons/lib/outline/FireOutline'
// <a-icon type="question-circle" />
export { default as QuestionCircleFill } from '@ant-design/icons/lib/fill/QuestionCircleFill'
// <a-icon type="arrow-up" />
export { default as ArrowUpOutline } from '@ant-design/icons/lib/outline/ArrowUpOutline'
