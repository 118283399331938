/**
 * @author wangsl
 */
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import Platform from '../platform'

@Observer
@Component
export default class signedAlertScript extends Vue {
  @Prop({}) visible: boolean
  @Prop({}) platform: string // 'web' || 'wap'

  get isWechatBowser() {
    return this.$store.$global.WechatBowser
  }

  get mobile() {
    return this.platform !== 'web' && Platform.Mobile()
  }

  goWf() {
    //这里如果想使用，请使用开放标签组件
    // if (!this.isWechatBowser) {
    //   return this.$msg("请在微信内操作")
    // }
    // window.location.href="weixin://dl/business/?t=siyFOZzZ9br"// update: 22022/6/9
  }

  @Emit('hide')
  hide() {}
}
