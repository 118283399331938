




















import UserGroup from './userGroupScript'
import { Component } from 'vue-property-decorator'
import HeaderImage from '../../../../../mobile/components/headerImage/index.vue'

@Component({
  name: 'UserGroupComp',
  components: { HeaderImage }
})
export default class UserGroupComp extends UserGroup {}
