














import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import lodash from 'lodash'
import { Observer } from 'mobx-vue'
@Observer
@Component({
  components: {}
})
export default class extends Vue {
  /**
   * 检查用户 不通过 唤起 登录窗口
   */
  @Prop({ default: false }) inspect: any
  get isInspect() {
    return lodash.includes(['', 'true', true], this.inspect)
  }
  get PageStore() {
    return this.$store.$storeUser
  }
  created() {
    try {
      this.$InspectUser(this.isInspect)
    } catch (error) {
      console.error('LENG: 用户校验未通过 请登录')
    }
  }
  mounted() {}
  updated() {}
  destroyed() {}
}
