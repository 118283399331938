
















import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component({
  components: {}
})
export default class MiniProgramPopup extends Vue {
  @Prop({ default: false }) visible: boolean
  @Prop({ default: '' }) title: string
  @Prop({ default: '' }) desc: string
  @Prop({ default: '' }) qrcodeUrl: any
  @Prop({ default: '' }) tip: string
  @Prop({ default: false }) loading: boolean

  @Emit('cancel')
  onCancel() {}

  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
