/** * wangsl */
<template>
  <div class="classmates" @mousedown="mousedown" @mousemove="mousemove" @mouseup="mouseup">
    <LazyImg className="classmates-top-ele" :src="ossProcessImg($images.classmate_top_ele, 216)" :noBackground="true" />
    <LazyImg className="classmates-cen-ele" :src="ossProcessImg($images.classmate_cen_ele, 677)" :noBackground="true" />
    <div
      @mouseover="stop = true"
      @mouseout="stop = false"
      :style="`
        left: ${$px2rem(index * 338)};
        transform: translateX(0rem);
      `"
      class="classmates-item"
      v-for="(item, index) in dataSource"
      :key="index"
      :ref="`classmates-item-${index}`"
    >
      <div class="classmates-item-img">
        <LazyImg
          :styles="`
            height: ${$px2rem(index % 2 == 0 ? 371 : 278)}
          `"
          :src="index % 2 == 0 ? ossProcessImg(item.img_b, 278, 371) : ossProcessImg(item.img_s, 278, 278)"
        />
      </div>
      <div class="classmates-item-nickname">{{ item.nickname }}</div>
      <div class="classmates-item-from">{{ item.from }}</div>
    </div>
    <div class="classmates-content">
      <div class="classmates-title">暄桐同学</div>
      <div class="classmates-vice">从第一笔至今，每一步都踏实</div>
      <!-- <div class="classmates-more">查看更多</div> -->
    </div>
  </div>
</template>
<script>
//ossProcessImg(PageStore.ArticleFirst.picBigUrl, 300)
import { Component, Vue, Prop } from 'vue-property-decorator'
import LazyImg from '@/components/lazyImg/index.vue'

@Component({
  components: {
    LazyImg
  }
})
class Index extends Vue {
  // 数据
  @Prop() dataSource

  data() {
    return {
      stop: false,
      windowWidth: window.outerWidth,
      move: false
    }
  }
  mousedown(e) {
    this.move = true
    this.oldX = e.clientX
  }
  mousemove(e) {
    if (!this.move) return
    this.oldX = e.clientX
  }
  mouseup() {
    this.move = false
  }
  get window() {
    return window
  }
  mounted() {
    setInterval(() => {
      if (this.stop) return
      for (let i = 0; i < this.dataSource.length; i++) {
        try {
          let item = this.$refs[`classmates-item-${i}`][0]
          let currentX = parseFloat(item.style.transform.replace('translateX(', '')) - 0.015625
          item.style.transform = `translateX(${currentX}rem)`
          if (currentX + parseFloat(item.style.left) < -17.375) {
            item.style.left = `${this.$px2rem((this.dataSource.length - 1) * 338 + 60)}`
            item.style.transform = `translateX(0rem)`
          }
        } catch (e) {}
      }
    }, 4)
    window.onresize = () => {
      this.windowWidth = window.outerWidth
    }
  }

  get window() {
    return window
  }
}

export default Index
</script>

<style scoped lang="less">
.classmates {
  width: 100%;
  height: 926px;
  background: #f4f0ec;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  text-align: center;
  min-width: 1200px;
  &-cen-ele {
    position: absolute;
    width: 677px;
    height: 847px;
    margin: auto;
    left: 0;
    right: 0;
    top: 20px;
    user-select: none;
    -webkit-user-drag: none;
  }
  &-top-ele {
    position: absolute;
    width: 216px;
    height: 90px;
    margin: auto;
    left: 0;
    right: 0;
    top: 60px;
    transform: translateX(-788px);
    user-select: none;
    -webkit-user-drag: none;
  }
  &-content {
    width: 1200px;
    min-width: 1200px;
    height: 100%;
    z-index: 9;
    user-select: none;
    -webkit-user-drag: none;
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  }
  &-title {
    font-size: 34px;
    font-weight: 500;
    color: #181c25;
    line-height: 34px;
    margin-top: 100px;
    width: 1200px;
    min-width: 1200px;
    text-align: center;
  }
  &-vice {
    font-size: 18px;
    color: #181c25;
    line-height: 18px;
    margin-top: 20px;
    width: 1200px;
    min-width: 1200px;
    text-align: center;
  }
  &-item {
    width: 278px;
    position: absolute;
    bottom: 206px;
    text-align: left;
    z-index: 11;
    &:hover {
      .classmates-item-img {
        img {
          transform: scale(1.1);
        }
      }
      .classmates-item-from {
        padding-bottom: 20px;
      }
    }
    &-img {
      width: 278px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(173, 145, 116, 0.3);
      border-radius: 12px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        user-select: none;
        -webkit-user-drag: none;
        transition: transform 0.5s;
      }
    }
    &-nickname {
      margin-top: 30px;
      font-size: 18px;
      font-weight: 500;
      color: #000000;
      line-height: 18px;
    }
    &-from {
      font-size: 14px;
      color: #333333;
      line-height: 24px;
      margin-top: 15px;
      transition: padding-bottom 0.5s;
    }
  }
  &-more {
    width: 120px;
    height: 36px;
    border-radius: 19px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: white;
    margin-top: 80px;
    cursor: pointer;
    background: #e0bf95;
    position: absolute;
    bottom: 130px;
    &:hover {
      background: #e0b47e;
      color: white;
    }
  }
}
</style>
