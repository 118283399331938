




































import CourseScript from './courseScript'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import LazyImg from '@/components/lazyImg/index.vue'

@Observer
@Component({
  name: 'CourseComp',
  components: { LazyImg }
})
export default class CourseComp extends CourseScript {}
