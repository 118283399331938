export type Config = {
  /**
   * 每隔多少毫秒开始轮询
   */
  pollingInterval: number
  /**
   * 轮询错误重试次数
   */
  pollingErrorRetryCount: number

  onError?: () => void

  checkResult?: (result: any) => 0 | 1 | 2
}

export class Loop {
  private pollingInterval: number
  private pollingErrorRetryCount: number
  private asyncFn: () => Promise<any>
  private onErrorCallback: () => void
  private checkResultCallback: (result: any) => 0 | 1 | 2

  private currentRetryTime = 1
  private pollingTimer: ReturnType<typeof setInterval> | null = null
  private isEnd = false
  private isLastRequestFailed = false

  static CheckResult = {
    Loop: 0,
    Retry: 1,
    Stop: 2
  } as const

  constructor(asyncFn: () => Promise<any>, config: Config) {
    this.pollingInterval = config.pollingInterval
    this.pollingErrorRetryCount = config.pollingErrorRetryCount
    this.asyncFn = asyncFn
    this.onErrorCallback = config.onError ?? (() => {})
    this.checkResultCallback = config.checkResult ?? (_result => Loop.CheckResult.Loop)

    this.startPolling()
  }

  async startPolling() {
    try {
      const result = await this.asyncFn()
      const checkResult = this.checkResultCallback(result)

      if (checkResult === Loop.CheckResult.Retry) {
        throw new Error('check result return false')
      } else if (checkResult === Loop.CheckResult.Stop) {
        this.currentRetryTime = this.pollingErrorRetryCount
      } else {
        this.isLastRequestFailed = false
      }
    } catch (e) {
      console.log('error', e)
      this.currentRetryTime = this.isLastRequestFailed ? this.currentRetryTime + 1 : 1
      this.isLastRequestFailed = true
    } finally {
      if (this.currentRetryTime >= this.pollingErrorRetryCount) {
        this.onErrorCallback()
        this.stopPolling()
        return
      }

      if (!this.isEnd) {
        this.pollingTimer = setTimeout(() => {
          this.startPolling()
        }, this.pollingInterval)
      }
    }
  }

  stopPolling() {
    this.isEnd = true
    if (this.pollingTimer) {
      clearInterval(this.pollingTimer)
      this.pollingTimer = null
    }
  }
}
