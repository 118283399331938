


























import { Observer } from 'mobx-vue'
import { Vue, Component } from 'vue-property-decorator'
import OrderProductScript from './script'
import LazyImg from '@/components/lazyImg/index.vue'
import { EnumProductType } from '@xt/client/enums'

@Observer
@Component({
  name: 'OrderProductComp',
  components: {
    LazyImg
  }
})
export default class OrderProductComp extends OrderProductScript {}
