/**
 *  通用 API 地址
 */
export enum EnumApiCurrency {
  /** oss 签名 */
  files = '/file/api/files/policy',
  wxFiles = '/file/api/files2/from/weixin',
  /** 微信签名 */
  wxmp = '/uc/api/wxmp/sign',
  /** 获取用户 openid */
  openid = '/uc/api/wxmp/openid',
  /** 获取私有访问的url */
  FilesUrl = '/file/api/files/url',
  /** 是否可以显示注销账号 **/
  checkWechat = '/uc/api/members/checkWechat',
  /** 是否可以注销 **/
  checkWechat2 = '/uc/api/members/checkWechat2',
  /** 注销 **/
  cancelWechat = '/uc/api/members/cancelWechat?cancel={cancel}&tempToken={tempToken}',
  // 通过验证码获取临时token /api/members/cancelWechat 接口需要使用
  GetTempToken = '/uc/api/members/verifycode/check',
  /**
   * 分享记录
   *
   * @deprecated
   */
  shareRecord = '/show/api/show/{showId}/share/record',
  /**
   * 分享记录
   *
   */
  shareRecordV2 = '/show/api/show/v2/share/record'
}
