























import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import { Debounce, BindAll } from 'lodash-decorators'
import draggable from 'vuedraggable'
import lodash from 'lodash'
import LazyImg from '@/components/lazyImg/index.vue'

@Component({
  components: { draggable, LazyImg }
})
export default class extends Vue {
  @Prop({ default: () => [] }) dataSource: Array<any>
  @Prop({ default: 'thumb' }) thumb //缩略图
  @Prop({ default: 'original' }) original //原图
  @Prop({ default: 200 }) size
  @Prop({ default: 10 }) margin
  @Prop({ default: false }) draggable //拖拽
  @Prop({}) viewerOptions
  drag = false
  get dragOptions() {
    return {
      animation: 200,
      group: 'description',
      disabled: !this.draggable,
      ghostClass: 'draggable-ghost',
      filter: '.xt-nine-img-cross'
    }
  }
  get images() {
    return [...this.dataSource]
  }
  set images(value) {
    this.$emit('change', value)
  }
  get options() {
    return lodash.merge(
      {
        url: 'data-original'
      },
      this.viewerOptions
    )
  }
  get length() {
    return this.dataSource?.length
  }
  getThumb(img) {
    if (lodash.isString(img)) {
      return img
    }
    return this.ossProcessImg(lodash.get(img, this.original), this.draggable ? 200 : lodash.get({ '1': 500, '4': 300 }, this.length, 200))
  }
  getOriginal(img) {
    if (lodash.isString(img)) {
      return img
    }
    return this.ossProcessImg(lodash.get(img, this.original), 1200)
  }
  getSpan() {
    if (!this.draggable) {
      if (this.length === 1) {
        return 24
      } else if (this.length === 4) {
        return 12
      } else if (this.length === 2 || this.length === 2) {
        return 12
      }
    }
    return 8
  }
  //   图片大小
  getStyle() {
    let base = 3
    let style = {
      overflow: null,
      maxWidth: null,
      maxHeight: null,
      width: this.size + 'px',
      height: this.size + 'px',
      // margin: "auto",
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: this.margin + 'px'
    }
    if (this.length === 1 && !this.draggable) {
      style.width = null
      style.maxWidth = this.size * 2 + 'px'
      style.maxHeight = this.size * 3 + 'px'
      style.height = 'auto'
      style.marginLeft = '0'
      // style.marginBottom = "0";
      // style.margin = "0";
    }
    if (this.length === 2 && !this.draggable) {
      // style.maxWidth = this.size * 2 + "px";
    }
    return style
  }
  // 容器大小
  getNineStyle() {
    let base = 3
    let margin = this.margin * base //lodash.ceil(this.length / base);
    let style = {
      overflow: null,
      width: null,
      maxWidth: null,
      maxHeight: null
    }
    if (!this.draggable) {
      if (lodash.includes([1, 2, 4], this.length)) {
        base = 2
        if (this.length === 1) {
          margin = 0
        } else {
          margin -= this.margin
        }
      }
      if (lodash.includes([2, 3], this.length)) {
        style.width = this.size * base + margin + 'px'
      }
      style.maxWidth = this.size * base + margin + 'px'
      // if (this.length !== 1) {
      // style.maxHeight = this.size * 3 + margin + 10 + "px";
      // }
    }
    return style
  }
  ImagePreview(index) {
    this.$showViewer(lodash.map(this.dataSource, this.getOriginal.bind(this)), index)
    lodash.result(this.options, 'show')
  }
  created() {}
  mounted() {}
}
