























import { Component, Prop, Vue, InjectReactive } from 'vue-property-decorator'
import lodash from 'lodash'
import LazyImg from '@/components/lazyImg/index.vue'
import { EnumProductTypeQuery } from '@xt/client/enums'

@Component({
  components: { LazyImg }
})
export default class PageView extends Vue {
  @InjectReactive('dataSource') dataSource: any
  loading = true
  loadErr = false
  get pictureList() {
    let entryKey = this.dataSource.productInfo.onlyEntryKey
    if (entryKey === EnumProductTypeQuery.Course) {
      return this.dataSource.courseInfo.pictureListPC
    } else if ([EnumProductTypeQuery.DayCourse, EnumProductTypeQuery.SelfStudy].includes(entryKey)) {
      return this.dataSource.courseInfo.picturesPC
    }
    const showPicturePC = this.dataSource.courseInfo.showPictureParamVOS.filter(item => item.picType === 1)
    return showPicturePC
  }
  get urlKey() {
    let entryKey = this.dataSource.productInfo.onlyEntryKey
    return entryKey === EnumProductTypeQuery.Course ? 'pictureUrl' : 'originalUrl'
  }
  isEmpty(data) {
    return lodash.isEmpty(data)
  }
  // imgs = [];
  // async created() {
  //   const res = await this.PageStore.onGetCourseIntroduce({
  //     courseId: this.$route.params.id,
  //     courseType: 1,
  //   });
  //   this.imgs = res;
  // }
  onLoadImg() {
    this.loading = false
  }
  onLoadError() {
    this.loading = false
    this.loadErr = true
  }
  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
