import { Observer } from 'mobx-vue'
import { Component, Prop } from 'vue-property-decorator'
import ProductScript from '@xt/client/mixins/product/product'

@Observer
@Component
export default class OrderProductScript extends ProductScript {
  toLiveCourseDetail(): void {
    if (this.dataSource.origin.activityPageUrl) this.$router.push({ path: this.dataSource.origin.activityPageUrl })
    else
      this.$router.push({
        path: `/commodity/${(this.dataSource.origin as any).productId.toString()}`
      })
  }

  toDayCourseDetail(): void {
    this.$router.push({
      path: `/commodity/${(this.dataSource.origin as any).productId.toString()}`
    })
  }

  // 注：这一版存在展览详情页，所以全部课程中的展览需要跳转到展览详情页
  toExhibitionDetail(): void {
    this.$router.push({
      path: `/commodity/${(this.dataSource.origin as any).productId.toString()}`
    })
  }
}
