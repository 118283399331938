/** * @author wangsl */
<template>
  <div>
    <div class="mantle" v-show="visible" @click="hide"></div>
    <div :class="`content ${visible && 'content-show'}`">
      <div class="picker-title"><h3>报名后，你需要：</h3></div>
      <div class="picker-box">
        <div class="scroll-content" ref="scrollContent">
          <div class="scroll-content-body">
            <div class="step1">1、添加书童微信，入学习群。<span>3月中旬陆续邀请入群哦~</span></div>
            <div class="step1-img">
              <img src="https://static.xuantong.cn/images/signAlert/popu_apply_code_im.png" />
              <div>{{ mobile ? '长按识别' : '微信扫描' }}</div>
            </div>
            <div class="step2">2、准备文房文具、书籍、字帖。<span>电子版字帖，也会随课程传至资料区</span></div>
            <div v-if="!mobile" class="step1-img">
              <img :src="$store.$storeGroup.wfcode" />
              <div>{{ mobile ? '长按识别' : '微信扫描' }}</div>
            </div>
            <div v-if="mobile" class="buy-study">去购买</div>
            <div v-if="mobile" class="buy-study-bottom">一键购齐</div>
            <div class="tips">4月14日，周四晚八点开课，等你哦</div>
          </div>
        </div>
      </div>
      <div class="pop-login-close" @click="hide">
        <img src="https://static.xuantong.cn/course/detail/login_close.png?x-oss-process=image/resize,w_1080,m_lfit/quality,q_90" />
      </div>
    </div>
  </div>
</template>

<script>
import { Component } from 'vue-property-decorator'
import signedAlertScript from './signedAlertScript'
import { Observer } from 'mobx-vue'

@Observer
@Component({
  name: 'SignedAlertComp'
})
export default class SignedAlertComp extends signedAlertScript {}
</script>

<style lang="less" scoped>
@import './signedAlert.less';
</style>
