/**
 *  Video api 枚举
 */
export enum EnumApiVideo {
  /** 视频列表 */
  VideoList = '/core/api/videoshares',
  /** 视频推荐列表 */
  VideoRecommend = '/core/api/videoshares/recommend',
  /** 视频详情 */
  VideoDetail = '/core/api/videoshares/{id}',
  /** 视频点赞 */
  VideoLike = '/core/api/videoshares/{id}/like',
  /** 添加播放量 */
  VideoPlayNumber = '/core/api/videoshares/{id}/play',

  /** 视频分享-评论列表 */
  VideoCommentList = '/core/api/videoshares/{videoShareId}/comments',
  /** 视频分享-评论 发布评论 */
  VideoComment = '/core/api/videoshares/{videoShareId}/comments',
  /** 视频分享-评论 点赞 */
  VideoCommentLike = '/core/api/videosharecomments/{id}/like',
  /** 视频分享-评论 删除 */
  VideoCommentDelete = '/core/api/videoshares/comments/{id}'
}
