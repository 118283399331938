/** * wangsl */
<template>
  <div class="about-model">
    <LazyImg className="about-top-ele" :src="ossProcessImg($images.about_top_ele, 388)" :noBackground="true" />
    <LazyImg className="about-cen-ele" :src="ossProcessImg($images.about_cen_ele, 510)" :noBackground="true" />
    <LazyImg className="about-bot-ele" :src="ossProcessImg($images.about_bot_ele_all, 350)" :noBackground="true" />
    <div class="about-xt">
      <div class="about-xt-first-box">
        <LazyImg className="about-xt-first-box-cover" :src="ossProcessImg($images.about_xt_im01, 422, 620)" />
        <div class="about-xt-first-box-content">
          <h1>关于暄桐.</h1>
          <LazyImg className="about-xt-first-box-content-bishan" :src="$images.about_bushan_im" :noBackground="true" />
          <h4>暄桐是一间传统美学教育教室</h4>
          <h5>
            暄桐是一间传统美学教育教室，创办于2011年，林曦是创办人和授课老师，教授以书法为主的传统文化和技艺，皆在以书法为起点，亲近中国传统之美，以实践和所得，滋养当下生活。
          </h5>
          <h4>“写字是一种生活”</h4>
          <h5>
            暄桐以传统文人审美为土壤，教授零基础的成年人研习书法与传统，内容也包括与书法相关的文化和技艺，如绘画、中医、静坐、经典阅读等。“写字是一种生活”，这里有传统的修养学习和实践，也致力于启发当代思考和创意，推崇非功利的学习态度、扎实精进且享受当下。
          </h5>
        </div>
      </div>

      <div class="about-xt-sec-box">
        <div class="about-xt-sec-box-content">
          <LazyImg className="about-xt-sec-box-content-brush" :src="$images.about_brush_im" :noBackground="true" />
          <h4>线上课程的开设</h4>
          <h5>
            七年积累后，于2018年开设了线上课程，使用直播互动、在线答疑、助教和书童辅助学习和作业等方式进行教学，并设置了一套督促和帮助学习的系统，使得课程得以跨越时空限制，与大家分享。
          </h5>
          <h4>从执笔到五种书体的书写体系</h4>
          <h5>
            自2011年暄桐教室创立，林曦老师已累积2300余学时的教学经验。和不同年级的同学一起完成了从最初的执笔蘸墨，到篆隶楷行草等不同书体的完整书写练习的过程，为许多热爱、喜欢传统文化的人提供系统学习和实践的可能。
          </h5>
          <div class="qr-box">
            <div class="qr-box-text">
              <div class="qr-box-text-label">想了解暄桐更多</div>
              <div class="qr-box-text-msg">微信扫一扫</div>
            </div>
            <div class="qr-box-img">
              <LazyImg :src="$images.xt_classroom_gzh" />
            </div>
          </div>
        </div>
        <LazyImg className="about-xt-sec-box-cover" :src="ossProcessImg($images.about_xt_im02, 422, 620)" />
      </div>

      <div class="about-xt-thr-box">
        <div class="about-xt-thr-box-lx">
          <LazyImg :src="ossProcessImgJPG($images.lx, 422, 683)" />
          <div class="about-xt-thr-box-lx-text">林曦老师</div>
        </div>
        <div class="about-xt-thr-box-content">
          <h1>关于林曦.</h1>
          <LazyImg class="about-xt-thr-box-content-linxi" :src="$images.about_linxi_im" :noBackground="true" />
          <p>林曦，1983年出生于重庆，毕业于中央美术学院，水墨画家，暄桐教室的创办人与授课老师。</p>
          <p>
            受家庭熏陶，她从小就很喜欢并且有机会深入学习体验中国传统，尤其喜爱书法。学习过程中，有幸与诸位大德名士结缘，被教导，并熏习其中。
          </p>
          <p>
            在举办画展、出版诗文画集、古琴与音乐专辑的同时，林曦致力于中国文人传统美学和生活方式的转译与传播，于2011年创办暄桐教室。课程包括书法、绘画、中医、静坐、经典阅读等内容，开设有线下与线上课程，为广大喜爱书法和传统的同好提供了一种有趣扎实，可坚持进阶的学习可能。
          </p>
          <p>
            自2011年暄桐教室创立，林曦老师已累积2300余学时的教学经验。从执笔蘸墨写下第一个线条，到篆、隶、真、行、草各个书体的系统学习，为喜爱书法和传统的同好提供了一种有趣扎实，可坚持进阶的学习可能。
          </p>
        </div>
      </div>
      <div class="about-xt-fourth-box">她还创办了美育和文化工作室「林曦的小世界」、基于中式文人审美的独立设计品牌「山林曦照」。</div>
      <div class="about-xt-fifth-box">
        <LazyImg :src="ossProcessImgJPG($images.about_lx_im01, 154, 100)" />
        <LazyImg :src="ossProcessImgJPG($images.about_lx_im02, 154, 100)" />
        <LazyImg :src="ossProcessImgJPG($images.about_lx_im03, 154, 100)" />
        <div class="qr-box">
          <div class="qr-box-text">
            <div class="qr-box-text-label">想了解林曦老师更多</div>
            <div class="qr-box-text-msg">微信扫一扫</div>
          </div>
          <div class="qr-box-img">
            <LazyImg :src="ossProcessImgJPG($images.lx_qrcode, 100, 100)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator'
import LazyImg from '@/components/lazyImg/index.vue'

@Component({
  components: {
    LazyImg
  }
})
class Index extends Vue {
  data() {
    return {
      hoverMap: false
    }
  }
  mouseover() {
    this.hoverMap = true
  }
  mouseout() {
    this.hoverMap = false
  }
  mounted() {}
}

export default Index
</script>

<style scoped lang="less">
.wf-jean-bg-ele {
  width: 1282px;
  height: 306px;
  position: absolute;
  margin: auto;
  bottom: 171px;
  left: 0;
  right: 0;
  transform: translateX(-169px);
}
.about-model {
  height: 3325px;
  width: 100vw;
  background: #f6f6f6;
  position: relative;
  display: flex;
  justify-content: center;
  min-width: 1200px;
  .about {
    &-top-ele {
      width: 388px;
      height: 498px;
      position: absolute;
      margin: auto;
      top: 623px;
      left: 0;
      right: 0;
      transform: translateX(-824px);
    }
    &-cen-ele {
      width: 510px;
      height: 340px;
      position: absolute;
      margin: auto;
      right: 0;
      left: 0;
      top: 1713px;
      transform: translateX(355px);
    }

    &-bot-ele {
      width: 350px;
      height: 330px;
      position: absolute;
      margin: auto;
      right: 0;
      left: 0;
      top: 2785px;
      transform: translateX(-425px);
    }
  }
}
.about {
  &-xt {
    display: inline-block;
    width: 1200px;
    min-width: 1200px;
    padding-top: 473px;
    &-wf {
      &-logo {
        width: 169px;
        height: 100px;
        margin-top: 210px;
      }
      &-model {
        width: 1200px;
        height: 724px;
        margin-top: 80px;
        position: relative;
      }
      &-box {
        width: 1200px;
        height: 458px;
        position: relative;
        background: white;
        z-index: 5;
      }
      &-basic {
        width: 1200px;
        height: 266px;
        background: #ffffff;
        border-radius: 2px;
        display: flex;
        &-tel {
          width: 456px;
          padding-left: 60px;
        }
        &-label {
          font-size: 16px;
          font-weight: 500;
          color: #000000;
          line-height: 17px;
          margin-top: 51px;
          margin-bottom: 30px;
        }
        &-address {
          width: 526px;
        }
        &-qrcode {
          div {
            margin-left: 8px;
          }
          img {
            width: 88px;
            height: 88px;
            transition: transform 0.5s;
            &:hover {
              transform: scale(1.2);
            }
          }
        }
        &-msg {
          margin-bottom: 20px;
          height: 22px;
          display: flex;
          align-items: center;
          i {
            margin-right: 8px;
            font-size: 22px;
            color: #bda893;
          }
          &-text {
            font-size: 14px;
            color: #000000;
            line-height: 18px;
          }
        }
      }
      &-monkey {
        width: 182px;
        height: 210px;
        position: absolute;
        top: -60px;
        right: 0;
        z-index: 1;
        &-hand {
          width: 109px;
          height: 30px;
          position: absolute;
          z-index: 7;
          right: 40px;
          top: -6px;
        }
        &-bottom {
          width: 86px;
          height: 98px;
          position: absolute;
          z-index: 7;
          left: 62px;
          top: 379px;
        }
      }
      &-map {
        position: absolute;
        top: 0;
        left: 0;
        width: 1200px;
        height: 458px;
        padding: 0;
        margin: 0;
        opacity: 0;
        transition: opacity 0.5s;
        z-index: 8;
        &-hover {
          opacity: 1;
        }
      }
      &-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-user-drag: none;
        transition: opacity 0.5s;
        &-box {
          width: 1200px;
          height: 458px;
          position: relative;
        }
        &-hover {
          opacity: 0;
        }
      }
    }
    h1 {
      padding: 0;
      font-size: 48px;
      font-weight: 500;
      color: #181c25;
      line-height: 48px;
      margin: 0;
    }
    h4 {
      padding: 0;
      margin: 0;
      font-size: 24px;
      color: #181c25;
      line-height: 32px;
      margin-top: 60px;
      font-weight: normal;
    }

    h5 {
      padding: 0;
      margin: 0;
      font-size: 18px;
      color: #888888;
      line-height: 36px;
      margin-top: 20px;
      font-weight: normal;
    }
    &-first-box {
      display: flex;
      &-cover {
        width: 422px;
        height: 620px;
        -webkit-user-drag: none;
      }
      &-content {
        margin-left: 130px;
        flex: 1;
        &-bishan {
          width: 30px;
          height: 20px;
          margin-top: 60px;
          -webkit-user-drag: none;
        }
      }
    }
    &-sec-box {
      display: flex;
      margin-top: 80px;
      &-cover {
        margin-left: 130px;
        width: 422px;
        height: 620px;
        -webkit-user-drag: none;
      }
      &-content {
        flex: 1;
        &-brush {
          width: 100px;
          height: 10px;
          -webkit-user-drag: none;
        }
      }
    }
    &-thr-box {
      display: flex;
      margin-top: 210px;
      &-lx {
        width: 422px;
        margin-right: 130px;
        img {
          width: 422px;
          height: 683px;
          -webkit-user-drag: none;
        }
        &-text {
          margin-top: 40px;
          font-size: 18px;
          font-weight: 500;
          color: #ad9174;
          line-height: 18px;
          text-align: center;
        }
      }
      &-content {
        flex: 1;
        h1 {
          margin-top: 40px;
        }
        &-linxi {
          width: 30px;
          height: 32px;
          margin-top: 50px;
          margin-bottom: 60px;
          -webkit-user-drag: none;
        }
        p {
          margin: 0;
          padding: 0;
          font-size: 18px;
          font-weight: 400;
          color: #181c25;
          line-height: 36px;
          margin-bottom: 40px;
        }
      }
    }
    &-fourth-box {
      font-size: 18px;
      font-weight: 400;
      color: #181c25;
      line-height: 36px;
    }
    &-fifth-box {
      display: flex;
      position: relative;
      & > img {
        width: 154px;
        height: 100px;
        margin-right: 20px;
        margin-top: 60px;
        -webkit-user-drag: none;
      }
      .qr-box {
        position: absolute;
        right: 0;
      }
    }
  }
}
.qr-box {
  width: 648px;
  height: 100px;
  background: fade(#ad9174, 10%);
  border-radius: 2px;
  margin-top: 60px;
  display: flex;
  padding: 22px 32px;
  user-select: none;
  &-text {
    flex: 1;
    &-label {
      font-size: 24px;
      font-weight: 500;
      color: #181c25;
      line-height: 24px;
    }
    &-msg {
      font-size: 14px;
      font-weight: 400;
      color: #181c25;
      line-height: 14px;
      margin-top: 10px;
    }
  }
  &-img {
    width: 66px;
    justify-content: flex-end;
    display: inline-flex;
    align-items: center;
    img {
      width: 66px;
      height: 66px;
      transition: transform 0.5s;
      border: 3px solid #ffffff;
      background: white;
      &:hover {
        transform: scale(1.5);
      }
    }
  }
}
</style>
