/** * wangsl */
<template>
  <div class="index">
    <LazyImg className="wf-jean-bg-ele" :src="ossProcessImg($images.wf_jean_bg_ele, 350)" :noBackground="true" />
    <div class="about-xt">
      <LazyImg className="about-xt-wf-logo" :src="$images.wf_logo_im" />
      <div class="about-xt-wf-model">
        <LazyImg className="about-xt-wf-monkey" :src="$images.wf_monkey_bg_ele" :noBackground="true" />
        <LazyImg className="about-xt-wf-monkey-hand" :src="$images.wf_monkey_hand_ele" :noBackground="true" />
        <LazyImg className="about-xt-wf-monkey-bottom" :src="$images.wf_monkey_ele" :noBackground="true" />
        <div class="about-xt-wf-box">
          <div class="about-xt-wf-img-box">
            <LazyImg :className="`about-xt-wf-map ${hoverMap && 'about-xt-wf-map-hover'}`" :src="ossProcessImg($images.wf_mp, 1500, 574)" />
            <LazyImg
              :className="`about-xt-wf-img ${hoverMap && 'about-xt-wf-img-hover'}`"
              :src="ossProcessImg($images.xt_wf_pic, 1500, 574)"
            />
          </div>
        </div>
        <div class="about-xt-wf-basic" @mouseover="mouseover" @mouseout="mouseout">
          <div class="about-xt-wf-basic-tel">
            <div class="about-xt-wf-basic-label">活泼泼地文房</div>
            <div class="about-xt-wf-basic-msg">
              <div>
                <i class="iconfont iconwf_contact_ic"></i>
              </div>
              <div class="about-xt-wf-basic-msg-text">15724748576</div>
            </div>
            <div class="about-xt-wf-basic-msg">
              <div>
                <i class="iconfont iconwf_email_ic"></i>
              </div>
              <div class="about-xt-wf-basic-msg-text">xuantongwenfang@qq.com</div>
            </div>
          </div>
          <div class="about-xt-wf-basic-address">
            <div class="about-xt-wf-basic-label">文房地址</div>
            <div class="about-xt-wf-basic-msg">
              <div>
                <i class="iconfont iconwf_address_ic"></i>
              </div>
              <div class="about-xt-wf-basic-msg-text">北京市朝阳区建国门外大街1号国贸商城F4</div>
            </div>
          </div>
          <div class="about-xt-wf-basic-qrcode">
            <div class="about-xt-wf-basic-label">关注我们</div>
            <LazyImg :src="ossProcessImgJPG($images.xt_wenfang_gzh, 100, 100)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator'
import LazyImg from '@/components/lazyImg/index.vue'

@Component({
  components: {
    LazyImg
  }
})
class Index extends Vue {
  data() {
    return {
      hoverMap: false
    }
  }
  mouseover() {
    this.hoverMap = true
  }
  mouseout() {
    this.hoverMap = false
  }
  controls() {}
  mounted() {}
}

export default Index
</script>

<style scoped lang="less">
.index {
  width: 100vw;
  min-width: 1200px;
  height: 1100px;
  background: #f6f6f6;
  position: relative;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wf-jean-bg-ele {
  width: 1282px;
  height: 306px;
  position: absolute;
  margin: auto;
  bottom: 14px;
  left: 0;
  right: 0;
  transform: translateX(-169px);
}
.about-model {
  height: 4442px;
  width: 100vw;
  background: #f6f6f6;
  position: relative;
  display: flex;
  justify-content: center;
  min-width: 1200px;
  .about {
    &-top-ele {
      width: 388px;
      height: 498px;
      position: absolute;
      margin: auto;
      top: 623px;
      left: 0;
      right: 0;
      transform: translateX(-824px);
    }
    &-cen-ele {
      width: 510px;
      height: 340px;
      position: absolute;
      margin: auto;
      right: 0;
      left: 0;
      top: 1713px;
      transform: translateX(355px);
    }

    &-bot-ele {
      width: 350px;
      height: 330px;
      position: absolute;
      margin: auto;
      right: 0;
      left: 0;
      top: 2785px;
      transform: translateX(-425px);
    }
  }
}
.about {
  &-xt {
    display: inline-block;
    width: 1200px;
    min-width: 1200px;
    &-wf {
      &-logo {
        width: 169px;
        height: 100px;
      }
      &-model {
        width: 1200px;
        height: 724px;
        margin-top: 80px;
        position: relative;
      }
      &-box {
        width: 1200px;
        height: 458px;
        position: relative;
        background: white;
        z-index: 5;
      }
      &-basic {
        width: 1200px;
        height: 266px;
        background: #ffffff;
        border-radius: 2px;
        display: flex;
        &-tel {
          width: 456px;
          padding-left: 60px;
        }
        &-label {
          font-size: 16px;
          font-weight: 500;
          color: #000000;
          line-height: 17px;
          margin-top: 51px;
          margin-bottom: 30px;
        }
        &-address {
          width: 526px;
        }
        &-qrcode {
          div {
            margin-left: 8px;
          }
          img {
            width: 88px;
            height: 88px;
            transition: transform 0.5s;
            &:hover {
              transform: scale(1.2);
            }
          }
        }
        &-msg {
          margin-bottom: 20px;
          height: 22px;
          display: flex;
          align-items: center;
          i {
            margin-right: 8px;
            font-size: 22px;
            color: #bda893;
          }
          &-text {
            font-size: 14px;
            color: #000000;
            line-height: 18px;
          }
        }
      }
      &-monkey {
        width: 182px;
        height: 210px;
        position: absolute;
        top: -60px;
        right: 0;
        z-index: 1;
        &-hand {
          width: 109px;
          height: 30px;
          position: absolute;
          z-index: 7;
          right: 40px;
          top: -6px;
        }
        &-bottom {
          width: 130px;
          height: 130px;
          position: absolute;
          z-index: 7;
          left: 60px;
          top: 359px;
        }
      }
      &-map {
        position: absolute;
        top: 0;
        left: 0;
        width: 1200px;
        height: 458px;
        padding: 0;
        margin: 0;
        opacity: 0;
        transition: opacity 0.5s;
        z-index: 8;
        &-hover {
          opacity: 1;
        }
      }
      &-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-user-drag: none;
        transition: opacity 0.5s;
        &-box {
          width: 1200px;
          height: 458px;
          position: relative;
        }
        &-hover {
          opacity: 0;
        }
      }
    }
    h1 {
      padding: 0;
      font-size: 48px;
      font-weight: 500;
      color: #181c25;
      line-height: 48px;
      margin: 0;
    }
    h4 {
      padding: 0;
      margin: 0;
      font-size: 24px;
      color: #181c25;
      line-height: 32px;
      margin-top: 60px;
    }

    h5 {
      padding: 0;
      margin: 0;
      font-size: 18px;
      color: #888888;
      line-height: 36px;
      margin-top: 20px;
    }
    &-first-box {
      display: flex;
      &-cover {
        width: 422px;
        height: 620px;
        -webkit-user-drag: none;
      }
      &-content {
        margin-left: 130px;
        flex: 1;
        &-bishan {
          width: 30px;
          height: 20px;
          margin-top: 60px;
          -webkit-user-drag: none;
        }
      }
    }
    &-sec-box {
      display: flex;
      margin-top: 80px;
      &-cover {
        margin-left: 130px;
        width: 422px;
        height: 620px;
        -webkit-user-drag: none;
      }
      &-content {
        flex: 1;
        &-brush {
          width: 100px;
          height: 10px;
          -webkit-user-drag: none;
        }
      }
    }
    &-thr-box {
      display: flex;
      margin-top: 210px;
      &-lx {
        width: 422px;
        margin-right: 130px;
        img {
          width: 422px;
          height: 683px;
          -webkit-user-drag: none;
        }
        &-text {
          margin-top: 40px;
          font-size: 18px;
          font-weight: 500;
          color: #ad9174;
          line-height: 18px;
          text-align: center;
        }
      }
      &-content {
        flex: 1;
        h1 {
          margin-top: 40px;
        }
        &-linxi {
          width: 30px;
          height: 32px;
          margin-top: 50px;
          margin-bottom: 60px;
          -webkit-user-drag: none;
        }
        p {
          margin: 0;
          padding: 0;
          font-size: 18px;
          font-weight: 400;
          color: #181c25;
          line-height: 36px;
          margin-bottom: 40px;
        }
      }
    }
    &-fourth-box {
      font-size: 18px;
      font-weight: 400;
      color: #181c25;
      line-height: 36px;
    }
    &-fifth-box {
      display: flex;
      position: relative;
      & > img {
        width: 154px;
        height: 100px;
        margin-right: 20px;
        margin-top: 60px;
        -webkit-user-drag: none;
      }
      .qr-box {
        position: absolute;
        right: 0;
      }
    }
  }
}
.qr-box {
  width: 648px;
  height: 100px;
  background: fade(#ad9174, 10%);
  border-radius: 2px;
  margin-top: 60px;
  display: flex;
  padding: 22px 32px;
  user-select: none;
  &-text {
    flex: 1;
    &-label {
      font-size: 24px;
      font-weight: 500;
      color: #181c25;
      line-height: 24px;
    }
    &-msg {
      font-size: 14px;
      font-weight: 400;
      color: #181c25;
      line-height: 14px;
      margin-top: 10px;
    }
  }
  &-img {
    width: 56px;
    justify-content: flex-end;
    img {
      width: 56px;
      height: 56px;
      transition: transform 0.5s;
      &:hover {
        transform: scale(1.7);
      }
    }
  }
}
</style>
